import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import IconButton from '@material-ui/core/IconButton';

import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';

// IMPORT own css
import "./ImageStepper.scss"

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: "auto",
    maxWidth: "100%",
    overflow: 'hidden',
    display: 'block',
    width: '100%',
  },
}));

export default function ImageStepper({imageItems}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = imageItems.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className={classes.root}>
      <InnerImageZoom src={imageItems[activeStep]} zoomSrc={imageItems[activeStep]} />

      {maxSteps > 1 &&
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
          <IconButton aria-label="next" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            <KeyboardArrowRight />
          </IconButton>
        }
        backButton={
          <IconButton aria-label="back" onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
          </IconButton>
        }
      />
      }
    </div>
  );
}

import React, { useState, useContext } from 'react';

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import userAtomState from "../../_atoms/userAtom";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { useSnackbar } from 'notistack';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { FormHelperText } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";

// IMPORT icons MATERIAL UI
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function PasswordDialog({ locked, setLocked, password, setPassword }) {
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);

  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { enqueueSnackbar } = useSnackbar();

  // GLOBAL STATE (RECOIL)
  const [userAtom, setUserAtom] = useRecoilState(userAtomState);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const checkPassword = async (event) => {
    // event.preventDefault();

    try {
      const formData = new FormData();
      formData.append("id", userAtom.data._id);
      formData.append("password", password);
      formData.append("image", "#");

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL_API}/users/check-password`,
        "POST",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      )
      // .then((res) => res.password === true && console.log("true"))
      .then(() => setPasswordErr(false))
      .then(() => setLocked(false))
      .then(() => handleClose())
    } catch (err) {
        setPasswordErr(true)
    }
  };

  return (
    <div>
      {locked && <Button size="small" variant="contained" color="secondary" onClick={handleClickOpen}>ändern</Button>}
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Passwort eingeben
        </DialogTitle>
        <DialogContent dividers>
          {/* <Grid item xs={12} style={{ marginBottom: "10px" }}>
            <Typography gutterBottom>
              Bitte geben Sie Ihr Passwort ein:
            </Typography>
          </Grid> */}
          
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel
                required
                htmlFor="password"
                // error={values.password.error}
                >
                {t("FIELDS.password.label")}
              </InputLabel>

              <OutlinedInput
                id="password"
                autoComplete="new-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e)=>setPassword(e.target.value)}
                onKeyDown={(e) => {
                  // On Spacebar or Enter
                  if ((e.keyCode === 32 || e.key === "Enter")) {
                    checkPassword();
                  }
                }}
                error={passwordErr}
                labelWidth={83}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={t("BUTTONS.AL_password_visibilty")}
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText
                error={passwordErr}
                id="form-helper-text-password"
              >
                {passwordErr ? "Das Passwort ist nicht korrekt" : ""}
              </FormHelperText>
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={checkPassword} color="primary">
            Passwort prüfen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useState, useContext, useEffect } from "react";
import {
  useRef,
  useImperativeHandle,
} from "react";

import { NavLink } from "react-router-dom";

import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import { AuthContext } from '../../shared/context/auth-context';

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { TextField, Button, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import InputAdornment from '@material-ui/core/InputAdornment';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ButtonLoading from "../../shared/components/UIElements/ButtonLoading";
import Alert from "@material-ui/lab/Alert";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Paper from '@material-ui/core/Paper';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh"
},
cardHeader: {
    backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
},
cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
},
}));



const StripeInput = ({ component: Component, inputRef, loading, ...other }) => {
  const ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSize: '16px',
        color: !loading ? 'rgba(0, 0, 0, 1)' : "rgba(0, 0, 0, 0.4)",
        letterSpacing: '0.025em',
        '::placeholder': {
          color: 'rgba(0, 0, 0, 0.5)',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

  const elementRef = useRef();
  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus
  }));

  
  return (
    <Component
      options={ELEMENT_OPTIONS}
      onReady={element => (elementRef.current = element)} {...other}/>
  );
}

const cardsLogo = [
  "amex",
  // "cirrus",
  "diners",
  // "dankort",
  "discover",
  // "jcb",
  "maestro",
  "mastercard",
  "visa",
  // "visaelectron",
];

function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successfulTransaction, setSuccessfulTransaction] = useState(false);
  const [showVat, setShowVat] = useState(false);
  const auth = useContext(AuthContext);

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const [value] = React.useState('monthly');

  function getSteps() {
    return ['Select campaign settings', 'Create an ad group', 'Create an ad'];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <React.Fragment>
            <CssBaseline />
            <Container
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "100vh", 
                }}
            >
                <Grid container style={{ maxWidth: "760px", justifyContent: "center", flexDirection: "column" }}>
                    {/* <img
                        src={Logo}
                        alt="Dagowert Logo"
                        style={{
                            marginTop: "20px",
                            maxHeight: "60px",
                        }}
                    /> */}

                    <Typography variant="h5" align="center" color="textSecondary" component="p" style={{ marginTop: "20px" }}>
                        Kunden pflegen. Rechnung legen.
                        <br />
                        <br />
                    </Typography>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Zahlung</FormLabel>
                        <RadioGroup aria-label="subscription" name="subscription" value={value} onChange={handleChange}>
                            <FormControlLabel value="monthly" control={<Radio color="primary" />} label="Monatlich" />
                            <FormControlLabel value="yearly" control={<Radio color="primary" />} label="Jährlich (20% Rabatt)" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                {/* End hero unit */}
                <Container maxWidth="md" component="main">
                    <Grid container spacing={5} alignItems="flex-end">
                        {tiers.map((tier) => (
                            // Enterprise card is full width at sm breakpoint
                            <Grid item key={tier.title} xs={12} md={6}>
                                <Card>
                                    <CardHeader
                                        title={tier.title}
                                        subheader={tier.subheader}
                                        titleTypographyProps={{ align: 'center' }}
                                        subheaderTypographyProps={{ align: 'center' }}
                                        // action={tier.title === 'Premium' ? <React.Fragment><div style={{display: "flex", flexDirection: "row", alignItems: "center"}}><Typography variant="subtitle2" >Beliebt</Typography><div><StarIcon color="primary"/></div></div></React.Fragment> : null}
                                        className={classes.cardHeader}
                                    />
                                    <CardContent>
                                        <div className={classes.cardPricing}>
                                            <Typography component="h2" variant="h5" color="textPrimary" style={{ marginRight: "6px" }}>
                                                {`€ ${tier.price}`}
                                            </Typography>
                                            <Typography variant="subtitle2" color="textSecondary">
                                             / Monat *
                                            </Typography>
                                        </div>
                                        <ul>
                                            {tier.description.map((line) => (
                                                <Typography component="li" variant="subtitle1" align="center" key={line}>
                                                    {line}
                                                </Typography>
                                            ))}
                                        </ul>
                                    </CardContent>
                                    <CardActions>
                                        <Button size="large" style={{ padding: "15px" }} fullWidth variant={tier.buttonVariant} color="primary" endIcon={<ArrowForwardIcon />}>
                                            {tier.buttonText}
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                   
                </Container>
                <Typography variant="subtitle2" color="textSecondary" style={{marginTop: "50px"}}>* exkl. MwSt.</Typography>
            </Container>

        </React.Fragment>
        )
      case 1:
        return 'An ad group contains one or more ads which target a shared set of keywords.';
      case 2:
        return `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`;
      default:
        return 'Unknown step';
    }
  }

  const steps = getSteps();


  const tiers = [
    {
        title: 'PREMIUM',
        // subheader: 'Beliebt',
        price: '25',
        description: [
            '20 users included',
            '10 GB of storage',
            'Help center access',
            'Priority email support',
        ],
        buttonText: 'Auf Premium wechseln',
        buttonVariant: 'contained',
    },
    {
        title: 'PRO',
        price: '100',
        description: [
            '50 users included',
            '30 GB of storage',
            'Help center access',
            'Phone & email support',
        ],
        buttonText: 'Auf Pro Wechseln',
        buttonVariant: 'outlined',
    },
];


  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [values, setValues] = useState({
    cardNumber: {
      state: "",
      error: false,
      errorMsg: ""
    },
    cardExpiry: {
      state: "",
      error: false,
      errorMsg: ""
    },
    cardCvc: {
      state: "",
      error: false,
      errorMsg: ""
    },
    vat: {
      state: "",
      digits: 0, // e.g. Austria: 8, Germany: 9 -> see https://www.finanz.at/steuern/umsatzsteuer/uid-nummer/
      code: "", // e.g. ATU, DE, etc ...
      error: false,
      errorMsg:
        "Bitte tragen Sie eine gültige USt.-Identifikationsnummer ein.",
    },
    country: "",
    isLoading: true,
  });

  useEffect(() => {
    if (auth.userId) {
        const currentUserData = async () => {
            try {
                fetch(`${process.env.REACT_APP_BACKEND_URL_API}/users/${auth.userId}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + auth.token,
                    },
                })
                    .then((response) => response.json())
                    .then((data) => {

                      let vatCode;
                      let vatDigits;
                      switch (JSON.parse(data.user.country).code) {
                        case "AT":
                          vatCode = "ATU";
                          vatDigits = 8;
                          break;
                        case 'DE':
                          vatCode = "DE";
                          vatDigits = 9;
                          break;
                        default:
                          vatCode = "ATU";
                          vatDigits = 8;
                      }

                      setValues({
                          ...values,
                          vat: {
                            ...values.vat,
                            state: data.user.vat === "" ? "" : data.user.vat,
                            code: vatCode,
                            digits: vatDigits
                          },
                          country: JSON.parse(data.user.country).code,
                          isLoading: false,
                      });
                    })
                    .catch((err) => {
                        console.log(err.message);
                    });
            } catch (err) { }
        };
        currentUserData();
    }
  }, [auth.userId, auth.token]);

  const createSubscription = async (event) => {
    event.preventDefault();

    if(!showVat || (showVat && !values.vat.error)) {
      setLoading(true);
      setErrorMessage("");

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const card = elements.getElement(CardNumberElement);
    const expiry = elements.getElement(CardExpiryElement);
    const cvc = elements.getElement(CardCvcElement);

    if (card == null) {
      return;
    }

    
    // Disable Fields when transaction is in progress
    card.update({ disabled: true });
    expiry.update({ disabled: true });
    cvc.update({ disabled: true });

    try {
      const paymentMethod = await stripe.createPaymentMethod({
        // card: elements.getElement("card"),
        // type: "card",
        type: 'card',
        card,
      });

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL_API}/users/create_subscription`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + auth.token
          },
          body: JSON.stringify({
            id: auth.userId,
            user_token: auth.token,
            paymentMethod: paymentMethod.paymentMethod.id,
            vatId: values.vat.state,
            vatCode: values.vat.code,
            countryCode: values.country,
          }),
        }
      );

      // if (!response.ok) {
      //   setLoading(false);
      // }

      if (response.ok) {
        const data = await response.json();
        const confirm = await stripe.confirmCardPayment(data.clientSecret);

        // Enable Fields again
        card.update({ disabled: false });
        expiry.update({ disabled: false });
        cvc.update({ disabled: false });

        if (confirm.error) {
          switch (confirm.error.code) {
            case "payment_intent_authentication_failure":
              setErrorMessage("Die Authentifizierung der angegebenen Zahlungsmethode ist fehlgeschlagen. Geben Sie eine neue Zahlungsmethode an, und versuchen Sie es erneut.")
              break;
            default:
              setErrorMessage("Die Zahlung war nicht erfolgreich. Bitte versuchen Sie es noch einmal oder kontaktieren Sie unseren Kundensupport unter customerservice@dagowert.com.")
          }
        }

        if (!confirm.error) {
          setSuccessfulTransaction(true);
          // window.location.href = `/dashboard`;
        }
      }

    } catch (err) {
      // Enable Fields again
      card.update({ disabled: false });
      expiry.update({ disabled: false });
      cvc.update({ disabled: false });

      // console.error(err);
      setErrorMessage("Die Zahlung war nicht erfolgreich. Bitte versuchen Sie es noch einmal oder kontaktieren Sie unseren Kundsupport unter customerservice@dagowert.com.")
      // alert("Payment failed! " + err.message);
    }

    setLoading(false);
    }
  };

  const validateStripeFields = (e, field) => {  

    // Workaround for cardExpiry field initial error state when submit button is clicked
    if (field === "cardExpiry" && e.empty === true) {
      setValues({ ...values, [field]: { ...values[field], error: true, errorMsg: e.error.message }});
    }
    
    const validateConditions = {
      cardNumber: field === "cardNumber" && e.error !== undefined ? true : false,
      cardExpiry: field === "cardExpiry" && e.error !== undefined ? true : false,
      cardCvc: field === "cardCvc" && e.error !== undefined ? true : false,
    };

    // Get the names of the object properties of validateConditions
    const names = Object.getOwnPropertyNames(validateConditions);

    // SINGLE field validation
    if (validateConditions[field]) {
      // ERROR true
      setValues({ ...values, [field]: { ...values[field], error: true, errorMsg: e.error.message }});
    } else {
      // ERROR false
      setValues({ ...values, [field]: { ...values[field], error: false, errorMsg: "" }});
    }
  }; 

  const validate = (field) => {
    const errorConditions = {
      vat: showVat ? values.vat.state === "" || values.vat.state.length !== values.vat.digits : false,
    };

    // Get the names of the object properties of errorConditions.
    const names = Object.getOwnPropertyNames(errorConditions);

    // Validate each entry during typing.
    if (field !== "all" && errorConditions[field]) {
      setValues({ ...values, [field]: { ...values[field], error: true } });
    } else {
      setValues({ ...values, [field]: { ...values[field], error: false } });
    }

    // Validate all entries at once when form is submitted.
    if (field === "all") {
      names.forEach((name) => {
        if (errorConditions[name]) {
          setValues((prevValues) => {
            return { ...prevValues, [name]: { ...values[name], error: true } };
          });
        } else {
          setValues((prevValues) => {
            return { ...prevValues, [name]: { ...values[name], error: false } };
          });
        }
      });
    }
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.id]: {
        ...values[event.target.id],
        state: event.target.value,
      },
    });
  };




  return (
    <React.Fragment>

<div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography>{getStepContent(index)}</Typography>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} className={classes.button}>
            Reset
          </Button>
        </Paper>
      )}
    </div>
      {!successfulTransaction &&
      <Container
        maxWidth="md"
        style={{ marginTop: "20px", marginBottom: "100px", minHeight: "100vh" }}
      >


        <form onSubmit={createSubscription} disabled={loading}>
          <Grid container item lg={6} spacing={2}>
            <Grid container item lg={12}>
              <br />
              <Typography variant="h6" component="h1">
                Mit Kreditkarte bezahlen
              </Typography>

            </Grid>

            <Grid
              container
              item
              lg={12}
              style={{ marginBottom: "10px" }}
            >
              {cardsLogo.map(e => <img key={e} src={`./cards/${e}.png`} alt={e} width="32px" align="bottom" style={{ marginRight: "5px" }} />)}
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                disabled={loading}
                autoComplete='off'
                label="Kartennummer"
                id="cardNumber"
                name="cardNumber"
                variant="outlined"
                required
                fullWidth
                defaultValue={values.cardNumber.state}
                onChange={(e)=>validateStripeFields(e, e.elementType)}
                error={values.cardNumber.error}
                  helperText={
                    values.cardNumber.error
                      ? values.cardNumber.errorMsg
                      : ""
                  }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CreditCardIcon color={!loading && "primary"} />
                    </InputAdornment>
                  ),
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardNumberElement,
                    loading: loading
                  },
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={6} sm={12}>
              <TextField
                disabled={loading}
                autoComplete='off'
                label="Gültig bis"
                id="expiry"
                name="expiry"
                variant="outlined"
                required
                fullWidth
                defaultValue={values.cardExpiry.state}
                onChange={(e)=>validateStripeFields(e, e.elementType)}
                error={values.cardExpiry.error}
                  helperText={
                    values.cardExpiry.error
                      ? values.cardExpiry.errorMsg
                      : ""
                  }
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardExpiryElement,
                    loading: loading
                  },
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={6} sm={12}>
              <TextField
                disabled={loading}
                autoComplete='off'
                label="CVC"
                id="cvc"
                name="cvc"
                variant="outlined"
                required
                fullWidth
                defaultValue={values.cardCvc.state}
                onChange={(e)=>validateStripeFields(e, e.elementType)}
                error={values.cardCvc.error}
                  helperText={
                    values.cardCvc.error
                      ? values.cardCvc.errorMsg
                      : ""
                  }
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardCvcElement,
                    loading: loading
                  },
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

                {!showVat && values.country === "DE" &&
                <Grid Grid item xs={12} sm={12}>

                  <span
                    onClick={() => {
                      !loading ? setShowVat(true) : console.log("loading");
                    }}
                    style={{ display: "flex", cursor: "pointer" }}>
                    <AddCircleIcon fontSize="small" color={"primary"}/>
                    <Typography
                      component="div"
                      variant="subtitle2"
                      style={{ cursor: "pointer", marginLeft: "5px" }}
                    >USt.-Identifikationsnummer hinzufügen (für MwSt.-freie Verrechnung nach dem Reverse Charge Prinzip)</Typography>
                  </span>

                </Grid>
              }

              {showVat &&
              <Grid item xs={12} sm={12}>
                <TextField
                  disabled={loading}
                  defaultValue={values.vat.state}
                  id="vat"
                  name="vat"
                  label={`UID Nummer (${values.vat.digits} Stellen, ohne Leerzeichen)`}
                  variant="outlined"
                  type="text"
                  onChange={handleChange}
                  error={values.vat.error}
                  helperText={
                    values.vat.error
                      ? values.vat.errorMsg
                      : ""
                  }
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start" style={{backgroundColor: "#ededed", borderRadius: "5px", padding: "10px", height: "100%"}}>{values.vat.code}</InputAdornment>,
                    endAdornment: <InputAdornment
                        onClick={() => {
                          !loading ? setShowVat(false) : console.log("loading");
                        }}
                        position="end"
                        style={{ cursor: "pointer" }}
                        >
                            <DeleteForeverIcon fontSize="small" style={{ color: "grey" }} />
                        </InputAdornment>,
                  }}
                />
              </Grid>
              }

          </Grid>
          <br />

          <Grid container item lg={6} spacing={2}>
            <Grid item lg={12}>
              <ButtonLoading
                loading={loading}
                disabled={loading}
                type="submit"
                onClick={() => validate("all")}
                fullWidth
                size="large"
                style={{ padding: "15px" }}
                endIcon={<ArrowForwardIcon />}
              // onClick={onButtonClick}
              >
                JETZT BESTELLEN
              </ButtonLoading>
            </Grid>
          

          {loading &&
            <Grid item lg={12}>
              <Typography>Ihre Zahlung wird bearbeitet. Bitte warten ...</Typography>
            </Grid>
          }

          {errorMessage !== "" &&
            <Grid item lg={12}>
              < Alert style={{ width: "100%"}} severity="error">{errorMessage}</Alert>
            </Grid>
          }
          </Grid>
        </form>


      </Container>
      }

      {successfulTransaction &&
      <Container
        maxWidth="md"
        style={{ marginTop: "20px", marginBottom: "100px", minHeight: "100vh" }}
      >
        <Grid container item lg={12} spacing={3} style={{ justifyContent: "center" }}></Grid>
          <Grid container item lg={12} style={{ justifyContent: "center", textAlign: "center" }}>
              <Typography variant="h5" >Zahlung erfolgreich durchgeführt!</Typography>
          </Grid>

          <Grid container item lg={12} style={{ justifyContent: "center", textAlign: "center" }}>
              <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
          </Grid>

          <Grid item lg={12} style={{ textAlign: "center" }}>
            <NavLink to={`/dashboard`}>
              <Button color="primary" variant="contained">Zum Dashboard</Button>
            </NavLink>
          </Grid>

      </Container>
      }
    </React.Fragment>
  );
}

export default CheckoutForm;


export const de_FEEDBACK = {
    verification: {
        email: {
            h1: "Bitte bestätigen Sie noch Ihre Email-Adresse",
            text: "Wir haben Ihnen eine Email auf die von Ihnen angegebene Adresse geschickt. Bitte öffnen Sie diese Email in Ihrem Postfach und klicken Sie auf den Bestätigungslink. Falls Sie keine E-Mail in Ihrer Inbox sehen, kontrollieren Sie bitte auch Ihren Spam-Folder."
        },
        password: {
            h1: "Link zum Ändern Ihres Passwortes wurde gesendet",
            text: "Falls ein User-Konto für die von Ihnen angegebene E-Mail-Adresse existiert, haben Ihnen einen Link zum Ändern Ihres Passwortes geschickt. Falls Sie keine E-Mail in Ihrer Inbox sehen, kontrollieren Sie bitte auch Ihren Spam-Folder."
        }
    },
    success: {
        email: {
            h1: "Email-Adresse bestätigt!",
            text: "Sie können sich jetzt einloggen."
        },
        password: {
            h1: "Passwort erfolgreich geändert",
            text: "Sie können sich jetzt einloggen."
        },
    },
    fail: {
        default: {
            h1: "Entschuldigung",
            text: "Etwas ist schief gelaufen."
        }
    }
  }
  
import React, { useEffect, useState } from "react";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import settingsAtomState from "../../_atoms/settingsAtom";
import userAtomState from "./../../_atoms/userAtom";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";

// IMPORT components
import SortableList, { SortableItem } from 'react-easy-sort'
import arrayMove from "array-move";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';


// IMPORT components MATERIAL UI
import {
  Typography,
  Button,
  Grid,
  IconButton,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  Chip,
  Menu,
  MenuItem,
  Dialog,
  TextField,
  FormLabel,
  InputAdornment
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

// IMPORT icons MATERIAL UI
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';
import settingsAtom from "../../_atoms/settingsAtom";

import "./InvoicesSettings.scss"


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function InvoicesSettings({
  settings,
  handleChange,
  cancelSettingsHandler,
  updateSettingsHandler,
  SET_VALUE,
  setSettings,
  setValues
}) {

  const [userAtom, setUserAtom] = useRecoilState(userAtomState);
  const [settingsAtomInitial, setSettingsAtomInitial] = useRecoilState(settingsAtomState);

  // If invoicesNumberItems are received from DB -> it is  an array of objects stored AS STRING, afterwards it is not a string
  const invoicesNumberItems = typeof (settings.invoicesNumberItems) === "string" ? JSON.parse(settings.invoicesNumberItems) : settings.invoicesNumberItems;

  // HOOKS & CONTEXT
  const { t } = useTranslation();

  const [localValues, setLocalValues] = useState({
    continuousNumberErr: false,
    continuousNumberErrMsg: "",
    invoicesDueDaysErr: false,
    invoicesDueDaysErrMsg: "",
  });

  const [editorStateMessage, setEditorStateMessage] = useState(null);

  const [open, setOpen] = useState(false);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');

  const handleClickOpen = () => {
    // Get current setting values
    SET_VALUE({
      target: {
        "invoicesNumberItems": settingsAtomInitial.invoicesNumberItems
      }
    }, setSettings, "multi");

    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  // LOCAL STATE (PURE)
  const [continuousNumberErrorMsg, setContinuousNumberErrorMsg] = useState("");
  const [anchorEl, setAnchorEl] = useState(null); // invoicesNumer-elements-menu (open state)http://localhost:3000/

  /* 
    FUNCTIONS
  */

  const handleOpenInvoicesNumberMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseInvoicesNumberMenu = () => {
    setAnchorEl(null);
  };

  const onSortEndInvoicesNumberItems = (oldIndex, newIndex) => {
    const items = arrayMove(invoicesNumberItems, oldIndex, newIndex);

    SET_VALUE({
      target: {
        "invoicesNumberItems": items
      }
    }, setSettings, "multi");
  };

  const addInvoicesNumberItem = (type) => {
    let newItem;
    if (type === "continuousNumber") {
      newItem = { id: (invoicesNumberItems).reduce((acc, item) => Math.max(acc, item.id), 0) + 1, type: type, value: "1" }
    } else {
      newItem = { id: (invoicesNumberItems).reduce((acc, item) => Math.max(acc, item.id), 0) + 1, type: type, value: "" }
    }

    SET_VALUE({
      target: {
        "invoicesNumberItems": [
          ...invoicesNumberItems,
          newItem
        ]
      }
    }, setSettings, "multi")
  };

  const changeinvoicesNumberItem = (id, type, value) => {
    
    if (type === "continuousNumber" && isNaN(value)) {
      setLocalValues({
        ...localValues,
        continuousNumberErr: true,
        continuousNumberErrMsg: "Bitte nur Zahlen verwenden"
      })
    }

    else if (type === "continuousNumber" && (value < 1)) {
      setLocalValues({
        ...localValues,
        continuousNumberErr: true,
        continuousNumberErrMsg: "Bitte nur Zahlen größer 0 verwenden"
      })
    }

    else if (type === "continuousNumber" && (value === "")) {
      setLocalValues({
        ...localValues,
        continuousNumberErr: true,
        continuousNumberErrMsg: "Bitte ausfüllen"
      })
    }

    else {
      setLocalValues({
        ...localValues,
        continuousNumberErr: false,
        continuousNumberErrMsg: ""
      })
    }

    // 1. Find the item with the provided id
    const currentIndex = invoicesNumberItems.findIndex((item) => item.id === id);
    // 2. Set new value for the item
    let updatedItem = { ...invoicesNumberItems[currentIndex], type: type, value: value};
    // 3. Update the item list with the updated item
    const newItems = [...invoicesNumberItems];
    newItems[currentIndex] = updatedItem;
    setContinuousNumberErrorMsg("");
    SET_VALUE({
      target: {
        "invoicesNumberItems": newItems
      }
    }, setSettings, "multi");
  };


  useEffect(() => {
    if(editorStateMessage !== null) {
      SET_VALUE({
        target: {
          "invoicesEmailText": draftToHtml(convertToRaw(editorStateMessage.getCurrentContent()))
        }
      }, setSettings, "multi");
    }
  }, [editorStateMessage]);


  const removeinvoicesNumberItem = (id) => {
    const newItems = invoicesNumberItems.filter((item) => item.id !== id);
    SET_VALUE({
      target: {
        "invoicesNumberItems": newItems
      }
    }, setSettings, "multi");
  };

  const checkDisabledInvoicesNumberElements = (type) => {
    return ((typeof (settings.invoicesNumberItems) === "string" ? JSON.parse(settings.invoicesNumberItems) : settings.invoicesNumberItems))
      .findIndex((elements) => elements.type === type) !== -1 ? true : false
  };

  const updateSettings = () => {
    if (!localValues.continuousNumberErr) {
      SET_VALUE({ target: { id: "submenuOpen", value: true } }, setValues); updateSettingsHandler(); handleClose();
    }
  }

  useEffect(() => {
    if(settingsAtomInitial.invoicesEmailText === undefined) {
      setEditorStateMessage(EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(`
          <p>Sehr geehrte Damen und Herren!</p>
          <p>Anbei senden wir Ihnen die Rechnung für die erbrachte Leistung und freuen uns auf erfolgreiche weitere Zusammenarbeit.</p>
          <p>Mit freundlichen Grüßen</br>${userAtom.data.company}</p>
          <p>Bitte antworten Sie nicht direkt auf diese automatisch generierte Nachricht. Bei Fragen sind wir unter den auf der Rechnung angegebenen Kontaktdaten gerne für Sie da.</p>
          `)
        )))
    } else {
      setEditorStateMessage(EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(settingsAtomInitial.invoicesEmailText)
        )))
    }
  }, [settingsAtomInitial]);


  // const handleChangeDueDays = (e) => {
  //   console.log(e.target.value === "")
  //   if (parseInt(e.target.value) > -1 || e.target.value === "") {
  //     SET_VALUE({
  //       target: {
  //         "invoicesDueDays": e.target.value
  //       }
  //     }, setSettings, "multi");
  //     setLocalValues({
  //       ...localValues,
  //       invoicesDueDaysErr: false,
  //       invoicesDueDaysErrMsg: ""
  //     })
  //   } else {
  //     setLocalValues({
  //       ...localValues,
  //       invoicesDueDaysErr: true,
  //       invoicesDueDaysErrMsg: "Nur positive Zahlen möglich."
  //     })
  //   }
  // }
  
  return (
    <>
    <IconButton
      aria-label="settings"
      style={{ color: "inherit" }}
      onClick={handleClickOpen}
    >
      <SettingsIcon fontSize="small" />
    </IconButton>
    <Dialog
      onClose={() => { cancelSettingsHandler(); handleClose() }}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <DialogTitle id="customized-dialog-title" onClose={() => { cancelSettingsHandler(); handleClose() }} style={{ display: "flex" }}>
        <SettingsIcon color="primary" /> {t("PAGE_INVOICES.settings")}
      </DialogTitle>
      <DialogContent dividers>

      <Typography variant="body2" component="div"><strong>Elemente von Rechnungsnummern</strong></Typography>
      <Grid container item alignItems="center" style={{ paddingLeft: "30px", marginTop: "0", marginBottom: "10px" }}>
        <Grid container item alignItems="center" style={{ marginTop: "5px", marginBottom: "10px" }}>
          <Grid item container direction="row" >

            <SortableList onSortEnd={onSortEndInvoicesNumberItems} draggedItemClassName="dragged">
              {invoicesNumberItems.map((item, i) => (
                <SortableItem key={item.id}>
                  <Grid container item direction="row" alignItems="center" className="sortable-chip">

                    {item.type === "text" &&
                      <Grid item container direction="row" alignItems="center" >
                        <DragIndicatorIcon fontSize="small" />
                        <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px" }}>{t("PAGE_INVOICES.elementText")}</Typography>
                        <Chip
                          style={{ width: "227px", justifyContent: "flex-start" }}
                          size="small"
                          label={<input className="badge-input" defaultValue={item.value !== "" ? item.value : ""} placeholder={`Text, Zahl oder Sonderzeichen ...`} onChange={(e) => changeinvoicesNumberItem(item.id, "text", e.target.value)} ></input>}
                          className="draggable-chip badge-input-wrapper"
                        />

                        <IconButton size="small" style={{ marginTop: "0px", marginRight: "5px" }} onClick={() => removeinvoicesNumberItem(item.id)} >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                    }

                    {item.type === "continuousNumber" &&
                      <Grid item container direction="row" alignItems="center" className="sortable-chip">
                        <DragIndicatorIcon fontSize="small" />
                        <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px" }}>{t("PAGE_INVOICES.elementContinuousNumberDescribed")} </Typography>
                        <Chip
                          style={{ width: "60px", justifyContent: "flex-start" }}
                          size="small"
                          label={<input defaultValue={item.value} value={item.value} className="badge-input" onChange={(e) => changeinvoicesNumberItem(item.id, "continuousNumber", e.target.value)} style={{ textAlign: "center", background: "transparent", border: "none" }}></input>}
                          className="draggable-chip badge-input-wrapper"
                        />
                        <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px", color: "red" }}>{localValues.continuousNumberErrMsg}</Typography>
                        {/* <IconButton size="small" style={{ marginTop: "0px", marginRight: "5px" }} onClick={() => removeinvoicesNumberItem(item.id)}>
                          <ClearIcon fontSize="small" />
                        </IconButton> */}
                      </Grid>
                    }

                    {item.type === "year" &&
                      <Grid item container direction="row" alignItems="center" className="sortable-chip" style={{ paddingTop: "3px", paddingBottom: "3px" }}>
                        <DragIndicatorIcon fontSize="small" />
                        <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px" }}>{t("PAGE_INVOICES.elementYear")}</Typography>
                        <IconButton size="small" style={{ marginTop: "0px", marginRight: "5px" }}>
                          <ClearIcon fontSize="small" onClick={() => removeinvoicesNumberItem(item.id)} />
                        </IconButton>
                      </Grid>
                    }

                    {item.type === "month" &&
                      <Grid item container direction="row" alignItems="center" className="sortable-chip" style={{ paddingTop: "3px", paddingBottom: "3px" }}>
                        <DragIndicatorIcon fontSize="small" />
                        <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px" }}>{t("PAGE_INVOICES.elementMonth")}</Typography>
                        <IconButton size="small" style={{ marginTop: "0px", marginRight: "5px" }}>
                          <ClearIcon fontSize="small" onClick={() => removeinvoicesNumberItem(item.id)} />
                        </IconButton>
                      </Grid>
                    }

                    {item.type === "day" &&
                      <Grid item container direction="row" alignItems="center" className="sortable-chip" style={{ paddingTop: "3px", paddingBottom: "3px" }}>
                        <DragIndicatorIcon fontSize="small" />
                        <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px" }}>{t("PAGE_INVOICES.elementDay")}</Typography>
                        <IconButton size="small" style={{ marginTop: "0px", marginRight: "5px" }}>
                          <ClearIcon fontSize="small" onClick={() => removeinvoicesNumberItem(item.id)} />
                        </IconButton>
                      </Grid>
                    }
                  </Grid>
                </SortableItem>
              ))}
            </SortableList>

            <Grid item container direction="row" alignItems="center" style={{ marginBottom: "5px" }}>
              <div className="button--xxs" aria-controls="simple-menu" aria-haspopup="true" onClick={handleOpenInvoicesNumberMenu}>
                <IconButton size="small" color="secondary" style={{ marginTop: "0px", marginRight: "5px" }}>
                  <AddCircleIcon fontSize="small" />
                </IconButton>

                <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px", fontWeight: "600" }}>{t("PAGE_INVOICES.addElement")}</Typography>
              </div>
              <Menu
                id="invoicesNumber-element-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseInvoicesNumberMenu}
              >
                <MenuItem
                  onClick={() => {
                    handleCloseInvoicesNumberMenu();
                    addInvoicesNumberItem("text");
                  }}
                >
                  <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px", fontWeight: "600" }}>{t("PAGE_INVOICES.elementText")} <span style={{ fontSize: "10px", fontWeight: "300" }}>{t("PAGE_INVOICES.elementTextPlaceholder")}</span></Typography>
                </MenuItem>

                <MenuItem
                  disabled={checkDisabledInvoicesNumberElements("continuousNumber")}
                  onClick={() => {
                    handleCloseInvoicesNumberMenu();
                    addInvoicesNumberItem("continuousNumber");
                  }}
                >
                  <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px", fontWeight: "600" }}>{t("PAGE_INVOICES.elementContinuousNumber")}</Typography>
                </MenuItem>

                <MenuItem
                  disabled={checkDisabledInvoicesNumberElements("year")}
                  onClick={() => {
                    handleCloseInvoicesNumberMenu();
                    addInvoicesNumberItem("year");
                  }}
                >
                  <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px", fontWeight: "600" }}>{t("PAGE_INVOICES.elementYear")} <span style={{ fontSize: "10px", fontWeight: "300" }}>{t("PAGE_INVOICES.elementDatePlaceholder")}</span></Typography>
                </MenuItem>

                <MenuItem
                  disabled={checkDisabledInvoicesNumberElements("month")}
                  onClick={() => {
                    handleCloseInvoicesNumberMenu();
                    addInvoicesNumberItem("month");
                  }}
                >
                  <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px", fontWeight: "600" }}>{t("PAGE_INVOICES.elementMonth")} <span style={{ fontSize: "10px", fontWeight: "300" }}>{t("PAGE_INVOICES.elementDatePlaceholder")}</span></Typography>
                </MenuItem>

                <MenuItem
                  disabled={checkDisabledInvoicesNumberElements("day")}
                  onClick={() => {
                    handleCloseInvoicesNumberMenu();
                    addInvoicesNumberItem("day")
                  }}
                >
                  <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px", fontWeight: "600" }}>{t("PAGE_INVOICES.elementDay")} <span style={{ fontSize: "10px", fontWeight: "300" }}>{t("PAGE_INVOICES.elementDatePlaceholder")}</span></Typography>
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>

                    {invoicesNumberItems.length !== 0 &&
                      <>
                        <Grid item container alignItems="center" style={{ marginBottom: "20px" }}>
                          <VisibilityIcon fontSize="small" style={{ marginTop: "0px", marginRight: "5px" }} />
                          <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px" }}>{t("PAGE_INVOICES.invoiceNumberPreview")} </Typography>
                          <Typography variant="body2" component="div" style={{ fontSize: "13px", paddingTop: "1px", marginRight: "5px", fontWeight: "500" }}>

                            {invoicesNumberItems.map((item, i) => (
                              <span key={i}>
                                {(item.type === "text" || item.type === "continuousNumber") &&
                                  <span>{item.value}</span>
                                }

                                {item.type === "year" &&
                                  <span>{new Date().getFullYear()}</span>
                                }

                                {item.type === "month" &&
                                  <span>{new Date().getMonth() + 1}</span>
                                }

                                {item.type === "day" &&
                                  <span>{new Date().getDate()}</span>
                                }
                              </span>
                            ))}

                          </Typography>
                        </Grid>
                      </>
                    }
              </Grid>

        <Grid container item >
          {/* <Grid container item >
            <Grid container item>
              <Typography variant="body2" component="div" style={{ marginTop: "15px" }}><strong>Fälligkeit von Rechnungen</strong></Typography>
            </Grid>
            <FormControl component="fieldset">
              <RadioGroup aria-label="invoicesDueState" value={settings.invoicesDueState ? settings.invoicesDueState : "instant"} name="invoicesDueState" onChange={handleChange}>
              <FormControlLabel value="instant" control={<Radio color="primary" style={{ paddingTop: "5px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">Sofort fällig</Typography>} />
              <FormControlLabel value="days" control={<Radio color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">Fällig in ...</Typography>} />
              </RadioGroup>
            </FormControl>

            {settings.invoicesDueState === "days" &&
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                value={settings.invoicesDueDays}
                id="invoicesDueDays"
                // label={`${t("FIELDS.invoicesText")}`}
                variant="outlined"
                type="text"
                onChange={(e) => handleChangeDueDays(e)}
                style={{ width: "100px" }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                  Tagen
                  </InputAdornment>,
                }}
              />
            </Grid>
            }
          </Grid> */}
          
          <Grid container item >
            <Typography variant="body2" component="div" style={{ marginTop: "15px" }}><strong>{t("PAGE_INVOICES.invoicesText")}</strong></Typography>
            <Grid item xs={12}>
              <TextField
              multiline
                autoComplete="off"
                defaultValue={(settings.invoicesText === undefined) ? "Wir erlauben uns, Ihnen folgenden Betrag in Rechnung zu stellen und freuen uns auf weitere erfolgreiche Zusammenarbeit." : settings.invoicesText}
                id="invoicesText"
                // label={`${t("FIELDS.invoicesText")}`}
                variant="outlined"
                type="textarea"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
        </Grid>

          <Grid container item>
            <Typography variant="body2" component="div" style={{ marginTop: "15px" }}><strong>Nachricht in Rechnungs-Emails</strong></Typography>

            <Editor
              toolbar={{
                options: ['inline', 'fontSize', 'list', 'textAlign', 'link'],
                inline: { inDropdown: true, options: ['bold', 'italic', 'underline', 'strikethrough']},
                textAlign: {inDropdown: true},
                link: { inDropdown: true },
                list: { inDropdown: true },
              }}
              // toolbarOnFocus
              editorState={editorStateMessage}
              onEditorStateChange={(editorState) => {setEditorStateMessage(editorState)}}
              localization={{
                locale: 'de',
              }}
              placeholder="E-Mail Text schreiben ..."
              wrapperClassName="editor-wrapper"
              editorClassName="editor"
            />
          </Grid>
        </Grid>
      </DialogContent>

<DialogActions>
  <Button
    variant="outlined"
    size="small"
    onClick={() => {
      cancelSettingsHandler();
      handleClose();
      settingsAtomInitial.invoicesEmailText === undefined ?
          setEditorStateMessage(EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(`
            <p>Sehr geehrte Damen und Herren!</p>
            <p>Anbei senden wir Ihnen die Rechnung für die erbrachte Leistung und freuen uns auf erfolgreiche weitere Zusammenarbeit.</p>
            <p>Mit freundlichen Grüßen</br>${userAtom.data.company}</p>
            <p>Bitte antworten Sie nicht direkt auf diese automatisch generierte Nachricht. Bei Fragen sind wir unter den auf der Rechnung angegebenen Kontaktdaten gerne für Sie da.</p>
            `)
          )))
        :
        setEditorStateMessage(EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(settingsAtomInitial.invoicesEmailText)
        )))
      }}
    >
    {t("BUTTONS.cancel")}
  </Button>

  <Button
    variant="contained"
    color="primary"
    size="small"
    // onClick={() => { SET_VALUE({ target: { id: "submenuOpen", value: true } }, setValues); updateSettingsHandler(); handleClose();}}
    onClick={() => { updateSettings() }}
    startIcon={<SaveIcon style={{ marginRight: "5px" }} />}
  >
    {t("BUTTONS.save")}
  </Button>
</DialogActions>
</Dialog>
</>
  );
}

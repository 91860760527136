import React, { useState } from "react";

// IMPORT hooks & context
import { useNavigate } from "react-router-dom";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { useTranslation } from "react-i18next";

// IMPORT functions
import { VALIDATE } from '../../_functions/VALIDATE';
import { SET_VALUES } from '../../_functions/SET_VALUES';
import { ERROR } from '../../_functions/ERROR';

// IMPORT components
import ButtonLoading from "../../shared/components/UIElements/ButtonLoading";
import Logo from "../../shared/components/UIElements/Logo";
import Copyright from "../../shared/components/UIElements/Copyright";
import DialogElement from "../../shared/components/UIElements/DialogElement";

// IMPORT components MATERIAL UI
import { Typography, Box, FormHelperText } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Alert from "@material-ui/lab/Alert";
import Checkbox from '@material-ui/core/Checkbox';
import Fade from '@material-ui/core/Fade';

// IMPORT icons MATERIAL UI
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockIcon from '@material-ui/icons/Lock';

// IMPORT theme MATERIAL UI and custom scss
import { useTheme } from '@material-ui/core/styles';

const Register = () => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const theme = useTheme();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const navigate = useNavigate();

  // LOCAL STATE
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [values, setValues] = useState({
    email: {
      state: "",
      error: false,
      errorMsg: t("FIELDS.email.error"),
    },
    password: {
      state: "",
      error: false,
      errorMsg: t("FIELDS.password.error"),
    },
    terms: {
      state: false,
      error: false,
      errorMsg: t("FIELDS.terms.error")
    },
    newsletter: {
      state: false
    }
  });

  /* 
    FUNCTIONS
  */
  const handleChange = (e) => {
    clearError();
    SET_VALUES(e, values, setValues);
  };

  const validate = (field) => {
    VALIDATE([
      "email",
      "password",
      "terms"
    ],
      field, values, setValues);
  };

  console.log(values.email.state)
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const authSubmitHandler = async (event) => {
    event.preventDefault();

    if (!values.email.error && !values.password.error && !values.terms.error) {
      try {
        const formData = new FormData();
        formData.append("email", values.email.state);
        formData.append("password", values.password.state);
        formData.append("newsletter", values.newsletter.state);

        await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL_API}/users/signup`,
          "POST",
          formData
        )
        .then(() => navigate("/verify-email"))
      } catch (err) { }
    }
  };

  return (
    <React.Fragment>
      <Container maxWidth={false} disableGutters className="bg--6">
        <Container maxWidth="sm">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            padding="50px"
            className="h-gap--50"
          >
            <Logo color="bright" />

            <Fade in={true}>
            <Grid
              container
              direction="column"
              alignContent="center"
              alignItems="center"
              className="h-gap--30 h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--1"
            >
              <form onSubmit={authSubmitHandler}>
                <Grid container item spacing={3}>
                  <Grid container item direction="column" alignItems="center">
                    <div className="icon-wrapper--lg" style={{ background: theme.palette.secondary.main }}>
                      <LockIcon size="large" />
                    </div>

                    <Typography component="h1" variant="h5">{t("PAGE_REGISTER.h1")}</Typography>
                  </Grid>

                  <Grid container item spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        autoComplete="off"
                        id="email"
                        label={`${t("FIELDS.email.label")}`}
                        variant="outlined"
                        type="email"
                        onChange={handleChange}
                        onBlur={() => validate("email")}
                        error={values.email.error}
                        helperText={values.email.error ? values.email.errorMsg : ""}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel required htmlFor="password" error={values.password.error}>
                          {t("FIELDS.password.label")}
                        </InputLabel>

                        <OutlinedInput
                          id="password"
                          autoComplete="new-password"
                          type={showPassword ? "text" : "password"}
                          value={values.password.state}
                          onChange={handleChange}
                          error={values.password.error}
                          onBlur={() => validate("password")}
                          disabled={isLoading}
                          labelWidth={83}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label={t("BUTTONS.AL_password_visibilty")}
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <FormHelperText
                          error={values.password.error}
                          id="form-helper-text-password"
                        >
                          {values.password.error ? values.password.errorMsg : ""}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    direction="column"
                    className="h-gap--10"
                    style={{ display: "flex"}}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        style={{ marginRight: 0 }}
                        control={
                          <Checkbox
                            checked={values.terms.state}
                            color="primary"
                            onChange={(e) => (handleChange(e))}
                            name="terms"
                            id="terms"
                          />}
                      />
                      <div>
                        <Typography variant="body2" component="span">
                          <span>{t("FIELDS.terms.label1")} </span>
                          <span className="link" onClick={() => (setDialogOpen(true), setDialogContent("TERMS"))} style={{ fontWeight: "bold" }}>{t("FIELDS.terms.label2")}</span>
                          <span> {t("FIELDS.terms.label3")} </span>
                          <span className="link" onClick={() => (setDialogOpen(true), setDialogContent("PRIVACY"))} style={{ fontWeight: "bold" }}>{t("FIELDS.terms.label4")}</span>
                          <span> {t("FIELDS.terms.label5")} *</span>
                        </Typography>

                        {values.terms.error &&
                          <FormHelperText style={{ color: "#b71c1c" }}>{values.terms.errorMsg}</FormHelperText>
                        }
                      </div>
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={<Checkbox checked={values.newsletter.state} color="primary" onChange={handleChange} name="newsletter" id="newsletter" />}
                        label={
                          <Typography variant="body2" >
                            Ich möchte den DagoWert Newsletter abonnieren.
                          </Typography>
                        }
                      />
                    </div>
                  </Grid>

                  {error &&
                    <Grid item xs={12}>
                      <Alert severity="error">{ERROR(t, error)}
                      </Alert>
                    </Grid>
                  }

                  <Grid item xs={12}>
                    <ButtonLoading
                      loading={isLoading}
                      disabled={isLoading}
                      type="submit"
                      onClick={() => validate("all")}
                      fullWidth
                      size="large"
                      className="button--loading"
                      endIcon={<ExitToAppIcon />}
                    >
                      {t("BUTTONS.register")}
                    </ButtonLoading>
                  </Grid>
                </Grid>
              </form>

              <Grid container item justifyContent="center">
                <Typography component="div" variant="subtitle2">
                  <Link
                    href="/"
                    variant="subtitle2"
                    style={{ fontWeight: "300" }}
                  >
                    {t("PAGE_REGISTER.link_login")}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
            </Fade>

            <Copyright color="bright"/>
          </Box>
        </Container>
      </Container>

      <DialogElement open={dialogOpen} setOpen={setDialogOpen} content={dialogContent} />
    </React.Fragment>
  );
};

export default Register;

import React, { useState } from "react";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import settingsAtomState from "../../_atoms/settingsAtom";
import userAtomState from "./../../_atoms/userAtom";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";

// IMPORT components
import SortableList, { SortableItem } from 'react-easy-sort'
import arrayMove from "array-move";


// IMPORT components MATERIAL UI
import {
  Typography,
  Button,
  Grid,
  IconButton,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  Chip,
  Menu,
  MenuItem,
  Dialog,
  TextField
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

// IMPORT icons MATERIAL UI
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';
import settingsAtom from "../../_atoms/settingsAtom";

// import "./InvoicesSettings.scss"


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function ClientsSettings({
  settings,
  handleChange,
  cancelSettingsHandler,
  updateSettingsHandler,
  SET_VALUE,
  setSettings,
  setValues
}) {

  const [userAtom, setUserAtom] = useRecoilState(userAtomState);
  const [settingsAtomInitial, setSettingsAtomInitial] = useRecoilState(settingsAtomState);

  // If clientsNumberItems are received from DB -> it is  an array of objects stored AS STRING, afterwards it is not a string
  const clientsNumberItems = typeof (settings.clientsNumberItems) === "string" ? JSON.parse(settings.clientsNumberItems) : settings.clientsNumberItems;

  // HOOKS & CONTEXT
  const { t } = useTranslation();

  const [localValues, setLocalValues] = useState({
    continuousNumberErr: false,
    continuousNumberErrMsg: ""
  });

  const [editorStateMessage, setEditorStateMessage] = useState(null);

  const [open, setOpen] = useState(false);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');

  const handleClickOpen = () => {
    // Get current setting values
    SET_VALUE({
      target: {
        "clientsNumberItems": settingsAtomInitial.clientsNumberItems
      }
    }, setSettings, "multi");

    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  // LOCAL STATE (PURE)
  const [continuousNumberErrorMsg, setContinuousNumberErrorMsg] = useState("");
  const [anchorEl, setAnchorEl] = useState(null); // invoicesNumer-elements-menu (open state)http://localhost:3000/

  /* 
    FUNCTIONS
  */

  const handleOpenClientsNumberMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseClientsNumberMenu = () => {
    setAnchorEl(null);
  };

  const onSortEndClientsNumberItems = (oldIndex, newIndex) => {
    const items = arrayMove(clientsNumberItems, oldIndex, newIndex);

    SET_VALUE({
      target: {
        "clientsNumberItems": items
      }
    }, setSettings, "multi");
  };

  const addClientsNumberItem = (type) => {
    let newItem;
    if (type === "continuousNumber") {
      newItem = { id: clientsNumberItems.length + 1, type: type, value: "1" }
    } else {
      newItem = { id: clientsNumberItems.length + 1, type: type, value: "" }
    }

    SET_VALUE({
      target: {
        "clientsNumberItems": [
          ...clientsNumberItems,
          newItem
        ]
      }
    }, setSettings, "multi")
  };

  const changeClientsNumberItem = (id, type, value) => {
    
    if (type === "continuousNumber" && isNaN(value)) {
      setLocalValues({
        ...localValues,
        continuousNumberErr: true,
        continuousNumberErrMsg: "Bitte nur Zahlen verwenden"
      })
    }

    else if (type === "continuousNumber" && (value < 1)) {
      setLocalValues({
        ...localValues,
        continuousNumberErr: true,
        continuousNumberErrMsg: "Bitte nur Zahlen größer 0 verwenden"
      })
    }

    else if (type === "continuousNumber" && (value === "")) {
      setLocalValues({
        ...localValues,
        continuousNumberErr: true,
        continuousNumberErrMsg: "Bitte ausfüllen"
      })
    }

    else {
      setLocalValues({
        ...localValues,
        continuousNumberErr: false,
        continuousNumberErrMsg: ""
      })
    }

    // 1. Find the item with the provided id
    const currentIndex = clientsNumberItems.findIndex((item) => item.id === id);
    // 2. Set new value for the item
    let updatedItem = { ...clientsNumberItems[currentIndex], type: type, value: value};
    // 3. Update the item list with the updated item
    const newItems = [...clientsNumberItems];
    newItems[currentIndex] = updatedItem;
    setContinuousNumberErrorMsg("");
    SET_VALUE({
      target: {
        "clientsNumberItems": newItems
      }
    }, setSettings, "multi");
  };


  const removeclientsNumberItem = (id) => {
    const newItems = clientsNumberItems.filter((item) => item.id !== id);
    SET_VALUE({
      target: {
        "clientsNumberItems": newItems
      }
    }, setSettings, "multi");
  };

  const checkDisabledClientsNumberElements = (type) => {
    return ((typeof (settings.clientsNumberItems) === "string" ? JSON.parse(settings.clientsNumberItems) : settings.clientsNumberItems))
      .findIndex((elements) => elements.type === type) !== -1 ? true : false
  };

  const updateSettings = () => {
    if (!localValues.continuousNumberErr) {
      SET_VALUE({ target: { id: "submenuOpen", value: true } }, setValues); updateSettingsHandler(); handleClose();
    }
  }
  
  return (
    <>
    <IconButton
      aria-label="settings"
      style={{ color: "inherit" }}
      onClick={handleClickOpen}
    >
      <SettingsIcon fontSize="small" />
    </IconButton>
    <Dialog
      onClose={() => { cancelSettingsHandler(); handleClose() }}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <DialogTitle id="customized-dialog-title" onClose={() => { cancelSettingsHandler(); handleClose() }} style={{ display: "flex" }}>
        <SettingsIcon color="primary" /> {t("PAGE_INVOICES.settings")}
      </DialogTitle>
      <DialogContent dividers>

      <Typography variant="body2" component="div"><strong>Elemente von Kundennummern</strong></Typography>
      <Grid container item alignItems="center" style={{ paddingLeft: "30px", marginTop: "0", marginBottom: "10px" }}>
        <Grid container item alignItems="center" style={{ marginTop: "5px", marginBottom: "10px" }}>
          <Grid item container direction="row" >

            <SortableList onSortEnd={onSortEndClientsNumberItems} draggedItemClassName="dragged">
              {clientsNumberItems.map((item, i) => (
                <SortableItem key={item.id}>
                  <Grid container item direction="row" alignItems="center" className="sortable-chip">

                    {item.type === "text" &&
                      <Grid item container direction="row" alignItems="center" >
                        <DragIndicatorIcon fontSize="small" />
                        <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px" }}>{t("PAGE_INVOICES.elementText")}</Typography>
                        <Chip
                          style={{ width: "227px", justifyContent: "flex-start" }}
                          size="small"
                          label={<input className="badge-input" defaultValue={item.value !== "" ? item.value : ""} placeholder={`Text, Zahl oder Sonderzeichen ...`} onChange={(e) => changeClientsNumberItem(item.id, "text", e.target.value)} ></input>}
                          className="draggable-chip badge-input-wrapper"
                        />

                        <IconButton size="small" style={{ marginTop: "0px", marginRight: "5px" }} onClick={() => removeclientsNumberItem(item.id)} >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                    }

                    {item.type === "continuousNumber" &&
                      <Grid item container direction="row" alignItems="center" className="sortable-chip">
                        <DragIndicatorIcon fontSize="small" />
                        <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px" }}>{t("PAGE_INVOICES.elementContinuousNumberDescribed")} </Typography>
                        <Chip
                          style={{ width: "60px", justifyContent: "flex-start" }}
                          size="small"
                          label={<input defaultValue={item.value} value={item.value} className="badge-input" onChange={(e) => changeClientsNumberItem(item.id, "continuousNumber", e.target.value)} style={{ textAlign: "center", background: "transparent", border: "none" }}></input>}
                          className="draggable-chip badge-input-wrapper"
                        />
                        <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px", color: "red" }}>{localValues.continuousNumberErrMsg}</Typography>
                        {/* <IconButton size="small" style={{ marginTop: "0px", marginRight: "5px" }} onClick={() => removeinvoicesNumberItem(item.id)}>
                          <ClearIcon fontSize="small" />
                        </IconButton> */}
                      </Grid>
                    }

                    {item.type === "year" &&
                      <Grid item container direction="row" alignItems="center" className="sortable-chip" style={{ paddingTop: "3px", paddingBottom: "3px" }}>
                        <DragIndicatorIcon fontSize="small" />
                        <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px" }}>{t("PAGE_INVOICES.elementYear")}</Typography>
                        <IconButton size="small" style={{ marginTop: "0px", marginRight: "5px" }}>
                          <ClearIcon fontSize="small" onClick={() => removeclientsNumberItem(item.id)} />
                        </IconButton>
                      </Grid>
                    }

                    {item.type === "month" &&
                      <Grid item container direction="row" alignItems="center" className="sortable-chip" style={{ paddingTop: "3px", paddingBottom: "3px" }}>
                        <DragIndicatorIcon fontSize="small" />
                        <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px" }}>{t("PAGE_INVOICES.elementMonth")}</Typography>
                        <IconButton size="small" style={{ marginTop: "0px", marginRight: "5px" }}>
                          <ClearIcon fontSize="small" onClick={() => removeclientsNumberItem(item.id)} />
                        </IconButton>
                      </Grid>
                    }

                    {item.type === "day" &&
                      <Grid item container direction="row" alignItems="center" className="sortable-chip" style={{ paddingTop: "3px", paddingBottom: "3px" }}>
                        <DragIndicatorIcon fontSize="small" />
                        <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px" }}>{t("PAGE_INVOICES.elementDay")}</Typography>
                        <IconButton size="small" style={{ marginTop: "0px", marginRight: "5px" }}>
                          <ClearIcon fontSize="small" onClick={() => removeclientsNumberItem(item.id)} />
                        </IconButton>
                      </Grid>
                    }
                  </Grid>
                </SortableItem>
              ))}
            </SortableList>

            <Grid item container direction="row" alignItems="center" style={{ marginBottom: "5px" }}>
              <div className="button--xxs" aria-controls="simple-menu" aria-haspopup="true" onClick={handleOpenClientsNumberMenu}>
                <IconButton size="small" color="secondary" style={{ marginTop: "0px", marginRight: "5px" }}>
                  <AddCircleIcon fontSize="small" />
                </IconButton>

                <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px", fontWeight: "600" }}>{t("PAGE_INVOICES.addElement")}</Typography>
              </div>
              <Menu
                id="invoicesNumber-element-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseClientsNumberMenu}
              >
                <MenuItem
                  onClick={() => {
                    handleCloseClientsNumberMenu();
                    addClientsNumberItem("text");
                  }}
                >
                  <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px", fontWeight: "600" }}>{t("PAGE_INVOICES.elementText")} <span style={{ fontSize: "10px", fontWeight: "300" }}>{t("PAGE_INVOICES.elementTextPlaceholder")}</span></Typography>
                </MenuItem>

                <MenuItem
                  disabled={checkDisabledClientsNumberElements("continuousNumber")}
                  onClick={() => {
                    handleCloseClientsNumberMenu();
                    addClientsNumberItem("continuousNumber");
                  }}
                >
                  <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px", fontWeight: "600" }}>{t("PAGE_INVOICES.elementContinuousNumber")}</Typography>
                </MenuItem>

                {/* <MenuItem
                  disabled={checkDisabledClientsNumberElements("year")}
                  onClick={() => {
                    handleCloseClientsNumberMenu();
                    addClientsNumberItem("year");
                  }}
                >
                  <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px", fontWeight: "600" }}>{t("PAGE_INVOICES.elementYear")} <span style={{ fontSize: "10px", fontWeight: "300" }}>{t("PAGE_INVOICES.elementDatePlaceholder")}</span></Typography>
                </MenuItem>

                <MenuItem
                  disabled={checkDisabledClientsNumberElements("month")}
                  onClick={() => {
                    handleCloseClientsNumberMenu();
                    addClientsNumberItem("month");
                  }}
                >
                  <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px", fontWeight: "600" }}>{t("PAGE_INVOICES.elementMonth")} <span style={{ fontSize: "10px", fontWeight: "300" }}>{t("PAGE_INVOICES.elementDatePlaceholder")}</span></Typography>
                </MenuItem>

                <MenuItem
                  disabled={checkDisabledClientsNumberElements("day")}
                  onClick={() => {
                    handleCloseClientsNumberMenu();
                    addClientsNumberItem("day")
                  }}
                >
                  <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px", fontWeight: "600" }}>{t("PAGE_INVOICES.elementDay")} <span style={{ fontSize: "10px", fontWeight: "300" }}>{t("PAGE_INVOICES.elementDatePlaceholder")}</span></Typography>
                </MenuItem> */}
              </Menu>
            </Grid>
          </Grid>
        </Grid>

                    {clientsNumberItems.length !== 0 &&
                      <>
                        <Grid item container alignItems="center" style={{ marginBottom: "20px" }}>
                          <VisibilityIcon fontSize="small" style={{ marginTop: "0px", marginRight: "5px" }} />
                          <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px" }}>{t("PAGE_INVOICES.invoiceNumberPreview")} </Typography>
                          <Typography variant="body2" component="div" style={{ fontSize: "13px", paddingTop: "1px", marginRight: "5px", fontWeight: "500" }}>

                            {clientsNumberItems.map((item, i) => (
                              <span key={i}>
                                {(item.type === "text" || item.type === "continuousNumber") &&
                                  <span>{item.value}</span>
                                }

                                {item.type === "year" &&
                                  <span>{new Date().getFullYear()}</span>
                                }

                                {item.type === "month" &&
                                  <span>{new Date().getMonth() + 1}</span>
                                }

                                {item.type === "day" &&
                                  <span>{new Date().getDate()}</span>
                                }
                              </span>
                            ))}

                          </Typography>
                        </Grid>
                      </>
                    }
              </Grid>
      </DialogContent>

<DialogActions>
  <Button
    variant="outlined"
    size="small"
    onClick={() => {
      cancelSettingsHandler();
      handleClose();
      }}
    >
    {t("BUTTONS.cancel")}
  </Button>

  <Button
    variant="contained"
    color="primary"
    size="small"
    onClick={() => { updateSettings() }}
    startIcon={<SaveIcon style={{ marginRight: "5px" }} />}
  >
    {t("BUTTONS.save")}
  </Button>
</DialogActions>
</Dialog>
</>
  );
}

// import React, { useState } from "react";

// // IMPORT hooks & context
// import { useTranslation } from "react-i18next";

// // IMPORT components
// import SortableList, { SortableItem } from 'react-easy-sort'
// import arrayMove from "array-move";

// // IMPORT components MATERIAL UI
// import {
//   Typography,
//   Button,
//   Grid,
//   IconButton,
//   FormControlLabel,
//   Radio,
//   FormControl,
//   RadioGroup,
//   Chip,
//   Menu,
//   MenuItem,
//   Dialog
// } from "@material-ui/core";
// import { withStyles } from '@material-ui/core/styles';
// import MuiDialogTitle from '@material-ui/core/DialogTitle';
// import MuiDialogContent from '@material-ui/core/DialogContent';
// import MuiDialogActions from '@material-ui/core/DialogActions';

// // IMPORT icons MATERIAL UI
// import AddCircleIcon from '@material-ui/icons/AddCircle';
// import SettingsIcon from '@material-ui/icons/Settings';
// import ClearIcon from '@material-ui/icons/Clear';
// import SaveIcon from '@material-ui/icons/Save';
// import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import CloseIcon from '@material-ui/icons/Close';

// const styles = (theme) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(2),
//   },
//   closeButton: {
//     position: 'absolute',
//     right: theme.spacing(1),
//     top: theme.spacing(1),
//     color: theme.palette.grey[500],
//   },
// });

// const DialogContent = withStyles((theme) => ({
//   root: {
//     padding: theme.spacing(2),
//   },
// }))(MuiDialogContent);

// const DialogActions = withStyles((theme) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(1),
//   },
// }))(MuiDialogActions);

// const DialogTitle = withStyles(styles)((props) => {
//   const { children, classes, onClose, ...other } = props;
//   return (
//     <MuiDialogTitle disableTypography className={classes.root} {...other}>
//       <Typography variant="h6">{children}</Typography>
//       {onClose ? (
//         <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </MuiDialogTitle>
//   );
// });

// export default function ClientsSettings({
//   settings,
//   handleChange,
//   cancelSettingsHandler,
//   updateSettingsHandler,
//   SET_VALUE,
//   setSettings,
//   setValues
// }) {

//   // If clientNumberItems are received from DB -> it is  an array of objects stored AS STRING, afterwards it is not a string
//   const clientsNumberItems = typeof (settings.clientsNumberItems) === "string" ? JSON.parse(settings.clientsNumberItems) : settings.clientsNumberItems;

//   // HOOKS & CONTEXT
//   const { t } = useTranslation();

//   const [open, setOpen] = useState(false);
//   const [fullWidth] = useState(true);
//   const [maxWidth] = useState('sm');

//   const handleClickOpen = () => {
//     setOpen(true);
//   };
//   const handleClose = () => {
//     setOpen(false);
//     // DB_PATCH_SETTINGS(settingsAtom, auth, sendRequest, enqueueSnackbar, t); 
//   };

//   // LOCAL STATE (PURE)
//   const [continuousNumberErrorMsg, setContinuousNumberErrorMsg] = useState("");
//   const [anchorEl, setAnchorEl] = useState(null); // clientsNumer-elements-menu (open state)http://localhost:3000/

//   /* 
//     FUNCTIONS
//   */
//   const handleOpenClientsNumberMenu = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleCloseClientsNumberMenu = () => {
//     setAnchorEl(null);
//   };

//   const onSortEndClientsNumberItems = (oldIndex, newIndex) => {
//     const items = arrayMove(clientsNumberItems, oldIndex, newIndex);

//     SET_VALUE({
//       target: {
//         "clientsNumberItems": items
//       }
//     }, setSettings, "multi");
//   };

//   const addClientsNumberItem = (type) => {
//     let newItem;
//     if (type === "continuousNumber") {
//       newItem = { id: clientsNumberItems.length + 1, type: type, value: "1" }
//     } else {
//       newItem = { id: clientsNumberItems.length + 1, type: type, value: "" }
//     }

//     SET_VALUE({
//       target: {
//         "clientsNumberItems": [
//           ...clientsNumberItems,
//           newItem
//         ]
//       }
//     }, setSettings, "multi")
//   };

//   const changeClientsNumberItem = (id, type, value) => {
//     if (type === "continuousNumber" && isNaN(value)) {
//       setContinuousNumberErrorMsg("Nur Zahlen werden berücksichtigt.")
//       return
//     } else if (type !== "continuousNumber" || type === "continuousNumber" && !isNaN(value)) {
//       // 1. Find the item with the provided id
//       const currentIndex = clientsNumberItems.findIndex((item) => item.id === id);
//       // 2. Set new value for the item
//       const updatedItem = { ...clientsNumberItems[currentIndex], type: type, value: value };
//       // 3. Update the item list with the updated item
//       const newItems = [...clientsNumberItems];
//       newItems[currentIndex] = updatedItem;
//       setContinuousNumberErrorMsg("");
//       SET_VALUE({
//         target: {
//           "clientsNumberItems": newItems
//         }
//       }, setSettings, "multi");
//     }
//   };

//   const removeClientsNumberItem = (id) => {
//     const newItems = clientsNumberItems.filter((item) => item.id !== id);
//     SET_VALUE({
//       target: {
//         "clientsNumberItems": newItems
//       }
//     }, setSettings, "multi");
//   };

//   const checkDisabledClientsNumberElements = (type) => {
//     return ((typeof (settings.clientsNumberItems) === "string" ? JSON.parse(settings.clientsNumberItems) : settings.clientsNumberItems))
//       .findIndex((elements) => elements.type === type) !== -1 ? true : false
//   };

//   return (
//     <>
//     <IconButton
//       aria-label="settings"
//       style={{ color: "inherit" }}
//       onClick={handleClickOpen}
//     >
//       <SettingsIcon fontSize="small" />
//     </IconButton>
//     <Dialog
//       onClose={() => { cancelSettingsHandler(); handleClose() }}
//       aria-labelledby="customized-dialog-title"
//       open={open}
//       fullWidth={fullWidth}
//       maxWidth={maxWidth}
//     >
//       <DialogTitle id="customized-dialog-title" onClose={() => { cancelSettingsHandler(); handleClose() }} style={{ display: "flex" }}>
//         <SettingsIcon color="primary" /> {t("PAGE_CLIENTS.settings")}
//       </DialogTitle>
//       <DialogContent dividers>
//         <Grid container item >
//           <FormControl component="fieldset">
//             <Typography variant="body2" component="div"><strong>{t("PAGE_CLIENTS.settingsCreation")}</strong></Typography>
//             <RadioGroup aria-label="clientsNumber" name="clientsNumber" value={settings.clientsNumber} onChange={handleChange}>
//               <FormControlLabel value="automatic" control={<Radio color="primary" style={{ paddingTop: "5px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("PAGE_CLIENTS.settingsCreationAutomatic")}</Typography>} />
//               <FormControlLabel value="manual" control={<Radio color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("PAGE_CLIENTS.settingsCreationManual")}</Typography>} />
//               <FormControlLabel value="schema" control={<Radio color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("PAGE_CLIENTS.settingsCreationSchema")}</Typography>} />
//               {settings.clientsNumber === "schema" &&
//                 <>
//                   <Grid container item alignItems="center" style={{ marginLeft: "30px", marginTop: "0", marginBottom: "10px" }}>
//                     <Grid container item alignItems="center" style={{ marginTop: "5px", marginBottom: "10px" }}>
//                       <Grid item container direction="row" >
//                         <Grid item container direction="row" alignItems="center" style={{ marginBottom: "5px" }}>
//                           <div className="button--xxs" aria-controls="simple-menu" aria-haspopup="true" onClick={handleOpenClientsNumberMenu}>
//                             <IconButton size="small" color="secondary" style={{ marginTop: "0px", marginRight: "5px" }}>
//                               <AddCircleIcon fontSize="small" />
//                             </IconButton>

//                             <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px", fontWeight: "600" }}>{t("PAGE_CLIENTS.addElement")}</Typography>
//                           </div>
//                           <Menu
//                             id="clientsNumber-element-menu"
//                             anchorEl={anchorEl}
//                             keepMounted
//                             open={Boolean(anchorEl)}
//                             onClose={handleCloseClientsNumberMenu}
//                           >
//                             <MenuItem
//                               onClick={() => {
//                                 handleCloseClientsNumberMenu();
//                                 addClientsNumberItem("text");
//                               }}
//                             >
//                               <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px", fontWeight: "600" }}>{t("PAGE_CLIENTS.elementText")} <span style={{ fontSize: "10px", fontWeight: "300" }}>{t("PAGE_CLIENTS.elementTextPlaceholder")}</span></Typography>
//                             </MenuItem>

//                             <MenuItem
//                               disabled={checkDisabledClientsNumberElements("continuousNumber")}
//                               onClick={() => {
//                                 handleCloseClientsNumberMenu();
//                                 addClientsNumberItem("continuousNumber");
//                               }}
//                             >
//                               <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px", fontWeight: "600" }}>{t("PAGE_CLIENTS.elementContinuousNumber")}</Typography>
//                             </MenuItem>

//                             <MenuItem
//                               disabled={checkDisabledClientsNumberElements("year")}
//                               onClick={() => {
//                                 handleCloseClientsNumberMenu();
//                                 addClientsNumberItem("year");
//                               }}
//                             >
//                               <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px", fontWeight: "600" }}>{t("PAGE_CLIENTS.elementYear")} <span style={{ fontSize: "10px", fontWeight: "300" }}>{t("PAGE_CLIENTS.elementDatePlaceholder")}</span></Typography>
//                             </MenuItem>

//                             <MenuItem
//                               disabled={checkDisabledClientsNumberElements("month")}
//                               onClick={() => {
//                                 handleCloseClientsNumberMenu();
//                                 addClientsNumberItem("month");
//                               }}
//                             >
//                               <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px", fontWeight: "600" }}>{t("PAGE_CLIENTS.elementMonth")} <span style={{ fontSize: "10px", fontWeight: "300" }}>{t("PAGE_CLIENTS.elementDatePlaceholder")}</span></Typography>
//                             </MenuItem>

//                             <MenuItem
//                               disabled={checkDisabledClientsNumberElements("day")}
//                               onClick={() => {
//                                 handleCloseClientsNumberMenu();
//                                 addClientsNumberItem("day")
//                               }}
//                             >
//                               <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px", fontWeight: "600" }}>{t("PAGE_CLIENTS.elementDay")} <span style={{ fontSize: "10px", fontWeight: "300" }}>{t("PAGE_CLIENTS.elementDatePlaceholder")}</span></Typography>
//                             </MenuItem>
//                           </Menu>
//                         </Grid>

//                         <SortableList onSortEnd={onSortEndClientsNumberItems} draggedItemClassName="dragged">
//                           {clientsNumberItems.map((item, i) => (
//                             <SortableItem key={item.id}>
//                               <Grid container item direction="row" alignItems="center" className="sortable-chip">

//                                 {item.type === "text" &&
//                                   <Grid item container direction="row" alignItems="center" >
//                                     <DragIndicatorIcon fontSize="small" />
//                                     <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px" }}>{t("PAGE_CLIENTS.elementText")}</Typography>
//                                     <Chip
//                                       style={{ width: "227px", justifyContent: "flex-start" }}
//                                       size="small"
//                                       label={<input className="badge-input" defaultValue={item.value !== "" ? item.value : ""} placeholder={`Text, Zahl oder Sonderzeichen ...`} onChange={(e) => changeClientsNumberItem(item.id, "text", e.target.value)} ></input>}
//                                       className="draggable-chip badge-input-wrapper"
//                                     />

//                                     <IconButton size="small" style={{ marginTop: "0px", marginRight: "5px" }}>
//                                       <ClearIcon fontSize="small" onClick={() => removeClientsNumberItem(item.id)} />
//                                     </IconButton>
//                                   </Grid>
//                                 }

//                                 {item.type === "continuousNumber" &&
//                                   <Grid item container direction="row" alignItems="center" className="sortable-chip">
//                                     <DragIndicatorIcon fontSize="small" />
//                                     <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px" }}>{t("PAGE_CLIENTS.elementContinuousNumberDescribed")} </Typography>
//                                     <Chip
//                                       style={{ width: "60px", justifyContent: "flex-start" }}
//                                       size="small"
//                                       label={<input defaultValue={item.value !== "" ? item.value : "1"} className="badge-input" onChange={(e) => changeClientsNumberItem(item.id, "continuousNumber", e.target.value)} style={{ textAlign: "center", background: "transparent", border: "none" }}></input>}
//                                       className="draggable-chip badge-input-wrapper"
//                                     />
//                                     <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px", color: "red" }}>{continuousNumberErrorMsg}</Typography>
//                                     {/* <IconButton size="small" style={{ marginTop: "0px", marginRight: "5px" }}>
//                                       <ClearIcon fontSize="small" onClick={() => removeClientsNumberItem(item.id)} />
//                                     </IconButton> */}
//                                   </Grid>
//                                 }

//                                 {item.type === "year" &&
//                                   <Grid item container direction="row" alignItems="center" className="sortable-chip" style={{ paddingTop: "3px", paddingBottom: "3px" }}>
//                                     <DragIndicatorIcon fontSize="small" />
//                                     <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px" }}>{t("PAGE_CLIENTS.elementYear")}</Typography>
//                                     <IconButton size="small" style={{ marginTop: "0px", marginRight: "5px" }}>
//                                       <ClearIcon fontSize="small" onClick={() => removeClientsNumberItem(item.id)} />
//                                     </IconButton>
//                                   </Grid>
//                                 }

//                                 {item.type === "month" &&
//                                   <Grid item container direction="row" alignItems="center" className="sortable-chip" style={{ paddingTop: "3px", paddingBottom: "3px" }}>
//                                     <DragIndicatorIcon fontSize="small" />
//                                     <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px" }}>{t("PAGE_CLIENTS.elementMonth")}</Typography>
//                                     <IconButton size="small" style={{ marginTop: "0px", marginRight: "5px" }}>
//                                       <ClearIcon fontSize="small" onClick={() => removeClientsNumberItem(item.id)} />
//                                     </IconButton>
//                                   </Grid>
//                                 }

//                                 {item.type === "day" &&
//                                   <Grid item container direction="row" alignItems="center" className="sortable-chip" style={{ paddingTop: "3px", paddingBottom: "3px" }}>
//                                     <DragIndicatorIcon fontSize="small" />
//                                     <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px" }}>{t("PAGE_CLIENTS.elementDay")}</Typography>
//                                     <IconButton size="small" style={{ marginTop: "0px", marginRight: "5px" }}>
//                                       <ClearIcon fontSize="small" onClick={() => removeClientsNumberItem(item.id)} />
//                                     </IconButton>
//                                   </Grid>
//                                 }
//                               </Grid>
//                             </SortableItem>
//                           ))}
//                         </SortableList>
//                       </Grid>
//                     </Grid>

//                     {clientsNumberItems.length !== 0 &&
//                       <>
//                         <Grid item container alignItems="center" style={{ marginBottom: "20px" }}>
//                           <VisibilityIcon fontSize="small" style={{ marginTop: "0px", marginRight: "5px" }} />
//                           <Typography variant="body2" component="div" style={{ fontSize: "13px", marginRight: "5px" }}>{t("PAGE_CLIENTS.clientNumberPreview")} </Typography>
//                           <Typography variant="body2" component="div" style={{ fontSize: "13px", paddingTop: "1px", marginRight: "5px", fontWeight: "500" }}>

//                             {clientsNumberItems.map((item, i) => (
//                               <>
//                                 {(item.type === "text" || item.type === "continuousNumber") &&
//                                   <span>{item.value}</span>
//                                 }

//                                 {item.type === "year" &&
//                                   <span>{new Date().getFullYear()}</span>
//                                 }

//                                 {item.type === "month" &&
//                                   <span>{new Date().getMonth() + 1}</span>
//                                 }

//                                 {item.type === "day" &&
//                                   <span>{new Date().getDate()}</span>
//                                 }
//                               </>
//                             ))}

//                           </Typography>
//                         </Grid>
//                       </>
//                     }
//                   </Grid>
//                 </>
//               }
//             </RadioGroup>
//           </FormControl>
//         </Grid>

//         </DialogContent>

// <DialogActions>
//   <Button
//     variant="outlined"
//     size="small"
//     onClick={() => { cancelSettingsHandler(); handleClose() }}
//   >
//     {t("BUTTONS.cancel")}
//   </Button>

//   <Button
//     variant="contained"
//     color="primary"
//     size="small"
//     onClick={() => { SET_VALUE({ target: { id: "submenuOpen", value: true } }, setValues); updateSettingsHandler(); handleClose();}}
//     startIcon={<SaveIcon style={{ marginRight: "5px" }} />}
//   >
//     {t("BUTTONS.save")}
//   </Button>
// </DialogActions>
// </Dialog>
// </>
//   );
// }

import React, { useContext } from "react";
import { AuthContext } from '../../shared/context/auth-context';

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from '../components/CheckoutForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const options = {
  locale: "de",
}

const Checkout = () => {
    const auth = useContext(AuthContext);

  
  return (
  
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm />
    </Elements>
   
  );
};

export default Checkout;


import React, { useState, useEffect } from 'react';

// IMPORT hooks & context
import { useTranslation } from "react-i18next";

// IMPORT components MATERIAL UI
import {
  Typography,
  Button,
  Grid,
  IconButton,
  Dialog
} from "@material-ui/core";

// IMPORT icons MATERIAL UI
import SaveIcon from '@material-ui/icons/Save';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

// IMPORT icons MATERIAL UI
import CloseIcon from '@material-ui/icons/Close';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function InvoiceNumberEdit({
  openInvoiceNumberEdit,
  setOpenInvoiceNumberEdit,
  selectedClient,
  values,
  setValues,
}) {
  // HOOKS & CONTEXT
  const { t } = useTranslation();

  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');

  // const handleClickOpen = () => {

  //   SET_VALUE({ target: { id: "submenuOpen", value: "filter" } }, setValues);
  //   console.log(values)

  // };
  
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(values.invoiceNumber.state)
  }, [values]);

  const handleNewItems = () => {
    // const filteredState = items.filter(item =>
    //   !items.includes(item.id)
    // );
    setValues({
      ...values,
      invoiceNumber: {
        ...values.invoiceNumber,
          state: items
      }
    });
  };

  
  const moveItemUp = index => {
    console.log('Current Items:', items); // Log current state before changes

    setItems(currentItems => {
      if (index > 0 && currentItems.length > 1) { // Ensure there is an item to swap with
        const newItems = [...currentItems]; // Copy the array to maintain immutability
        // Swap the current item with the item above it
        let itemAbove = newItems[index - 1];
        let currentItem = newItems[index];

        newItems[index - 1] = currentItem;
        newItems[index] = itemAbove;

        console.log('New Items after Swap:', newItems); // Log new array after swap
        return newItems;
      }
      return currentItems;
    });
  };

  const moveItemDown = index => {
    setItems(currentItems => {
      if (index < currentItems.length - 1) {
        const newItems = [...currentItems];
        [newItems[index + 1], newItems[index]] = [newItems[index], newItems[index + 1]];
        return newItems;
      }
      return currentItems;
    });
  };
  
  return (
    <>
      <Dialog
        onClose={() => { setOpenInvoiceNumberEdit(false); setItems(values.invoiceNumber.state); }}
        aria-labelledby="customized-dialog-title"
        open={openInvoiceNumberEdit}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogTitle id="customized-dialog-title" onClose={() => { setOpenInvoiceNumberEdit(false);  setItems(values.invoiceNumber.state); }} style={{ display: "flex" }}>
          <CompareArrowsIcon color="primary" /> Elemente anordnen / entfernen
        </DialogTitle>
        <DialogContent dividers>
        {selectedClient !== "" &&
        <>
        <Grid item container xs={12} md={12}>
          <Grid item xs={12} md={12}>
            <Grid item style={{  marginBottom: "5px", wordBreak: "break-all"}}>
              <Typography>
                <span className="invoiceNumberHeadline" style={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.54)"}}>{(values.type === "invoice" || values.type === "reminder" || values.type === "reminderRequest") ? "Rechnungsnummer: " : values.type === "estimate" ? "Kostenvoranschlag Nr.: " : "Angebot Nr.: "}</span>
                <span style={{ fontSize: "12px" }}>
                {`
                ${(items).map((item, i) => (
                 item.value
                )).join("")}
                `}
                </span>
              </Typography>
            </Grid>

            <Grid container style={{ marginBottom: "5px", borderRadius: "4px"}}>
                {(items).map((item, i) => (
                <Grid key={item.id} item className="invoiceNumberItem--v3">
                  {item.type === "continuousNumber" &&
                  <div style={{ display: "flex", flexDirection: "row", border: "2px solid rgb(240, 243, 255)" }}>
                    <IconButton onClick={() => moveItemUp(i)} disabled={i === 0} color="primary" aria-label="add to shopping cart">
                      <ArrowUpwardIcon />
                    </IconButton>

                    <IconButton onClick={() => moveItemDown(i)} disabled={i === items.length -1} color="primary" aria-label="add to shopping cart">
                      <ArrowDownwardIcon />
                    </IconButton>
                    
                    <Grid container item direction="row" className="element" justifyContent="space-between" style={{ background: "rgba(33, 150, 243, 0.1)" }}>
                      <Grid item style={{ textAlign: "center", width: "100%" }}>
                        <Typography >
                          <input
                            disabled={true}
                            className="badge-input--invoicenr"
                            defaultValue={item.value}
                            placeholder={`Nr. ...`}
                            style={{ width: "100%", fontSize: "16px", textAlign: "center", paddingTop: "4px", paddingBottom: "2px"  }}
                            size={JSON.stringify(item.value).length}
                          />
                        </Typography>

                        <Typography style={{ fontSize: "10px" }}>Fortlaufende Nummer</Typography>
                      </Grid>
                    </Grid>

                    <IconButton disabled color="primary" aria-label="add to shopping cart">
                      <DeleteForeverIcon />
                    </IconButton>
                  </div>
                  }

                  {item.type === "text" &&
                  <div style={{ display: "flex", flexDirection: "row", border: "2px solid rgb(240, 243, 255)" }}>
                    <IconButton onClick={() => moveItemUp(i)} disabled={i === 0} color="primary" aria-label="add to shopping cart">
                      <ArrowUpwardIcon />
                    </IconButton>

                    <IconButton onClick={() => moveItemDown(i)} disabled={i === items.length -1} color="primary" aria-label="add to shopping cart">
                      <ArrowDownwardIcon />
                    </IconButton>

                    <Grid container item direction="row" className="element" justifyContent="space-between" style={{ background: "rgb(242 239 255)" }}>
                      <Grid item style={{  width: "100%", textAlign: "center" }}>
                        <Typography style={{ textAlign: "center" }}>
                          <input
                            disabled={true}
                            className="badge-input--invoicenr"
                            defaultValue={item.value}
                            placeholder={`Text ...`}
                            style={{ width: "100%", fontSize: "16px", textAlign: "center", paddingTop: "4px", paddingBottom: "2px"   }}
                            size={JSON.stringify(item.value).length}
                          />
                        </Typography>

                        <Typography style={{ fontSize: "10px" }}>Fixer Text</Typography>
                      </Grid>
                    </Grid>

                    <IconButton onClick={()=> setItems(currentItems => currentItems.filter(element => element.id !== item.id))}  color="primary" aria-label="add to shopping cart" >
                      <DeleteForeverIcon style={{ color: "red" }}/>
                    </IconButton>
                  </div>
                  }
                
                  {item.type === "year" &&
                  <div style={{ display: "flex", flexDirection: "row", border: "2px solid rgb(240, 243, 255)" }}>
                    <IconButton onClick={() => moveItemUp(i)} disabled={i === 0} color="primary" aria-label="add to shopping cart">
                      <ArrowUpwardIcon />
                    </IconButton>

                    <IconButton onClick={() => moveItemDown(i)} disabled={i === items.length -1} color="primary" aria-label="add to shopping cart">
                      <ArrowDownwardIcon />
                    </IconButton>

                    <Grid container item direction="row" className="element"  justifyContent="space-between" style={{ background: "rgb(251 239 255)" }}>
                      <Grid item style={{  width: "100%", textAlign: "center" }}>
                        <Typography
                          style={{ width: "100%", fontSize: "16px", fontWeight: "400", display: "flex", alignItems: "center" }}
                          className="badge-input--date">
                          <span style={{ width: "100%", textAlign: "center", paddingLeft: "15px", paddingRight: "15px" }}>
                              {item.value}
                          </span>
                        </Typography>

                        <Typography style={{ fontSize: "10px" }}>{(values.type === "invoice" || values.type === "reminder" || values.type === "reminderRequest") ? "Jahr des Rechnungsdatums" : "Datum (Jahr)"}</Typography>
                      </Grid>
                    </Grid>

                    <IconButton color="primary" aria-label="add to shopping cart">
                      <DeleteForeverIcon onClick={()=> setItems(currentItems => currentItems.filter(element => element.id !== item.id))} style={{ color: "red" }}/>
                    </IconButton>
                  </div>
                  }

                  {item.type === "month" &&
                  <div style={{ display: "flex", flexDirection: "row", border: "2px solid rgb(240, 243, 255)" }}>
                    <IconButton onClick={() => moveItemUp(i)} disabled={i === 0} color="primary" aria-label="add to shopping cart">
                      <ArrowUpwardIcon />
                    </IconButton>

                    <IconButton onClick={() => moveItemDown(i)} disabled={i === items.length -1} color="primary" aria-label="add to shopping cart">
                      <ArrowDownwardIcon />
                    </IconButton>

                    <Grid container item direction="row" className="element" justifyContent="space-between" style={{ background: "rgb(251 239 255)" }}>
                      <Grid item style={{  width: "100%", textAlign: "center" }}>
                        <Typography
                          style={{ width: "100%", fontSize: "16px", fontWeight: "400", display: "flex", alignItems: "center" }}
                          className="badge-input--date">
                          <span style={{ width: "100%", textAlign: "center", paddingLeft: "15px", paddingRight: "15px" }}>
                              {item.value}
                          </span>
                        </Typography>

                        <Typography style={{ fontSize: "10px" }}>{(values.type === "invoice" || values.type === "reminder" || values.type === "reminderRequest") ? "Monat des Rechnungsdatums" : "Datum (Monat)"}</Typography>
                      </Grid>
                    </Grid>

                    <IconButton color="primary" aria-label="add to shopping cart">
                      <DeleteForeverIcon onClick={()=> setItems(currentItems => currentItems.filter(element => element.id !== item.id))} style={{ color: "red" }}/>
                    </IconButton>
                  </div>
                  }

                  {item.type === "day" &&
                  <div style={{ display: "flex", flexDirection: "row", border: "2px solid rgb(240, 243, 255)" }}>
                    <IconButton onClick={() => moveItemUp(i)} disabled={i === 0} color="primary" aria-label="add to shopping cart">
                      <ArrowUpwardIcon />
                    </IconButton>

                    <IconButton onClick={() => moveItemDown(i)} disabled={i === items.length -1} color="primary" aria-label="add to shopping cart">
                      <ArrowDownwardIcon />
                    </IconButton>

                    <Grid container item direction="row" className="element"  justifyContent="space-between" style={{ background: "rgb(251 239 255)" }}>
                      <Grid item style={{  width: "100%", textAlign: "center" }}>
                        <Typography
                          style={{ width: "100%", fontSize: "16px", fontWeight: "400", display: "flex", alignItems: "center" }}
                          className="badge-input--date">
                          <span style={{ width: "100%", textAlign: "center", paddingLeft: "15px", paddingRight: "15px" }}>
                              {item.value}
                          </span>
                        </Typography>

                        <Typography style={{ fontSize: "10px" }}>{(values.type === "invoice" || values.type === "reminder" || values.type === "reminderRequest") ? "Tag des Rechnungsdatums" : "Datum (Tag)"}</Typography>
                      </Grid>
                    </Grid>

                    <IconButton color="primary" aria-label="add to shopping cart">
                      <DeleteForeverIcon onClick={()=> setItems(currentItems => currentItems.filter(element => element.id !== item.id))} style={{ color: "red" }}/>
                    </IconButton>
                  </div>
                  }
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        </>
        }
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            onClick={() => { setOpenInvoiceNumberEdit(false); setItems(values.invoiceNumber.state); }}
          >
            {t("BUTTONS.cancel")}
          </Button>

          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => { setOpenInvoiceNumberEdit(false); handleNewItems(); }}
            startIcon={<SaveIcon style={{ marginRight: "5px" }} />}
          >
            Änderungen anwenden
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

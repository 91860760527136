import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

const invoicesFilteredAtom = atom({
  key: "invoicesFiltered",
  default: [],
  // effects_UNSTABLE: [persistAtom],
});

export default invoicesFilteredAtom
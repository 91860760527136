import { useState, useCallback, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";


let logoutTimer;

export const useAuth = () => {
  const [userId, setUserId] = useState(false);
  const [token, setToken] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();

  const login = useCallback((
    uid, 
    token,
    expirationDate
    ) => {
      const decodedToken = jwtDecode(token);
      const expirationTime = decodedToken.exp;
      const expirationDateX = new Date(expirationTime * 1000);

      setUserId(uid);
      setToken(token);
      const tokenExpirationDate =
        // expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60);
        expirationDateX;
      setTokenExpirationDate(expirationDateX);
      localStorage.setItem(
        'userData',
        JSON.stringify({
          userId: uid,
          token: token,
          expiration: tokenExpirationDate.toISOString(),
          loggedIn: true
        })
      );
    }, []);

  const logout = useCallback(() => {
    setUserId(null);
    setToken(null);
    setTokenExpirationDate(null);
    localStorage.removeItem('userData');
    localStorage.removeItem('recoil-persist');
    window.location.href = '/';
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime = tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);

    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  // Additionaly
  // ADDED
  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime = tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, []);

  useEffect(() => {
    const checkLocalStorage = () => {
      const storedData = localStorage.getItem('userData');
      if (storedData) {
        // Do something with the data if local storage is not empty
        if(new Date(JSON.parse(storedData).expiration) < new Date()) {
          logout()
        }
      }
    };

    checkLocalStorage();
  }, [token]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('userData'));
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(storedData.userId, storedData.token, new Date(storedData.expiration));
    }
  }, [login]);

  return { userId, token, login, logout };
};
import { useEffect } from 'react';

const useInvoiceSetup = (invoicesAtom, settingsAtom, openInvoiceDialogAtom, clientsAtom, setValues, setSelectedClient, setItems, setShowGlobalDiscount, values) => {
  useEffect(() => {
    // If there are no invoices, avoid errors by setting length to 0
    let invoicesLength = invoicesAtom ? invoicesAtom.length : 0;

    // Collect invoice numbers in use. Fallback has to be an array with 1 entry -> 0 ... to avoid errors if there are no invoices.
    let arrInvoiceNumbersAll = [0]; // All numbers including alphanumerical numbers (saved as string)
    let arrInvoiceNumbers = [0]; // Only the pure numbers saved as string
    if (invoicesAtom) {
      invoicesAtom.forEach((item) => {
        arrInvoiceNumbersAll.push(item.invoiceNr);
        if (!isNaN(item.invoiceNr)) {
          arrInvoiceNumbers.push(item.invoiceNr);
        }
      });
    }

    // Sort collected invoice numbers from 0 to n.
    let arrInvoiceNumbersSorted = arrInvoiceNumbers.sort((a, b) => a - b);

    // Get length of sorted array of invoice numbers in use.
    let N = arrInvoiceNumbersSorted.length;

    let invoiceNumber;
    if (settingsAtom.invoicesNumber === "automatic") {
      invoiceNumber = JSON.parse(invoicesAtom[invoicesLength - 1].invoiceNr) + 1;
    } else if (settingsAtom.invoicesNumber === "schema") {
      invoiceNumber = [];
      (typeof settingsAtom.invoicesNumberItems === "string" ? JSON.parse(settingsAtom.invoicesNumberItems) : settingsAtom.invoicesNumberItems).forEach((item) => {
        if (item.type === "continuousNumber") {
          invoiceNumber.push(item);
        } else if (item.type === "year") {
          invoiceNumber.push({ id: item.id, type: "year", value: new Date().getFullYear() });
        } else if (item.type === "month") {
          invoiceNumber.push({ id: item.id, type: "month", value: new Date().getMonth() + 1 });
        } else if (item.type === "day") {
          invoiceNumber.push({ id: item.id, type: "day", value: new Date().getDate() });
        } else {
          invoiceNumber.push(item);
        }
      });
    } else {
      invoiceNumber = "";
    }

    setValues(() => {
      if (openInvoiceDialogAtom.invoiceId === "") {
        // If it is a NEW Invoice
        return ({
          ...values,
          invoiceNumberValues: {
            ...values.invoiceNumberValues,
            invoicesLength: invoicesLength,
            arrInvoiceNumbers: arrInvoiceNumbersAll,
          },
          invoiceNumber: {
            ...values.invoiceNumber,
            state: !openInvoiceDialogAtom.isEstimate ? invoiceNumber : [{ id: 1, type: 'continuousNumber', value: "" }],
          },
          headline: {
            ...values.headline,
            state: !openInvoiceDialogAtom.isEstimate ? "Rechnung" : "Kostenvoranschlag",
          },
          text: {
            ...values.text,
            state: (settingsAtom.invoicesText === undefined && !openInvoiceDialogAtom.isEstimate)
              ? "Wir erlauben uns, Ihnen folgenden Betrag in Rechnung zu stellen und freuen uns auf weitere erfolgreiche Zusammenarbeit."
              : openInvoiceDialogAtom.isEstimate
                ? "Vielen Dank für Ihr Interesse an unseren Leistungen. Im Folgenden finden Sie einen detaillierten Kostenvoranschlag, der speziell auf Ihre Anforderungen zugeschnitten ist."
                : settingsAtom.invoicesText,
          },
          blob: values.blob,
          type: openInvoiceDialogAtom.isEstimate === false ? "invoice" : "estimate",
          loading: false,
        });
      } else if (openInvoiceDialogAtom.invoiceId !== "" && openInvoiceDialogAtom.duplicate === false) {
        // If it is EDITING MODE of an existing invoice
        let invoice = invoicesAtom.filter((invoice) => invoice.id === openInvoiceDialogAtom.invoiceId)[0];

        setSelectedClient(clientsAtom.filter((client) => client.id === JSON.parse(invoice.clientData).id)[0]);
        setItems(JSON.parse(invoice.items));
        invoice.discount !== "none" && setShowGlobalDiscount(true);

        return ({
          ...values,
          invoiceId: invoice.id,
          invoiceNumberValues: {
            ...values.invoiceNumberValues,
            invoicesLength: invoicesLength,
            arrInvoiceNumbers: arrInvoiceNumbers,
          },
          initialInvoiceNumber: JSON.parse(invoice.invoiceNr).map((item) => item.value).join(""),
          invoiceNumber: {
            ...values.invoiceNumber,
            state: JSON.parse(invoice.invoiceNr),
          },
          status: {
            ...values.status,
            state: invoice.status,
          },
          sendState: {
            ...values.sendState,
            state: invoice.sendState,
          },
          payState: {
            ...values.payState,
            state: invoice.payState,
          },
          invoiceDate: new Date(invoice.invoiceDate),
          dueDate: new Date(invoice.dueDate),
          sendDate: new Date(invoice.sendDate),
          payDate: new Date(invoice.payDate),
          discount: invoice.discount,
          discountAbsolute: invoice.discountAbsolute,
          discountPercent: invoice.discountPercent,
          headline: {
            ...values.headline,
            state: invoice.headline === undefined ? "Rechnung" : invoice.headline,
          },
          text: {
            ...values.text,
            state: invoice.text,
          },
          blob: values.blob,
          sentBlobs: invoice.sentBlobs,
          type: invoice.type === undefined ? "invoice" : invoice.type,
          reminderRequestCharge: invoice.reminderRequestCharge === undefined ? "0" : invoice.reminderRequestCharge,
          showAdditionalText: invoice.showAdditionalText === undefined ? false : invoice.showAdditionalText,
          additionalText: invoice.additionalText === undefined ? "" : invoice.additionalText,
          loading: false,
        });
      } else if (openInvoiceDialogAtom.invoiceId !== "" && openInvoiceDialogAtom.duplicate === true) {
        // If it is DUPLICATE MODE of an existing invoice
        let invoice = invoicesAtom.filter((invoice) => invoice.id === openInvoiceDialogAtom.invoiceId)[0];

        setSelectedClient(clientsAtom.filter((client) => client.id === JSON.parse(invoice.clientData).id)[0]);
        setItems(JSON.parse(invoice.items));
        invoice.discount !== "none" && setShowGlobalDiscount(true);

        return ({
          ...values,
          invoiceNumberValues: {
            ...values.invoiceNumberValues,
            invoicesLength: invoicesLength,
            arrInvoiceNumbers: arrInvoiceNumbers,
          },
          invoiceNumber: {
            ...values.invoiceNumber,
            state: invoiceNumber,
          },
          status: {
            ...values.status,
            state: "draft",
          },
          sendState: {
            ...values.sendState,
            state: "notSent",
          },
          payState: {
            ...values.payState,
            state: "notPayed",
          },
          invoiceDate: new Date(),
          dueDate: new Date(),
          sendDate: new Date(),
          payDate: new Date(),
          discount: invoice.discount,
          discountAbsolute: invoice.discountAbsolute,
          discountPercent: invoice.discountPercent,
          headline: {
            ...values.headline,
            state: invoice.headline === undefined ? "Rechnung" : invoice.headline,
          },
          text: {
            ...values.text,
            state: invoice.text,
          },
          blob: "",
          type: invoice.type === undefined ? "invoice" : invoice.type,
          reminderRequestCharge: invoice.reminderRequestCharge === undefined ? "0" : invoice.reminderRequestCharge,
          loading: false,
        });
      }
    });
  }, []);
};

export default useInvoiceSetup;

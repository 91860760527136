export const DB_PATCH_SETTINGS = async (settingsAtom, auth, sendRequest, enqueueSnackbar, t, showSnack) => {
  try {
    const formData = new FormData();

    Object.entries(settingsAtom).forEach(([k, v]) => {
      if (k === "clientsNumberItems") {
        typeof(v) !== "string" ? formData.append(k, JSON.stringify(v)) : formData.append(k, v);
      } else if (k === "invoicesNumberItems") {
        typeof(v) !== "string" ? formData.append(k, JSON.stringify(v)) : formData.append(k, v);
      } else {
        formData.append(k, v);
      }
    });

    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL_API}/settings/${settingsAtom._id}`,
      "PATCH",
      formData,
      {
        Authorization: "Bearer " + auth.token,
      }
    )
    // NO ERROR
    .then(() => showSnack !== false && enqueueSnackbar(t("FIELDS.saved"), { variant: "success" }))
  } catch (err) { 
    // ERROR
    showSnack !== false && enqueueSnackbar(t("FIELDS.error"), { variant: "error" });
  }
};

export const de_PRODUCTS = {
    basic: {
        name: "BASIC",
        features: [
            ["+", "5 Kunden"],
            ["+", "10 Rechnungen / Monat"],
            ["-", "Rechnungsversand über eigene SMTP-Daten"],
            ["-", "Automatisierung regelmäßiger Rechnungen"],
            ["-", "Telefonischer Kundensupport"],
        ]
    },

    premium: {
        name: "PREMIUM",
        features: [
            ["+", "50 Kunden"],
            ["+", "100 Rechnungen / Monat"],
            ["+", "Rechnungsversand über eigene SMTP-Daten"],
            ["+", "Automatisierung regelmäßiger Rechnungen"],
            ["-", "Telefonischer Kundensupport"],
        ]
    },
    pro: {
        name: "PRO",
        features: [
            ["+", "Unbeschränkte Kundenanzahl"],
            ["+", "Unbeschränkte Rechnungen / Monat"],
            ["+", "Rechnungsversand über eigene SMTP-Daten"],
            ["+", "Automatisierung regelmäßiger Rechnungen"],
            ["+", "Telefonischer Kundensupport"],
        ]
    }
}

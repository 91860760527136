import React, { useContext, useEffect } from "react";
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import Logo from "../../_assets/logo_colored.svg";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { AuthContext } from '../../shared/context/auth-context';

const useStyles = makeStyles((theme) => ({
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    link: {
        margin: theme.spacing(1, 1.5),
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh"
    },
    cardHeader: {
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    },
    footer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },
}));

const tiers = [
    {
        title: 'PREMIUM',
        // subheader: 'Beliebt',
        price: '25',
        description: [
            '20 users included',
            '10 GB of storage',
            'Help center access',
            'Priority email support',
        ],
        buttonText: 'Auf Premium wechseln',
        buttonVariant: 'contained',
    },
    {
        title: 'PRO',
        price: '100',
        description: [
            '50 users included',
            '30 GB of storage',
            'Help center access',
            'Phone & email support',
        ],
        buttonText: 'Auf Pro Wechseln',
        buttonVariant: 'outlined',
    },
];



export default function Pricing() {
    const classes = useStyles();
    const auth = useContext(AuthContext);

    const [value, setValue] = React.useState('monthly');


      useEffect(() => {
        if (auth.userId) {
            const currentPriceData = async () => {
                try {
                    fetch(`${process.env.REACT_APP_BACKEND_URL_API}/payment/prices`, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + auth.token,
                        },
                    })
                        .then((response) => response.json())
                        .then((data) => {

                            console.log(data)

                        })
                        .catch((err) => {
                            console.log(err.message);
                        });
                } catch (err) { }
            };
            currentPriceData();
        }
    }, [auth.userId, auth.token]);

    const handleChange = (event) => {
        setValue(event.target.value);
    };


    return (
        <React.Fragment>
            <CssBaseline />
            <Container
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "100vh", 
                }}
            >
                <Grid container style={{ maxWidth: "760px", justifyContent: "center", flexDirection: "column" }}>
                    <img
                        src={Logo}
                        alt="Dagowert Logo"
                        style={{
                            marginTop: "20px",
                            maxHeight: "60px",
                        }}
                    />

                    <Typography variant="h5" align="center" color="textSecondary" component="p" style={{ marginTop: "20px" }}>
                        Kunden pflegen. Rechnung legen.
                        <br />
                        <br />
                    </Typography>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Zahlung</FormLabel>
                        <RadioGroup aria-label="subscription" name="subscription" value={value} onChange={handleChange}>
                            <FormControlLabel value="monthly" control={<Radio color="primary" />} label="Monatlich" />
                            <FormControlLabel value="yearly" control={<Radio color="primary" />} label="Jährlich (20% Rabatt)" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                {/* End hero unit */}
                <Container maxWidth="md" component="main">
                    <Grid container spacing={5} alignItems="flex-end">
                        {tiers.map((tier) => (
                            // Enterprise card is full width at sm breakpoint
                            <Grid item key={tier.title} xs={12} md={6}>
                                <Card>
                                    <CardHeader
                                        title={tier.title}
                                        subheader={tier.subheader}
                                        titleTypographyProps={{ align: 'center' }}
                                        subheaderTypographyProps={{ align: 'center' }}
                                        // action={tier.title === 'Premium' ? <React.Fragment><div style={{display: "flex", flexDirection: "row", alignItems: "center"}}><Typography variant="subtitle2" >Beliebt</Typography><div><StarIcon color="primary"/></div></div></React.Fragment> : null}
                                        className={classes.cardHeader}
                                    />
                                    <CardContent>
                                        <div className={classes.cardPricing}>
                                            <Typography component="h2" variant="h5" color="textPrimary" style={{ marginRight: "6px" }}>
                                                {`€ ${tier.price}`}
                                            </Typography>
                                            <Typography variant="subtitle2" color="textSecondary">
                                             / Monat *
                                            </Typography>
                                        </div>
                                        <ul>
                                            {tier.description.map((line) => (
                                                <Typography component="li" variant="subtitle1" align="center" key={line}>
                                                    {line}
                                                </Typography>
                                            ))}
                                        </ul>
                                    </CardContent>
                                    <CardActions>
                                        <Button size="large" style={{ padding: "15px" }} fullWidth variant={tier.buttonVariant} color="primary" endIcon={<ArrowForwardIcon />}>
                                            {tier.buttonText}
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                   
                </Container>
                <Typography variant="subtitle2" color="textSecondary" style={{marginTop: "50px"}}>* exkl. MwSt.</Typography>
            </Container>

        </React.Fragment>
    );
}
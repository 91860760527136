import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

const invoicesAtom = atom({
  key: "invoices",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export default invoicesAtom
import React from "react";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";

// IMPORT components MATERIAL UI
import {
  TextField,
  Grid,
  InputAdornment,
  IconButton
} from "@material-ui/core";

// IMPORT icons MATERIAL UI
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

import "./ClientsSearch.scss"


export default function ClientsSearch({ 
  values,
  clientsFilteredInitial,
  SET_VALUE,
  setValues,
  handleSearchClear
 }) {

  // HOOKS & CONTEXT
  const { t } = useTranslation();

  /* 
    FUNCTIONS
  */
  const handleSearch = (e) => {
    if (e.target.value !== "") {
      // Check if user empties search field without using the clear button (e.g. by using return on keyboard)
      let clientsSearched = clientsFilteredInitial.filter((client) => {
        return (
          client.company.toLowerCase().includes(e.target.value.toLowerCase()) ||
          // client.firstName.toLowerCase().includes(e.target.value.toLowerCase()) ||
          // client.lastName.toLowerCase().includes(e.target.value.toLowerCase()) ||
          client.clientNumber.toString().includes(e.target.value)
        );
      });

      SET_VALUE({
        target: {
          "searchInput": e.target.value,
          "clientsFiltered": clientsSearched
        }
      }, setValues, "multi")
    } else {
      handleSearchClear();
    }
  };

  return (
    <>
      <Grid
        container
        item
        direction="row"
        style={{ marginBottom: "10px" }}
        className="h-bg--white h-borderRadius--10 box-shadow--2 search"
      >
        <Grid
          container
          item
          lg={12}
        >
          <TextField
            id="searchInput"
            variant="filled"
            type="text"
            className="search-field"
            placeholder={t("PAGE_CLIENTS.searchPlaceholder")}
            value={values.searchInput}
            onChange={handleSearch}
            InputProps={{
              startAdornment: <InputAdornment position="start" ><SearchIcon color="primary" /></InputAdornment>,
              endAdornment:
                <InputAdornment position="end" style={{ marginRight: "5px"}}>
                  {values.searchInput !== "" &&
                    <IconButton
                      style={{ zoom: 0.8 }}
                      aria-label="clear search"
                      onClick={handleSearchClear}
                      edge="end"
                    >
                      <ClearIcon />
                    </IconButton>
                  }
                </InputAdornment>,
              inputProps: { style: { fontSize: "12px", background: '#ffffff', paddingTop: "14px", paddingBottom: "15px" } }
            }}
            hiddenLabel
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
}

import React, { useState } from 'react';

// IMPORT hooks & context
import { useTranslation } from "react-i18next";

// IMPORT components MATERIAL UI
import {
  Typography,
  Button,
  Grid,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  FormGroup,
  Checkbox,
  IconButton,
  Dialog
} from "@material-ui/core";

// IMPORT icons MATERIAL UI
import ImportExportIcon from '@material-ui/icons/ImportExport';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import ErrorIcon from '@material-ui/icons/Error';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


export default function InvoicesSort({
  settings,
  handleChange,
  cancelSettingsHandler={cancelSettingsHandler},
  updateSettingsHandler={updateSettingsHandler},
  SET_VALUE,
  setSettings,
  setValues,
  values,
  type
 }) {

  // HOOKS & CONTEXT
  const { t } = useTranslation();

  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');

  const handleClickOpen = () => {
    SET_VALUE({ target: { id: "submenuOpen", value: "sort" } }, setValues);
  };

  return (
    <>
      <IconButton
        aria-label="settings"
        style={{ color: "inherit" }}
        onClick={handleClickOpen}
      >
        <ImportExportIcon fontSize="small" />
      </IconButton>
      <Dialog
        onClose={() => { cancelSettingsHandler() }}
        aria-labelledby="customized-dialog-title"
        open={values.submenuOpen === "sort"}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogTitle id="customized-dialog-title" onClose={() => { cancelSettingsHandler() }} style={{ display: "flex" }}>
          <ImportExportIcon color="primary" /> Sortieren nach ...
        </DialogTitle>
        <DialogContent dividers>

        {/* <Grid container item alignItems="center" style={{ marginBottom: "20px" }}>
          <ImportExportIcon color="primary" style={{ marginLeft: "-2px", marginRight: "5px" }} />
          <Typography variant="h6" component="div">Sortieren nach ...</Typography>
        </Grid> */}

        {/* SORT: Creation point in time */}
        <Grid container item style={{ marginBottom: "10px" }}>
          <FormControl component="fieldset">
            <Grid item container direction="row" alignItems="center">
              <FormControl component="fieldset">
                <FormGroup aria-label="invoicesSortCreation" name="invoicesSortCreation"
                  onChange={(e) => {
                    SET_VALUE({
                      target: {
                        "invoicesSortCreation": true,
                        "invoicesSortInvoiceDate": false,
                        "invoicesSortDueDate": false,
                        "invoicesSortPayDate": false,
                        "invoicesSortNumber": false,
                        "invoicesSortCompany": false
                      }
                    }, setSettings, "multi")
                  }}
                >
                  <FormControlLabel
                    name="invoicesSortCreation" control={<Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} checked={settings.invoicesSortCreation} color="primary" style={{ paddingBottom: 0, paddingTop: 0 }} />}
                    style={{ marginBottom: "3px" }}
                    label={<Typography variant="body2" component="div"><strong>Erstellungszeitpunkt</strong></Typography>}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            {settings.invoicesSortCreation === true &&
            <RadioGroup aria-label="invoicesSortCreationOption" name="invoicesSortCreationOption" value={settings.invoicesSortCreationOption} onChange={handleChange} style={{ marginLeft: "32px" }}>
              <FormControlLabel value="chronological" control={<Radio size="small" disabled={!settings.invoicesSortCreation} color="primary" style={{ paddingTop: "0px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">Chronologisch</Typography>} />
              <FormControlLabel value="reverse-chronological" control={<Radio size="small" disabled={!settings.invoicesSortCreation} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">Umgekehrt chronologisch</Typography>} />
            </RadioGroup>
            }
          </FormControl>
        </Grid>

         {/* SORT: Invoice date */}
         <Grid container item style={{ marginBottom: "10px" }}>
          <FormControl component="fieldset">
            <Grid item container direction="row" alignItems="center">
              <FormControl component="fieldset">
                <FormGroup aria-label="invoicesSortInvoiceDate" name="invoicesSortInvoiceDate"
                  onChange={(e) => {
                    SET_VALUE({
                      target: {
                        "invoicesSortCreation": false,
                        "invoicesSortInvoiceDate": true,
                        "invoicesSortDueDate": false,
                        "invoicesSortPayDate": false,
                        "invoicesSortNumber": false,
                        "invoicesSortCompany": false
                      }
                    }, setSettings, "multi")
                  }}
                >
                  <FormControlLabel
                    name="invoicesSortInvoiceDate" control={<Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} checked={settings.invoicesSortInvoiceDate} color="primary" style={{ paddingBottom: 0, paddingTop: 0 }} />}
                    style={{ marginBottom: "3px" }}
                    label={<Typography variant="body2" component="div"><strong>{type === "invoices" ? "Rechnungsdatum" : "Datum"}</strong></Typography>}
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            {settings.invoicesSortInvoiceDate === true &&
            <RadioGroup aria-label="invoicesSortInvoiceDateOption" name="invoicesSortInvoiceDateOption" value={settings.invoicesSortInvoiceDateOption} onChange={handleChange} style={{ marginLeft: "32px" }}>
              <FormControlLabel value="chronological" control={<Radio size="small" disabled={!settings.invoicesSortInvoiceDate} color="primary" style={{ paddingTop: "0px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">Chronologisch</Typography>} />
              <FormControlLabel value="reverse-chronological" control={<Radio size="small" disabled={!settings.invoicesSortInvoiceDate} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">Umgekehrt chronologisch</Typography>} />
            </RadioGroup>
            }
          </FormControl>
        </Grid>

        {/* SORT: Due date */}
        {type === "invoices" &&
        <Grid container item style={{ marginBottom: "10px" }}>
          <FormControl component="fieldset">
            <Grid item container direction="row" alignItems="center">
              <FormControl component="fieldset">
                <FormGroup aria-label="invoicesSortDueDate" name="invoicesSortDueDate"
                  onChange={(e) => {
                    SET_VALUE({
                      target: {
                        "invoicesSortCreation": false,
                        "invoicesSortInvoiceDate": false,
                        "invoicesSortDueDate": true,
                        "invoicesSortPayDate": false,
                        "invoicesSortNumber": false,
                        "invoicesSortCompany": false
                      }
                    }, setSettings, "multi")
                  }}
                >
                  <FormControlLabel
                    name="invoicesSortDueDate" control={<Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} checked={settings.invoicesSortDueDate} color="primary" style={{ paddingBottom: 0, paddingTop: 0 }} />}
                    style={{ marginBottom: "3px" }}
                    label={<Typography variant="body2" component="div"><strong>Fälligkeitsdatum</strong></Typography>}
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            {settings.invoicesSortDueDate === true &&
            <RadioGroup aria-label="invoicesSortDueDateOption" name="invoicesSortDueDateOption" value={settings.invoicesSortDueDateOption} onChange={handleChange} style={{ marginLeft: "32px" }}>
              <FormControlLabel value="chronological" control={<Radio size="small" disabled={!settings.invoicesSortDueDate} color="primary" style={{ paddingTop: "0px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">Chronologisch</Typography>} />
              <FormControlLabel value="reverse-chronological" control={<Radio size="small" disabled={!settings.invoicesSortDueDate} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">Umgekehrt chronologisch</Typography>} />
            </RadioGroup>
            }
          </FormControl>
        </Grid>
        }


        {/* SORT: Pay date */}
        {type === "invoices" &&
        <Grid container item style={{ marginBottom: "10px" }}>
          <FormControl component="fieldset">
            <Grid item container direction="row" alignItems="center">
              <FormControl component="fieldset">
                <FormGroup aria-label="invoicesSortPayDate" name="invoicesSortPayDate"
                  onChange={(e) => {
                    SET_VALUE({
                      target: {
                        "invoicesSortCreation": false,
                        "invoicesSortInvoiceDate": false,
                        "invoicesSortDueDate": false,
                        "invoicesSortPayDate": true,
                        "invoicesSortNumber": false,
                        "invoicesSortCompany": false,
                        // The following lines are necessary as ordering according to payDate only should include payed invoices
                        // "invoicesFiltersStatusDraft": false,
                        // "invoicesFiltersStatusOverdue": false,
                        // "invoicesFiltersStatusPayed": true,
                        // "invoicesFiltersStatusScheduled": false,
                        // "invoicesFiltersStatusSent": false,
                      }
                    }, setSettings, "multi")
                  }}
                >
                  <FormControlLabel
                    name="invoicesSortPayDate" control={<Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} checked={settings.invoicesSortPayDate} color="primary" style={{ paddingBottom: 0, paddingTop: 0 }} />}
                    style={{ marginBottom: "3px" }}
                    label={<Typography variant="body2" component="div"><strong>Bezahldatum</strong></Typography>}
                  />
                  
                </FormGroup>
              </FormControl>
            </Grid>

            {settings.invoicesSortPayDate === true &&
            <RadioGroup aria-label="invoicesSortPayDateOption" name="invoicesSortPayDateOption" value={settings.invoicesSortPayDateOption} onChange={handleChange} style={{ marginLeft: "32px" }}>
              <Typography style={{ fontSize: "10px", marginBottom: "7px", display: "flex", alignItems: "center", padding: "2px", background: "rgb(234, 242, 234)", borderRadius: "4px"  }}><ErrorIcon style={{ zoom: 0.9, marginRight: "6px" }}/><span>Bei Sortierung nach Bezahldatum werden auschließlich Rechnungen mit dem Status 'bezahlt' angezeigt</span></Typography>
              <FormControlLabel value="chronological" control={<Radio size="small" disabled={!settings.invoicesSortPayDate} color="primary" style={{ paddingTop: "0px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">Chronologisch</Typography>} />
              <FormControlLabel value="reverse-chronological" control={<Radio size="small" disabled={!settings.invoicesSortPayDate} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">Umgekehrt chronologisch</Typography>} />
            </RadioGroup>
            }
          </FormControl>
        </Grid>
        }

        {/* SORT: number */}
        <Grid container item style={{ marginBottom: "10px" }}>
          <FormControl component="fieldset">
            <Grid item container direction="row" alignItems="center">
              <FormControl component="fieldset">
                <FormGroup aria-label="invoicesSortNumber" name="invoicesSortNumber"
                  onChange={(e) => {
                    SET_VALUE({
                      target: {
                        "invoicesSortNumber": true,
                        "invoicesSortCreation": false,
                        "invoicesSortInvoiceDate": false,
                        "invoicesSortDueDate": false,
                        "invoicesSortPayDate": false,
                        "invoicesSortCompany": false
                      }
                    }, setSettings, "multi")
                  }}
                >
                  <FormControlLabel
                    name="invoicesSortNumber" control={<Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} checked={settings.invoicesSortNumber} color="primary" style={{ paddingBottom: 0, paddingTop: 0 }} />}
                    style={{ marginBottom: "3px" }}
                    label={<Typography variant="body2" component="div"><strong>{type === "invoice" ? "Rechnungsnummer" : "Nummer"}</strong></Typography>}
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            {settings.invoicesSortNumber === true &&
            <RadioGroup aria-label="invoicesSortNumberOption" name="invoicesSortNumberOption" value={settings.invoicesSortNumberOption} onChange={handleChange} style={{ marginLeft: "32px" }}>
              <FormControlLabel value="ascending" control={<Radio size="small" disabled={!settings.invoicesSortNumber} color="primary" style={{ paddingTop: "0px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">Aufsteigend</Typography>} />
              <FormControlLabel value="descending" control={<Radio size="small" disabled={!settings.invoicesSortNumber} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">Absteigend</Typography>} />
            </RadioGroup>
            }
          </FormControl>
        </Grid>

        {/* SORT: Name of company */}
        <Grid container item style={{ marginBottom: "20px" }}>
          <FormControl component="fieldset">
            <Grid item container direction="row" alignItems="center">
              <FormControl component="fieldset">
                <FormGroup aria-label="invoicesSortCompany" name="invoicesSortCompany"
                  onChange={(e) => {
                    SET_VALUE({
                      target: {
                        "invoicesSortCompany": true,
                        "invoicesSortCreation": false,
                        "invoicesSortInvoiceDate": false,
                        "invoicesSortDueDate": false,
                        "invoicesSortPayDate": false,
                        "invoicesSortNumber": false
                      }
                    }, setSettings, "multi")
                  }}
                >
                  <FormControlLabel
                    name="invoicesSortCompany" control={<Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} checked={settings.invoicesSortCompany} color="primary" style={{ paddingBottom: 0, paddingTop: 0 }} />}
                    style={{ marginBottom: "3px" }}
                    label={<Typography variant="body2" component="div"><strong>Kundenname</strong></Typography>}
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            {settings.invoicesSortCompany === true &&
            <RadioGroup aria-label="invoicesSortCompanyOption" name="invoicesSortCompanyOption" value={settings.invoicesSortCompanyOption} onChange={handleChange} style={{ marginLeft: "32px" }}>
              <FormControlLabel value="ascending" control={<Radio size="small" disabled={!settings.invoicesSortCompany} color="primary" style={{ paddingTop: "0px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">Kundennamen (A - Z)</Typography>} />
              <FormControlLabel value="descending" control={<Radio size="small" disabled={!settings.invoicesSortCompany} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">Kundenamen (Z - A)</Typography>} />
            </RadioGroup>
            }
          </FormControl>
        </Grid>
      </DialogContent>

<DialogActions>
  <Button
    variant="outlined"
    size="small"
    onClick={() => { cancelSettingsHandler() }}
  >
    {t("BUTTONS.cancel")}
  </Button>

  <Button
    variant="contained"
    color="primary"
    size="small"
    onClick={() => { updateSettingsHandler() }}
    startIcon={<SaveIcon style={{ marginRight: "5px" }} />}
  >
    {t("BUTTONS.save")}
  </Button>
</DialogActions>
</Dialog>
</>
  );
}

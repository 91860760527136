export const DB_GET_EXPENSES = async (setExpensesAtom, auth, sendRequest) => {
  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL_API}/expenses/user/${auth.userId}`,
      "GET",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    )
    // NO ERROR
    .then((res) => setExpensesAtom(res.expenses))
  } catch (err) { 
    // ERROR
    console.log(err)
  }
};

export const DB_DELETE_EXPENSE = async (
  expense,
  auth,
  sendRequest,
  enqueueSnackbar,
  t,
  expensesAtom,
  setExpensesAtom,
  setShowConfirmModal
  ) => {
  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL_API}/expenses/${expense}`,
      "DELETE",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    )
    // NO ERROR
    .then(() => setShowConfirmModal(false))
    .then(() => setExpensesAtom(expensesAtom.filter((item) => item.id !== expense)))
    .then(() => enqueueSnackbar(t("FIELDS.saved"), { variant: "success" }))
  } catch (err) { 
    // ERROR
    enqueueSnackbar(t("FIELDS.error"), { variant: "error" })
  }
};


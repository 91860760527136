import React, { useEffect, useState, useContext } from "react";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { useAuth } from "../../shared/hooks/auth-hook";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import userAtomState from "../../_atoms/userAtom";
import clientsAtomState from "../../_atoms/clientsAtom";
import invoicesAtomState from "../../_atoms/invoicesAtom";
import openInvoiceDialogAtomState from "../../_atoms/openInvoiceDialogAtom";
import openInvoiceRecurringDialogAtomState from "../../_atoms/openInvoiceRecurringDialogAtom";
import invoicesrecurringAtomState from "../../_atoms/invoicesrecurringAtom";
import settingsAtomState from "../../_atoms/settingsAtom";

// IMPORT components
import { useSnackbar } from 'notistack';

// IMPORT components MATERIAL UI
import {
  Typography,
  Box,
  Container,
  Grid,
  Tooltip,
  Chip,
  Collapse,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import Alert from "@material-ui/lab/Alert";
import Fab from '@material-ui/core/Fab';


// IMPORT icons MATERIAL UI
import ClientsIcon from "@material-ui/icons/RecentActors";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import FilterListIcon from '@material-ui/icons/FilterList';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import EditIcon from '@material-ui/icons/Edit';
import ScheduleIcon from '@material-ui/icons/Schedule';
import MailIcon from '@material-ui/icons/Mail';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AddIcon from '@material-ui/icons/Add';


// IMPORT own functions
import { SET_VALUE } from '../../_functions/SET_VALUE';
import { DB_GET_CLIENTS } from '../../_functions/DB_CLIENTS';
import { DB_GET_INVOICES } from '../../_functions/DB_INVOICES';
import { DB_GET_INVOICESRECURRING } from '../../_functions/DB_INVOICESRECURRING';
import { DB_PATCH_SETTINGS } from '../../_functions/DB_SETTINGS';
import { RETURN_DATE } from '../../_functions/DATES';
import { RETURN_CLEARDATE } from '../../_functions/DATES';
import { RETURN_HOURS } from '../../_functions/DATES';
import { RETURN_OVERDUE_DAYS } from '../../_functions/DATES';

// IMPORT own components
import UserProfileIncompleteWarning from "../../user/components/UserProfileIncompleteWarning";
import InvoicesMenu from "../components/InvoicesMenu";
import InvoicesMenuView from "../components/InvoicesMenuView";
import InvoicesSearch from "../components/InvoicesSearch";
import InvoicesMenuDots from "../components/InvoicesMenuDots";
import LogoMonogram from './../../_assets/logo_monogram.svg';

// IMPORT own CSS
// import "./Invoices.scss"

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  table: {
    minWidth: 650,
  },
}));

const InvoicesRecurring = () => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { isLoading, error, sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const { userId } = useAuth();

  // GLOBAL STATE (RECOIL)
  const [userAtom] = useRecoilState(userAtomState);
  const [openInvoiceDialogAtom, setOpenInvoiceDialogAtom] = useRecoilState(openInvoiceDialogAtomState);
  const [openInvoiceRecurringDialogAtom, setOpenInvoiceRecurringDialogAtom] = useRecoilState(openInvoiceRecurringDialogAtomState);
  const [clientsAtom, setClientsAtom] = useRecoilState(clientsAtomState);
  const [invoicesAtom, setInvoicesAtom] = useRecoilState(invoicesAtomState);
  const [invoicesrecurringAtom, setInvoicesrecurringAtom] = useRecoilState(invoicesrecurringAtomState);
  const [settingsAtom, setSettingsAtom] = useRecoilState(settingsAtomState);

  // LOCAL STATE (GLOBAL DEPENDENCY monitoring temporay CHANGES until SAVED)
  const [settings, setSettings] = useState(settingsAtom);

  // LOCAL STATE (PURE)
  const [draggableSortItems, setDraggableSortItems] = useState([]);
  const [values, setValues] = useState({
    submenuOpen: false, // values: false, search, filter, sort, settings
    invoicesFiltered: {},
    searchInput: "",
    chipDirectChange: false,
    save: false
  });

  const [invoicesFilteredInitial, setInvoicesFilteredIntitial] = useState({});

  useEffect(() => {
    let invoicesFilteredInitial = invoicesrecurringAtom.filter((invoice) => {
      return (
        // Filter: Invoice status
        (
          settingsAtom.invoicesFiltersStatusDraft && (invoice.sendState !== "sent" && invoice.sendState !== "scheduled" && (invoice.payState === "notPayed" || invoice.payState === undefined)) || // draft
          settingsAtom.invoicesFiltersStatusScheduled && (invoice.sendState === "scheduled" && invoice.payState === "notPayed") || // scheduled
          settingsAtom.invoicesFiltersStatusSent && (invoice.sendState === "sent" && invoice.payState === "notPayed") || // sent
          settingsAtom.invoicesFiltersStatusPayed && invoice.payState === "payed" || // payed
          settingsAtom.invoicesFiltersStatusOverdue && (RETURN_OVERDUE_DAYS(invoice.dueDate) > 0 && invoice.payState === "notPayed") || // *** overdue ***
          
          // Reset invoice status filter (return all invoices when no invoice status filter is set)
          !settingsAtom.invoicesFiltersStatusDraft &&
          !settingsAtom.invoicesFiltersStatusScheduled &&
          !settingsAtom.invoicesFiltersStatusSent &&
          !settingsAtom.invoicesFiltersStatusPayed &&
          !settingsAtom.invoicesFiltersStatusOverdue
        )
        &&
        // Filter: Date range
        (
          settingsAtom.invoicesDateRange === "invoiceDate" && (
            ((RETURN_CLEARDATE(invoice.invoiceDate) >= RETURN_CLEARDATE(settingsAtom.invoicesDateRangeStartValue)) && (RETURN_CLEARDATE(invoice.invoiceDate) <= RETURN_CLEARDATE(settingsAtom.invoicesDateRangeEndValue)))) ||
          settingsAtom.invoicesDateRange === "dueDate" && (
            ((RETURN_CLEARDATE(invoice.dueDate) >= RETURN_CLEARDATE(settingsAtom.invoicesDateRangeStartValue)) && (RETURN_CLEARDATE(invoice.dueDate) <= RETURN_CLEARDATE(settingsAtom.invoicesDateRangeEndValue)))) ||
          (settingsAtom.invoicesDateRange === "payDate" && invoice.payState === "payed") && (
            ((RETURN_CLEARDATE(invoice.payDate) >= RETURN_CLEARDATE(settingsAtom.invoicesDateRangeStartValue)) && (RETURN_CLEARDATE(invoice.payDate) <= RETURN_CLEARDATE(settingsAtom.invoicesDateRangeEndValue)))) ||
          // Reset date range filter   
          settingsAtom.invoicesDateRange === "none"
        )
      );
    })

    // SORT: Creation point in time
    if (settingsAtom.invoicesSortCreation) {
      if (settingsAtom.invoicesSortCreationOption === "chronological") {
        invoicesFilteredInitial = invoicesFilteredInitial;
      } else if (settingsAtom.invoicesSortCreationOption === "reverse-chronological") {
        invoicesFilteredInitial = Array.from(invoicesFilteredInitial).reverse();
      }
    }

    // SORT: Client name alphabetically
    if (settingsAtom.invoicesSortCompany) {
      if (settingsAtom.invoicesSortCompanyOption === "ascending") {
        invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
          return (JSON.parse(a.clientData).company).toLowerCase() < (JSON.parse(b.clientData).company).toLowerCase() ? -1 : 1
        })
      } else if (settingsAtom.invoicesSortCompanyOption === "descending") {
        invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
          return (JSON.parse(a.clientData).company).toLowerCase() > (JSON.parse(b.clientData).company).toLowerCase() ? -1 : 1
        })
      }
    }

    // SORT: Invoice number
    if (settingsAtom.invoicesSortNumber) {
      if (settingsAtom.invoicesSortNumberOption === "ascending") {
        invoicesFilteredInitial = Array.from(invoicesFilteredInitial)
        // const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });

        // const output = invoicesFilteredInitial.sort((b, a) => collator.compare(b.invoiceNr, a.invoiceNr));
        // return output

        invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
          // Only check numbers (without letters) to order
          return (a.invoiceNr).replace(/^\D+/g, '') - (b.invoiceNr).replace(/^\D+/g, '')
        })
      } else if (settingsAtom.invoicesSortNumberOption === "descending") {
        invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
          // Only check numbers (without letters) to order
          return (b.invoiceNr).replace(/^\D+/g, '') - (a.invoiceNr).replace(/^\D+/g, '')
        })
      }
    }

    // SORT: Invoice date
    if (settingsAtom.invoicesSortInvoiceDate) {
      if (settingsAtom.invoicesSortInvoiceDateOption === "chronological") {
        invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
          return new Date(a.invoiceDate) - new Date(b.invoiceDate)
        })
      } else if (settingsAtom.invoicesSortInvoiceDateOption === "reverse-chronological") {
        invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
          return new Date(b.invoiceDate) - new Date(a.invoiceDate)
        })
      }
    }

    // SORT: Due date
    if (settingsAtom.invoicesSortDueDate) {
      if (settingsAtom.invoicesSortDueDateOption === "chronological") {
        invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
          return new Date(a.dueDate) - new Date(b.dueDate)
        })
      } else if (settingsAtom.invoicesSortDueDateOption === "reverse-chronological") {
        invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
          return new Date(b.dueDate) - new Date(a.dueDate)
        })
      }
    }

    // SORT: Pay date
    if (settingsAtom.invoicesSortPayDate) {
      if (settingsAtom.invoicesSortPayDateOption === "chronological") {
        invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
          return new Date(a.payDate) - new Date(b.payDate)
        }).filter((invoice) => {
          return (
            invoice.payState === "payed")
        })
      } else if (settingsAtom.invoicesSortPayDateOption === "reverse-chronological") {
        invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
          return new Date(b.payDate) - new Date(a.payDate)
        }).filter((invoice) => {
          return (
            invoice.payState === "payed")
        })
      }
    }

    setInvoicesFilteredIntitial(invoicesFilteredInitial)
  }, [invoicesAtom, settingsAtom]);


  useEffect(() => {
    // Only fetch from DB the first time the invoices page is loaded
    clientsAtom.length === 0 && DB_GET_CLIENTS(setClientsAtom, auth, sendRequest);
    invoicesAtom.length === 0 && DB_GET_INVOICES(setInvoicesAtom, auth, sendRequest);
  }, [userId, auth.token]);


  const handleSearchClear = () => {
    SET_VALUE({
      target: {
        "searchInput": "",
        "invoicesFiltered": invoicesFilteredInitial
      }
    }, setValues, "multi");
  };

  useEffect(() => {
    // Only fetch from DB the first time the clients page is loaded
    invoicesrecurringAtom.length === 0 && DB_GET_INVOICESRECURRING(setInvoicesrecurringAtom, auth, sendRequest);
  }, [userId, auth.token]);

  useEffect(() => {
    SET_VALUE({ target: { id: "invoicesFiltered", value: invoicesFilteredInitial } }, setValues);
  }, [JSON.stringify(invoicesFilteredInitial)]); // stringifying object dependency prevents infitive loop

  const handleChange = (e) => {
    SET_VALUE(e, setSettings);
  };

  // ON SAVE: 1) Update settingsAtom
  const updateSettingsHandler = () => {
    // GLOBAL changes
    setSettingsAtom(settings);

    // Save variable is only needed to trigger submenuClose when NOTHING changes in settings and save button is clicked
    SET_VALUE({ target: { id: "save", value: true } }, setValues);
  };

  // ON SAVE: 2) DB: PATCH SETTINGS
  useEffect(() => {

    if ((auth.token && values.submenuOpen !== false) || (auth.token && values.chipDirectChange === true)) {
      DB_PATCH_SETTINGS(settingsAtom, auth, sendRequest, enqueueSnackbar, t);

      SET_VALUE({
        target: {
          "submenuOpen": false,
          "chipDirectChange": false,
          "save": false,
        }
      }, setValues, "multi")
    }
  }, [auth.token, settingsAtom, values.save]);

  // ON CANCEL:
  const cancelSettingsHandler = () => {
    // Re-set original values
    setSettings(settingsAtom);
    SET_VALUE({ target: { id: "submenuOpen", value: false } }, setValues);
  };

  // Add to Sort Chips
  useEffect(() => {
    let i = []

    if (settingsAtom.invoicesSortCreation) {
      i.push("invoicesSortCreation")
    }
    if (settingsAtom.invoicesSortInvoiceDate) {
      i.push("invoicesSortInvoiceDate")
    }
    if (settingsAtom.invoicesSortDueDate) {
      i.push("invoicesSortDueDate")
    }
    if (settingsAtom.invoicesSortPayDate) {
      i.push("invoicesSortPayDate")
    }
    if (settingsAtom.invoicesSortNumber) {
      i.push("invoicesSortNumber")
    }
    if (settingsAtom.invoicesSortCompany) {
      i.push("invoicesSortCompany")
    }

    setDraggableSortItems(i)
  }, [settingsAtom]);

  return (
    <React.Fragment>
      <div className={classes.toolbar} />
      {userAtom.data.signupCompleted && clientsAtom.length !== 0 &&
        <React.Fragment>
          <Container maxWidth={false} disableGutters className="bg--1">
            <Container maxWidth="lg" style={{ paddingTop: "25px", paddingBottom: "100px" }}>
              <Grid container item justifyContent="space-between" alignItems="center" style={{ marginBottom: "5px" }}>
                <Grid item style={{ marginBottom: "5px", display: "flex" }}>
                  <Grid container item>
                    <Typography variant="h5" component="h1" style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
                      <LibraryBooksIcon color="primary" fontSize="large" style={{ marginRight: "5px" }} />
                      {t("PAGE_INVOICES.h1")}
                    </Typography>
                  </Grid>
                </Grid>

                <InvoicesMenu
                  values={values}
                  setValues={setValues}
                  SET_VALUE={SET_VALUE}
                  handleSearchClear={handleSearchClear}
                  settings={settings}
                  handleChange={handleChange}
                  cancelSettingsHandler={cancelSettingsHandler}
                  updateSettingsHandler={updateSettingsHandler}
                  setSettings={setSettings}
                />
              </Grid>

              <Box display="flex"
                flexDirection="column"
                justifyContent="center"
                
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ minHeight: "100vh" }}
                >
                  <Collapse in={values.submenuOpen === "search" ? true : false}>
                    <InvoicesSearch
                      values={values}
                      invoicesFilteredInitial={invoicesFilteredInitial}
                      SET_VALUE={SET_VALUE}
                      setValues={setValues}
                      handleSearchClear={handleSearchClear}
                    />
                  </Collapse>

                  <InvoicesMenuView />

                  <Grid container item xs={12} >
                    <Grid
                      container
                      item
                      xs={12}
                      className="h-bg--white h-borderRadiusTopRight--10 box-shadow--2 h-padding--5-5 tool-menu"
                      style={{ width: "100%", background: "#9cadc9", borderBottomRightRadius: "0px !important", padding: "20px 13px"}}
                    >   

                    {/* CHIPS: Date Range */}
                    {settingsAtom.invoicesDateRange !== "none" &&
                      <Grid container item xs={12} alignItems="center" style={{ marginTop: "2.5px", marginBottom: "2.5px",  }}>
                        <DateRangeIcon fontSize="small" style={{ marginTop: "-2.5px", marginRight: "5px", color: "#fff"  }} />
                        <Typography variant="body2" component="div" style={{ marginRight: "5px", fontSize: "13px", color: "#fff" }}>{t("PAGE_INVOICES.dateRangeSlim")}: </Typography>
                        <Chip
                          size="small"
                          label={
                            settings.invoicesDateRange === "invoiceDate" ? `${t("PAGE_INVOICES.invoiceDate")} ${RETURN_DATE(settingsAtom.invoicesDateRangeStartValue)} - ${RETURN_DATE(settingsAtom.invoicesDateRangeEndValue)}`
                              : settings.invoicesDateRange === "dueDate" ? `${t("PAGE_INVOICES.dueDate")} ${RETURN_DATE(settingsAtom.invoicesDateRangeStartValue)} - ${RETURN_DATE(settingsAtom.invoicesDateRangeEndValue)}`
                                : settings.invoicesDateRange === "payDate" ? `${t("PAGE_INVOICES.payDate")} ${RETURN_DATE(settingsAtom.invoicesDateRangeStartValue)} - ${RETURN_DATE(settingsAtom.invoicesDateRangeEndValue)}`
                                  : "keine Einschränkung"
                          }
                          onClick={() => SET_VALUE({ target: { id: "submenuOpen", value: "dateRange" } }, setValues)}
                          onDelete={() => {
                            SET_VALUE({ target: { id: "chipDirectChange", value: true } }, setValues);
                            SET_VALUE({ target: { id: "invoicesDateRange", value: "none" } }, setSettings);
                            SET_VALUE({ target: { id: "invoicesDateRange", value: "none" } }, setSettingsAtom);
                          }}
                          style={{ background: "#edefff", marginRight: "2.5px", fontSize: "10px" }}
                          className="draggable-chip"
                        />
                      </Grid>
                    }

                    {/* CHIPS: Filter */}
                    {(settingsAtom.invoicesFiltersStatusDraft || settingsAtom.invoicesFiltersStatusScheduled || settingsAtom.invoicesFiltersStatusSent || settingsAtom.invoicesFiltersStatusPayed || settingsAtom.invoicesFiltersStatusOverdue) &&
                      <Grid container item xs={12} alignItems="center" style={{ marginTop: "2.5px", marginBottom: "2.5px",  }}>
                        <FilterListIcon fontSize="small" style={{ marginTop: "-2.5px", marginRight: "5px", color: "#fff"  }} />
                        <Typography variant="body2" component="div" style={{ marginRight: "5px", fontSize: "13px", color: "#fff"  }}>Filter: </Typography>
                        {settingsAtom.invoicesFiltersStatusDraft &&
                          <Chip
                            size="small"
                            label={t("PAGE_INVOICES.filterStatusDraft")}
                            onDelete={() => {
                              SET_VALUE({ target: { id: "chipDirectChange", value: true } }, setValues);
                              SET_VALUE({ target: { id: "invoicesFiltersStatusDraft", value: false } }, setSettings);
                              SET_VALUE({ target: { id: "invoicesFiltersStatusDraft", value: false } }, setSettingsAtom);
                            }}
                            onClick={() => SET_VALUE({ target: { id: "submenuOpen", value: "filter" } }, setValues)}
                            style={{ color: "white !important", marginTop: "2.5px", marginBottom: "2.5px", marginRight: "2.5px", fontSize: "10px" }}
                            className="draggable-chip draft"
                          />
                        }
                        {settingsAtom.invoicesFiltersStatusScheduled &&
                          <Chip
                            size="small"
                            label={t("PAGE_INVOICES.filterStatusScheduled")}
                            onDelete={() => {
                              SET_VALUE({ target: { id: "chipDirectChange", value: true } }, setValues);
                              SET_VALUE({ target: { id: "invoicesFiltersStatusScheduled", value: false } }, setSettings);
                              SET_VALUE({ target: { id: "invoicesFiltersStatusScheduled", value: false } }, setSettingsAtom);
                            }}
                            onClick={() => SET_VALUE({ target: { id: "submenuOpen", value: "filter" } }, setValues)}
                            style={{ background: "#edefff", marginTop: "2.5px", marginBottom: "2.5px", marginRight: "2.5px", fontSize: "10px" }}
                            className="draggable-chip scheduled"
                          />
                        }
                        {settingsAtom.invoicesFiltersStatusSent &&
                          <Chip
                            size="small"
                            label={t("PAGE_INVOICES.filterStatusSent")}
                            onDelete={() => {
                              SET_VALUE({ target: { id: "chipDirectChange", value: true } }, setValues);
                              SET_VALUE({ target: { id: "invoicesFiltersStatusSent", value: false } }, setSettings);
                              SET_VALUE({ target: { id: "invoicesFiltersStatusSent", value: false } }, setSettingsAtom);
                            }}
                            onClick={() => SET_VALUE({ target: { id: "submenuOpen", value: "filter" } }, setValues)}
                            style={{ background: "#edefff", marginTop: "2.5px", marginBottom: "2.5px", marginRight: "2.5px", fontSize: "10px" }}
                            className="draggable-chip sent"
                          />
                        }
                        {settingsAtom.invoicesFiltersStatusPayed &&
                          <Chip
                            size="small"
                            label={t("PAGE_INVOICES.filterStatusPayed")}
                            onDelete={() => {
                              SET_VALUE({ target: { id: "chipDirectChange", value: true } }, setValues);
                              SET_VALUE({ target: { id: "invoicesFiltersStatusPayed", value: false } }, setSettings);
                              SET_VALUE({ target: { id: "invoicesFiltersStatusPayed", value: false } }, setSettingsAtom);
                            }}
                            onClick={() => SET_VALUE({ target: { id: "submenuOpen", value: "filter" } }, setValues)}
                            style={{ background: "#edefff", marginTop: "2.5px", marginBottom: "2.5px", marginRight: "2.5px", fontSize: "10px" }}
                            className="draggable-chip payed"
                          />
                        }
                        {settingsAtom.invoicesFiltersStatusOverdue &&
                          <Chip
                            size="small"
                            label={t("PAGE_INVOICES.filterStatusOverdue")}
                            onDelete={() => {
                              SET_VALUE({ target: { id: "chipDirectChange", value: true } }, setValues);
                              SET_VALUE({ target: { id: "invoicesFiltersStatusOverdue", value: false } }, setSettings);
                              SET_VALUE({ target: { id: "invoicesFiltersStatusOverdue", value: false } }, setSettingsAtom);
                            }}
                            onClick={() => SET_VALUE({ target: { id: "submenuOpen", value: "filter" } }, setValues)}
                            style={{ background: "#edefff", marginTop: "2.5px", marginBottom: "2.5px", marginRight: "2.5px", fontSize: "10px" }}
                            className="draggable-chip overdue"
                          />
                        }
                      </Grid>
                    }

                    {/* CHIPS: Sort */}
                    {(settingsAtom.invoicesSortCreation || settingsAtom.invoicesSortInvoiceDate || settingsAtom.invoicesSortDueDate || settingsAtom.invoicesSortPayDate || settingsAtom.invoicesSortNumber || settingsAtom.invoicesSortCompany) &&
                      <Grid container item xs={12} alignItems="center" style={{ marginTop: "2.5px", marginBottom: "2.5px",  }}>
                        <ImportExportIcon fontSize="small" style={{ marginRight: "5px", color: "#fff"  }} />
                        <Typography variant="body2" component="div" style={{ marginRight: "5px", fontSize: "13px", color: "#fff"  }}>Sortierung: </Typography>

                        {/* <SortableList onSortEnd={onSortEnd} className="list" draggedItemClassName="dragged"> */}
                        {draggableSortItems.map((item) => (
                          // <SortableItem key={item}>
                          <Chip
                            // icon={<DragIndicatorIcon />}
                            key={item}
                            size="small"
                            style={{ fontSize: "10px" }}
                            label={
                              item === "invoicesSortCreation" ?
                                settings.invoicesSortCreationOption === "chronological" ? t("PAGE_INVOICES.sortCreationChronologically") : t("PAGE_INVOICES.sortCreationChronologicallyReverse")
                                : item === "invoicesSortInvoiceDate" ?
                                  settings.invoicesSortInvoiceDateOption === "chronological" ? t("PAGE_INVOICES.sortInvoiceDateChronologically") : t("PAGE_INVOICES.sortInvoiceDateChronologicallyReverse")
                                  : item === "invoicesSortDueDate" ?
                                    settings.invoicesSortDueDateOption === "chronological" ? t("PAGE_INVOICES.sortDueDateChronologically") : t("PAGE_INVOICES.sortDueDateChronologicallyReverse")
                                    : item === "invoicesSortPayDate" ?
                                      settings.invoicesSortPayDateOption === "chronological" ? t("PAGE_INVOICES.sortPayDateChronologically") : t("PAGE_INVOICES.sortPayDateChronologicallyReverse")
                                      : item === "invoicesSortNumber" ?
                                        settings.invoicesSortNumberOption === "ascending" ? t("PAGE_INVOICES.sortInvoiceNumbersAsc") : t("PAGE_INVOICES.sortInvoiceNumbersDesc")
                                        : item === "invoicesSortCompany" &&
                                          settings.invoicesSortCompanyOption === "ascending" ? t("PAGE_INVOICES.sortCompanyAsc") : t("PAGE_INVOICES.sortCompanyDesc")
                            }
                            onClick={() => SET_VALUE({ target: { id: "submenuOpen", value: "sort" } }, setValues)}
                            className="draggable-chip"
                          />
                          // </SortableItem>

                        ))}
                        {/* </SortableList> */}
                      </Grid>
                    }

                    {(settings.invoicesSortPayDate || settings.invoicesDateRange === "payDate") &&
                      <Alert icon={false} severity="success" style={{ marginTop: "10px", width: "100%" }}>
                        <Typography style={{fontSize: "13px"}}>Bei Sortierung oder Zeitraumbeschränkung nach BEZAHLDATUM werden ausschließlich Rechnungen mit dem Status 'bezahlt' angezeigt.</Typography>
                      </Alert>
                    }


                      {/* <Button
                          className="add-invoice"
                          variant="contained"
                          size="large"
                          startIcon={<AddCircleIcon >send</AddCircleIcon>}
                          onClick={() => setOpenInvoiceDialogAtom({
                            ...openInvoiceDialogAtom,
                            open: true,
                          })}
                        >
                          Rechnung erstellen
                      </Button> */}

                    </Grid>

                    <div className="box-shadow--2" style={{ width: "100%" }}>
                      <Grid
                        item
                        className="sticky-nav box-shadow--2 h-padding--5-5 tool-menu"
                      >
                        <Grid container>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                            item
                            style={{ paddingLeft: "19px"}}
                            xs={12}
                          >
                            <Grid container item xs={11} justifyContent="space-between">
                              <Grid container item alignItems="center" xs={12} lg={4}>
                                <div>
                                  <Typography>Unternehmen</Typography>
                                </div>
                              </Grid>

                              <Grid container item justifyContent="flex-start" alignItems="center" xs={12} lg={8}>
                                <Grid container item xs={12} md={2} justifyContent="center">
                                  <Typography>Betrag</Typography>
                                </Grid>

                                <Grid container item xs={4} md={2} justifyContent="center">
                                  <Typography>R-Nr.</Typography>
                                </Grid>

                                <Grid container item xs={4} md={2} justifyContent="center">
                                  <Typography>R-Datum</Typography>
                                </Grid>

                                <Grid container item xs={4} md={2} justifyContent="center">
                                  <Typography>Fälligkeit</Typography>
                                </Grid>

                                <Grid container item xs={4} md={1} justifyContent="center">
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid container item xs={1} justifyContent="flex-end">
                            </Grid>
                          </Grid>
                        </Grid>
                      
                      </Grid>

                      {(!isLoading) &&
                        <>
                          {/* INVOICES already created */}
                          {invoicesAtom.length !== 0 &&
                            <>
                              {/* APPLIED FILTER after Loading doesn't result in list of length 0 */}
                              {Object.keys(values.invoicesFiltered).length !== 0 &&
                                (values.invoicesFiltered).map((invoice, i) => (
                                  <Grid container key={i}>
                                    <Grid
                                      container
                                      alignItems="center"
                                      justifyContent="space-between"
                                      item
                                      xs={12}
                                      className="h-bg--white user-clients"
                                      style={{
                                        borderBottom: "1px solid #e2ecfd",
                                        borderLeft:
                                          (invoice.sendState !== "sent" && invoice.sendState !== "scheduled" && (invoice.payState === "notPayed" || invoice.payState === undefined)) ? `5px solid #dbdef5` :
                                            (invoice.sendState === "scheduled" && invoice.payState === "notPayed") ? `5px solid ${theme.palette.warning.light}` :
                                              (invoice.sendState === "sent" && invoice.payState === "notPayed") ? `5px solid ${theme.palette.primary.dark}` :
                                                `5px solid ${theme.palette.success.light}`
                                      }}
                                    >

                                      <Grid container item xs={11} justifyContent="space-between">
                                        <Grid container item alignItems="center" xs={12} lg={4}
                                          className="image-name-company"
                                          style={{ padding: "30px 15px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", flexWrap: "nowrap" }}
                                          onClick={() => setOpenInvoiceDialogAtom({
                                            ...openInvoiceDialogAtom,
                                            open: true,
                                            invoiceId: invoice.id
                                          })}
                                        >
                                          <div style={{ width: "50px", height: "25px", marginRight: "10px", position: "relative" }}>
                                            {(((clientsAtom).find(obj => obj._id === JSON.parse(invoice.clientData).logo)) === "") ? (
                                              <img
                                                // src={`${process.env.REACT_APP_BACKEND_URL}/${JSON.parse(invoice.clientData).image}`}
                                                src={`${process.env.REACT_APP_BACKEND_URL}/${((clientsAtom).find(obj => obj._id === JSON.parse(invoice.clientData)._id)).image}`}
                                                alt="logo"
                                                style={{
                                                  maxWidth: "100%",
                                                  maxHeight: "100%",
                                                  marginRight: "10px",
                                                  position: "absolute",
                                                  top: "50%",
                                                  left: "50%",
                                                  transform: "translate(-50%, -50%)"
                                                }}
                                              />
                                            ) :
                                              <img src={LogoMonogram} height={25} width={25} alt={`${t("APP.name")} Logo`} style={{ width: "50px", marginRight: "10px", opacity: 0.2 }} />
                                            }
                                          </div>

                                          <Typography component="div" variant="body2" style={{ fontWeight: "500", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{JSON.parse(invoice.clientData).company}</Typography>
                                        </Grid>

                                        <Grid container item justifyContent="flex-start" alignItems="center" xs={12} lg={8}
                                          className="invoice-meta"
                                          style={{padding: "30px 15px", }}
                                          onClick={() => setOpenInvoiceDialogAtom({
                                            ...openInvoiceDialogAtom,
                                            open: true,
                                            invoiceId: invoice.id
                                          })}
                                        >
                                          <Grid container item xs={12} md={2} justifyContent="center" className="invoice-total" style={{ background: "rgba(33, 150, 243, 0.1)", borderRadius: "4px", paddingTop: "5px", paddingBottom: "5px" }}>
                                            <Tooltip title={`${t("PAGE_INVOICES.total")} brutto`}>
                                              <Typography component="div" variant="body2" style={{ fontWeight: "500", fontSize: "12px" }}>{parseFloat(invoice.total).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €</Typography>
                                            </Tooltip>
                                          </Grid>

                                          <Grid container item xs={4} md={2} justifyContent="center">
                                            <Typography style={{ textAlign: "center", fontSize: "10px" }} component="div" variant="body2"><span className="menu-heading-title">R-Nr.<br /></span><span style={{ fontWeight: "600" }}>{invoice.invoiceNr}</span></Typography>
                                          </Grid>

                                          <Grid container item xs={4} md={2} justifyContent="center">
                                            <Typography style={{ textAlign: "center", fontSize: "10px" }} component="div" variant="body2"><span className="menu-heading-title">R-Datum<br /></span><span style={{ fontWeight: "600" }}>{RETURN_DATE(invoice.invoiceDate)}</span></Typography>
                                          </Grid>

                                          <Grid container item xs={4} md={2} justifyContent="center">
                                            <Typography component="div" variant="body2" style={{ textAlign: "center", fontSize: "10px" }}><span className="menu-heading-title">Fällig am<br /></span><span style={{ fontWeight: "600" }}>{RETURN_DATE(invoice.dueDate)}<br /><span style={{ color: theme.palette.error.main }}></span></span></Typography>
                                          </Grid>

                                          <Grid container item xs={12} md={1} justifyContent="center" className="invoice-overdue">
                                            <Typography component="div" variant="body2" style={{ textAlign: "center", fontSize: "10px" }}><span style={{ fontWeight: "600" }}><span style={{ color: theme.palette.error.main }}>{(RETURN_OVERDUE_DAYS(invoice.dueDate) > 0 && invoice.payState === "notPayed") && `${RETURN_OVERDUE_DAYS(invoice.dueDate)} Tag${RETURN_OVERDUE_DAYS(invoice.dueDate) > 1 ? "e" : ""} überfällig`}</span></span></Typography>
                                          </Grid>


                                          <Grid container item xs={12} md={3} alignItems="center" className="invoice-status">
                                              {(invoice.sendState !== "sent" && invoice.sendState !== "scheduled" && (invoice.payState === "notPayed" || invoice.payState === undefined)) &&
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", background: "#dbdef5 ", marginRight: "5px", width: "25px", height: "25px", borderRadius: "60px" }}>
                                                  <EditIcon style={{ fontSize: "14px", color: "white" }} />
                                                </div>
                                              }

                                              {(invoice.sendState === "scheduled" && invoice.payState === "notPayed") &&
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", background: theme.palette.warning.light, marginRight: "5px", width: "25px", height: "25px", borderRadius: "60px" }}>
                                                  <ScheduleIcon style={{ fontSize: "16px", color: "white" }} />
                                                </div>
                                              }

                                              {(invoice.sendState === "sent" && invoice.payState === "notPayed") &&
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", background: theme.palette.primary.dark, marginRight: "5px", width: "25px", height: "25px", borderRadius: "60px" }}>
                                                  <MailIcon style={{ fontSize: "14px", color: "white" }} />
                                                </div>
                                              }

                                              {invoice.payState === "payed" &&
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", background: theme.palette.success.light, marginRight: "5px", width: "25px", height: "25px", borderRadius: "60px" }}>
                                                  <CheckCircleIcon style={{ fontSize: "17px", color: "white" }} />
                                                </div>
                                              }

                                              {/* draft */}
                                              {(invoice.sendState !== "sent" && invoice.sendState !== "scheduled" && (invoice.payState === "notPayed" || invoice.payState === undefined)) &&
                                                <Typography component="div" variant="body2" style={{ textAlign: "left", fontSize: "10px" }}><span style={{ fontWeight: "600" }}>Entwurf</span></Typography>
                                              }

                                              {/* scheduled */}
                                              {(invoice.sendState === "scheduled" && invoice.payState === "notPayed") &&
                                                <Typography component="div" variant="body2" style={{ textAlign: "left", fontSize: "10px" }}><span>Wird gesendet am </span><br /><span style={{ fontWeight: "600" }}>{`${RETURN_DATE(invoice.sendDate)} um ${RETURN_HOURS(invoice.sendDate)}`}</span></Typography>
                                              }

                                              {/* sent */}
                                              {(invoice.sendState === "sent" && invoice.payState === "notPayed") &&
                                                <Typography component="div" variant="body2" style={{ textAlign: "left", fontSize: "10px" }}><span>Gesendet am</span><br /><span style={{ fontWeight: "600" }}>{RETURN_DATE(invoice.sendDate)}</span></Typography>
                                              }

                                              {/* payed */}
                                              {invoice.payState === "payed" &&
                                                <Typography component="div" variant="body2" style={{ textAlign: "left", fontSize: "10px" }}><span>Bezahlt am</span><br /><span style={{ fontWeight: "600" }}>{RETURN_DATE(invoice.payDate)}</span></Typography>
                                              }
                                          </Grid>
                                        </Grid>
                                      </Grid>

                                      <Grid container item xs={1} justifyContent="flex-end">
                                        <InvoicesMenuDots
                                          invoice={invoice}
                                          setOpenInvoiceDialogAtom={setOpenInvoiceDialogAtom}
                                          openInvoiceDialogAtom={openInvoiceDialogAtom}
                                        />
                                      </Grid>

                                    </Grid>
                                  </Grid>
                                ))
                              }

                              {/* APPLIED FILTER after Loading does result in list of length 0 */}
                              {Object.keys(values.invoicesFiltered).length === 0 &&
                                <Typography component="div"><i>{t("PAGE_INVOICES.noResult")}</i></Typography>
                              }
                            </>
                          }

                          {/* NO INVOICES created yet */}
                          {invoicesAtom.length === 0 &&
                            <Typography component="div"><i>{t("PAGE_INVOICES.noInvoicesCreated")}</i></Typography>
                          }
                        </>
                      }
                    </div>
                  </Grid>
                  

                </Box>
              </Box>
            </Container>
          </Container>
        </React.Fragment>
      }

      {!userAtom.data.signupCompleted &&
        <UserProfileIncompleteWarning
          headline={t("PAGE_INVOICES.h1")}
          text={t("PAGE_INVOICES.profileIncomplete")}
          icon={<ClientsIcon color="primary" fontSize="large" style={{ marginRight: "5px" }} />}
        />
      }

      {userAtom.data.signupCompleted && clientsAtom.length === 0 &&
        <UserProfileIncompleteWarning
          headline={t("PAGE_INVOICES.h1")}
          text={t("PAGE_INVOICES.noClients")}
          icon={<ClientsIcon color="primary" fontSize="large" style={{ marginRight: "5px" }} />}
          link={"/clients"}
          linkText={t("BUTTONS.back_to_clients")}
        />
      }
        <Fab color="primary" aria-label="add" className="add-invoice-fab" style={{ position: "fixed", bottom: "0", right: "0", margin: "20px"}}
          onClick={() => setOpenInvoiceRecurringDialogAtom({
            ...openInvoiceRecurringDialogAtom,
            open: true,
          })}
        >
        <AddIcon />
      </Fab>
    </React.Fragment>
  );
};

export default InvoicesRecurring;

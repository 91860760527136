import React, { useState, useEffect, useContext } from "react";

// IMPORT hooks & context
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/auth-context";
import { useAuth } from "../../../shared/hooks/auth-hook";

// IMPORT components MATERIAL UI
import {
  Typography,
  Button,
  Box,
  Container,
  Grid,
  makeStyles,
  Link,
} from "@material-ui/core";

// IMPORT icons MATERIAL UI
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
}));

const AccountTransactions = () => {
  // HOOKS & CONTEXT
  const classes = useStyles();
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const { userId } = useAuth();


  // LOCAL
  const [transactions, setTransactions] = useState([]);

  /* 
    Functions
  */
  useEffect(() => {
    if (auth.token) {
      getTransactions();
    }
  }, [userId]);


  const getTransactions = async (event) => {

    const formData = new FormData();
    formData.append("id", userId);
    formData.append("image", "#");

    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL_API}/users/account-transactions`,
        "POST",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      )
      // NO ERROR
      .then((res) => {
        console.log(res.transactions.data);
        setTransactions(res.transactions.data)
        })
    } catch (err) { 
      console.log(err)
    }
  };


  return (
    <React.Fragment>
      <div className={classes.toolbar} />
      <Container maxWidth={false} disableGutters className="bg--1">
        <Container maxWidth="lg" style={{ paddingTop: "25px", paddingBottom: "150px" }}>
          <Grid container item direction="row" alignItems="center" style={{ marginBottom: "20px" }}>
            <AccountBalanceWalletIcon color="primary" fontSize="large" style={{ marginRight: "5px" }} />
            <Typography variant="h5" component="h1" color="textSecondary">Transaktionen (Rechnungen)</Typography>
          </Grid>

          <Box display="flex"
            flexDirection="column"
            justifyContent="center"
            // alignItems="center"
            margin="0 25px"
            className="h-gap--25"
          >
            <Box
              display="flex"
              flexDirection="column"
              padding="25px 0px"
              className="h-gap--20"
            >
              <Grid
                container
                spacing={5}
                direction="row"
                alignContent="flex-start"
                alignItems="flex-start"
                className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
              >
                <Grid container item lg={12} spacing={2}>
                {(JSON.stringify(transactions) !== "[]" && transactions !== undefined) ? transactions.map((item, i) => (
                  <div style={{ display: "flex", alignItems: "center", width: "100%", marginBottom: "10px" }}>
                    <Link href={item.invoice_pdf} >
                      <Button startIcon={<GetAppIcon />} variant="outlined" size="small">PDF</Button>
                    </Link>
                    <span style={{ marginLeft: "10px" }}><strong>Datum:</strong> {(new Date(item.created * 1000)).toLocaleString("de-DE", {year: "numeric", month: "numeric", day: "numeric"})}</span>
                    <span style={{ marginLeft: "10px" }}><strong>Betrag:</strong> {(item.amount_paid / 100).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €</span>
                    {/* <span style={{ marginLeft: "10px" }}><strong>Nächste Zahlung fällig am:</strong> {(new Date(1715533023 * 1000)).toLocaleString("de-DE", {year: "numeric", month: "numeric", day: "numeric"})}</span> */}
                  </div>
                ))
                : <div>Keine Transaktionen</div>
                }
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </Container>
    </React.Fragment>
  );
};

export default AccountTransactions;

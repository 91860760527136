import React, { useState } from 'react';

// IMPORT hooks & context
import { useTranslation } from "react-i18next";

// IMPORT components MATERIAL UI
import {
  Typography,
  Button,
  Grid,
  FormControlLabel,
  FormControl,
  FormGroup,
  Checkbox,
  IconButton,
  Dialog
} from "@material-ui/core";

// IMPORT icons MATERIAL UI
import SaveIcon from '@material-ui/icons/Save';
import FilterListIcon from '@material-ui/icons/FilterList';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

// IMPORT icons MATERIAL UI
import CloseIcon from '@material-ui/icons/Close';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function InvoicesFilter({
  settings,
  handleChange,
  cancelSettingsHandler = { cancelSettingsHandler },
  updateSettingsHandler = { updateSettingsHandler },
  SET_VALUE,
  setValues,
  values,
  type
}) {
  // HOOKS & CONTEXT
  const { t } = useTranslation();

  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');

  const handleClickOpen = () => {

    SET_VALUE({ target: { id: "submenuOpen", value: "filter" } }, setValues);
    console.log(values)

  };

  return (
    <>
      <IconButton
        aria-label="settings"
        style={{ color: "inherit" }}
        onClick={handleClickOpen}
      >
        <FilterListIcon fontSize="small" />
      </IconButton>
      <Dialog
        onClose={() => { cancelSettingsHandler() }}
        aria-labelledby="customized-dialog-title"
        open={values.submenuOpen === "filter"}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogTitle id="customized-dialog-title" onClose={() => { cancelSettingsHandler() }} style={{ display: "flex" }}>
          <FilterListIcon color="primary" /> {t("PAGE_INVOICES.filter")}
        </DialogTitle>
        <DialogContent dividers>
            <Grid container item>
              <FormControl component="fieldset">
                <Typography variant="body2" component="div"><strong>{t("PAGE_INVOICES.filterStatus")}</strong></Typography>
                <FormGroup aria-label="filterInvoicesStatus" name="filterInvoicesStatus" onChange={(e) => handleChange(e)}>
                  <FormControlLabel name="invoicesFiltersStatusDraft" control={<Checkbox checked={settings.invoicesFiltersStatusDraft} color="primary" style={{ paddingTop: "5px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("PAGE_INVOICES.filterStatusDraft")}</Typography>} />
                  {type === "invoices" && <FormControlLabel name="invoicesFiltersStatusScheduled" control={<Checkbox checked={settings.invoicesFiltersStatusScheduled} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("PAGE_INVOICES.filterStatusScheduled")}</Typography>} />}
                  <FormControlLabel name="invoicesFiltersStatusSent" control={<Checkbox checked={settings.invoicesFiltersStatusSent} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("PAGE_INVOICES.filterStatusSent")}</Typography>} />
                  {type === "invoices" && <FormControlLabel name="invoicesFiltersStatusPayed" control={<Checkbox checked={settings.invoicesFiltersStatusPayed} color="primary" style={{ paddingTop: "5px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("PAGE_INVOICES.filterStatusPayed")}</Typography>} />}
                  {type === "invoices" && <FormControlLabel name="invoicesFiltersStatusOverdue" control={<Checkbox checked={settings.invoicesFiltersStatusOverdue} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("PAGE_INVOICES.filterStatusOverdue")}</Typography>} />}
                </FormGroup>
              </FormControl>
            </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            onClick={() => { cancelSettingsHandler() }}
          >
            {t("BUTTONS.cancel")}
          </Button>

          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => { updateSettingsHandler() }}
            startIcon={<SaveIcon style={{ marginRight: "5px" }} />}
          >
            {t("BUTTONS.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

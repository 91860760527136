import React, { useState, useEffect } from "react";

// KEEP!!!! even it is greyed out!!!!
import _ from "lodash";
import filter from "lodash";
import groupBy from "lodash";
import map from "lodash";
import sumBy from "lodash";
// KEEP !!!!

// IMPORT hooks & context
import {
  NavLink
} from "react-router-dom";
import { useTranslation } from "react-i18next";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import userAtomState from "../../_atoms/userAtom";
import selectedMenuItemAtomState from "../../_atoms/selectedMenuItemAtom";
import subMenuOpenAtomState from "../../_atoms/subMenuOpenAtom";
import invoicesAtomState from "../../_atoms/invoicesAtom";
import expensesAtomState from "../../_atoms/expensesAtom";
import openInvoiceDialogAtomState from "../../_atoms/openInvoiceDialogAtom";
import openExpenseDialogAtomState from "../../_atoms/openExpenseDialogAtom";
import settingsAtomState from "../../_atoms/settingsAtom";

import DateFnsUtils from '@date-io/date-fns';
import { format, endOfDay, startOfDay } from 'date-fns';


// IMPORT components
import LogoMonogram from './../../_assets/logo_monogram.svg';
import LogoMonogramWhite from './../../_assets/logo_monogram_white.svg';
import Promo from "./../../shared/components/UIElements/Promo";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from 'recharts';

// IMPORT components MATERIAL UI
import {
  Typography,
  Button,
  Box,
  Icon,
  Container,
  Grid,
  IconButton,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  useMediaQuery
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';



// IMPORT icons MATERIAL UI
import NotificationsIcon from '@material-ui/icons/Notifications';
import DashboardIcon from "@material-ui/icons/Dashboard";
import TimelineIcon from '@material-ui/icons/Timeline';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ClientsIcon from "@material-ui/icons/RecentActors";
import AccountCircle from "@material-ui/icons/AccountCircle";
import LogoutIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from '@material-ui/icons/Menu';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import GavelIcon from '@material-ui/icons/Gavel';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';



// import "./Cashboard.scss"


const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: {
    height: '0px',
    '@media (max-width: 959px)': {
      height: '62px',
    },
    '@media (max-width: 599px)': {
      height: '45px',
    },
    '@media (max-width: 455px)': {
      height: '55px',
    },
  },
  // gridContainer: {
  //   margin: '-5px', // Offset the padding of each grid item
  //   width: 'calc(100% + 10px)', // Adjust the width to account for the negative margins
  // },
  // gridItem: {
  //   padding: '5px', // Add padding of 5px (half of 10px) to each side of the item
  // },
}));

const InvoicesExpensesChart = () => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const classes = useStyles();


 // GLOBAL STATE (RECOIL)
 const [userAtom] = useRecoilState(userAtomState);
 const [selectedMenuItemAtom, setSelectedMenuItemAtom] = useRecoilState(selectedMenuItemAtomState);
 const [subMenuOpenAtom, setSubMenuOpenAtom] = useRecoilState(subMenuOpenAtomState);
 const [openInvoiceDialogAtom, setOpenInvoiceDialogAtom] = useRecoilState(openInvoiceDialogAtomState);
 const [openExpenseDialogAtom, setOpenExpenseDialogAtom] = useRecoilState(openExpenseDialogAtomState);
 const [settingsAtom, setSettingsAtom] = useRecoilState(settingsAtomState);


 const [invoicesAtom, setInvoicesAtom] = useRecoilState(invoicesAtomState);
 const [dataInvoices, setDataInvoices] = useState([]);
 const [expensesAtom, setExpensesAtom] = useRecoilState(expensesAtomState);
 const [dataExpenses, setDataExpenses] = useState([]);
 const [data, setData] = useState([]);
 const [newData, setNewData] = useState([]);

 const [revenue, setRevenue] = useState(0);
 const [costs, setCosts] = useState(0);
 const [profit, setProfit] = useState(0);
 const [vat, setVat] = useState(0);
 const [chartType, setChartType] = React.useState('area');
 const [valueType, setValueType] = React.useState('net');
 const [year, setYear] = useState(new Date().getFullYear());

  const months = [
    {month: "Jän"},
    {month: "Feb"},
    {month: "Mar"},
    {month: "Apr"},
    {month: "Mai"},
    {month: "Jun"},
    {month: "Jul"},
    {month: "Aug"},
    {month: "Sep"},
    {month: "Okt"},
    {month: "Nov"},
    {month: "Dez"},
  ]

  const monthMapping = {
    "Jän": 0,
    "Feb": 1,
    "Mar": 2,
    "Apr": 3,
    "Mai": 4,
    "Jun": 5,
    "Jul": 6,
    "Aug": 7,
    "Sep": 8,
    "Okt": 9,
    "Nov": 10,
    "Dez": 11,
  };

  const returnNetOrGrossInvoices=(item)=> {
    if (valueType === "net") {
      return Number(item.discountedSubtotal);
    } else {
      return Number(item.total);
    }
  }

  const returnNetOrGrossExpenses=(item)=> {
    if (valueType === "net") {
      if (item.charge === "100") {
        return Number(item.net_value);
      } else {
        return Number(item.net_value / 100 * item.charge)
      }
    } else {
      if (item.charge === "100") {
        return Number(item.gross_value);
      } else {
        return Number(item.gross_value / 100 * item.charge)
      }
    }
  }

  const dataMonthlyYear = (atom, setData, property, year, name) => {
    console.log(year)
    let arrFilteredYear = _.filter(atom, function (item) {return (new Date(item[property])).getFullYear() === year});
    let arrGroupedMonths = _.groupBy(arrFilteredYear, (item) => new Date(item[property]).getMonth());
    let arrSummedPerMonth = _.map(arrGroupedMonths, (objs, key) => ({
      'month':
        key === "0" ? months[0].month
        : key === "1" ? months[1].month
        : key === "2" ? months[2].month
        : key === "3" ? months[3].month
        : key === "4" ? months[4].month
        : key === "5" ? months[5].month
        : key === "6" ? months[6].month
        : key === "7" ? months[7].month
        : key === "8" ? months[8].month
        : key === "9" ? months[9].month
        : key === "10" ? months[10].month
        : key === "11" ? months[11].month
        : months[12],
      [name]: _.sumBy(objs, item => (
        property === "payDate" && item.payState === "payed") ? returnNetOrGrossInvoices(item)
        : property === "pay_date" ? returnNetOrGrossExpenses(item)
        : 0
      )
    }))
    months.forEach (function (item, i) {
      // If there is a month with no payed invoice, add month with total of 0
      !arrSummedPerMonth.some((obj)=> obj.month === months[i].month) && arrSummedPerMonth.splice(i, 0, {month: months[i].month, [name]: 0});
    });    
    setData(arrSummedPerMonth)
    console.log(arrSummedPerMonth)
  }

  useEffect(() => {
    const filteredMonths = data.filter(({ month }) => {
      const monthIndex = monthMapping[month];
      const start = (settingsAtom.dateRangeStartValue === undefined) ? (endOfDay(new Date(new Date().getUTCFullYear(), 0, 1))).getUTCMonth() : endOfDay(new Date(settingsAtom.dateRangeStartValue)).getUTCMonth();
      const end = (settingsAtom.dateRangeStartValue === undefined) ? 11 : new Date(settingsAtom.dateRangeEndValue).getUTCMonth();
      return monthIndex >= start && monthIndex <= end
    });
    setNewData(filteredMonths)
  }, [data, settingsAtom]);

  useEffect(() => {
    const dateRangeFilteredInvoices = invoicesAtom.filter(entry => {
        const payDate = new Date(entry.payDate); // Parse the payDate string to a Date object
        return (payDate >= new Date(settingsAtom.dateRangeStartValue)) && (payDate <= new Date(settingsAtom.dateRangeEndValue)); // Filter out entries not within the date range
    });

    const dateRangeFilteredExpenses = expensesAtom.filter(entry => {
      const payDate = new Date(entry.pay_date); // Parse the payDate string to a Date object
      return (payDate >= new Date(settingsAtom.dateRangeStartValue)) && (payDate <= new Date(settingsAtom.dateRangeEndValue)); // Filter out entries not within the date range
  });

    dataMonthlyYear(dateRangeFilteredInvoices, setDataInvoices, "payDate", settingsAtom.dateRangeEndValue === undefined ? year : new Date(settingsAtom.dateRangeEndValue).getFullYear(), "totalInvoices")
    dataMonthlyYear(dateRangeFilteredExpenses, setDataExpenses, "pay_date", settingsAtom.dateRangeEndValue === undefined ? year : new Date(settingsAtom.dateRangeEndValue).getFullYear(), "totalExpenses")
  }, [invoicesAtom, expensesAtom, settingsAtom, valueType]);

  useEffect(() => {
    setData([
      {month: months[0].month, totalInvoices: dataInvoices["0"] !== undefined && dataInvoices["0"].totalInvoices, totalExpenses: dataExpenses["0"] !== undefined && dataExpenses["0"].totalExpenses},
      {month: months[1].month, totalInvoices: dataInvoices["1"] !== undefined && dataInvoices["1"].totalInvoices, totalExpenses: dataExpenses["1"] !== undefined && dataExpenses["1"].totalExpenses},
      {month: months[2].month, totalInvoices: dataInvoices["2"] !== undefined && dataInvoices["2"].totalInvoices, totalExpenses: dataExpenses["2"] !== undefined && dataExpenses["2"].totalExpenses},
      {month: months[3].month, totalInvoices: dataInvoices["3"] !== undefined && dataInvoices["3"].totalInvoices, totalExpenses: dataExpenses["3"] !== undefined && dataExpenses["3"].totalExpenses},
      {month: months[4].month, totalInvoices: dataInvoices["4"] !== undefined && dataInvoices["4"].totalInvoices, totalExpenses: dataExpenses["4"] !== undefined && dataExpenses["4"].totalExpenses},
      {month: months[5].month, totalInvoices: dataInvoices["5"] !== undefined && dataInvoices["5"].totalInvoices, totalExpenses: dataExpenses["5"] !== undefined && dataExpenses["5"].totalExpenses},
      {month: months[6].month, totalInvoices: dataInvoices["6"] !== undefined && dataInvoices["6"].totalInvoices, totalExpenses: dataExpenses["6"] !== undefined && dataExpenses["6"].totalExpenses},
      {month: months[7].month, totalInvoices: dataInvoices["7"] !== undefined && dataInvoices["7"].totalInvoices, totalExpenses: dataExpenses["7"] !== undefined && dataExpenses["7"].totalExpenses},
      {month: months[8].month, totalInvoices: dataInvoices["8"] !== undefined && dataInvoices["8"].totalInvoices, totalExpenses: dataExpenses["8"] !== undefined && dataExpenses["8"].totalExpenses},
      {month: months[9].month, totalInvoices: dataInvoices["9"] !== undefined && dataInvoices["9"].totalInvoices, totalExpenses: dataExpenses["9"] !== undefined && dataExpenses["9"].totalExpenses},
      {month: months[10].month, totalInvoices: dataInvoices["10"] !== undefined && dataInvoices["10"].totalInvoices, totalExpenses: dataExpenses["10"] !== undefined && dataExpenses["10"].totalExpenses},
      {month: months[11].month, totalInvoices: dataInvoices["11"] !== undefined && dataInvoices["11"].totalInvoices, totalExpenses: dataExpenses["11"] !== undefined && dataExpenses["11"].totalExpenses},
    ])
  }, [dataInvoices, dataExpenses]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          {/* <p className="label">{`${label} : ${payload[0].value}`}</p> */}
          <div className="label" style={{ fontWeight: "bold", fontSize: "12px", display: "inline-block", paddingTop: "5px", paddingLeft: "10px", paddingRight: "10px" }}>{`${label}`}</div>
          <div>
            {payload.map((pld, i) => (
              <div key={i} style={{ display: "inline-block", padding: "10px", paddingTop: "5px" }}>
                <div style={{ fontWeight: "bold", fontSize: "12px", color: pld.dataKey === "totalInvoices" ? "#00bf72" : "#ff7979" }}>{pld.dataKey === "totalInvoices" ? "Einnahmen" : "Ausgaben"}</div>
                {/* <div style={{ color: pld.fill }}>{pld.value}</div> */}
                <div style={{ fontWeight: "bold", color: "#ffffff", background: pld.dataKey === "totalInvoices" ? "#00bf72" : "#ff7979", textAlign: "center", borderRadius: "4px" }}> € {pld.value}</div>
              </div>
            ))}
          </div>
        </div>
      );
    }

    return null;
  };



  const [mobileOpen, setMobileOpen] = useState(false);


   /* 
      FUNCTIONS
    */
      const handleListItemClick = (e, i) => {
        setSelectedMenuItemAtom(i);
        setMobileOpen(false);
        if (i !== 5 && i !== 6 && i !== 7) {
            setSubMenuOpenAtom(false);
        }
    };




  return (
    <div>

      <Fade in={true}>
      <Container maxWidth={false} disableGutters className="bg--1" >
      <Container maxWidth={false} >
          <Box display="flex"
            flexDirection="row"
            justifyContent="center"
            margin="25px 25px 25px 25px"
            className="h-gap--25"
          >
      <Grid
                container
                direction="row"
                alignContent="flex-start"
                alignItems="flex-start"
                className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
              >
                {(JSON.stringify(dataInvoices) !== "[]" || JSON.stringify(dataExpenses) !== "[]") &&
                <Grid container item xs={12} spacing={2}>
                  <Grid container item>
                    <Grid container item direction="row" alignItems="center">
                      {/* <TimelineIcon color="primary" fontSize="medium" style={{ marginRight: "5px" }} /> */}
                      <Typography variant="body1"  style={{ color: "#5f6791", fontWeight: "500" }}>Einnahmen und Ausgaben <span style={{ fontSize: "12px" }}>(bezahlt)</span></Typography>
                      <ToggleButtonGroup
                        size={"small"}
                        style={{ zoom: "0.7", marginLeft: "10px"}}
                        value={chartType}
                        exclusive
                        aria-label="chart type"
                      >
                        <ToggleButton value="area" aria-label="area chart" onClick={()=>setChartType("area")}>
                          <TimelineIcon />
                        </ToggleButton>
                        <ToggleButton value="bar" aria-label="bar chart" onClick={()=>setChartType("bar")}>
                          <EqualizerIcon />
                        </ToggleButton>
                      </ToggleButtonGroup>

                      <ToggleButtonGroup
                        size={"small"}
                        style={{ zoom: "0.7", marginLeft: "10px", height: "100%", maxHeight: "41px"}}
                        value={valueType}
                        exclusive
                        aria-label="value type"
                      >
                        <ToggleButton value="net" aria-label="net values" onClick={()=>setValueType("net")}>
                          netto
                        </ToggleButton>
                        <ToggleButton value="gross" aria-label="gross values" onClick={()=>setValueType("gross")}>
                          brutto
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>
                  </Grid>

                  {chartType === "area" &&
                  <ResponsiveContainer width="100%" height={400}>
                    <AreaChart
                      data={newData}
                      margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
                      <defs>
                        <linearGradient id="colorInvoices" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="100%" stopColor="#00bf72" stopOpacity={1}/>
                          <stop offset="100%" stopColor="#00bf72" stopOpacity={0}/>
                        </linearGradient>
                        <linearGradient id="colorExpenses" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="100%" stopColor="#ff7979" stopOpacity={1}/>
                          <stop offset="100%" stopColor="ff7979" stopOpacity={0}/>
                        </linearGradient>
                      </defs>
                      <XAxis dataKey="month" />
                      <YAxis />
                      {/* <CartesianGrid strokeDasharray="3 3" /> */}
                      <Tooltip content={<CustomTooltip />}/>
                      <Area type="monotone" dot={true} dataKey="totalInvoices" stroke="#00bf72" strokeWidth={1} fill="url(#colorInvoices)" />
                      <Area type="monotone" dot={true} dataKey="totalExpenses" stroke="#ff7979" strokeWidth={1} fill="url(#colorExpenses)" />
                    </AreaChart>
                  </ResponsiveContainer>
                  }

                  {chartType === "bar" &&
                  <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                      data={newData}
                      margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
                      <XAxis dataKey="month" />
                      <YAxis />
                      <Tooltip content={<CustomTooltip />}/>
                      <Bar type="monotone" dataKey="totalInvoices" stroke="#00bf72"  fill="#00bf72" />
                      <Bar type="monotone" dataKey="totalExpenses" stroke="#ff7979"  fill="#ff7979" />
                    </BarChart>
                  </ResponsiveContainer>
                  }
                </Grid>
                }
              </Grid>

              </Box>
              </Container>
      </Container>
      </Fade>
    </div>
  );
};

export default InvoicesExpensesChart;

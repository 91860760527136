import React, { useEffect, useState, useContext } from "react";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { useAuth } from "../../shared/hooks/auth-hook";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import userAtomState from "../../_atoms/userAtom";
import clientsAtomState from "../../_atoms/clientsAtom";
import invoicesAtomState from "../../_atoms/invoicesAtom";
import invoicesrecurringAtomState from "../../_atoms/invoicesrecurringAtom";
import openInvoiceDialogAtomState from "../../_atoms/openInvoiceDialogAtom";
import settingsAtomState from "../../_atoms/settingsAtom";

// IMPORT components
import { useSnackbar } from 'notistack';

// IMPORT components MATERIAL UI
import {
  Typography,
  Box,
  Container,
  Grid,
  Tooltip,
  Chip,
  Collapse,
  IconButton,
  Button,
  Icon,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import Alert from "@material-ui/lab/Alert";
import Fab from '@material-ui/core/Fab';
import Fade from '@material-ui/core/Fade';


// IMPORT icons MATERIAL UI
import ClientsIcon from "@material-ui/icons/RecentActors";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import FilterListIcon from '@material-ui/icons/FilterList';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import EditIcon from '@material-ui/icons/Edit';
import ScheduleIcon from '@material-ui/icons/Schedule';
import MailIcon from '@material-ui/icons/Mail';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AddIcon from '@material-ui/icons/Add';
import GavelIcon from '@material-ui/icons/Gavel';
import SearchIcon from '@material-ui/icons/Search';
import ErrorIcon from '@material-ui/icons/Error';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import RepeatIcon from '@material-ui/icons/Repeat';

// IMPORT own functions
import { SET_VALUE } from '../../_functions/SET_VALUE';
import { DB_GET_CLIENTS } from '../../_functions/DB_CLIENTS';
import { DB_GET_INVOICES } from '../../_functions/DB_INVOICES';
import { DB_GET_INVOICESRECURRING } from '../../_functions/DB_INVOICESRECURRING';
import { DB_PATCH_SETTINGS } from '../../_functions/DB_SETTINGS';
import { RETURN_DATE } from '../../_functions/DATES';
import { RETURN_CLEARDATE } from '../../_functions/DATES';
import { RETURN_HOURS } from '../../_functions/DATES';
import { RETURN_OVERDUE_DAYS } from '../../_functions/DATES';

// IMPORT own components
import UserProfileIncompleteWarning from "../../user/components/UserProfileIncompleteWarning";
import InvoicesMenu from "../components/InvoicesMenu";
import InvoicesMenuView from "../components/InvoicesMenuView";
import InvoicesSearch from "../components/InvoicesSearch";
import InvoicesFilter from "../components/InvoicesFilter";
import InvoicesSort from "../components/InvoicesSort";
import InvoicesSettings from "../components/InvoicesSettings";
import InvoicesCsv from "../components/InvoicesCsv";
import InvoicesMenuDots from "../components/InvoicesMenuDots";
import LogoMonogram from './../../_assets/logo_monogram.svg';
import LogoMonogramWhite from './../../_assets/logo_monogram_white.svg';
import DateRange from "../../shared/components/DateRange/DateRange";


// IMPORT own CSS
import "./Invoices.scss"
import DeleteForever from "@material-ui/icons/DeleteForever";
import SaveAlt from "@material-ui/icons/SaveAlt";

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: {
    height: '0px',
    '@media (max-width: 959px)': {
      height: '62px',
    },
    '@media (max-width: 599px)': {
      height: '45px',
    },
    '@media (max-width: 455px)': {
      height: '55px',
    },
  },
  table: {
    minWidth: 650,
  },
}));

const Invoices = ({type}) => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { isLoading, error, sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const { userId } = useAuth();

  // GLOBAL STATE (RECOIL)
  const [userAtom] = useRecoilState(userAtomState);
  const [openInvoiceDialogAtom, setOpenInvoiceDialogAtom] = useRecoilState(openInvoiceDialogAtomState);
  const [clientsAtom, setClientsAtom] = useRecoilState(clientsAtomState);
  const [invoicesAtom, setInvoicesAtom] = useRecoilState(invoicesAtomState);
  const [invoicesrecurringAtom, setInvoicesrecurringAtom] = useRecoilState(invoicesrecurringAtomState);
  const [settingsAtom, setSettingsAtom] = useRecoilState(settingsAtomState);

  // LOCAL STATE (GLOBAL DEPENDENCY monitoring temporay CHANGES until SAVED)
  const [settings, setSettings] = useState(settingsAtom);

  // LOCAL STATE (PURE)
  const [recurring, setRecurring] = useState(false);
  const [draggableSortItems, setDraggableSortItems] = useState([]);
  const [values, setValues] = useState({
    submenuOpen: false, // values: false, search, filter, sort, settings
    invoicesFiltered: {},
    searchInput: "",
    chipDirectChange: false,
    save: false,
    loading: true,
  });
  // console.log(values.invoicesFiltered)


  const [invoicesFilteredInitial, setInvoicesFilteredIntitial] = useState({});

  useEffect(() => {
    let invoicesFilteredInitial = invoicesAtom.filter((invoice) => {
      return (
      // Filter: Type === invoices or estimates
        (type === "invoices" ?
          (
            invoice.type === "invoice" ||
            invoice.type === "reminder" ||
            invoice.type === "reminderRequest"
          ) : (
            invoice.type === "estimate" ||
            invoice.type === "offer"
          )
        )
        &&

        // Filter: Invoice status
        (
          settingsAtom.invoicesFiltersStatusDraft && (invoice.sendState !== "sent" && invoice.sendState !== "scheduled" && (invoice.payState === "notPayed" || invoice.payState === undefined)) || // draft
          settingsAtom.invoicesFiltersStatusScheduled && (invoice.sendState === "scheduled" && invoice.payState === "notPayed") || // scheduled
          settingsAtom.invoicesFiltersStatusSent && (invoice.sendState === "sent" && invoice.payState === "notPayed") || // sent
          settingsAtom.invoicesFiltersStatusPayed && invoice.payState === "payed" || // payed
          settingsAtom.invoicesFiltersStatusOverdue && (RETURN_OVERDUE_DAYS(invoice.dueDate) > 0 && invoice.payState === "notPayed") || // *** overdue ***
          
          // Reset invoice status filter (return all invoices when no invoice status filter is set)
          !settingsAtom.invoicesFiltersStatusDraft &&
          !settingsAtom.invoicesFiltersStatusScheduled &&
          !settingsAtom.invoicesFiltersStatusSent &&
          !settingsAtom.invoicesFiltersStatusPayed &&
          !settingsAtom.invoicesFiltersStatusOverdue
        )
        &&
        // Filter: Date range
        (
          settingsAtom.invoicesDateRange === "invoiceDate" && (
            ((RETURN_CLEARDATE(invoice.invoiceDate) >= RETURN_CLEARDATE(settingsAtom.dateRangeStartValue)) && (RETURN_CLEARDATE(invoice.invoiceDate) <= RETURN_CLEARDATE(settingsAtom.dateRangeEndValue)))) ||
          settingsAtom.invoicesDateRange === "dueDate" && (
            ((RETURN_CLEARDATE(invoice.dueDate) >= RETURN_CLEARDATE(settingsAtom.dateRangeStartValue)) && (RETURN_CLEARDATE(invoice.dueDate) <= RETURN_CLEARDATE(settingsAtom.dateRangeEndValue)))) ||
          (settingsAtom.invoicesDateRange === "payDate" && invoice.payState === "payed") && (
            ((RETURN_CLEARDATE(invoice.payDate) >= RETURN_CLEARDATE(settingsAtom.dateRangeStartValue)) && (RETURN_CLEARDATE(invoice.payDate) <= RETURN_CLEARDATE(settingsAtom.dateRangeEndValue)))) ||
          // Reset date range filter   
          settingsAtom.invoicesDateRange === "none"
        )
      );
    })

    // SORT: Creation point in time
    if (settingsAtom.invoicesSortCreation) {
      if (settingsAtom.invoicesSortCreationOption === "chronological") {
        invoicesFilteredInitial = invoicesFilteredInitial;
      } else if (settingsAtom.invoicesSortCreationOption === "reverse-chronological") {
        invoicesFilteredInitial = Array.from(invoicesFilteredInitial).reverse();
      }
    }

    // SORT: Client name alphabetically
    if (settingsAtom.invoicesSortCompany) {
      if (settingsAtom.invoicesSortCompanyOption === "ascending") {
        invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
          return (JSON.parse(a.clientData).company).toLowerCase() < (JSON.parse(b.clientData).company).toLowerCase() ? -1 : 1
        })
      } else if (settingsAtom.invoicesSortCompanyOption === "descending") {
        invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
          return (JSON.parse(a.clientData).company).toLowerCase() > (JSON.parse(b.clientData).company).toLowerCase() ? -1 : 1
        })
      }
    }

    // SORT: Invoice number
    if (settingsAtom.invoicesSortNumber) {
      const returnValue = (item, type) => JSON.parse(item.invoiceNr).find(obj => {return obj.type === type}).value;
      
      if (settingsAtom.invoicesSortNumberOption === "ascending") {
        invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
          a = parseInt(returnValue(a, "continuousNumber"), 10);
          b = parseInt(returnValue(b, "continuousNumber"), 10);
          return (a < b) ? -1 : 1
        }).sort((a, b) => {
            a = new Date(a.invoiceDate).getUTCFullYear();
            b = new Date(b.invoiceDate).getUTCFullYear();
            return (a < b) ? -1 : 1
          })
        
      
      } else if (settingsAtom.invoicesSortNumberOption === "descending") {
        invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
          a = returnValue(a, "continuousNumber");
          b = returnValue(b, "continuousNumber");
          return (a > b) ? -1 : 1
        }).sort((a, b) => {
          a = new Date(a.invoiceDate).getUTCFullYear();
          b = new Date(b.invoiceDate).getUTCFullYear();
          return (a > b) ? -1 : 1
        })
      }
    }

    // SORT: Invoice date
    if (settingsAtom.invoicesSortInvoiceDate) {
      if (settingsAtom.invoicesSortInvoiceDateOption === "chronological") {
        invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
          return new Date(a.invoiceDate) - new Date(b.invoiceDate)
        })
      } else if (settingsAtom.invoicesSortInvoiceDateOption === "reverse-chronological") {
        invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
          return new Date(b.invoiceDate) - new Date(a.invoiceDate)
        })
      }
    }

    // SORT: Due date
    if (settingsAtom.invoicesSortDueDate) {
      if (settingsAtom.invoicesSortDueDateOption === "chronological") {
        invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
          return new Date(a.dueDate) - new Date(b.dueDate)
        })
      } else if (settingsAtom.invoicesSortDueDateOption === "reverse-chronological") {
        invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
          return new Date(b.dueDate) - new Date(a.dueDate)
        })
      }
    }

    // SORT: Pay date
    if (settingsAtom.invoicesSortPayDate) {
      if (settingsAtom.invoicesSortPayDateOption === "chronological") {
        invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
          return new Date(a.payDate) - new Date(b.payDate)
        }).filter((invoice) => {
          return (
            invoice.payState === "payed")
        })
      } else if (settingsAtom.invoicesSortPayDateOption === "reverse-chronological") {
        invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
          return new Date(b.payDate) - new Date(a.payDate)
        }).filter((invoice) => {
          return (
            invoice.payState === "payed")
        })
      }
    }

    setInvoicesFilteredIntitial(invoicesFilteredInitial)
  }, [invoicesAtom, settingsAtom, type]);


  useEffect(() => {
    // Only fetch from DB the first time the invoices page is loaded
    clientsAtom.length === 0 && DB_GET_CLIENTS(setClientsAtom, auth, sendRequest);
    invoicesAtom.length === 0 && DB_GET_INVOICES(setInvoicesAtom, auth, sendRequest);
    // Also fetch recurring invoices here as otherwise on first page load of /invoicesrecurring nothing is shown
    invoicesrecurringAtom.length === 0 && DB_GET_INVOICESRECURRING(setInvoicesrecurringAtom, auth, sendRequest);
    setValues({
      ...values,
      loading: false,
    })
  }, [userId, auth.token]);


  const handleSearchClear = () => {
    SET_VALUE({
      target: {
        "searchInput": "",
        "invoicesFiltered": invoicesFilteredInitial
      }
    }, setValues, "multi");
  };

  useEffect(() => {
    // Only fetch from DB the first time the invoices page is loaded
    invoicesAtom.length === 0 && DB_GET_INVOICES(setInvoicesAtom, auth, sendRequest);
  }, [userId, auth.token]);

  useEffect(() => {
    SET_VALUE({ target: { id: "invoicesFiltered", value: invoicesFilteredInitial } }, setValues);
  }, [JSON.stringify(invoicesFilteredInitial)]); // stringifying object dependency prevents infitive loop

  const handleChange = (e) => {
    SET_VALUE(e, setSettings);
  };

  // ON SAVE: 1) Update settingsAtom
  const updateSettingsHandler = () => {
    // GLOBAL changes
    setSettingsAtom(settings);

    // Save variable is only needed to trigger submenuClose when NOTHING changes in settings and save button is clicked
    SET_VALUE({ target: { id: "save", value: true } }, setValues);
  };


  // ON SAVE: 2) DB: PATCH SETTINGS
  useEffect(() => {

    if ((auth.token && values.submenuOpen !== false) || (auth.token && values.chipDirectChange === true)) {
      DB_PATCH_SETTINGS(settingsAtom, auth, sendRequest, enqueueSnackbar, t);

      SET_VALUE({
        target: {
          "submenuOpen": false,
          "chipDirectChange": false,
          "save": false,
        }
      }, setValues, "multi")
    }
  }, [auth.token, settingsAtom, values.save]);

  // ON CANCEL:
  const cancelSettingsHandler = () => {
    // Re-set original values
    setSettings(settingsAtom);
    SET_VALUE({ target: { id: "submenuOpen", value: false } }, setValues);
  };

  // Add to Sort Chips
  useEffect(() => {
    let i = []

    if (settingsAtom.invoicesSortCreation) {
      i.push("invoicesSortCreation")
    }
    if (settingsAtom.invoicesSortInvoiceDate) {
      i.push("invoicesSortInvoiceDate")
    }
    if (settingsAtom.invoicesSortDueDate) {
      i.push("invoicesSortDueDate")
    }
    if (settingsAtom.invoicesSortPayDate) {
      i.push("invoicesSortPayDate")
    }
    if (settingsAtom.invoicesSortNumber) {
      i.push("invoicesSortNumber")
    }
    if (settingsAtom.invoicesSortCompany) {
      i.push("invoicesSortCompany")
    }

    setDraggableSortItems(i)
  }, [settingsAtom]);




  return (
    <div>
      <div className={classes.toolbar} />
      <Fade in={true}>
        <Grid container className="subpage-header" maxWidth={false} item direction="row" alignItems="center" alignContent="center">
          <Grid item container direction="row" alignItems="center" justifyContent="space-between" style={{ marginRight: "10px" }}>
            <Grid item direction="row">
              <Grid item container alignItems="center">
                {type ==="invoices" ?
                <LibraryBooksIcon color="primary" fontSize="large" style={{ background: "linear-gradient(to right top, rgb(38, 108, 189), rgb(36, 115, 192), rgb(33, 150, 243), #00bca7, #00bf72)", marginRight: "15px" }} className="icon--with-bg--1" />
                : <GavelIcon color="primary" fontSize="large" style={{ background: "linear-gradient(to right top, rgb(38, 108, 189), rgb(36, 115, 192), rgb(33, 150, 243), #00bca7, #00bf72)", marginRight: "15px" }} className="icon--with-bg--1" />
                }
                <Typography variant="body1" component="div" style={{ fontWeight: "500" }} className="headline">{type ==="invoices" ? !recurring ? "Rechnungen" : "Wiederkehrende Rechnungen" : "Kostenvoranschläge"}</Typography>
                
                <IconButton
                  aria-label="add"
                  color="primary"
                  style={{ marginLeft: "5px" }}
                  onClick={
                    type === "invoices" ?
                    () =>setOpenInvoiceDialogAtom({
                    ...openInvoiceDialogAtom,
                    open: true,
                    isEstimate: false,
                    recurring: recurring ? true : false
                    })
                    : () =>setOpenInvoiceDialogAtom({
                      ...openInvoiceDialogAtom,
                      open: true,
                      isEstimate: true,
                    })
                  }
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
            
            <Grid item>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div
                  onClick={()=>setRecurring(!recurring)}
                  style={{ textAlign: "center", marginRight: "10px", opacity: recurring ? 1 : 0.5 }}>
                  <div style={{ borderRadius: "4px", display: "inline-grid", textAlign: "center", padding: "7px 7px", background: "linear-gradient(to right top, #2276f4, #6b6bf4, #9b5bed, #c243df, #e312ca)", color: "#ffffff", fontSize: "12px" }}>
                    <RepeatIcon style={{ fontSize: 18 }}/>
                  </div>
                </div>
                <FormControlLabel
                  className="switch"
                  style={{ marginTop: "10px", marginBottom: "10px", color: values.readData ? "inherit" : "#919191" }}
                  control={
                    <Switch
                      checked={recurring}
                      onChange={()=>setRecurring(!recurring)}
                      name="readData"
                      color="primary"
                    />
                  }
                  label={<Typography style={{ color: recurring ? "black" : "grey" }}>Wiederkehrend</Typography>}
                />
            </div>
            </Grid>
          </Grid>

          

          <Grid item container justifyContent="space-between">
            <Grid item container alignItems="center" style={{ padding: "0px 2px 0px 0px", borderRadius: "6px", marginTop: "10px", width: "auto", boxShadow: "inset rgb(108 116 173 / 12%) 0px 0px 20px 0px" }}>
              <DateRange type={type === "invoices" ? "invoices" : "estimates"}/>

              <IconButton aria-label="search" style={{ color: "inherit" }}
                onClick={() => {
                  SET_VALUE({ target: { id: "submenuOpen", value: values.submenuOpen !== "search" ? "search" : false } }, setValues);
                  handleSearchClear();
                }}
              >
                <SearchIcon fontSize="small" style={{ padding: values.submenuOpen === "search" ? "2px" : "inherit", backgroundColor: values.submenuOpen === "search" ? "#2196f3" : "inherit", color: values.submenuOpen === "search" ? "#ffffff" : "inherit", borderRadius: "60px"}}  />
              </IconButton>

              <InvoicesSort
                settings={settings}
                handleChange={handleChange}
                cancelSettingsHandler={cancelSettingsHandler}
                updateSettingsHandler={updateSettingsHandler}
                setValues={setValues}
                SET_VALUE={SET_VALUE}
                setSettings={setSettings}
                values={values}
                type={type}
              />

              <InvoicesFilter
                settings={settings}
                handleChange={handleChange}
                cancelSettingsHandler={cancelSettingsHandler}
                updateSettingsHandler={updateSettingsHandler}
                setValues={setValues}
                SET_VALUE={SET_VALUE}
                setSettings={setSettings}
                values={values}
                type={type}
              />

              <InvoicesSettings
                settings={settings}
                handleChange={handleChange}
                cancelSettingsHandler={cancelSettingsHandler}
                updateSettingsHandler={updateSettingsHandler}
                setValues={setValues}
                SET_VALUE={SET_VALUE}
                setSettings={setSettings}
                values={values}
              />

              {/* <InvoicesCsv data={values.invoicesFiltered} /> */}
            </Grid>



          </Grid>

          <Grid item style={{ display: "block", width: "100%"}}>
            <Collapse
              in={values.submenuOpen === "search" ? true : false}
              style={{ marginTop: values.submenuOpen === "search" ? "5px" : 0 }}
            // style={{ position: "fixed", top: "122px", zIndex: 9, width: "100%" }}
            >
              <InvoicesSearch
                values={values}
                invoicesFilteredInitial={invoicesFilteredInitial}
                SET_VALUE={SET_VALUE}
                setValues={setValues}
                handleSearchClear={handleSearchClear}
                type={type}
              />
            </Collapse>
          </Grid>
        </Grid>
      </Fade>

      {/* CHIPS */}
      <Grid container item xs={12} alignItems="center" className="chips">
        <>
          <>
            <ImportExportIcon fontSize="small" style={{ marginTop: "-2.5px", marginRight: "5px", color: "rgba(0, 0, 0, 0.87)", boxShadow: "rgba(108, 116, 173, 0.12) 0px 0px 20px 0px inset", borderRadius: "4px"  }} />
            <Typography variant="body2" component="div" style={{ marginRight: "5px", fontSize: "10px", color: "rgba(0, 0, 0, 0.87)"  }}>Sortiert nach: </Typography>
            {draggableSortItems.map((item) => (
              // <SortableItem key={item}>
              <Chip
                // icon={<DragIndicatorIcon />}
                key={item}
                size="small"
                style={{ marginRight: "10px", fontSize: "10px" }}
                label={
                  type === "invoices" ? (
                    item === "invoicesSortCreation" ?
                      settings.invoicesSortCreationOption === "chronological" ? t("PAGE_INVOICES.sortCreationChronologically") : t("PAGE_INVOICES.sortCreationChronologicallyReverse")
                      : item === "invoicesSortInvoiceDate" ?
                        settings.invoicesSortInvoiceDateOption === "chronological" ? t("PAGE_INVOICES.sortInvoiceDateChronologically") : t("PAGE_INVOICES.sortInvoiceDateChronologicallyReverse")
                        : item === "invoicesSortDueDate" ?
                          settings.invoicesSortDueDateOption === "chronological" ? t("PAGE_INVOICES.sortDueDateChronologically") : t("PAGE_INVOICES.sortDueDateChronologicallyReverse")
                          : item === "invoicesSortPayDate" ?
                            settings.invoicesSortPayDateOption === "chronological" ? t("PAGE_INVOICES.sortPayDateChronologically") : t("PAGE_INVOICES.sortPayDateChronologicallyReverse")
                            : item === "invoicesSortNumber" ?
                              settings.invoicesSortNumberOption === "ascending" ? t("PAGE_INVOICES.sortInvoiceNumbersAsc") : t("PAGE_INVOICES.sortInvoiceNumbersDesc")
                              : item === "invoicesSortCompany" &&
                                settings.invoicesSortCompanyOption === "ascending" ? t("PAGE_INVOICES.sortCompanyAsc") : t("PAGE_INVOICES.sortCompanyDesc")
                  )

                  : (
                  item === "invoicesSortCreation" ?
                    settings.invoicesSortCreationOption === "chronological" ? t("PAGE_INVOICES.sortCreationChronologically") : t("PAGE_INVOICES.sortCreationChronologicallyReverse")
                    : item === "invoicesSortInvoiceDate" ?
                      settings.invoicesSortInvoiceDateOption === "chronological" ? "Datum (chronologisch)" : "Datum (umgekehrt chronologisch)"
                          : item === "invoicesSortNumber" ?
                            settings.invoicesSortNumberOption === "ascending" ? "Nummer (aufsteigend)" : "Nummber (absteigend)"
                            : item === "invoicesSortCompany" &&
                              settings.invoicesSortCompanyOption === "ascending" ? t("PAGE_INVOICES.sortCompanyAsc") : t("PAGE_INVOICES.sortCompanyDesc")
                            )
                }
                onClick={() => SET_VALUE({ target: { id: "submenuOpen", value: "sort" } }, setValues)}
                className="draggable-chip"
              />
              // </SortableItem>

            ))}
          </>
        
          {(settingsAtom.invoicesFiltersStatusDraft || settingsAtom.invoicesFiltersStatusScheduled || settingsAtom.invoicesFiltersStatusSent || settingsAtom.invoicesFiltersStatusPayed || settingsAtom.invoicesFiltersStatusOverdue) &&
          <>
            <FilterListIcon fontSize="small" style={{ marginTop: "-2.5px", marginRight: "5px", color: "rgba(0, 0, 0, 0.87)", boxShadow: "rgba(108, 116, 173, 0.12) 0px 0px 20px 0px inset", borderRadius: "4px"  }} />
            <Typography variant="body2" component="div" style={{ marginRight: "5px", fontSize: "10px", color: "rgba(0, 0, 0, 0.87)"  }}>Gefiltert nach Status: </Typography>
            {settingsAtom.invoicesFiltersStatusDraft &&
              <Chip
                style={{ marginRight: "5px" }}
                icon={<EditIcon style={{ color: "white", background: "rgb(219, 222, 245)", borderRadius: "60px", padding: "2px" }}/>}
                size="small"
                label={t("PAGE_INVOICES.filterStatusDraft")}
                onDelete={() => {
                  SET_VALUE({ target: { id: "chipDirectChange", value: true } }, setValues);
                  SET_VALUE({ target: { id: "invoicesFiltersStatusDraft", value: false } }, setSettings);
                  SET_VALUE({ target: { id: "invoicesFiltersStatusDraft", value: false } }, setSettingsAtom);
                }}
                onClick={() => SET_VALUE({ target: { id: "submenuOpen", value: "filter" } }, setValues)}
                className="draggable-chip draft"
              />
            }
            {settingsAtom.invoicesFiltersStatusScheduled &&
              <Chip
                style={{ marginRight: "5px" }}
                icon={<WatchLaterIcon style={{ color: "rgb(255, 183, 77)", borderRadius: "60px" }}/>}
                size="small"
                label={t("PAGE_INVOICES.filterStatusScheduled")}
                onDelete={() => {
                  SET_VALUE({ target: { id: "chipDirectChange", value: true } }, setValues);
                  SET_VALUE({ target: { id: "invoicesFiltersStatusScheduled", value: false } }, setSettings);
                  SET_VALUE({ target: { id: "invoicesFiltersStatusScheduled", value: false } }, setSettingsAtom);
                }}
                onClick={() => SET_VALUE({ target: { id: "submenuOpen", value: "filter" } }, setValues)}
                className="draggable-chip scheduled"
              />
            }
            {settingsAtom.invoicesFiltersStatusSent &&
              <Chip
                style={{ marginRight: "5px" }}
                size="small"
                icon={<MailIcon style={{ color: "white", background: "rgb(26, 123, 201)", borderRadius: "60px", padding: "2px" }}/>}
                label={t("PAGE_INVOICES.filterStatusSent")}
                onDelete={() => {
                  SET_VALUE({ target: { id: "chipDirectChange", value: true } }, setValues);
                  SET_VALUE({ target: { id: "invoicesFiltersStatusSent", value: false } }, setSettings);
                  SET_VALUE({ target: { id: "invoicesFiltersStatusSent", value: false } }, setSettingsAtom);
                }}
                onClick={() => SET_VALUE({ target: { id: "submenuOpen", value: "filter" } }, setValues)}
                className="draggable-chip sent"
              />
            }
            {settingsAtom.invoicesFiltersStatusPayed &&
              <Chip
              style={{ marginRight: "5px" }}
              icon={<CheckCircleIcon style={{ color: "rgb(76, 175, 80)", borderRadius: "60px" }}/>}
                size="small"
                label={t("PAGE_INVOICES.filterStatusPayed")}
                onDelete={() => {
                  SET_VALUE({ target: { id: "chipDirectChange", value: true } }, setValues);
                  SET_VALUE({ target: { id: "invoicesFiltersStatusPayed", value: false } }, setSettings);
                  SET_VALUE({ target: { id: "invoicesFiltersStatusPayed", value: false } }, setSettingsAtom);
                }}
                onClick={() => SET_VALUE({ target: { id: "submenuOpen", value: "filter" } }, setValues)}
                className="draggable-chip payed"
              />
            }
            {settingsAtom.invoicesFiltersStatusOverdue &&
              <Chip
              style={{ marginRight: "5px" }}
              icon={<ErrorIcon style={{ color: "rgb(183, 28, 28)", borderRadius: "60px" }}/>}
                size="small"
                label={t("PAGE_INVOICES.filterStatusOverdue")}
                onDelete={() => {
                  SET_VALUE({ target: { id: "chipDirectChange", value: true } }, setValues);
                  SET_VALUE({ target: { id: "invoicesFiltersStatusOverdue", value: false } }, setSettings);
                  SET_VALUE({ target: { id: "invoicesFiltersStatusOverdue", value: false } }, setSettingsAtom);
                }}
                onClick={() => SET_VALUE({ target: { id: "submenuOpen", value: "filter" } }, setValues)}
                className="draggable-chip overdue"
              />
            }
          </>
          }
        </>
      </Grid>
                     
      {userAtom.data.signupCompleted && clientsAtom.length !== 0 &&
        <React.Fragment>
          <Container maxWidth={false} disableGutters className="bg--1" >
            <Box
              display="flex"
              flexDirection="column"
              className="list-box"
            >
              <Grid container item xs={12} >
                <div className="box-shadow--2" style={{ width: "100%" }}>
                  {!recurring &&
                  <Grid
                    item
                    className="sticky-nav box-shadow--2 h-padding--5-5 tool-menu"
                  >
                    <Grid container>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        item
                        style={{ paddingLeft: "19px"}}
                        xs={12}
                      >
                        <Grid container item xs={11} justifyContent="space-between">
                          <Grid container item alignItems="center" xs={12} lg={4}>
                            <div>
                              <Typography>Unternehmen</Typography>
                            </div>
                          </Grid>

                          <Grid container item justifyContent="flex-start" alignItems="center" xs={12} lg={8}>
                            <Grid container item xs={12} md={2} justifyContent="center">
                              <Typography>Betrag</Typography>
                            </Grid>

                            <Grid container item xs={4} md={2} justifyContent="center">
                              <Typography>{type === "invoices" ? "R-Nr." : "Nr."}</Typography>
                            </Grid>

                            <Grid container item xs={4} md={2} justifyContent="center">
                              <Typography>{type === "invoices" ? "R-Datum" : "Datum"}</Typography>
                            </Grid>

                            {type === "invoices" && 
                            <Grid container item xs={4} md={2} justifyContent="center">
                              <Typography>Fälligkeit</Typography>
                            </Grid>
                            }

                            {type === "estimates" &&
                            <Grid container item xs={4} md={2} justifyContent="center">
                              <Typography>Typ</Typography>
                            </Grid>
                            }
                            
                            <Grid container item xs={12} md={3} justifyContent="center">
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid container item xs={1} justifyContent="flex-end">
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  }

                  {(!isLoading) &&
                    <>
                      {/* INVOICES already created */}
                      {(invoicesAtom.length !== 0 && !recurring) &&
                        <>
                          {/* APPLIED FILTER after Loading doesn't result in list of length 0 */}
                          {Object.keys(values.invoicesFiltered).length !== 0 &&
                            (values.invoicesFiltered).map((invoice, i) => (
                              <Grid container key={i}>
                                <Grid
                                  container
                                  alignItems="center"
                                  justifyContent="space-between"
                                  item
                                  xs={12}
                                  className="h-bg--white user-clients"
                                  style={{
                                    borderBottom: "1px solid #e2ecfd",
                                    borderLeft:
                                      (invoice.sendState !== "sent" && invoice.sendState !== "scheduled" && (invoice.payState === "notPayed" || invoice.payState === undefined)) ? `5px solid #dbdef5` :
                                        (invoice.sendState === "scheduled" && invoice.payState === "notPayed") ? `5px solid ${theme.palette.warning.light}` :
                                          (invoice.sendState === "sent" && invoice.payState === "notPayed") ? `5px solid ${theme.palette.primary.dark}` :
                                            `5px solid ${theme.palette.success.light}`
                                  }}
                                >

                                  <Grid container item xs={11} justifyContent="space-between">
                                    <Grid container item alignItems="center" xs={12} lg={4}
                                      className="image-name-company"
                                      style={{ padding: "30px 15px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", flexWrap: "nowrap" }}
                                      onClick={() => setOpenInvoiceDialogAtom({
                                        ...openInvoiceDialogAtom,
                                        open: true,
                                        invoiceId: invoice.id,
                                        isEstimate: (invoice.type === "estimate" || invoice.type === "offer") ? true : false
                                      })}
                                    >

                                      <div style={{ width: "50px", minWidth: "50px", height: "25px", marginRight: "10px", position: "relative" }}>
                                        {(((clientsAtom).find(obj => obj._id === JSON.parse(invoice.clientData).id))).logo !== "" ? (
                                          <img
                                            src={`${((clientsAtom).find(obj => obj._id === JSON.parse(invoice.clientData)._id)).logo}`}
                                            alt="logo"
                                            style={{
                                              maxWidth: "100%",
                                              maxHeight: "100%",
                                              marginRight: "10px",
                                              position: "absolute",
                                              top: "50%",
                                              left: "50%",
                                              transform: "translate(-50%, -50%)"
                                            }}
                                          />
                                        ) :
                                          <img src={LogoMonogram} height={25} width={25} alt={`${t("APP.name")} Logo`} style={{ width: "50px", minWidth: "50px", marginRight: "10px", opacity: 0.2 }} />
                                        }
                                      </div>

                                      <Typography component="div" variant="body2" style={{ fontWeight: "500", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{JSON.parse(invoice.clientData).company}</Typography>
                                    </Grid>

                                    <Grid container item justifyContent="flex-start" alignItems="center" xs={12} lg={8}
                                      className="invoice-meta"
                                      style={{padding: "30px 15px", }}
                                      onClick={() => setOpenInvoiceDialogAtom({
                                        ...openInvoiceDialogAtom,
                                        open: true,
                                        invoiceId: invoice.id,
                                        isEstimate: (invoice.type === "estimate" || invoice.type === "offer") ? true : false
                                      })}
                                    >
                                      <Grid container item xs={12} md={2} justifyContent="center" className="invoice-total" style={{ background: "rgba(33, 150, 243, 0.1)", borderRadius: "4px", paddingTop: "5px", paddingBottom: "5px" }}>
                                        <Tooltip title={`${t("PAGE_INVOICES.total")} brutto`}>
                                          <Typography component="div" variant="body2" style={{ fontWeight: "500", fontSize: "12px" }}>{parseFloat(invoice.total).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €</Typography>
                                        </Tooltip>
                                      </Grid>

                                      <Grid container item xs={4} md={2} justifyContent="center">
                                        <Typography style={{ textAlign: "center", fontSize: "10px" }} component="div" variant="body2"><span className="menu-heading-title">R-Nr.<br /></span>
                                          <span style={{ fontWeight: "600" }}>
                                          {JSON.parse(invoice.invoiceNr).map((item, i) => (
                                            <span>
                                            {item.type === "continuousNumber" &&
                                              <span style={{ background: "rgba(33, 150, 243, 0.1)", padding: "7.5px", borderRadius: "4px", marginLeft: "1px", marginRight: "1px" }}>{item.value}</span>
                                            }
                                            {item.type !== "continuousNumber" &&
                                              <span>{item.value}</span>
                                            }
                                            </span>
                                          ))}
                                          </span></Typography>
                                      </Grid>

                                      <Grid container item xs={4} md={2} justifyContent="center">
                                        <Typography style={{ textAlign: "center", fontSize: "10px" }} component="div" variant="body2"><span className="menu-heading-title">{type === "invoices" ? "R-Datum" : "Datum"}<br /></span><span style={{ fontWeight: "600" }}>{RETURN_DATE(invoice.invoiceDate)}</span></Typography>
                                      </Grid>

                                      {type === "invoices" &&
                                      <Grid container item xs={4} md={2} justifyContent="center">
                                        <Typography component="div" variant="body2" style={{ textAlign: "center", fontSize: "10px" }}><span className="menu-heading-title">Fällig am<br /></span><span style={{ fontWeight: "600" }}>{RETURN_DATE(invoice.dueDate)}<br /><span style={{ color: theme.palette.error.main }}></span></span></Typography>
                                      </Grid>
                                      }

                                      {type === "invoices" &&
                                      <Grid container item xs={12} md={1} justifyContent="center" className="invoice-overdue">
                                        <Typography component="div" variant="body2" style={{ textAlign: "center", fontSize: "10px" }}><span style={{ fontWeight: "600" }}><span style={{ color: theme.palette.error.main }}>{(RETURN_OVERDUE_DAYS(invoice.dueDate) > 0 && invoice.payState === "notPayed") && `${RETURN_OVERDUE_DAYS(invoice.dueDate)} Tag${RETURN_OVERDUE_DAYS(invoice.dueDate) > 1 ? "e" : ""} überfällig`}</span></span></Typography>
                                      </Grid>
                                      }

                                      {type === "estimates" &&
                                      <Grid container item xs={4} md={2} justifyContent="center" className="invoice-overdue">
                                        <Typography style={{ textAlign: "center", fontSize: "10px" }} component="div" variant="body2"><span style={{ fontWeight: "600" }}>{invoice.type === "estimate" ? "KV" : "Angebot"}</span></Typography>
                                      </Grid>
                                      }

                                      <Grid container item xs={12} md={3} alignItems="center" className="invoice-status">
                                          {(invoice.sendState !== "sent" && invoice.sendState !== "scheduled" && (invoice.payState === "notPayed" || invoice.payState === undefined)) &&
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", background: "#dbdef5 ", marginRight: "5px", width: "25px", height: "25px", borderRadius: "60px" }}>
                                              <EditIcon style={{ fontSize: "14px", color: "white" }} />
                                            </div>
                                          }

                                          {(invoice.sendState === "scheduled" && invoice.payState === "notPayed") &&
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", background: theme.palette.warning.light, marginRight: "5px", width: "25px", height: "25px", borderRadius: "60px" }}>
                                              <ScheduleIcon style={{ fontSize: "16px", color: "white" }} />
                                            </div>
                                          }

                                          {(invoice.sendState === "sent" && invoice.payState === "notPayed") &&
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", background: theme.palette.primary.dark, marginRight: "5px", width: "25px", height: "25px", borderRadius: "60px" }}>
                                              <MailIcon style={{ fontSize: "14px", color: "white" }} />
                                            </div>
                                          }

                                          {invoice.payState === "payed" &&
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", background: theme.palette.success.light, marginRight: "5px", width: "25px", height: "25px", borderRadius: "60px" }}>
                                              <CheckCircleIcon style={{ fontSize: "17px", color: "white" }} />
                                            </div>
                                          }

                                          {/* draft */}
                                          {(invoice.sendState !== "sent" && invoice.sendState !== "scheduled" && (invoice.payState === "notPayed" || invoice.payState === undefined)) &&
                                            <Typography component="div" variant="body2" style={{ textAlign: "left", fontSize: "10px" }}><span style={{ fontWeight: "600" }}>Entwurf</span></Typography>
                                          }

                                          {/* scheduled */}
                                          {(invoice.sendState === "scheduled" && invoice.payState === "notPayed") &&
                                            <Typography component="div" variant="body2" style={{ textAlign: "left", fontSize: "10px" }}><span>Wird gesendet am </span><br /><span style={{ fontWeight: "600" }}>{`${RETURN_DATE(invoice.sendDate)} um ${RETURN_HOURS(invoice.sendDate)}`}</span></Typography>
                                          }

                                          {/* sent */}
                                          {(invoice.sendState === "sent" && invoice.payState === "notPayed") &&
                                            <Typography component="div" variant="body2" style={{ textAlign: "left", fontSize: "10px" }}><span>Gesendet am</span><br /><span style={{ fontWeight: "600" }}>{RETURN_DATE(invoice.sendDate)}</span></Typography>
                                          }

                                          {/* payed */}
                                          {invoice.payState === "payed" &&
                                            <Typography component="div" variant="body2" style={{ textAlign: "left", fontSize: "10px" }}><span>Bezahlt am</span><br /><span style={{ fontWeight: "600" }}>{RETURN_DATE(invoice.payDate)}</span></Typography>
                                          }
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid container item xs={1} justifyContent="flex-end">
                                    <InvoicesMenuDots
                                      invoice={invoice}
                                      setOpenInvoiceDialogAtom={setOpenInvoiceDialogAtom}
                                      openInvoiceDialogAtom={openInvoiceDialogAtom}
                                    />
                                  </Grid>

                                </Grid>
                              </Grid>
                            ))
                          }

                          {/* APPLIED FILTER after Loading does result in list of length 0 */}
                          {!values.loading && !isLoading && Object.keys(values.invoicesFiltered).length === 0 &&
                            <Typography component="div" style={{ fontSize: "12px", padding: "10px 20px" }}>{t("PAGE_INVOICES.noResult")}</Typography>
                          }
                        </>
                      }

                      {/* NO INVOICES created yet */}
                      {!values.loading && !isLoading && invoicesFilteredInitial.length === 0  && type === "invoices" &&
                        <Typography component="div" style={{ fontSize: "12px", padding: "10px 20px" }}>{t("PAGE_INVOICES.noInvoicesCreated")}</Typography>
                      }

                      {/* NO ESTIMATES created yet */}
                      {!values.loading && !isLoading && invoicesFilteredInitial.length === 0  && type === "estimates" &&
                        <Typography component="div" style={{ fontSize: "12px", padding: "10px 20px" }}>Du hast noch keine Kostenvoranschläge oder Angebote angelegt</Typography>
                      }

                      {(values.loading || isLoading) &&
                        <Typography>Rechnungen werden geladen ...</Typography>
                      }
                    </>
                  }

                  {recurring &&
                  <Grid
                    item
                    className="sticky-nav box-shadow--2 h-padding--5-5 tool-menu"
                  >
                    <Grid container>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        item
                        style={{ paddingLeft: "19px"}}
                        xs={12}
                      >
                        <Grid container item xs={11} justifyContent="space-between">
                          <Grid container item alignItems="center" xs={12} lg={4}>
                            <div>
                              <Typography>Unternehmen</Typography>
                            </div>
                          </Grid>

                          <Grid container item justifyContent="flex-start" alignItems="center" xs={12} lg={8}>
                            <Grid container item xs={12} md={2} justifyContent="center">
                              <Typography>Betrag</Typography>
                            </Grid>

                            <Grid container item xs={4} md={2} justifyContent="center">
                              <Typography>Nr</Typography>
                            </Grid>

                            <Grid container item xs={4} md={2} justifyContent="center">
                              <Typography>Intervall</Typography>
                            </Grid>
                            
                            <Grid container item xs={12} md={3} justifyContent="center">
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid container item xs={1} justifyContent="flex-end">
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  }

                  {(!isLoading) &&
                    <>
                      {/* INVOICES already created */}
                      {(invoicesAtom.length !== 0 && recurring) &&
                        <>
                          {/* APPLIED FILTER after Loading doesn't result in list of length 0 */}
                          {Object.keys(values.invoicesFiltered).length !== 0 &&
                            (values.invoicesFiltered).map((invoice, i) => (
                              <Grid container key={i}>
                                <Grid
                                  container
                                  alignItems="center"
                                  justifyContent="space-between"
                                  item
                                  xs={12}
                                  className="h-bg--white user-clients"
                                  style={{
                                    borderBottom: "1px solid #e2ecfd",
                                    borderLeft:
                                      (invoice.sendState !== "sent" && invoice.sendState !== "scheduled" && (invoice.payState === "notPayed" || invoice.payState === undefined)) ? `5px solid #dbdef5` :
                                        (invoice.sendState === "scheduled" && invoice.payState === "notPayed") ? `5px solid ${theme.palette.warning.light}` :
                                          (invoice.sendState === "sent" && invoice.payState === "notPayed") ? `5px solid ${theme.palette.primary.dark}` :
                                            `5px solid ${theme.palette.success.light}`
                                  }}
                                >

                                  <Grid container item xs={11} justifyContent="space-between">
                                    <Grid container item alignItems="center" xs={12} lg={4}
                                      className="image-name-company"
                                      style={{ padding: "30px 15px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", flexWrap: "nowrap" }}
                                      onClick={() => setOpenInvoiceDialogAtom({
                                        ...openInvoiceDialogAtom,
                                        open: true,
                                        invoiceId: invoice.id,
                                        isEstimate: (invoice.type === "estimate" || invoice.type === "offer") ? true : false
                                      })}
                                    >

                                      <div style={{ width: "50px", minWidth: "50px", height: "25px", marginRight: "10px", position: "relative" }}>
                                        {(((clientsAtom).find(obj => obj._id === JSON.parse(invoice.clientData).id))).logo !== "" ? (
                                          <img
                                            src={`${((clientsAtom).find(obj => obj._id === JSON.parse(invoice.clientData)._id)).logo}`}
                                            alt="logo"
                                            style={{
                                              maxWidth: "100%",
                                              maxHeight: "100%",
                                              marginRight: "10px",
                                              position: "absolute",
                                              top: "50%",
                                              left: "50%",
                                              transform: "translate(-50%, -50%)"
                                            }}
                                          />
                                        ) :
                                          <img src={LogoMonogram} height={25} width={25} alt={`${t("APP.name")} Logo`} style={{ width: "50px", minWidth: "50px", marginRight: "10px", opacity: 0.2 }} />
                                        }
                                      </div>

                                      <Typography component="div" variant="body2" style={{ fontWeight: "500", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{JSON.parse(invoice.clientData).company}</Typography>
                                    </Grid>

                                    <Grid container item justifyContent="flex-start" alignItems="center" xs={12} lg={8}
                                      className="invoice-meta"
                                      style={{padding: "30px 15px", }}
                                      onClick={() => setOpenInvoiceDialogAtom({
                                        ...openInvoiceDialogAtom,
                                        open: true,
                                        invoiceId: invoice.id,
                                        isEstimate: (invoice.type === "estimate" || invoice.type === "offer") ? true : false
                                      })}
                                    >
                                      <Grid container item xs={12} md={2} justifyContent="center" className="invoice-total" style={{ background: "rgba(33, 150, 243, 0.1)", borderRadius: "4px", paddingTop: "5px", paddingBottom: "5px" }}>
                                        <Tooltip title={`${t("PAGE_INVOICES.total")} brutto`}>
                                          <Typography component="div" variant="body2" style={{ fontWeight: "500", fontSize: "12px" }}>{parseFloat(invoice.total).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €</Typography>
                                        </Tooltip>
                                      </Grid>

                                      <Grid container item xs={4} md={2} justifyContent="center">
                                        <Typography style={{ textAlign: "center", fontSize: "10px" }} component="div" variant="body2"><span className="menu-heading-title">R-Nr.<br /></span>
                                          <span style={{ fontWeight: "600" }}>
                                          {JSON.parse(invoice.invoiceNr).map((item, i) => (
                                            <span>
                                            {item.type === "continuousNumber" &&
                                              <span style={{ background: "rgba(33, 150, 243, 0.1)", padding: "7.5px", borderRadius: "4px", marginLeft: "1px", marginRight: "1px" }}>{item.value}</span>
                                            }
                                            {item.type !== "continuousNumber" &&
                                              <span>{item.value}</span>
                                            }
                                            </span>
                                          ))}
                                          </span></Typography>
                                      </Grid>

                                      <Grid container item xs={4} md={2} justifyContent="center">
                                        <Typography style={{ textAlign: "center", fontSize: "10px" }} component="div" variant="body2"><span className="menu-heading-title">{type === "invoices" ? "R-Datum" : "Datum"}<br /></span><span style={{ fontWeight: "600" }}>{RETURN_DATE(invoice.invoiceDate)}</span></Typography>
                                      </Grid>

                                      {type === "invoices" &&
                                      <Grid container item xs={4} md={2} justifyContent="center">
                                        <Typography component="div" variant="body2" style={{ textAlign: "center", fontSize: "10px" }}><span className="menu-heading-title">Fällig am<br /></span><span style={{ fontWeight: "600" }}>{RETURN_DATE(invoice.dueDate)}<br /><span style={{ color: theme.palette.error.main }}></span></span></Typography>
                                      </Grid>
                                      }

                                      {type === "invoices" &&
                                      <Grid container item xs={12} md={1} justifyContent="center" className="invoice-overdue">
                                        <Typography component="div" variant="body2" style={{ textAlign: "center", fontSize: "10px" }}><span style={{ fontWeight: "600" }}><span style={{ color: theme.palette.error.main }}>{(RETURN_OVERDUE_DAYS(invoice.dueDate) > 0 && invoice.payState === "notPayed") && `${RETURN_OVERDUE_DAYS(invoice.dueDate)} Tag${RETURN_OVERDUE_DAYS(invoice.dueDate) > 1 ? "e" : ""} überfällig`}</span></span></Typography>
                                      </Grid>
                                      }

                                      {type === "estimates" &&
                                      <Grid container item xs={4} md={2} justifyContent="center" className="invoice-overdue">
                                        <Typography style={{ textAlign: "center", fontSize: "10px" }} component="div" variant="body2"><span style={{ fontWeight: "600" }}>{invoice.type === "estimate" ? "KV" : "Angebot"}</span></Typography>
                                      </Grid>
                                      }

                                      <Grid container item xs={12} md={3} alignItems="center" className="invoice-status">
                                          {(invoice.sendState !== "sent" && invoice.sendState !== "scheduled" && (invoice.payState === "notPayed" || invoice.payState === undefined)) &&
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", background: "#dbdef5 ", marginRight: "5px", width: "25px", height: "25px", borderRadius: "60px" }}>
                                              <EditIcon style={{ fontSize: "14px", color: "white" }} />
                                            </div>
                                          }

                                          {(invoice.sendState === "scheduled" && invoice.payState === "notPayed") &&
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", background: theme.palette.warning.light, marginRight: "5px", width: "25px", height: "25px", borderRadius: "60px" }}>
                                              <ScheduleIcon style={{ fontSize: "16px", color: "white" }} />
                                            </div>
                                          }

                                          {(invoice.sendState === "sent" && invoice.payState === "notPayed") &&
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", background: theme.palette.primary.dark, marginRight: "5px", width: "25px", height: "25px", borderRadius: "60px" }}>
                                              <MailIcon style={{ fontSize: "14px", color: "white" }} />
                                            </div>
                                          }

                                          {invoice.payState === "payed" &&
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", background: theme.palette.success.light, marginRight: "5px", width: "25px", height: "25px", borderRadius: "60px" }}>
                                              <CheckCircleIcon style={{ fontSize: "17px", color: "white" }} />
                                            </div>
                                          }

                                          {/* draft */}
                                          {(invoice.sendState !== "sent" && invoice.sendState !== "scheduled" && (invoice.payState === "notPayed" || invoice.payState === undefined)) &&
                                            <Typography component="div" variant="body2" style={{ textAlign: "left", fontSize: "10px" }}><span style={{ fontWeight: "600" }}>Entwurf</span></Typography>
                                          }

                                          {/* scheduled */}
                                          {(invoice.sendState === "scheduled" && invoice.payState === "notPayed") &&
                                            <Typography component="div" variant="body2" style={{ textAlign: "left", fontSize: "10px" }}><span>Wird gesendet am </span><br /><span style={{ fontWeight: "600" }}>{`${RETURN_DATE(invoice.sendDate)} um ${RETURN_HOURS(invoice.sendDate)}`}</span></Typography>
                                          }

                                          {/* sent */}
                                          {(invoice.sendState === "sent" && invoice.payState === "notPayed") &&
                                            <Typography component="div" variant="body2" style={{ textAlign: "left", fontSize: "10px" }}><span>Gesendet am</span><br /><span style={{ fontWeight: "600" }}>{RETURN_DATE(invoice.sendDate)}</span></Typography>
                                          }

                                          {/* payed */}
                                          {invoice.payState === "payed" &&
                                            <Typography component="div" variant="body2" style={{ textAlign: "left", fontSize: "10px" }}><span>Bezahlt am</span><br /><span style={{ fontWeight: "600" }}>{RETURN_DATE(invoice.payDate)}</span></Typography>
                                          }
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid container item xs={1} justifyContent="flex-end">
                                    <InvoicesMenuDots
                                      invoice={invoice}
                                      setOpenInvoiceDialogAtom={setOpenInvoiceDialogAtom}
                                      openInvoiceDialogAtom={openInvoiceDialogAtom}
                                    />
                                  </Grid>

                                </Grid>
                              </Grid>
                            ))
                          }

                          {/* APPLIED FILTER after Loading does result in list of length 0 */}
                          {!values.loading && !isLoading && Object.keys(values.invoicesFiltered).length === 0 &&
                            <Typography component="div" style={{ fontSize: "12px", padding: "10px 20px" }}>{t("PAGE_INVOICES.noResult")}</Typography>
                          }
                        </>
                      }

                      {/* NO INVOICES created yet */}
                      {!values.loading && !isLoading && invoicesFilteredInitial.length === 0  && type === "invoices" &&
                        <Typography component="div" style={{ fontSize: "12px", padding: "10px 20px" }}>{t("PAGE_INVOICES.noInvoicesCreated")}</Typography>
                      }

                      {/* NO ESTIMATES created yet */}
                      {!values.loading && !isLoading && invoicesFilteredInitial.length === 0  && type === "estimates" &&
                        <Typography component="div" style={{ fontSize: "12px", padding: "10px 20px" }}>Du hast noch keine Kostenvoranschläge oder Angebote angelegt</Typography>
                      }

                      {(values.loading || isLoading) &&
                        <Typography>Rechnungen werden geladen ...</Typography>
                      }
                    </>
                  }
                </div>
              </Grid>
              

            </Box>
          </Container>
        </React.Fragment>
      }

      {(!values.loading || !isLoading) && !userAtom.data.signupCompleted &&
        <UserProfileIncompleteWarning
          headline={t("PAGE_INVOICES.h1")}
          text={t("PAGE_INVOICES.profileIncomplete")}
          icon={<ClientsIcon color="primary" fontSize="large" style={{ marginRight: "5px" }} />}
        />
      }

      {(!values.loading || !isLoading) && userAtom.data.signupCompleted && clientsAtom.length === 0 &&
        <UserProfileIncompleteWarning
          headline={t("PAGE_INVOICES.h1")}
          text={"Bitte lege zunächst einen Kunden an."}
          icon={<ClientsIcon color="primary" fontSize="large" style={{ marginRight: "5px" }} />}
          link={"/clients"}
          linkText={t("BUTTONS.back_to_clients")}
        />
      }

        
        <Fab color="primary" aria-label="add" className="add-invoice-fab" style={{ position: "fixed", bottom: "0", right: "0", margin: "20px"}}
          onClick={
            type === "invoices" ?
            () =>setOpenInvoiceDialogAtom({
            ...openInvoiceDialogAtom,
            open: true,
            isEstimate: false,
            recurring: recurring ? true : false
            })
            : () =>setOpenInvoiceDialogAtom({
              ...openInvoiceDialogAtom,
              open: true,
              isEstimate: true,
            })
          }
        >
           <AddIcon />
        </Fab>
        
    </div>
  );
};

export default Invoices;

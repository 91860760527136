import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { PDFDocument, rgb } from 'pdf-lib';

const WATERMARK_SIZE = 40;
const WATERMARK_MARGIN = 20;
const WATERMARK_FONT_SIZE = 20;
const A4_WIDTH = 595.28;
const A4_HEIGHT = 841.89;

const base64ToArrayBuffer = (base64) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

const createPDFWithExpenseDetails = async (headline, company, netExpense, vatExpense, grossExpense, index, watermark) => {
  const pdfDoc = await PDFDocument.create();
  const page = pdfDoc.addPage([A4_WIDTH, A4_HEIGHT]);
  const { width, height } = page.getSize();
  const fontSize = 12;
  const textFontSize = 10;

  page.drawText(headline, {
    x: 50,
    y: height - 4 * fontSize,
    size: fontSize,
    color: rgb(0, 0, 0),
  });

  page.drawText(`Lieferant: ${company}`, {
    x: 50,
    y: height - 6 * fontSize,
    size: textFontSize,
    color: rgb(0, 0, 0),
  });

  page.drawText(`Netto (in €): ${netExpense}`, {
    x: 50,
    y: height - 8 * fontSize,
    size: textFontSize,
    color: rgb(0, 0, 0),
  });

  page.drawText(`USt (in €): ${vatExpense}`, {
    x: 50,
    y: height - 10 * fontSize,
    size: textFontSize,
    color: rgb(0, 0, 0),
  });

  page.drawText(`Brutto (in €): ${grossExpense}`, {
    x: 50,
    y: height - 12 * fontSize,
    size: textFontSize,
    color: rgb(0, 0, 0),
  });

  // Add watermark if the flag is true
  if (watermark) {
    const pdf_WATERMARK_SIZE = 15;
    const pdf_WATERMARK_MARGIN = 20;
    const pdf_WATERMARK_FONT_SIZE = 10;
    const circleX = width - pdf_WATERMARK_SIZE - pdf_WATERMARK_MARGIN;
    const circleY = height - pdf_WATERMARK_SIZE - pdf_WATERMARK_MARGIN;

    page.drawEllipse({
      x: circleX + pdf_WATERMARK_SIZE / 2,
      y: circleY + pdf_WATERMARK_SIZE / 2,
      xScale: pdf_WATERMARK_SIZE,
      yScale: pdf_WATERMARK_SIZE,
      color: rgb(1, 1, 1, 1),
      borderColor: rgb(0, 0, 0),
      opacity: 0.5,
      borderWidth: 1,
    });

    // Center the text within the ellipse
    const textWidth = pdf_WATERMARK_FONT_SIZE / 2 * index.toString().length;
    const textX = circleX + (pdf_WATERMARK_SIZE / 2) - (textWidth / 2);
    const textY = circleY + (pdf_WATERMARK_SIZE / 2) - (pdf_WATERMARK_FONT_SIZE / 2);

    page.drawText(`${index}`, {
      x: textX,
      y: textY,
      size: pdf_WATERMARK_FONT_SIZE,
      color: rgb(0, 0, 0),
    });
  }

  const pdfBytes = await pdfDoc.save();
  return new Blob([pdfBytes], { type: 'application/pdf' });
};

const addWatermarkToImage = async (blob, index, watermark) => {
  const imageBitmap = await createImageBitmap(blob);
  const canvas = document.createElement('canvas');
  canvas.width = imageBitmap.width;
  canvas.height = imageBitmap.height;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(imageBitmap, 0, 0);

  // Add watermark if the flag is true
  if (watermark) {
    const circleX = canvas.width - WATERMARK_SIZE - WATERMARK_MARGIN;
    const circleY = WATERMARK_MARGIN + WATERMARK_SIZE;

    ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.arc(circleX, circleY, WATERMARK_SIZE, 0, 2 * Math.PI);
    ctx.fill();
    ctx.stroke();

    ctx.fillStyle = 'black';
    ctx.font = `${WATERMARK_FONT_SIZE}px Arial`;
    ctx.fillText(`${index + 99}`, circleX - 10, circleY + 10);
  }

  return new Promise((resolve) => {
    canvas.toBlob((watermarkedBlob) => {
      resolve(watermarkedBlob);
    });
  });
};

const DOWNLOAD_ZIP = async (data, watermark) => {
  const zip = new JSZip();

  for (const [index, item] of data.entries()) {
    let blob;
    let filename;
    const fileIndex = index + 100;

    if (item.type === "expense" && item.blob === undefined) {
      // Create a PDF with the headline "ohne Beleg" and expense details
      blob = await createPDFWithExpenseDetails(
        "Ohne Beleg",
        item.company,
        item.netExpense,
        item.vatExpense,
        item.grossExpense,
        fileIndex,
        watermark
      );
      filename = `${fileIndex}_Ausgabe_ohne_beleg.pdf`;
    } else {
      const base64String = item.blob.split(',')[1]; // Remove the data prefix
      const arrayBuffer = base64ToArrayBuffer(base64String);
      blob = new Blob([arrayBuffer], { type: item.blobtype === 'pdf' ? 'application/pdf' : item.blob.split(';')[0].split(':')[1] });

      // Add watermark to image if the flag is true
      if (item.blobtype !== 'pdf' && watermark) {
        blob = await addWatermarkToImage(blob, fileIndex, watermark);
      }

      filename = `${fileIndex}_${item.type === "invoice" ? "Einnahme" : "Ausgabe"}_${item.filename}${item.type === "invoice" ? ".pdf" : ""}`;

      if (item.blobtype === 'pdf') {
        const pdfDoc = await PDFDocument.load(await blob.arrayBuffer());
        const pages = pdfDoc.getPages();
        const page = pages[0];
        const { width, height } = page.getSize();

        if (watermark) {
          // Add watermark to PDF if the flag is true
          const pdf_WATERMARK_SIZE = 15;
          const pdf_WATERMARK_MARGIN = 20;
          const pdf_WATERMARK_FONT_SIZE = 10;
          const circleX = width - pdf_WATERMARK_SIZE - pdf_WATERMARK_MARGIN;
          const circleY = height - pdf_WATERMARK_SIZE - pdf_WATERMARK_MARGIN;

          page.drawEllipse({
            x: circleX + pdf_WATERMARK_SIZE / 2,
            y: circleY + pdf_WATERMARK_SIZE / 2,
            xScale: pdf_WATERMARK_SIZE,
            yScale: pdf_WATERMARK_SIZE,
            color: rgb(1, 1, 1, 1),
            borderColor: rgb(0, 0, 0),
            opacity: 0.5,
            borderWidth: 1,
          });

          // Center the text within the ellipse
          const textWidth = pdf_WATERMARK_FONT_SIZE / 2 * index.toString().length;
          const textX = circleX + (pdf_WATERMARK_SIZE / 2) - (textWidth / 2);
          const textY = circleY + (pdf_WATERMARK_SIZE / 2) - (pdf_WATERMARK_FONT_SIZE / 2);

          page.drawText(`${index + 1}`, {
            x: textX,
            y: textY,
            size: pdf_WATERMARK_FONT_SIZE,
            color: rgb(0, 0, 0),
          });

          const pdfBytes = await pdfDoc.save();
          blob = new Blob([pdfBytes], { type: 'application/pdf' });
        }
      } else {
        let extension = 'png'; // Default to png
        const mimeType = item.blob.split(';')[0].split(':')[1];

        if (mimeType === 'image/jpeg') {
          extension = 'jpeg';
        } else if (mimeType === 'image/jpg') {
          extension = 'jpg';
        }

        filename = item.type === "invoice" ? `${filename}.${extension}` : filename;
      }
    }

    zip.file(filename, blob);
  }

  // Generate the zip file and trigger the download
  const zipBlob = await zip.generateAsync({ type: 'blob' });
  saveAs(zipBlob, 'belege.zip');
};

export default DOWNLOAD_ZIP;



import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

const openInvoiceDesignerDialogAtom = atom({
  key: "openInvoiceDesignerDialog",
  default: {
    open: false,
    invoiceId: ""
  },
  effects_UNSTABLE: [persistAtom],
});

export default openInvoiceDesignerDialogAtom
export const DB_GET_THEME = async (settingsAtom, setDesignerAtom, auth, sendRequest) => {
  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL_API}/themes/${settingsAtom.invoicesSelectedTheme}`,
      "GET",
      null,
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      }
    )
    .then((res)=> setDesignerAtom(JSON.parse(res.theme.properties)))
  } catch (err) { }
};

export const DB_GET_THEMES = async (setThemesAtom, auth, sendRequest, values, setValues) => {
  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL_API}/themes/user/${auth.userId}`,
      "GET",
      null,
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      }
    )
    .then((res)=> {
      setThemesAtom(res.themes);
      setValues({...values, isLoading: false})
    })
  } catch (err) { }
};

export const DB_DELETE_THEME = async (
  theme,
  auth,
  sendRequest,
  enqueueSnackbar,
  t,
  themesAtom,
  setThemesAtom,
  setShowConfirmModal
  ) => {
  try {
    console.log(theme)
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL_API}/themes/${theme}`,
      "DELETE",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    )
    // NO ERROR
    .then(() => setShowConfirmModal(false))
    .then(() => setThemesAtom(themesAtom.filter((item) => item.id !== theme)))
    .then(() => enqueueSnackbar(t("FIELDS.saved"), { variant: "success" }))
  } catch (err) { 
    // ERROR
    console.log(err)
    enqueueSnackbar(t("FIELDS.error"), { variant: "error" })
  }
};

const VALIDATOR_EMAIL = /^\S+@\S+\.\S+$/;
const VALIDATOR_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
const VALIDATOR_BOOLEAN_TRUE = true;
const VALIDATOR_TEXT = 0;
const VALIDATOR_COUNTRY = undefined || "";
const VALIDATOR_VAT = (value, values) => {
  if (values.vat.state === "") {
    return false;
  } else {
      if (values.country.state.code === "AT" && value.length !== 8) {
        return true;
      } else if (values.country.state.code === "DE" && value.length !== 9) {
        return true;
      }
  }
}
const VALIDATOR_CLIENT_NUMBER = (value, values) => {
  if (
    value === "" // Check if client number field is empty.
    // || isNaN(value) // Check if client number entered is not a number
    // || values.clientNumberValues.arrClientNumbers.includes(parseInt(value)) // Check if client number entered is already in use.
    || values.clientNumberValues.arrClientNumbers.includes(value) // Check if client number entered is already in use.
  ) {
    return true;
  } else {
    return false
  }
}

const VALIDATORS = (field, value, values) => {
  let notValid = true;
  
  switch(field) {
    case "email":
      notValid = !VALIDATOR_EMAIL.test(value);
      break;
    case "password":
      notValid = !VALIDATOR_PASSWORD.test(value);
      break;
    case "terms":
      notValid = VALIDATOR_BOOLEAN_TRUE !== value;
      break;
    case "company":
    case "street":
    case "streetNr":
    case "zip":
    case "city":
    case "firstName":
    case "lastName":
      notValid = value <= VALIDATOR_TEXT;
      break;
    case "country":
      notValid = value === VALIDATOR_COUNTRY;
      break;
    case "vat":
      notValid = VALIDATOR_VAT(value, values);
      break;
    case "clientNumber":
      notValid = VALIDATOR_CLIENT_NUMBER(value, values);
      break;
    default:
      notValid = true
  }
  return notValid;
};

export const VALIDATE = (allFields, field, values, setValues) => {
    const validateConditions = {};

    if (field !== "all") {
      validateConditions[field] = VALIDATORS(field, values[field].state, values)
      if (validateConditions[field]) {
        setValues({ ...values, [field]: { ...values[field], error: true } });
      } else {
        setValues({ ...values, [field]: { ...values[field], error: false } });
      }
    }
   
    if (field === "all") {
      allFields.forEach ((item, i) => {
        validateConditions[item] = VALIDATORS(item, values[item].state, values)
      });

      const names = Object.getOwnPropertyNames(validateConditions); 

      names.forEach((name) => {
        if (validateConditions[name]) {
          setValues((prevValues) => {
            return { ...prevValues, [name]: { ...values[name], error: true }};
          });
        } else {
          setValues((prevValues) => {
            return { ...prevValues, [name]: { ...values[name], error: false }};
          });
        }
      });
    }
};

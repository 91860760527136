export const de_FIELDS = {
    saved: "Deine Änderungen wurden gespeichert.",
    error: "Deine Änderungen konnten nicht gespeichert werden.",
    email: {
        label: "E-Mail",
        labelInvoice: "E-Mail für Rechnungslegung",
        placeholder: "E-Mail",
        error: "Keine gültige Email-Adresse"
    },
    password: {
        label: "Passwort",
        placeholder: "Passwort",
        error: "Dein Passwort muss mindestens 8 Zeichen lang sein sowie einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl sowie eines der folgenden Sonderzeichen enthalten: @$!%*?&."
    },
    terms: {
        label1: "Ich habe die",
        label2: "AGB",
        label3: "und Bestimmungen zum",
        label4: "Datenschutz",
        label5: "gelesen und stimme zu.",
        placeholder: "Passwort",
        error: "Die Registrierung ist nur mit Zustimmung zu unseren AGB und Datenschutzbestimmungen möglich."
    },
    firstName: {
        label: "Vorname",
        placeholder: "Vorname",
        error: "Bitte Vorname eintragen",
    },
    lastName: {
        label: "Nachname",
        placeholder: "Nachname",
        error: "Bitte Nachname eintragen",
    },
    company: {
        label: "Name des Unternehmens",
        placeholder: "Name des Unternehmens",
        error: "Bitte Namen des Unternehmens angeben",
    },
    street: {
        label: "Straße",
        placeholder: "Straße",
        error: "Bitte Straße angeben",
    },
    streetNr: {
        label: "Nr.",
        placeholder: "Nr.",
        error: "Bitte Hausnummer angeben",
    },
    zip: {
        label: "PLZ",
        placeholder: "PLZ",
        error: "Bitte PLZ angeben",
    },
    city: {
        label: "Stadt/Ort",
        placeholder: "Stadt/Ort",
        error: "Bitte Stadt oder Ort angeben",
    },
    country: {
        label: "Land",
        placeholder: "Land",
        error: "Bitte Land auswählen",
    },
    vat: {
        label: "USt-IdNr./UID",
        placeholder: "UID",
        error: "Bitte gültige USt.-Identifikationsnummer angeben",
    },
    defaultVatPercentage: {
        label: "USt.-Satz"
    },
    precedingTitle: {
        label: "Titel (vorgestellt)",
        placeholder: "Titel (vorgestellt)",
    },
    followingTitle: {
        label: "Titel (nachgestellt)",
        placeholder: "Titel (nachgestellt)",
    },
    bank: {
        label: "Name Ihrer Bank",
        placeholder: "Name Ihrer Bank",
    },
    bankAccount: {
        label: "Kontonummer",
        placeholder: "Kontonummer",
    },
    bankAccountOwner: {
        label: "Kontoinhaber",
        placeholder: "Kontoinhaber",
    },
    bankZip: {
        label: "Bankleitzahl",
        placeholder: "Bankleitzahl",
    },
    bic: {
        label: "BIC",
        placeholder: "BIC",
    },
    iban: {
        label: "IBAN",
        placeholder: "IBAN",
    },
    phone: {
        label: "Telefonnummer",
        placeholder: "Telefonnummer",
    },
    mobile: {
        label: "Mobiltelefonnummer",
        placeholder: "Mobiltelefonnummer",
    },
    companyWebsite: {
        label: "URL Webseite",
        placeholder: "URL Webseite",
    },
    companyEmail: {
        label: "Email-Adresse auf Rechnung",
        placeholder: "Email-Adresse auf Rechnung",
    },
    fbnr: {
        label: "Firmenbuchnummer",
        placeholder: "Firmenbuchnummer",
    },
    clientNumber: {
        label: "Kundennummer",
        errorMissing: "Bitte tragen Sie eine Kundennummer ein",
        errorAlreadyExisting: "Diese Kundennummer existiert bereits"
    },
    clientStatus: {
        label: "Kundenstatus",
        optionActive: "Aktiver Kunde",
        optionInactive: "Inaktiver Kunde",
        optionProspect: "Möglicher Kunde (in Verhandlung)"
    },
    invoiceNumber: {
        label: "Rechnungsnummer",
        errorMissing: "Bitte tragen Sie eine Rechnungsnummer ein",
        errorAlreadyExisting: "Diese Rechnungsnummer existiert bereits"
    },
    invoiceDate: {
        label: "Rechnungsdatum"
    },
    invoiceDueDate: {
        label: "Fälligkeitsdatum"
    },
    invoiceStatus: {
        label: "Rechnungsstatus",
        optionDraft: "Entwurf",
        optionScheduled: "Versand geplant",
        optionSent: "Gesendet",
        optionPayed: "Bezahlt",
        optionOverdue: "Überfällig"
    },
    invoicesText: "Standard-Einleitungstext"
}

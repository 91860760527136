import React, { useEffect, useState, useContext } from 'react';

import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from '../../../shared/context/auth-context';
import { useAuth } from "../../../shared/hooks/auth-hook";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import invoicesAtomState from "../../../_atoms/invoicesAtom";
import expensesAtomState from "../../../_atoms/expensesAtom";
import settingsAtomState from "../../../_atoms/settingsAtom";
import userAtomState from "../../../_atoms/userAtom";
import clientsAtomState from "../../../_atoms/clientsAtom";
import openInvoiceDialogAtomState from "../../../_atoms/openInvoiceDialogAtom";



// IMPORT hooks & context
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';


// IMPORT components MATERIAL UI
import {
  Typography,
  Button,
  Grid,
  IconButton,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  Dialog,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  Tooltip
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import DateFnsUtils from '@date-io/date-fns';
import { format, startOfDay, endOfDay } from 'date-fns';
import { de } from "date-fns/locale";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

// IMPORT icons MATERIAL UI
import SaveIcon from '@material-ui/icons/Save';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CloseIcon from '@material-ui/icons/Close';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import ErrorIcon from '@material-ui/icons/Error';


import { RETURN_DATE } from '../../../_functions/DATES';
import { DB_PATCH_SETTINGS } from '../../../_functions/DB_SETTINGS';


// IMPORT own CSS
import "./DateRange.scss"


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">Zeitraum</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function DateRange({ type }) {

  
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { enqueueSnackbar } = useSnackbar();

  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');

  // GLOBAL STATE (RECOIL)
  const [invoicesAtom] = useRecoilState(invoicesAtomState);
  const [expensesAtom] = useRecoilState(expensesAtomState);
  const [settingsAtom, setSettingsAtom] = useRecoilState(settingsAtomState);


  // LOCAL STATE (PURE)
  const [types, setTypes] = useState(() => []);
  const [selectedType, setSelectedType] = useState(null);
  const [years, setYears] = useState(() => []);
  const [selectedYear, setSelectedYear] = useState(null);
  const quarters = ["first", "second", "third", "fourth"];
  const months = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember"
  ];

  const [open, setOpen] = useState(false);

  const [values, setValues] = useState({
    startDate: new Date(),
    endDate: new Date(),
    startDateError: false,
    endDateError: false,
  });

  useEffect(() => {
    setValues({
      ...values,
      startDate: (settingsAtom.dateRangeStartValue === undefined) ? new Date(new Date().getUTCFullYear(), 0, 1) : settingsAtom.dateRangeStartValue,
      endDate: (settingsAtom.dateRangeEndValue === undefined) ? new Date((new Date(new Date().getFullYear(), 11, 31)).setHours(23, 59, 59, 999)) : settingsAtom.dateRangeEndValue,
    })
  }, []);

  useEffect(() => {
    settingsAtom.dateRangeStartValue === undefined &&
    setSettingsAtom({
      ...settingsAtom,
      dateRangeStartValue: (settingsAtom.dateRangeStartValue === undefined) ? new Date(new Date().getUTCFullYear(), 0, 1) : settingsAtom.dateRangeStartValue,
      dateRangeEndValue: (settingsAtom.dateRangeEndValue === undefined) ? new Date((new Date(new Date().getFullYear(), 11, 31)).setHours(23, 59, 59, 999)) : settingsAtom.dateRangeEndValue,
    });

    settingsAtom.dateRangeStartValue === undefined ?
      setSelectedYear(new Date().getUTCFullYear())
      : setSelectedYear((new Date(settingsAtom.dateRangeEndValue)).getUTCFullYear())

    settingsAtom.dateRangeStartValue === undefined ?
      setSelectedYear(new Date().getUTCFullYear())
      : setSelectedYear((new Date(settingsAtom.dateRangeEndValue)).getUTCFullYear())

    }, []);


  // useEffect(() => {
  //   // console.log(invoicesAtom)
  //   let allYears = years
  //   for (var key in invoicesAtom) {
  //     // skip loop if the property is from prototype
  //     if (!invoicesAtom.hasOwnProperty(key)) continue;

  //     var obj = invoicesAtom[key];
  //     for (var prop in obj) {
  //         // skip loop if the property is from prototype
  //         if (!obj.hasOwnProperty(prop)) continue;

  //         // your code
  //         if (prop === "payDate" && obj["payState"] === "payed") {
  //           if (allYears.indexOf(new Date(obj[prop]).getUTCFullYear()) === -1) {
  //             allYears.push(new Date(obj[prop]).getUTCFullYear())
  //           }

  //           setYears(allYears.sort((a, b) => {return a - b}))
  //         }
  //     }
  //   }
  //   if(allYears.length === 0) {
  //     allYears.push(new Date().getFullYear())
  //   }
  // }, [invoicesAtom]);

  useEffect(() => {
    let allYears = years;  // Create a copy of the years array to avoid direct mutation
    for (var key in invoicesAtom) {
      // Skip loop if the property is from prototype
      if (!invoicesAtom.hasOwnProperty(key)) continue;
  
      var obj = invoicesAtom[key];
      for (var prop in obj) {
        // Skip loop if the property is from prototype
        if (!obj.hasOwnProperty(prop)) continue;
  
        // Check payDate if payState is payed
        if (prop === "payDate" && obj["payState"] === "payed") {
          const year = new Date(obj[prop]).getUTCFullYear();
          if (allYears.indexOf(year) === -1) {
            allYears.push(year);
          }
        }
  
        // Check invoiceDate
        if (prop === "invoiceDate") {
          const year = new Date(obj[prop]).getUTCFullYear();
          if (allYears.indexOf(year) === -1) {
            allYears.push(year);
          }
        }
  
        // Check dueDate
        if (prop === "dueDate") {
          const year = new Date(obj[prop]).getUTCFullYear();
          if (allYears.indexOf(year) === -1) {
            allYears.push(year);
          }
        }
      }
    }

    for (var key in expensesAtom) {
      if (!expensesAtom.hasOwnProperty(key)) continue;
  
      var obj = expensesAtom[key];
      for (var prop in obj) {
        // Skip loop if the property is from prototype
        if (!obj.hasOwnProperty(prop)) continue;
  
        // Check payDate if pay_date is payed
        if (prop === "pay_date") {
          const year = new Date(obj[prop]).getUTCFullYear();
          if ((allYears.indexOf(year) === -1) && !Number.isNaN(year)) {
            allYears.push(year);
          }
        }
      }
    }

  
    if (allYears.length === 0) {
      allYears.push(new Date().getFullYear());
    }
  
    setYears(allYears.sort((a, b) => a - b));
  }, [expensesAtom, invoicesAtom]);


  // useEffect(() => {
  //   let allYears = years
  //   for (var key in expensesAtom) {
  //     // skip loop if the property is from prototype
  //     if (!expensesAtom.hasOwnProperty(key)) continue;

  //     var obj = expensesAtom[key];
  //     for (var prop in obj) {
  //         // skip loop if the property is from prototype
  //         if (!obj.hasOwnProperty(prop)) continue;

  //         // your code
  //         if (prop === "pay_date") {
  //           if (allYears.indexOf(new Date(obj[prop]).getUTCFullYear()) === -1) {
  //             allYears.push(new Date(obj[prop]).getUTCFullYear())
  //           }

  //           setYears(allYears.sort((a, b) => {return b - a}))
  //         }
  //     }
  //   }
  // }, [expensesAtom]);


  function getFormattedDateStringForYearBasedOnString(dateString, newYear) {
    // Parse the date string into a Date object
    let originalDate = new Date(dateString);

    // Check if the date is valid
    if (isNaN(originalDate)) {
        return "Invalid date string";
    }

    // Extract the original month and day
    let month = originalDate.getMonth(); // 0 for January, 11 for December
    let day = originalDate.getDate();

    // Handle leap year for February 29th
    if (month === 1 && day === 29) { // February is month 1 (0-based)
        // Check if the new year is a leap year
        if ((newYear % 4 === 0 && newYear % 100 !== 0) || (newYear % 400 === 0)) {
            // The new year is a leap year, so February 29th is valid
            day = 29;
        } else {
            // The new year is not a leap year, adjust the date to February 28th
            day = 28;
        }
    }

    // Create a new Date object with the new year, original month, and adjusted day
    let newDate = new Date(newYear, month, day, originalDate.getHours(), originalDate.getMinutes(), originalDate.getSeconds());

    // Get the formatted date string
    let dateStringNew = newDate.toString();

    return dateStringNew;
}

  const handleYears = (year) => {
    setSelectedYear(year);
    setValues({
      startDate: getFormattedDateStringForYearBasedOnString(values.startDate, year),
      endDate: getFormattedDateStringForYearBasedOnString(values.endDate, year),
    });
  };

  const handleQuarters = (quarter) => {
    // setValues({
    //   startDate: (new Date(year, 0, 1, 0, 0, 0)).toString(),
    //   endDate: (new Date(year, 11, 31, 23, 59, 59)).toString(),
    // });
    if (quarter === "first") {
      setValues({
        startDate: (new Date(selectedYear, 0, 1, 0, 0, 0)).toString(),
        endDate: (new Date(selectedYear, 2, 31, 23, 59, 59)).toString(),
      });
    } else if (quarter === "second") {
      setValues({
        startDate: (new Date(selectedYear, 3, 1, 0, 0, 0)).toString(),
        endDate: (new Date(selectedYear, 5, 30, 23, 59, 59)).toString(),
      });
    } else if (quarter === "third") {
      setValues({
        startDate: (new Date(selectedYear, 6, 1, 0, 0, 0)).toString(),
        endDate: (new Date(selectedYear, 8, 30, 23, 59, 59)).toString(),
      });
    } else {
      setValues({
        startDate: (new Date(selectedYear, 9, 1, 0, 0, 0)).toString(),
        endDate: (new Date(selectedYear, 11, 31, 23, 59, 59)).toString(),
      });
    }
  };

  const handleMonths = (month) => {
    const daysInMonth = [
      31, // January
      isLeapYear(selectedYear) ? 29 : 28, // February
      31, // March
      30, // April
      31, // May
      30, // June
      31, // July
      31, // August
      30, // September
      31, // October
      30, // November
      31  // December
    ];
  
    function isLeapYear(year) {
      return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
    }
  
    setValues({
      startDate: (new Date(selectedYear, month, 1, 0, 0, 0)).toString(),
      endDate: (new Date(selectedYear, month, daysInMonth[month], 23, 59, 59)).toString(),
    });
  };


  const handleTypes = (type) => {
    setSelectedType(type);
  };


  useEffect(() => {
    if (type === "invoices") {
      setTypes(["invoiceDate", "payDate", "dueDate"]);
      if(settingsAtom.invoicesDateRange === "none") {
        setSelectedType("invoiceDate")
      } else {
        setSelectedType(settingsAtom.invoicesDateRange)
      }
    } else {
      setSelectedType(settingsAtom.invoicesDateRange)
    }
    }, []);



  const [anchorElType, setAnchorElType] = React.useState(null);

  const handleClickType = (event) => {
    setAnchorElType(event.currentTarget);
  };

  const handleCloseType = () => {
    setAnchorElType(null);
  };

  function TypeMenu() {
    return (
      <div>
        <Menu
          id="type-menu"
          anchorEl={anchorElType}
          keepMounted
          open={Boolean(anchorElType)}
          onClose={handleCloseType}
        >
          {types.map((type => (
            <MenuItem
              key={type}
              style={{ fontSize: "12px" }}
              onClick={() => {
                handleTypes(type);
                handleCloseType();
              }}
            >
            <span style={{ paddingRight: "5px" }}>{type === "payDate" ? "Zahldatum" : type === "invoiceDate" ? "Rechungsdatum" : "Fälligkeitsdatum"}</span>
            </MenuItem>
          )))
          }
        </Menu>
      </div>
    );
  }


  const handleCompleteYear = () => {
    setValues({
      startDate: (new Date(selectedYear, 0, 1, 0, 0, 0)).toString(),
      endDate: (new Date(selectedYear, 11, 31, 23, 59, 59)).toString(),
    });
  };

  const [anchorElYear, setAnchorElYear] = React.useState(null);

  const handleClickYear = (event) => {
    setAnchorElYear(event.currentTarget);
  };

  const handleCloseYear = () => {
    setAnchorElYear(null);
  };

  function YearMenu() {
    return (
      <div>
        <Menu
          id="year-menu"
          anchorEl={anchorElYear}
          keepMounted
          open={Boolean(anchorElYear)}
          onClose={handleCloseYear}
        >
          {years.map((year => (
            <MenuItem
              key={year}
              style={{ fontSize: "12px" }}
              onClick={() => {
                handleYears(year);
                handleCloseYear();
              }}
            >
            <span style={{ paddingRight: "5px" }}>{year}</span>
            </MenuItem>
          )))
          }
        </Menu>
      </div>
    );
  }

  const [anchorElQuarter, setAnchorElQuarter] = React.useState(null);

  const handleClickQuarter = (event) => {
    setAnchorElQuarter(event.currentTarget);
  };

  const handleCloseQuarter = () => {
    setAnchorElQuarter(null);
  };

  function QuarterMenu() {
    return (
      <div>
        <Menu
          id="quarter-menu"
          anchorEl={anchorElQuarter}
          keepMounted
          open={Boolean(anchorElQuarter)}
          onClose={handleCloseQuarter}
        >
          {quarters.map((quarter => (
            <MenuItem
              key={quarter}
              style={{ fontSize: "12px" }}
              onClick={() => {
                handleQuarters(quarter);
                handleCloseQuarter();
              }}
            >
            <span style={{ paddingRight: "5px" }}>{quarter === "first" ? "1. Quartal" : quarter === "second" ? "2. Quartal" : quarter === "third" ? "3. Quartal" : "4. Quartal"}</span>
            </MenuItem>
          )))
          }
        </Menu>
      </div>
    );
  }


  const [anchorElMonth, setAnchorElMonth] = React.useState(null);

  const handleClickMonth = (event) => {
    setAnchorElMonth(event.currentTarget);
  };

  const handleCloseMonth = () => {
    setAnchorElMonth(null);
  };

  function MonthMenu() {
    return (
      <div>
        <Menu
          id="month-menu"
          anchorEl={anchorElMonth}
          keepMounted
          open={Boolean(anchorElMonth)}
          onClose={handleCloseMonth}
        >
          {months.map((month, i) => (
            <MenuItem
              key={month}
              style={{ fontSize: "12px" }}
              onClick={() => {
                handleMonths(i);
                handleCloseMonth();
              }}
            >
            <span style={{ paddingRight: "5px" }}>{month}</span>
            </MenuItem>
          ))
          }
        </Menu>
      </div>
    );
  }


  return (
    <>
      <div className="date" onClick={()=>setOpen(!open)}>
        <Typography className="date-text" style={{ borderRadius: "4px", marginLeft: "8px", padding: "2px", fontSize: "12px", marginRight: "5px" }}>
          {type === "invoices" ? `${settingsAtom.invoicesDateRange === "payDate" ? "Bezahlung" : settingsAtom.invoicesDateRange === "invoiceDate" ? "Rechnungsdatum" : "Fälligkeitsdatum"} ` : type === "estimates" ?  "Datum" + " " : "Zahldatum" + " "}
          <span style={{ fontWeight: 600 }}>
          {`${settingsAtom.dateRangeStartValue === undefined ? format(new Date(new Date().getFullYear(), 0, 1), 'dd.MM.yyyy') : format(new Date(settingsAtom.dateRangeStartValue), 'dd.MM.yyyy')}
           - 
           ${settingsAtom.dateRangeEndValue === undefined ? format((new Date(new Date().getFullYear(), 11, 31)).setHours(23, 59, 59, 999), 'dd.MM.yyyy') : format(new Date(settingsAtom.dateRangeEndValue), 'dd.MM.yyyy')}
           `}
           </span>
          </Typography>
        
        <IconButton aria-label="date range" style={{ zoom: "0.8", color: "inherit" }}>
          <DateRangeIcon />
        </IconButton>
      </div>

      <Dialog
        onClose={() => {
          setOpen(!open);
          setSelectedType(settingsAtom.invoicesDateRange)
          setValues({
            ...values,
            startDateError: false,
            endDateError: false,
          })
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogTitle id="customized-dialog-title"
          onClose={() => {
            setOpen(!open);
            setSelectedType(settingsAtom.invoicesDateRange)
            setValues({
              ...values,
              startDateError: false,
              endDateError: false,
            })
          }}
          style={{ display: "flex" }}>
          <DateRangeIcon color="primary" /> {t("PAGE_INVOICES.dateRange")}
        </DialogTitle>

        <DialogContent dividers>
          <Grid item container spacing={1}>

          {type === "invoices" &&
          <Grid item container xs={12} md={12} spacing={1}>
              <List style={{ display: "flex", boxShadow: "rgba(108, 116, 173, 0.12) 0px 0px 20px 0px inset", borderRadius: "4px", padding: 0, marginLeft: "5px", marginTop: "8px", marginBottom: "8px"}}>
                  <ListItem
                    button
                    onClick={handleClickType}
                  >
                    <ListItemText >
                        <ListItemText primary={selectedType === "payDate" ? "Zahldatum" : selectedType === "invoiceDate" ? "Rechnungsdatum" : "Falligkeitsdatum"} />
                    </ListItemText>

                    <MoreVertIcon />
                  </ListItem>
                  <TypeMenu />
              </List>

              {selectedType === "payDate" &&
                <Typography style={{ marginLeft: "5px", fontSize: "10px", marginBottom: "7px", display: "flex", alignItems: "center", padding: "2px", background: "rgb(234, 242, 234)", borderRadius: "4px"  }}><ErrorIcon style={{ zoom: 0.9, marginRight: "6px" }}/><span>Bei Datumseingrenzung nach Zahldatum werden auschließlich Rechnungen mit dem Status 'bezahlt' angezeigt</span></Typography>
              }
          </Grid>
          }


            <Grid item container xs={12} md={12} spacing={1}>
              <List style={{ display: "flex", boxShadow: "rgba(108, 116, 173, 0.12) 0px 0px 20px 0px inset", borderRadius: "4px", padding: 0, marginLeft: "5px", marginTop: "8px", marginBottom: "8px"}}>
                  
                  {/* {((values.startDate !== (new Date(selectedYear, 0, 1, 0, 0, 0)).toString()) || (values.endDate !== (new Date(selectedYear, 11, 31, 23, 59, 59)).toString())) && */}
                  <Tooltip title="Gesamtes Jahr (01.01. bis 31.12.)">
                    <ListItem
                      button
                      onClick={handleCompleteYear}
                    >
                      <ListItemText >
                          <ListItemText primary={""} />
                      </ListItemText>

                      <SettingsEthernetIcon />
                    </ListItem>
                  </Tooltip>
                  {/* } */}

                  <ListItem
                    button
                    onClick={handleClickYear}
                  >
                    <ListItemText >
                        <ListItemText primary={"Jahr"} />
                    </ListItemText>

                    <MoreVertIcon />
                  </ListItem>
                  <YearMenu />

                  <ListItem
                    button
                    onClick={handleClickQuarter}
                  >
                    <ListItemText >
                        <ListItemText primary={"Quartal"} />
                    </ListItemText>

                    <MoreVertIcon />
                  </ListItem>
                  <QuarterMenu />

                  <ListItem
                    button
                    onClick={handleClickMonth}
                  >
                    <ListItemText >
                        <ListItemText primary={"Monat"} />
                    </ListItemText>

                    <MoreVertIcon />
                  </ListItem>
                  <MonthMenu />
                </List>
                <QuarterMenu />
            </Grid>

            <Grid item container xs={12} md={12} spacing={1}>
              <Grid item container spacing={1}>
                      <MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
                        <Grid item xs={12} md={6} className="datepicker" style={{ marginBottom: "5px" }}>
                          <KeyboardDatePicker
                            fullWidth
                            inputVariant="filled"
                            id="dateRangeStartValue"
                            format="dd.MM.yyyy"
                            label="Von"
                            value={values.startDate}
                            onChange={(date) => {
                              console.log(startOfDay(date).getFullYear())
                              if(
                                startOfDay(date).getFullYear() === (endOfDay(new Date(values.endDate)).getFullYear()) &&
                                startOfDay(date) < endOfDay(new Date(values.endDate))
                              ) {
                                setValues({
                                  ...values,
                                  startDate: startOfDay(date),
                                  startDateError: false
                                })
                              } else {
                                setValues({
                                  ...values,
                                  startDateError: true
                                })
                              }
                              // setSelectedYear(null);
                              // setSelectedQuarter(null);
                            }}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            okLabel="OK"
                            cancelLabel="Abbrechen"
                            error={values.startDateError}
                            helperText={values.startDateError ? "Das Startdatum muss im gleichen Jahr wie das Enddatum liegen und kleiner als das Enddatum sein." : ""}
                          />
                        </Grid>

                        <Grid item xs={12} md={6} className="datepicker">
                          <KeyboardDatePicker
                            fullWidth
                            inputVariant="filled"
                            id="dateRangeEndValue"
                            format="dd.MM.yyyy"
                            label="Bis"
                            value={values.endDate}
                            onChange={(date) => {
                              if(
                                date.getUTCFullYear() === (startOfDay(new Date(values.startDate)).getFullYear()) &&
                                date > startOfDay(new Date(values.startDate))
                              ) {
                                setValues({
                                  ...values,
                                  endDate: endOfDay(date).toString(),
                                  endDateError: false
                                })
                              } else {
                                setValues({
                                  ...values,
                                  endDateError: true
                                })
                              }
                              // setSelectedYear(null);
                              // setSelectedQuarter(null);
                            }}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            okLabel="OK"
                            cancelLabel="Abbrechen"
                            error={values.endDateError}
                            helperText={values.endDateError ? "Das Enddatum muss im gleich Jahr wie das Startdatum liegen und größer als das Startdatum sein." : ""}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              setOpen(!open);
              setValues({
                ...values,
                startDateError: false,
                endDateError: false,
              })
              setSelectedType(settingsAtom.invoicesDateRange)
            }}
          >
            {t("BUTTONS.cancel")}
          </Button>

          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setSettingsAtom({
                ...settingsAtom,
                dateRangeStartValue: values.startDate.toString(),
                dateRangeEndValue: values.endDate,
                invoicesDateRange: selectedType,
              });
              DB_PATCH_SETTINGS({ ...settingsAtom, invoicesDateRange: selectedType, dateRangeStartValue: values.startDate.toString(), dateRangeEndValue: values.endDate }, auth, sendRequest, enqueueSnackbar, t, false);
              setOpen(!open);
              setValues({
                ...values,
                startDateError: false,
                endDateError: false,
              })
            }}
            startIcon={<SaveIcon style={{ marginRight: "5px" }} />}
          >
            {t("BUTTONS.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export const de_PAGE_CHECKOUT = {
    newOrder: "Neue Bestellung",
    completedOrder: "Bestellung abgeschlossen",
    yourOrder: "Dein gewähltes Produkt",
    payCard: "Mit Kreditkarte bezahlen",
    steps: {
        step1: "Produkt",
        step2: "Bezahlung",
        step3: "Abschluss",
    },
    product: {
        compare: "PREMIUM & PRO vergleichen",
    },
    payment: {
        priceExclVat: "Preis exkl. USt.",
        vat: "% USt.",
        priceInclVat: "(inkl. USt.)",
        price: "Preis",
        addVatId: "USt.-Identifikationsnummer hinzufügen (für USt.-freie Verrechnung nach dem Reverse Charge Prinzip)",
        vatId1: "UID Nummer",
        vatId2: "Stellen, ohne Leerzeichen",
        cardLabel: "Kartennummer",
        validLabel: "Gültig bis",
        cvcLabel: "CVC",
        processing: "Ihre Zahlung wird bearbeitet",
        pleaseWait: "Bitte warten ..."
    },
    completion: {
        h1: "Gratulation!",
        text: "Du benutzt jetzt DagoWert"
    }
}

import React, { useState, useEffect } from 'react';


import { Document, Page, pdfjs } from 'react-pdf';


// IMPORT hooks & context
import { useTranslation } from "react-i18next";

// IMPORT components MATERIAL UI
import {
  Paper
} from "@material-ui/core";


import "./InvoicePdfPreview.scss"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



export default function InvoicePdfPreview({ instance }) {
  // HOOKS & CONTEXT
  const { t } = useTranslation();

  const [numPages, setNumPages] = useState(null);

   const [pdfString, setPdfString] = useState('');

  useEffect(() => {
    const file = new Blob(
      [instance.blob],
      { type: 'application/pdf' }
    );

    let base64String;
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = () => {
    base64String = reader.result;
    setPdfString(base64String.substr(base64String.indexOf(',') + 1));
  };


  }, [instance])

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  function pages() {
    let pages = []
    for (let i = 1; i <= numPages; i++) {
      pages.push(
        <Paper key={i} elevation={0} style={{margin: "20px auto", width: "fit-content", overflow: "hidden", boxShadow: "0px 3px 3px -2px #545ea51a, 0px 3px 4px 0px #545ea51a, 0px 1px 8px 0px #545ea51a"}}>
          <Page pageNumber={i} scale={2} />
        </Paper>
      );
    }
    return pages
  }

  return (
    <div className="invoice-pdf-preview">
        <Document
        file={`data:application/pdf;base64,${pdfString}`}
        // file={testpdf}
        onLoadSuccess={onDocumentLoadSuccess}
      >
       {pages()}
      </Document>
    </div>
  );
}

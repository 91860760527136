import React, {
  useState,
  useContext,
  useEffect,
} from "react";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";
import { countryToFlag } from "../../shared/functions/countryToFlag";
import { COUNTRIES } from "../../shared/util/countries_de";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from '../../shared/context/auth-context';
import { useAuth } from "../../shared/hooks/auth-hook";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import settingsAtomState from "../../_atoms/settingsAtom";
import openClientDialogAtomState from "../../_atoms/openClientDialogAtom";
import clientsAtomState from "../../_atoms/clientsAtom";
import clientsFilteredAtomState from "../../_atoms/clientsFilteredAtom";

// IMPORT functions
import { VALIDATE } from '../../_functions/VALIDATE';
import { SET_VALUES } from '../../_functions/SET_VALUES';
import { VAT } from '../../_functions/VAT';
import { DB_PATCH_SETTINGS } from '../../_functions/DB_SETTINGS';
import { DB_DELETE_CLIENT } from '../../_functions/DB_CLIENTS';

// IMPORT components
import { useSnackbar } from 'notistack';
import DropzoneUploaderSingle from "../../shared/components/DropzoneUploader/DropzoneUploaderSingle";
import ButtonLoadingBottom from "../../shared/components/UIElements/ButtonLoadingBottom";
import ClientNumber from "./components/ClientNumber";

// IMPORT components MATERIAL UI
import {
  Typography,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  Grid,
  Box,
  InputAdornment,
  TextField,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  ListItemIcon,
  ListItemText,
  BottomNavigation
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import Autocomplete from "@material-ui/lab/Autocomplete";

// IMPORT icons MATERIAL UI
import CloseIcon from "@material-ui/icons/Close";
import ClientsIcon from "@material-ui/icons/RecentActors";
import SaveIcon from '@material-ui/icons/Save';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

// IMPORT own components
import Logo from "../../_assets/logo_colored.svg";
import ButtonLoading from "../../shared/components/UIElements/ButtonLoading";
import ConfirmModal from "../../shared/components/UIElements/Modal";

// IMPORT own css
import "./ClientDialog.scss"

const useStyles = makeStyles((theme) => {
  return {
    toolbar: theme.mixins.toolbar,
    stepContent: {
      display: "flex",
      justifyContent: "center"
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  };
});

const ClientDialog = (props) => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const classes = useStyles();
  const { userId } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  // GLOBAL STATE (RECOIL)
  const [openClientDialogAtom, setOpenClientDialogAtom] = useRecoilState(openClientDialogAtomState);
  const [clientsAtom, setClientsAtom] = useRecoilState(clientsAtomState);
  const [, setClientsFilteredAtom] = useRecoilState(clientsFilteredAtomState);
  const [settingsAtom, setSettingsAtom] = useRecoilState(settingsAtomState);

  // LOCAL
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [settings, setSettings] = useState(settingsAtom);
  const [values, setValues] = useState({
    email: {
      state: "",
      error: false,
      errorMsg: t("FIELDS.email.error"),
    },
    firstName: {
      state: "",
      error: false,
      errorMsg: t("FIELDS.firstName.error"),
    },
    lastName: {
      state: "",
      error: false,
      errorMsg: t("FIELDS.lastName.error"),
    },
    company: {
      state: "",
      error: false,
      errorMsg: t("FIELDS.company.error"),
    },
    street: {
      state: "",
      error: false,
      errorMsg: t("FIELDS.street.error"),
    },
    streetNr: {
      state: "",
      error: false,
      errorMsg: t("FIELDS.streetNr.error"),
    },
    zip: {
      state: "",
      error: false,
      errorMsg: t("FIELDS.zip.error"),
    },
    city: {
      state: "",
      error: false,
      errorMsg: t("FIELDS.city.error"),
    },
    country: {
      state: "",
      error: false,
      errorMsg: t("FIELDS.country.error"),
    },
    precedingTitle: {
      // optional
      state: "",
    },
    followingTitle: {
      // optional
      state: ""
    },
    bank: {
      // optional
      state: "",
    },
    phone: {
      // optional
      state: "",
    },
    vat: {
      // optional
      state: "",
      digits: 0, // e.g. Austria: 8, Germany: 9 -> see https://www.finanz.at/steuern/umsatzsteuer/uid-nummer/
      code: "", // e.g. ATU, DE, etc ...
      error: false,
      errorMsg: t("FIELDS.vat.error"),
    },
    vatPercent: {
      // optional
      state: "",
    },
    image: {
      // optional
      state: "",
    },
    logo: "",
    showClientNumber: settingsAtom.clientsNumber === "manual" ? true : false,
    clientNumber: {
      state: [],
      error: false,
      errorMsg: t("FIELDS.clientNumber.errorMissing"),
    },
    clientNumberValues: {
      clientsLength: 0,
      arrClientNumbers: [0], // Array of all client numbers in use. 0 has to be default state to make client numbers start at 1.
      missingClientNumbers: [] // Client numbers that are "free" due to client deletion
    },
    status: {
      state: "",
    },
    loading: true
  });

  const [formState, inputHandler] = useForm(
    {
      image: {
        value: null,
        isValid: false,
      },
    },
    false
  );

  console.log(values.clientNumber.state)


  /********************* 
    Component Functions
  **********************/

  // INVOICE NUMBER MENU DOTS
  const [addElementOpen, setAddElementOpen] = useState(false);
  const [openClientNumberEdit, setOpenClientNumberEdit] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openClientNrMenuDots = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  useEffect(() => {
    // UPDATE SETTINGS: CONTINUOUS NUMBER IN SCHEMA when client is created (but only update it if clioent is saved => see clientHandler)
    let clientNumberItems = (typeof (settingsAtom.clientsNumberItems) === "string" ? JSON.parse(settingsAtom.clientsNumberItems) : settingsAtom.clientsNumberItems)
    const currentIndex = clientNumberItems.findIndex((elements) => elements.type === "continuousNumber");
    if (currentIndex !== -1) {
      let currentId = clientNumberItems[currentIndex].id;
      let currentValue = clientNumberItems[currentIndex].value;

      let objClientNumber = values.clientNumber.state.find(key => key.type === "continuousNumber");
      let objClientNumberContinuousNumber = objClientNumber !== undefined ? objClientNumber.value : "";

      const updatedItem = { ...clientNumberItems[currentIndex], id: currentId, type: "continuousNumber", value: objClientNumberContinuousNumber >= parseFloat(currentValue) ? parseFloat(objClientNumberContinuousNumber) + 1 : parseFloat(currentValue)};
      const newItems = [...clientNumberItems];
      newItems[currentIndex] = updatedItem;
      setSettings({ ...settings, clientsNumberItems: newItems });
    }
  }, [values.clientNumber]);

  const changeContinuousNumber = (e) => {

    const onlyContainsNumbers = (str) => /^\d+$/.test(str);
    if(!onlyContainsNumbers(e.target.value)) {
      setValues({
        ...values,
        clientNumber: {
          ...values.clientNumber,
          error: true,
          errorMsg: "Bitte eine ganzzahlige positive Nummer eintragen"
        }
      });
      return;
    }
  
    else {
      let clientNumberItems = (typeof (values.clientNumber.state) === "string" ? JSON.parse(values.clientNumber.state) : values.clientNumber.state)
      const currentIndex = clientNumberItems.findIndex((elements) => elements.type === "continuousNumber");
      if (currentIndex !== -1) {
        let currentId = clientNumberItems[currentIndex].id;
  
        const updatedItem = { ...clientNumberItems[currentIndex], id: currentId, type: "continuousNumber", value: parseFloat(e.target.value)};
        const newItems = [...clientNumberItems];
        newItems[currentIndex] = updatedItem;
        setValues({
          ...values,
          clientNumber: {
            ...values.clientNumber,
            error: false,
            errorMsg: "",
            state: newItems
          }
        });

        // Generic function: Get one client number out of client number items
        const streamlinedClientNr = (clientNumber) => {
          let nr = ""
          for (const [key, value] of Object.entries(clientNumber)) {
            nr += value
          }
          return nr;
        }

    
        // Get current (streamlined) invoice number
        // const currentInvoiceNumber = streamlinedInvoiceNr(values.invoiceNumber.state.map((item, i) => (item.value)))
    
        if (openClientDialogAtom.clientId === "" || (openClientDialogAtom.clientId !== "" && (streamlinedClientNr(JSON.parse(((clientsAtom.filter((client) => { return client.id === openClientDialogAtom.clientId }))[0]).clientNumber).map((item, i) => (item.value))) !== streamlinedClientNr(newItems.map((item, i) => (item.value)))))) {
          // If is NEW INVOICE
          // OR is UPDATE of EXISTING INVOICE AND invoice number (new) doesn't equal invoice number (old)
          let allClientNumbers = []
    
          const getAllClientNumbers = () => {
            // console.log(Object.entries(invoicesAtom[0]))

            for (var key in clientsAtom) {
              // skip loop if the property is from prototype
              if (!clientsAtom.hasOwnProperty(key)) continue;
          
              var obj = clientsAtom[key];
              for (var prop in obj) {
                  // skip loop if the property is from prototype
                  if (!obj.hasOwnProperty(prop)) continue;
          
                  // your code
                  if (prop === "clientNumber") {
                    // allInvoiceNumbers.push(streamlinedInvoiceNr(JSON.parse(value).map((item, i) => (item.value))))
                    allClientNumbers.push(streamlinedClientNr(JSON.parse(obj[prop]).map((item, i) => (item.value))));
                  }
              }
            }
          }
          getAllClientNumbers()

          
          if (allClientNumbers.indexOf(streamlinedClientNr(newItems.map((item, i) => (item.value)))) !== -1) {
            setValues({
              ...values,
              clientNumber: {
                ...values.clientNumber,
                error: true,
                errorMsg: "Diese Kundennummer existiert bereits",
              }
            });
          }        
        }
      }
    }
  }

  useEffect(() => {
    const streamlinedClientNr = (clientNumber) => {
      let nr = ""
      for (const [key, value] of Object.entries(clientNumber)) {
        nr += value
      }
      return nr;
    }

    const currentClientNumber = streamlinedClientNr(values.clientNumber.state.map((item, i) => (item.value)))

    // if(selectedClient && openClientDialogAtom.clientId === "") {
  
      let allClientNumbers = []
    
      const getAllClientNumbers = () => {
        // console.log(Object.entries(invoicesAtom[0]))
  
        for (var key in clientsAtom) {
          // skip loop if the property is from prototype
          if (!clientsAtom.hasOwnProperty(key)) continue;
      
          var obj = clientsAtom[key];
          for (var prop in obj) {
              // skip loop if the property is from prototype
              if (!obj.hasOwnProperty(prop)) continue;
      
              // your code
              if (prop === "clientNumber") {
                // allInvoiceNumbers.push(streamlinedInvoiceNr(JSON.parse(value).map((item, i) => (item.value))))
                allClientNumbers.push(streamlinedClientNr(JSON.parse(obj[prop]).map((item, i) => (item.value))));
              }
          }
        }
        }
        getAllClientNumbers()
        
        if(allClientNumbers.indexOf(currentClientNumber) !== -1) {
          setValues({
            ...values,
            clientNumber: {
              ...values.clientNumber,
              error: true,
              errorMsg: "Diese Rechnungsnummer existiert bereits",
            }
          });
        } else {
          setValues({
            ...values,
            clientNumber: {
              ...values.clientNumber,
              error: false,
              errorMsg: "",
            }
          });
        }
    // }
    }, []);

  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true);
  };

  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  };

  const confirmDeleteHandler = (client) => {
    DB_DELETE_CLIENT(
      client,
      auth,
      sendRequest,
      enqueueSnackbar,
      t,
      clientsAtom,
      setClientsAtom,
      openClientDialogAtom,
      setOpenClientDialogAtom,
      setShowConfirmModal
    );
  };

  console.log("hereeeee!!!")
  console.log(values.clientNumber.state)


  const clientHandler = async (event) => {
    event.preventDefault();

    if (
      userId &&
      auth.token &&
      !values.company.error &&
      !values.country.error &&
      !values.street.error &&
      !values.streetNr.error &&
      !values.zip.error &&
      !values.city.error &&
      !values.email.error &&
      !values.firstName.error &&
      !values.lastName.error &&
      !values.vat.error &&
      !values.clientNumber.error
    ) {
      try {
        const formData = new FormData();
        formData.append("company", values.company.state);
        formData.append("email", values.email.state);
        formData.append("phone", values.phone.state);
        formData.append("vatPercent", values.vatPercent.state);
        formData.append("vat", values.vat.state);
        formData.append("street", values.street.state);
        formData.append("streetNr", values.streetNr.state);
        formData.append("zip", values.zip.state);
        formData.append("city", values.city.state);
        formData.append("country", JSON.stringify(values.country.state));
        formData.append("precedingTitle", values.precedingTitle.state);
        formData.append("firstName", values.firstName.state);
        formData.append("lastName", values.lastName.state);
        formData.append("followingTitle", values.followingTitle.state);
        formData.append("clientNumber", JSON.stringify(values.clientNumber.state));
        formData.append("status", values.status.state);
        if (openClientDialogAtom.clientId !== "" && (formState.inputs.image.value === "/#" || formState.inputs.image.value === null)) {
          // Editing: image was not changed
          formData.append("image", values.image.state);
        } else if (openClientDialogAtom.clientId !== "" && (formState.inputs.image.value !== "/#" && formState.inputs.image.value !== null)) {
          // Editing: image was changed
          formData.append("image", formState.inputs.image.value);
        } else if (openClientDialogAtom.clientId === "" && (formState.inputs.image.value === "/#" || formState.inputs.image.value === null)) {
          // Creation: image was not uploaded
          formData.append("image", "/#");
        } else if (openClientDialogAtom.clientId === "" && (formState.inputs.image.value !== "/#" || formState.inputs.image.value !== null)) {
          // Creation: image was uploaded
          formData.append("image", formState.inputs.image.value);
        }
        formData.append("logo", values.logo);

        if (openClientDialogAtom.clientId === "") {
          // CREATE NEW CLIENT

          await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL_API}/clients`,
            "POST",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          )
          .then(() => DB_PATCH_SETTINGS(settings, auth, sendRequest, enqueueSnackbar, t, false))
          .then(() => setSettingsAtom(settings))
        } else if (openClientDialogAtom.clientId !== "") {
          // UPDATE EXISTING CLIENT
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL_API}/clients/${openClientDialogAtom.clientId}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          )
        }

        // FETCH updated Client List
        try {
          const resClients = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL_API}/clients/user/${userId}`,
            "GET",
            null,
            {
              Authorization: `Bearer ${auth.token}`
            }
          );
          setClientsAtom(resClients.clients);
          setClientsFilteredAtom(resClients.clients);
          setOpenClientDialogAtom({ ...openClientDialogAtom, open: false, clientId: "" });
          enqueueSnackbar(t("FIELDS.saved"), { variant: "success" });
        } catch (err) {
          // SERVER ERROR
          enqueueSnackbar(t("ERRORS.save_server"), { variant: "error" });
        }
      } catch (err) {
        // SERVER ERROR
        enqueueSnackbar(t("ERRORS.save_server"), { variant: "error" });
      }
    } else {
      enqueueSnackbar(t("ERRORS.save_form"), { variant: "error" });
    }
  };

  useEffect(() => {
  // If there are no invoices, avoid errors by setting length to 0
  let clientsLength = clientsAtom ? clientsAtom.length : 0;

  // Collect invoice numbers in use. Fallback has to be an array with 1 entry -> 0 ... to avoid errors if there are no invoices.https://v4.mui.com/api/drawer/
  let arrClientNumbersAll = [0] // All numbers including alphanumerical numbers (saved as string)
  let arrClientNumbers = [0] // Only the pure numbers saved as string)
  if (clientsAtom) {
    clientsAtom.forEach((item) => {
      arrClientNumbersAll.push(item.clientNumber);
      if (!isNaN(item.clientNumber)) {
        arrClientNumbers.push(item.clientNumber);
      }
    })
  }


  // Sort collected invoice numbers from 0 to n.
  let arrClientNumbersSorted = arrClientNumbers.sort(function (a, b) {
    return a - b;
  });

  // Get length of sorted array of invoice numbers in use.
  let N = arrClientNumbersSorted.length;

  let clientNumber;
  if (settingsAtom.clientsNumber === "automatic") {

    clientNumber = JSON.parse(clientsAtom[clientsLength - 1].clientNumber) + 1

  } else if (settingsAtom.clientsNumber === "schema") {
    clientNumber = [];
    (typeof (settingsAtom.clientsNumberItems) === "string" ? JSON.parse(settingsAtom.clientsNumberItems) : settingsAtom.clientsNumberItems).forEach((item) => {
      if (item.type === "continuousNumber") {
        clientNumber.push(item);
      } else if (item.type === "year") {
        clientNumber.push({id: item.id, type: "year", value: new Date().getFullYear()});
      } else if (item.type === "month") {
        clientNumber.push({id: item.id, type: "month", value: new Date().getMonth() + 1});
      } else if (item.type === "day") {
        clientNumber.push({id: item.id, type: "day", value: new Date().getDate()});
      } else {
        clientNumber.push(item);
      }
    })
  } else {
    clientNumber = ""
  }

    setValues(() => {
      if (openClientDialogAtom.clientId === "") {
        // If is NEW Client
        return ({
          ...values,
          clientNumberValues: {
            ...values.clientNumberValues,
            clientsLength: clientsLength,
            arrClientNumbers: arrClientNumbersAll,
          },
          clientNumber: {
            ...values.clientNumber,
            state: clientNumber
          },
          status: {
            ...values.status,
            state: "active"
          },
          loading: false
        })
      } else if (openClientDialogAtom.clientId !== "") {
        // If is EDITING MODE of existing client
        let client = clientsAtom.filter((client) => {
          return client.id === openClientDialogAtom.clientId;
        });

        return ({
          ...values,
          company: { ...values, state: client[0].company },
          country: { ...values, state: JSON.parse(client[0].country) },
          street: { ...values, state: client[0].street },
          streetNr: { ...values, state: client[0].streetNr },
          zip: { ...values, state: client[0].zip },
          city: { ...values, state: client[0].city },
          phone: { ...values, state: client[0].phone },
          email: { ...values, state: client[0].email },
          vat: { ...values, state: client[0].vat },
          vatPercent: { ...values, state: client[0].vatPercent },
          precedingTitle: { ...values, state: client[0].precedingTitle },
          firstName: { ...values, state: client[0].firstName },
          lastName: { ...values, state: client[0].lastName },
          followingTitle: { ...values, state: client[0].followingTitle },
          status: { ...values, state: client[0].status },
          image: { ...values, state: client[0].image },
          logo: client[0].logo,

          clientNumberValues: {
            ...values.clientNumberValues,
            clientsLength: clientsLength,
            arrClientNumbers: arrClientNumbersAll,
          },
          clientNumber: {
            ...values.clientNumber,
            state: JSON.parse(client[0].clientNumber)
          },

          loading: false
        })
      }
    });

    // if (settingsAtom.clientsNumber === "schema") {
    //   // UPDATE SETTINGS: CONTINUOUS NUMBER IN SCHEMA when client is Created
    //   let clientNumberItems = (typeof (settingsAtom.clientsNumberItems) === "string" ? JSON.parse(settingsAtom.clientsNumberItems) : settingsAtom.clientsNumberItems)
    //   const currentIndex = clientNumberItems.findIndex((elements) => elements.type === "continuousNumber");
    //   if (currentIndex !== -1) {
    //     let currentId = clientNumberItems[currentIndex].id;
    //     let currentValue = clientNumberItems[currentIndex].value;
    //     const updatedItem = { ...clientNumberItems[currentIndex], id: currentId, type: "continuousNumber", value: parseFloat(currentValue) + 1 };
    //     const newItems = [...clientNumberItems];
    //     newItems[currentIndex] = updatedItem;
    //     setSettings({ ...settings, clientsNumberItems: newItems });
    //   }
    // }
  }, []);

  const handleChange = async (e) => {
    clearError();
    SET_VALUES(e, values, setValues);
  };

  useEffect(() => {
    const vatData = VAT(values.country.state.code)

    if (values.country.state !== "") {
      // If statement prevents rendering when country is not set yet
      setValues({
        ...values,
        vat: {
          ...values.vat,
          code: vatData.vatCode,
          digits: vatData.vatDigits,
        }
      });
    }
  }, [values.country]);

  const validate = (field) => {
    // ONLY validate clientNumber if it is NEW CLIENT
    openClientDialogAtom.clientId === "" ?
      VALIDATE([
        "company",
        "country",
        "street",
        "streetNr",
        "zip",
        "city",
        "firstName",
        "lastName",
        "vat",
        "clientNumber",
        "email"
      ],
        field, values, setValues)
      :
      VALIDATE([
        "company",
        "country",
        "street",
        "streetNr",
        "zip",
        "city",
        "firstName",
        "lastName",
        "vat",
        "email"
      ],
        field, values, setValues)
  };

  return (
    <React.Fragment>
      <AppBar style={{ background: "#263238", paddingRight: "0" }}>
        <Toolbar style={{ justifyContent: "space-between" }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "30%" }}>
            <ClientsIcon fontSize="small" color="primary" style={{ marginRight: "5px" }} />
            <Typography variant="body1">{openClientDialogAtom.clientId !== "" ? t("PAGE_CLIENTS.editClient") : t("PAGE_CLIENTS.createClient")}</Typography>
          </div>

          <div style={{ width: "30%", display: "flex", justifyContent: "center" }}>
            <img
              src={Logo}
              alt={`${t("APP.name")} Logo`}
              style={{
                maxHeight: "40px",
              }}
            />
          </div>

          <div style={{ width: "30%", display: "flex", justifyContent: "end" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpenClientDialogAtom({
                ...openClientDialogAtom,
                open: false,
                clientId: ""
              })}
              aria-label={`${t("BUTTONS.AL_close")}`}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <React.Fragment>
        <div className={classes.toolbar} />
        <Container maxWidth={false} disableGutters className="bg--1">
          <Container maxWidth="lg" style={{ paddingTop: "25px", paddingBottom: "150px" }}>
            {!values.loading &&
              <>
                <Grid container item direction="row" alignItems="center" style={{ marginBottom: "20px" }}>
                  <ClientsIcon color="primary" fontSize="large" style={{ marginRight: "5px" }} />
                  <Typography variant="h5" component="h1" color="primary">{openClientDialogAtom.clientId !== "" ? t("PAGE_CLIENTS.editClient") : t("PAGE_CLIENTS.createClient")}</Typography>
                </Grid>

                <form onSubmit={clientHandler}>
                  <Box display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    // alignItems="center"
                    margin="0 25px"
                    className="h-gap--25"
                  >

                    <Box
                      display="flex"
                      flexDirection="column"
                      padding="25px 0px"
                      className="h-gap--20"
                    >
                      <Grid
                        container
                        spacing={5}
                        direction="row"
                        alignContent="flex-start"
                        alignItems="flex-start"
                        className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                      >
                        <Grid container item lg={12} spacing={3} >
                          <Grid container item direction="row" alignItems="center" justifyContent="space-between">
                            <Typography variant="h6" component="h2">{t("PAGE_CLIENTS.company.h2")}</Typography>
                          </Grid>

                          <Grid container item spacing={1}>
                            <Grid item xs={12} style={{ margin: 0 }}>
                            <ClientNumber
                                values={values}
                                isLoading={isLoading}
                                changeContinuousNumber={changeContinuousNumber}
                                handleClick={handleClick}
                                anchorEl={anchorEl}
                                openClientNrMenuDots={openClientNrMenuDots}
                                handleClose={handleClose}
                                addElementOpen={addElementOpen}
                                setAddElementOpen={setAddElementOpen}
                                setValues={setValues}
                                setOpenClientNumberEdit={setOpenClientNumberEdit}
                              />
                              </Grid>
                              {/* <Grid item sm={12} md={4} > */}
                             

                                {/* <Grid item container direction="row" alignItems="center" style={{ background: "#6a6f8d", borderRadius: "4px", height: "56px", paddingLeft: "15px" }}>
  

  <Grid item container>
    <Typography style={{ fontSize: "12px", color: "white", marginBottom: "-10px"}}>Kundennummer</Typography>
  </Grid>
  {(values.clientNumber.state).map((item, i) => (
  <Grid key={item.id} item >
    {item.type === "continuousNumber" &&
    <Typography>
      <input
        className="badge-input"
        defaultValue={item.value}
        placeholder={`Nr.`}
        onChange={(e) => {
          e.target.value.length !== 0 && e.target.setAttribute('size', e.target.value.length);
          changeContinuousNumber(e);
        }}
        style={{ fontSize: "16px", textAlign: "center", background: "white", paddingTop: "2px", paddingBottom: "2px", marginLeft: "7px", marginRight: "7px", borderRadius: "2px" }}
        size={JSON.stringify(item.value).length}
      />
    </Typography>
    }

    {item.type === "text" &&
    <Typography style={{ color: "white", fontSize: "16px" }}>{item.value}</Typography>
    }

    {item.type === "year" &&
    <Typography style={{ color: "white", fontSize: "16px" }}>{item.value}</Typography>
    }

    {item.type === "month" &&
    <Typography style={{ color: "white", fontSize: "16px" }}>{item.value}</Typography>
    }

    {item.type === "day" &&
    <Typography style={{ color: "white", fontSize: "16px" }}>{item.value}</Typography>
    }

  </Grid>
  ))}
</Grid> */}

{/* {values.clientNumber.error &&
<p style={{ color: theme.palette.error.main }}>{values.clientNumber.errorMsg}</p>
}














                            </Grid> */}

                            <Grid item xs={12} sm={8}>
                              <TextField
                                autoComplete="off"
                                defaultValue={values.company.state}
                                id="company"
                                label={`${t("FIELDS.company.label")} *`}
                                variant="filled"
                                type="text"
                                error={values.company.error}
                                helperText={
                                  values.company.error ? values.company.errorMsg : ""
                                }
                                onChange={handleChange}
                                onBlur={() => validate("company")}
                                fullWidth
                              // InputProps={{
                              //   inputProps: { style: { background: '#f0f3ff' }},
                              //   disableUnderline: true,
                              // }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Autocomplete
                                fullWidth
                                autoComplete={false}
                                defaultValue={values.country.state}
                                value={values.country.state || null}
                                getOptionSelected={(option, value) => option.label === value.label} // prevents infinite loop!!!
                                id="country"
                                onChange={(event, value) =>
                                  handleChange({
                                    target: {
                                      id: "country",
                                      value: value
                                    }
                                  })
                                }
                                disableClearable
                                options={COUNTRIES}
                                classes={{
                                  option: classes.option,
                                }}
                                autoHighlight
                                getOptionLabel={(option) => `${countryToFlag(option.code)} ${option.label}`}
                                renderOption={(option) => (
                                  <React.Fragment>
                                    <span style={{ marginRight: "5px" }}>{countryToFlag(option.code)} </span>
                                    {option.label}
                                  </React.Fragment>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={`${t("FIELDS.country.label")} *`}
                                    variant="filled"
                                    onBlur={() => validate("country")}
                                    error={values.country.error}
                                    helperText={
                                      values.country.error ? values.country.errorMsg : ""
                                    }
                                    value={values.country.state}
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                              />
                            </Grid>

                            <Grid item xs={12} sm={8} lg={4}>
                              <TextField
                                autoComplete="off"
                                defaultValue={values.street.state}
                                id="street"
                                label={`${t("FIELDS.street.label")} *`}
                                variant="filled"
                                type="text"
                                error={values.street.error}
                                helperText={
                                  values.street.error ? values.street.errorMsg : ""
                                }
                                onChange={handleChange}
                                onBlur={() => validate("street")}
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} sm={4} lg={2}>
                              <TextField
                                autoComplete="off"
                                defaultValue={values.streetNr.state}
                                id="streetNr"
                                label={`${t("FIELDS.streetNr.label")} *`}
                                variant="filled"
                                type="text"
                                error={values.streetNr.error}
                                helperText={
                                  values.streetNr.error ? values.streetNr.errorMsg : ""
                                }
                                onChange={handleChange}
                                onBlur={() => validate("streetNr")}
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} sm={4} lg={2}>
                              <TextField
                                autoComplete="off"
                                defaultValue={values.zip.state}
                                id="zip"
                                label={`${t("FIELDS.zip.label")} *`}
                                variant="filled"
                                type="text"
                                error={values.zip.error}
                                helperText={values.zip.error ? values.zip.errorMsg : ""}
                                onChange={handleChange}
                                onBlur={() => validate("zip")}
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} sm={8} lg={4}>
                              <TextField
                                autoComplete="off"
                                defaultValue={values.city.state}
                                id="city"
                                label={`${t("FIELDS.city.label")} *`}
                                variant="filled"
                                type="text"
                                error={values.city.error}
                                helperText={values.city.error ? values.city.errorMsg : ""}
                                onChange={handleChange}
                                onBlur={() => validate("city")}
                                fullWidth
                              />
                            </Grid>
                            {/* 
                            <Grid item xs={12}>
                              <Typography variant="subtitle2" component="div"><br />{t("PAGE_CLIENTS.companyContact")}</Typography>
                            </Grid> */}

                            <Grid item xs={12} md={8} lg={3}>
                              <TextField
                                autoComplete="off"
                                defaultValue={values.email.state}
                                id="email"
                                label={`${t("FIELDS.email.labelInvoice")} *`}
                                variant="filled"
                                type="text"
                                error={values.email.error}
                                helperText={values.email.error ? values.email.errorMsg : ""}
                                onBlur={() => validate("email")}
                                onChange={handleChange}
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} md={4} lg={3}>
                              <TextField
                                autoComplete="off"
                                defaultValue={values.phone.state}
                                id="phone"
                                label={`${t("FIELDS.phone.label")}`}
                                variant="filled"
                                type="text"
                                onChange={handleChange}
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} lg={3}>
                              <TextField
                                // required={values.vat.state !== ""}
                                autoComplete="off"
                                defaultValue={values.vat.state}
                                disabled={values.country.state === "" ? true : false}
                                id="vat"
                                label={`${t("FIELDS.vat.label")}`}
                                variant="filled"
                                type="text"
                                onChange={handleChange}
                                onBlur={() => validate("vat")}
                                error={values.vat.error}
                                helperText={values.vat.error ? values.vat.errorMsg : ""}
                                fullWidth
                                InputProps={{
                                  startAdornment: <InputAdornment position="start" style={{ backgroundColor: "#ffffff", borderRadius: "5px", padding: "0 5px", height: "100%" }}><span style={{ fontSize: "75%" }}>{values.vat.code}</span></InputAdornment>,
                                }}
                              />
                            </Grid>

                            <Grid container item lg={3}>
                              <TextField
                                label={`${t("FIELDS.defaultVatPercentage.label")}`}
                                id="vatPercent"
                                variant="filled"
                                onChange={handleChange}
                                defaultValue={values.vatPercent.state}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">
                                    <span style={{ color: "inherit" }}>%</span>
                                  </InputAdornment>
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      flexDirection="column"
                      padding="25px 0px"
                      className="h-gap--20"
                    >
                      <Grid
                        container
                        spacing={5}
                        direction="row"
                        alignContent="flex-start"
                        alignItems="flex-start"
                        className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                      >
                        <Grid container item lg={12} spacing={2}>
                          <Grid container item direction="row" alignItems="center">
                            <Typography variant="h6" component="h2">{t("PAGE_CLIENTS.person.h2")}</Typography>
                          </Grid>

                          <Grid container item spacing={1}>
                            <Grid container item xs={12} lg={6} spacing={1}>
                              <Grid item xs={12} sm={5}>
                                <TextField
                                  defaultValue={values.precedingTitle.state}
                                  id="precedingTitle"
                                  label={`${t("FIELDS.precedingTitle.label")}`}
                                  variant="filled"
                                  type="text"
                                  onChange={handleChange}
                                  fullWidth
                                />
                              </Grid>

                              <Grid item xs={12} sm={7}>
                                <TextField
                                  defaultValue={values.firstName.state}
                                  id="firstName"
                                  label={`${t("FIELDS.firstName.label")} *`}
                                  variant="filled"
                                  type="text"
                                  error={values.firstName.error}
                                  helperText={
                                    values.firstName.error
                                      ? values.firstName.errorMsg
                                      : ""
                                  }
                                  onChange={handleChange}
                                  onBlur={() => validate("firstName")}
                                  fullWidth
                                />
                              </Grid>
                            </Grid>

                            <Grid container item xs={12} lg={6} spacing={1}>
                              <Grid item xs={12} sm={7}>
                                <TextField
                                  defaultValue={values.lastName.state}
                                  id="lastName"
                                  label={`${t("FIELDS.lastName.label")} *`}
                                  variant="filled"
                                  type="text"
                                  error={values.lastName.error}
                                  helperText={
                                    values.lastName.error ? values.lastName.errorMsg : ""
                                  }
                                  onChange={handleChange}
                                  onBlur={() => validate("lastName")}
                                  fullWidth
                                />
                              </Grid>

                              <Grid item xs={12} sm={5}>
                                <TextField
                                  defaultValue={values.followingTitle.state}
                                  id="followingTitle"
                                  label={`${t("FIELDS.followingTitle.label")}`}
                                  variant="filled"
                                  type="text"
                                  onChange={handleChange}
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      flexDirection="column"
                      padding="25px 0px"
                      className="h-gap--20"
                    >
                      <Grid
                        container
                        spacing={5}
                        direction="row"
                        alignContent="flex-start"
                        alignItems="flex-start"
                        className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                      >
                        <Grid container item xs={12} spacing={2}>
                          <Grid container item direction="row" alignItems="center">
                            <Typography variant="h6" component="h2">{t("PAGE_CLIENTS.logo.h2")}</Typography>
                          </Grid>

                          <Grid container item xs={12}>
                            <Grid item xs={12}>
                              <Typography variant="body2" component="p" style={{ marginBottom: "10px" }}>Kunden-Logos helfen dabei z.B. Rechnungen schneller zu finden.</Typography>
                            </Grid>

                            <DropzoneUploaderSingle
                              values={values}
                              setValues={setValues}
                              buttonText="Logo hochladen"
                              description="Hier klicken oder Bild in den Rahmen ziehen um den Logo-Upload zu starten. Mögliche Dateiformate: JPG und PNG"
                              accept="image/jpg, image/jpeg, image/png"
                              logo={values.logo}
                              multiple={false}
                              maxFiles={1}
                              disabled={false}
                              readData={false}
                            />
                          </Grid>
                        </Grid>

                        <Grid container item lg={6} spacing={2}>
                          <Grid container item direction="row" alignItems="center">
                            <Typography variant="h6" component="h2">{t("PAGE_CLIENTS.status.h2")}</Typography>
                          </Grid>

                          <Grid container item xs={12} lg={12}>
                            <FormControl variant="filled" id="status" className={classes.formControl} fullWidth>
                              <InputLabel id="status">{t("FIELDS.clientStatus.label")}</InputLabel>
                              <Select
                                labelId="status"
                                id="status"
                                name="status"
                                defaultValue={values.status.state}
                                onChange={handleChange}
                                className="select-status"
                              >
                                <MenuItem value={"active"}>
                                  <ListItemIcon style={{ minWidth: "auto", marginRight: "5px" }}>
                                    <FiberManualRecordIcon style={{ color: theme.palette.success.light }} />
                                  </ListItemIcon>
                                  <ListItemText primary={t("FIELDS.clientStatus.optionActive")} />
                                </MenuItem>

                                <MenuItem value={"inactive"}>
                                  <ListItemIcon style={{ minWidth: "auto", marginRight: "5px" }}>
                                    <FiberManualRecordIcon style={{ color: theme.palette.error.main }} />
                                  </ListItemIcon>
                                  <ListItemText primary={t("FIELDS.clientStatus.optionInactive")} />
                                </MenuItem>

                                <MenuItem value={"prospect"}>
                                  <ListItemIcon style={{ minWidth: "auto", marginRight: "5px" }}>
                                    <FiberManualRecordIcon style={{ color: theme.palette.warning.light }} />
                                  </ListItemIcon>
                                  <ListItemText primary={t("FIELDS.clientStatus.optionProspect")} />
                                </MenuItem>
                                {/* <MenuItem value={"active"}><FiberManualRecordIcon color={theme.palette.success.light}/>Aktiver Kunde</MenuItem> */}
                                {/* <MenuItem value={"inactive"}>Inaktiver Kunde</MenuItem>
                                <MenuItem value={"prospect"}>Möglicher Kunde (in Verhandlung)</MenuItem> */}
                              </Select>
                            </FormControl>
                          </Grid>

                          {openClientDialogAtom.clientId !== "" &&
                            <Grid container item xs={12} lg={12}>
                              <Typography variant="body2" component="div" onClick={() => showDeleteWarningHandler(clientsAtom.id)} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                                <DeleteForeverIcon style={{ marginRight: "5px" }} />{t("BUTTONS.clientDelete")}
                              </Typography>
                            </Grid>
                          }
                        </Grid>
                      </Grid>
                    </Box>

                    <Grid container item lg={12} spacing={2} direction="column" alignItems="center">

                      <Grid container item lg={12} spacing={2} justifyContent="center" alignItems="center">
                        <Grid item>
                          <BottomNavigation
                            showLabels
                            style={{ position: "fixed", left: 0, width: "100%", zIndex: 999, bottom: 0, background: "transparent", margin: "0 auto" }}
                            className="box-shadow--2"
                          >
                            <ButtonLoadingBottom
                              loading={isLoading}
                              disabled={isLoading}
                              color="primary"
                              type="submit"
                              size="large"
                              className="button--loading"
                              borderTopLeftRadius={"10px"}
                              borderTopRightRadius="10px"
                              startIcon={<SaveIcon />}
                              onClick={() => validate("all")}
                            >
                              Speichern
                            </ButtonLoadingBottom>
                          </BottomNavigation>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </form>
              </>
            }

            {values.loading &&
              <div className="center">
                <CircularProgress />
              </div>
            }

            <ConfirmModal
              show={showConfirmModal}
              onCancel={cancelDeleteHandler}
              header={t("BUTTONS.clientDelete")}
              footerClass="client-item__modal-actions"
              style={{ zIndex: "9999999" }}
              footer={
                <React.Fragment>
                  <Button
                    variant="outlined"
                    style={{ marginRight: "10px" }}
                    onClick={cancelDeleteHandler}
                  >
                   {t("BUTTONS.cancel")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => confirmDeleteHandler(openClientDialogAtom.clientId)}
                  >
                    {t("BUTTONS.delete")}
                  </Button>
                </React.Fragment>
              }
            >
              <p>
                {t("PAGE_CLIENTS.clientDelete")}
              </p>
            </ConfirmModal>


          </Container>
        </Container>
      </React.Fragment>


    </React.Fragment>
  );
};

export default ClientDialog;

import React from "react";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import selectedMenuItemAtomState from "../../_atoms/selectedMenuItemAtom";
import subMenuOpenAtomState from "../../_atoms/subMenuOpenAtom";
import openClientDialogAtomState from "../../_atoms/openClientDialogAtom";


// IMPORT hooks & context
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

// IMPORT components MATERIAL UI
import {
  Typography,
  Box,
  Container,
  Grid,
  Button
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const UserProfileIncompleteWarning = (props) => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();

  const [selectedMenuItemAtom, setSelectedMenuItemAtom] = useRecoilState(selectedMenuItemAtomState);
  const [subMenuOpenAtom, setSubMenuOpenAtom] = useRecoilState(subMenuOpenAtomState);
  const [openClientDialogAtom, setOpenClientDialogAtom] = useRecoilState(openClientDialogAtomState);

  return (
    <Container maxWidth={false} disableGutters className="bg--1">
      <Container style={{ paddingTop: "25px" }}>


        <Box display="flex"
          flexDirection="column"
          justifyContent="center"
          className="h-gap--25"
        >
          <Box
            display="flex"
            flexDirection="column"
          >
            <Grid
              container
              item
              direction="column"
              xs={12}
            >
              <Alert severity="warning" style={{marginBottom: "20px"}}>
                <Typography>{props.text}</Typography>
              </Alert>
              
              {props.link === undefined &&
              <NavLink to={"/profile"}>
                  <Button 
                    onClick={()=> {
                      setSelectedMenuItemAtom(5);
                      setSubMenuOpenAtom(true);
                    }}
                    color="primary"
                    variant="contained">{t("BUTTONS.back_to_profile")}
                  </Button>
              </NavLink>
              }
              {props.link !== undefined &&
              <NavLink to={props.link}>
                  <Button
                     onClick={()=> {
                      setOpenClientDialogAtom({
                        ...openClientDialogAtom,
                        open: true,
                      })
                      setSelectedMenuItemAtom(1);
                      setSubMenuOpenAtom(true);
                    }}
                    color="primary" 
                    variant="contained">{props.linkText}</Button>
              </NavLink>
              }
            </Grid>
          </Box>
        </Box>
      </Container>
    </Container>
  );
};

export default UserProfileIncompleteWarning;

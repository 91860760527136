import React, { useState, useEffect } from "react";

// IMPORT hooks & context
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { useTranslation } from "react-i18next";

// IMPORT functions
import { VALIDATE } from '../../../_functions/VALIDATE';
import { SET_VALUES } from '../../../_functions/SET_VALUES';

// IMPORT components
import ButtonLoading from "../../../shared/components/UIElements/ButtonLoading";
import Logo from "../../../shared/components/UIElements/Logo";
import Copyright from "../../../shared/components/UIElements/Copyright";

// IMPORT components MATERIAL UI
import { Typography, Box, FormHelperText } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";

// IMPORT icons MATERIAL UI
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

// IMPORT theme MATERIAL UI and custom scss
import { useTheme } from '@material-ui/core/styles';

const ResetPassword = () => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const theme = useTheme();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  // LOCAL STATE
  const [showPassword, setShowPassword] = useState(false);
  const [id, setId] = useState("");
  const [token, setToken] = useState("");
  const [values, setValues] = useState({
    password: {
      state: "",
      error: false,
      errorMsg: t("FIELDS.password.error")
    },
  });

  /* 
    FUNCTIONS
  */
  const handleChange = (e) => {
    clearError();
    SET_VALUES(e, values, setValues);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const validate = (field) => {
    VALIDATE([
      "password"
    ],
      field, values, setValues);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
   
    if (!values.password.error) {
      try {
        const res = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL_API}/users/verify-reset-password/${id}/${token}`,
          "POST",
          JSON.stringify({
            id: id,
            token: token,
            password: values.password.state,
          }),
          {
            "Content-Type": "application/json",
          }
        );
        res.success === true ? window.location.href = "/success-reset-password" : window.location.href = "/failed";
      } catch (err) { 
        console.log(err)
      }
    }
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id');
    const token = urlParams.get('token');
    setId(id);
    setToken(token);
  }, [])

  return (
    <Container maxWidth={false} disableGutters className="bg--1">
      <Container maxWidth="sm">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
          padding="50px"
          className="h-gap--50"
        >
          <Grid
            container
            direction="column"
            alignContent="center"
            alignItems="center"
            className="h-gap--30 h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--1"
          >
            <Logo />

            <form onSubmit={submitHandler}>
              <Grid container item spacing={2}>
                <React.Fragment>
                  <Grid container item direction="column" alignItems="center">
                    <div className="icon-wrapper--lg" style={{ background: theme.palette.secondary.main }}>
                      <VpnKeyIcon size="large" />
                    </div>

                    <Typography component="h1" variant="h6">{t("PAGE_RESET_PASSWORD.h1")}</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel required htmlFor="password" error={values.password.error}>
                        {t("FIELDS.password.label")}
                      </InputLabel>

                      <OutlinedInput
                        id="password"
                        autoComplete="new-password"
                        type={showPassword ? "text" : "password"}
                        value={values.password.state}
                        onChange={handleChange}
                        error={values.password.error}
                        onBlur={() => validate("password")}
                        disabled={isLoading}
                        labelWidth={83}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label={t("BUTTONS.AL_password_visibilty")}
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <FormHelperText
                        error={values.password.error}
                        id="form-helper-text-password"
                      >
                        {values.password.error ? values.password.errorMsg : ""}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <ButtonLoading
                      loading={isLoading}
                      disabled={isLoading}
                      type="submit"
                      onClick={() => validate("all")}
                      fullWidth
                      size="large"
                      className="button--loading"
                      endIcon={<ExitToAppIcon />}
                    >
                      {t("BUTTONS.reset_password")}
                    </ButtonLoading>
                  </Grid>
                </React.Fragment>
              </Grid>
            </form>
          </Grid>

          <Copyright />
        </Box>
      </Container>
    </Container>
  );
};

export default ResetPassword;

export const changeItem = (event, index, items, setItems) => {
  const target = event.target.id !== undefined ? event.target.id : event.target.name;

  const newItems = items.map((item, i) => {
    if (i === (index - 1)) {

      let total = item.total;
      let discountValue = item.discountValue;

      if (target === "price") {
        total = event.target.value * item.quantity
        if (item.discount === "percent") {
          discountValue = total * item.discountPercent / 100
        }
      } else if (target === "quantity") {
        total = event.target.value * item.price
        if (item.discount === "percent") {
          discountValue = total * item.discountPercent / 100
        }
      }

      // Handles switch between discount variants
      if (target === "discount" && event.target.value === "percent") {
        discountValue = total * item.discountPercent / 100
      } else if (target === "discount" && event.target.value === "absolute") {
        discountValue = item.discountAbsolute
      } else if (target === "discount" && event.target.value === "none") {
        discountValue = 0;
      }

      // Handles change of discount % or absolute
      if (target === "discountPercent") {
        discountValue = total * event.target.value / 100
      } else if (target === "discountAbsolute") {
        discountValue = event.target.value
      }

      let discountedTotal = total - discountValue;

      return {
        ...item,
        [target]: event.target.value,
        total: total,
        discountValue: discountValue,
        discountedTotal: discountedTotal,
      };
    }
    return item;
  });

  setItems(newItems);
};

export const deleteItem = (index, items, setItems) => {
  setItems((current) =>
    current.filter((item, i) => {
      return i !== index;
    })
  );
};

export const addItem = (items, setItems, currentItem, setCurrentItem) => {
  setItems((items) => [
    ...items,
    {
      name: "",
      quantity: 1,
      price: "",
      unit: "",
      vatPercent: "",
      total: 0,
      discount: "none",
      discountPercent: 0,
      discountAbsolute: 0,
      discountValue: 0,
      discountedTotal: 0
    },
  ]);

  // reset state
  setCurrentItem({
    ...currentItem,
    i: items.length + 1,
    name: "",
    quantity: 1,
    price: "",
    unit: "",
    vatPercent: "",
    discount: "none",
    discountPercent: 0,
    discountAbsolute: 0,
  });
};

import React from "react";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";

// IMPORT misc
import LogoImage from "../../../_assets/logo.svg";
import LogoImageColored from "../../../_assets/logo_colored.svg";

const Logo = (props) => {
    // HOOKS & CONTEXT
    const { t } = useTranslation();

    return (
        <div>
            <img
                src={props.color === "bright" ? LogoImage : LogoImageColored}
                alt={`${t("APP.name")} Logo`}
                className="image--logo--presenter"
            />
        </div>
    );
};

export default Logo;

import React, { useState } from "react";

// IMPORT hooks & context
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { useTranslation } from "react-i18next";

// IMPORT functions
import { VALIDATE } from '../../../_functions/VALIDATE';
import { SET_VALUES } from '../../../_functions/SET_VALUES';
import { ERROR } from '../../../_functions/ERROR';

// IMPORT components
import ButtonLoading from "../../../shared/components/UIElements/ButtonLoading";
import Logo from "../../../shared/components/UIElements/Logo";
import Copyright from "../../../shared/components/UIElements/Copyright";

// IMPORT components MATERIAL UI
import { Typography, Box } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";

// IMPORT icons MATERIAL UI
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

// IMPORT theme MATERIAL UI and custom scss
import { useTheme } from '@material-ui/core/styles';

const ForgotPassword = () => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const theme = useTheme();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  // LOCAL STATE
  const [passwordRequested, setPasswordRequested] = useState(false);
  const [values, setValues] = useState({
    email: {
      state: "",
      error: false,
      errorMsg: t("FIELDS.email.error"),
    },
  });

  /* 
    FUNCTIONS
  */
  const handleChange = (e) => {
    clearError();
    SET_VALUES(e, values, setValues);
  };

  const validate = (field) => {
    VALIDATE([
      "email",
    ],
      field, values, setValues);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!values.email.error) {
      try {
        const res = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL_API}/users/forgot-password`,
          "POST",
          JSON.stringify({
            email: values.email.state,
          }),
          {
            "Content-Type": "application/json",
          }
        );

        setPasswordRequested(res.requested);
        window.location.href = `/verify-password`;
      } catch (err) { }
    }
  };

  return (
    <Container maxWidth={false} disableGutters className="bg--1">
      <Container maxWidth="sm">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
          padding="50px"
          className="h-gap--50"
        >
          <Grid
            container
            direction="column"
            alignContent="center"
            alignItems="center"
            className="h-gap--30 h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--1"
          >
            <Logo />

            <form onSubmit={submitHandler}>
              <Grid container item spacing={2}>
                {!passwordRequested &&
                  <React.Fragment>
                    <Grid container item direction="column" alignItems="center">
                      <div className="icon-wrapper--lg" style={{ background: theme.palette.secondary.main }}>
                        <VpnKeyIcon size="large" />
                      </div>

                      <Typography component="h1" variant="h6">{t("PAGE_FORGOT_PASSWORD.h1")}</Typography>
                    </Grid>

                    <Grid container item spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          required
                          autoComplete="off"
                          id="email"
                          label={`${t("FIELDS.email.label")}`}
                          variant="outlined"
                          type="email"
                          onChange={handleChange}
                          onBlur={() => validate("email")}
                          error={values.email.error}
                          helperText={values.email.error ? values.email.errorMsg : ""}
                          fullWidth
                          disabled={isLoading}
                        />
                      </Grid>
                    </Grid>

                    {error &&
                      <Grid item xs={12}>
                        <Alert severity="error">{ERROR(t, error)}
                        </Alert>
                      </Grid>
                    }

                    <Grid item xs={12}>
                      <ButtonLoading
                        loading={isLoading}
                        disabled={isLoading}
                        type="submit"
                        onClick={() => validate("all")}
                        fullWidth
                        size="large"
                        className="button--loading"
                        endIcon={<ExitToAppIcon />}
                      >
                        {t("BUTTONS.forgot_password")}
                      </ButtonLoading>
                    </Grid>
                  </React.Fragment>
                }
              </Grid>
            </form>
          </Grid>

          <Copyright />
        </Box>
      </Container>
    </Container>
  );
};

export default ForgotPassword;

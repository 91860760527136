import React, { useState, useEffect } from "react";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";

// IMPORT components MATERIAL UI
import { Button } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const DialogElement = ({ open, setOpen, content }) => {
    // HOOKS & CONTEXT
    const { t } = useTranslation();

    // LOCAL STATE
    const [scroll] = useState('paper');
    const [h1, setH1] = useState("");
    const [text, setText] = useState([]);

    /* 
        FUNCTIONS
    */
    useEffect(() => {
        if (open) {
            const contentObject = t(content, { returnObjects: true }); // Get object
            const contentArray = Object.values(contentObject); // Convert object to array

            setH1(contentArray[0])
            setText(contentArray[1])
        }
    }, [content, open]);

    return (
        <Dialog
            open={open}
            maxWidth="md"
            onClose={() => setOpen(false)}
            scroll={'paper'}
        >
            <DialogTitle>{h1}</DialogTitle>
            <DialogContent>
                {text.map(function (item, i) {
                    return item[0] === "h2" ?
                        <DialogContentText key={i} style={{ paddingTop: "10px" }}><strong>{item[1]}</strong></DialogContentText>
                        : <DialogContentText key={i}>{item[1]}</DialogContentText>
                })}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">
                    {t("BUTTONS.close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogElement;

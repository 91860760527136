import React, { useState, useEffect } from 'react';

// IMPORT hooks & context
import { useTranslation } from "react-i18next";

// IMPORT components MATERIAL UI
import {
  Typography,
  Button,
  Grid,
  IconButton,
  FormControlLabel,
  FormControl,
  FormGroup,
  Checkbox,
  Dialog,
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

// IMPORT icons MATERIAL UI
import CloseIcon from '@material-ui/icons/Close';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

// IMPORT components
import { CSVLink } from "react-csv";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function ClientsCsv(props) {
  // HOOKS & CONTEXT
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');

  const [values, setValues] = useState({
    data: [],
    headers: [
      { label: t("FIELDS.company.label"), key: "company" },
      { label: t("FIELDS.country.label"), key: "country" },
      { label: t("FIELDS.street.label"), key: "street" },
      { label: t("FIELDS.streetNr.label"), key: "streetNr" },
      { label: t("FIELDS.zip.label"), key: "zip" },
      { label: t("FIELDS.city.label"), key: "city" },
      { label: t("FIELDS.email.label"), key: "email" },
      { label: t("FIELDS.phone.label"), key: "phone" },
      { label: t("FIELDS.vat.label"), key: "vat" },
      { label: t("FIELDS.defaultVatPercentage.label"), key: "vatPercent" },
      { label: t("FIELDS.precedingTitle.label"), key: "precedingTitle" },
      { label: t("FIELDS.firstName.label"), key: "firstName" },
      { label: t("FIELDS.lastName.label"), key: "lastName" },
      { label: t("FIELDS.followingTitle.label"), key: "followingTitle" },
      { label: t("FIELDS.clientStatus.label"), key: "status" }
    ],
    checked: {
      company: true,
      country: true,
      street: true,
      streetNr: true,
      zip: true,
      city: true,
      email: true,
      phone: true,
      vat: true,
      vatPercent: true,
      precedingTitle: true,
      firstName: true,
      lastName: true,
      followingTitle: true,
      status: true,
    }
  });

  const handleChange = (e) => {
    setValues(previousInputs => ({
      ...previousInputs,
      checked: {
        ...values.checked,
        [e.target.name]: e.target.checked,
      },
    }))
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let newData = []

    Object.entries(props.data).forEach((item) => {
      const status = item[1].status === "active" ? t("PAGE_CLIENTS.activeClient") : item[1].status === t("PAGE_CLIENTS.inactiveClient") ? "inaktiv" : t("PAGE_CLIENTS.prospectClient")
      item[1] = {
        ...item[1],
        country: JSON.parse(item[1].country).code,
        status: status
      }
      newData.push(item[1]);
    })

    setValues({ ...values, data: newData });

  }, [props.data]);

  useEffect(() => {
    const headers = [];
    values.checked.company && headers.push({ label: t("FIELDS.company.label"), key: "company" },)
    values.checked.country && headers.push({ label: t("FIELDS.country.label"), key: "country" },)
    values.checked.street && headers.push({ label: t("FIELDS.street.label"), key: "street" },)
    values.checked.streetNr && headers.push({ label: t("FIELDS.streetNr.label"), key: "streetNr" },)
    values.checked.zip && headers.push({ label: t("FIELDS.zip.label"), key: "zip" },)
    values.checked.city && headers.push({ label: t("FIELDS.city.label"), key: "city" },)
    values.checked.email && headers.push({ label: t("FIELDS.email.label"), key: "email" },)
    values.checked.email && headers.push({ label: t("FIELDS.phone.label"), key: "email" },)
    values.checked.vat && headers.push({ label: t("FIELDS.vat.label"), key: "vat" },)
    values.checked.vatPercent && headers.push({ label: t("FIELDS.defaultVatPercentage.label"), key: "vatPercent" },)
    values.checked.precedingTitle && headers.push({ label: t("FIELDS.precedingTitle.label"), key: "precedingTitle" },)
    values.checked.firstName && headers.push({ label: t("FIELDS.firstName.label"), key: "firstName" },)
    values.checked.lastName && headers.push({ label: t("FIELDS.lastName.label"), key: "lastName" },)
    values.checked.followingTitle && headers.push({ label: t("FIELDS.followingTitle.label"), key: "followingTitle" },)
    values.checked.status && headers.push({ label: t("FIELDS.clientStatus.label"), key: "status" },)

    setValues({ ...values, headers: headers });

  }, [values.checked]);


  return (
    <>
      <IconButton
        aria-label="settings"
        style={{ color: "inherit" }}
        onClick={handleClickOpen}
      >
        <SaveAltIcon fontSize="small" />
      </IconButton>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t("PAGE_CLIENTS.exportClientsList")}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container item style={{ marginBottom: "20px" }}>
            <FormControl component="fieldset">
              <Typography variant="body2" component="div" style={{ marginBottom: "10px" }}><strong>{t("PAGE_CLIENTS.exportClientsListIncludeFields")}:</strong></Typography >
              <FormGroup aria-label="csvFields" name="csvFields" onChange={(e) => handleChange(e)}>
                <FormControlLabel name="company" control={<Checkbox checked={values.checked.company} color="primary" style={{ paddingTop: "5px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("FIELDS.company.label")}</Typography>} />
                <FormControlLabel name="country" control={<Checkbox checked={values.checked.country} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("FIELDS.country.label")}</Typography>} />
                <FormControlLabel name="street" control={<Checkbox checked={values.checked.street} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("FIELDS.street.label")}</Typography>} />
                <FormControlLabel name="streetNr" control={<Checkbox checked={values.checked.streetNr} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("FIELDS.streetNr.label")}</Typography>} />
                <FormControlLabel name="zip" control={<Checkbox checked={values.checked.zip} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("FIELDS.zip.label")}</Typography>} />
                <FormControlLabel name="city" control={<Checkbox checked={values.checked.city} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("FIELDS.city.label")}</Typography>} />
                <FormControlLabel name="email" control={<Checkbox checked={values.checked.email} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("FIELDS.email.label")}</Typography>} />
                <FormControlLabel name="phone" control={<Checkbox checked={values.checked.phone} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("FIELDS.phone.label")}</Typography>} />
                <FormControlLabel name="vat" control={<Checkbox checked={values.checked.vat} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("FIELDS.vat.label")}</Typography>} />
                <FormControlLabel name="vatPercent" control={<Checkbox checked={values.checked.vatPercent} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("FIELDS.defaultVatPercentage.label")}</Typography>} />
                <FormControlLabel name="precedingTitle" control={<Checkbox checked={values.checked.precedingTitle} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("FIELDS.precedingTitle.label")}</Typography>} />
                <FormControlLabel name="firstName" control={<Checkbox checked={values.checked.firstName} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("FIELDS.firstName.label")}</Typography>} />
                <FormControlLabel name="lastName" control={<Checkbox checked={values.checked.lastName} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("FIELDS.lastName.label")}</Typography>} />
                <FormControlLabel name="followingTitle" control={<Checkbox checked={values.checked.followingTitle} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("FIELDS.followingTitle.label")}</Typography>} />
                <FormControlLabel name="status" control={<Checkbox checked={values.checked.status} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("FIELDS.clientStatus.label")}</Typography>} />
              </FormGroup>
            </FormControl>
          </Grid>
        </DialogContent>

        <DialogActions>
          <CSVLink data={values.data} headers={values.headers}>
            <Button autoFocus onClick={handleClose} color="primary">
              {t("BUTTONS.exportCsv")}
            </Button>
          </CSVLink>
        </DialogActions>
      </Dialog>
    </>
  );
}

// READ: https://dev.to/franklin030601/building-a-multi-language-app-with-react-js-2och

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { de_APP } from "./de/APP";
import { de_BUTTONS } from "./de/BUTTONS";
import { de_ERRORS } from "./de/ERRORS";
import { de_FIELDS } from "./de/FIELDS";
import { de_DRAWER } from "./de/DRAWER";
import { de_PAGE_LOGIN } from "./de/PAGE_LOGIN";
import { de_PAGE_REGISTER } from "./de/PAGE_REGISTER";
import { de_PAGE_FORGOT_PASSWORD } from "./de/PAGE_FORGOT_PASSWORD";
import { de_PAGE_RESET_PASSWORD } from "./de/PAGE_RESET_PASSWORD";
import { de_PAGE_DASHBOARD } from "./de/PAGE_DASHBOARD";
import { de_PAGE_PROFILE } from "./de/PAGE_PROFILE";
import { de_PAGE_ACCOUNT } from "./de/PAGE_ACCOUNT";
import { de_PAGE_CHECKOUT } from "./de/PAGE_CHECKOUT";
import { de_PAGE_CLIENTS } from "./de/PAGE_CLIENTS";
import { de_PAGE_INVOICES } from "./de/PAGE_INVOICES";
import { de_PAGE_EXPENSES } from "./de/PAGE_EXPENSES";
import { de_FEEDBACK } from "./de/FEEDBACK";
import { de_PRODUCTS } from "./de/PRODUCTS";
import { de_PROMO } from "./de/PROMO";
import { de_TERMS } from "./de/TERMS";
import { de_PRIVACY } from "./de/PRIVACY";

i18n.use(initReactI18next).init({
  lng: "de",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  resources:{
    de: {
        translation: {
            APP: de_APP,
            BUTTONS: de_BUTTONS,
            ERRORS: de_ERRORS,
            FIELDS: de_FIELDS,
            DRAWER: de_DRAWER,
            TERMS: de_TERMS,
            PRIVACY: de_PRIVACY,
            PAGE_LOGIN: de_PAGE_LOGIN,
            PAGE_REGISTER: de_PAGE_REGISTER,
            PAGE_FORGOT_PASSWORD: de_PAGE_FORGOT_PASSWORD,
            PAGE_RESET_PASSWORD: de_PAGE_RESET_PASSWORD,
            PAGE_DASHBOARD: de_PAGE_DASHBOARD,
            PAGE_PROFILE: de_PAGE_PROFILE,
            PAGE_ACCOUNT: de_PAGE_ACCOUNT,
            PAGE_CHECKOUT: de_PAGE_CHECKOUT,
            PAGE_CLIENTS: de_PAGE_CLIENTS,
            PAGE_INVOICES: de_PAGE_INVOICES,
            PAGE_EXPENSES: de_PAGE_EXPENSES,
            FEEDBACK: de_FEEDBACK,
            PRODUCTS: de_PRODUCTS,
            PROMO: de_PROMO,
        }
    },
    en: {
        translation: {
            title: 'Aplicación en varios idiomas',
            home: "Home",
        }
    },
  }
});

export default i18n;

import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

const ButtonLoading = ({ children, loading, className, ...rest }) => {
  return (
    <Button {...rest} color="primary" variant="contained" className={className}>
      {children}
      {loading && (
        <CircularProgress
          size={30}
          color="primary"
          style={{ position: "absolute"}}
        />
      )}
    </Button>
  );
};

export default ButtonLoading;

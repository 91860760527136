import React from 'react';
import { AppBar, Toolbar, IconButton, Grid, Typography, Button } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon, LibraryBooks as LibraryBooksIcon, Repeat as RepeatIcon, Gavel as GavelIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const InvoiceAppBar = ({ setOpenInvoiceDialogAtom, openInvoiceDialogAtom, selectedClient, isLoading, setSendMode, setOpen }) => {
  const { t } = useTranslation();

  return (
    <AppBar style={{ background: "#263238", zIndex: "1500" }}>
      <Toolbar style={{ justifyContent: "space-between" }}>
        <>
          <IconButton
            edge="start"
            color="inherit"
            style={{ margin: "0", display: "flex", width: "40px", height: "40px", border: "1px solid white" }}
            onClick={() => {
              setOpenInvoiceDialogAtom({
                ...openInvoiceDialogAtom,
                open: false,
                invoiceId: "",
                duplicate: false
              })
            }}
            aria-label={`${t("BUTTONS.AL_close")}`}
          >
            <ArrowBackIcon />
          </IconButton>
        </>

        <Grid container item direction="row" alignItems="center" justifyContent="center" >
          {(!openInvoiceDialogAtom.isEstimate && !openInvoiceDialogAtom.recurring) ?
            <LibraryBooksIcon color="primary" fontSize="large" style={{ marginRight: "5px" }} />
            : (!openInvoiceDialogAtom.isEstimate && openInvoiceDialogAtom.recurring) ? 
                <RepeatIcon color="primary" fontSize="large" style={{ marginRight: "5px" }} />
                : <GavelIcon color="primary" fontSize="large" style={{ marginRight: "5px" }} />
          }
          <Typography variant="body1" component="h1" style={{ textTransform: "none", fontWeight: 600 }}>
            {
              !openInvoiceDialogAtom.recurring ?
              (openInvoiceDialogAtom.invoiceId !== "") ?
                (!openInvoiceDialogAtom.isEstimate ? t("PAGE_INVOICES.editInvoice") : "KV / Angebot bearbeiten")
                : (!openInvoiceDialogAtom.isEstimate ? t("PAGE_INVOICES.createInvoice") : "KV / Angebot erstellen")
              : openInvoiceDialogAtom.invoiceId !== "" ? "Wiederkehrende Rechnung bearbeiten" : "Wiederkehrende Rechnung erstellen"
            }
          </Typography>
        </Grid>

        {selectedClient &&
          <Button
            disabled={isLoading}
            variant="outlined"
            color="inherit"
            style={{ paddingTop: "7px", paddingBottom: "7px", lineHeight: 1, textTransform: "none" }}
            onClick={() => { setSendMode(false); setOpen(true) }}
          >
            PDF <span style={{ fontSize: "10px", paddingLeft: "4px" }}>Vorschau</span>
          </Button>
        }
      </Toolbar>
    </AppBar>
  );
};

export default InvoiceAppBar;

import React from "react";
import { Button, CircularProgress } from '@material-ui/core';

const ButtonLoadingBottom = ({ children, loading, style, borderTopLeftRadius, borderTopRightRadius, className, ...rest }) => {

  const additionalStyles = {
    position: 'relative',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    opacity: 1,
    borderTopLeftRadius: borderTopLeftRadius,
    borderTopRightRadius: borderTopRightRadius
  };

  return (
    <Button
      {...rest}
      variant="contained"
      className={className}
      disabled={loading}
      style={{ ...additionalStyles, ...style }}
    >
      {children}
      {loading && (
        <CircularProgress
          size={30}
          color="primary"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -15,
            marginLeft: -15,
          }}
        />
      )}
    </Button>
  );
};

export default ButtonLoadingBottom;

export const de_ERRORS = {
    failedToFetch: "Es ist ein Problem aufgetreten. Bitte warten Sie einen Moment und probieren Sie dann nochmals sich einzuloggen.",
    invalidCredentials: "Ihr Passwort oder ihre E-Mail-Adresse ist nicht korrekt.",
    default: "Es ist ein Problem aufgetreten",
    save_form: "Speichern fehlgeschlagen. Bitte füllen Sie alle Pflichtfelder aus.",
    save_server: "Speichern fehlgeschlagen. Bitte probieren Sie es später nochmals.",
    payment_authentification: "Die Authentifizierung der angegebenen Zahlungsmethode ist fehlgeschlagen. Geben Sie eine neue Zahlungsmethode an, und versuchen Sie es erneut.",
    payment_default: "Die Zahlung war nicht erfolgreich. Bitte versuchen Sie es noch einmal oder kontaktieren Sie unseren Kundensupport unter support@dagowert.com.",
    
}

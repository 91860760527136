import React, { useRef, useEffect, useState, useContext } from "react";

import { PDFViewer, usePDF, Page, Text, View, Image, Document, StyleSheet, pdf, Font, BlobProvider } from '@react-pdf/renderer';

import { THEME_CLASSIC_STYLES } from "./THEME_CLASSIC_STYLES"

export const ThemeClassic = (design, values, t, userAtom, cssZoom) => {
  const styles = THEME_CLASSIC_STYLES(design, cssZoom)

  return (
    <Document style={styles.document}>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.pageView}>
          <View style={styles.sender} fixed>
            {userAtom.data.image !== "/#" ?
              <>
                {/* ON-SCREEN image */}
                <img
                  src={`${process.env.REACT_APP_BACKEND_URL}/${userAtom.data.image}`}
                  alt={`${t("APP.name")} Logo`}
                  style={styles.sender.logo.onscreen}
                />

                {/* PDF image */}
                <View style={styles.sender.viewImagePdf}>
                  <Image
                    src={`${process.env.REACT_APP_BACKEND_URL}/${userAtom.data.image}`}
                    style={styles.sender.logo.pdf}
                  />
                </View>
              </>
              :
              <View style={styles.h1}>
                <View style={styles.h1.view}>
                  <Text>Rechnung</Text>
                </View>
              </View>
            }

            <View style={styles.sender.view}>
              <Text style={styles.sender.text}>{userAtom.data.company}</Text>
              <Text style={styles.sender.text}>{userAtom.data.street} {userAtom.data.streetNr}</Text>
              <Text style={styles.sender.text}>{userAtom.data.zip} {userAtom.data.city}</Text>
              <Text style={styles.sender.text}>{JSON.parse(userAtom.data.country).label}</Text>
            </View>
          </View>

          <View style={styles.recipient}>
            <View style={styles.recipient.view}>
              <div style={styles.recipient.column}>
                <Text style={styles.recipient.text}>{values.client.company}</Text>
                <Text style={styles.recipient.text}>{values.client.street} {values.client.streetNr}</Text>
                <Text style={styles.recipient.text}>{values.client.zip} {values.client.city}</Text>
                <Text style={styles.recipient.text}>{values.country}</Text>
              </div>

              <div style={styles.recipient.column}>
                <Text style={styles.recipient.text}>{t("FIELDS.clientNumber.label")}: {values.client.clientNumber}</Text>
                <Text style={styles.recipient.text}>{t("FIELDS.invoiceNumber.label")}: {values.invoice.invoiceNr}</Text>
                {/* <Text style={styles.recipient.text}>{t("FIELDS.invoiceDate.label")}: {`${values.invoice.invoiceDate.getDate()}.${values.invoice.invoiceDate.getMonth() + 1}.${values.invoice.invoiceDate.getFullYear()}`}</Text>
                <Text style={styles.recipient.text}>{t("FIELDS.invoiceDueDate.label")}: {`${values.invoice.invoiceDueDate.getDate()}.${values.invoice.invoiceDueDate.getMonth() + 1}.${values.invoice.invoiceDueDate.getFullYear()}`}</Text> */}
              </div>
            </View>
          </View>

          <View style={styles.h1}>
            <View style={styles.h1.view}>
              <Text>Rechnung</Text>
            </View>
          </View>

          <View style={styles.items}>
            <View style={styles.items.headlines}>
              <Text style={styles.items.headlineAlignLeft}>Leistung</Text>
              {values.items.some(item => item.quantity > 1) &&
                <Text style={styles.items.headlineAlignRight}>Einzelpreis</Text>
              }
              {/* <Text style={styles.items.headlineAlignRight}>Preis<br/>{"\n"}(gesamt)</Text> */}
              {values.items.some(item => (item.quantity > 1 || (item.unit !== "" && item.unit !== undefined))) &&
                <Text style={styles.items.headlineAlignRight}>Menge</Text>
              }
              {values.items.some(item => (item.quantity > 1 || item.discountValue !== 0)) &&
                <Text style={styles.items.headlineAlignRight}>Preis</Text>
              }
              {values.items.some(item => item.discountValue !== 0) &&
                <Text style={styles.items.headlineAlignRight}>Rabatt</Text>
              }
              <Text style={styles.items.headlineAlignRight}>MwSt (%)</Text>
              <Text style={styles.items.headlineAlignRight}>Gesamt</Text>
            </View>
          </View>

          {(values.items).map((item, i) => (
            <View style={styles.items}>
              <View style={styles.items.view}>
                <Text style={styles.items.textAlignLeft}>{item.name}</Text>
                {values.items.some(item => item.quantity > 1) &&
                  <Text style={styles.items.textAlignRight}>{`${(item.price * "1").toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</Text>
                }
                {values.items.some(item => (item.quantity > 1 || (item.unit !== "" && item.unit !== undefined))) &&
                  <Text style={styles.items.textAlignRight}>{item.quantity === "" ? 0 : item.quantity} {item.unit !== "" && item.unit}</Text>
                }
                {values.items.some(item => (item.quantity > 1 || item.discountValue !== 0)) &&
                  <Text style={styles.items.textAlignRight}>{`${(item.total).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</Text>
                }
                {values.items.some(item => item.discountValue !== 0) &&
                  <Text style={styles.items.textAlignRight}>{item.discount === "percent" ? `(${item.discountPercent}%) ${(item.discountValue).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : `${(item.discountValue).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</Text>
                }
                <Text style={styles.items.textAlignRight}>{item.vatPercent !== "" ? item.vatPercent : values.client.vatPercent !== "" ? values.client.vatPercent : "0"}</Text>
                <Text style={styles.items.textAlignRight}>{`${(item.discountedTotal).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</Text>
              </View>
            </View>
          ))}
          {/* <input value="asdf"/>
          <button>asdf</button> */}

          <View style={styles.summary}>
            <View style={styles.summary.view}>
              <Text style={styles.summary.view.headline}>Rechnungsbetrag (netto)</Text>
              <Text style={styles.summary.view.text}>{`${(values.invoice.subtotal).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</Text>
            </View>

            {(values.invoice.invoiceDiscount !== "none" && values.invoice.invoiceDiscountValue > 0) &&
              <>
                <View style={styles.summary.view}>
                  <Text style={styles.summary.view.headline}>{values.invoice.invoiceDiscount === "percent" && `${values.invoice.invoiceDiscountPercent} %`}{" Rabatt "}</Text>
                  <Text style={styles.summary.view.text}>{`- ${(values.invoice.invoiceDiscountValue).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</Text>
                </View>

                <View style={styles.summary.view}>
                  <Text style={styles.summary.view.headline}>Rabattierter Rechnungsbetrag (netto)</Text>
                  <Text style={styles.summary.view.text}>{`${(values.invoice.invoiceDiscountedSubtotal).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</Text>
                </View>
              </>
            }
          </View>

          <View style={styles.summary}>
            {Object.keys(values.vatValues).map((keyName, i) => (
              <View style={styles.summary.view}>
                <Text style={styles.summary.view.headline}>MwSt. {keyName} %</Text>
                <Text style={styles.summary.view.text}>{`${(values.vatValues[keyName][1]).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</Text>
              </View>
            ))}
          </View>

          <View style={styles.summary}>
            <View style={styles.summary.view}>
              <Text style={styles.summary.view.headlineBold}>Rechnungsbetrag (brutto)</Text>
              <Text style={styles.summary.view.textBold}>{`${(values.invoice.total).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</Text>
            </View>
          </View>

          <View style={styles.footnotes}>
            <View style={styles.footnotes.view}>
              <Text style={styles.items.textAlignRight}>Alle Preise in Euro (€)</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

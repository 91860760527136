import { useEffect } from 'react';

const useUpdateContinuousNumber = (settingsAtom, values, setSettings, settings) => {
  useEffect(() => {
    // UPDATE SETTINGS: CONTINUOUS NUMBER IN SCHEMA when invoice is Created (but only update it if invoice is saved => see invoiceHandler)
    let invoiceNumberItems = (typeof (settingsAtom.invoicesNumberItems) === "string" ? JSON.parse(settingsAtom.invoicesNumberItems) : settingsAtom.invoicesNumberItems);
    const currentIndex = invoiceNumberItems.findIndex((elements) => elements.type === "continuousNumber");
    if (currentIndex !== -1) {
      let currentId = invoiceNumberItems[currentIndex].id;
      let currentValue = invoiceNumberItems[currentIndex].value;

      let objInvoiceNumber = values.invoiceNumber.state.find(key => key.type === "continuousNumber");
      let objInvoiceNumberContinuousNumber = objInvoiceNumber !== undefined ? objInvoiceNumber.value : "";

      function incrementWithLeadingZeros(numString) {
        // Check if the input is a valid positive number string
        if (!/^\d+$/.test(numString)) {
          throw new Error("Input must be a numeric string.");
        }

        let originalLength = numString.length; // Get the length of the original string
        let number = parseInt(numString, 10) + 1; // Convert to integer and add 1
        let result = number.toString();

        // Pad the result with zeros to ensure it at least maintains the original length
        return result.padStart(originalLength, '0');
      }

      const updatedItem = { ...invoiceNumberItems[currentIndex], id: currentId, type: "continuousNumber", value: objInvoiceNumberContinuousNumber >= parseFloat(currentValue) ? incrementWithLeadingZeros(objInvoiceNumberContinuousNumber) : parseFloat(currentValue) };
      const newItems = [...invoiceNumberItems];
      newItems[currentIndex] = updatedItem;
      setSettings({ ...settings, invoicesNumberItems: newItems });
    }
  }, [values.invoiceNumber]);
};

export default useUpdateContinuousNumber;

import React, { useState, useEffect } from "react";

// KEEP!!!! even it is greyed out!!!!
import _ from "lodash";
import filter from "lodash";
import groupBy from "lodash";
import map from "lodash";
import sumBy from "lodash";
// KEEP !!!!

// IMPORT hooks & context
import {
  NavLink
} from "react-router-dom";
import { useTranslation } from "react-i18next";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import userAtomState from "../../_atoms/userAtom";
import selectedMenuItemAtomState from "../../_atoms/selectedMenuItemAtom";
import subMenuOpenAtomState from "../../_atoms/subMenuOpenAtom";
import invoicesAtomState from "../../_atoms/invoicesAtom";
import expensesAtomState from "../../_atoms/expensesAtom";

// IMPORT components
import LogoMonogram from './../../_assets/logo_monogram.svg';
import LogoMonogramWhite from './../../_assets/logo_monogram_white.svg';
import Promo from "./../../shared/components/UIElements/Promo";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from 'recharts';

// IMPORT components MATERIAL UI
import {
  Typography,
  Button,
  Box,
  Icon,
  Container,
  Grid,
  IconButton,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  useMediaQuery
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';



// IMPORT icons MATERIAL UI
import NotificationsIcon from '@material-ui/icons/Notifications';
import DashboardIcon from "@material-ui/icons/Dashboard";
import TimelineIcon from '@material-ui/icons/Timeline';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ClientsIcon from "@material-ui/icons/RecentActors";
import AccountCircle from "@material-ui/icons/AccountCircle";
import LogoutIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from '@material-ui/icons/Menu';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import GavelIcon from '@material-ui/icons/Gavel';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import BrushIcon from '@material-ui/icons/Brush';


import "./Dashboard.scss"


const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: {
    height: '0px',
    '@media (max-width: 959px)': {
      height: '62px',
    },
    '@media (max-width: 599px)': {
      height: '45px',
    },
    '@media (max-width: 455px)': {
      height: '55px',
    },
  },
  // gridContainer: {
  //   margin: '-5px', // Offset the padding of each grid item
  //   width: 'calc(100% + 10px)', // Adjust the width to account for the negative margins
  // },
  // gridItem: {
  //   padding: '5px', // Add padding of 5px (half of 10px) to each side of the item
  // },
}));

const Dashboard = () => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const classes = useStyles();

  // GLOBAL STATE (RECOIL)
  const [userAtom] = useRecoilState(userAtomState);
  const [selectedMenuItemAtom, setSelectedMenuItemAtom] = useRecoilState(selectedMenuItemAtomState);
  const [subMenuOpenAtom, setSubMenuOpenAtom] = useRecoilState(subMenuOpenAtomState);

  const [invoicesAtom, setInvoicesAtom] = useRecoilState(invoicesAtomState);
  const [dataInvoices, setDataInvoices] = useState([]);
  const [expensesAtom, setExpensesAtom] = useRecoilState(expensesAtomState);
  const [dataExpenses, setDataExpenses] = useState([]);
  const [data, setData] = useState([]);

  const [chartType, setChartType] = React.useState('area');

  // const handleChartType = (event, newChartType) => {
  //   setChartType(newChartType);
  // };

  // const months = [
  //   {month: "Jän"},
  //   {month: "Feb"},
  //   {month: "Mar"},
  //   {month: "Apr"},
  //   {month: "Mai"},
  //   {month: "Jun"},
  //   {month: "Jul"},
  //   {month: "Aug"},
  //   {month: "Sep"},
  //   {month: "Okt"},
  //   {month: "Nov"},
  //   {month: "Dez"},
  // ]

  // const dataMonthlyYear = (atom, setData, property, year, name) => {
  //   let arrFilteredYear = _.filter(atom, function (item) {return (new Date(item[property])).getFullYear() === year});
  //   let arrGroupedMonths = _.groupBy(arrFilteredYear, (item) => new Date(item[property]).getMonth());
  //   let arrSummedPerMonth = _.map(arrGroupedMonths, (objs, key) => ({
  //     'month':
  //       key === "0" ? months[0].month
  //       : key === "1" ? months[1].month
  //       : key === "2" ? months[2].month
  //       : key === "3" ? months[3].month
  //       : key === "4" ? months[4].month
  //       : key === "5" ? months[5].month
  //       : key === "6" ? months[6].month
  //       : key === "7" ? months[7].month
  //       : key === "8" ? months[8].month
  //       : key === "9" ? months[9].month
  //       : key === "10" ? months[10].month
  //       : months[11],
  //     [name]: _.sumBy(objs, item => (
  //       property === "payDate" && item.payState === "payed") ? Number(item.total)
  //       : property === "pay_date" ? item.charge === "100" ? Number(item.gross_value) : Number(item.gross_value / 100 * item.charge)
  //       : 0
  //     )
  //   }))
  //   months.forEach (function (item, i) {
  //     // If there is a month with no payed invoice, add month with total of 0
  //     !arrSummedPerMonth.some((obj)=> obj.month === months[i].month) && arrSummedPerMonth.splice(i, 0, {month: months[i].month, [name]: 0});
  //   });
  //   setData(arrSummedPerMonth)
  // }

  // useEffect(() => {
  //   dataMonthlyYear(invoicesAtom, setDataInvoices, "payDate", 2024, "totalInvoices")
  //   dataMonthlyYear(expensesAtom, setDataExpenses, "pay_date", 2024, "totalExpenses")
  // }, [invoicesAtom, expensesAtom]);

  // useEffect(() => {
  //   setData([
  //     {month: months[0].month, totalInvoices: dataInvoices["0"] !== undefined && dataInvoices["0"].totalInvoices, totalExpenses: dataExpenses["0"] !== undefined && dataExpenses["0"].totalExpenses},
  //     {month: months[1].month, totalInvoices: dataInvoices["1"] !== undefined && dataInvoices["1"].totalInvoices, totalExpenses: dataExpenses["1"] !== undefined && dataExpenses["1"].totalExpenses},
  //     {month: months[2].month, totalInvoices: dataInvoices["2"] !== undefined && dataInvoices["2"].totalInvoices, totalExpenses: dataExpenses["2"] !== undefined && dataExpenses["2"].totalExpenses},
  //     {month: months[3].month, totalInvoices: dataInvoices["3"] !== undefined && dataInvoices["3"].totalInvoices, totalExpenses: dataExpenses["3"] !== undefined && dataExpenses["3"].totalExpenses},
  //     {month: months[4].month, totalInvoices: dataInvoices["4"] !== undefined && dataInvoices["4"].totalInvoices, totalExpenses: dataExpenses["4"] !== undefined && dataExpenses["4"].totalExpenses},
  //     {month: months[5].month, totalInvoices: dataInvoices["5"] !== undefined && dataInvoices["5"].totalInvoices, totalExpenses: dataExpenses["5"] !== undefined && dataExpenses["5"].totalExpenses},
  //     {month: months[6].month, totalInvoices: dataInvoices["6"] !== undefined && dataInvoices["6"].totalInvoices, totalExpenses: dataExpenses["6"] !== undefined && dataExpenses["6"].totalExpenses},
  //     {month: months[7].month, totalInvoices: dataInvoices["7"] !== undefined && dataInvoices["7"].totalInvoices, totalExpenses: dataExpenses["7"] !== undefined && dataExpenses["7"].totalExpenses},
  //     {month: months[8].month, totalInvoices: dataInvoices["8"] !== undefined && dataInvoices["8"].totalInvoices, totalExpenses: dataExpenses["8"] !== undefined && dataExpenses["8"].totalExpenses},
  //     {month: months[9].month, totalInvoices: dataInvoices["9"] !== undefined && dataInvoices["9"].totalInvoices, totalExpenses: dataExpenses["9"] !== undefined && dataExpenses["9"].totalExpenses},
  //     {month: months[10].month, totalInvoices: dataInvoices["10"] !== undefined && dataInvoices["10"].totalInvoices, totalExpenses: dataExpenses["10"] !== undefined && dataExpenses["10"].totalExpenses},
  //     {month: months[11].month, totalInvoices: dataInvoices["11"] !== undefined && dataInvoices["11"].totalInvoices, totalExpenses: dataExpenses["11"] !== undefined && dataExpenses["11"].totalExpenses},
  //   ])
  // }, [dataInvoices, dataExpenses]);

  const data01 = [
    {
      "name": "Aktive Kunden",
      "value": 10
    },
    {
      "name": "Inaktive Kunden",
      "value": 5
    },
    {
      "name": "Mögliche Kunden (in Verhandlung)",
      "value": 1
    },
  ];

  const COLORS = ["green", "red", "orange"]

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          {/* <p className="label">{`${label} : ${payload[0].value}`}</p> */}
          <div className="label" style={{ fontWeight: "bold", fontSize: "12px", display: "inline-block", paddingTop: "5px", paddingLeft: "10px", paddingRight: "10px" }}>{`${label}`}</div>
          <div>
            {payload.map((pld, i) => (
              <div key={i} style={{ display: "inline-block", padding: "10px", paddingTop: "5px" }}>
                <div style={{ fontWeight: "bold", fontSize: "12px", color: pld.dataKey === "totalInvoices" ? "#00bf72" : "#ff7979" }}>{pld.dataKey === "totalInvoices" ? "Einnahmen" : "Ausgaben"}</div>
                {/* <div style={{ color: pld.fill }}>{pld.value}</div> */}
                <div style={{ fontWeight: "bold", color: "#ffffff", background: pld.dataKey === "totalInvoices" ? "#00bf72" : "#ff7979", textAlign: "center", borderRadius: "4px" }}> € {pld.value}</div>
              </div>
            ))}
          </div>
        </div>
      );
    }

    return null;
  };


  const [mobileOpen, setMobileOpen] = useState(false);


   /* 
      FUNCTIONS
    */
      const handleListItemClick = (e, i) => {
        setSelectedMenuItemAtom(i);
        setMobileOpen(false);
        if (i !== 5 && i !== 6 && i !== 7) {
            setSubMenuOpenAtom(false);
        }
    };


  function ResponsiveBox({ icon, headline, text }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.up('xs'));

    return (
      <Fade in={true}>
      <Box bgcolor="white" p={1} className="feature-box-inner" style={{ position: 'relative', padding: '10px', height: '100%' }}>
        <Grid
          container
          direction={'column'}
          alignItems={'center'}
          spacing={1}
          style={{ textAlign: "center", padding: "10px" }}
        >
          <Grid item>
            {icon}
          </Grid>
          <Grid item>
            <Typography variant="body1" className="headline-feature">{headline}</Typography>
            <Typography variant="body2" className="text-feature">{text}</Typography>
          </Grid>
        </Grid>
        <div className="icon--link">
          <IconButton aria-label="delete">
            <ChevronRightIcon style={{ color: "#ffffff" }}/>
          </IconButton>
        </div>
      </Box>
      </Fade>
    );
  }


  return (
    <div>
      <div className={classes.toolbar} />
      <Fade in={true}>
        <Grid container className="subpage-header" maxWidth={false} item direction="row" alignItems="center" alignContent="center">
          <Grid item container alignItems="center" style={{ marginRight: "10px" }}>
            <Icon style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "35px", width: "35px", borderRadius: "4px", background: "linear-gradient(to right top, rgb(38, 108, 189), rgb(36, 115, 192), rgb(33, 150, 243), #00bca7, #00bf72)", marginRight: "15px" }}>
              <img src={LogoMonogramWhite} height={25} width={25} alt={`${t("APP.name")} Logo`} />
            </Icon>
            <Typography variant="body1" component="div" style={{ fontWeight: "500" }} className="headline">Willkommen</Typography>
          </Grid>

          <Grid item container justifyContent="space-between">
            <Grid item container alignItems="center" style={{ padding: "0px 2px 0px 0px", borderRadius: "6px", marginTop: "10px", width: "auto", boxShadow: "inset rgb(108 116 173 / 12%) 0px 0px 20px 0px" }}>
              <Typography className="date-text" style={{ display: "flex", alignItems: "center", fontWeight: 400, minHeight: "43px", borderRadius: "4px", marginLeft: "8px", padding: "2px", fontSize: "12px", marginRight: "5px" }}><span><span style={{ fontWeight: 600 }}>Schön, dass du da bist!</span></span></Typography>
            </Grid>
          </Grid>
        </Grid>
      </Fade>


      <Container maxWidth={false} disableGutters className="bg--1" >
        <Container maxWidth={false} >
          <Box display="flex"
            flexDirection="column"
            justifyContent="center"
            margin="25px 25px 25px 25px"
            className="h-gap--25"
          >
            <Grid container spacing={1} alignItems="stretch">
              <Grid item xs={12} md={12} lg={6} onClick={(e) => handleListItemClick(e, 0)}>
                <NavLink to={`/cashboard`}>
                  <Box className="feature-box">
                    <ResponsiveBox
                      icon={<DashboardIcon className="icon--with-bg--1--feature" />}
                      headline="Cashboard"
                      text="Übersicht Kennzahlen wie z.B. Gewinn/Verlust, Einnahmen Ausgaben"
                    />
                  </Box>
                </NavLink>
              </Grid>


              <Grid item xs={12} md={12} lg={6} onClick={(e) => handleListItemClick(e, 1)}>
                <NavLink to={`/clients`}>
                  <Box className="feature-box">
                    <ResponsiveBox
                      icon={<ClientsIcon className="icon--with-bg--1--feature" />}
                      headline="Kunden"
                      text="Kundenübersicht, Kunden anlegen und verwalten"
                    />
                  </Box>
                </NavLink>
              </Grid>
              <Grid item xs={12} md={12} lg={6} onClick={(e) => handleListItemClick(e, 10)}>
                <NavLink to={`/estimates`}>
                  <Box className="feature-box">
                    <ResponsiveBox
                      icon={<GavelIcon className="icon--with-bg--1--feature" />}
                      headline="Kostenvoranschläge"
                      text="Kostenvoranschläge und Angebote erstellen und verwalten"
                    />
                  </Box>
                </NavLink>
              </Grid>
              <Grid item xs={12} md={12} lg={6} onClick={(e) => handleListItemClick(e, 3)}>
                <NavLink to={`/invoices`}>
                  <Box className="feature-box">
                    <ResponsiveBox
                      icon={<LibraryBooksIcon className="icon--with-bg--1--feature" />}
                      headline="Rechnungen"
                      text="Rechnungen, Zahlungserinnerungen und Mahnungen erstellen und verwalten"
                    />
                  </Box>
                </NavLink>
              </Grid>
              <Grid item xs={12} md={12} lg={6} onClick={(e) => handleListItemClick(e, 8)}>
                <NavLink to={`/expenses`}>
                  <Box className="feature-box">
                    <ResponsiveBox
                      icon={<CreditCardIcon className="icon--with-bg--1--feature" />}
                      headline="Ausgaben"
                      text="Ausgaben aus Belegen automatisch auslesen oder manuell erfassen"
                    />
                  </Box>
                </NavLink>
              </Grid>
              <Grid item xs={12} md={12} lg={6} onClick={(e) => handleListItemClick(e, 9)}>
                <NavLink to={`/balance`}>
                  <Box className="feature-box">
                    <ResponsiveBox
                      icon={<HorizontalSplitIcon className="icon--with-bg--1--feature" />}
                      headline="Einnahmen-Ausgaben-Rechnung"
                      text="Finalisierte Einnahmen-Ausgaben-Rechnung für einen gewählten Zeitraum"
                    />
                  </Box>
                </NavLink>
              </Grid>
              <Grid item xs={12} md={12} lg={6} onClick={(e) => {handleListItemClick(e, 5); setSubMenuOpenAtom(true)}}>
                <NavLink to={`/profile`}>
                  <Box className="feature-box">
                    <ResponsiveBox
                      icon={<AccountCircle className="icon--with-bg--1--feature" />}
                      headline="Unternehmensprofil"
                      text="Daten zum eigenen Unternehmen verwalten und Logo für Rechnungslegung hochladen"
                    />
                  </Box>
                </NavLink>
              </Grid>
              <Grid item xs={12} md={12} lg={6} onClick={(e) => {handleListItemClick(e, 6); setSubMenuOpenAtom(true)}}>
                <NavLink to={`/settings`}>
                  <Box className="feature-box">
                    <ResponsiveBox
                      icon={<SettingsIcon className="icon--with-bg--1--feature" />}
                      headline="Einstellungen"
                      text="Einstellungen zum Email-Versand von Rechnungen und Kostenvoranschlägen"
                    />
                  </Box>
                </NavLink>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <NavLink to={`/designer`}>
                  <Box className="feature-box">
                    <ResponsiveBox
                      icon={<BrushIcon className="icon--with-bg--1--feature" />}
                      headline="Designer"
                      text="Aus verschiedenen Vorlagen für Rechnungen und Kostenvoranschlägen wählen und Anpassungen vornehmen"
                    />
                  </Box>
                </NavLink>
              </Grid>
            </Grid>
          </Box>

        </Container>
      </Container>



    </div>
  );
};

export default Dashboard;

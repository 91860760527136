import React, { useState,   useContext,
} from "react";
// IMPORT hooks & context
import { useTranslation } from "react-i18next";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from '../../shared/context/auth-context';

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import expensesAtomState from "../../_atoms/expensesAtom";

// IMPORT components MATERIAL UI
import {
  IconButton,
  Menu,
  MenuItem,
  Button
} from "@material-ui/core";

// IMPORT icons MATERIAL UI
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

// IMPORT functions
import { DB_DELETE_EXPENSE } from './../../_functions/DB_EXPENSES';

// IMPORT components
import { useSnackbar } from 'notistack';

// IMPORT own components
import ConfirmModal from "../../shared/components/UIElements/Modal";

const ITEM_HEIGHT = 48;

export default function ExpensesMenuDots({
  expense,
  setOpenExpenseDialogAtom,
  openExpenseDialogAtom
}) {
  // HOOKS & CONTEXT 
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { enqueueSnackbar } = useSnackbar();

  // GLOBAL STATE (RECOIL)
  const [expensesAtom, setExpensesAtom] = useRecoilState(expensesAtomState);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true);
  };

  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  };

  const confirmDeleteHandler = (expense) => {
    DB_DELETE_EXPENSE(
      expense,
      auth,
      sendRequest,
      enqueueSnackbar,
      t,
      expensesAtom,
      setExpensesAtom,
      setShowConfirmModal
    );
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls={expense.id}
        aria-haspopup="true"
        onClick={handleClick}
        style= {{ marginRight: "5px" }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={expense.id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 1,
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >

          <MenuItem
            style={{ fontSize: "13px" }}
            onClick={
              () => {
                setOpenExpenseDialogAtom({
                  ...openExpenseDialogAtom,
                  open: true,
                  expenseId: expense.id
                });
                handleClose()
              }
            }>
            <EditIcon fontSize="small" style={{ marginRight: "5px" }} />Bearbeiten
          </MenuItem>

          {/* <MenuItem
            style={{ fontSize: "13px" }}
            onClick={
              () => {
                setOpenExpenseDialogAtom({
                  ...openExpenseDialogAtom,
                  open: true,
                  expenseId: expense.id,
                  duplicate: true
                });
                handleClose()
              }
            }
            >
            <FileCopyIcon fontSize="small" style={{ marginRight: "5px" }} />Duplizieren
          </MenuItem> */}

          <MenuItem 
            style={{ fontSize: "13px" }}
            onClick={
              () => {
                showDeleteWarningHandler(expense.id);
                handleClose()
              }
            }
            >
            <DeleteForeverIcon fontSize="small" style={{ marginRight: "5px" }} />Löschen
          </MenuItem>
         
          {/* <InvoiceLivePreview
            openPdfPreview={false}
            sendMode={false}
            client={JSON.parse(invoice.clientData)}
            items={JSON.parse(invoice.items)}
            clientNr={JSON.parse(invoice.clientData).clientNumber}
            invoiceNr={JSON.parse(invoice.invoiceNr).map((item, i) => (item.value))}
            // invoiceNr={invoice.invoiceNr.map((item, i) => (item.value))}
            invoiceDate={new Date(invoice.invoiceDate)}
            invoiceDueDate={new Date(invoice.dueDate)}
            invoiceSubtotal={invoice.subtotal}
            invoiceDiscount={invoice.discount}
            invoiceDiscountAbsolute={invoice.discountAbsolute}
            invoiceDiscountPercent={invoice.discountPercent}
            invoiceDiscountValue={invoice.discountValue}
            invoiceDiscountedSubtotal={invoice.discountedSubtotal}
            invoiceVatValues={JSON.parse(invoice.vatValues)}
            invoiceTotal={invoice.total}
            text={invoice.text}
            loading={false}
            directDownload={true}
            handleClose={handleClose}
          /> */}
      
      </Menu>

      <ConfirmModal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header={t("BUTTONS.expenseDelete")}
        footerClass="expense-item__modal-actions"
        style={{ zIndex: "9999999" }}
        footer={
          <React.Fragment>
            <Button
              variant="outlined"
              style={{ marginRight: "10px" }}
              onClick={cancelDeleteHandler}
            >
              {t("BUTTONS.cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => confirmDeleteHandler(expense.id)}
            >
              {t("BUTTONS.delete")}
            </Button>
          </React.Fragment>
        }
      >
        <p>
          {t("PAGE_EXPENSES.expenseDelete")}
        </p>
      </ConfirmModal>
    </div>
  );
}

import React, { useEffect, useState, useContext } from "react";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import userAtomState from "../../../_atoms/userAtom";
import openInvoiceDialogAtomState from "../../../_atoms/openInvoiceDialogAtom";
import settingsAtomState from "../../../_atoms/settingsAtom";
import designerAtomState from "../../../_atoms/designerAtom";


// IMPORT hooks & context
import { useTranslation } from "react-i18next";

// import PreviewInvoice from "./../../invoices/pages/PreviewInvoice";

import { usePDF, Font } from '@react-pdf/renderer';

import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/auth-context";

// IMPORT functions
import { DB_GET_THEME } from '../../../_functions/DB_THEMES';

import { ThemeClassic } from "./themes/ThemeClassic";

import {
  Grid,
  Paper,
} from "@material-ui/core";

import { SET_VALUES } from '../../../_functions/SET_VALUES';

import { CLIENT } from './defaultData';
import { INVOICE } from './defaultData';


// IMPORT components

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import "./../InvoiceLivePreview.scss"

import Inter from "./../../../_assets/fonts/inter/Inter-Regular.ttf";
import InterBold from "./../../../_assets/fonts/inter/Inter-Bold.ttf";
import Jakarta from "./../../../_assets/fonts/plusjakartasans/PlusJakartaSans-Regular.ttf";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { validate } from "@material-ui/pickers";


const invoiceTheme = (selectedTheme) => {
  let theme;
  switch (selectedTheme) {
    case "classic":
      theme = {
        theme: "classic",
        colorBgBody: "#bada55"
      }
      break;
    default:
      theme = {
        theme: "classic",
        colorBgBody: "#ffffff"
      }
  }
  return theme;
};



function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

// TODO: 
// Create 5 different templates as starter basis
// Background color
// Background image
// Font family
// Font sizes
// Font color
// Show / hide dividers
// Letter spacing
// ??? Arrangement of sections

// FIXME:  



const DesignPreview = (props) => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);

  // GLOBAL STATE (RECOIL)
  const [userAtom] = useRecoilState(userAtomState);
  const [designerAtom, setDesignerAtom] = useRecoilState(designerAtomState);
  const [settingsAtom] = useRecoilState(settingsAtomState);


  const [cssZoom, setCssZoom] = useState("100%");




  // const client = props.client === "default" && CLIENT;
  // const invoice = props.invoice === "default" && INVOICE;

  const [values, setValues] = useState({
    client: {},
    invoice: {},
    items: [],
    country: "",
    vatValues: {},
    isLoading: true,
  });

  useEffect(() => {

    const country = props.client === "default" ? JSON.parse(CLIENT.country) : JSON.parse(props.client.country);
    setValues({
      ...values,
      client: CLIENT,
      invoice: props.invoice === "default" ? INVOICE : props.invoice,
      items: props.items === "default" ? JSON.parse(INVOICE.items) : props.items,
      country: country.label,
      vatValues: props.invoice === "default" ? JSON.parse(INVOICE.vatValues) : props.invoice.vatValues,
      isLoading: false
    })
  }, []);


  Font.register({
    family: "Inter",
    fonts: [
      {
        src: Inter,
      },
      {
        src: InterBold,
        fontWeight: 'bold',
      },
      // {
      //   src: FontUbuntu700,
      //   fontWeight: 'normal',
      //   fontStyle: 'italic',
      // },
    ],
  });

  Font.register({
    family: "Plus Jakarta Sans",
    format: "truetype",
    src: Jakarta
  });




  let MyDoc = !values.isLoading && ThemeClassic(props.design, values, t, userAtom, cssZoom);



  // Variable has to be placed below MyDoc and above updateInstance
  const [instance, updateInstance] = usePDF({ document: MyDoc });

  useEffect(() => {
    if (settingsAtom.invoicesSelectedTheme === "classic") {
      // Default "classic" before any theme is saved
      setDesignerAtom(invoiceTheme(settingsAtom.invoicesSelectedTheme));
    } else {
      DB_GET_THEME(settingsAtom, setDesignerAtom, auth, sendRequest)
    }
  }, [])

  // Update pdf content when values change because of input
  // DO NOT forget to add cssX dependencies in Order to change the the style of the PDF that is sent via Email!!
  // ALL styles that are only applied to the screen and not to the PDF that is sent, should NOT be added as dependency but in .scss!!!
  useEffect(() => {
    updateInstance(MyDoc);

  }, [values, designerAtom, values.client])

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Calculate zoom depending on screen width
  useEffect(() => {
    const zoomFactor = (100 / 1920 * windowDimensions.width) - 8;
    if (props.widthDesktop === "50%") {
      if (windowDimensions.width < 1920) {
        setCssZoom(`${zoomFactor}%`)
      } else {
        setCssZoom(`${zoomFactor}%`)
        // setCssZoom(`100%`)
      }
    } else if (props.widthDesktop === "30%") {
      if (windowDimensions.width < 1920) {
        setCssZoom(`${zoomFactor/100*30}%`)
      } else {
        setCssZoom(`${zoomFactor/100*30}%`)
        // setCssZoom(`100%`)
      }
    }
   
  }, [windowDimensions, cssZoom]);


  const handleChange = (e) => {
    SET_VALUES(e, values, setValues);
  };

  return (
    <div className="invoice-paper bg--1" style={{ width: "100%" }}>
      <Grid container item xs={12} direction="column" spacing={2} style={{ margin: 0 }}>
        <Grid container item xs={12} direction="column" spacing={2} style={{ margin: 0 }}>
          <Grid item xs={12} style={{ marginTop: "15px" }}>
            <Paper elevation={0}>
              {MyDoc}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default DesignPreview;

export const DB_GET_CLIENTS = async (setClientsAtom, auth, sendRequest) => {
  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL_API}/clients/user/${auth.userId}`,
      "GET",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    )
    // NO ERROR
    .then((res) => setClientsAtom(res.clients))
  } catch (err) { 
    // ERROR
  }
};

export const DB_DELETE_CLIENT = async (
  client,
  auth,
  sendRequest,
  enqueueSnackbar,
  t,
  clientsAtom,
  setClientsAtom,
  openClientDialogAtom,
  setOpenClientDialogAtom,
  setShowConfirmModal
  ) => {
  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL_API}/clients/${client}`,
      "DELETE",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    )
    // NO ERROR
    .then(() => setOpenClientDialogAtom({ ...openClientDialogAtom, open: false, clientId: "" }))
    .then(() => setShowConfirmModal(false))
    .then(() => setClientsAtom(clientsAtom.filter((item) => item.id !== client)))
    .then(() => enqueueSnackbar(t("FIELDS.saved"), { variant: "success" }))
  } catch (err) { 
    // ERROR
    enqueueSnackbar(t("FIELDS.error"), { variant: "error" })
  }
};

export const DB_UPDATE_CLIENT = async (client, auth, sendRequest, enqueueSnackbar, t, clientsAtom, setClientsAtom, openClientDialogAtom, setOpenClientDialogAtom, setShowConfirmModal) => {
  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL_API}/clients/${client}`,
      "DELETE",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    )
    // NO ERROR
    .then(() => setOpenClientDialogAtom({ ...openClientDialogAtom, open: false, clientId: "" }))
    .then(() => setShowConfirmModal(false))
    .then(() => setClientsAtom(clientsAtom.filter((item) => item.id !== client)))
    .then(() => enqueueSnackbar(t("FIELDS.saved"), { variant: "success" }))
  } catch (err) { 
    // ERROR
    enqueueSnackbar(t("FIELDS.error"), { variant: "error" })
  }
};

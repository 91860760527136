import React from "react";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";

// IMPORT components MATERIAL UI
import { Typography } from "@material-ui/core";
import Link from "@material-ui/core/Link";

const Copyright = (props) => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  
  return (
    <Typography variant="subtitle2" style={{color: props.color === "bright" ? "#ffffff" : "textSecondary", fontWeight: "normal"}} align="center">
      {'Copyright © '}
      <Link color="inherit" href={process.env.REACT_APP_FRONTEND_URL}>
        {t("APP.frontend")}
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>

  );
};

export default Copyright;

import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

const checkoutAtom = atom({
  key: "checkout",
  default: {
    premiumMonthly: {
        productId: "",
        price: 0,
        name: ""
    },
    premiumYearly: {
        productId: "",
        price: 0,
        name: ""
    },
    proMonthly: {
        productId: "",
        price: 0,
        name: ""
    },
    proYearly: {
        productId: "",
        price: 0,
        name: ""
    },
    userTaxRate: 20,
    selectedInterval: {
      premium: "monthly",
      pro: "monthly",
    },
    selectedProduct: {},
    show: "PREMIUM_PRO"
  },
  effects_UNSTABLE: [persistAtom],
});

export default checkoutAtom

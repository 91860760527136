import { enqueueSnackbar } from 'notistack';
import { DB_PATCH_SETTINGS } from '../../../_functions/DB_SETTINGS';

const invoiceSave = async (
  event,
  sendMode,
  cancelSchedule,
  openInvoiceDialogAtom,
  setSendMode,
  setOpen,
  values,
  setValues,
  invoicesAtom,
  selectedClient,
  items,
  userId,
  auth,
  sendRequest,
  setOpenInvoiceDialogAtom,
  setSettingsAtom,
  setInvoicesAtom,
  setInvoicesFilteredAtom,
  settings,
  t
) => {
  event.preventDefault();

  sendMode && setSendMode(true);
  sendMode && setOpen(true);

  let invoiceNumbers = [];
  let invoiceNumbersError = false;
  let invNr = values.invoiceNumber.state.map((item) => item.value).join("");

  if (openInvoiceDialogAtom.invoiceId === "" || (openInvoiceDialogAtom.invoiceId !== "" && invNr !== values.initialInvoiceNumber)) {
    if (!openInvoiceDialogAtom.isEstimate) {
      invoiceNumbersError = true;
      invoicesAtom.forEach((item) => {
        if (item.type === "invoice" || item.type === "reminder" || item.type === "reminderRequest") {
          invoiceNumbers.push(JSON.parse(item.invoiceNr).map((item) => item.value).join(""));
        }
      });
      if (invoiceNumbers.indexOf(values.invoiceNumber.state.map((item) => item.value).join("")) !== -1 && values.initialInvoiceNumber !== values.invoiceNumber.state.map((item) => item.value).join("")) {
        invoiceNumbersError = true;
        enqueueSnackbar("Die Rechnungsnummer existiert bereits", { variant: "error" });
        return;
      } else {
        invoiceNumbersError = false;
      }
    }

    if (openInvoiceDialogAtom.isEstimate && values.type === "estimate") {
      invoiceNumbersError = true;
      invoicesAtom.forEach((item) => {
        if (item.type === "estimate") {
          invoiceNumbers.push(JSON.parse(item.invoiceNr).map((item) => item.value).join(""));
        }
      });
      if (invoiceNumbers.indexOf(values.invoiceNumber.state.map((item) => item.value).join("")) !== -1 && values.initialInvoiceNumber !== values.invoiceNumber.state.map((item) => item.value).join("")) {
        invoiceNumbersError = true;
        enqueueSnackbar("Die KV-Nummer existiert bereits", { variant: "error" });
        return;
      } else {
        invoiceNumbersError = false;
      }
    }

    if (openInvoiceDialogAtom.isEstimate && values.type === "offer") {
      invoiceNumbersError = true;
      invoicesAtom.forEach((item) => {
        if (item.type === "offer") {
          invoiceNumbers.push(JSON.parse(item.invoiceNr).map((item) => item.value).join(""));
        }
      });
      if (invoiceNumbers.indexOf(values.invoiceNumber.state.map((item) => item.value).join("")) !== -1 && values.initialInvoiceNumber !== values.invoiceNumber.state.map((item) => item.value).join("")) {
        invoiceNumbersError = true;
        enqueueSnackbar("Die Angebotsnummer existiert bereits", { variant: "error" });
        return;
      } else {
        invoiceNumbersError = false;
      }
    }
  }

  if (userId && auth.token && !values.invoiceNumber.error) {
    try {
      const formData = new FormData();
      formData.append("clientData", JSON.stringify(selectedClient));
      formData.append("items", JSON.stringify(items));
      formData.append("invoiceNr", JSON.stringify(values.invoiceNumber.state));
      formData.append("invoiceDate", values.invoiceDate);
      formData.append("sendDate", values.sendDate);
      formData.append("dueDate", values.dueDate);
      formData.append("payDate", values.payDate);
      formData.append("subtotal", JSON.stringify(values.subtotal));
      formData.append("vatValues", JSON.stringify(values.vatValues));
      formData.append("total", JSON.stringify(values.total));
      formData.append("discount", values.discount);
      formData.append("discountAbsolute", values.discountAbsolute);
      formData.append("discountPercent", values.discountPercent);
      formData.append("discountValue", values.discountValue);
      formData.append("discountedSubtotal", JSON.stringify(values.discountedSubtotal));
      formData.append("discountedTotal", JSON.stringify(values.discountedTotal));
      formData.append("status", values.status.state);
      formData.append("sendState", cancelSchedule ? (values.sentBlobs.length >= 1 ? "sent" : "notSent") : values.sendState.state);
      formData.append("payState", values.payState.state);
      formData.append("recurring", values.recurring);
      formData.append("headline", values.headline.state);
      formData.append("text", values.text.state);
      formData.append("blob", localStorage.getItem('jsonString'));
      formData.append("type", values.type);
      formData.append("reminderRequestCharge", values.reminderRequestCharge === "" ? "0" : values.reminderRequestCharge);
      formData.append("showAdditionalText", values.showAdditionalText);
      formData.append("additionalText", values.additionalText);

      if (openInvoiceDialogAtom.invoiceId === "" || openInvoiceDialogAtom.duplicate === true) {
        // CREATE NEW INVOICE
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL_API}/invoices`,
          "POST",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        )
          .then((responseData) => {
            setValues({ ...values, initialInvoiceNumber: JSON.parse(responseData.invoice.invoiceNr).map((item) => item.value).join("") });
            setOpenInvoiceDialogAtom({ ...openInvoiceDialogAtom, invoiceId: responseData.invoice._id });
          })
          .then(() => DB_PATCH_SETTINGS(settings, auth, sendRequest, enqueueSnackbar, t, false))
          .then(() => setSettingsAtom(settings));
      } else if (openInvoiceDialogAtom.invoiceId !== "" && openInvoiceDialogAtom.duplicate === false) {
        // UPDATE EXISTING INVOICE
        const responseDate = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL_API}/invoices/${openInvoiceDialogAtom.invoiceId}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        )
          .then((responseData) => setValues({ ...values, invoiceId: responseData.invoice._id }))
          .then(() => DB_PATCH_SETTINGS(settings, auth, sendRequest, enqueueSnackbar, t, false))
          .then(() => setSettingsAtom(settings));
      }

      // FETCH updated Invoices List
      try {
        const resInvoices = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL_API}/invoices/user/${userId}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${auth.token}`
          }
        );
        setInvoicesAtom(resInvoices.invoices);
        setInvoicesFilteredAtom(resInvoices.invoices);
        enqueueSnackbar(t("FIELDS.saved"), { variant: "success" });
      } catch (err) {
        console.log(err);
        !sendMode && enqueueSnackbar(t("ERRORS.save_server"), { variant: "error" });
      }
    } catch (err) {
      console.log(err);
      !sendMode && enqueueSnackbar(t("ERRORS.save_server"), { variant: "error" });
    }
  } else {
    console.log("foo");
    enqueueSnackbar(t("ERRORS.save_form"), { variant: "error" });
  }
};

export default invoiceSave;

import React, { useEffect, useState, useContext } from "react";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { useAuth } from "../../shared/hooks/auth-hook";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import userAtomState from "../../_atoms/userAtom";
import clientsAtomState from "../../_atoms/clientsAtom";
import openClientDialogAtomState from "../../_atoms/openClientDialogAtom";
import settingsAtomState from "../../_atoms/settingsAtom";

// IMPORT components
import { useSnackbar } from 'notistack';

// IMPORT components MATERIAL UI
import {
  Typography,
  Box,
  Container,
  Grid,
  Tooltip,
  Chip,
  Collapse,
  IconButton,
  Fab
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';


// IMPORT icons MATERIAL UI
import ClientsIcon from "@material-ui/icons/RecentActors";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FilterListIcon from '@material-ui/icons/FilterList';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';


// IMPORT own functions
import { SET_VALUE } from './../../_functions/SET_VALUE';
import { DB_GET_CLIENTS } from './../../_functions/DB_CLIENTS';
import { DB_PATCH_SETTINGS } from './../../_functions/DB_SETTINGS';

// IMPORT own components
import UserProfileIncompleteWarning from "../../user/components/UserProfileIncompleteWarning";
import ClientsMenu from "../components/ClientsMenu";
import ClientsSearch from "../components/ClientsSearch";
import LogoMonogram from './../../_assets/logo_monogram.svg';
import ClientsSort from "../components/ClientsSort";
import ClientsFilter from "../components/ClientsFilter";
import ClientsSettings from "../components/ClientsSettings";

// IMPORT own CSS
import "./UserClients.scss"

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: {
    height: '0px',
    '@media (max-width: 959px)': {
      height: '62px',
    },
    '@media (max-width: 599px)': {
      height: '45px',
    },
    '@media (max-width: 455px)': {
      height: '55px',
    },
  },
  table: {
    minWidth: 650,
  },
}));

const UserClients = () => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { isLoading, error, sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const { userId } = useAuth();

  // GLOBAL STATE (RECOIL)
  const [userAtom] = useRecoilState(userAtomState);
  const [openClientDialogAtom, setOpenClientDialogAtom] = useRecoilState(openClientDialogAtomState);
  const [clientsAtom, setClientsAtom] = useRecoilState(clientsAtomState);
  const [settingsAtom, setSettingsAtom] = useRecoilState(settingsAtomState);

  // LOCAL STATE (GLOBAL DEPENDENCY monitoring temporay CHANGES until SAVED)
  const [settings, setSettings] = useState(settingsAtom);

  // LOCAL STATE (PURE)
  const [draggableSortItems, setDraggableSortItems] = useState([]);
  const [values, setValues] = useState({
    submenuOpen: false, // values: false, search, filter, sort, settings
    clientsFiltered: {},
    searchInput: "",
    chipDirectChange: false,
    save: false
  });

  /* 
    FIRST PAGE LOAD
  */
  // FILTER: Client Status
  
  let clientsFilteredInitial = clientsAtom.filter((client) => {
    return (
      settingsAtom.clientsFiltersStatusActive && client.status === "active" ||
      settingsAtom.clientsFiltersStatusInactive && client.status === "inactive" ||
      settingsAtom.clientsFiltersStatusProspect && client.status === "prospect" ||
      // Reset client status filter (return all clients when no client status filter is set)
      !settingsAtom.clientsFiltersStatusActive && !settingsAtom.clientsFiltersStatusInactive && !settingsAtom.clientsFiltersStatusProspect
    );
  })

  // SORT: Creation point in time
  if (settingsAtom.clientsSortCreation) {
    if (settingsAtom.clientsSortCreationOption === "chronological") {
      clientsFilteredInitial = clientsFilteredInitial;
    } else if (settingsAtom.clientsSortCreationOption === "reverse-chronological") {
      clientsFilteredInitial = Array.from(clientsFilteredInitial).reverse();
    }
  }

  // SORT: Client name alphabetically
  if (settingsAtom.clientsSortCompany) {
    if (settingsAtom.clientsSortCompanyOption === "ascending") {
      clientsFilteredInitial = Array.from(clientsFilteredInitial).sort((a, b) => {
        return (a.company).toLowerCase() < (b.company).toLowerCase() ? -1 : 1
        // return a.company < b.company ? -1 : 1
      })
    } else if (settingsAtom.clientsSortCompanyOption === "descending") {
      clientsFilteredInitial = Array.from(clientsFilteredInitial).sort((a, b) => {
        return (a.company).toLowerCase() > (b.company).toLowerCase() ? -1 : 1
        // return a.company > b.company ? -1 : 1
      })
    }
  }

    // SORT: Invoice number
    if (settingsAtom.clientsSortNumber) {
      const returnValue = (item, type) => JSON.parse(item.clientNumber).find(obj => {return obj.type === type}).value;
      
      if (settingsAtom.clientsSortNumberOption === "ascending") {
        clientsFilteredInitial = Array.from(clientsFilteredInitial).sort((a, b) => {
          a = returnValue(a, "continuousNumber");
          b = returnValue(b, "continuousNumber");
          return (a < b) ? -1 : 1
        })
      
      } else if (settingsAtom.clientsSortNumberOption === "descending") {
         clientsFilteredInitial = Array.from(clientsFilteredInitial).sort((a, b) => {
          a = returnValue(a, "continuousNumber");
          b = returnValue(b, "continuousNumber");
          return (a > b) ? -1 : 1
        })
      }
    }

  const handleSearchClear = () => {
    SET_VALUE({
      target: {
        "searchInput": "",
        "clientsFiltered": clientsFilteredInitial
      }
    }, setValues, "multi");
  };

  useEffect(() => {
    // Only fetch from DB the first time the clients page is loaded
    clientsAtom.length === 0 && DB_GET_CLIENTS(setClientsAtom, auth, sendRequest);
  }, [userId, auth.token]);

  useEffect(() => {
    SET_VALUE({ target: { id: "clientsFiltered", value: clientsFilteredInitial } }, setValues);
  }, [JSON.stringify(clientsFilteredInitial)]); // stringifying object dependency prevents infitive loop

  const handleChange = (e) => {
    SET_VALUE(e, setSettings);
  };

  // ON SAVE: 1) Update settingsAtom
  const updateSettingsHandler = () => {
    // GLOBAL changes
    setSettingsAtom(settings);

    // Save variable is only needed to trigger submenuClose when NOTHING changes in settings and save button is clicked
    SET_VALUE({ target: { id: "save", value: true } }, setValues);
  };

  // ON SAVE: 2) DB: PATCH SETTINGS
  useEffect(() => {
    if ((auth.token && values.submenuOpen !== false) || (auth.token && values.chipDirectChange === true)) {
      DB_PATCH_SETTINGS(settingsAtom, auth, sendRequest, enqueueSnackbar, t);

      SET_VALUE({
        target: {
          "submenuOpen": false,
          "chipDirectChange": false,
          "save": false,
        }
      }, setValues, "multi")
    }
  }, [auth.token, settingsAtom, values.save]);

  // ON CANCEL:
  const cancelSettingsHandler = () => {
    // Re-set original values
    setSettings(settingsAtom);
    SET_VALUE({ target: { id: "submenuOpen", value: false } }, setValues);
  };

  // Add to Sort Chips
  useEffect(() => {
    let i = []

    if (settingsAtom.clientsSortCreation) {
      i.push("clientsSortCreation")
    }
    if (settingsAtom.clientsSortNumber) {
      i.push("clientsSortNumber")
    }
    if (settingsAtom.clientsSortCompany) {
      i.push("clientsSortCompany")
    }

    setDraggableSortItems(i)
  }, [settingsAtom]);

  // const onSortEnd = (oldIndex, newIndex) => {
  //   setDraggableSortItems((array) => arrayMove(array, oldIndex, newIndex));
  // };

  return (
    <div>
      <div className={classes.toolbar} />
      <Fade in={true}>
        <Grid container className="subpage-header" maxWidth={false} item direction="row" alignItems="center" alignContent="center">
          <Grid item container alignItems="center" style={{ marginRight: "10px" }}>
            <ClientsIcon color="primary" fontSize="large" style={{ background: "linear-gradient(to right top, rgb(38, 108, 189), rgb(36, 115, 192), rgb(33, 150, 243), #00bca7, #00bf72)", marginRight: "15px" }} className="icon--with-bg--1" />
            <Typography variant="body1" component="div" style={{ fontWeight: "500" }} className="headline">{t("PAGE_CLIENTS.h1")}</Typography>
            <IconButton
              aria-label="add"
              color="primary"
              style={{ marginLeft: "5px" }}
              onClick={()=>{
                setOpenClientDialogAtom({
                  ...openClientDialogAtom,
                  open: true,
                });
              }}
            >
              <AddIcon />
            </IconButton>
          </Grid>

          

          <Grid item container justifyContent="space-between">
            <Grid item container alignItems="center" style={{ padding: "0px 2px 0px 0px", borderRadius: "6px", marginTop: "10px", width: "auto", boxShadow: "inset rgb(108 116 173 / 12%) 0px 0px 20px 0px" }}>

              <IconButton aria-label="search" style={{ color: "inherit" }}
                onClick={() => {
                  SET_VALUE({ target: { id: "submenuOpen", value: values.submenuOpen !== "search" ? "search" : false } }, setValues);
                  handleSearchClear();
                }}
              >
                <SearchIcon fontSize="small" style={{ padding: values.submenuOpen === "search" ? "2px" : "inherit", backgroundColor: values.submenuOpen === "search" ? "#2196f3" : "inherit", color: values.submenuOpen === "search" ? "#ffffff" : "inherit", borderRadius: "60px"}}  />
              </IconButton>

              <ClientsSort
                settings={settings}
                handleChange={handleChange}
                cancelSettingsHandler={cancelSettingsHandler}
                updateSettingsHandler={updateSettingsHandler}
                setValues={setValues}
                SET_VALUE={SET_VALUE}
                setSettings={setSettings}
                values={values}
              />

              <ClientsFilter
                settings={settings}
                handleChange={handleChange}
                cancelSettingsHandler={cancelSettingsHandler}
                updateSettingsHandler={updateSettingsHandler}
                setValues={setValues}
                SET_VALUE={SET_VALUE}
                setSettings={setSettings}
                values={values}
              />

              <ClientsSettings
                settings={settings}
                handleChange={handleChange}
                cancelSettingsHandler={cancelSettingsHandler}
                updateSettingsHandler={updateSettingsHandler}
                setValues={setValues}
                SET_VALUE={SET_VALUE}
                setSettings={setSettings}
                values={values}
              />

              {/* <ClientsCsv data={values.clientsFiltered} /> */}
            </Grid>
          </Grid>

          <Grid item style={{ display: "block", width: "100%"}}>
            <Collapse
              in={values.submenuOpen === "search" ? true : false}
              style={{ marginTop: values.submenuOpen === "search" ? "5px" : 0 }}
            // style={{ position: "fixed", top: "122px", zIndex: 9, width: "100%" }}
            >
              <ClientsSearch
              values={values}
              clientsFilteredInitial={clientsFilteredInitial}
              SET_VALUE={SET_VALUE}
              setValues={setValues}
              handleSearchClear={handleSearchClear}
              />
            </Collapse>
          </Grid>
        </Grid>
      </Fade>

      {/* CHIPS */}
      <Grid container item xs={12} alignItems="center" className="chips">
        <>
          <>
            <ImportExportIcon fontSize="small" style={{ marginTop: "-2.5px", marginRight: "5px", color: "rgba(0, 0, 0, 0.87)", boxShadow: "rgba(108, 116, 173, 0.12) 0px 0px 20px 0px inset", borderRadius: "4px"  }} />
            <Typography variant="body2" component="div" style={{ marginRight: "5px", fontSize: "10px", color: "rgba(0, 0, 0, 0.87)"  }}>{t("PAGE_CLIENTS.sorted")}:</Typography>

            {/* <SortableList onSortEnd={onSortEnd} className="list" draggedItemClassName="dragged"> */}
            {draggableSortItems.map((item) => (
              // <SortableItem key={item}>
              <Chip
                // icon={<DragIndicatorIcon />}
                style={{ marginRight: "10px", fontSize: "10px" }}
                key={item}
                size="small"
                label={
                  item === "clientsSortCreation" ?
                    settings.clientsSortCreationOption === "chronological" ? t("PAGE_CLIENTS.sortCreationChronologically") : t("PAGE_CLIENTS.sortCreationChronologicallyReverse")
                    : item === "clientsSortNumber" ?
                      settings.clientsSortNumberOption === "ascending" ? t("PAGE_CLIENTS.sortClientNumbersAsc") : t("PAGE_CLIENTS.sortClientNumbersDesc")
                      : item === "clientsSortCompany" &&
                        settings.clientsSortCompanyOption === "ascending" ? t("PAGE_CLIENTS.sortCompanyAsc") : t("PAGE_CLIENTS.sortCompanyDesc")
                }
                onClick={() => SET_VALUE({ target: { id: "submenuOpen", value: "sort" } }, setValues)}

                // onDelete={() => {
                //   SET_VALUE({ target: { id: "chipDirectChange", value: true } }, setValues);
                //   SET_VALUE({ target: { id: item, value: false } }, setSettings);
                //   SET_VALUE({ target: { id: item, value: false } }, setSettingsAtom);
                //   setDraggableSortItems(oldValues => {
                //     return oldValues.filter(items => items !== item)
                //   })
                // }}
                className="draggable-chip"
              />
              // </SortableItem>

            ))}
            {/* </SortableList> */}
          </>          
        
          {/* CHIPS: Filter */}
          {(settingsAtom.clientsFiltersStatusActive || settingsAtom.clientsFiltersStatusInactive || settingsAtom.clientsFiltersStatusProspect) &&
          <>
            <FilterListIcon fontSize="small" style={{ marginTop: "-2.5px", marginRight: "5px", color: "rgba(0, 0, 0, 0.87)", boxShadow: "rgba(108, 116, 173, 0.12) 0px 0px 20px 0px inset", borderRadius: "4px"  }} />
            <Typography variant="body2" component="div" style={{ marginRight: "5px", fontSize: "10px", color: "rgba(0, 0, 0, 0.87)"  }}>Gefiltert nach Status: </Typography>
            {settingsAtom.clientsFiltersStatusActive &&
              <Chip
                icon={<FiberManualRecordIcon style={{ color: "rgb(76, 175, 80)", background: "rgb(76, 175, 80)", borderRadius: "60px" }}/>}
                size="small"
                label={t("PAGE_CLIENTS.filterStatusActive")}
                onDelete={() => {
                  SET_VALUE({ target: { id: "chipDirectChange", value: true } }, setValues);
                  SET_VALUE({ target: { id: "clientsFiltersStatusActive", value: false } }, setSettings);
                  SET_VALUE({ target: { id: "clientsFiltersStatusActive", value: false } }, setSettingsAtom);
                }}
                style={{ background: "#edefff", marginTop: "2.5px", marginBottom: "2.5px", marginRight: "5px" }}
                className="draggable-chip active"
                onClick={() => SET_VALUE({ target: { id: "submenuOpen", value: "filter" } }, setValues)}
              />
            }
            {settingsAtom.clientsFiltersStatusInactive &&
              <Chip
                icon={<FiberManualRecordIcon style={{ color: "rgb(183, 28, 28)", background: "rgb(183, 28, 28)", borderRadius: "60px" }}/>}
                size="small"
                label={t("PAGE_CLIENTS.filterStatusInactive")}
                onDelete={() => {
                  SET_VALUE({ target: { id: "chipDirectChange", value: true } }, setValues);
                  SET_VALUE({ target: { id: "clientsFiltersStatusInactive", value: false } }, setSettings);
                  SET_VALUE({ target: { id: "clientsFiltersStatusInactive", value: false } }, setSettingsAtom);
                }}
                style={{ background: "#edefff", marginTop: "2.5px", marginBottom: "2.5px", marginRight: "5px" }}
                className="draggable-chip inactive"
                onClick={() => SET_VALUE({ target: { id: "submenuOpen", value: "filter" } }, setValues)}
              />
            }
            {settingsAtom.clientsFiltersStatusProspect &&
              <Chip
                icon={<FiberManualRecordIcon style={{ color: "rgb(255, 183, 77)", background: "rgb(255, 183, 77)", borderRadius: "60px" }}/>}
                size="small"
                label={t("PAGE_CLIENTS.filterStatusProspect")}
                onDelete={() => {
                  SET_VALUE({ target: { id: "chipDirectChange", value: true } }, setValues);
                  SET_VALUE({ target: { id: "clientsFiltersStatusProspect", value: false } }, setSettings);
                  SET_VALUE({ target: { id: "clientsFiltersStatusProspect", value: false } }, setSettingsAtom);
                }}
                style={{ background: "#edefff", marginTop: "2.5px", marginBottom: "2.5px", marginRight: "5px" }}
                className="draggable-chip prospect"
                onClick={() => SET_VALUE({ target: { id: "submenuOpen", value: "filter" } }, setValues)}
              />
            }
          </>
        }
        </>
      </Grid>

      {true &&
      <>


<React.Fragment>
<Container maxWidth={false} disableGutters className="bg--1" >
  <Box
    display="flex"
    flexDirection="column"
    className="list-box"
  >
    <Grid container item xs={12} >
      <div className="box-shadow--2" style={{ width: "100%" }}>
        <Grid
          item
          className="sticky-nav box-shadow--2 h-padding--5-5 tool-menu"
        >
          <Grid container>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              item
              style={{ paddingLeft: "19px"}}
              xs={12}
            >
              <Grid container item xs={11} justifyContent="space-between">
                <Grid container item alignItems="center" xs={12} lg={4}>
                  <div>
                    <Typography>Kunde</Typography>
                  </div>
                </Grid>

                <Grid container item justifyContent="flex-end" alignItems="center" xs={12} lg={8}>
                  <Grid container item xs={12} md={2} justifyContent="center">
                    <Typography style={{ textAlign: "center" }}>Nr.</Typography>
                  </Grid>

                  <Grid container item xs={4} md={2} justifyContent="center">
                    <Typography style={{ textAlign: "center" }}>Status</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item xs={1} justifyContent="flex-end">

              </Grid>
            </Grid>
          </Grid>
        </Grid>




        {(!isLoading) &&
                    <>
                      {/* CLIENTS already created */}
                      {clientsAtom.length !== 0 &&
                        <>
                          {/* APPLIED FILTER after Loading doesn't result in list of length 0 */}
                          {Object.keys(values.clientsFiltered).length !== 0 &&
                              (values.clientsFiltered).map((client, i) => (
                              <Grid container key={i}>
                                <Grid
                                  container
                                  alignItems="center"
                                  justifyContent="space-between"
                                  item
                                  xs={12}
                                  className="h-bg--white user-clients"
                                  style={{
                                    borderBottom: "1px solid #e2ecfd",
                                    borderLeft: 
                                      client.status === "active" ? `5px solid rgb(76, 175, 80)` :
                                      client.status === "inactive" ? `5px solid rgb(183, 28, 28)` :
                                      `5px solid rgb(255, 183, 77)`
                                  }}
                                >

                                  <Grid container item xs={11} justifyContent="space-between">
                                    <Grid container item alignItems="center" xs={12} lg={4}
                                      className="image-name-company"
                                      style={{ padding: "30px 15px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", flexWrap: "nowrap" }}
                                      onClick={() => setOpenClientDialogAtom({
                                        ...openClientDialogAtom,
                                        open: true,
                                        clientId: client.id
                                      })}
                                    >

                                      <div style={{ width: "50px", minWidth: "50px", height: "25px", marginRight: "10px", position: "relative" }}>
                                        {client.logo !== "" ? (
                                            <img
                                              // src={`${process.env.REACT_APP_BACKEND_URL}/${JSON.parse(invoice.clientData).image}`}
                                              src={client.logo}
                                              alt="logo"
                                              style={{
                                                maxWidth: "100%",
                                                maxHeight: "100%",
                                                marginRight: "10px",
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                transform: "translate(-50%, -50%)"
                                              }}
                                            />
                                          ) :
                                            <img src={LogoMonogram} height={25} width={25} alt={`${t("APP.name")} Logo`} style={{ width: "50px", marginRight: "10px", opacity: 0.2 }} />
                                          }
                                      </div>

                                      <Typography component="div" variant="body2" style={{ fontWeight: "500", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{client.company}</Typography>
                                    </Grid>

                                    <Grid container item justifyContent="flex-end" alignItems="center" xs={12} lg={8}
                                      className="invoice-meta"
                                      style={{padding: "30px 0", }}
                                      onClick={() => setOpenClientDialogAtom({
                                        ...openClientDialogAtom,
                                        open: true,
                                        clientId: client.id
                                      })}
                                    >
                                     
                                      <Grid container item xs={4} md={2} justifyContent="center">
                                        <Typography style={{ textAlign: "center", fontSize: "10px" }} component="div" variant="body2"><span className="menu-heading-title">Nr.<br /></span>
                                        <span style={{ fontWeight: "600" }}>
                                        {JSON.parse(client.clientNumber).map((item, i) => (
                                          <span>
                                          {item.type === "continuousNumber" &&
                                            <span style={{ background: "rgba(33, 150, 243, 0.1)", padding: "7.5px", borderRadius: "4px", marginLeft: "1px", marginRight: "1px" }}>{item.value}</span>
                                          }
                                          {item.type !== "continuousNumber" &&
                                            <span>{item.value}</span>
                                          }
                                          </span>
                                        ))}
                                        </span>
                                        </Typography>
                                      </Grid>

                                      <Grid container item xs={4} md={2} justifyContent="center">
                                        <Typography style={{ textAlign: "center", fontSize: "10px" }} component="div" variant="body2"><span className="menu-heading-title">Status<br /></span>
                                          <div style={{ display: "flex", alignItems: "center" }}>
                                            <FiberManualRecordIcon style={{ color: client.status === "active" ? theme.palette.success.light : client.status === "inactive" ? theme.palette.error.main : theme.palette.warning.light }} />
                                          </div>
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid container item xs={1} justifyContent="flex-end">
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))
                          }

                          {/* APPLIED FILTER after Loading does result in list of length 0 */}
                          {!values.loading && !isLoading && Object.keys(values.clientsFiltered).length === 0 &&
                            <Typography component="div" style={{ fontSize: "12px", padding: "10px 20px" }}>{t("PAGE_CLIENTS.noResult")}</Typography>
                          }
                        </>
                      }

                      {/* NO CLIENTS created yet */}
                      {!values.loading && !isLoading && clientsAtom.length === 0 &&
                        <Typography component="div" style={{ fontSize: "12px", padding: "10px 20px" }}>{t("PAGE_CLIENTS.noClientsCreated")}</Typography>
                      }

                      {(values.loading || isLoading) &&
                        <Typography>Rechnungen werden geladen ...</Typography>
                      }
                    </>
                  }




        
</div>
</Grid>
</Box>
</Container>
</React.Fragment>







        </>
      }

      {/* {!userAtom.data.signupCompleted &&
        <UserProfileIncompleteWarning
          headline={t("PAGE_CLIENTS.h1")}
          text={t("PAGE_CLIENTS.profileIncomplete")}
          icon={<ClientsIcon color="primary" fontSize="large" style={{ marginRight: "5px" }} />}
        />
      } */}

      <Fab color="primary" aria-label="add" className="add-invoice-fab" style={{ position: "fixed", bottom: "0", right: "0", margin: "20px"}}
          onClick={(e)=>{
            setOpenClientDialogAtom({
            ...openClientDialogAtom,
            open: true,
            });
        }}
        >
           <AddIcon />
        </Fab>
    </div>
  );
};

export default UserClients;

import React from "react";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import userAtomState from "../../_atoms/userAtom";
import checkoutAtomState from "../../_atoms/checkoutAtom";

// IMPORT components MATERIAL UI
import {
  Typography,
  Container,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@material-ui/core";

// IMPORT custom components
import CardProduct from "./CardProduct";

const Step0Product = (props) => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();

  // GLOBAL STATE (RECOIL)
  const [userAtom] = useRecoilState(userAtomState);
  const [checkoutAtom, setCheckoutAtom] = useRecoilState(checkoutAtomState);

  /* 
    Functions
  */
  const handleChangeShow = (e, target) => {
    setCheckoutAtom({
      ...checkoutAtom,
      [target]: e.target.value
    });
  };

  console.log(checkoutAtom)

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        {userAtom.data.subscription === "basic" &&
          <Grid container style={{ justifyContent: "center", flexDirection: "row", marginTop: "20px", marginBottom: "20px" }}>
            <FormControl component="fieldset" style={{ padding: "5px 5px 5px 15px" }} className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--1">
              <RadioGroup aria-label="show" name="show" value={checkoutAtom.show} onChange={(e) => handleChangeShow(e, "show")} style={{ display: "flex", flexDirection: "row" }}>
                <FormControlLabel
                  value="PREMIUM"
                  control={<Radio color="primary" />}
                  label={<Typography variant="subtitle2">{t("PRODUCTS.premium.name")}</Typography>}
                />

                <FormControlLabel
                  value="PRO"
                  control={<Radio color="primary" />}
                  label={
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Typography variant="subtitle2">{t("PRODUCTS.pro.name")}</Typography>
                    </div>
                  }
                />

                <FormControlLabel
                  value="PREMIUM_PRO"
                  control={<Radio color="primary" />}
                  label={
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Typography variant="subtitle2">{t("PAGE_CHECKOUT.product.compare")}</Typography>
                    </div>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        }

        {(checkoutAtom.show === "BASIC_PREMIUM" || checkoutAtom.show === "BASIC_PRO") &&
          <CardProduct handleNext={props.handleNext} product="basic" />
        }

        {(checkoutAtom.show === "PREMIUM" || checkoutAtom.show === "BOTH" || checkoutAtom.show === "PREMIUM_PRO" || checkoutAtom.show === "BASIC_PREMIUM") &&
          <CardProduct handleNext={props.handleNext} product="premium" />
        }

        {(checkoutAtom.show === "PRO" || checkoutAtom.show === "BOTH" || checkoutAtom.show === "PREMIUM_PRO" || checkoutAtom.show === "BASIC_PRO") &&
          <CardProduct handleNext={props.handleNext} product="pro" />
        }
      </Grid>
    </Container>
  );
};

export default Step0Product;

import React, {
  useState,
  useContext,
  useEffect,
} from "react";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import userAtomState from "../_atoms/userAtom";
import openCheckoutAtomState from "../_atoms/openCheckoutAtom";
import checkoutAtomState from "../_atoms/checkoutAtom";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";
import { useHttpClient } from "../shared/hooks/http-hook";
import { AuthContext } from './../shared/context/auth-context';

// IMPORT components MATERIAL UI
import {
  Typography,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  CircularProgress
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// IMPORT icons MATERIAL UI
import CloseIcon from "@material-ui/icons/Close";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

// IMPORT own components
import Logo from "./../_assets/logo_colored.svg";
import Step0Product from "./components/Step0Product";
import Step1Payment from "./components/Step1Payment";
import Step2Completion from "./components/Step2Completion";

const useStyles = makeStyles((theme) => {
  const appbarHeight = 64;
  return {
    root: {
      marginTop: `${appbarHeight}px !important`,
      minHeight: "100vh"
    },
    rootInner: {
      maxWidth: "1200px",
      margin: "0 auto",
    },
    stepContent: {
      display: "flex",
      justifyContent: "center"
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  };
});

const CheckoutDialog = () => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const { isLoading, sendRequest } = useHttpClient();
  const classes = useStyles();

  // GLOBAL STATE (RECOIL)
  const [userAtom] = useRecoilState(userAtomState);
  const [, setOpenCheckoutAtom] = useRecoilState(openCheckoutAtomState);
  const [checkoutAtom, setCheckoutAtom] = useRecoilState(checkoutAtomState);

  // LOCAL STATE
  const [activeStep, setActiveStep] = useState(0);

  const steps = getSteps();

  /* 
    Functions
  */
  useEffect(() => {
    const currentPriceData = async () => {
      try {
        const res = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL_API}/payment/prices`,
          "POST",
          JSON.stringify({
            countryCode: JSON.parse(userAtom.data.country).code
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );

        setCheckoutAtom({
          ...checkoutAtom,
          premiumMonthly: { productId: res.products[0][0], price: (res.products[0][1]) / 100, recurring: "monthly", name: t("PRODUCTS.premium.name") },
          premiumYearly: { productId: res.products[1][0], price: res.products[1][1] / 100, recurring: "yearly", name: t("PRODUCTS.premium.name") },
          proMonthly: { productId: res.products[2][0], price: res.products[2][1] / 100, recurring: "monthly", name: t("PRODUCTS.pro.name") },
          proYearly: { productId: res.products[3][0], price: res.products[3][1] / 100, recurring: "yearly", name: t("PRODUCTS.pro.name") },
          userTaxRate: res.taxRate,
        });
      } catch (err) { }
    };
    currentPriceData();
  }, []);

  function getSteps() {
    return [t("PAGE_CHECKOUT.steps.step1"), t("PAGE_CHECKOUT.steps.step2"), t("PAGE_CHECKOUT.steps.step3")];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Step0Product handleNext={handleNext} />
        )
      case 1:
        return (
          <Step1Payment handleNext={handleNext} />
        )
      case 2:
        return (
          <Step2Completion onClick={() => setOpenCheckoutAtom(false)} />
        );
      default:
        return 'Unknown step';
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <React.Fragment>
      <AppBar style={{ background: "#263238", paddingRight: "0" }}>
        <Toolbar style={{ justifyContent: "space-between" }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "30%" }}>
            <ShoppingCartIcon fontSize="small" color="primary" style={{ marginRight: "5px" }} />
            <Typography variant="body1">{activeStep !== 2 ? t("PAGE_CHECKOUT.newOrder") : t("PAGE_CHECKOUT.completedOrder")}</Typography>
          </div>

          <div style={{ width: "30%", display: "flex", justifyContent: "center" }}>
            <img
              src={Logo}
              alt={`${t("APP.name")} Logo`}
              style={{
                maxHeight: "40px",
              }}
            />
          </div>

          <div style={{ width: "30%", display: "flex", justifyContent: "end" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpenCheckoutAtom(false)}
              aria-label={`${t("BUTTONS.AL_close")}`}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <div className={`${classes.root} bg--1`}>
        <div className={classes.rootInner}>
          <Stepper activeStep={activeStep} style={{ background: "transparent" }}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps} alternativeLabel>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          {isLoading &&
            <div style={{ height: "100vh", marginTop: "-64px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              <CircularProgress />
            </div>
          }

          {!isLoading &&
            <div className={classes.stepContent} >
              <div style={{ width: "100%" }}>
                <div style={{ width: "100%" }} className={classes.instructions}>{getStepContent(activeStep)}</div>

                <div style={{ display: "flex", justifyContent: "center", marginBottom: "100px" }}>
                  {activeStep === 1 &&
                    <React.Fragment>
                      <Button onClick={handleBack} className={classes.button}>
                        {t("BUTTONS.back")}
                      </Button>
                    </React.Fragment>
                  }
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </React.Fragment>
  );
};

export default CheckoutDialog;

import React, { useRef, useEffect, useState, useContext } from "react";

import { StyleSheet } from '@react-pdf/renderer';

export const THEME_CLASSIC_STYLES = (design, cssZoom) => {
  return StyleSheet.create({
    // DOCUMENT
    document: {
      zoom: cssZoom,
    },

    // PAGE
    page: {
      padding: "50px 50px",
      boxShadow: "0px 3px 3px -2px #545ea51a, 0px 3px 4px 0px #545ea51a, 0px 1px 8px 0px #545ea51a",
      backgroundColor: design.colorBgBody,
      // zoom: cssZoom,
      fontFamily: "Inter"
    },

    // VIEW WRAPPER
    pageView: {
      zoom: "70%"
    },

    // SENDER 
    sender: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      flexGrow: 1,
      fontSize: "10px",
      logo: {
        text: {
          fontSize: "18px",
          fontFamily: "Inter"
        },
        onscreen: {
          maxHeight: "75px",
          width: "auto",
        },
        pdf: {
          display: "block",
          objectFit: "contain",
          objectPosition: "left top",
          height: "50px"
        }
      },
      viewImagePdf: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        flex: 5
      },
      view: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        flex: 5
      },
      text: {
        display: "flex",
        alignSelf: "flex-end",
        lineHeight: "1.2"
      }
    },

    // RECIPIENT 
    recipient: {
      display: "flex",
      flexDirection: "row",
      fontSize: "10px",
      view: {
        borderTop: "1px solid black",
        borderBottom: "1px solid black",
        margin: "20px 0",
        padding: "20px 0",
        display: "flex",
        flexDirection: "row",
        flexGrow: 1
      },
      column: {
        width: "50%",
      },
      text: {
        display: "flex",
        lineHeight: "1.2"
      }
    },
    // h1
    h1: {
      fontSize: "18px",

      view: {
        display: "flex",
        padding: "10px 0 30px 0",
      },
    },

    // ITEMS
    items: {
      fontSize: "8px",
      headlines: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        gap: "5px",
        justifyContent: "space-between",
        borderBottom: "1px solid black",
        paddingBottom: "10px",
      },
      view: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        gap: "5px",
        justifyContent: "space-between",
        borderBottom: "1px solid lightgrey",
        padding: "10px 0",
      },
      headlineAlignLeft: {
        display: "flex",
        lineHeight: "1.2",
        justifyContent: "flex-start",
        alignSelf: "flex-end",
        textAlign: "left",
        flex: 3,
        fontWeight: "bold"
      },
      headlineAlignRight: {
        display: "flex",
        lineHeight: "1.2",
        justifyContent: "flex-end",
        alignSelf: "flex-end",
        textAlign: "right",
        flex: 1.5,
        fontWeight: "bold"
      },
      textAlignLeft: {
        wordBreak: "break-word",
        display: "flex",
        lineHeight: "1.5",
        flex: 3
      },
      textAlignRight: {
        display: "flex",
        lineHeight: "1.5",
        justifyContent: "flex-end",
        alignSelf: "flex-start",
        textAlign: "right",
        flex: 1.5
      },
    },

    // SUMMARY
    summary: {
      fontSize: "8px",
      display: "flex",
      flexDirection: "column",
      padding: "20px 0 0 0",
      view: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        justifyContent: "flex-end",
        headline: {
          display: "flex",
          lineHeight: "1.5",
          justifyContent: "flex-end",
          alignSelf: "flex-end",
          textAlign: "right",
          paddingRight: "10px",
          flex: 6,
        },
        headlineBold: {
          display: "flex",
          lineHeight: "1.5",
          justifyContent: "flex-end",
          alignSelf: "flex-end",
          textAlign: "right",
          paddingRight: "10px",
          fontWeight: "bold",
          flex: 6,
        },
        text: {
          display: "flex",
          lineHeight: "1.5",
          justifyContent: "flex-end",
          alignSelf: "flex-end",
          textAlign: "right",
          flex: 1
        },
        textBold: {
          display: "flex",
          lineHeight: "1.5",
          justifyContent: "flex-end",
          alignSelf: "flex-end",
          textAlign: "right",
          fontWeight: "bold",
          flex: 1
        }
      },
    },

    // FOOTNOTES
    footnotes: {
      fontSize: "8px",
      display: "flex",
      flexDirection: "column",
      padding: "30px 0",
      view: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        gap: "5px",
        justifyContent: "space-between",
      },
    }
  })
}

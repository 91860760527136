import React, { useState, useEffect } from "react";

// KEEP!!!! even it is greyed out!!!!
import _ from "lodash";
import filter from "lodash";
import groupBy from "lodash";
import map from "lodash";
import sumBy from "lodash";
// KEEP !!!!

// IMPORT hooks & context
import {
  NavLink
} from "react-router-dom";
import { useTranslation } from "react-i18next";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import userAtomState from "../../_atoms/userAtom";
import selectedMenuItemAtomState from "../../_atoms/selectedMenuItemAtom";
import subMenuOpenAtomState from "../../_atoms/subMenuOpenAtom";
import invoicesAtomState from "../../_atoms/invoicesAtom";
import expensesAtomState from "../../_atoms/expensesAtom";
import openInvoiceDialogAtomState from "../../_atoms/openInvoiceDialogAtom";
import openExpenseDialogAtomState from "../../_atoms/openExpenseDialogAtom";
import settingsAtomState from "../../_atoms/settingsAtom";

import { format, startOfDay } from 'date-fns';


// IMPORT components
import LogoMonogram from './../../_assets/logo_monogram.svg';
import LogoMonogramWhite from './../../_assets/logo_monogram_white.svg';
import Promo from "./../../shared/components/UIElements/Promo";
import InvoicesExpensesChart from "../components/InvoicesExpensesChart";
import DateRange from "../../shared/components/DateRange/DateRange";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from 'recharts';

// IMPORT components MATERIAL UI
import {
  Typography,
  Button,
  Box,
  Icon,
  Container,
  Grid,
  IconButton,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  useMediaQuery
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';

// IMPORT icons MATERIAL UI
import NotificationsIcon from '@material-ui/icons/Notifications';
import DashboardIcon from "@material-ui/icons/Dashboard";
import TimelineIcon from '@material-ui/icons/Timeline';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ClientsIcon from "@material-ui/icons/RecentActors";
import AccountCircle from "@material-ui/icons/AccountCircle";
import LogoutIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from '@material-ui/icons/Menu';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import GavelIcon from '@material-ui/icons/Gavel';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';



import "./Cashboard.scss"


const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: {
    height: '0px',
    '@media (max-width: 959px)': {
      height: '62px',
    },
    '@media (max-width: 599px)': {
      height: '45px',
    },
    '@media (max-width: 455px)': {
      height: '55px',
    },
  },
  // gridContainer: {
  //   margin: '-5px', // Offset the padding of each grid item
  //   width: 'calc(100% + 10px)', // Adjust the width to account for the negative margins
  // },
  // gridItem: {
  //   padding: '5px', // Add padding of 5px (half of 10px) to each side of the item
  // },
}));

const Cashboard = () => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const classes = useStyles();


 // GLOBAL STATE (RECOIL)
 const [userAtom] = useRecoilState(userAtomState);
 const [selectedMenuItemAtom, setSelectedMenuItemAtom] = useRecoilState(selectedMenuItemAtomState);
 const [subMenuOpenAtom, setSubMenuOpenAtom] = useRecoilState(subMenuOpenAtomState);
 const [openInvoiceDialogAtom, setOpenInvoiceDialogAtom] = useRecoilState(openInvoiceDialogAtomState);
 const [openExpenseDialogAtom, setOpenExpenseDialogAtom] = useRecoilState(openExpenseDialogAtomState);
 const [settingsAtom, setSettingsAtom] = useRecoilState(settingsAtomState);


 const [invoicesAtom, setInvoicesAtom] = useRecoilState(invoicesAtomState);
 const [dataInvoices, setDataInvoices] = useState([]);
 const [expensesAtom, setExpensesAtom] = useRecoilState(expensesAtomState);
 const [dataExpenses, setDataExpenses] = useState([]);
 const [data, setData] = useState([]);
 const [newData, setNewData] = useState([]);

 const [revenue, setRevenue] = useState(0);
 const [costs, setCosts] = useState(0);
 const [profit, setProfit] = useState(0);
 const [vat, setVat] = useState(0);
 const [chartType, setChartType] = React.useState('area');
 const theme = useTheme();

 const isTabletDown = useMediaQuery(theme.breakpoints.down('md'));


 const [sums, setSums] = useState({
  netRevenue: 0,
  vatRevenue: 0,
  grossRevenue: 0,
  netCosts: 0,
  vatCosts: 0,
  grossCosts: 0,
  profit: 0,
});

useEffect(() => {
  invoicesAtom.forEach(function (item, i) {
    if (item.payState === "payed") {
      setData((items) => {
        const existingItemIndex = items.findIndex(x => x.id === item.id);
        if (existingItemIndex >= 0) {
          // Item exists, update it
          const updatedItems = [...items];
          updatedItems[existingItemIndex] = {
            ...updatedItems[existingItemIndex],
            type: "invoice",
            company: JSON.parse(item.clientData).company,
            payDate: JSON.stringify(new Date(item.payDate)),
            invoiceNr: JSON.parse(item.invoiceNr).map(i => i.value),
            category: "",
            netInvoice: item.discountedSubtotal,
            vatInvoice: item.total - item.discountedSubtotal,
            grossInvoice: item.total,
            charge: "0",
            netExpense: 0,
            vatExpense: 0,
            grossExpense: 0,
            id: item.id
          };
          return updatedItems;
        } else {
          // Item does not exist, add new item
          return [
            ...items,
            {
              type: "invoice",
              company: JSON.parse(item.clientData).company,
              payDate: JSON.stringify(new Date(item.payDate)),
              invoiceNr: JSON.parse(item.invoiceNr).map(i => i.value),
              category: "",
              netInvoice: item.discountedSubtotal,
              vatInvoice: item.total - item.discountedSubtotal,
              grossInvoice: item.total,
              charge: "0",
              netExpense: 0,
              vatExpense: 0,
              grossExpense: 0,
              id: item.id
            },
          ];
        }
      });
    }
  });
}, [invoicesAtom]);

useEffect(() => {
  expensesAtom.forEach(function (item, i) {
    if (item) {
      setData((items) => {
        const existingItemIndex = items.findIndex(x => x.id === item.id);
        if (existingItemIndex >= 0) {
          // If the item exists, update it
          const updatedItems = [...items];
          updatedItems[existingItemIndex] = {
            ...updatedItems[existingItemIndex],
            type: "expense",
            company: item.vendor_company,
            payDate: JSON.stringify(new Date(item.pay_date)),
            invoiceNr: item.invoice_number,
            category: item.invoice_type,
            netInvoice: 0,
            vatInvoice: 0,
            grossInvoice: 0,
            charge: item.charge,
            netExpense: item.charge === "100" ? item.net_value : item.net_value / 100 * parseFloat(item.charge),
            vatExpense: item.charge === "100" ? item.vat_amount : item.vat_amount / 100 * parseFloat(item.charge),
            grossExpense: item.charge === "100" ? item.gross_value : item.gross_value / 100 * parseFloat(item.charge),
            id: item.id
          };
          return updatedItems;
        } else {
          // If the item does not exist, add as a new item
          return [
            ...items,
            {
              type: "expense",
              company: item.vendor_company,
              payDate: JSON.stringify(new Date(item.pay_date)),
              invoiceNr: item.invoice_number,
              category: item.invoice_type,
              netInvoice: 0,
              vatInvoice: 0,
              grossInvoice: 0,
              charge: item.charge,
              netExpense: item.charge === "100" ? item.net_value : item.net_value / 100 * parseFloat(item.charge),
              vatExpense: item.charge === "100" ? item.vat_amount : item.vat_amount / 100 * parseFloat(item.charge),
              grossExpense: item.charge === "100" ? item.gross_value : item.gross_value / 100 * parseFloat(item.charge),
              id: item.id
            },
          ];
        }
      });
    }
  });
}, [expensesAtom]);


useEffect(() => {
  let netRevenue = _.sumBy(newData, item => (item.type === "invoice") && Number(item.netInvoice))
  let vatRevenue = _.sumBy(newData, item => (item.type === "invoice") && Number(item.vatInvoice))
  let grossRevenue = _.sumBy(newData, item => (item.type === "invoice") && Number(item.grossInvoice))
  let netCosts = _.sumBy(newData, item => (item.type === "expense") && Number(item.netExpense))
  let vatCosts = _.sumBy(newData, item => (item.type === "expense") && Number(item.vatExpense))
  let grossCosts = _.sumBy(newData, item => (item.type === "expense") && Number(item.grossExpense))

  setSums({
    ...sums,
    netRevenue: netRevenue === false ? 0 : netRevenue,
    vatRevenue: vatRevenue === false ? 0 : vatRevenue,
    grossRevenue: grossRevenue === false ? 0 : grossRevenue,
    netCosts: netCosts === false ? 0 : netCosts,
    vatCosts: vatCosts === false ? 0 : vatCosts,
    grossCosts: grossCosts === false ? 0 : grossCosts,
  })
}, [newData]);



useEffect(() => {
  let newData = data.sort((a, b) => {
    return new Date(JSON.parse(a.payDate)) - new Date(JSON.parse(b.payDate))
  });

  setNewData(data);
}, [data]);



useEffect(() => {
  const dateRangeFilteredEntries = data.filter(entry => {
    return new Date(JSON.parse(entry.payDate)) >= new Date(settingsAtom.dateRangeStartValue) && new Date(JSON.parse(entry.payDate)) <= new Date(settingsAtom.dateRangeEndValue); // Filter out entries not within the date range
  });
  setNewData(dateRangeFilteredEntries);
}, [data, settingsAtom]);

  function ResponsiveBox({ headline, text, background, colorHeadline, color, border }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.up('xs'));

    return (
      <Fade in={true}>
      <Box bgcolor="white" p={1} className="feature-box-inner" style={{ background: background, borderLeft: border, position: 'relative', padding: '10px', height: '100%' }}>
        <Grid
          container
          direction={'column'}
          alignItems={'center'}
          spacing={1}
          style={{ textAlign: "center", padding: "10px"}}
        >
          <Grid item>
            <Typography variant="body1" style={{ color: colorHeadline, fontFamily: "Plus Jakarta Sans", fontWeight: 600, fontSize: "12px" }}>{headline}</Typography>
            <Typography variant="h6" component="div" style={{ color: color }}>{text}</Typography>
          </Grid>
        </Grid>
      </Box>
      </Fade>
    );
  }


  return (
    <div>
      <div className={classes.toolbar} />
      <Fade in={true}>
        <Grid container className="subpage-header" maxWidth={false} item direction="row" alignItems="center" alignContent="center">
          <Grid item container alignItems="center" style={{ marginRight: "10px" }}>
            <Icon style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "35px", width: "35px", borderRadius: "4px", background: "linear-gradient(to right top, rgb(38, 108, 189), rgb(36, 115, 192), rgb(33, 150, 243), #00bca7, #00bf72)", marginRight: "15px" }}>
              <img src={LogoMonogramWhite} height={25} width={25} alt={`${t("APP.name")} Logo`} />
            </Icon>
            <Typography variant="body1" component="div" style={{ fontWeight: "500" }} className="headline">Cashboard</Typography>
          </Grid>

          <Grid item container justifyContent="space-between">
            <Grid item container alignItems="center" style={{ padding: "0px 2px 0px 0px", borderRadius: "6px", marginTop: "10px", width: "auto", boxShadow: "inset rgb(108 116 173 / 12%) 0px 0px 20px 0px" }}>
            <DateRange />

            </Grid>
          </Grid>
        </Grid>
      </Fade>


      <Container maxWidth={false} disableGutters className="bg--1" >



      <InvoicesExpensesChart />

        <Container maxWidth={false} >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            margin="25px 25px 25px 25px"
            className="h-gap--25"
          >
            <Grid container spacing={1} alignItems="stretch">
              <Grid item xs={12} md={12} lg={6} >
                  <Box className="feature-box-cashboard">
                    <ResponsiveBox
                      background={`${sums.netRevenue >= sums.netCosts ? "rgb(0, 191, 114)" : "rgb(255 79 79)"}`}
                      colorHeadline="white"
                      color="white"
                      headline={`${sums.netRevenue >= sums.netCosts ? "Gewinn" : "Verlust"}`}
                      text={`€ ${(sums.netRevenue - sums.netCosts).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                    />
                  </Box>
              </Grid>

              <Grid item xs={12} md={12} lg={6} >
                  <Box className="feature-box-cashboard">
                    <ResponsiveBox
                      background={`${(sums.vatRevenue - sums.vatCosts) > 0 ? "rgb(255 79 79)" : "rgb(0, 191, 114)"}`}
                      colorHeadline="white"
                      color="white"
                      border="0px solid transparent"
                      headline={`${(sums.vatRevenue - sums.vatCosts) > 0 ? "USt. Zahllast" : "VSt. Überschuss"}`}
                      text={`€ ${(sums.vatRevenue - sums.vatCosts).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                    />
                  </Box>
              </Grid>
            </Grid>


            <Grid container spacing={1} alignItems="stretch">
              <Grid item xs={12} md={12} lg={4} >
                  <Box className="feature-box-cashboard">
                    <ResponsiveBox
                      background="#ffffff"
                      colorHeadline="#5f6791"
                      color="#5f6791"
                      border="10px solid rgb(0, 191, 114)"
                      headline={`Einnahmen netto`}
                      text={`€ ${(sums.netRevenue).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                    />
                  </Box>
              </Grid>

              <Grid item xs={12} md={12} lg={4} >
                  <Box className="feature-box-cashboard">
                    <ResponsiveBox
                      background="#ffffff"
                      colorHeadline="#5f6791"
                      color="#5f6791"
                      border="10px solid rgb(0, 191, 114)"
                      headline={`USt.`}
                      text={`€ ${(sums.vatRevenue).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                    />
                  </Box>
              </Grid>

              <Grid item xs={12} md={12} lg={4} >
                  <Box className="feature-box-cashboard">
                    <ResponsiveBox
                      background="#ffffff"
                      colorHeadline="#5f6791"
                      color="#5f6791"
                      border="10px solid rgb(0, 191, 114)"
                      headline={`Einnahmen brutto`}
                      text={`€ ${(sums.grossRevenue).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                    />
                  </Box>
              </Grid>

              <Grid item xs={12} md={12} lg={4} >
                  <Box className="feature-box-cashboard">
                    <ResponsiveBox
                      background="#ffffff"
                      colorHeadline="#5f6791"
                      color="#5f6791"
                      border="10px solid rgb(255, 79, 79)"
                      headline={`Ausgaben netto`}
                      text={`€ ${(sums.netCosts).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                    />
                  </Box>
              </Grid>

              <Grid item xs={12} md={12} lg={4} >
                  <Box className="feature-box-cashboard">
                    <ResponsiveBox
                      background="#ffffff"
                      colorHeadline="#5f6791"
                      color="#5f6791"
                      border="10px solid rgb(255, 79, 79)"
                      headline={`VSt.`}
                      text={`€ ${sums.vatCosts.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                    />
                  </Box>
              </Grid>

              <Grid item xs={12} md={12} lg={4} >
                  <Box className="feature-box-cashboard">
                    <ResponsiveBox
                      background="#ffffff"
                      colorHeadline="#5f6791"
                      color="#5f6791"
                      border="10px solid rgb(255, 79, 79)"
                      headline={`Ausgaben brutto`}
                      text={`€ ${sums.grossCosts.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                    />
                  </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>



      </Container>
    </div>
  );
};

export default Cashboard;

import React from "react";

// IMPORT hooks & context
import {
  NavLink
} from "react-router-dom";
import { useTranslation } from "react-i18next";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import openInvoiceDialogAtomState from "../../_atoms/openInvoiceDialogAtom";
import openInvoiceRecurringDialogAtomState from "../../_atoms/openInvoiceRecurringDialogAtom";
import openInvoiceDesignerDialogAtomState from "../../_atoms/openInvoiceDesignerDialogAtom";
import userAtomState from "../../_atoms/userAtom";


// IMPORT components MATERIAL UI
import {
  Grid,
  IconButton,
  Typography,
  Button,
  ButtonGroup
} from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';


// IMPORT icons MATERIAL UI
import FilterListIcon from '@material-ui/icons/FilterList';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import SearchIcon from '@material-ui/icons/Search';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import BrushRoundedIcon from '@material-ui/icons/BrushRounded';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ListAltIcon from '@material-ui/icons/ListAlt';
import RepeatIcon from '@material-ui/icons/Repeat';

// IMPORT own components
import InvoicesCsv from "../components/InvoicesCsv";
import InvoicesFilter from "./InvoicesFilter";
import InvoicesSort from "./InvoicesSort";
import InvoicesDateRange from "./InvoicesDateRange";
import InvoicesSettings from "./InvoicesSettings";

export default function InvoicesMenuView({
  values,
  setValues,
  SET_VALUE,
  handleSearchClear,
  settings,
  handleChange,
  cancelSettingsHandler,
  updateSettingsHandler,
  setSettings
}) {
  // HOOKS & CONTEXT 
  const { t } = useTranslation();
  const theme = useTheme();


  // GLOBAL STATE (RECOIL)
  const [openInvoiceDialogAtom, setOpenInvoiceDialogAtom] = useRecoilState(openInvoiceDialogAtomState);
  const [openInvoiceRecurringDialogAtom, setOpenInvoiceRecurringDialogAtom] = useRecoilState(openInvoiceRecurringDialogAtomState);
  const [openInvoiceDesignerDialogAtom, setOpenInvoiceDesignerDialogAtom] = useRecoilState(openInvoiceDesignerDialogAtomState);
  const [userAtom, setUserAtom] = useRecoilState(userAtomState);

  return (
    <>
      <Grid
        item container 
        style={{ padding: "0"}}
      >
        <NavLink to={`/invoices`} className="invoice-tab">
          <Button
            disableRipple
            variant="contained"
            startIcon={<ListAltIcon>einmalig</ListAltIcon>}
            style={{
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "0",
              borderBottomRightRadius: "0",
              borderBottomLeftRadius: "0",
              borderRight: "0px solid transparent",
              textTransform: "none",
              fontSize: "12px",
            }}
          >
            <span class="title">Einzelrechnungen</span>
          </Button>

          <span className="invoice-add-button">
            <IconButton
              aria-label="delete"
              color="primary"
              disabled={window.location.pathname === "/invoices" ? false : true}
              style={{ padding: "5px", color: "white",border: "1px solid white" }}
              onClick={() => setOpenInvoiceDialogAtom({
                ...openInvoiceDialogAtom,
                open: true,
              })}
            >
              <AddCircleIcon className="add-invoice" />
            </IconButton>
          </span>
        </NavLink>

        {/* <NavLink to={`/invoicesrecurring`} className="invoice-tab">
          <Button
            disableRipple
            variant="contained"
            startIcon={<RepeatIcon>regelmäßig</RepeatIcon>}
            style={{
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "0",
              borderBottomRightRadius: "0",
              borderBottomLeftRadius: "0",
              borderRight: "0px solid transparent",
              textTransform: "none",
              fontSize: "12px",
            }}
          >
            <span class="title">Regelmäßige Verrechnungen</span>
          </Button>

          <span className="invoice-add-button">
            <IconButton
              aria-label="delete"
              color="primary"
              disabled={window.location.pathname === "/invoicesrecurring" ? false : true}
              style={{ padding: "5px", color: "white",border: "1px solid white" }}
              onClick={() => setOpenInvoiceRecurringDialogAtom({
                ...openInvoiceRecurringDialogAtom,
                open: true,
              })}
            >
              <AddCircleIcon className="add-invoice" />
            </IconButton>
          </span>
        </NavLink> */}
      </Grid>
    </>
  );
}

const COUNTRIES_unsorted = [
    { code: 'AD', label: 'Andorra', phone: '376' },
    {
        code: 'AE',
        label: 'Vereinigte Arabische Emirate',
        phone: '971',
    },
    { code: 'AF', label: 'Afghanistan', phone: '93' },
    {
        code: 'AG',
        label: 'Antigua und Barbuda',
        phone: '1-268',
    },
    { code: 'AI', label: 'Anguilla', phone: '1-264' },
    { code: 'AL', label: 'Albania', phone: '355' },
    { code: 'AM', label: 'Armenien', phone: '374' },
    { code: 'AO', label: 'Angola', phone: '244' },
    { code: 'AQ', label: 'Antarktis', phone: '672' },
    { code: 'AR', label: 'Argentina', phone: '54' },
    { code: 'AS', label: 'Amerikanisch-Samoa', phone: '1-684' },
    { code: 'AT', label: 'Austria', phone: '43' },
    {
        code: 'AU',
        label: 'Australien',
        phone: '61',
        vorgeschlagen: true,
    },
    { code: 'AW', label: 'Aruba', phone: '297' },
    { code: 'AX', label: 'Alland Inseln', phone: '358' },
    { code: 'AZ', label: 'Aserbaidschan', phone: '994' },
    {
        code: 'BA',
        label: 'Bosnien und Herzegowina',
        phone: '387',
    },
    { code: 'BB', label: 'Barbados', phone: '1-246' },
    { code: 'BD', label: 'Bangladesh', phone: '880' },
    { code: 'BE', label: 'Belgien', phone: '32' },
    { code: 'BF', label: 'Burkina Faso', phone: '226' },
    { code: 'BG', label: 'Bulgarien', phone: '359' },
    { code: 'BH', label: 'Bahrain', phone: '973' },
    { code: 'BI', label: 'Burundi', phone: '257' },




    { code: 'BJ', label: 'Benin', phone: '229' },
    { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
    { code: 'BM', label: 'Bermuda', phone: '1-441' },
    { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
    { code: 'BO', label: 'Bolivien', phone: '591' },
    { code: 'BR', label: 'Brazil', phone: '55' },
    { code: 'BS', label: 'Bahamas', phone: '1-242' },
    { code: 'BT', label: 'Bhutan', phone: '975' },
    { code: 'BV', label: 'Bouvetinsel', phone: '47' },
    { code: 'BW', label: 'Botswana', phone: '267' },
    { code: 'BY', label: 'Belarus', phone: '375' },
    { code: 'BZ', label: 'Belize', phone: '501' },
    {
        code: 'CA',
        label: 'Kanada',
        phone: '1',
        vorgeschlagen: true,
    },
    {
        code: 'CC',
        label: 'Cocos (Keeling) Inseln',
        phone: '61',
    },
    {
        code: 'CD',
        label: 'Kongo, Demokratische Republik',
        phone: '243',
    },
    {
        code: 'CF',
        label: 'Zentralafrikanische Republik',
        phone: '236',
    },
    {
        code: 'CG',
        label: 'Kongo, Republik',
        phone: '242',
    },
    { code: 'CH', label: 'Schweiz', phone: '41' },
    { code: 'CI', label: "Côte d'Ivoire", phone: '225' },
    { code: 'CK', label: 'Cookinseln', phone: '682' },
    { code: 'CL', label: 'Chile', phone: '56' },
    { code: 'CM', label: 'Kamerun', phone: '237' }, { code: 'CM', label: 'Kamerun', phone: '237' },


    { code: 'CN', label: 'China', phone: '86' },
    { code: 'CO', label: 'Kolumbien', phone: '57' },
    { code: 'CR', label: 'Costa Rica', phone: '506' },
    { code: 'CU', label: 'Cuba', phone: '53' },
    { code: 'CV', label: 'Kap Verde', phone: '238' },
    { code: 'CW', label: 'Curacao', phone: '599' },
    { code: 'CX', label: 'Christmas Island', phone: '61' },
    { code: 'CY', label: 'Zypern', phone: '357' },
    { code: 'CZ', label: 'Tschechische Republik', phone: '420' },
    {
        code: 'DE',
        label: 'Deutschland',
        phone: '49',
        vorgeschlagen: true,
    },
    { code: 'DJ', label: 'Djibouti', phone: '253' },
    { code: 'DK', label: 'Dänemark', phone: '45' },
    { code: 'DM', label: 'Dominica', phone: '1-767' },
    {
        code: 'DO',
        label: 'Dominikanische Republik',
        phone: '1-809',
    },
    { code: 'DZ', label: 'Algerien', phone: '213' },
    { code: 'EC', label: 'Ecuador', phone: '593' },
    { code: 'EE', label: 'Estland', phone: '372' },
    { code: 'EG', label: 'Ägypten', phone: '20' },
    { code: 'EH', label: 'Westsahara', phone: '212' },
    { code: 'ER', label: 'Eritrea', phone: '291' },
    { code: 'ES', label: 'Spanien', phone: '34' },
    { code: 'ET', label: 'Äthiopien', phone: '251' },
    { code: 'FI', label: 'Finnland', phone: '358' },
    { code: 'FJ', label: 'Fidschi', phone: '679' },

    {
        code: 'FK',
        label: 'Falklandinseln (Malwinen)',
        phone: '500',
    },
    {
        code: 'FM',
        label: 'Mikronesien, Föderierte Staaten von',
        phone: '691',
    },
    { code: 'FO', label: 'Färöer Inseln', phone: '298' },
    {
        code: 'FR',
        label: 'Frankreich',
        phone: '33',
        vorgeschlagen: true,
    },
    { code: 'GA', label: 'Gabun', phone: '241' },
    { code: 'GB', label: 'Vereinigtes Königreich', phone: '44' },
    { code: 'GD', label: 'Grenada', phone: '1-473' },
    { code: 'GE', label: 'Georgia', phone: '995' },
    { code: 'GF', label: 'Französisch-Guayana', phone: '594' },
    { code: 'GG', label: 'Guernsey', phone: '44' },
    { code: 'GH', label: 'Ghana', phone: '233' },
    { code: 'GI', label: 'Gibraltar', phone: '350' },
    { code: 'GL', label: 'Grönland', phone: '299' },
    { code: 'GM', label: 'Gambia', phone: '220' },
    { code: 'GN', label: 'Guinea', phone: '224' }, { code: 'GN', label: 'Guinea', phone: '224' },
    { code: 'GP', label: 'Guadeloupe', phone: '590' },
    { code: 'GQ', label: 'Äquatorialguinea', phone: '240' },
    { code: 'GR', label: 'Griechenland', phone: '30' },
    {
        code: 'GS',
        label: 'Südgeorgien und die Südlichen Sandwichinseln',
        phone: '500',
    },
    { code: 'GT', label: 'Guatemala', phone: '502' },
    { code: 'GU', label: 'Guam', phone: '1-671' },
    { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
    { code: 'GY', label: 'Guyana', phone: '592' },
    { code: 'HK', label: 'Hongkong', phone: '852' },


    {
        code: 'HM',
        label: 'Heard Island und McDonald Islands',
        phone: '672',
    },
    { code: 'HN', label: 'Honduras', phone: '504' },
    { code: 'HR', label: 'Croatia', phone: '385' },
    { code: 'HT', label: 'Haiti', phone: '509' },
    { code: 'HU', label: 'Ungarn', phone: '36' },
    { code: 'ID', label: 'Indonesia', phone: '62' },
    { code: 'IE', label: 'Irland', phone: '353' },
    { code: 'IL', label: 'Israel', phone: '972' },
    { code: 'IM', label: 'Isle of Man', phone: '44' },
    { code: 'IN', label: 'Indien', phone: '91' },
    {
        code: 'IO',
        label: 'Britisches Territorium im Indischen Ozean',
        phone: '246',
    },
    { code: 'IQ', label: 'Iraq', phone: '964' },
    {
        code: 'IR',
        label: 'Iran, Islamische Republik',
        phone: '98',
    },
    { code: 'IS', label: 'Island', phone: '354' },
    { code: 'IT', label: 'Italien', phone: '39' },
    { code: 'JE', label: 'Jersey', phone: '44' },
    { code: 'JM', label: 'Jamaika', phone: '1-876' },
    { code: 'JO', label: 'Jordan', phone: '962' },
    {
        code: 'JP',
        label: 'Japan',
        phone: '81',
        vorgeschlagen: true,
    },
    { code: 'KE', label: 'Kenia', phone: '254' },
    { code: 'KG', label: 'Kirgisistan', phone: '996' },
    { code: 'KH', label: 'Kambodscha', phone: '855' },
    { code: 'KI', label: 'Kiribati', phone: '686' },
    { code: 'KM', label: 'Komoren', phone: '269' },
    {


        code: 'KN',
        label: 'St. Kitts und Nevis',
        phone: '1-869',
    },

    {
        code: 'KP',
        label: "Korea, Demokratische Volksrepublik",
        phone: '850',
    },
    { code: 'KR', label: 'Korea, Republik', phone: '82' },
    { code: 'KW', label: 'Kuwait', phone: '965' },
    { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
    { code: 'KZ', label: 'Kazakhstan', phone: '7' },
    {
        code: 'LA',
        label: 'Demokratische Volksrepublik Laos',
        phone: '856',
    },
    { code: 'LB', label: 'Libanon', phone: '961' },
    { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
    { code: 'LI', label: 'Liechtenstein', phone: '423' },
    { code: 'LK', label: 'Sri Lanka', phone: '94' },
    { code: 'LR', label: 'Liberia', phone: '231' },
    { code: 'LS', label: 'Lesotho', phone: '266' },
    { code: 'LT', label: 'Litauen', phone: '370' },
    { code: 'LU', label: 'Luxembourg', phone: '352' }, { code: 'LU', label: 'Luxembourg', phone: '352' },
    { code: 'LV', label: 'Latvia', phone: '371' },
    { code: 'LY', label: 'Libya', phone: '218' }, { code: 'LY', label: 'Libya', phone: '218' },
    { code: 'MA', label: 'Marokko', phone: '212' }, { code: 'MA', label: 'Marokko', phone: '212' },
    { code: 'MC', label: 'Monaco', phone: '377' },
    {
        code: 'MD',
        label: 'Moldawien, Republik',
        phone: '373',
    },
    { code: 'ME', label: 'Montenegro', phone: '382' },
    {
        code: 'MF',
        label: 'Saint Martin (französischer Teil)',
        phone: '590',
    },
    { code: 'MG', label: 'Madagaskar', phone: '261' },
    { code: 'MH', label: 'Marshallinseln', phone: '692' },
    {
        code: 'MK',
        label: 'Mazedonien, die ehemalige jugoslawische Republik',
        phone: '389',
    },
    { code: 'ML', label: 'Mali', phone: '223' },
    { code: 'MM', label: 'Myanmar', phone: '95' },
    { code: 'MN', label: 'Mongolei', phone: '976' },
    { code: 'MO', label: 'Macao', phone: '853' },
    {
        code: 'MP',
        label: 'Nördliche Marianen',
        phone: '1-670',
    },
    { code: 'MQ', label: 'Martinique', phone: '596' },
    { code: 'MR', label: 'Mauretanien', phone: '222' },
    { code: 'MS', label: 'Montserrat', phone: '1-664' },
    { code: 'MT', label: 'Malta', phone: '356' },
    { code: 'MU', label: 'Mauritius', phone: '230' },
    { code: 'MV', label: 'Malediven', phone: '960' },
    { code: 'MW', label: 'Malawi', phone: '265' },
    { code: 'MX', label: 'Mexiko', phone: '52' },
    { code: 'MY', label: 'Malaysia', phone: '60' },
    { code: 'MZ', label: 'Mosambik', phone: '258' },
    { code: 'NA', label: 'Namibia', phone: '264' },
    { code: 'NC', label: 'Neukaledonien', phone: '687' },
    { code: 'NE', label: 'Niger', phone: '227' },
    { code: 'NF', label: 'Norfolkinsel', phone: '672' },
    { code: 'NG', label: 'Nigeria', phone: '234' },
    { code: 'NI', label: 'Nicaragua', phone: '505' }, { code: 'NI', label: 'Nicaragua', phone: '505' },
    { code: 'NL', label: 'Niederlande', phone: '31' },
    { code: 'NO', label: 'Norwegen', phone: '47' }, { code: 'NO', label: 'Norwegen', phone: '47' },
    { code: 'NP', label: 'Nepal', phone: '977' },
    { code: 'NR', label: 'Nauru', phone: '674' },
    { code: 'NU', label: 'Niue', phone: '683' }, { code: 'NU', label: 'Niue', phone: '683' },
    { code: 'NZ', label: 'New Zealand', phone: '64' },
    { code: 'OM', label: 'Oman', phone: '968' },
    { code: 'PA', label: 'Panama', phone: '507' },
    { code: 'PE', label: 'Peru', phone: '51' },
    { code: 'PF', label: 'Französisch-Polynesien', phone: '689' },
    { code: 'PG', label: 'Papua-Neuguinea', phone: '675' },
    { code: 'PH', label: 'Philippinen', phone: '63' },
    { code: 'PK', label: 'Pakistan', phone: '92' }, { code: 'PK', label: 'Pakistan', phone: '92' },
    { code: 'PL', label: 'Poland', phone: '48' },
    {
        code: 'PM',
        label: 'Saint Pierre und Miquelon',
        phone: '508',
    },
    { code: 'PN', label: 'Pitcairn', phone: '870' },
    { code: 'PR', label: 'Puerto Rico', phone: '1' },
    {
        code: 'PS',
        label: 'Palästina, Staat',
        phone: '970',
    },
    { code: 'PT', label: 'Portugal', phone: '351' },
    { code: 'PW', label: 'Palau', phone: '680' },
    { code: 'PY', label: 'Paraguay', phone: '595' },
    { code: 'QA', label: 'Katar', phone: '974' },
    { code: 'RE', label: 'Reunion', phone: '262' },
    { code: 'RO', label: 'Romania', phone: '40' }, { code: 'RO', label: 'Romania', phone: '40' },
    { code: 'RS', label: 'Serbia', phone: '381' }, { code: 'RS', label: 'Serbia', phone: '381' },
    { code: 'RU', label: 'Russische Föderation', phone: '7' },
    { code: 'RW', label: 'Ruanda', phone: '250' }, { code: 'RW', label: 'Ruanda', phone: '250' },
    { code: 'SA', label: 'Saudi-Arabien', phone: '966' },
    { code: 'SB', label: 'Salomonen', phone: '677' },
    { code: 'SC', label: 'Seychellen', phone: '248' }, { code: 'SC', label: 'Seychellen', phone: '248' },
    { code: 'SD', label: 'Sudan', phone: '249' }, { code: 'SD', label: 'Sudan', phone: '249' },
    { code: 'SE', label: 'Schweden', phone: '46' },
    { code: 'SG', label: 'Singapur', phone: '65' },
    { code: 'SH', label: 'Saint Helena', phone: '290' },
    { code: 'SI', label: 'Slowenien', phone: '386' },
    {
        code: 'SJ',
        label: 'Svalbard und Jan Mayen',
        phone: '47',
    },
    { code: 'SK', label: 'Slowakei', phone: '421' },
    { code: 'SL', label: 'Sierra Leone', phone: '232' },
    { code: 'SM', label: 'San Marino', phone: '378' },
    { code: 'SN', label: 'Senegal', phone: '221' },
    { code: 'SO', label: 'Somalia', phone: '252' },
    { code: 'SR', label: 'Surinam', phone: '597' },
    { code: 'SS', label: 'Südsudan', phone: '211' },
    {
        code: 'ST',
        label: 'Sao Tome und Principe',
        phone: '239',
    },
    { code: 'SV', label: 'El Salvador', phone: '503' },
    {
        code: 'SX',
        label: 'Sint Maarten (Niederländischer Teil)',
        phone: '1-721',
    },
    {
        code: 'SY',
        label: 'Syrische Arabische Republik',
        phone: '963',
    },
    { code: 'SZ', label: 'Swasiland', phone: '268' },
    {
        code: 'TC',
        label: 'Turks- und Caicosinseln',
        phone: '1-649',
    },
    { code: 'TD', label: 'Tschad', phone: '235' },
    {
        code: 'TF',
        label: 'Französische Süd-Territorien',
        phone: '262',
    },
    { code: 'TG', label: 'Togo', phone: '228' },
    { code: 'TH', label: 'Thailand', phone: '66' },
    { code: 'TJ', label: 'Tadschikistan', phone: '992' },
    { code: 'TK', label: 'Tokelau', phone: '690' },
    { code: 'TL', label: 'Timor-Leste', phone: '670' },
    { code: 'TM', label: 'Turkmenistan', phone: '993' },
    { code: 'TN', label: 'Tunesien', phone: '216' }, { code: 'TN', label: 'Tunesien', phone: '216' },
    { code: 'TO', label: 'Tonga', phone: '676' },
    { code: 'TR', label: 'Türkei', phone: '90' },
    {
        code: 'TT',
        label: 'Trinidad und Tobago',
        phone: '1-868',
    },
    { code: 'TV', label: 'Tuvalu', phone: '688' },
    {
        code: 'TW',
        label: 'Taiwan, Republik China',
        phone: '886',
    },
    {
        code: 'TZ',
        label: 'Vereinigte Republik Tansania',
        phone: '255',
    },
    { code: 'UA', label: 'Ukraine', phone: '380' },
    { code: 'UG', label: 'Uganda', phone: '256' },
    {
        code: 'US',
        label: 'Vereinigte Staaten',
        phone: '1',
        vorgeschlagen: true,
    },
    { code: 'UY', label: 'Uruguay', phone: '598' },
    { code: 'UZ', label: 'Usbekistan', phone: '998' },
    {
        code: 'VA',
        label: 'Heiliger Stuhl (Staat Vatikanstadt)',
        phone: '379',
    },
    {
        code: 'VC',
        label: 'St. Vincent und die Grenadinen',
        phone: '1-784',
    },
    { code: 'VE', label: 'Venezuela', phone: '58' },
    {
        code: 'VG',
        label: 'Britische Jungferninseln',
        phone: '1-284',
    },
    {
        code: 'VI',
        label: 'US-Jungferninseln',
        phone: '1-340',
    },
    { code: 'VN', label: 'Vietnam', phone: '84' },
    { code: 'VU', label: 'Vanuatu', phone: '678' },
    { code: 'WF', label: 'Wallis und Futuna', phone: '681' },
    { code: 'WS', label: 'Samoa', phone: '685' },
    { code: 'XK', label: 'Kosovo', phone: '383' }, { code: 'XK', label: 'Kosovo', phone: '383' },
    { code: 'YE', label: 'Jemen', phone: '967' },
    { code: 'YT', label: 'Mayotte', phone: '262' },
    { code: 'ZA', label: 'South Africa', phone: '27' },
    { code: 'ZM', label: 'Sambia', phone: '260' },
    { code: 'ZW', label: 'Simbabwe', phone: '263' },
];

const COUNTRIES_preferred = [
    { code: 'AT', label: 'Österreich', phone: '43' },
    { code: 'DE', label: 'Deutschland', phone: '49' },
    { code: 'CH', label: 'Schweiz', phone: '41' },
    { code: 'IT', label: 'Italien', phone: '39' },
]

let COUNTRIES_sorted = COUNTRIES_unsorted.slice(0);
COUNTRIES_sorted.sort(function(a,b) {
    var x = a.label.toLowerCase();
    var y = b.label.toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
});

// export const COUNTRIES = COUNTRIES_preferred.concat(COUNTRIES_sorted);

const COUNTRIES_de = [
    { code: 'AT', label: 'Österreich', phone: '43' },
    { code: 'DE', label: 'Deutschland', phone: '49' },
]

export const COUNTRIES = COUNTRIES_de

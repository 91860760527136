export const CLIENT = {
  "company": "Foosa AG",
  "email": "mb@autbite.com",
  "phone": "",
  "vatPercent": "20",
  "vat": "",
  "street": "Teststraße",
  "streetNr": "1",
  "zip": "1122",
  "city": "Nürnberg",
  "country": "{\"code\":\"DE\",\"label\":\"Deutschland\",\"phone\":\"49\"}",
  "precedingTitle": "",
  "firstName": "adsf",
  "lastName": "asdf",
  "followingTitle": "",
  "clientNumber": "50003b",
  "creator": "6543af78b6d571c2d0a72e3b",
}

export const INVOICE = {
  "items": "[{\"name\":\"SEO Basicsss\",\"quantity\":1,\"price\":\"100\",\"vatPercent\":\"20\",\"total\":100,\"discount\":\"none\",\"discountPercent\":0,\"discountAbsolute\":0,\"discountValue\":0,\"discountedTotal\":100,\"chosen\":false,\"selected\":false,\"unit\":\"h\"},{\"name\":\"fffff\",\"quantity\":1,\"price\":\"500\",\"vatPercent\":\"10\",\"total\":500,\"discount\":\"none\",\"discountPercent\":0,\"discountAbsolute\":0,\"discountValue\":0,\"discountedTotal\":500,\"chosen\":false,\"selected\":false},{\"name\":\"aaa\",\"quantity\":1,\"price\":\"200\",\"vatPercent\":\"20\",\"total\":200,\"discount\":\"none\",\"discountPercent\":0,\"discountAbsolute\":0,\"discountValue\":0,\"discountedTotal\":200,\"chosen\":false,\"selected\":false},{\"name\":\"aaabbbbb\",\"quantity\":1,\"price\":\"150\",\"vatPercent\":\"20\",\"total\":150,\"discount\":\"none\",\"discountPercent\":0,\"discountAbsolute\":0,\"discountValue\":0,\"discountedTotal\":150,\"chosen\":false,\"selected\":false}]",
  "invoiceNr": "1",
  "invoiceDate": "Tue Nov 21 2023 15:47:00 GMT+0100 (Central European Standard Time)",
  "sendDate": "Thu Nov 23 2023 15:47:00 GMT+0100 (Central European Standard Time)",
  "dueDate": "Thu Nov 23 2023 15:47:00 GMT+0100 (Central European Standard Time)",
  "subtotal": "950",
  "vatValues": "{\"10\":[\"10\",45],\"20\":[\"20\",81]}",
  "total": "981",
  "discount": "percent",
  "discountAbsolute": "0",
  "discountPercent": "10",
  "discountValue": "95",
  "discountedSubtotal": "855",
  "discountedTotal": "0",
  "recurring": "no",
}

import { useEffect } from 'react';

const useInvoiceNumberValidation = (values, setValues, selectedClient, openInvoiceDialogAtom, invoicesAtom) => {
  useEffect(() => {
    const streamlinedInvoiceNr = (invoiceNumber) => {
      let nr = "";
      for (const [key, value] of Object.entries(invoiceNumber)) {
        nr += value;
      }
      return nr;
    };

    const currentInvoiceNumber = streamlinedInvoiceNr(values.invoiceNumber.state.map((item) => item.value));

    if (selectedClient && openInvoiceDialogAtom.invoiceId === "") {
      let allInvoiceNumbers = [];

      const getAllInvoiceNumbers = () => {
        for (const key in invoicesAtom) {
          if (invoicesAtom.hasOwnProperty(key)) {
            const obj = invoicesAtom[key];
            for (const prop in obj) {
              if (obj.hasOwnProperty(prop) && prop === "invoiceNr") {
                allInvoiceNumbers.push(streamlinedInvoiceNr(JSON.parse(obj[prop]).map((item) => item.value)));
              }
            }
          }
        }
      };
      getAllInvoiceNumbers();

      if (allInvoiceNumbers.indexOf(currentInvoiceNumber) !== -1) {
        setValues({
          ...values,
          invoiceNumber: {
            ...values.invoiceNumber,
            error: true,
            errorMsg: "Diese Rechnungsnummer existiert bereits",
          }
        });
      } else {
        setValues({
          ...values,
          invoiceNumber: {
            ...values.invoiceNumber,
            error: false,
            errorMsg: "",
          }
        });
      }
    }
  }, [selectedClient]);
};

export default useInvoiceNumberValidation;

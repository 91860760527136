
import React, { useState, useContext, useEffect, useRef, useImperativeHandle } from "react";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";
import { AuthContext } from './../../shared/context/auth-context';
import { useHttpClient } from "./../../shared/hooks/http-hook";
import { loadStripe } from "@stripe/stripe-js";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import userAtomState from "../../_atoms/userAtom";
import checkoutAtomState from "../../_atoms/checkoutAtom";

// IMPORT components
import { NumericFormat } from "react-number-format";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

// IMPORT components MATERIAL UI
import {
  TextField,
  Typography,
  Container,
  Grid,
  Backdrop,
  CircularProgress,
  Button,
  makeStyles,
  useTheme,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

// IMPORT icons MATERIAL UI
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';

// IMPORT custom components
import Logo from "./../../_assets/logo.svg";

// IMPORT custom functions
import { VAT } from './../../_functions/VAT';
import { SET_VALUES } from './../../_functions/SET_VALUES';
import { SET_USER_ATOM } from './../../_functions/SET_USER_ATOM';

const StripeInput = ({ component: Component, inputRef, loading, ...other }) => {
  const ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSize: '16px',
        color: !loading ? 'rgba(0, 0, 0, 1)' : "rgba(0, 0, 0, 0.4)",
        letterSpacing: '0.025em',
        '::placeholder': {
          color: 'rgba(0, 0, 0, 0.5)',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

  const elementRef = useRef();
  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus
  }));

  return (
    <Component
      options={ELEMENT_OPTIONS}
      onReady={element => (elementRef.current = element)} {...other} />
  );
}

const useStyles = makeStyles((theme) => {
  const appbarHeight = 64;
  return {
    root: {
      marginTop: `${appbarHeight}px !important`,
      minHeight: "100vh"
    },
    rootInner: {
      maxWidth: "1200px",
      margin: "0 auto",
    },
    stepContent: {
      display: "flex",
      justifyContent: "center"
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    link: {
      margin: theme.spacing(1, 1.5),
    },
    heroContent: {
      padding: theme.spacing(8, 0, 6),
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh"
    },
    cardHeader: {
      backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    },
    cardPricing: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(1),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  };
});

const cardsLogo = [
  "amex",
  // "cirrus",
  "diners",
  // "dankort",
  "discover",
  // "jcb",
  "maestro",
  "mastercard",
  "visa",
  // "visaelectron",
];

const Step1Payment = (props) => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const stripe = useStripe();
  const elements = useElements();
  const { sendRequest, clearError } = useHttpClient();

  // GLOBAL STATE (RECOIL)
  const [userAtom, setUserAtom] = useRecoilState(userAtomState);
  const [checkoutAtom] = useRecoilState(checkoutAtomState);

  // LOCAL STATE
  const [errorMessage, setErrorMessage] = useState("");
  const [, setSuccessfulTransaction] = useState(false);
  const [showVat, setShowVat] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    cardNumber: {
      state: "",
      error: false,
      errorMsg: ""
    },
    cardExpiry: {
      state: "",
      error: false,
      errorMsg: ""
    },
    cardCvc: {
      state: "",
      error: false,
      errorMsg: ""
    },
    vat: {
      state: userAtom.data.vat,
      digits: 0, // e.g. Austria: 8, Germany: 9 -> see https://www.finanz.at/steuern/umsatzsteuer/uid-nummer/
      code: "", // e.g. ATU, DE, etc ...
      error: false,
      errorMsg: t("FIELDS.vat.error"),
    },
   loading: false
  });

  const [promotionCode, setPromotionCode] = useState('');
  const [promotionCodeValid, setPromotionCodeValid] = useState(false);
  const [promotionCodes, setPromotionCodes] = useState([]);
  const [promotionCodePercent, setPromotionCodePercent] = useState(0);
  const [promotionCodeError, setPromotionCodeError] = useState(false);

  const [vatNumber, setVatNumber] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [validVat, setValidVat] = useState(false);
  const [validVatError, setValidVatError] = useState(false);
  const [useVat, setUseVat] = useState(false);
  const [useReverseCharge, setUseReverseCharge] = useState(false);

  useEffect(() => {
    // Check if the entered promotion code is valid
    const promotionCodeObject = promotionCodes.find(code => code.code === promotionCode);
    const isValid = Boolean(promotionCodeObject);
    setPromotionCodeValid(isValid);

    if (isValid) {
      setPromotionCodePercent(promotionCodeObject.coupon.percent_off);
    } else {
      setPromotionCodePercent(0);
    }
  }, [promotionCode, promotionCodes]);

  /* 
    Functions
  */

  useEffect(() => {
    const fetchPromotionCodes = async () => {
      try {
        const res = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL_API}/payment/promotion-codes`,
          "POST",
          JSON.stringify({
            countryCode: JSON.parse(userAtom.data.country).code
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        setPromotionCodes(res);
        console.log(res)
      } catch (error) {
        console.error('Error fetching promotion codes:', error);
      }
    };

    fetchPromotionCodes();
  }, []);

  useEffect(() => {
    setValues({
      ...values,
      vat: {
        ...values.vat,
        code: VAT(JSON.parse(userAtom.data.country).code).vatCode,
        digits: VAT(JSON.parse(userAtom.data.country).code).vatDigits,
      }
    });
  }, []);

  const handleChange = (e) => {
    clearError();
    SET_VALUES(e, values, setValues);
  };

  const validate = (field) => {
    const errorConditions = {
      vat: !validVat
    };

    // Get the names of the object properties of errorConditions.
    const names = Object.getOwnPropertyNames(errorConditions);

    // Validate each entry during typing.
    if (field !== "all" && errorConditions[field]) {
      setValues({ ...values, [field]: { ...values[field], error: true } });
    } else {
      setValues({ ...values, [field]: { ...values[field], error: false } });
    }

    // Validate all entries at once when form is submitted.
    if (field === "all") {
      names.forEach((name) => {
        if (errorConditions[name]) {
          setValues((prevValues) => {
            return { ...prevValues, [name]: { ...values[name], error: true } };
          });
        } else {
          setValues((prevValues) => {
            return { ...prevValues, [name]: { ...values[name], error: false } };
          });
        }
      });
    }
  };

  const downgradeToBasic = async (event) => {
    event.preventDefault();
    try {
      const res = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL_API}/users/create_subscription`,
        "POST",
        JSON.stringify({
          id: auth.userId,
          productId: "basic"
        }),
        {
          "Content-Type": "application/json",
          Authorization: 'Bearer ' + auth.token,
        },
      )
      .then((res) => {
        setSuccessfulTransaction(true);
        SET_USER_ATOM({subscription: res.subscriptionType}, userAtom, setUserAtom)
        props.handleNext();
      })
    } catch (err) {}
  };



  const createSubscription = async (event) => {
    event.preventDefault();

    if(promotionCode !== "" && !promotionCodeValid) {
      setPromotionCodeError(true);
    } else {
      setPromotionCodeError(false);
    }

    

    if (
      promotionCode === "" || promotionCodeValid // Errors in Stripe From are catched by Stripe API itself
      ) {
      setLoading(true);
      setErrorMessage("");

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      const card = elements.getElement(CardNumberElement);
      const expiry = elements.getElement(CardExpiryElement);
      const cvc = elements.getElement(CardCvcElement);

      if (card == null) {
        return;
      }

      // Disable Fields when transaction is in progress
      card.update({ disabled: true });
      expiry.update({ disabled: true });
      cvc.update({ disabled: true });

      try {
        const paymentMethod = await stripe.createPaymentMethod({
          type: 'card',
          card,
        });

        const res = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL_API}/users/create_subscription`,
          "POST",
          JSON.stringify({
            id: auth.userId,
            user_token: auth.token,
            paymentMethod: paymentMethod.paymentMethod.id,
            vatId: useVat ? values.vat.state : "", // can be changed for checkout during checkout
            vatCode: values.vat.code,
            countryCode: JSON.parse(userAtom.data.country).code,
            productId: checkoutAtom.selectedProduct.productId,
            promotionCode: promotionCode,
            reverseCharge: useReverseCharge,
          }),
          {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + auth.token,
          },
        );
        const confirm = await stripe.confirmCardPayment(res.clientSecret);

        // Enable Fields again
        card.update({ disabled: false });
        expiry.update({ disabled: false });
        cvc.update({ disabled: false });
 
        if (confirm.error) {
          switch (confirm.error.code) {
            case "payment_intent_authentication_failure":
              setErrorMessage(t("ERRORS.payment_authentification"))
              break;
            default:
              setErrorMessage(t("ERRORS.payment_default"))
          }
        }

        if (!confirm.error) {
          let resPayment;
          try {
            resPayment = await sendRequest(
              `${process.env.REACT_APP_BACKEND_URL_API}/users/confirm_subscription`,
              "POST",
              JSON.stringify({
                id: auth.userId,
                user_token: auth.token,
                customerId: res.customerId,
                subscriptionId: res.subscriptionId,
                subscriptionType: res.subscriptionType,
              }),
              {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + auth.token,
              },
            );            
          } catch (err) {}
          // setCheckoutAtom({
          //   ...checkoutAtom,
          //   selectedInterval: {
          //     premium: "monthly",
          //     pro: "monthly",
          //   },
          //   selectedProduct: {},
          // })
          setSuccessfulTransaction(true);
          SET_USER_ATOM({subscription: res.subscriptionType}, userAtom, setUserAtom)
          props.handleNext();
        }
      } catch (err) {
        // Enable Fields again
        card.update({ disabled: false });
        expiry.update({ disabled: false });
        cvc.update({ disabled: false });

        // console.error(err);
        setErrorMessage(t("ERRORS.payment_default"))
      }
      setLoading(false);
    }
  };

  const validateStripeFields = (e, field) => {

    // Workaround for cardExpiry field initial error state when submit button is clicked
    if (field === "cardExpiry" && e.empty === true) {
      setValues({ ...values, [field]: { ...values[field], error: true, errorMsg: e.error.message } });
    }

    const validateConditions = {
      cardNumber: field === "cardNumber" && e.error !== undefined ? true : false,
      cardExpiry: field === "cardExpiry" && e.error !== undefined ? true : false,
      cardCvc: field === "cardCvc" && e.error !== undefined ? true : false,
    };

    // Get the names of the object properties of validateConditions
    const names = Object.getOwnPropertyNames(validateConditions);

    // SINGLE field validation
    if (validateConditions[field]) {
      // ERROR true
      setValues({ ...values, [field]: { ...values[field], error: true, errorMsg: e.error.message } });
    } else {
      // ERROR false
      setValues({ ...values, [field]: { ...values[field], error: false, errorMsg: "" } });
    }
  };


  // const [vatNumber, setVatNumber] = useState('');
  // const [countryCode, setCountryCode] = useState('');
  // const [result, setResult] = useState(null);
  // const [error, setError] = useState(null);


  // const validateVAT = async () => {

  //   const res = await sendRequest(
  //     `${process.env.REACT_APP_BACKEND_URL_API}/validate/validate-vat`,
  //     "POST",
  //     JSON.stringify({
  //       countryCode, vatNumber
  //     }),
  //     {
  //       "Content-Type": "application/json",
  //       Authorization: 'Bearer ' + auth.token,
  //     },
  //   );

  //   console.log(res)    

  // };

 



  const validateVAT = async (countryCode, vatNumber) => {

    setError(null); // Reset error state
      try {
        const res = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL_API}/validate/validate-vat`,
          "POST",
          JSON.stringify({ countryCode, vatNumber }),
          {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + auth.token,
          }
        );

        const data = await res;
        setValidVat(data.valid);
        if(data.valid) {
          setValidVatError(false)
        } else {
          setValidVatError(true)
        }

        // const addressLines = data.address.split('\n');
        // const streetLine = addressLines[0];
        // const cityLine = addressLines[1];

        // const [street, streetNumber] = streetLine.split(/(\d+)/).filter(Boolean);
        // const [rawZipCode, ...cityParts] = cityLine.split(' ');
        // const zipCode = rawZipCode.replace(/\D/g, '');
        // const city = cityParts.join(' ');



        // setResult({
        //   ...data,
        //   street,
        //   streetNumber,
        //   zipCode,
        //   city,
        //   validVat
        // });
      } catch (err) {
        setError(err.message || 'Something went wrong, please try again.');
      }
  };


  useEffect(() => {
    console.log(typeof(values.vat.state))
    if(values.vat.code !== "") {
      setValidVatError(false)
      if(values.vat.code === "ATU" && values.vat.state.length === 8) {
        validateVAT("AT", `U${values.vat.state}`)
      } else if (values.vat.code === "DE" && values.vat.state.length === 9) {
        validateVAT(values.vat.code, values.vat.state)
      } else {
        setValidVat(false);
      }
    } else {
      setValidVat(false);
    }
    
  }, [values.vat.code, values.vat.state]);

  return (
    <Container
      maxWidth="md"
      style={{ marginTop: "20px", marginBottom: "50px", display: "flex", justifyContent: "center" }}
    >  

{/* <div>
      <h1>VAT Validation</h1>
      <div>
        <label>
          Country Code:
          <input type="text" value={countryCode} onChange={(e) => setCountryCode(e.target.value.toUpperCase())} />
        </label>
      </div>
      <div>
        <label>
          VAT Number:
          <input type="text" value={vatNumber} onChange={(e) => setVatNumber(e.target.value)} />
        </label>
      </div>
      <button onClick={validateVAT}>Validate VAT</button>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {result && (
        <div>
          <h2>Result</h2>
          <p>Valid: {result.valid ? 'Yes' : 'No'}</p>
          {result.valid && (
            <>
              <p>Name: {result.name}</p>
              <p>Street: {result.street}</p>
              <p>Street Number: {result.streetNumber}</p>
              <p>ZIP Code: {result.zipCode}</p>
              <p>City: {result.city}</p>
            </>
          )}
        </div>
      )}
    </div> */}
      <Grid container lg={12} spacing={6} justifyContent="center" className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--1" style={{margin: "auto"}}>
        <React.Fragment>
          <Grid container item lg={6} spacing={2} justifyContent="center">
            <div style={{width: "100%"}}>
              <Grid container item lg={12} spacing={2} justifyContent="center">
                <Grid container item lg={12}>
                    <Typography variant="h6" component="h2">
                    {t("PAGE_CHECKOUT.yourOrder")}
                    </Typography>
                </Grid>

                <Grid item xs={12} spacing={2}>
                  <Typography variant="div" component="div" style={{ marginBottom: "10px", fontFamily: "Plus Jakarta Sans, sans-serif" }}>
                    <div className={checkoutAtom.selectedProduct.name === "PREMIUM" ? "bg--4" : checkoutAtom.selectedProduct.name === "PRO" ? "bg--5": "bg--basic"} style={{ color: "#ffffff", padding: "10px 15px", borderRadius: "4px", display: "flex", justifyContent: "center", alignItems: "center"}}>                         <img
                        src={Logo}
                        alt={`${t("APP.name")} Logo`}
                        style={{
                          maxHeight: "21.5px",
                          paddingTop: "2px",
                          marginRight: "5px"
                        }}
                      />
                      <span style={{ marginRight: "5px", fontWeight: 900, }}>{checkoutAtom.selectedProduct.name === "PREMIUM" ? checkoutAtom.selectedProduct.name : checkoutAtom.selectedProduct.name === "PRO" ? checkoutAtom.selectedProduct.name : "BASIC"}</span>
                      <span style={{ fontWeight: 500 }}>{checkoutAtom.selectedProduct.recurring === "monthly" ? t("PROMO.monthly") : checkoutAtom.selectedProduct.recurring === "monthly" ? t("PROMO.yearly") : ""}</span>
                      <span style={{ fontWeight: 500 }}>{checkoutAtom.selectedProduct.recurring === "yearly" ? t("PROMO.yearly") : checkoutAtom.selectedProduct.recurring === "yearly" ? t("PROMO.yearly") : ""}</span>
                    </div>
                  </Typography>

                  <Grid container item direction="column" spacing={1} style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <Grid item style={{ borderBottom: "1px solid lightgrey" }}>
                      <Grid container item direction="row" justifyContent="space-between">
                        <Typography variant="div" component="div">
                          {t("PAGE_CHECKOUT.payment.priceExclVat")}
                        </Typography>

                        <NumericFormat
                          prefix="€ "
                          value={((checkoutAtom.selectedProduct.name === "PREMIUM" || checkoutAtom.selectedProduct.name === "PRO") ? checkoutAtom.selectedProduct.price : 0)*(promotionCodePercent !== 0 ? (100 - promotionCodePercent)/100: 1)}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          decimalScale={2}
                          valueIsNumericString
                          fixedDecimalScale
                          displayType={"text"}
                        />
                      </Grid>

                      <Grid container item direction="row" justifyContent="space-between">
                        <Typography variant="div" component="div">
                          {useReverseCharge ? 0 : checkoutAtom.userTaxRate}{t("PAGE_CHECKOUT.payment.vat")}
                        </Typography>

                        <NumericFormat
                          prefix="€ "
                          value={((checkoutAtom.selectedProduct.name === "PREMIUM" || checkoutAtom.selectedProduct.name === "PRO") ? useReverseCharge ? 0 : (checkoutAtom.selectedProduct.price * checkoutAtom.userTaxRate / 100).toFixed(2) : 0)*(promotionCodePercent !== 0 ? (100 - promotionCodePercent)/100: 1)}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          decimalScale={2}
                          valueIsNumericString
                          fixedDecimalScale
                          displayType={"text"}
                        />
                      </Grid>
                    </Grid>

                    <Grid container item direction="row" justifyContent="space-between">
                      <Typography variant="body1" component="div">
                        <strong>{t("PAGE_CHECKOUT.payment.price")} <span style={{fontSize: "75%", fontWeight: "normal"}}>{t("PAGE_CHECKOUT.payment.priceInclVat")}</span></strong>
                      </Typography>

                      <Typography variant="body1" component="div">
                        <NumericFormat
                          prefix="€ "
                          value={
                            !useReverseCharge ? ((checkoutAtom.selectedProduct.name === "PREMIUM" || checkoutAtom.selectedProduct.name === "PRO") ? (checkoutAtom.selectedProduct.price + checkoutAtom.selectedProduct.price * checkoutAtom.userTaxRate / 100).toFixed(2) : 0)*(promotionCodePercent !== 0 ? (100 - promotionCodePercent)/100: 1)
                            : ((checkoutAtom.selectedProduct.name === "PREMIUM" || checkoutAtom.selectedProduct.name === "PRO") ? checkoutAtom.selectedProduct.price : 0)*(promotionCodePercent !== 0 ? (100 - promotionCodePercent)/100: 1)
                          }
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          decimalScale={2}
                          valueIsNumericString
                          fixedDecimalScale
                          displayType={"text"}
                          style={{ fontWeight: "bold" }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                  
                </Grid>
                {checkoutAtom.selectedProduct === "basic" &&
                <Grid item lg={12}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={(e) => downgradeToBasic(e)}
                    fullWidth
                    size="large"
                    style={{ padding: "15px" }}
                    endIcon={<ArrowForwardIcon />}
                  >
                    JETZT AUF BASIC DOWNGRADEN
                  </Button>
                </Grid>
                }

                {checkoutAtom.selectedProduct !== "basic" &&
                <Grid item xs={6} sm={12}>
                <TextField
                  disabled={loading}
                  autoComplete='off'
                  label="Rabatt Code"
                  id="promotionCode"
                  name="promotionCode"
                  variant="outlined"
                  fullWidth
                  value={promotionCode}
                  onChange={(e) => {setPromotionCode(e.target.value); setPromotionCodeError(false)}}
                  error={promotionCodeError}
                  helperText={promotionCodeError ? "Dieser Code ist nicht gültig" : ""}
                  InputProps={{
                    style: {
                      fontWeight: promotionCodeValid ? "bold" : "initial",
                      color: promotionCodeValid ? "green" : "initial",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        {promotionCode && (
                          promotionCodeValid === true ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              {promotionCodePercent !== 0 && (
                                <Typography variant="body2" style={{ color: 'green', marginLeft: 4, marginRight: 4, fontWeight: "bold" }}>
                                  {promotionCodePercent}% Rabatt
                                </Typography>
                              )}
                              <CheckCircleIcon style={{ color: 'green' }} />
                            </div>
                          ) : (
                            promotionCodeValid === false ? (
                              <CheckCircleIcon style={{ color: 'rgb(237, 237, 237)' }} />
                            ) : null
                          )
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                </Grid>
                }

                {(checkoutAtom.selectedProduct !== "basic") &&
                  <>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox color="primary" checked={useVat} onChange={()=>setUseVat(!useVat)} name="showTextField" />}
                      label={<span style={{ fontSize: '12px' }}>UID-Nr. auf Rechnungen anzeigen</span>} 
                    />
                  </Grid>
                  {useVat &&
                  <Grid item xs={12} sm={12}>
                    <TextField
                      disabled={loading}
                      defaultValue={values.vat.state}
                      id="vat"
                      name="vat"
                      label={`UID-Nr. (${values.vat.digits} ${t("PAGE_CHECKOUT.payment.vatId2")})`}
                      variant="outlined"
                      type="text"
                      onChange={(e)=>{
                        handleChange(e);
                      }}
                      error={values.vat.error || validVatError}
                      helperText={values.vat.error ? values.vat.errorMsg : validVatError ? "Diese UID-Nr. existiert nicht." : ""}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                        style: {width: "100%"}
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start" style={{ backgroundColor: "#ededed", borderRadius: "5px", padding: "10px", height: "100%" }}>{values.vat.code}</InputAdornment>,
                        endAdornment: (
                          <InputAdornment position="end">
                            {
                              (validVat) ? (
                                  <CheckCircleIcon style={{ color: 'green' }} />
                              ) : (
                                (!validVat && values.vat.state.length > 0) ? (
                                  <CheckCircleIcon style={{ color: 'rgb(237, 237, 237)' }} />
                                ) : null
                              )
                            }
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  }
                  {(validVat && values.vat.code !== "ATU") &&
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox color="primary" checked={useReverseCharge} onChange={()=>setUseReverseCharge(!useReverseCharge)} name="showTextField" />}
                      label={<span style={{ fontSize: '12px' }}>USt.-freie Rechnung (Reverse Charge Prinzip)</span>} 
                    />
                  </Grid>
                  }
                  </>
                }

                {/* {(checkoutAtom.selectedProduct !== "basic" && values.vatCode !== "AT") && 
                  <Typography>
                    asdf

                  </Typography>
                } */}
              </Grid>
            </div>
          </Grid>
          
          {checkoutAtom.selectedProduct !== "basic" &&
          <Grid container item lg={6} spacing={2} justifyContent="center">
            <form onSubmit={createSubscription} disabled={loading} style={{width: "100%"}}>
              <Grid container item lg={12} spacing={2} justifyContent="center">
                <Grid container item lg={12}>
                  <Typography variant="h6" component="h2">
                  {t("PAGE_CHECKOUT.payCard")}
                  </Typography>

                </Grid>

                <Grid
                  container
                  item
                  lg={12}
                  style={{ marginBottom: "10px" }}
                >
                  {cardsLogo.map(e => <img key={e} src={`../cards/${e}.png`} alt={e} width="32px" align="bottom" style={{ marginRight: "5px" }} />)}
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    disabled={loading}
                    autoComplete='off'
                    label={t("PAGE_CHECKOUT.payment.cardLabel")}
                    id="cardNumber"
                    name="cardNumber"
                    variant="outlined"
                    required
                    fullWidth
                    defaultValue={values.cardNumber.state}
                    onChange={(e) => validateStripeFields(e, e.elementType)}
                    error={values.cardNumber.error}
                    helperText={
                      values.cardNumber.error
                        ? values.cardNumber.errorMsg
                        : ""
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CreditCardIcon color={!loading ? "primary" : ""} />
                        </InputAdornment>
                      ),
                      inputComponent: StripeInput,
                      inputProps: {
                        component: CardNumberElement,
                        loading: loading
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item xs={6} sm={12}>
                  <TextField
                    disabled={loading}
                    autoComplete='off'
                    label={t("PAGE_CHECKOUT.payment.validLabel")}
                    id="expiry"
                    name="expiry"
                    variant="outlined"
                    required
                    fullWidth
                    defaultValue={values.cardExpiry.state}
                    onChange={(e) => validateStripeFields(e, e.elementType)}
                    error={values.cardExpiry.error}
                    helperText={
                      values.cardExpiry.error
                        ? values.cardExpiry.errorMsg
                        : ""
                    }
                    InputProps={{
                      inputComponent: StripeInput,
                      inputProps: {
                        component: CardExpiryElement,
                        loading: loading
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item xs={6} sm={12}>
                  <TextField
                    disabled={loading}
                    autoComplete='off'
                    label={t("PAGE_CHECKOUT.payment.cvcLabel")}
                    id="cvc"
                    name="cvc"
                    variant="outlined"
                    required
                    fullWidth
                    defaultValue={values.cardCvc.state}
                    onChange={(e) => validateStripeFields(e, e.elementType)}
                    error={values.cardCvc.error}
                    helperText={
                      values.cardCvc.error
                        ? values.cardCvc.errorMsg
                        : ""
                    }
                    InputProps={{
                      inputComponent: StripeInput,
                      inputProps: {
                        component: CardCvcElement,
                        loading: loading
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
              <br />

              <Grid container item xs={12} spacing={2}>
                <Grid item lg={12}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={() => validate("all")}
                    fullWidth
                    size="large"
                    style={{ padding: "15px" }}
                    endIcon={<ArrowForwardIcon />}
                  >
                    {t("BUTTONS.buy")}
                  </Button>
                </Grid>

                {errorMessage !== "" &&
                  <Grid item lg={12}>
                    < Alert style={{ width: "100%" }} severity="error">{errorMessage}</Alert>
                  </Grid>
                }
              </Grid>
            </form>
          </Grid>
          }
        </React.Fragment>

        <Backdrop className={classes.backdrop} open={loading}>
          <Container>
            <Grid container justifyContent="center" alignItems="center">
              <Grid container item lg={12} justifyContent="center" alignItems="center">
                <CircularProgress color="inherit" />
              </Grid>

              <Grid container item lg={12} justifyContent="center" alignItems="center">
                <Typography variant="h6" compontent="div" align="center">{t("PAGE_CHECKOUT.payment.processing")}<br />{t("PAGE_CHECKOUT.payment.pleaseWait")}</Typography>
              </Grid>
            </Grid>
          </Container>
        </Backdrop>
      </Grid>
    </Container>
  );
};

export default Step1Payment;

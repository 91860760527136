export const de_DRAWER = {
    promoPremium: {
        part1: "Wechsle auf",
        part2: "Premium!"
    },
    promoPro: {
        part1: "Wechsle auf",
        part2: "Pro!"
    },
    dashboard: "Dashboard",
    clients: "Kunden",
    estimates: "Kostenvoranschläge / Angebote",
    invoices: "Rechnungen",
    expenses: "Ausgaben",
    settings: "Einstellungen",
    my: "Mein DagoWert",
    profile: "Unternehmensprofil",
    account: "Konto",
    logout: "Logout",
    createClient: "KUNDEN ANLEGEN",
    createInvoice: "RECHNUNG ERSTELLEN"
}

import React from 'react';
import { Grid, Typography, Tooltip, IconButton, Menu, MenuItem, Collapse, FormHelperText } from '@material-ui/core';
import { AddCircle as AddCircleIcon, ExpandMore, ExpandLess, CompareArrows as CompareArrowsIcon, MoreVert as MoreVertIcon, Today as TodayIcon, TextFields as TextFieldsIcon } from '@material-ui/icons';
import {
  RETURN_YEAR,
  RETURN_MONTH,
  RETURN_DAY,
} from '../../../_functions';

const ClientNumber = ({ values, isLoading, changeContinuousNumber, handleClick, anchorEl, openClientNrMenuDots, handleClose, addElementOpen, setAddElementOpen, setValues, setOpenClientNumberEdit }) => (
  <>
    <Grid item container xs={12} md={12} style={{ border: "2px solid rgb(240, 243, 255)", margin: "5px", minHeight: "56px", marginTop: "4px", borderRadius: "4px", paddingBottom: "5px" }}>
      <Grid item xs={9} md={11}>
        <Grid item style={{ marginLeft: "8px", marginBottom: "5px", wordBreak: "break-all" }}>
          <Typography>
            <span className="invoiceNumberHeadline" style={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.54)" }}>Kundennummer: </span>
            <span style={{ fontSize: "12px" }}>
              {`
                ${(values.clientNumber.state).map((item, i) => (
                  item.value
                )).join("")}
              `}
            </span>
          </Typography>
        </Grid>

        <Grid container style={{ marginBottom: "5px", width: "fit-content", marginLeft: "8px", borderRadius: "4px" }}>
          {(values.clientNumber.state).map((item, i) => (
            <Grid key={item.id} item className="invoiceNumberItem--v2">
              {item.type === "continuousNumber" &&
                <Tooltip title="Fortlaufende Nummer" aria-label="Continuous Number">
                  <Grid container item direction="row" justifyContent="space-between" style={{ background: "rgba(33, 150, 243, 0.1)" }}>
                    <Grid item style={{ textAlign: "center", width: "100%" }}>
                      <Typography>
                        <input
                          disabled={isLoading}
                          className="badge-input--invoicenr"
                          defaultValue={item.value}
                          placeholder={`Nr. ...`}
                          onChange={(e) => {
                            e.target.value.length !== 0 && e.target.setAttribute('size', e.target.value.length);
                            changeContinuousNumber(e, values, setValues);
                          }}
                          style={{ opacity: isLoading ? 0.5 : "inherit", width: "100%", fontSize: "16px", textAlign: "center", paddingTop: "4px", paddingBottom: "2px" }}
                          size={JSON.stringify(item.value).length}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Tooltip>
              }

              {item.type === "text" &&
                <Tooltip title="Fixer Text" aria-label="Text">
                  <Grid container item direction="row" justifyContent="space-between" style={{ background: "rgb(242 239 255)" }}>
                    <Grid item style={{ width: "100%", textAlign: "center" }}>
                      <Typography style={{ textAlign: "center" }}>
                        <input
                          disabled={isLoading}
                          className="badge-input--invoicenr"
                          defaultValue={item.value}
                          placeholder={`Text ...`}
                          // onChange={(e) => {
                          //   e.target.value.length !== 0 && e.target.setAttribute('size', e.target.value.length);
                          //   setValues(prevValues => ({
                          //     ...prevValues,
                          //     clientNumber: {
                          //       ...prevValues.clientNumber,
                          //       state: prevValues.clientNumber.state.map((oldItem, oldIndex) =>
                          //         oldIndex === i ? { ...oldItem, value: e.target.value } : oldItem
                          //       )
                          //     }
                          //   }));
                          // }}
                          onChange={(e) => {
                            // Extract the value and length before the synthetic event is nullified
                            const newValue = e.target.value;
                            const newLength = e.target.value.length;
                          
                            // Set the attribute size based on the new length
                            newLength !== 0 && e.target.setAttribute('size', newLength);
                          
                            // Update the values state with the new value
                            setValues(prevValues => ({
                              ...prevValues,
                              clientNumber: {
                                ...prevValues.clientNumber,
                                state: prevValues.clientNumber.state.map((oldItem, oldIndex) =>
                                  oldIndex === i ? { ...oldItem, value: newValue } : oldItem
                                )
                              }
                            }));
                          }}
                          
                          style={{ opacity: isLoading ? 0.5 : "inherit", width: "100%", fontSize: "16px", textAlign: "center", paddingTop: "4px", paddingBottom: "2px" }}
                          size={JSON.stringify(item.value).length}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Tooltip>
              }
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid item xs={3} md={1} style={{ display: "flex", alignItems: "center" }}>
        <Grid item container style={{ justifyContent: "center", paddingLeft: "10px", borderLeft: "2px solid rgba(210, 215, 243, 0.2)", alignContent: "center", marginLeft: "15px", marginRight: "10px" }}>
          <IconButton disabled={isLoading} onClick={handleClick} aria-label="delete" size="small" className="icon--invoicenr-dots">
            <MoreVertIcon />
          </IconButton>
        </Grid>
      </Grid>
      {values.clientNumber.error &&
        <FormHelperText error={true} style={{ marginLeft: "10px" }} id="continuous-number-error">{values.clientNumber.errorMsg}</FormHelperText>
      }
    </Grid>

    <Menu
      id="invoiceNr-menuDots"
      anchorEl={anchorEl}
      open={openClientNrMenuDots}
      onClose={handleClose}
      PaperProps={{
        elevation: 1,
      }}
    >
      <Typography style={{ marginLeft: "15px", fontWeight: "bold", fontSize: "15px", marginRight: "5px" }}>{(values.type === "invoice" || values.type === "reminder" || values.type === "reminderRequest") ? "Rechnungsnummer" : values.type === "estimate" ? "Kostenvoranschlag Nr." : "Angebot Nr."}</Typography>
      <MenuItem
        style={{ fontSize: "13px", marginRight: "5px" }}
        onClick={() => setAddElementOpen(!addElementOpen)}
      >
        <AddCircleIcon fontSize="small" style={{ marginRight: "5px" }} />
        Element hinzufügen
        {!addElementOpen ?
          <ExpandMore fontSize="small" style={{ marginLeft: "5px" }} />
          : <ExpandLess fontSize="small" style={{ marginLeft: "5px" }} />
        }
      </MenuItem>

      <Collapse in={addElementOpen} timeout="auto" unmountOnExit>
        <div style={{ marginLeft: "25px" }}>
          <MenuItem
            style={{ fontSize: "13px", marginRight: "5px" }}
            onClick={
              () => {
                setValues({
                  ...values,
                  clientNumber: {
                    ...values.clientNumber,
                    state: [
                      ...values.clientNumber.state,
                      { id: (values.clientNumber.state).reduce((acc, item) => Math.max(acc, item.id), 0) + 1, type: 'text', value: '' }
                    ]
                  }
                });
                handleClose();
                setAddElementOpen(false);
              }
            }
          >
            <TextFieldsIcon fontSize="small" style={{ marginRight: "5px" }} />
            Text
          </MenuItem>
        </div>
      </Collapse>

      <MenuItem
        disabled={!((values.clientNumber.state).length > 1)}
        style={{ fontSize: "13px", marginRight: "5px" }}
        onClick={
          () => {
            setOpenClientNumberEdit(true);
            handleClose();
          }
        }
      >
        <CompareArrowsIcon fontSize="small" style={{ marginRight: "5px" }} />Elemente anordnen / entfernen
      </MenuItem>
    </Menu>
  </>
);

export default ClientNumber;

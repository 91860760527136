import React, { useState,   useContext,
} from "react";
// IMPORT hooks & context
import { useTranslation } from "react-i18next";
import { countryToFlag } from "../../shared/functions/countryToFlag";
import { COUNTRIES } from "../../shared/util/countries_de";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from '../../shared/context/auth-context';
import { useAuth } from "../../shared/hooks/auth-hook";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import invoicesAtomState from "../../_atoms/invoicesAtom";

// IMPORT components MATERIAL UI
import {
  Typography,
  Box,
  Container,
  Grid,
  Tooltip,
  Chip,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  Button
} from "@material-ui/core";

// IMPORT icons MATERIAL UI
import ClientsIcon from "@material-ui/icons/RecentActors";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FilterListIcon from '@material-ui/icons/FilterList';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import EditIcon from '@material-ui/icons/Edit';
import ScheduleIcon from '@material-ui/icons/Schedule';
import MailIcon from '@material-ui/icons/Mail';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

// IMPORT functions
import { DB_DELETE_INVOICE } from './../../_functions/DB_INVOICES';

// IMPORT components
import { useSnackbar } from 'notistack';

// IMPORT own components
import InvoicesCsv from "../components/InvoicesCsv";
import InvoicesFilter from "./InvoicesFilter";
import InvoicesSort from "./InvoicesSort";
import InvoicesDateRange from "./InvoicesDateRange";
import InvoicesSettings from "./InvoicesSettings";
import ConfirmModal from "../../shared/components/UIElements/Modal";
import InvoiceLivePreview from "./InvoiceLivePreview";

const ITEM_HEIGHT = 48;

export default function InvoicesMenuDots({
  invoice,
  setOpenInvoiceDialogAtom,
  openInvoiceDialogAtom
}) {
  // HOOKS & CONTEXT 
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { enqueueSnackbar } = useSnackbar();

  // GLOBAL STATE (RECOIL)
  const [invoicesAtom, setInvoicesAtom] = useRecoilState(invoicesAtomState);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true);
  };

  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  };

  const confirmDeleteHandler = (invoice) => {
    DB_DELETE_INVOICE(
      invoice,
      auth,
      sendRequest,
      enqueueSnackbar,
      t,
      invoicesAtom,
      setInvoicesAtom,
      openInvoiceDialogAtom,
      setOpenInvoiceDialogAtom,
      setShowConfirmModal
    );
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls={invoice.id}
        aria-haspopup="true"
        onClick={handleClick}
        style= {{ marginRight: "5px" }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={invoice.id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 1,
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >

          <MenuItem
            style={{ fontSize: "13px" }}
            onClick={
              () => {
                setOpenInvoiceDialogAtom({
                  ...openInvoiceDialogAtom,
                  open: true,
                  invoiceId: invoice.id
                });
                handleClose()
              }
            }>
            <EditIcon fontSize="small" style={{ marginRight: "5px" }} />Bearbeiten
          </MenuItem>

          <MenuItem
            style={{ fontSize: "13px" }}
            onClick={
              () => {
                setOpenInvoiceDialogAtom({
                  ...openInvoiceDialogAtom,
                  open: true,
                  invoiceId: invoice.id,
                  duplicate: true
                });
                handleClose()
              }
            }
            >
            <FileCopyIcon fontSize="small" style={{ marginRight: "5px" }} />Duplizieren
          </MenuItem>

          <MenuItem 
            style={{ fontSize: "13px" }}
            onClick={
              () => {
                showDeleteWarningHandler(invoice.id);
                handleClose()
              }
            }
            >
            <DeleteForeverIcon fontSize="small" style={{ marginRight: "5px" }} />Löschen
          </MenuItem>
         
          <InvoiceLivePreview
            openPdfPreview={false}
            sendMode={false}
            client={JSON.parse(invoice.clientData)}
            items={JSON.parse(invoice.items)}
            clientNr={JSON.parse(invoice.clientData).clientNumber}
            invoiceNr={JSON.parse(invoice.invoiceNr).map((item, i) => (item.value))}
            // invoiceNr={invoice.invoiceNr.map((item, i) => (item.value))}
            invoiceDate={new Date(invoice.invoiceDate)}
            invoiceDueDate={new Date(invoice.dueDate)}
            invoiceSubtotal={invoice.subtotal}
            invoiceDiscount={invoice.discount}
            invoiceDiscountAbsolute={invoice.discountAbsolute}
            invoiceDiscountPercent={invoice.discountPercent}
            invoiceDiscountValue={invoice.discountValue}
            invoiceDiscountedSubtotal={invoice.discountedSubtotal}
            invoiceVatValues={JSON.parse(invoice.vatValues)}
            invoiceTotal={invoice.total}
            text={invoice.text}
            loading={false}
            directDownload={true}
            handleClose={handleClose}
          />
      
      </Menu>

      <ConfirmModal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header={t("BUTTONS.invoiceDelete")}
        footerClass="invoice-item__modal-actions"
        style={{ zIndex: "9999999" }}
        footer={
          <React.Fragment>
            <Button
              variant="outlined"
              style={{ marginRight: "10px" }}
              onClick={cancelDeleteHandler}
            >
              {t("BUTTONS.cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => confirmDeleteHandler(invoice.id)}
            >
              {t("BUTTONS.delete")}
            </Button>
          </React.Fragment>
        }
      >
        <p>
          {t("PAGE_INVOICES.invoiceDelete")}
        </p>
      </ConfirmModal>
    </div>
  );
}

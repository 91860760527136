import React from "react";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import openInvoiceDialogAtomState from "../../_atoms/openInvoiceDialogAtom";
import openInvoiceDesignerDialogAtomState from "../../_atoms/openInvoiceDesignerDialogAtom";
import userAtomState from "../../_atoms/userAtom";


// IMPORT components MATERIAL UI
import {
  Grid,
  IconButton,
  Typography,
  Button
} from "@material-ui/core";

// IMPORT icons MATERIAL UI
import FilterListIcon from '@material-ui/icons/FilterList';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import SearchIcon from '@material-ui/icons/Search';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import BrushRoundedIcon from '@material-ui/icons/BrushRounded';
import DateRangeIcon from '@material-ui/icons/DateRange';

// IMPORT own components
import InvoicesCsv from "../components/InvoicesCsv";
import InvoicesFilter from "./InvoicesFilter";
import InvoicesSort from "./InvoicesSort";
import InvoicesDateRange from "./InvoicesDateRange";
import InvoicesSettings from "./InvoicesSettings";

export default function InvoicesMenu({
  values,
  setValues,
  SET_VALUE,
  handleSearchClear,
  settings,
  handleChange,
  cancelSettingsHandler,
  updateSettingsHandler,
  setSettings
}) {
  // HOOKS & CONTEXT 
  const { t } = useTranslation();

  // GLOBAL STATE (RECOIL)
  const [openInvoiceDialogAtom, setOpenInvoiceDialogAtom] = useRecoilState(openInvoiceDialogAtomState);
  const [openInvoiceDesignerDialogAtom, setOpenInvoiceDesignerDialogAtom] = useRecoilState(openInvoiceDesignerDialogAtomState);
  const [userAtom, setUserAtom] = useRecoilState(userAtomState);

  return (
    <>
      <Grid
        item
        className="h-bg--white h-borderRadius--10 box-shadow--2 h-padding--5-5 tool-menu"
        style={{ marginBottom: "10px" }}
      >
        {/* <IconButton
          color="primary"
          aria-label="add invoice"
          onClick={() => setOpenInvoiceDialogAtom({
            ...openInvoiceDialogAtom,
            open: true,
          })}
        >
          <AddCircleIcon fontSize="medium" />
        </IconButton> */}

        <IconButton aria-label="search" style={{ color: "inherit" }}
          onClick={() => {
            SET_VALUE({ target: { id: "submenuOpen", value: values.submenuOpen !== "search" ? "search" : false } }, setValues);
            handleSearchClear();
          }}
        >
          <SearchIcon fontSize="small" color={values.submenuOpen === "search" ? "primary" : "inherit"} />
        </IconButton>

        {/* <IconButton aria-label="filter" style={{ color: "inherit" }}
          onClick={() => {
            SET_VALUE({ target: { id: "submenuOpen", value: values.submenuOpen !== "filter" ? "filter" : false } }, setValues);
            handleSearchClear();
          }}
        >
          <FilterListIcon fontSize="medium" color={values.submenuOpen === "filter" ? "primary" : "inherit"} />
        </IconButton> */}

        <InvoicesFilter
          settings={settings}
          handleChange={handleChange}
          cancelSettingsHandler={cancelSettingsHandler}
          updateSettingsHandler={updateSettingsHandler}
          setValues={setValues}
          SET_VALUE={SET_VALUE}
          setSettings={setSettings}
          values={values}
        />

        <InvoicesSort
          settings={settings}
          handleChange={handleChange}
          cancelSettingsHandler={cancelSettingsHandler}
          updateSettingsHandler={updateSettingsHandler}
          setValues={setValues}
          SET_VALUE={SET_VALUE}
          setSettings={setSettings}
          values={values}
        />

        <InvoicesDateRange
          settings={settings}
          handleChange={handleChange}
          cancelSettingsHandler={cancelSettingsHandler}
          updateSettingsHandler={updateSettingsHandler}
          setValues={setValues}
          SET_VALUE={SET_VALUE}
          setSettings={setSettings}
          values={values}
        />

        <InvoicesSettings
          settings={settings}
          handleChange={handleChange}
          cancelSettingsHandler={cancelSettingsHandler}
          updateSettingsHandler={updateSettingsHandler}
          setValues={setValues}
          SET_VALUE={SET_VALUE}
          setSettings={setSettings}
          values={values}
        />

        <InvoicesCsv data={values.invoicesFiltered} />

        {/* <Button
        variant="contained"
        color="secondary"
        size="small"
        startIcon={<BrushRoundedIcon />}
        style={{marginLeft: "15px"}}
        onClick={()=>setOpenInvoiceDesignerDialogAtom({...setOpenInvoiceDesignerDialogAtom, open: true})}
        >
          Designer
        </Button> */}
      </Grid>
    </>
  );
}

import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

const openInvoiceDialogAtom = atom({
  key: "openInvoiceDialog",
  default: {
    open: false,
    invoiceId: "",
    duplicate: false,
    isEstimate: false,
    recurring: false,
  },
  effects_UNSTABLE: [persistAtom],
});

export default openInvoiceDialogAtom
export const de_PAGE_CLIENTS = {
  h1: "Kunden",
  createClient: "KUNDEN ANLEGEN",
  editClient: "KUNDEN BEARBEITEN",
  search: "Suche",
  searchPlaceholder: "Nr. oder Kundenname suchen ...",
  noResult: "Kein Ergebnis",
  noClientsCreated: "Du hast keine Kunden angelegt",
  settings: "Einstellungen",
  settingsCreation: "Erstellung von Kundennummern",
  settingsCreationAutomatic: "Automatisch",
  settingsCreationManual: "Manuell (Kundennummern selbst eingeben)",
  settingsCreationSchema: "Schema definieren",
  addElement: "Element hinzufügen",
  elementText: "Fixer Text",
  elementTextPlaceholder: "Text, Zahl oder Sonderzeichen",
  elementContinuousNumber: "Fortlaufende Nummer",
  elementContinuousNumberDescribed: "Fortlaufende Nummer beginnend bei",
  elementYear: "Jahr",
  elementMonth: "Monat",
  elementDay: "Tag",
  elementDatePlaceholder: "Zum Zeitpunkt der Erstellung eines Kunden",
  clientNumberPreview: "Vorschau",
  filter: "Filter",
  filterStatus: "Kunden mit folgendem Status anzeigen",
  filterStatusActive: "Aktive Kunden",
  filterStatusInactive: "Inaktive Kunden",
  filterStatusProspect: "Mögliche Kunden (in Verhandlung)",
  appliedFilters: "Angewendete Filter",
  sorted: "Sortiert nach",
  sortCreationChronologically: "Erstellungszeitpunkt (chronologisch)",
  sortCreationChronologicallyReverse: "Erstellungszeitpunkt (umgekehrt chronologisch)",
  sortClientNumbersAsc: "Kundennummer (aufsteigend)",
  sortClientNumbersDesc: "Kundennummer (absteigend)",
  sortCompanyAsc: "Kundennamen (A-Z)",
  sortCompanyDesc: "Kundennamen (Z-A)",
  clientNumber: "Kundennummer",
  activeClient: "Aktiver Kunde",
  inactiveClient: "Inaktiver Kunde",
  prospectClient: "Möglicher Kunde (in Verhandlung)",
  tooltipCannotEditExistingClient: "Kundennummern bestehender Kunden können nicht geändert werden.",
  tooltipChangeClientNumberToManual: 'Um Kundennummern selbst einzugeben, wechseln Sie bitte in Ihren Einstellungen auf "manuell".',
  profileIncomplete: "Bitte vervollständige dein Profil um Kunden anlegen zu können.",
  company: {
    h2: "Unternehmen"
  },
  person: {
    h2: "Kontaktperson"
  },
  logo: {
    h2: "Logo"
  },
  status: {
    h2: "Status"
  },
  note: {
    h2: "Notizen"
  },
  clientDelete: "Bist du sicher, dass du diesen Kunden endgültig löschen möchtest? Das Löschen kann nicht mehr rückgängig gemacht werden.",
  exportClientsList: "Kundenliste exportieren",
  exportClientsListIncludeFields: "Folgende Felder der Kundenliste im Export inkludieren",
}



import React, { useState, useEffect } from "react";

import AnimationUpload from "../UIElements/animations/AnimationUpload";

import imageCompression from 'browser-image-compression';
import {
  Typography,
  Grid,
  IconButton,
  Backdrop,
  Box,
  CircularProgress,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';

import Logo from './../../../_assets/logo_colored.svg'

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Fade from '@material-ui/core/Fade';
import PublishIcon from '@material-ui/icons/Publish';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import "./DropzoneUploader.scss"
import { useSnackbar } from 'notistack';
import { pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker; 



const useStyles = makeStyles((theme) => {
  return {
    toolbar: theme.mixins.toolbar,
    stepContent: {
      display: "flex",
      justifyContent: "center"
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },

    // cssLabel: {
    //   color : 'green'
    // },

    cssOutlinedInput: {
      '&$cssFocused $notchedOutline': {
        borderColor: `${theme.palette.primary.main} !important`,
      }
    },

    cssFocused: {},

    notchedOutline: {
      borderWidth: '2px',
      borderColor: "#f0f3ff !important"
    },

    backdrop: {
      zIndex: 999999,
      color: 'rgba(0,0,0,0.65)',
      backgroundColor: "rgba(245,245,245,1)"
    },
  };
});

const DropzoneUploader = ({
  values,
  setValues,
  buttonText,
  description,
  accept,
  logo,
  multiple,
  maxFiles,
  disabled,
  setLogosProcessing,
  createExpenses,
  setCreateExpenses,
  expensesLength,
  setExpenses,
  errorFiles,
  setErrorFiles,
  overallExpensesLength
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();


  const [imageBlobs, setImageBlobs] = useState([]);
  const [filesProcessing, setFilesProcessing] = useState(0);
  const [files, setFiles] = useState([]);
  const [errorFilesSize, setErrorFilesSize] = useState([]);
  const [errorFilesType, setErrorFilesType] = useState([]);
  const [errorFilesMax, setErrorFilesMax] = useState([]);

  // Initial imageBlobs state
  useEffect(() => {
    if (typeof(logo) === "string" && logo !== "") {
      setImageBlobs([[logo, "image"]])
    } else {
      setImageBlobs([])
    }
  }, []);


  useEffect(() => {
      if ((filesProcessing !== 0) && (filesProcessing === files.length)) {
      setValues(prevValues => ({
        ...prevValues,
        logo: files,
      }));
      // multiple && setLogosProcessing(prevState => prevState + allFiles.length);
      setCreateExpenses(true);
      // setErrorFilesSize([]);
      // setErrorFilesType([]);
      // setErrorFilesMax([]);
    }
  }, [filesProcessing, files]);


  useEffect(() => {
    if((filesProcessing !== 0) && (filesProcessing === files.length)) {
      setFilesProcessing(0)
      setFiles([])
    }
    
  }, [values]);


  // specify upload params and url for your files
  const getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };

  const handleChangeStatus = ({ meta, file, remove }, status) => {
    if (status === "done") {
      processFiles(meta, file, remove, status).then(processedFiles => {
        // Update the state with all processed files
        // setValues(prevValues => ({
        //   ...prevValues,
        //   logo: [...prevValues.logo, ...processedFiles],
        // }));

        setFiles((prevFiles) => [...prevFiles, ...processedFiles]);
        remove()
      });
    } else {
      processFiles(meta, file, remove, status);
    }
  };

  const processFiles = async (meta, file, remove, status) => {
    const metaArray = [meta.name, meta.size];

    if (status === "preparing") {
      setFilesProcessing(prevFilesProcessing => prevFilesProcessing + 1);
    }

    // Handle file size or type errors
    if (status === "error_file_size") {
        setFilesProcessing(prevFilesProcessing => prevFilesProcessing - 1);
        if (!errorFilesSize.some(arr => arr[0] === metaArray[0] && arr[1] === metaArray[1])) {
          setErrorFilesSize(prevErrorFilesSize => [...prevErrorFilesSize, metaArray]);
        }
        remove();
        // enqueueSnackbar(`File is too large. Maximum file size: 5 MB.`, { variant: "error" });
        return [];
    } else if (status === "rejected_file_type") {
        if (!errorFilesType.some(arr => arr[0] === metaArray[0] && arr[1] === metaArray[1])) {
          setErrorFilesType((prevErrorFilesType) => [...prevErrorFilesType, metaArray]);
        }
        // enqueueSnackbar(`Invalid file type. Allowed formats: ${multiple ? '.jpg, .png, or .pdf' : '.jpg or .png'}`, { variant: "error" });
        return [];
    } else if (status === "rejected_max_files") {
        if (!errorFilesMax.some(arr => arr[0] === metaArray[0] && arr[1] === metaArray[1])) {
          setErrorFilesMax((prevErrorFilesMax) => [...prevErrorFilesMax, metaArray]);
        }
        // enqueueSnackbar(multiple ? `Maximum ${maxFiles} files per upload.` : "Maximum 1 file per upload", { variant: "error" });
        return [];
    }

    if (status === "done") {
        let fileProcessingPromises = [];

        if (meta.type.startsWith("image/")) {
            const options = {
                maxSizeMB: 1, // (max file size in MB)
                maxWidthOrHeight: 1920, // (scales image if it's too large)
                useWebWorker: true
            };

            const compressImagePromise = imageCompression(file, options)
                .then(compressedFile => getDataBlob(compressedFile, remove))
                .catch(error => {
                    console.error("Image compression error:", error);
                    enqueueSnackbar("Error compressing image", { variant: "error" });
                });

            fileProcessingPromises.push(compressImagePromise);
        } else if (meta.type === "application/pdf") {
            const extractImagesPromise = extractImagesFromPDF(file, remove);
            fileProcessingPromises.push(extractImagesPromise);
        }

        // Wait for all file processing promises to complete
        const processedFiles = await Promise.all(fileProcessingPromises);
        return processedFiles.filter(fileResult => fileResult !== undefined);
    }
    return [];
};

const extractImagesFromPDF = (file, remove) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            fetch(reader.result).then((response) => {
                response.blob().then((blob) => {
                    let reader = new FileReader();
                    reader.onload = (e) => {
                        const data = atob(e.target.result.replace(/.*base64,/, ""));
                        renderPage(data, reader.result, resolve, reject, file.name, blob);
                    };
                    reader.readAsDataURL(blob);
                });
            });

            const renderPage = async (data, pdfBlob, resolve, reject, fileName, originalBlob) => {
                const imagesList = [];
                const canvas = document.createElement("canvas");
                canvas.setAttribute("className", "canv");
                try {
                    const pdf = await pdfjs.getDocument({ data }).promise;
                    for (let i = 1; i <= pdf.numPages; i++) {
                        const page = await pdf.getPage(i);
                        const viewport = page.getViewport({ scale: 1.5 });
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;

                        const render_context = {
                            canvasContext: canvas.getContext("2d"),
                            viewport: viewport,
                        };

                        await page.render(render_context).promise;
                        let imgDataUrl = canvas.toDataURL("image/png", 1.0);
                        let quality = 1.0;
                        let fileSize = (imgDataUrl.length * (3 / 4)) / (1024 * 1024);

                        while (fileSize > 1 && quality > 0.1) {
                            quality -= 0.1;
                            imgDataUrl = canvas.toDataURL("image/png", quality);
                            fileSize = (imgDataUrl.length * (3 / 4)) / (1024 * 1024);
                        }

                        imagesList.push(imgDataUrl);
                    }

                    const fileResult = [JSON.stringify(imagesList), "pdf", fileName, pdfBlob];
                    resolve(fileResult);
                } catch (error) {
                    console.error('Error rendering PDF:', error);
                    reject(error);
                }
            };
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
            reject(error);
        };
    });
};


const getDataBlob = (file, remove) => {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
          const dataURL = reader.result;
          const fileResult = [dataURL, "image", file.name];
          resolve(fileResult);
      };
      reader.onerror = (error) => {
          console.error('File reading error:', error);
          reject(error);
      };
      reader.readAsDataURL(file);
  });
};

const handleSubmit = (files, allFiles) => {
    multiple && allFiles.forEach(f => f.remove())
    // multiple && setLogosProcessing(prevState => prevState + allFiles.length);
    // multiple && setCreateExpenses(true);
    setErrorFilesSize([]);
    setErrorFilesType([]);
    setErrorFilesMax([]);
}

const handleDrop = () => {
  // multiple && setLogosProcessing(prevState => prevState + allFiles.length);
  // multiple && setCreateExpenses(true);
  setErrorFilesSize([]);
  setErrorFilesType([]);
  setErrorFilesMax([]);
  setFiles([]);
  setValues({
    ...values,
    logo: []
  })
}

return (
    <>
      {(JSON.stringify(imageBlobs) !== "[]" && multiple === false) &&
      <Grid container item xs={12}>
        <div className="dropzone-preview-image-wrapper" style={{ height: multiple ? "auto" : "216px"}}>
          <Grid item container alignItems="center" style={{ flexWrap: "nowrap" }}>
          {imageBlobs.map((item, i) => (
            <>
              <img src={item[0]} alt="image" />
              <IconButton aria-label="delete" onClick={()=> {
                setImageBlobs([]);
                setValues({...values, logo: ""})
                }} >
                <DeleteForeverIcon/>
              </IconButton>
              </>
             ))}
          </Grid>
        </div>
      </Grid>
      }

      {((JSON.stringify(imageBlobs) === "[]" && multiple === false) || multiple === true) &&
      <Grid container item xs={12} style={{ height: multiple ? "auto" : "216px", alignContent: "flex-start" }}>
        <Dropzone
          classNames={{ dropzone: multiple ? "dzu-dropzone multiple" : "dzu-dropzone single" }}
          disabled={disabled}
          // getUploadParams={getUploadParams} // not needed as file is stored to local storage
          onChangeStatus={handleChangeStatus}
          accept={accept}
          multiple={multiple}
          maxFiles={maxFiles}
          style={{ background: "red" }}
          maxSizeBytes={5242880} // 5 MB
          inputContent={
            <>
            {((multiple === true && !disabled) || multiple === false) &&
            <Fade in={true}>
              <div className="dropzone-upload-wrapper">
                <div className="dropzone-button">
                  <Typography variant="body1" style={{ fontWeight: "500", marginRight: "10px" }}>{buttonText}</Typography>
                  <PublishIcon />
                </div>

                <Typography className="dropzone-text" variant="body2" style={{ maxWidth: "550px" }}>{description}</Typography>
              </div>
            </Fade>
            }
            </>
          }
        >
        </Dropzone>
      </Grid>
      }

      {(!createExpenses && filesProcessing === 0 && expensesLength !== 0) &&
          <div style={{ marginTop: "10px" }}>
            <Alert severity="success" style={{ fontWeight: 500 }}>{`${expensesLength === 1 ?  `${expensesLength} Ausgabe wurde erstellt` : `${expensesLength} Ausgaben wurden erstellt`}`}</Alert>
          </div>
      }

      {(errorFilesSize.length !== 0 && !createExpenses && filesProcessing === 0) &&
      <div style={{ marginTop: "10px" }}>
        <Alert severity="error" className="alert-dropzone">
          <span style={{ fontWeight: 500  }}>Datei zu groß (max. 5 MB). Nicht hochgeladene Dateien:</span>
          <div>
            <ul>
          {errorFilesSize.map((error, index) => (
            <li key={index}>{`${error[0]} (${(error[1] < 1024 ? error[1] + ' B' : error[1] < 1048576 ? (error[1] / 1024).toFixed(1) + ' KB' : error[1] < 1073741824 ? (error[1] / 1048576).toFixed(1) + ' MB' : (error[1] / 1073741824).toFixed(1) + ' GB')})`}</li>
          ))}
          </ul>
          </div>
        
        </Alert>
      </div>
      }

      {(errorFilesType.length !== 0 && !createExpenses && filesProcessing === 0) && 
      <div style={{ marginTop: "10px" }}>
      <Alert severity="error" className="alert-dropzone">
        <span style={{ fontWeight: 500}}>Falscher Datei-Typ (erlaubt: pdf, jpg, png). Nicht hochgeladene Dateien:</span>
        <div>
          <ul>
        {errorFilesType.map((error, index) => (
          <li key={index}>{error[0]}</li>
        ))}
        </ul>
        </div>

      </Alert>
      </div>
      }

      {(errorFilesMax.length !== 0 && !createExpenses && filesProcessing === 0) &&
      <div style={{ marginTop: "10px" }}>
      <Alert severity="error" className="alert-dropzone">
        <span style={{ fontWeight: 500 }}>Maximal 10 Dateien pro Upload. Nicht hochgeladene Dateien:</span>
        <div>
          <ul>
        {errorFilesMax.map((error, index) => (
          <li key={index}>{error[0]}</li>
        ))}
        </ul>
        </div>

      </Alert>
      </div>
      }

      {(errorFiles.length !== 0 && !createExpenses && filesProcessing === 0) &&
      <div style={{ marginTop: "10px" }}>
      <Alert severity="error" className="alert-dropzone">
        <span style={{ fontWeight: 500 }}>Folgende Belege konnten nicht ausgelesen werden:</span>
        <div>
          <ul>
        {errorFiles.map((error, index) => (
          <li key={index}>{error}</li>
        ))}
        </ul>
        </div>
      </Alert>
      </div>
      }
      
      <Backdrop
        className={classes.backdrop}
        open={((filesProcessing !== 0) && (filesProcessing !== files.length)) || createExpenses}
        >
        <Box position="relative" display="flex" style={{ flexDirection: "column", alignItems: "center"}}>
          {(((filesProcessing !== 0) && (filesProcessing !== files.length)) && !createExpenses) &&
          <>
          <img src={Logo} height={50} width={100} alt={`Logo`}/>

          <AnimationUpload />
          <div style={{ marginTop: "5px" }}>
            <Typography variant="h6" style={{ marginLeft: 20 }}><span style={{ color: "#2484FF"}}><span className="text-animation-1" style={{ letterSpacing: 2 }}>BELEGE WERDEN HOCHGELADEN</span></span><br/><span style={{ fontSize: "16px" }}>Bitte warten</span><span className="one">.</span><span className="two">.</span><span className="three">.</span></Typography>
          </div>
          </>
          }
          {(createExpenses || (overallExpensesLength !== expensesLength) )&&
          <>
          <img src={Logo} height={50} width={100} alt={`Logo`}/>
          <CircularProgress style={{ color: "#19a051", background: "#e6edf3", border: "2px solid white", width: "64px", height: "64px", borderRadius: "80px" }}/>
          <div style={{ marginTop: "10px" }}>
            <Typography variant="h6" style={{ marginLeft: 20 }}><span className="text-animation-2" style={{ letterSpacing: 2 }}>BELEGE WERDEN AUSGELESEN</span><br/><span style={{ fontSize: "16px" }}>Bitte warten</span><span className="one">.</span><span className="two">.</span><span className="three">.</span></Typography>
          </div>
          </>
          }
        </Box>
      </Backdrop>
    </>
  );
};

export default DropzoneUploader;


export const DB_GET_INVOICESRECURRING = async (setInvoicesrecurringAtom, auth, sendRequest) => {
  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL_API}/invoicesrecurring/user/${auth.userId}`,
      "GET",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    )
    // NO ERROR
    .then((res) => setInvoicesrecurringAtom(res.invoicerecurrings))
  } catch (err) { 
    // console.log(err)
    // ERROR
  }
};

export const DB_DELETE_INVOICE = async (
  invoice,
  auth,
  sendRequest,
  enqueueSnackbar,
  t,
  invoicesrecurringAtom,
  setInvoicesrecurringAtom,
  openInvoiceDialogAtom,
  setOpenInvoiceDialogAtom,
  setShowConfirmModal
  ) => {
  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL_API}/invoicesrecurring/${invoice}`,
      "DELETE",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    )
    // NO ERROR
    .then(() => setOpenInvoiceDialogAtom({ ...openInvoiceDialogAtom, open: false, invoiceId: "" }))
    .then(() => setShowConfirmModal(false))
    .then(() => setInvoicesrecurringAtom(invoicesrecurringAtom.filter((item) => item.id !== invoice)))
    .then(() => enqueueSnackbar(t("FIELDS.saved"), { variant: "success" }))
  } catch (err) { 
    // ERROR
    enqueueSnackbar(t("FIELDS.error"), { variant: "error" })
  }
};


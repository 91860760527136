import React, { useEffect, useState } from "react";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";

// IMPORT recoil
import {
    useRecoilState,
  } from 'recoil';
import openCheckoutAtomState from "../../../_atoms/openCheckoutAtom";
import checkoutAtomState from "../../../_atoms/checkoutAtom";

// IMPORT components MATERIAL UI
import {
    Typography,
    Button,
    Grid
} from "@material-ui/core";

// IMPORT icons MATERIAL UI
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

// IMPORT theme MATERIAL UI and custom scss
import { useTheme } from '@material-ui/core/styles';
import "./Promo.scss"

const Promo = (props) => {
    // HOOKS & CONTEXT
    const { t } = useTranslation();
    const theme = useTheme();

    // GLOBAL STATE (RECOIL)
    const [, setOpenCheckoutAtom] = useRecoilState(openCheckoutAtomState);
    const [checkoutAtom, setCheckoutAtom] = useRecoilState(checkoutAtomState);

    // LOCAL STATE
    const [features, setFeatures] = useState([]);

    const bg = props.product === "premium" ? "bg--4" : "bg--5";
    const mainColor = props.product === "premium" ? theme.palette.premium.main : theme.palette.pro.main;
    const buttonTextColor = props.product === "premium" ? theme.palette.premium.buttonText : theme.palette.pro.buttonText;
    const product = props.product;

    useEffect(() => {
        const contentObject = t(`PRODUCTS.${props.product}.features`, { returnObjects: true }); // Get object
        const contentArray = Object.values(contentObject); // Convert object to array
        setFeatures(contentArray)
    }, [props.product]);

    return (
        <Grid item xs={12} className={`h-borderRadius--10 ${bg} container--promo`}>
            <Typography variant="h6" component="div">{t(`PROMO.${product}.headline1`)}<span style={{ color: mainColor }}>{t(`PROMO.${product}.headline2`)}</span>{t(`PROMO.${product}.headline3`)}</Typography>

            <Typography variant="body1" component="p">{t(`PROMO.${product}.subline`)}</Typography>

            <div className="list">
                {features.map(function (item, i) {
                    return item[0] === "+" &&
                    <div className="list-item" key={i}>
                        <CheckCircleIcon fontSize="medium" className="list-item-icon" style={{ color: mainColor}}/>
                        <Typography component="span" variant="body1">{item[1]}</Typography>
                    </div>
                })}
            </div>

            <Button onClick={() => {setOpenCheckoutAtom(true); setCheckoutAtom({...checkoutAtom, show: product.toUpperCase()})}} variant="contained" size="medium" style={{ background: mainColor }}>
                <span style={{ color: buttonTextColor }}>{t(`PROMO.${product}.button`)}</span> <ExitToAppIcon style={{ color: buttonTextColor }} />
            </Button>
        </Grid>
    );
};

export default Promo;

export const ERROR = (t, error) => {
  let message = "";

  switch(error) {
    case "Failed to fetch":
      message = t("ERRORS.failedToFetch");
      break;
    case "Invalid credentials, could not log you in.":
      message = t("ERRORS.invalidCredentials");
      break;
    default:
      message = t("ERRORS.default")
  }
  
  return message;
}

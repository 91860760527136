import React, { useState, useEffect } from 'react';

// IMPORT hooks & context
import { useTranslation } from "react-i18next";

// IMPORT components MATERIAL UI
import {
  Typography,
  Button,
  Grid,
  IconButton,
  FormControlLabel,
  FormControl,
  FormGroup,
  Checkbox,
  Dialog,
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

// IMPORT icons MATERIAL UI
import CloseIcon from '@material-ui/icons/Close';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

// IMPORT components
import { CSVLink } from "react-csv";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function InvoicesCsv(props) {
  // HOOKS & CONTEXT
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');

  const [values, setValues] = useState({
    data: [],
    checked: {
      invoiceNr: true,
      company: true,
      clientNumber: true,
      discountedSubtotal: true,
      vatTotal: true,
      total: true,
      vatValues: true,
    }
  });

  const [prepareCsv, setPrepareCsv] = useState(false);
  const [headers, setHeaders] = useState([]);

  const handleChange = (e) => {
    setValues(previousInputs => ({
      ...previousInputs,
      checked: {
        ...values.checked,
        [e.target.name]: e.target.checked,
      },
    }))
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (prepareCsv) {
      // DEFAULT HEADERS
      let headers = [];

      values.checked.invoiceNr && headers.push({ label: t("FIELDS.invoiceNumber.label"), key: "invoiceNr" });
      values.checked.company && headers.push({ label: t("FIELDS.company.label"), key: "company" });
      values.checked.clientNumber && headers.push({ label: t("FIELDS.clientNumber.label"), key: "clientNumber" });
      values.checked.discountedSubtotal && headers.push({ label: "Nettobetrag (gesamt)", key: "discountedSubtotal" });
      values.checked.vatTotal && headers.push({ label: "USt.(gesamt)", key: "vatTotal" });
      values.checked.total && headers.push({ label: "Bruttobetrag (gesamt)", key: "total" });

      setHeaders(headers)
    }
  }, [props.data, prepareCsv, values.checked]);

  useEffect(() => {
    if (prepareCsv) {
      // HEADERS
      let vatPercentages = [];

      // Get VAT percentage of each item of each invoice (because there has to be a column for a VAT %, even if it comes up only in 1 item in 1 invoice)
      Object.entries(props.data).forEach((item) => {
        const vatPercentageOfEachItem = Object.values(JSON.parse(item[1].vatValues));
        vatPercentageOfEachItem.forEach((entry) => {
          if(vatPercentages.indexOf(entry[0]) === -1) {
            vatPercentages.push(entry[0]);
          }
        });
      });

      if (values.checked.vatValues) {
        vatPercentages.forEach((item, i) => {
          setHeaders((items) => [
            ...items,
            { label: `Nettobetrag (${item}% USt.)`, key: `Nettobetrag (${item}% USt.)` },
            { label: item + "% USt.", key: item + "% USt." },
            { label: `Bruttobetrag (${item}% USt.)`, key: `Bruttobetrag (${item}% USt.)` },
          ]);
        })
      }
      
      // DATA
      let newData = [];

      Object.entries(props.data).forEach((item) => {
      let netValueTotal = 0;
      Object.values(JSON.parse(item[1].vatValues)).forEach((entry) => {
        let entry0 = parseFloat(entry[0]); // vat percent
        let entry1 = parseFloat(entry[1]); // vat absolute
        let entry2 = parseFloat(entry[2]); // net
        let netValue = entry2;
        netValueTotal += netValue;
        let grossValue = entry2 * (1 + (entry0 / 100));
        item[1] = {
          ...item[1],
          [`${entry0}% USt.`]: entry1,
          [`Nettobetrag (${entry0}% USt.)`]: netValue,
          [`Bruttobetrag (${entry0}% USt.)`]: grossValue,
          // [`Nettobetrag (${entry[0]}% MwSt.)`]: parseInt(netValue),
          // [`Bruttobetrag (${entry[0]}% MwSt.)`]: parseInt(grossValue),
        }
      })

      const invoiceNumber = (invoiceNr) => {
        let nr = ""
        for (const [key, value] of Object.entries(invoiceNr)) {
          nr += value.value
        }
        return nr;
      }

      const clientNumber = (clientNr) => {
        let nr = ""
        for (const [key, value] of Object.entries(clientNr)) {
          nr += value.value
        }
        return nr;
      }

      item[1] = {
        ...item[1],
        company: JSON.parse(item[1].clientData).company,
        clientNumber: clientNumber(JSON.parse(JSON.parse(item[1].clientData).clientNumber)),
        invoiceNr: invoiceNumber(JSON.parse(item[1].invoiceNr)),
        vatTotal: JSON.parse(item[1].total) - JSON.parse(item[1].discountedSubtotal),
        // items: JSON.parse(item[1].items)
        // status: status
      }
      newData.push(item[1]);
    })

    setValues({ ...values, data: newData });
    }
  }, [props.data, prepareCsv, values.checked]);

  return (
    <>
      <IconButton
        aria-label="settings"
        style={{ color: "inherit" }}
        onClick={()=>{handleClickOpen(); setPrepareCsv(true)}}
      >
        <SaveAltIcon fontSize="small" />
      </IconButton>
      <Dialog
        onClose={()=>{handleClose(); setPrepareCsv(false);}}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogTitle id="customized-dialog-title" onClose={()=>{handleClose(); setPrepareCsv(false);}}>
          {t("PAGE_INVOICES.exportInvoicesList")}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container item style={{ marginBottom: "20px" }}>
            <FormControl component="fieldset">
              <Typography variant="body2" component="div" style={{ marginBottom: "10px" }}><strong>{t("PAGE_INVOICES.exportInvoicesListIncludeFields")}:</strong></Typography >
              <FormGroup aria-label="csvFields" name="csvFields" onChange={(e) => handleChange(e)}>
                <FormControlLabel name="invoiceNr" control={<Checkbox checked={values.checked.invoiceNr} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("FIELDS.invoiceNumber.label")}</Typography>} />
                <FormControlLabel name="company" control={<Checkbox checked={values.checked.company} color="primary" style={{ paddingTop: "5px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("FIELDS.company.label")}</Typography>} />
                <FormControlLabel name="clientNumber" control={<Checkbox checked={values.checked.clientNumber} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("FIELDS.clientNumber.label")}</Typography>} />
                <FormControlLabel name="discountedSubtotal" control={<Checkbox checked={values.checked.discountedSubtotal} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">Nettobetrag</Typography>} />
                <FormControlLabel name="vatTotal" control={<Checkbox checked={values.checked.vatTotal} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">USt.</Typography>} />
                <FormControlLabel name="total" control={<Checkbox checked={values.checked.total} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">Bruttobetrag</Typography>} />
                <FormControlLabel name="vatValues" control={<Checkbox checked={values.checked.vatValues} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">Beträge nach USt.-Satz aufgeschlüsselt</Typography>} />
              </FormGroup>
            </FormControl>
          </Grid>
        </DialogContent>

        <DialogActions>
          <CSVLink data={values.data} headers={headers}>
            <Button autoFocus onClick={()=>{handleClose(); setPrepareCsv(false);}} color="primary">
              {t("BUTTONS.exportCsv")}
            </Button>
          </CSVLink>
        </DialogActions>
      </Dialog>
    </>
  );
}

export const de_BUTTONS = {
    login: 'Login',
    register: "Jetzt registrieren",
    back_to_login: "Zum Login",
    back_to_dashboard: "Zum Dashboard",
    back_to_app: "Zurück zur App",
    forgot_password: 'Link senden',
    reset_password: 'Passwort ändern',
    AL_password_visibilty: "toggle password visibility", // area-label
    AL_close: "schließen", // area-label
    back_to_profile: "Zum Profil",
    back_to_clients: "Kunden anlegen",
    save: "Speichern",
    send: "Senden",
    saveAndClose: "Speichern und Schließen",
    saveAndSend: "Speichern und Senden",
    cancel: "Abbrechen",
    logoUpload: "Logo hochladen",
    logoDelete: "Logo entfernen",
    close: "Schließen",
    back: "Zurück",
    next: "Weiter",
    buy: "Jetzt bestellen",
    delete: "Löschen",
    clientDelete: "Kunden löschen",
    invoiceDelete: "Rechnung löschen",
    expenseDelete: "Ausgabe löschen",
    exportCsv: "CSV exportieren"
}

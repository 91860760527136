import React, { useEffect } from "react";

import "./AnimationLetter.scss"

const AnimationLetter = () => {
  useEffect(() => {
    setTimeout(function () {
      var el = document.getElementById('letter-image');
      if (el) {
        el.className += el.className ? ' open' : 'someClass';
      }
    }, 100);
  }, [])

  return (
    <div class="letter-image" id="letter-image">
      <div class="animated-mail">
        <div class="back-fold"></div>

        <div class="letter">
          <div class="letter-title"></div>
          <div class="letter-context"></div>
          <div class="letter-stamp">
            <div class="letter-stamp-inner"></div>
          </div>
        </div>

        <div class="top-fold"></div>

        <div class="body"></div>

        <div class="left-fold"></div>
      </div>

      <div class="shadow"></div>
    </div>
  );
};

export default AnimationLetter;

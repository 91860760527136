import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

const openClientDialogAtom = atom({
  key: "openClientDialog",
  default: {
    open: false,
    clientId: ""
  },
  effects_UNSTABLE: [persistAtom],
});

export default openClientDialogAtom
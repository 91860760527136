import { createTheme } from '@material-ui/core/styles';

/*
// Official Breakpoints
Breakpoints(

Matieral UI:
xs: 0
sm: 600
md: 960
lg: 1280
xl: 1920

iPad Pro:
1024
)*/



let theme = createTheme({
  palette: {
    primary: {
      light: '#4dabf5',
      main: "#2196f3",
      // main: "#00a4b5",
      dark: "#1a7bc9",
    },
    secondary: {
      main: '#E312CA',
    },
    standard: {
      main: "rgba(0, 0, 0, 0.54)",
      light: "#e9e9e9"
    },
    error: {
      main: "#b71c1c",
    },
    success: {
      light: "#4caf50",
      main: "#2e7d32",
      dark: "#1b5e20"
    },
    premium: {
      light: "#000000",
      main: "#A8EB12",
      dark: "#000000",
      buttonText: "#004d7a",
    },
    pro: {
      light: "#000000",
      main: "#FFD700",
      dark: "#000000",
      buttonText: "#004d7a",
    },
    field: {
      main: "#f0f3ff"
    }
  },
  typography: {
    fontFamily: `Inter, sans-serif`,
    h1: {
      fontFamily: `Plus Jakarta Sans, sans-serif`,
      fontWeight: 700,
      // letterspacing: "1px"
    },
    h2: {
      fontFamily: `Plus Jakarta Sans, sans-serif`,
    },
    h3: {
      fontFamily: `Plus Jakarta Sans, sans-serif`,
    },
    h4: {
      fontFamily: `Plus Jakarta Sans, sans-serif`,
      fontWeight: 700,
    },
    h5: {
      fontFamily: `Plus Jakarta Sans, sans-serif`,
      fontWeight: 700,
    },
    h6: {
      fontFamily: `Plus Jakarta Sans, sans-serif`,
      fontWeight: 700,
    },
    body1: {
      fontWeight: 300,
    },
    body2: {
      fontWeight: 300,
    }
  },

  breakpoints: {
    values: {
      // xxs: 0,
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      // xxl: 1800
    }
  },
  props: {
    MuiTypography: {
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h2',
        h4: 'h2',
        h5: 'h2',
        h6: 'h2',
        subtitle1: 'h2',
        subtitle2: 'h2',
        body1: 'span',
        body2: 'p',
      },
    },
    MuiButton: {
      disableElevation: true,
    },
  },
});


theme = createTheme(theme, {
  overrides: {
    MuiFilledInput: {
      root: {
        background: theme.palette.field.main,
        backgroundColor: theme.palette.field.main,
        borderRadius: "4px",
        "&:hover": {
          background: theme.palette.field.main,
          backgroundColor: theme.palette.field.main,
        },
        "&.Mui-focused": {
          background: theme.palette.field.main,
          backgroundColor: theme.palette.field.main,
        },
        "&.Mui-disabled": {
          background: "rgb(177 177 177 / 12%)",
          backgroundColor: "rgb(177 177 177 / 12%)",
        }
      },
      input: {
        borderRadius: "4px",
      },
      underline: {
        '&:after': {
          display: "none"
        },
        '&:focused::after': {
          display: "none"
        },
        '&:error::after': {
          display: "none"
        },
        '&:before': {
          display: "none"
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          display: "none"
        },
        '&$disabled:before': {
          display: "none"
        },
      }
    },

      //   MuiAppBar: {
    //     root: {
    //       transition: "top 0.3s",
    //     },
    //     colorDefault: {
    //       backgroundColor: "#34292D"
    //     }
    //   },
    //   MuiDrawer: {
    //     paper: {
    //       padding: "20px",
    //       margin: "0 auto",
    //       backgroundColor: "#34292D",
    //       color: "#ffffff",
    //       minHeight: "100vh !important",
    //     }
    //   },

    MuiTypography: {
      // root: {
      //   fontFamily: "Raleway, sans-serif"
      // },
      // body1: {
      //   fontFamily: "Raleway, sans-serif"
      // },
      // body2: {
      //   fontFamily: "Raleway, sans-serif"
      // },
      // h1: {
      //   fontFamily: "Plus Jakarta Sans",
      // },
      // h6: {
      //   letterSpacing: "1px",
      // }
    },
    //   MuiLinearProgress: {
    //     root: {
    //       height: "2px"
    //     }
    //   },
    //   MuiMobileStepper: {
    //     root: {
    //       background: "transparent",
    //       justifyContent: "center"
    //     },
    //     progress: {
    //       display: "none"
    //     }
    //   },
    MuiButton: {
      label: {
        // fontFamily: `Plus Jakarta Sans 700, sans-serif`,
        // letterSpacing: "1px",
      },
      contained: {
        '&.Mui-disabled': {
          backgroundColor: '#e3e3e3',
        },
      },
    },
    MuiListItemText: {
      primary: {
        fontFamily: `Plus Jakarta Sans, sans-serif`,
        fontSize: "14px"
      }
    },

    MuiListItemIcon: {
      root: {
        minWidth: "40px",
      }
    },

    MuiAutocomplete: {
      inputRoot: {
        background: "#f0f3ff !important",


      },
    },

    MuiFormControl: {
      root: {
        borderRadius: "4px",
      }
    },

    MuiBadge: {
      badge: {
        padding: 0
      }
    }



    //   MuiInputBase: {
    //     root: {
    //       fontFamily: "Stag_Sans-Light"
    //     },
    //     input: {
    //       fontFamily: "Stag_Sans-Light"
    //     }
    //   },
    //   MuiFilledInput: {
    //     root: {
    //       borderTopLeftRadius: "12px",
    //       borderTopRightRadius: "12px",
    //       backgroundColor: "#ffffff",
    //       borderRadius: "12px",
    //       border: "1px solid #34292D"
    //     }
    //   },
    //   MuiFormLabel: {
    //     root: {
    //       fontFamily: "Stag_Sans-Light",
    //     },
    //   },
    //   MuiFormControl: {
    //     root: {
    //       width: "100%"
    //     },
    //   },
    //   MuiFormControlLabel: {
    //     root: {
    //       fontFamily: "Stag_Sans-Light",
    //       color: "#ffffff",
    //     },
    //   },
    //   PrivateRadioButtonIcon: {
    //     root: {
    //       color: "#ffffff"
    //     }
    //   },
    //   MuiRadio: {
    //     root:  {
    //       display: "block",

    //     }
    //   },
    //   MuiList: {
    //     padding: {
    //       paddingTop: 0,
    //       paddingBottom: 0,
    //     }
    //   },
    //   MuiListItem: {
    //     gutters: {
    //       padding: 0,
    //       paddingLeft:0,
    //       paddingRight:0
    //     }
    //   },
    //   MuiListItemText: {
    //     root: {
    //       marginBottom: 0,
    //       marginTop: 0,
    //     }
    //   },
    //   MuiSnackbar: {
    //     root:  {
    //       left: 0,
    //       right: 0,
    //       bottom: 0,
    //     },
    //     anchorOriginBottomCenter: {
    //       left: "0 !important",
    //       right: "0 !important",
    //       bottom: "0 !important",
    //       transform: "none !important"
    //     }
    //   },
    //   MuiSnackbarContent: {
    //     root: {
    //       borderRadius: 0,
    //       border: "none",
    //       backgroundColor: "#34292D",
    //       color: "#ffffff",
    //       padding: 0
    //     },
    //     message: {
    //       width: "100%",
    //       padding: 0
    //     }
    //   },
    //   PrivateSwitchBase: {
    //     root: {
    //       padding: 0
    //     }
    //   },
    //   MuiToolbar: {
    //     root: {
    //       backgroundColor: "#34292D",
    //       color: "white",
    //       maxHeight: '86px',
    //     },
    //     regular: {
    //       maxWidth: "1110px",
    //       margin: "0 auto",
    //     },
    //   },
    //   MuiBadge: {
    //     badge: {
    //       transform: "scale(1.5)",
    //     },
    //     anchorOriginTopRightRectangle: {
    //       transform: 'scale(1.5)',
    //       right: '-15px'
    //     }
    //   },
    //   MuiSlider: {
    //     track: {
    //       display: 'inline',
    //     },
    //     rail: {
    //       display: 'inline',
    //     }
    //   },
  }
});

export default theme;

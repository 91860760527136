// Used for setting values that have multidimensional object WITH state
export const SET_VALUES = (e, values, setValues, type) => {
  if (type === undefined) {
   // SINGLE VALUE
    const target = (e.target.id !== undefined && e.target.id !== "") ? e.target.id : e.target.name;
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value; // If checkbox gets checked value
    setValues(previousInputs => ({
      ...previousInputs,
      [target]: {
        ...values[target],
        state: value,
      },
    }))
  } else if (type === "multi") {
  // MULTIPLE VALUES
     Object.entries(e.target).forEach(([k, v]) => {
      setValues(previousInputs => ({
        ...previousInputs,
        [k]: {
          ...values[k],
          state: v,
        },
      }))
    })
  }
};

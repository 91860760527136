export const RETURN_DATE = (date) => {
  const dateObj = new Date(date);
  
  let month = dateObj.getMonth() + 1; // months from 1-12
  if (month > 0 && month < 10) {
    month = `0${month}`
  }
  let day = dateObj.getDate();
  if (day > 0 && day < 10) {
    day = `0${day}`
  }

  // return(`${day}.${month}.${(dateObj.getMonth() + 1 === 1 && dateObj.getDate() === 1) ? dateObj.getUTCFullYear() + 1 : dateObj.getUTCFullYear()}`);
  return(`${day}.${month}.${dateObj.getUTCFullYear()}`);
}

export const RETURN_CLEARDATE = (date) => {
  date = new Date(date);
  // Return date without time elements
  date.setHours(0,0,0,0);
  return date;
}

export const RETURN_YEAR = (date) => {
  const dateObj = new Date(date);
  const year   = dateObj.getUTCFullYear();
  return(`${year}`);
}

export const RETURN_MONTH = (date) => {
  const dateObj = new Date(date);
  const year   = dateObj.getUTCMonth() + 1;
  return(`${year}`);
}

export const RETURN_DAY = (date) => {
  const dateObj = new Date(date);
  const year   = dateObj.getUTCDate();
  return(`${year}`);
}

export const RETURN_HOURS = (date) => {
  const dateObj = new Date(date);
  let hours   = dateObj.getHours(); // months from 1-12
  if (hours > 0 && hours < 10) {
    hours = `0${hours}`
  }
  let minutes = dateObj.getMinutes();
  if (minutes > 0 && minutes < 10) {
    minutes = `0${minutes}`
  }
  return(`${hours}:${minutes}`);
}

export const RETURN_OVERDUE_DAYS = (date) => {
  let date1 = new Date(date);
  let date2 = new Date();
  // Ensure that only dates, whithout time elements are compared
  date1.setHours(0,0,0,0);
  date2.setHours(0,0,0,0); 
  
  // Calculating the time difference
  // of two dates
  let Difference_In_Time =
      date2.getTime() - date1.getTime();
  
  // Calculating the no. of days between
  // two dates
  let Difference_In_Days =
      Math.round
          (Difference_In_Time / (1000 * 3600 * 24));
  return(Difference_In_Days);
}

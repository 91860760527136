import React, { useState, useEffect } from "react";

// IMPORT hooks & context
import {
  BrowserRouter as Router,
  useLocation
} from "react-router-dom";
import { AuthContext } from "./shared/context/auth-context";
import { useAuth } from "./shared/hooks/auth-hook";
import { useTranslation } from "react-i18next";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Helmet } from "react-helmet";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import userAtomState from "./_atoms/userAtom";
import loggedInAtomState from "./_atoms/loggedInAtom";
import openCheckoutAtomState from "./_atoms/openCheckoutAtom";
import openClientDialogAtomState from "./_atoms/openClientDialogAtom";
import openInvoiceDialogAtomState from "./_atoms/openInvoiceDialogAtom";
import openExpenseDialogAtomState from "./_atoms/openExpenseDialogAtom";
import openInvoiceRecurringDialogAtomState from "./_atoms/openInvoiceRecurringDialogAtom";
import openInvoiceDesignerDialogAtomState from "./_atoms/openInvoiceDesignerDialogAtom";

// IMPORT components
import AppRoutes from "./AppRoutes"
import AppDrawer from "./AppDrawer"
import CheckoutDialog from "./checkout/CheckoutDialog"
import ClientDialog from "./clients/client_dialog/ClientDialog"
import InvoiceDialog from "./invoices/invoice_dialog/InvoiceDialog";
import ExpenseDialog from "./expenses/pages/ExpenseDialog"
import InvoiceDialogRecurring from "./invoices/pages/InvoiceDialogRecurring"
import InvoiceDesignerDialog from "./invoices/pages/InvoiceDesignerDialog"
import { SnackbarProvider } from 'notistack';

// IMPORT components MATERIAL UI
import {
  Dialog,
  Backdrop,
  Typography
} from "@material-ui/core";

// IMPORT theme MATERIAL UI and custom scss
import theme from "./_styles_global/theme.js";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";

const key = window.location.hostname === "localhost" ? process.env.REACT_APP_STRIPE_PK_DEV : process.env.REACT_APP_STRIPE_PK_PROD
const stripePromise = loadStripe(key);

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
}));

// Makes page scroll to top when new page is loaded
export function GoToTop() {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    onTop()
  }, [routePath]);

  return null;
}

const App = () => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    token,
    login,
    logout,
    userId,
  } = useAuth();

  // GLOBAL STATE (RECOIL)
  const [userAtom] = useRecoilState(userAtomState);
  const [loggedInAtom] = useRecoilState(loggedInAtomState);
  const [openCheckoutAtom] = useRecoilState(openCheckoutAtomState);
  const [openClientDialogAtom] = useRecoilState(openClientDialogAtomState);
  const [openInvoiceDialogAtom] = useRecoilState(openInvoiceDialogAtomState);
  const [openExpenseDialogAtom] = useRecoilState(openExpenseDialogAtomState);
  const [openInvoiceRecurringDialogAtom] = useRecoilState(openInvoiceRecurringDialogAtomState);
  const [openInvoiceDesignerDialogAtom] = useRecoilState(openInvoiceDesignerDialogAtomState);

  // LOCAL STATE
  const [vat] = useState({});

 


  

  return (
    <ThemeProvider theme={theme}>
      <AuthContext.Provider
        value={{
          isLoggedIn: !!token,
          token: token,
          userId: userId,
          login: login,
          logout: logout,
        }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>{t("APP.name")} App</title>
          <html lang="de" dir="ltr" />
        </Helmet>

        <SnackbarProvider
          dense
          maxSnack={10}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          autoHideDuration={2500}
          >
          <Router>
            <div className={classes.root}>
              <React.Fragment>
                {loggedInAtom && userAtom.data.verified && userAtom.data.signupCompleted &&
                  <AppDrawer />
                }

                <main>
                  <AppRoutes />
                </main>

                {loggedInAtom && userAtom.data.country !== "" &&
                  <Dialog
                    fullScreen
                    open={openCheckoutAtom}
                    transitionDuration={0}
                  >
                    <Elements stripe={stripePromise} options={{ locale: "de" }}>
                      <CheckoutDialog />
                    </Elements>
                  </Dialog>
                }

                {loggedInAtom &&
                  <Dialog
                    fullScreen
                    open={openClientDialogAtom.open}
                    transitionDuration={0}
                  >
                    <ClientDialog />
                  </Dialog>
                }

                {loggedInAtom && userAtom.data.signupCompleted &&
                  <Dialog
                    fullScreen
                    open={openInvoiceDialogAtom.open}
                    transitionDuration={0}
                  >
                    <InvoiceDialog />
                  </Dialog>
                }
                
                {loggedInAtom  &&
                  <Dialog
                    fullScreen
                    open={openExpenseDialogAtom.open}
                    transitionDuration={0}
                  >
                    <ExpenseDialog />
                  </Dialog>
                }

                {loggedInAtom && userAtom.data.signupCompleted &&
                  <Dialog
                    fullScreen
                    open={openInvoiceRecurringDialogAtom.open}
                    transitionDuration={0}
                  >
                    <InvoiceDialogRecurring />
                  </Dialog>
                }

                {loggedInAtom && userAtom.data.signupCompleted &&
                  <Dialog
                    fullScreen
                    open={openInvoiceDesignerDialogAtom.open}
                    transitionDuration={0}
                  >
                    <InvoiceDesignerDialog />
                  </Dialog>
                }
                {/* <Backdrop
                  // When SAVING
                  style={{
                    zIndex: 999999,
                    color: '#fff',
                    backgroundColor: 'rgba(245, 245, 245, 0.8)'
                  }}
                  open={true}
                >
                            <div style={{ marginTop: "10px" }}>
            <Typography variant="h6" style={{ marginLeft: 20 }}><span className="text-animation-2" style={{ letterSpacing: 2 }}>BELEGE WERDEN AUSGELESEN</span><br/><span style={{ fontSize: "16px" }}>Bitte warten</span><span className="one">.</span><span className="two">.</span><span className="three">.</span></Typography>
          </div>
          </Backdrop> */}
              </React.Fragment>
            </div>

            <GoToTop />
          </Router>
        </SnackbarProvider>
      </AuthContext.Provider>
    </ThemeProvider>
  );
};

export default App;

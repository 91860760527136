import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

const userAtom = atom({
  key: "user",
  default: {
    data: {}
  },
  effects_UNSTABLE: [persistAtom],
});

export default userAtom
import React, { useState, useContext, useEffect, useRef } from "react";

// IMPORT recoil
import { useRecoilState } from 'recoil';
import {
  settingsAtomState,
  openInvoiceDialogAtomState,
  invoicesAtomState,
  clientsAtomState,
  invoicesFilteredAtomState,
} from "../../_atoms";

// IMPORT global hooks & context
import { useTranslation } from "react-i18next";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from '../../shared/context/auth-context';
import { useAuth } from "../../shared/hooks/auth-hook";

// IMPORT global functions
import {
  SET_VALUES,
  RETURN_DATE,
  RETURN_HOURS,
  RETURN_OVERDUE_DAYS
} from '../../_functions';

// IMPORT components
import { NumericFormat } from "react-number-format";

// IMPORT own components
import LogoMonogram from './../../_assets/logo_monogram.svg';
import InvoiceLivePreview from "../components/InvoiceLivePreview";
import ConfirmModal from "../../shared/components/UIElements/Modal";
import SentBlob from '../components/SentBlob';
import InvoiceNumberEdit from "../components/InvoiceNumberEdit";
import ButtonLoadingBottom from "../../shared/components/UIElements/ButtonLoadingBottom";
import InvoiceNumber from "./components/InvoiceNumber";

import InvoiceAppBar from "./components/InvoiceAppBar";
import InvoiceAlerts from "./components/InvoiceAlerts";
import InvoiceBottomNavigation from "./components/InvoiceBottomNavigation";
import SentBlobsHistory from "./components/SentBlobsHistory";


// IMPORT Material-UI components
import {
  Typography,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  Grid,
  Box,
  InputAdornment,
  TextField,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
  Switch,
  Chip,
  Backdrop,
  BottomNavigation,
  AccordionDetails
} from "@material-ui/core";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Alert from "@material-ui/lab/Alert";

// IMPORT Material-UI icons from custom file
import {
  AddCircle as AddCircleIcon,
  ArrowBack as ArrowBackIcon,
  CheckCircle as CheckCircleIcon,
  DeleteForever as DeleteForeverIcon,
  OpenWith as DragIcon,
  Edit as EditIcon,
  Gavel as GavelIcon,
  RecentActors as InvoicesIcon,
  Mail as MailIcon,
  Save as SaveIcon,
  Warning as WarningIcon,
} from '@material-ui/icons';

// IMPORT date-fns utils and localization
import DateFnsUtils from '@date-io/date-fns';
import { de } from "date-fns/locale";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

// IMPORT own css
import "./InvoiceDialog.scss"

// IMPORT local utils and hooks
import { changeItem, deleteItem, addItem } from "./utils/ITEM";

import invoiceSave from "./utils/INVOICE_SAVE";
import changeContinuousNumber from "./utils/CHANGE_CONTINUOUS_NUMBER";
import changeInvoiceNumberDate from "./utils/CHANGE_INVOICE_NUMBER_DATE";
import useInvoiceCalculations from "./utils/useInvoiceCalculations";
import useInvoiceSetup from "./utils/useInvoiceSetup";
import useUpdateContinuousNumber from "./utils/useUpdateContinuousNumber";
import useInvoiceNumberValidation from "./utils/useInvoiceNumberValidation";

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: "5px",
      borderRadius: "5px"
    },
    margin: "5px",
    borderRadius: "5px"
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },

  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const useStyles = makeStyles((theme) => {
  return {
    toolbar: theme.mixins.toolbar,
    stepContent: {
      display: "flex",
      justifyContent: "center"
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    cssOutlinedInput: {
      '&$cssFocused $notchedOutline': {
        borderColor: `${theme.palette.primary.main} !important`,
      }
    },
    cssFocused: {},
    notchedOutline: {
      borderWidth: '2px',
      borderColor: "#f0f3ff !important"
    },
  };
});

const InvoiceDialog = () => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { userId } = useAuth();
  const theme = useTheme();
  const classes = useStyles();

  // GLOBAL STATE (RECOIL)
  const [openInvoiceDialogAtom, setOpenInvoiceDialogAtom] = useRecoilState(openInvoiceDialogAtomState);
  const [invoicesAtom, setInvoicesAtom] = useRecoilState(invoicesAtomState);
  const [, setInvoicesFilteredAtom] = useRecoilState(invoicesFilteredAtomState);
  const [settingsAtom, setSettingsAtom] = useRecoilState(settingsAtomState);
  const [clientsAtom] = useRecoilState(clientsAtomState);

  // LOCAL STATE
  const [recurring, setRecurring] = useState(false)
  const [selectedClient, setSelectedClient] = useState("");
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [sendMode, setSendMode] = useState(false);
  const [openSentBlob, setOpenSentBlob] = useState(false);
  const [openInvoiceNumberEdit, setOpenInvoiceNumberEdit] = useState(false);
  const [addElementOpen, setAddElementOpen] = useState(false);
  const [, setShowGlobalDiscount] = useState(false);
  const [currentItem, setCurrentItem] = useState({
    i: false,
    name: "",
    quantity: 1,
    price: 0,
    unit: "",
    vatPercent: "",
    discount: "none",
    discountPercent: 0,
    discountAbsolute: 0,
  });
  const [settings, setSettings] = useState(settingsAtom);
  const [values, setValues] = useState({
    invoiceId: "",
    vat: {
      state: "",
      digits: 0,
      code: "",
      error: false,
      errorMsg: t("FIELDS.vat.error"),
    },
    vatPercent: {
      state: "",
    },
    image: {
      state: "",
    },
    showInvoiceNumber: settingsAtom.invoiceNumber === "manual",
    initialInvoiceNumber: "",
    invoiceNumber: {
      state: [],
      error: false,
      errorMsg: t("FIELDS.invoiceNumber.errorMissing"),
    },
    invoiceNumberValues: {
      invoicesLength: 0,
      arrInvoiceNumbers: [0],
      missingInvoiceumbers: [],
    },
    status: {
      state: "draft",
    },
    loading: true,
    isLoading: true,
    invoiceDate: new Date(),
    sendDate: new Date(),
    dueDate: new Date(),
    payDate: new Date(),
    subtotal: 0,
    vatValues: {},
    total: 0,
    discount: "none",
    discountAbsolute: 0,
    discountPercent: 0,
    discountValue: 0,
    discountedSubtotal: 0,
    discountedTotal: 0,
    sent: false,
    recurring: "no",
    headline: {
      state: "",
    },
    text: {
      state: "",
    },
    sendState: {
      state: "notSent",
    },
    payState: {
      state: "notPayed",
    },
    blob: "",
    sentBlobs: [],
    type: "invoice",
    reminderRequestCharge: "0",
    showVat: false,
    showSignature: false,
    showAdditionalText: false,
    additionalText: "",
  });
  const [buttonLoadingAction, setButtonLoadingAction] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [openPicker, setOpenPicker] = useState(false);

  // REF
  const textFieldRef = useRef(null);

  // CUSTOM HOOKS
  // Initial values when opening the dialog
  useInvoiceSetup(invoicesAtom, settingsAtom, openInvoiceDialogAtom, clientsAtom, setValues, setSelectedClient, setItems, setShowGlobalDiscount, values);
  // Calculate invoice values (net, gross, etc.)
  useInvoiceCalculations(items, values, setValues, selectedClient);
  // Updating the continuous number
  useUpdateContinuousNumber(settingsAtom, values, setSettings, settings);
  // Validating the whole invoice number
  useInvoiceNumberValidation(values, setValues, selectedClient, openInvoiceDialogAtom, invoicesAtom);

  /********************* 
    Component Functions
  **********************/

  // SAVE INVOICE
  const invoiceHandler = (event, sendMode, cancelSchedule) => {
    invoiceSave(
      event,
      sendMode,
      cancelSchedule,
      openInvoiceDialogAtom,
      setSendMode,
      setOpen,
      values,
      setValues,
      invoicesAtom,
      selectedClient,
      items,
      userId,
      auth,
      sendRequest,
      setOpenInvoiceDialogAtom,
      setSettingsAtom,
      setInvoicesAtom,
      setInvoicesFilteredAtom,
      settings,
      t
    );
  };

  // CHANGE STATUS
  const handleChangeStatus = async (e) => {
    clearError();
    SET_VALUES(e, values, setValues);
  };
  
  // OPEN DATE PICKER
  const handleOpenPicker = () => {
    setOpenPicker(true);
  };

  // INVOICE NUMBER MENU DOTS
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openInvoiceNrMenuDots = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <InvoiceAppBar 
        setOpenInvoiceDialogAtom={setOpenInvoiceDialogAtom} 
        openInvoiceDialogAtom={openInvoiceDialogAtom} 
        selectedClient={selectedClient} 
        isLoading={isLoading} 
        setSendMode={setSendMode} 
        setOpen={setOpen} 
      />

      <InvoiceAlerts 
        selectedClient={selectedClient} 
        settings={settings} 
        values={values} 
        openInvoiceDialogAtom={openInvoiceDialogAtom} 
        invoiceHandler={invoiceHandler} 
        setShowConfirmModal={setShowConfirmModal} 
      />

      <InvoiceBottomNavigation 
        selectedClient={selectedClient} 
        settings={settings} 
        values={values} 
        isLoading={isLoading} 
        buttonLoadingAction={buttonLoadingAction} 
        invoiceHandler={invoiceHandler} 
        setButtonLoadingAction={setButtonLoadingAction} 
        openInvoiceDialogAtom={openInvoiceDialogAtom} 
      />

      <React.Fragment>
        <div>
          <div className="invoice-edit">
            <div className={classes.toolbar} />
            {(selectedClient !== "" && settings.invoicesEmailServer !== "download" && openInvoiceDialogAtom.invoiceId !== "") &&
              <div className={classes.toolbar} />
            }
            <Container maxWidth={false} disableGutters className="bg--1">
              <Container maxWidth="lg" style={{ paddingTop: "25px", paddingBottom: "100px" }}>
                {!values.loading &&
                  <>
                    <form onSubmit={invoiceHandler}>
                      <Box display="flex"
                        flexDirection="column"
                        margin="0 25px"
                        className="h-gap--25"
                        style={{ minHeight: "100vh" }}
                      >
                        <SentBlobsHistory
                          selectedClient={selectedClient}
                          settings={settings}
                          values={values}
                          openInvoiceDialogAtom={openInvoiceDialogAtom}
                          isLoading={isLoading}
                          openSentBlob={openSentBlob}
                          setOpenSentBlob={setOpenSentBlob}
                        />

                        <Grid container item xs={12} lg={12} justifyContent="center">
                          <Typography variant="h6">{values.headline.state}</Typography>
                        </Grid>

                        <Box
                          display="flex"
                          flexDirection="column"
                          // padding="25px 0px"
                          className="h-gap--20"
                        >
                          <Grid
                            container
                            spacing={1}
                            direction="row"
                            alignContent="flex-start"
                            alignItems="flex-start"
                            className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                          >
                            <Grid item xs={12} md={4}>
                              <FormControl variant="outlined" fullWidth className={classes.formControl} disabled={isLoading}>
                                <InputLabel id="demo-simple-select-outlined-label">Typ</InputLabel>
                                <Select
                                  labelId="type"
                                  id="type"
                                  value={values.type}
                                  onChange={(e) => setValues({
                                    ...values,
                                    type: e.target.value,
                                    headline: {
                                      ...values.headline,
                                      state: e.target.value === "invoice" ? "Rechnung"
                                        : e.target.value === "reminder" ? "Zahlungserinnerung"
                                          : e.target.value === "reminderRequest" ? "Mahnung"
                                            : e.target.value === "estimate" ? "Kostenvoranschlag"
                                              : "Angebot"
                                    },
                                    text: {
                                      ...values.text,
                                      state: e.target.value === "invoice" ? `Wir erlauben uns, Ihnen folgenden Betrag in Rechnung zu stellen und freuen uns auf weitere erfolgreiche Zusammenarbeit.`
                                        : e.target.value === "reminder" ? "Wir möchten Sie freundlich daran erinnern, dass diese Rechnung noch nicht beglichen wurde. Wir ersuchen höflichst um Bezahlung des offenen Betrags. Sofern Sie die Zahlung zwischenzeitlich veranlasst haben, bitten wir Sie, dieses Schreiben als gegenstandslos zu betrachten."
                                          : e.target.value === "reminderRequest" ? "Für diese Rechnung konnten wir noch keinen Zahlungseingang feststellen. Wir ersuchen um Bezahlung des offenen Betrags. Sofern Sie die Zahlung zwischenzeitlich veranlasst haben, bitten wir Sie, dieses Schreiben als gegenstandslos zu betrachten."
                                            : e.target.value === "estimate" ? "Vielen Dank für Ihr Interesse an unseren Leistungen. Im Folgenden finden Sie einen detaillierten Kostenvoranschlag, der speziell auf Ihre Anforderungen zugeschnitten ist."
                                              : "Wir freuen uns, Ihnen dieses Angebot auf Basis Ihrer angefragten Leistungen unterbreiten zu dürfen."
                                    },
                                    reminderRequestCharge: 0,
                                  })}
                                  label="Typ"
                                >
                                  {openInvoiceDialogAtom.isEstimate && <MenuItem value={"estimate"}>Kostenvoranschlag</MenuItem>}
                                  {openInvoiceDialogAtom.isEstimate && <MenuItem value={"offer"}>Angebot</MenuItem>}
                                  {!openInvoiceDialogAtom.isEstimate && <MenuItem value={"invoice"}>Rechnung</MenuItem>}
                                  {!openInvoiceDialogAtom.isEstimate && <MenuItem value={"reminder"}>Zahlungserinnerung</MenuItem>}
                                  {!openInvoiceDialogAtom.isEstimate && <MenuItem value={"reminderRequest"}>Mahnung</MenuItem>}
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} md={8}>
                              <Autocomplete
                                disabled={isLoading}
                                style={{ width: "100%" }}
                                required
                                id="client"
                                onChange={(event, value) => (setSelectedClient(value), setCurrentItem({ ...currentItem, vatPercent: value.vatPercent }))}
                                disableClearable
                                defaultValue={selectedClient}
                                value={selectedClient || null}
                                getOptionSelected={(option, value) => option.company === value.company} // prevents infinite loop!!!
                                options={clientsAtom}
                                classes={{
                                  option: classes.option,
                                }}
                                autoHighlight
                                getOptionLabel={(option) => option.company}
                                renderOption={(option) => (
                                  <>
                                    <>
                                      {option.logo !== "" && (
                                        <div style={{ width: "50px", height: "25px", marginRight: "10px", position: "relative" }}>
                                          <img
                                            src={option.logo}
                                            alt="logo"
                                            style={{
                                              maxWidth: "100%",
                                              maxHeight: "100%",
                                              marginRight: "10px",
                                              position: "absolute",
                                              top: "50%",
                                              left: "50%",
                                              transform: "translate(-50%, -50%)"
                                            }}
                                          />
                                        </div>
                                      )}
                                      {option.logo === "" && (
                                        <img src={LogoMonogram} height={25} width={25} alt={`${t("APP.name")} Logo`} style={{ width: "50px", marginRight: "10px", opacity: 0.2 }} />
                                      )}
                                    </>
                                    <>{option.company}</>
                                  </>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    required
                                    label="Kund:in"
                                    placeholder="Unternehmen auswählen"
                                    variant="outlined"
                                    InputLabelProps={{
                                      shrink: true,
                                      style: {
                                        background: 'rgb(240, 243, 255)',
                                        padding: "2px 5px",
                                        marginLeft: "-2px",
                                        borderRadius: "6px"
                                      }
                                    }}
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "new-password", // disable autocomplete and autofill
                                      // style: { color: 'red' }
                                    }}
                                    InputProps={{
                                      ...params.InputProps,
                                      startAdornment: (
                                        <>
                                          {(selectedClient.logo !== "" && selectedClient.logo !== undefined) &&
                                            <div style={{ width: "50px", height: "25px", marginRight: "10px", position: "relative" }}>
                                              <img
                                                src={`${selectedClient.logo}`}
                                                alt="logo"
                                                style={{
                                                  maxWidth: "100%",
                                                  maxHeight: "100%",
                                                  marginRight: "10px",
                                                  position: "absolute",
                                                  top: "50%",
                                                  left: "50%",
                                                  transform: "translate(-50%, -50%)"
                                                }}
                                              />
                                            </div>
                                          }
                                        </>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </Grid>

                            {selectedClient !== "" &&
                              <InvoiceNumber
                                values={values}
                                isLoading={isLoading}
                                handleOpenPicker={handleOpenPicker}
                                changeContinuousNumber={changeContinuousNumber}
                                handleClick={handleClick}
                                anchorEl={anchorEl}
                                openInvoiceNrMenuDots={openInvoiceNrMenuDots}
                                handleClose={handleClose}
                                addElementOpen={addElementOpen}
                                setAddElementOpen={setAddElementOpen}
                                setValues={setValues}
                                setOpenInvoiceNumberEdit={setOpenInvoiceNumberEdit}
                                openInvoiceDialogAtom={openInvoiceDialogAtom}
                              />
                            }

                            {selectedClient !== "" &&
                              <MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
                                <Grid item xs={12} md={3} className="datepicker">
                                  <KeyboardDatePicker
                                    disabled={isLoading}
                                    fullWidth
                                    inputVariant="filled"
                                    id="invoiceDate"
                                    format="dd.MM.yyyy"
                                    label={(values.type === "invoice" || values.type === "reminder" || values.type === "reminderRequest") ? "Rechnungsdatum" : "Datum"}
                                    value={values.invoiceDate}
                                    // onChange={(date) => {
                                    //   setValues({
                                    //     ...values,
                                    //     invoiceDate: date,
                                    //     invoiceNumber: {
                                    //       ...values.invoiceNumber,
                                    //       state: changeInvoiceNumberDate(date),
                                    //     },
                                    //   });
                                    // }}
                                    onChange={(date) =>
                                      setValues({
                                        ...values,
                                        invoiceDate: date,
                                      })
                                    }
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                    }}
                                    okLabel="OK"
                                    cancelLabel="Abbrechen"
                                    open={openPicker}
                                    onOpen={() => setOpenPicker(true)}
                                    onClose={() => setOpenPicker(false)}
                                  />
                                </Grid>

                                {!openInvoiceDialogAtom.isEstimate &&
                                  <Grid item xs={12} md={3} className="datepicker">
                                    <KeyboardDatePicker
                                      disabled={isLoading}
                                      fullWidth
                                      inputVariant="filled"
                                      id="dueDate"
                                      format="dd.MM.yyyy"
                                      label="Fälligkeitsdatum"
                                      value={values.dueDate}
                                      onChange={(date) =>
                                        setValues({
                                          ...values,
                                          dueDate: date,
                                        })
                                      }
                                      KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                      }}
                                      okLabel="OK"
                                      cancelLabel="Abbrechen"
                                    />
                                  </Grid>
                                }
                              </MuiPickersUtilsProvider>
                            }

                            {(!openInvoiceDialogAtom.isEstimate && selectedClient !== "" && settings.invoicesEmailServer === "download") &&
                              <Grid container item xs={12} md={3}>
                                <FormControl variant="filled" id="status" className={classes.formControl} fullWidth>
                                  <InputLabel id="status">Versandstatus</InputLabel>
                                  <Select
                                    disabled={isLoading}
                                    labelId="sendState"
                                    id="sendState"
                                    name="sendState"
                                    value={values.sendState.state}
                                    onChange={handleChangeStatus}
                                    className="select-status"
                                  >
                                    <MenuItem value={"notSent"}>
                                      <ListItemIcon style={{ minWidth: "auto", marginRight: "5px" }}>
                                        <WarningIcon style={{ color: theme.palette.standard.main }} />
                                      </ListItemIcon>
                                      <ListItemText primary={"Noch nicht versendet"} />
                                    </MenuItem>

                                    <MenuItem value={"sent"}>
                                      <ListItemIcon style={{ minWidth: "auto", marginRight: "5px" }}>
                                        <MailIcon style={{ color: theme.palette.primary.dark }} />
                                      </ListItemIcon>
                                      <ListItemText primary={"Gesendet"} />
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                            }



                            {(!openInvoiceDialogAtom.isEstimate && selectedClient !== "") &&
                              <Grid container item xs={12} md={3}>
                                <FormControl variant="filled" id="status" style={{ border: values.payState.state === "payed" ? "2px solid rgb(76, 175, 80)" : "inherit" }} className={classes.formControl} fullWidth>
                                  <InputLabel id="status">Bezahlstatus</InputLabel>
                                  <Select
                                    disabled={isLoading}
                                    labelId="payState"
                                    id="payState"
                                    name="payState"
                                    defaultValue={values.payState.state}
                                    value={values.payState.state}
                                    onChange={handleChangeStatus}
                                    className="select-status"
                                  >
                                    <MenuItem value={"notPayed"}>
                                      <ListItemIcon style={{ minWidth: "auto", }}>
                                        <WarningIcon style={{ color: theme.palette.standard.main }} />
                                      </ListItemIcon>
                                      <ListItemText primary={"Noch nicht bezahlt"} />
                                    </MenuItem>

                                    <MenuItem value={"payed"} onClick={() =>
                                      // If an invoice is payed, it has to have been sent before
                                      // So if the sendState is still "notSent", automatically change it to "sent"
                                      // If the payState is changed to "payed"
                                      setValues({
                                        ...values,
                                        payState: {
                                          ...values.payState,
                                          state: "payed",
                                        },
                                      })
                                    }>
                                      <ListItemIcon style={{ minWidth: "auto", marginRight: "5px" }}>
                                        <CheckCircleIcon style={{ color: theme.palette.success.light }} />
                                      </ListItemIcon>
                                      <ListItemText primary={"Bezahlt"} />
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                            }

                            {(selectedClient !== "" && values.payState.state === "payed") &&
                              <MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
                                <Grid item xs={12} md={3} className="datepicker" >
                                  <KeyboardDatePicker
                                    disabled={isLoading}
                                    style={{ border: values.payState.state === "payed" ? "2px solid rgb(76, 175, 80)" : "inherit" }}
                                    fullWidth
                                    inputVariant="filled"
                                    id="payDate"
                                    format="dd.MM.yyyy"
                                    label="Zahldatum"
                                    value={values.payDate}
                                    onChange={(date) =>
                                      setValues({
                                        ...values,
                                        payDate: date,
                                      })
                                    }
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                    }}
                                    okLabel="OK"
                                    cancelLabel="Abbrechen"
                                  />
                                </Grid>
                              </MuiPickersUtilsProvider>
                            }
                          </Grid>
                        </Box>

                        {/* Items */}
                        {selectedClient &&
                          <Box
                            display="flex"
                            flexDirection="column"
                            // padding="25px 0px"
                            className="h-gap--20"
                          >
                            <Grid
                              container
                              spacing={1}
                              direction="row"
                              alignContent="flex-start"
                              alignItems="flex-start"
                              className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                            >

                              {selectedClient &&
                                <Grid item xs={12} style={{ marginBottom: "20px" }}>
                                  <TextField
                                    disabled={isLoading}
                                    multiline
                                    autoComplete="off"
                                    defaultValue={values.headline.state}
                                    value={values.headline.state}
                                    id="headline"
                                    label={`Überschrift`}
                                    variant="outlined"
                                    type="textarea"
                                    onChange={handleChangeStatus}
                                    fullWidth
                                  />
                                </Grid>
                              }

                              {selectedClient &&
                                <Grid item xs={12} style={{ marginBottom: "20px" }}>
                                  <TextField
                                    disabled={isLoading}
                                    multiline
                                    autoComplete="off"
                                    value={values.text.state}
                                    id="text"
                                    label={`Einleitungstext`}
                                    variant="outlined"
                                    type="textarea"
                                    onChange={handleChangeStatus}
                                    fullWidth
                                  />
                                </Grid>
                              }

                              <Grid container item lg={12} spacing={1} >
                                <Grid container item spacing={1}>
                                  <Grid item container xs={12} justifyContent="flex-end" alignItems="center">
                                    <Grid item xs={12}>
                                      <div className={classes.root}>
                                        {items.map((item, i) => (
                                          <Accordion square expanded={isLoading ? false : i + 1 === currentItem.i ? (i + 1) : false} className="accordion" disableGutters={true}
                                            disabled={isLoading}
                                            TransitionProps={{ timeout: { appear: 1, enter: 1, exit: 4 } }}>
                                            <AccordionSummary
                                              key={i + 1}
                                              onClick={
                                                () => setCurrentItem({
                                                  ...currentItem,
                                                  i: i + 1 !== currentItem.i ? i + 1 : false,
                                                  name: i + 1 !== currentItem.i ? item.name : "",
                                                  price: i + 1 !== currentItem.i ? item.price : 0,
                                                  quantity: i + 1 !== currentItem.i ? item.quantity : 1,
                                                  unit: i + 1 !== currentItem.i ? item.unit : "",
                                                  vatPercent: i + 1 !== currentItem.i ? item.vatPercent : "",
                                                  discount: i + 1 !== currentItem.i ? item.discount : "none",
                                                  discountAbsolute: i + 1 !== currentItem.i ? item.discountAbsolute : 0,
                                                  discountPercent: i + 1 !== currentItem.i ? item.discountPercent : 0,
                                                })
                                              }
                                              expandIcon={
                                                <EditIcon className="edit-icon"
                                                  onClick={
                                                    () => setCurrentItem({
                                                      ...currentItem,
                                                      i: i + 1 !== currentItem.i ? i + 1 : false,
                                                      name: i + 1 !== currentItem.i ? item.name : "",
                                                      price: i + 1 !== currentItem.i ? item.price : 0,
                                                      quantity: i + 1 !== currentItem.i ? item.quantity : 1,
                                                      unit: i + 1 !== currentItem.i ? item.quantity : "",
                                                      vatPercent: i + 1 !== currentItem.i ? item.vatPercent : "",
                                                      discount: i + 1 !== currentItem.i ? item.discount : "none",
                                                      discountAbsolute: i + 1 !== currentItem.i ? item.discountAbsolute : 0,
                                                      discountPercent: i + 1 !== currentItem.i ? item.discountPercent : 0,
                                                    })
                                                  }
                                                />}
                                              style={{ borderBottom: "none" }}
                                              aria-controls={item.name}
                                              id={item.name}
                                            >
                                              <Grid container item xs={12} spacing={1} style={{ alignItems: "center" }}>
                                                <Grid item>
                                                  <DragIcon className="drag-icon" fontSize="small" />
                                                </Grid>

                                                <Grid container item xs={12} md={11} spacing={1}>
                                                  <Grid container item xs={9} md={9} alignItems="center">
                                                    <Typography style={{ fontSize: "13px", fontWeight: 500 }}>{i + 1 !== currentItem.i ? item.name : currentItem.name}</Typography>
                                                  </Grid>

                                                  <Grid container item xs={12} md={3} justifyContent="flex-end" alignItems="center" className="price">
                                                    <Typography style={{ fontWeight: 500, fontSize: "13px", }}>{parseFloat(item.total).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €</Typography>
                                                  </Grid>
                                                </Grid>
                                              </Grid>

                                            </AccordionSummary>

                                            {currentItem.i !== 0 &&
                                              <AccordionDetails>
                                                <Grid
                                                  container
                                                  item
                                                  xs={12}
                                                >
                                                  <Grid
                                                    container
                                                    item
                                                    lg={12}
                                                    spacing={1}
                                                  >
                                                    <Grid container item lg={12}>
                                                      <TextField
                                                        multiline
                                                        autoComplete='off'
                                                        className="input-white"
                                                        id="name"
                                                        label="Leistung"
                                                        variant="outlined"
                                                        type="text"
                                                        value={currentItem.name}
                                                        onChange={
                                                          (e) => setCurrentItem({
                                                            ...currentItem,
                                                            name: e.target.value,
                                                          })
                                                        }
                                                        onBlur={(e) => {
                                                          changeItem(e, currentItem.i, items, setItems);
                                                        }}
                                                        fullWidth
                                                        InputLabelProps={{
                                                          classes: {
                                                            root: classes.cssLabel,
                                                            focused: classes.cssFocused,
                                                          },
                                                        }}
                                                        InputProps={{
                                                          classes: {
                                                            root: classes.cssOutlinedInput,
                                                            focused: classes.cssFocused,
                                                            notchedOutline: classes.notchedOutline,
                                                          },
                                                        }}
                                                      />
                                                    </Grid>

                                                    <Grid container item md={6} lg={2}>
                                                      <NumericFormat
                                                        fullWidth
                                                        className="input-white"
                                                        id="price"
                                                        label="Preis (netto)"
                                                        variant="outlined"
                                                        value={currentItem.price}
                                                        prefix="€ "
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        valueIsNumericString
                                                        customInput={TextField}
                                                        onValueChange={({ value: v }) =>
                                                          setCurrentItem({
                                                            ...currentItem,
                                                            price: v,
                                                          })
                                                        }
                                                        onBlur={(e) => {
                                                          changeItem(
                                                            {
                                                              target: { value: currentItem.price, id: "price" },
                                                            },
                                                            currentItem.i,
                                                            items,
                                                            setItems
                                                          )
                                                        }}
                                                      />
                                                    </Grid>

                                                    <Grid container item md={6} lg={2}>
                                                      <TextField
                                                        id="unit"
                                                        label="Einheit"
                                                        variant="outlined"
                                                        type="text"
                                                        placeholder="h/kg/cm etc."
                                                        value={currentItem.unit}
                                                        onChange={(e) =>
                                                          setCurrentItem({
                                                            ...currentItem,
                                                            unit: e.target.value,
                                                          })
                                                        }
                                                        onBlur={(e) => {
                                                          changeItem(e, currentItem.i, items, setItems);
                                                        }}
                                                        fullWidth
                                                        InputLabelProps={{
                                                          classes: {
                                                            root: classes.cssLabel,
                                                            focused: classes.cssFocused,
                                                          },
                                                        }}
                                                        InputProps={{
                                                          classes: {
                                                            root: classes.cssOutlinedInput,
                                                            focused: classes.cssFocused,
                                                            notchedOutline: classes.notchedOutline,
                                                          },
                                                        }}
                                                      />
                                                    </Grid>

                                                    <Grid container item md={6} lg={2}>
                                                      <NumericFormat
                                                        disabled={currentItem.unit === ""}
                                                        fullWidth
                                                        className="input-white"
                                                        id="quantity"
                                                        label="Menge"
                                                        variant="outlined"
                                                        value={currentItem.quantity}
                                                        // prefix="€ "
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        valueIsNumericString
                                                        customInput={TextField}
                                                        onValueChange={({ value: v }) =>
                                                          setCurrentItem({
                                                            ...currentItem,
                                                            quantity: v,
                                                          })
                                                        }
                                                        onBlur={(e) => {
                                                          changeItem(
                                                            {
                                                              target: { value: currentItem.quantity, id: "quantity" },
                                                            },
                                                            currentItem.i,
                                                            items,
                                                            setItems
                                                          )
                                                        }}
                                                      />
                                                    </Grid>

                                                    <Grid container item md={6} lg={2}>
                                                      <NumericFormat
                                                        fullWidth
                                                        className="input-white"
                                                        id="vatPercent"
                                                        label="USt."
                                                        variant="outlined"
                                                        value={currentItem.vatPercent !== "" ? currentItem.vatPercent : selectedClient.vatPercent !== "" ? selectedClient.vatPercent : "0"}
                                                        decimalScale={0}
                                                        valueIsNumericString
                                                        customInput={TextField}
                                                        InputProps={{
                                                          endAdornment: <InputAdornment position="end">
                                                            <span style={{ color: "inherit" }}>%</span>
                                                          </InputAdornment>,
                                                          classes: {
                                                            root: classes.cssOutlinedInput,
                                                            focused: classes.cssFocused,
                                                            notchedOutline: classes.notchedOutline,
                                                          },
                                                        }}
                                                        onValueChange={({ value: v }) =>
                                                          setCurrentItem({
                                                            ...currentItem,
                                                            vatPercent: v,
                                                          })
                                                        }
                                                        onBlur={(e) => {
                                                          changeItem(
                                                            {
                                                              target: { value: currentItem.vatPercent, id: "vatPercent" },
                                                            },
                                                            currentItem.i,
                                                            items,
                                                            setItems
                                                          )
                                                        }}
                                                      />
                                                    </Grid>

                                                    {currentItem.discount === "none" &&
                                                      <Grid container item md={6} lg={4}>
                                                        <FormControl variant="outlined" style={{ width: "100%", minWidth: "100%" }}>
                                                          <InputLabel id="discount">Rabatt</InputLabel>
                                                          <Select
                                                            labelId="discount"
                                                            id="discount"
                                                            name="discount"
                                                            value={currentItem.discount}
                                                            onChange={(e) => {
                                                              setCurrentItem({
                                                                ...currentItem,
                                                                discount: e.target.value,
                                                              });
                                                              changeItem(e, currentItem.i, items, setItems);
                                                            }
                                                            }
                                                            label="Rabatt"
                                                            fullWidth
                                                          >
                                                            <MenuItem value={"none"}>Kein Rabatt</MenuItem>
                                                            <MenuItem value={"absolute"}>Rabatt in €</MenuItem>
                                                            <MenuItem value={"percent"}>Rabatt in %</MenuItem>
                                                          </Select>
                                                        </FormControl>
                                                      </Grid>
                                                    }

                                                    {currentItem.discount === "absolute" &&
                                                      <Grid container item md={6} lg={4}>
                                                        <NumericFormat
                                                          required
                                                          id="discountAbsolute"
                                                          label="Rabatt in €"
                                                          variant="outlined"
                                                          value={currentItem.discountAbsolute}
                                                          prefix="€ "
                                                          thousandSeparator={"."}
                                                          decimalSeparator={","}
                                                          decimalScale={2}
                                                          valueIsNumericString
                                                          fixedDecimalScale
                                                          customInput={TextField}
                                                          InputLabelProps={{
                                                            classes: {
                                                              root: classes.cssLabel,
                                                              focused: classes.cssFocused,
                                                            },
                                                          }}

                                                          InputProps={{
                                                            classes: {
                                                              root: classes.cssOutlinedInput,
                                                              focused: classes.cssFocused,
                                                              notchedOutline: classes.notchedOutline,
                                                            },
                                                            endAdornment: <InputAdornment position="end" style={{ height: "100%" }}>
                                                              <div style={{ borderLeft: "1px solid #d7d7d7de", height: "100%", marginLeft: "15px", display: "flex" }}>
                                                                <IconButton
                                                                  style={{ marginLeft: "5px" }}
                                                                  aria-label="delete discount"
                                                                  onClick={(e) => {
                                                                    setCurrentItem({
                                                                      ...currentItem,
                                                                      discount: "none",
                                                                      discountAbsolute: 0,
                                                                      discountPercent: 0
                                                                    });
                                                                    changeItem(
                                                                      {
                                                                        target: { value: "none", id: "discount" },
                                                                      },
                                                                      currentItem.i,
                                                                      items,
                                                                      setItems
                                                                    );
                                                                  }}
                                                                >
                                                                  <DeleteForeverIcon fontSize="small" />
                                                                </IconButton>
                                                              </div>
                                                            </InputAdornment>
                                                          }}
                                                          onValueChange={({ value: v }) =>
                                                            setCurrentItem({
                                                              ...currentItem,
                                                              discountAbsolute: v,
                                                            })
                                                          }
                                                          onBlur={(e) => {
                                                            changeItem(
                                                              {
                                                                target: { value: currentItem.discountAbsolute, id: "discountAbsolute" },
                                                              },
                                                              currentItem.i,
                                                              items,
                                                              setItems
                                                            )
                                                          }}
                                                        />
                                                      </Grid>
                                                    }

                                                    {currentItem.discount === "percent" &&
                                                      <Grid container item md={6} lg={4}>
                                                        <NumericFormat
                                                          required
                                                          id="discountPercent"
                                                          label="Rabatt in %"
                                                          variant="outlined"
                                                          value={currentItem.discountPercent}
                                                          decimalScale={0}
                                                          valueIsNumericString
                                                          fixedDecimalScale
                                                          customInput={TextField}
                                                          InputLabelProps={{
                                                            classes: {
                                                              root: classes.cssLabel,
                                                              focused: classes.cssFocused,
                                                            },
                                                          }}

                                                          InputProps={{
                                                            classes: {
                                                              root: classes.cssOutlinedInput,
                                                              focused: classes.cssFocused,
                                                              notchedOutline: classes.notchedOutline,
                                                            },
                                                            endAdornment: <InputAdornment position="end" style={{ height: "100%" }}>
                                                              <span style={{ color: "inherit" }}>%</span>
                                                              <div style={{ borderLeft: "1px solid #d7d7d7de", height: "100%", marginLeft: "15px", display: "flex" }}>
                                                                <IconButton
                                                                  style={{ marginLeft: "5px" }}
                                                                  aria-label="delete discount"
                                                                  onClick={(e) => {
                                                                    setCurrentItem({
                                                                      ...currentItem,
                                                                      discount: "none",
                                                                      discountAbsolute: 0,
                                                                      discountPercent: 0
                                                                    });
                                                                    changeItem(
                                                                      {
                                                                        target: { value: "none", id: "discount" },
                                                                      },
                                                                      currentItem.i,
                                                                      items,
                                                                      setItems
                                                                    );
                                                                  }}
                                                                >
                                                                  <DeleteForeverIcon fontSize="small" />
                                                                </IconButton>
                                                              </div>
                                                            </InputAdornment>
                                                          }}
                                                          onValueChange={({ value: v }) =>
                                                            setCurrentItem({
                                                              ...currentItem,
                                                              discountPercent: v,
                                                            })
                                                          }
                                                          onBlur={(e) => {
                                                            changeItem(
                                                              {
                                                                target: { value: currentItem.discountPercent, id: "discountPercent" },
                                                              },
                                                              currentItem.i,
                                                              items,
                                                              setItems
                                                            )
                                                          }}
                                                        />
                                                      </Grid>
                                                    }
                                                  </Grid>

                                                  <Grid container item xs={12} justifyContent="flex-end">
                                                    <Grid item>
                                                      <Button
                                                        size="small"
                                                        className={classes.button}
                                                        startIcon={<DeleteForeverIcon />}
                                                        style={{ marginTop: "10px", marginBottom: "5px" }}
                                                        onClick={() => {
                                                          deleteItem(i, items, setItems);
                                                          setCurrentItem({
                                                            ...currentItem,
                                                            i: false,
                                                            name: "",
                                                            price: 0,
                                                            quantity: 1,
                                                            unit: "",
                                                            vatPercent: "",
                                                          })
                                                        }}
                                                      >
                                                        Leistung löschen
                                                      </Button>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                              </AccordionDetails>
                                            }
                                          </Accordion>
                                        ))}
                                      </div>
                                    </Grid>
                                  </Grid>

                                  <Grid container item lg={12} spacing={1}>
                                    <Button
                                      disabled={isLoading}
                                      variant="outlined"
                                      size="large"
                                      color="primary"
                                      style={{ marginLeft: "5px", marginTop: "5px", marginBottom: "10px", textTransform: "none" }}
                                      startIcon={<AddCircleIcon />}
                                      onClick={() => addItem(items, setItems, currentItem, setCurrentItem)}
                                    >
                                      Leistung hinzfügen
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>
                        }

                        {/* Global discount */}
                        {selectedClient &&
                          <Box
                            display="flex"
                            flexDirection="column"
                            className="h-gap--20"
                          >
                            <Grid
                              container
                              spacing={1}
                              direction="row"
                              alignContent="flex-start"
                              alignItems="flex-start"
                              className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                            >
                              <Grid container item lg={12} spacing={3} >
                                {values.discount === "none" &&
                                  <Grid container item lg={4}>

                                    <FormControl disabled={isLoading} variant="outlined" style={{ width: "100%", minWidth: "100%" }}>
                                      <InputLabel id="discount">Rabatt auf Gesamtbetrag (netto)</InputLabel>
                                      <Select
                                        labelId="discount"
                                        id="discount"
                                        name="discount"
                                        value={values.discount}
                                        onChange={(e) => setValues({ ...values, discount: e.target.value })}
                                        label="Rabatt auf Gesamtrechnung"
                                        fullWidth
                                      >

                                        <MenuItem value={"none"}>Kein Rabatt</MenuItem>
                                        <MenuItem value={"absolute"}>Rabatt in €</MenuItem>
                                        <MenuItem value={"percent"}>Rabatt in %</MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                }

                                {values.discount === "absolute" &&

                                  <Grid container item lg={4}>
                                    <NumericFormat
                                      disabled={isLoading}
                                      required
                                      id="discountAbsolute"
                                      label="Rabatt in €"
                                      variant="outlined"
                                      value={values.discountAbsolute}
                                      prefix="€ "
                                      thousandSeparator={"."}
                                      decimalSeparator={","}
                                      decimalScale={2}
                                      valueIsNumericString
                                      fixedDecimalScale
                                      customInput={TextField}
                                      InputLabelProps={{
                                        classes: {
                                          root: classes.cssLabel,
                                          focused: classes.cssFocused,
                                        },
                                      }}
                                      onValueChange={({ value: v }) =>
                                        setValues({
                                          ...values,
                                          discountAbsolute: v
                                        })
                                      }

                                      InputProps={{
                                        classes: {
                                          root: classes.cssOutlinedInput,
                                          focused: classes.cssFocused,
                                          notchedOutline: classes.notchedOutline,
                                        },
                                        endAdornment: <InputAdornment position="end" style={{ height: "100%" }}>
                                          <div style={{ borderLeft: "1px solid #d7d7d7de", height: "100%", marginLeft: "15px", display: "flex" }}>
                                            <IconButton
                                              style={{ marginLeft: "5px" }}
                                              aria-label="delete discount"
                                              onClick={() => setValues({
                                                ...values,
                                                discount: "none",
                                                discountAbsolute: "0"
                                              })}
                                              disabled={isLoading}
                                            >
                                              <DeleteForeverIcon fontSize="small" />
                                            </IconButton>
                                          </div>
                                        </InputAdornment>
                                      }}
                                    />

                                  </Grid>
                                }

                                {values.discount === "percent" &&

                                  <Grid container item lg={4}>
                                    <TextField
                                      disabled={isLoading}
                                      label="Rabatt in %"
                                      id="discountPercent"
                                      variant="outlined"
                                      value={values.discountPercent}
                                      onChange={(e) =>
                                        setValues({
                                          ...values,
                                          discountPercent: e.target.value
                                        })
                                      }
                                      InputLabelProps={{
                                        classes: {
                                          root: classes.cssLabel,
                                          focused: classes.cssFocused,
                                        },
                                      }}
                                      InputProps={{
                                        classes: {
                                          root: classes.cssOutlinedInput,
                                          focused: classes.cssFocused,
                                          notchedOutline: classes.notchedOutline,
                                        },
                                        endAdornment: <InputAdornment position="end" style={{ height: "100%" }}>
                                          <span style={{ color: "inherit" }}>%</span>
                                          <div style={{ borderLeft: "1px solid #d7d7d7de", height: "100%", marginLeft: "15px", display: "flex" }}>
                                            <IconButton
                                              style={{ marginLeft: "5px" }}
                                              aria-label="delete discount"
                                              onClick={() => setValues({
                                                ...values,
                                                discount: "none",
                                                discountPercent: 0
                                              })}
                                              disabled={isLoading}
                                            >
                                              <DeleteForeverIcon fontSize="small" />
                                            </IconButton>
                                          </div>
                                        </InputAdornment>
                                      }}

                                    />
                                  </Grid>

                                }
                              </Grid>

                              {values.type === "reminderRequest" &&
                                <Grid container item lg={4} spacing={2} >
                                  <Grid container item >
                                    <NumericFormat
                                      disabled={isLoading}
                                      id="reminderRequestCharge"
                                      label="Mahnspesen"
                                      variant="outlined"
                                      value={values.reminderRequestCharge}
                                      prefix="€ "
                                      thousandSeparator={"."}
                                      decimalSeparator={","}
                                      decimalScale={2}
                                      valueIsNumericString
                                      fixedDecimalScale
                                      customInput={TextField}
                                      fullWidth
                                      InputLabelProps={{
                                        classes: {
                                          root: classes.cssLabel,
                                          focused: classes.cssFocused,
                                        },
                                      }}
                                      onValueChange={({ value: v }) =>
                                        setValues({
                                          ...values,
                                          reminderRequestCharge: v
                                        })
                                      }

                                      InputProps={{
                                        classes: {
                                          root: classes.cssOutlinedInput,
                                          focused: classes.cssFocused,
                                          notchedOutline: classes.notchedOutline,
                                        },
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              }

                              {openInvoiceDialogAtom.isEstimate &&
                                <Grid container item xs={12} spacing={2} >
                                  <Grid container item >
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          disabled={isLoading}
                                          checked={values.showVat}
                                          onChange={() => setValues({ ...values, showVat: !values.showVat })}
                                          name="showVat"
                                          color="primary"
                                        />
                                      }
                                      label={`USt. auf ${values.type === "estimate" ? "Kostenvoranschlag" : "Angebot"} anzeigen`}
                                    />
                                  </Grid>
                                </Grid>
                              }
                              {openInvoiceDialogAtom.isEstimate &&
                                <Grid container item xs={12} spacing={2} >
                                  <Grid container item >
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          disabled={isLoading}
                                          checked={values.showSignature}
                                          onChange={() => setValues({ ...values, showSignature: !values.showSignature })}
                                          name="showSignature"
                                          color="primary"
                                        />
                                      }
                                      label={`Unterschriftenzeile anzeigen`}
                                    />
                                  </Grid>
                                </Grid>
                              }
                            </Grid>
                          </Box>
                        }

                        {/* Additional text */}
                        {(selectedClient && !openInvoiceDialogAtom.isEstimate) &&
                          <Box
                            display="flex"
                            flexDirection="column"
                            // padding="25px 0px"
                            className="h-gap--20"
                          >
                            <Grid
                              container
                              spacing={1}
                              direction="row"
                              alignContent="flex-start"
                              alignItems="flex-start"
                              className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                            >
                              {!openInvoiceDialogAtom.isEstimate &&
                                <Grid container item xs={12} spacing={2} >
                                  <Grid container item >
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          disabled={isLoading}
                                          checked={values.showAdditionalText}
                                          onChange={() => setValues({ ...values, showAdditionalText: !values.showAdditionalText })}
                                          name="showAdditionalText"
                                          color="primary"
                                        />
                                      }
                                      label={`Zusatztext auf Rechnung`}
                                    />
                                  </Grid>
                                </Grid>
                              }
                              {(!openInvoiceDialogAtom.isEstimate && values.showAdditionalText) &&
                                <Grid item xs={12} style={{ marginBottom: "20px" }}>
                                  <Chip
                                    size="small"
                                    label="Textvorlagen:"
                                    style={{ marginBottom: 10, borderRadius: 2, marginRight: 5 }}
                                  />
                                  <Chip
                                    size="small"
                                    label="Reverse Charge"
                                    variant="outlined" style={{ marginBottom: 10, marginRight: 5 }}
                                    onClick={() => {
                                      setValues({
                                        ...values,
                                        additionalText: "Die Umsatzsteuerschuld geht auf den Leistungsempfänger über (Reverse Charge System)."
                                      });
                                      // Focus the text field after setting the value
                                      textFieldRef.current.focus();
                                    }}
                                    onDelete={() => {
                                      setValues({
                                        ...values,
                                        additionalText: values.additionalText === "" ? "Die Umsatzsteuerschuld geht auf den Leistungsempfänger über (Reverse Charge System)." : values.additionalText += " Die Umsatzsteuerschuld geht auf den Leistungsempfänger über (Reverse Charge System)."
                                      });
                                      textFieldRef.current.focus();
                                    }}
                                    deleteIcon={<AddCircleIcon />}
                                  />
                                  <Chip
                                    size="small"
                                    label="Änderung Bankverbindung"
                                    variant="outlined" style={{ marginBottom: 10, marginRight: 5 }}
                                    onClick={() => {
                                      setValues({
                                        ...values,
                                        additionalText: "Bitte beachten Sie, dass sich unsere Bankverbindung geändert hat."
                                      });
                                      // Focus the text field after setting the value
                                      textFieldRef.current.focus();
                                    }}
                                    onDelete={() => {
                                      setValues({
                                        ...values,
                                        additionalText: values.additionalText === "" ? "Bitte beachten Sie, dass sich unsere Bankverbindung geändert hat." : values.additionalText += " Bitte beachten Sie, dass sich unsere Bankverbindung geändert hat."
                                      });
                                      textFieldRef.current.focus();
                                    }}
                                    deleteIcon={<AddCircleIcon />}
                                  />
                                  <Chip
                                    size="small"
                                    label="Zahlung nach Erhalt"
                                    variant="outlined" style={{ marginBottom: 10, marginRight: 5 }}
                                    onClick={() => {
                                      setValues({
                                        ...values,
                                        additionalText: "Wir bitten um Zahlung nach Erhalt, ohne Abzug."
                                      });
                                      // Focus the text field after setting the value
                                      textFieldRef.current.focus();
                                    }}
                                    onDelete={() => {
                                      setValues({
                                        ...values,
                                        additionalText: values.additionalText === "" ? "Wir bitten um Zahlung nach Erhalt, ohne Abzug." : values.additionalText += " Wir bitten um Zahlung nach Erhalt, ohne Abzug."
                                      });
                                      textFieldRef.current.focus();
                                    }}
                                    deleteIcon={<AddCircleIcon />}
                                  />

                                  <Chip
                                    size="small"
                                    label="Rechnungsnummer als Zahlungsreferenz"
                                    variant="outlined" style={{ marginBottom: 10, marginRight: 5 }}
                                    onClick={() => {
                                      setValues({
                                        ...values,
                                        additionalText: "Bitte die Rechnungsnummer als Zahlungsreferenz anführen."
                                      });
                                      // Focus the text field after setting the value
                                      textFieldRef.current.focus();
                                    }}
                                    onDelete={() => {
                                      setValues({
                                        ...values,
                                        additionalText: values.additionalText === "" ? "Bitte die Rechnungsnummer als Zahlungsreferenz anführen." : values.additionalText += " Bitte die Rechnungsnummer als Zahlungsreferenz anführen."
                                      });
                                      textFieldRef.current.focus();
                                    }}
                                    deleteIcon={<AddCircleIcon />}
                                  />

                                  <TextField
                                    multiline
                                    autoComplete="off"
                                    placeholder="Text ..."
                                    defaultValue={values.additionalText}
                                    value={values.additionalText}
                                    id="additionalText"
                                    // label={`Text`}
                                    variant="outlined"
                                    type="textarea"
                                    onChange={(e) => setValues({ ...values, additionalText: e.target.value })}
                                    fullWidth
                                    inputRef={textFieldRef}
                                  />
                                </Grid>
                              }
                            </Grid>
                          </Box>
                        }

                      </Box>
                    </form>
                  </>
                }

                {values.loading &&
                  <div className="center">
                    <CircularProgress />
                  </div>
                }
              </Container>
            </Container>
          </div>

          {!isLoading &&
            <InvoiceLivePreview
              openPdfPreview={open}
              setOpen={setOpen}
              sendMode={sendMode}
              client={selectedClient}
              items={items}
              clientNr={selectedClient && JSON.parse(selectedClient.clientNumber).map((item, i) => (item.value))}
              invoiceId={values.invoiceId}
              invoiceNr={values.invoiceNumber.state.map((item, i) => (item.value))}
              invoiceDate={values.invoiceDate}
              invoiceDueDate={values.dueDate}
              invoiceSubtotal={values.subtotal}
              invoiceDiscount={values.discount}
              invoiceDiscountAbsolute={values.discountAbsolute}
              invoiceDiscountPercent={values.discountPercent}
              invoiceDiscountValue={values.discountValue}
              invoiceDiscountedSubtotal={values.discountedSubtotal}
              invoiceVatValues={values.vatValues}
              invoiceTotal={values.total}
              headline={values.headline.state}
              text={values.text.state}
              loading={values.loading}
              directDownload={false}
              invoiceValues={values}
              selectedClient={selectedClient}
              userId={userId}
              setInvoicesAtom={setInvoicesAtom}
              setInvoicesFilteredAtom={setInvoicesFilteredAtom}
              sendState={values.sendState.state}
              reminderRequestCharge={values.reminderRequestCharge}
              isEstimate={openInvoiceDialogAtom.isEstimate}
              type={values.type}
              showVat={values.showVat}
              showSignature={values.showSignature}
              showAdditionalText={values.showAdditionalText}
              additionalText={values.additionalText}
            />
          }
        </div>

        <InvoiceNumberEdit
          openInvoiceNumberEdit={openInvoiceNumberEdit}
          setOpenInvoiceNumberEdit={setOpenInvoiceNumberEdit}
          selectedClient={selectedClient}
          values={values}
          setValues={setValues}
        />

        <ConfirmModal
          show={showConfirmModal}
          onClick={() => {
            setValues({ ...values, sendState: { ...values.sendState, state: values.sentBlobs.length >= 1 ? "sent" : "notSent" } });
            setShowConfirmModal(false);
          }}
          header="Versand wurde abgebrochen"
          footerClass="client-item__modal-actions"
          style={{ zIndex: "9999999" }}
          footer={
            <React.Fragment>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setValues({ ...values, sendState: { ...values.sendState, state: values.sentBlobs.length >= 1 ? "sent" : "notSent" } });
                  setShowConfirmModal(false);
                }}
              >
                OK
              </Button>
            </React.Fragment>
          }
        >
          <p>
            Das Bearbeiten ist wieder möglich. Für einen erneuten Versand klicke bitte auf Senden.
          </p>
        </ConfirmModal>


        <Backdrop
          // When invoice, etc. is scheduled (prevents editing)
          className={classes.backdrop}
          open={values.sendState.state === "scheduled" ? true : false}
        />
      </React.Fragment>
    </React.Fragment>
  );
};

export default InvoiceDialog;

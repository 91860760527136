export const de_PROMO = {
    monthly: "monatlich",
    yearly: "jährlich",
    month: "Monat",
    year: "Jahr",
    inclVat: "ink. USt.",
    inclVatEquals: "inkl. USt. | entspricht",
    basic: {
        button: "Auf Basic wechseln",
    },
    premium: {
        product: "PREMIUM",
        headline1: 'Wechsle jetzt auf ',
        headline2: "Premium!",
        headline3: "",
        subline: "Genieße die Vorteile unserer Premium-Version.",
        button: "Auf Premium wechseln",
        save: "30% sparen",
    },
    pro: {
        product: "PRO",
        headline1: 'Wechsle jetzt auf ',
        headline2: "Pro!",
        headline3: "",
        subline: "Genieße die Vorteile unserer Pro-Version.",
        button: "Auf Pro wechseln",
        save: "30% sparen",
    }
}

import React, { useEffect, useState, useContext } from "react";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { useAuth } from "../../shared/hooks/auth-hook";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import userAtomState from "../../_atoms/userAtom";
import clientsAtomState from "../../_atoms/clientsAtom";
import invoicesAtomState from "../../_atoms/invoicesAtom";
import expensesAtomState from "../../_atoms/expensesAtom";
import invoicesrecurringAtomState from "../../_atoms/invoicesrecurringAtom";
import openExpenseDialogAtomState from "../../_atoms/openExpenseDialogAtom";
import settingsAtomState from "../../_atoms/settingsAtom";

// IMPORT components
import { useSnackbar } from 'notistack';

// IMPORT components MATERIAL UI
import {
  Typography,
  Box,
  Container,
  Grid,
  Tooltip,
  Chip,
  Collapse,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import Alert from "@material-ui/lab/Alert";
import Fab from '@material-ui/core/Fab';
import Fade from '@material-ui/core/Fade';

// IMPORT icons MATERIAL UI
import ClientsIcon from "@material-ui/icons/RecentActors";
import FilterListIcon from '@material-ui/icons/FilterList';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AddIcon from '@material-ui/icons/Add';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import SearchIcon from '@material-ui/icons/Search';


// IMPORT own functions
import { SET_VALUE } from '../../_functions/SET_VALUE';
import { DB_GET_CLIENTS } from '../../_functions/DB_CLIENTS';
import { DB_GET_EXPENSES } from '../../_functions/DB_EXPENSES';
import { DB_GET_INVOICESRECURRING } from '../../_functions/DB_INVOICESRECURRING';
import { DB_PATCH_SETTINGS } from '../../_functions/DB_SETTINGS';
import { RETURN_DATE } from '../../_functions/DATES';
import { RETURN_CLEARDATE } from "../../_functions/DATES";

// IMPORT own components
import UserProfileIncompleteWarning from "../../user/components/UserProfileIncompleteWarning";
import LogoMonogram from './../../_assets/logo_monogram.svg';
import ExpensesMenuDots from "../components/ExpensesMenuDots";
import DateRange from "../../shared/components/DateRange/DateRange";
import ExpensesSearch from "../components/ExpensesSearch";

// IMPORT own CSS
// import "../../invoices/pages/Invoices.scss"

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: {
    height: '0px',
    '@media (max-width: 959px)': {
      height: '62px',
    },
    '@media (max-width: 599px)': {
      height: '45px',
    },
    '@media (max-width: 455px)': {
      height: '55px',
    },
  },
  table: {
    minWidth: 650,
  },
}));

const Expenses = () => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { isLoading, error, sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const { userId } = useAuth();

  // GLOBAL STATE (RECOIL)
  const [userAtom] = useRecoilState(userAtomState);
  const [openExpenseDialogAtom, setOpenExpenseDialogAtom] = useRecoilState(openExpenseDialogAtomState);
  const [clientsAtom, setClientsAtom] = useRecoilState(clientsAtomState);
  const [invoicesAtom, setInvoicesAtom] = useRecoilState(invoicesAtomState);
  const [expensesAtom, setExpensesAtom] = useRecoilState(expensesAtomState);
  const [invoicesrecurringAtom, setInvoicesrecurringAtom] = useRecoilState(invoicesrecurringAtomState);
  const [settingsAtom, setSettingsAtom] = useRecoilState(settingsAtomState);

  // LOCAL STATE (GLOBAL DEPENDENCY monitoring temporay CHANGES until SAVED)
  const [settings, setSettings] = useState(settingsAtom);

  // LOCAL STATE (PURE)
  const [draggableSortItems, setDraggableSortItems] = useState([]);
  const [values, setValues] = useState({
    submenuOpen: false, // values: false, search, filter, sort, settings
    expensesFiltered: {},
    searchInput: "",
    chipDirectChange: false,
    save: false,
    loading: true,
  });


  const [expensesFilteredInitial, setExpensesFilteredIntitial] = useState({});

  useEffect(() => {
    let expensesFilteredInitial = expensesAtom.filter((expense) => {
      return (
  
        // Filter: Invoice status
        // (
        //   settingsAtom.invoicesFiltersStatusDraft && (invoice.sendState !== "sent" && invoice.sendState !== "scheduled" && (invoice.payState === "notPayed" || invoice.payState === undefined)) || // draft
        //   settingsAtom.invoicesFiltersStatusScheduled && (invoice.sendState === "scheduled" && invoice.payState === "notPayed") || // scheduled
        //   settingsAtom.invoicesFiltersStatusSent && (invoice.sendState === "sent" && invoice.payState === "notPayed") || // sent
        //   settingsAtom.invoicesFiltersStatusPayed && invoice.payState === "payed" || // payed
        //   settingsAtom.invoicesFiltersStatusOverdue && (RETURN_OVERDUE_DAYS(invoice.dueDate) > 0 && invoice.payState === "notPayed") || // *** overdue ***
          
        //   // Reset invoice status filter (return all invoices when no invoice status filter is set)
        //   !settingsAtom.invoicesFiltersStatusDraft &&
        //   !settingsAtom.invoicesFiltersStatusScheduled &&
        //   !settingsAtom.invoicesFiltersStatusSent &&
        //   !settingsAtom.invoicesFiltersStatusPayed &&
        //   !settingsAtom.invoicesFiltersStatusOverdue
        // )
        // &&
        // Filter: Date range
        (
            ((RETURN_CLEARDATE(expense.pay_date) >= RETURN_CLEARDATE(settingsAtom.dateRangeStartValue)) && (RETURN_CLEARDATE(expense.pay_date) <= RETURN_CLEARDATE(settingsAtom.dateRangeEndValue)))
        )
      );
    })

    // // SORT: Creation point in time
    // if (settingsAtom.invoicesSortCreation) {
    //   if (settingsAtom.invoicesSortCreationOption === "chronological") {
    //     invoicesFilteredInitial = invoicesFilteredInitial;
    //   } else if (settingsAtom.invoicesSortCreationOption === "reverse-chronological") {
    //     invoicesFilteredInitial = Array.from(invoicesFilteredInitial).reverse();
    //   }
    // }

    // // SORT: Client name alphabetically
    // if (settingsAtom.invoicesSortCompany) {
    //   if (settingsAtom.invoicesSortCompanyOption === "ascending") {
    //     invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
    //       return (JSON.parse(a.clientData).company).toLowerCase() < (JSON.parse(b.clientData).company).toLowerCase() ? -1 : 1
    //     })
    //   } else if (settingsAtom.invoicesSortCompanyOption === "descending") {
    //     invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
    //       return (JSON.parse(a.clientData).company).toLowerCase() > (JSON.parse(b.clientData).company).toLowerCase() ? -1 : 1
    //     })
    //   }
    // }

    // // SORT: Invoice number
    // if (settingsAtom.invoicesSortNumber) {
    //   const returnValue = (item, type) => JSON.parse(item.invoiceNr).find(obj => {return obj.type === type}).value;
      
    //   if (settingsAtom.invoicesSortNumberOption === "ascending") {
    //     invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
    //       a = parseInt(returnValue(a, "continuousNumber"), 10);
    //       b = parseInt(returnValue(b, "continuousNumber"), 10);
    //       return (a < b) ? -1 : 1
    //     }).sort((a, b) => {
    //         a = new Date(a.invoiceDate).getUTCFullYear();
    //         b = new Date(b.invoiceDate).getUTCFullYear();
    //         return (a < b) ? -1 : 1
    //       })
        
      
    //   } else if (settingsAtom.invoicesSortNumberOption === "descending") {
    //     invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
    //       a = returnValue(a, "continuousNumber");
    //       b = returnValue(b, "continuousNumber");
    //       return (a > b) ? -1 : 1
    //     }).sort((a, b) => {
    //       a = new Date(a.invoiceDate).getUTCFullYear();
    //       b = new Date(b.invoiceDate).getUTCFullYear();
    //       return (a > b) ? -1 : 1
    //     })
    //   }
    // }

    // // SORT: Invoice date
    // if (settingsAtom.invoicesSortInvoiceDate) {
    //   if (settingsAtom.invoicesSortInvoiceDateOption === "chronological") {
    //     invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
    //       return new Date(a.invoiceDate) - new Date(b.invoiceDate)
    //     })
    //   } else if (settingsAtom.invoicesSortInvoiceDateOption === "reverse-chronological") {
    //     invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
    //       return new Date(b.invoiceDate) - new Date(a.invoiceDate)
    //     })
    //   }
    // }

    // // SORT: Due date
    // if (settingsAtom.invoicesSortDueDate) {
    //   if (settingsAtom.invoicesSortDueDateOption === "chronological") {
    //     invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
    //       return new Date(a.dueDate) - new Date(b.dueDate)
    //     })
    //   } else if (settingsAtom.invoicesSortDueDateOption === "reverse-chronological") {
    //     invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
    //       return new Date(b.dueDate) - new Date(a.dueDate)
    //     })
    //   }
    // }

    // // SORT: Pay date
    // if (settingsAtom.invoicesSortPayDate) {
    //   if (settingsAtom.invoicesSortPayDateOption === "chronological") {
    //     invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
    //       return new Date(a.payDate) - new Date(b.payDate)
    //     }).filter((invoice) => {
    //       return (
    //         invoice.payState === "payed")
    //     })
    //   } else if (settingsAtom.invoicesSortPayDateOption === "reverse-chronological") {
    //     invoicesFilteredInitial = Array.from(invoicesFilteredInitial).sort((a, b) => {
    //       return new Date(b.payDate) - new Date(a.payDate)
    //     }).filter((invoice) => {
    //       return (
    //         invoice.payState === "payed")
    //     })
    //   }
    // }

    setExpensesFilteredIntitial(expensesFilteredInitial)
  }, [expensesAtom, settingsAtom]);

  useEffect(() => {
    SET_VALUE({ target: { id: "expensesFiltered", value: expensesFilteredInitial } }, setValues);
  }, [JSON.stringify(expensesFilteredInitial)]); // stringifying object dependency prevents infitive loop



  useEffect(() => {
    // Only fetch from DB the first time the invoices page is loaded
    clientsAtom.length === 0 && DB_GET_CLIENTS(setClientsAtom, auth, sendRequest);
    expensesAtom.length === 0 && DB_GET_EXPENSES(setExpensesAtom, auth, sendRequest);
    // Also fetch recurring invoices here as otherwise on first page load of /invoicesrecurring nothing is shown
    invoicesrecurringAtom.length === 0 && DB_GET_INVOICESRECURRING(setInvoicesrecurringAtom, auth, sendRequest);
    setValues({
      ...values,
      loading: false,
    })
  }, [userId, auth.token]);


  useEffect(() => {
    // Only fetch from DB the first time the invoices page is loaded
    expensesAtom.length === 0 && DB_GET_EXPENSES(setExpensesAtom, auth, sendRequest);
  }, [userId, auth.token]);


  useEffect(() => {
    SET_VALUE({ target: { id: "expensesFiltered", value: expensesFilteredInitial } }, setValues);
  }, [expensesFilteredInitial]); // stringifying object dependency prevents infitive loop



  const handleSearchClear = () => {
    SET_VALUE({
      target: {
        "searchInput": "",
        "expensesFiltered": expensesFilteredInitial
      }
    }, setValues, "multi");
  };





  const handleChange = (e) => {
    SET_VALUE(e, setSettings);
  };

  // ON SAVE: 1) Update settingsAtom
  const updateSettingsHandler = () => {
    // GLOBAL changes
    setSettingsAtom(settings);

    // Save variable is only needed to trigger submenuClose when NOTHING changes in settings and save button is clicked
    SET_VALUE({ target: { id: "save", value: true } }, setValues);
  };

  // ON SAVE: 2) DB: PATCH SETTINGS
  useEffect(() => {

    if ((auth.token && values.submenuOpen !== false) || (auth.token && values.chipDirectChange === true)) {
      DB_PATCH_SETTINGS(settingsAtom, auth, sendRequest, enqueueSnackbar, t);

      SET_VALUE({
        target: {
          "submenuOpen": false,
          "chipDirectChange": false,
          "save": false,
        }
      }, setValues, "multi")
    }
  }, [auth.token, settingsAtom, values.save]);

  // ON CANCEL:
  const cancelSettingsHandler = () => {
    // Re-set original values
    setSettings(settingsAtom);
    SET_VALUE({ target: { id: "submenuOpen", value: false } }, setValues);
  };

  // Add to Sort Chips
  useEffect(() => {
    let i = []

    if (settingsAtom.invoicesSortCreation) {
      i.push("invoicesSortCreation")
    }
    if (settingsAtom.invoicesSortInvoiceDate) {
      i.push("invoicesSortInvoiceDate")
    }
    if (settingsAtom.invoicesSortDueDate) {
      i.push("invoicesSortDueDate")
    }
    if (settingsAtom.invoicesSortPayDate) {
      i.push("invoicesSortPayDate")
    }
    if (settingsAtom.invoicesSortNumber) {
      i.push("invoicesSortNumber")
    }
    if (settingsAtom.invoicesSortCompany) {
      i.push("invoicesSortCompany")
    }

    setDraggableSortItems(i)
  }, [settingsAtom]);


  return (
    <React.Fragment>
      <div className={classes.toolbar} />
      <Fade in={true}>
        <Grid container className="subpage-header" maxWidth={false} item direction="row" alignItems="center" alignContent="center">
          <Grid item container alignItems="center" style={{ marginRight: "10px" }}>
            <CreditCardIcon color="primary" fontSize="large" style={{ background: "linear-gradient(to right top, rgb(38, 108, 189), rgb(36, 115, 192), rgb(33, 150, 243), #00bca7, #00bf72)", marginRight: "15px" }} className="icon--with-bg--1" />
            <Typography variant="body1" component="div" style={{ fontWeight: "500" }} className="headline">Ausgaben</Typography>
            <IconButton
              aria-label="add"
              color="primary"
              style={{ marginLeft: "5px" }}
              onClick={() => setOpenExpenseDialogAtom({
                ...openExpenseDialogAtom,
                open: true,
            })}
            >
              <AddIcon />
            </IconButton>
          </Grid>

        
          <Grid item container justifyContent="space-between">
            <Grid item container alignItems="center" style={{ padding: "0px 2px 0px 0px", borderRadius: "6px", marginTop: "10px", width: "auto", boxShadow: "inset rgb(108 116 173 / 12%) 0px 0px 20px 0px" }}>
              <DateRange />

              <IconButton aria-label="search" style={{ color: "inherit" }}
                onClick={() => {
                  SET_VALUE({ target: { id: "submenuOpen", value: values.submenuOpen !== "search" ? "search" : false } }, setValues);
                  handleSearchClear();
                }}
              >
                <SearchIcon fontSize="small" style={{ padding: values.submenuOpen === "search" ? "2px" : "inherit", backgroundColor: values.submenuOpen === "search" ? "#2196f3" : "inherit", color: values.submenuOpen === "search" ? "#ffffff" : "inherit", borderRadius: "60px"}}  />
              </IconButton>

              {/* <ClientsSort
                settings={settings}
                handleChange={handleChange}
                cancelSettingsHandler={cancelSettingsHandler}
                updateSettingsHandler={updateSettingsHandler}
                setValues={setValues}
                SET_VALUE={SET_VALUE}
                setSettings={setSettings}
                values={values}
              />

              <ClientsFilter
                settings={settings}
                handleChange={handleChange}
                cancelSettingsHandler={cancelSettingsHandler}
                updateSettingsHandler={updateSettingsHandler}
                setValues={setValues}
                SET_VALUE={SET_VALUE}
                setSettings={setSettings}
                values={values}
              />

              <ClientsSettings
                settings={settings}
                handleChange={handleChange}
                cancelSettingsHandler={cancelSettingsHandler}
                updateSettingsHandler={updateSettingsHandler}
                setValues={setValues}
                SET_VALUE={SET_VALUE}
                setSettings={setSettings}
                values={values}
              /> */}

              {/* <ClientsCsv data={values.clientsFiltered} /> */}
            </Grid>
          </Grid>

          <Grid item style={{ display: "block", width: "100%"}}>
            <Collapse
              in={values.submenuOpen === "search" ? true : false}
              style={{ marginTop: values.submenuOpen === "search" ? "5px" : 0 }}
            >
              <ExpensesSearch
              values={values}
              expensesFilteredInitial={expensesFilteredInitial}
              SET_VALUE={SET_VALUE}
              setValues={setValues}
              handleSearchClear={handleSearchClear}
              />
            </Collapse>
          </Grid>
        </Grid>
      </Fade>

      {true &&
        <React.Fragment>
          <Container maxWidth={false} disableGutters className="bg--1" >
            <Box
              display="flex"
              flexDirection="column"
              className="list-box"
            >
              <Grid container item xs={12} >
                <div className="box-shadow--2" style={{ width: "100%" }}>
                  <Grid
                    item
                    className="sticky-nav box-shadow--2 h-padding--5-5 tool-menu"
                  >
                    <Grid container>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        item
                        style={{ paddingLeft: "19px"}}
                        xs={12}
                      >

                            <Grid container item xs={11} justifyContent="space-between">
                              <Grid container item alignItems="center" xs={12} lg={4}>
                                <div>
                                  <Typography>Unternehmen</Typography>
                                </div>
                              </Grid>

                              <Grid container item justifyContent="flex-start" alignItems="center" xs={12} lg={8}>
                                <Grid container item xs={12} md={2} justifyContent="center">
                                  <Typography>Betrag</Typography>
                                </Grid>

                                <Grid container item xs={12} md={1} justifyContent="center">
                                  <Typography>Anteil</Typography>
                                </Grid>

                                <Grid container item xs={4} md={2} justifyContent="center">
                                  <Typography>R-Nr.</Typography>
                                </Grid>

                                <Grid container item xs={4} md={2} justifyContent="center">
                                  <Typography>R-Datum</Typography>
                                </Grid>

                                <Grid container item xs={4} md={2} justifyContent="center">
                                  <Typography>Z-Datum</Typography>
                                </Grid>

                                <Grid container item xs={4} md={2} justifyContent="center">
                                  <Typography>Kategorie</Typography>
                                </Grid>

                                <Grid container item xs={4} md={1} justifyContent="center">
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid container item xs={1} justifyContent="flex-end">
                            </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                      {(!isLoading) &&
                        <>
                          {/* EXPENSES already created */}
                          {expensesAtom.length !== 0 &&
                            <>
                              {/* APPLIED FILTER after Loading doesn't result in list of length 0 */}
                              {Object.keys(values.expensesFiltered).length !== 0 &&
                                (values.expensesFiltered).map((expense, i) => (
                                  <Fade
                                    key={i}
                                    in={true}
                                    // style={{ transformOrigin: '0 0 0' }}
                                    // {...(true ? { timeout: 1000 } : {})}
                                  >
                                    <Grid container >
                                      <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="space-between"
                                        item
                                        xs={12}
                                        className="h-bg--white user-clients"
                                        style={{
                                          borderBottom: "1px solid #e2ecfd",
                                        }}
                                      >
                                        <Grid container item xs={11} justifyContent="space-between">
                                          <Grid container item alignItems="center" xs={12} lg={4}
                                            className="image-name-company"
                                            style={{ padding: "30px 15px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", flexWrap: "nowrap" }}
                                            onClick={() => setOpenExpenseDialogAtom({
                                              ...openExpenseDialogAtom,
                                              open: true,
                                              expenseId: expense.id
                                            })}
                                          >
                                            <div style={{ width: "50px", height: "25px", marginRight: "10px", position: "relative" }}>
                                              <img src={LogoMonogram} height={25} width={25} alt={`${t("APP.name")} Logo`} style={{ width: "50px", marginRight: "10px", opacity: 0.2 }} />
                                            </div>
                                            <Typography component="div" variant="body2" style={{ fontWeight: "500", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{expense.vendor_company}</Typography>
                                          </Grid>

                                          <Grid container item justifyContent="flex-start" alignItems="center" xs={12} lg={8}
                                            className="invoice-meta"
                                            style={{padding: "30px 15px", }}
                                            onClick={() => setOpenExpenseDialogAtom({
                                              ...openExpenseDialogAtom,
                                              open: true,
                                              expenseId: expense.id
                                            })}
                                          >
                                            <Grid container item xs={12} md={2} justifyContent="center" className="invoice-total" style={{ background: "rgba(33, 150, 243, 0.1)", borderRadius: "4px", paddingTop: "5px", paddingBottom: "5px" }}>
                                              <Tooltip title={`${t("PAGE_INVOICES.total")} brutto`}>
                                                <Typography component="div" variant="body2" style={{ fontWeight: "500", fontSize: "12px" }}>{parseFloat(expense.charge === 100 ? expense.gross_value : expense.gross_value / 100 * expense.charge).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €</Typography>
                                              </Tooltip>
                                            </Grid>

                                            <Grid container item xs={3} md={1} justifyContent="center">
                                              <Typography style={{ textAlign: "center", fontSize: "10px" }} component="div" variant="body2"><span className="menu-heading-title">Anteil<br /></span>
                                                <span style={{ fontWeight: "600" }}>
                                                {expense.charge}%
                                                </span></Typography>
                                            </Grid>


                                            <Grid container item xs={4} md={2} justifyContent="center">
                                              <Typography style={{ textAlign: "center", fontSize: "10px" }} component="div" variant="body2"><span className="menu-heading-title">R-Nr.<br /></span>
                                                <span style={{ fontWeight: "600" }}>
                                                {expense.invoice_number}
                                                </span></Typography>
                                            </Grid>

                                            <Grid container item xs={4} md={2} justifyContent="center">
                                              <Typography style={{ textAlign: "center", fontSize: "10px" }} component="div" variant="body2"><span className="menu-heading-title">R-Datum<br /></span><span style={{ fontWeight: "600" }}>{RETURN_DATE(expense.invoice_date)}</span></Typography>
                                            </Grid>

                                            <Grid container item xs={4} md={2} justifyContent="center">
                                              <Typography style={{ textAlign: "center", fontSize: "10px" }} component="div" variant="body2"><span className="menu-heading-title">Z-Datum<br /></span><span style={{ fontWeight: "600" }}>{RETURN_DATE(expense.pay_date)}</span></Typography>
                                            </Grid>

                                            <Grid container item xs={4} md={2} justifyContent="center">
                                              <Typography style={{ textAlign: "center", fontSize: "10px" }} component="div" variant="body2"><span className="menu-heading-title">Kategorie<br /></span><span style={{ fontWeight: "600" }}>{expense.invoice_type}</span></Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>

                                        <Grid container item xs={1} justifyContent="flex-end">
                                            <ExpensesMenuDots
                                              expense={expense}
                                              setOpenExpenseDialogAtom={setOpenExpenseDialogAtom}
                                              openExpenseDialogAtom={openExpenseDialogAtom}
                                            />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Fade>
                                ))
                              }
                              {/* APPLIED FILTER after Loading does result in list of length 0 */}
                              {/* {!values.loading && !isLoading && Object.keys(values.invoicesFiltered).length === 0 &&
                                <Typography component="div"><i>{t("PAGE_INVOICES.noResult")}</i></Typography>
                              } */}
                            </>
                          }

                          {/* NO INVOICES created yet */}
                          {!values.loading && !isLoading && expensesFilteredInitial.length === 0 &&
                            <Typography component="div" style={{ fontSize: "12px", padding: "10px 20px" }}>Du hast noch keine Ausgaben erfasst.</Typography>
                          }

                          {(values.loading || isLoading) &&
                            <Typography>Rechnungen werden geladen ...</Typography>
                          }
                        </>
                      }
                </div>
              </Grid>
            </Box>
            </Container>
        </React.Fragment>
      }



      {/* {(!values.loading || !isLoading) && !userAtom.data.signupCompleted &&
        <UserProfileIncompleteWarning
          headline={t("PAGE_INVOICES.h1")}
          text={t("PAGE_INVOICES.profileIncomplete")}
          icon={<ClientsIcon color="primary" fontSize="large" style={{ marginRight: "5px" }} />}
        />
      } */}

        <Fab color="primary" aria-label="add" className="add-invoice-fab" style={{ position: "fixed", bottom: "0", right: "0", margin: "20px"}}
          onClick={() => setOpenExpenseDialogAtom({
              ...openExpenseDialogAtom,
              open: true,
          })}
        >
        <AddIcon />
      </Fab>
    </React.Fragment>
  );
};

export default Expenses;

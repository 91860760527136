import React, { useState } from 'react';

// IMPORT hooks & context
import { useTranslation } from "react-i18next";

// IMPORT components MATERIAL UI
import {
  Typography,
  Button,
  Grid,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  FormGroup,
  Checkbox,
  IconButton,
  Dialog
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

// IMPORT icons MATERIAL UI
import ImportExportIcon from '@material-ui/icons/ImportExport';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function ClientsSort({
  settings,
  handleChange,
  cancelSettingsHandler,
  updateSettingsHandler,
  SET_VALUE,
  setSettings,
  setValues,
  values
 }) {

  // HOOKS & CONTEXT
  const { t } = useTranslation();

  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');

  const handleClickOpen = () => {
    SET_VALUE({ target: { id: "submenuOpen", value: "sort" } }, setValues);
  };

  return (
    <>
    <IconButton
      aria-label="settings"
      style={{ color: "inherit" }}
      onClick={handleClickOpen}
    >
      <ImportExportIcon fontSize="small" />
    </IconButton>
    <Dialog
      onClose={() => { cancelSettingsHandler() }}
      aria-labelledby="customized-dialog-title"
      open={values.submenuOpen === "sort"}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <DialogTitle id="customized-dialog-title" onClose={() => { cancelSettingsHandler() }} style={{ display: "flex" }}>
        <ImportExportIcon color="primary" /> Sortieren nach ...
      </DialogTitle>
      <DialogContent dividers>

        {/* SORT: Creation point in time */}
        <Grid container item style={{ marginBottom: "10px" }}>
          <FormControl component="fieldset">
            <Grid item container direction="row" alignItems="center">
              <FormControl component="fieldset">
                <FormGroup aria-label="clientsSortCreation" name="clientsSortCreation"
                  onChange={(e) => {
                    SET_VALUE({
                      target: {
                        "clientsSortCreation": true,
                        "clientsSortNumber": false,
                        "clientsSortCompany": false
                      }
                    }, setSettings, "multi")
                  }}
                >
                  <FormControlLabel
                    name="clientsSortCreation" control={<Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} checked={settings.clientsSortCreation} color="primary" style={{ paddingBottom: 0, paddingTop: 0 }} />}
                    style={{ marginBottom: "3px" }}
                    label={<Typography variant="body2" component="div"><strong>Erstellungszeitpunkt</strong></Typography>}
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            <RadioGroup aria-label="clientsSortCreationOption" name="clientsSortCreationOption" value={settings.clientsSortCreationOption} onChange={handleChange} style={{ marginLeft: "32px" }}>
              <FormControlLabel value="chronological" control={<Radio size="small" disabled={!settings.clientsSortCreation} color="primary" style={{ paddingTop: "0px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">Chronologisch</Typography>} />
              <FormControlLabel value="reverse-chronological" control={<Radio size="small" disabled={!settings.clientsSortCreation} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">Umgekehrt chronologisch</Typography>} />
            </RadioGroup>
          </FormControl>
        </Grid>

        {/* SORT: number */}
        <Grid container item style={{ marginBottom: "10px" }}>
          <FormControl component="fieldset">
            <Grid item container direction="row" alignItems="center">
              <FormControl component="fieldset">
                <FormGroup aria-label="clientsSortNumber" name="clientsSortNumber"
                  onChange={(e) => {
                    SET_VALUE({
                      target: {
                        "clientsSortNumber": true,
                        "clientsSortCreation": false,
                        "clientsSortCompany": false
                      }
                    }, setSettings, "multi")
                  }}
                >
                  <FormControlLabel
                    name="clientsSortNumber" control={<Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} checked={settings.clientsSortNumber} color="primary" style={{ paddingBottom: 0, paddingTop: 0 }} />}
                    style={{ marginBottom: "3px" }}
                    label={<Typography variant="body2" component="div"><strong>Kundennummer</strong></Typography>}
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            <RadioGroup aria-label="clientsSortNumberOption" name="clientsSortNumberOption" value={settings.clientsSortNumberOption} onChange={handleChange} style={{ marginLeft: "32px" }}>
              <FormControlLabel value="ascending" control={<Radio size="small" disabled={!settings.clientsSortNumber} color="primary" style={{ paddingTop: "0px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">Aufsteigend</Typography>} />
              <FormControlLabel value="descending" control={<Radio size="small" disabled={!settings.clientsSortNumber} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">Absteigend</Typography>} />
            </RadioGroup>
          </FormControl>
        </Grid>

        {/* SORT: Name of company */}
        <Grid container item style={{ marginBottom: "20px" }}>
          <FormControl component="fieldset">
            <Grid item container direction="row" alignItems="center">
              <FormControl component="fieldset">
                <FormGroup aria-label="clientsSortCompany" name="clientsSortCompany"
                  onChange={(e) => {
                    SET_VALUE({
                      target: {
                        "clientsSortCompany": true,
                        "clientsSortCreation": false,
                        "clientsSortNumber": false
                      }
                    }, setSettings, "multi")
                  }}
                >
                  <FormControlLabel
                    name="clientsSortCompany" control={<Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} checked={settings.clientsSortCompany} color="primary" style={{ paddingBottom: 0, paddingTop: 0 }} />}
                    style={{ marginBottom: "3px" }}
                    label={<Typography variant="body2" component="div"><strong>Kundenname</strong></Typography>}
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            <RadioGroup aria-label="clientsSortCompanyOption" name="clientsSortCompanyOption" value={settings.clientsSortCompanyOption} onChange={handleChange} style={{ marginLeft: "32px" }}>
              <FormControlLabel value="ascending" control={<Radio size="small" disabled={!settings.clientsSortCompany} color="primary" style={{ paddingTop: "0px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">Kundennamen (A - Z)</Typography>} />
              <FormControlLabel value="descending" control={<Radio size="small" disabled={!settings.clientsSortCompany} color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">Kundenamen (Z - A)</Typography>} />
            </RadioGroup>
          </FormControl>
        </Grid>

        {/* <Grid container item lg={12} spacing={1} justifyContent="flex-start" alignItems="center">
          <Grid item>
            <Button
              variant="outlined"
              size="small"
              onClick={cancelSettingsHandler}
            >
              {t("BUTTONS.cancel")}
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={updateSettingsHandler}
              startIcon={<SaveIcon style={{ marginRight: "5px" }} />}
            >
              {t("BUTTONS.save")}
            </Button>
          </Grid>
        </Grid> */}
    
    
      </DialogContent>

<DialogActions>
  <Button
    variant="outlined"
    size="small"
    onClick={() => { cancelSettingsHandler() }}
  >
    {t("BUTTONS.cancel")}
  </Button>

  <Button
    variant="contained"
    color="primary"
    size="small"
    onClick={() => { updateSettingsHandler() }}
    startIcon={<SaveIcon style={{ marginRight: "5px" }} />}
  >
    {t("BUTTONS.save")}
  </Button>
</DialogActions>
</Dialog>
</>
  );
}

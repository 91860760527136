export const VAT = (countryCode) => {
    let vatCode;
    let vatDigits;

    switch (countryCode) {
        case "AT":
            vatCode = "ATU";
            vatDigits = 8;
            break;
        case 'DE':
            vatCode = "DE";
            vatDigits = 9;
            break;
        default:
            vatCode = "ATU";
            vatDigits = 8;
    }
    return {vatCode, vatDigits}
};

import React, { useState, useEffect, useContext } from 'react';


// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import userAtomState from "../../_atoms/userAtom";
import invoicesAtomState from "../../_atoms/invoicesAtom";
import openInvoiceDesignerDialogAtomState from "../../_atoms/openInvoiceDesignerDialogAtom";
import settingsAtomState from "../../_atoms/settingsAtom";
import designerAtomState from "../../_atoms/designerAtom";
import themesAtomState from "../../_atoms/themesAtom";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";
import { AuthContext } from '../../shared/context/auth-context';
import { useAuth } from "../../shared/hooks/auth-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";

// IMPORT components
import { HexColorPicker } from "react-colorful";

// IMPORT components MATERIAL UI
import {
  Typography,
  Button,
  Grid,
  IconButton,
  Toolbar,
  AppBar,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

// IMPORT icons MATERIAL UI
import CloseIcon from '@material-ui/icons/Close';
import BrushRoundedIcon from '@material-ui/icons/BrushRounded';
import SaveIcon from '@material-ui/icons/Save';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

// IMPORT components
import Logo from "../../_assets/logo.svg";
import ButtonLoading from "../../shared/components/UIElements/ButtonLoading";
import Invoice1 from "./../../_assets/invoice1.jpg";
import Invoice2 from "./../../_assets/invoice2.jpg";
import Invoice3 from "./../../_assets/invoice3.jpg";
import Preview from "../components/preview/Preview"
import PreviewDesignPdf from "../components/preview/PreviewDesignPdf"
import { useSnackbar } from 'notistack';
import ConfirmModal from "../../shared/components/UIElements/Modal";

// IMPORT functions
import { DB_PATCH_SETTINGS } from './../../_functions/DB_SETTINGS';
import { INVOICE_THEME } from "./../components/preview/defaultDataThemes"
import { DB_GET_THEMES, DB_DELETE_THEME } from './../../_functions/DB_THEMES';

// IMPORT own css
import "./InvoiceDesignerDialog.scss"

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  root: {
    width: '100%',
  },
  heading: {
    marginLeft: "5px",
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});


const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


export default function InvoiceDesignerDialog({ }) {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const { isLoading, sendRequest } = useHttpClient();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { userId } = useAuth();

  const [openInvoiceDesignerDialogAtom, setOpenInvoiceDesignerDialogAtom] = useRecoilState(openInvoiceDesignerDialogAtomState);
  const [settingsAtom, setSettingsAtom] = useRecoilState(settingsAtomState);
  const [userAtom, setUserAtom] = useRecoilState(userAtomState);
  const [themesAtom, setThemesAtom] = useRecoilState(themesAtomState);
  const [showConfirmModal, setShowConfirmModal] = useState(false);


  const [values, setValues] = useState({
    paper: true,


    newDesign: true,
    selectedTheme: INVOICE_THEME("classic"),
    openModal: false,
    openModalExisingDesigns: false,
    chooseTheme: false,
    chosenTheme: "",
    chooseThemePaper: true,
    isLoading: true,
  })

  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true);
  };

  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  };

  const confirmDeleteHandler = (theme) => {
    DB_DELETE_THEME(
      theme,
      auth,
      sendRequest,
      enqueueSnackbar,
      t,
      themesAtom,
      setThemesAtom,
      setShowConfirmModal
    );
  };

  useEffect(() => {
    // themesAtom.length === 0 && DB_GET_THEMES(setThemesAtom, auth, sendRequest, values, setValues);
    DB_GET_THEMES(setThemesAtom, auth, sendRequest, values, setValues);
  }, [userId, auth.token]);

  useEffect(() => {
    if (settingsAtom.invoicesSelectedTheme === values.chosenTheme) {
      setValues({ ...values, setAsDefault: true })
    }
  }, [values.chosenTheme]);


  const handleSelectTheme = (theme) => {
    setValues(previousInputs => ({
      ...previousInputs,
      selectedTheme: INVOICE_THEME(theme),
    }))
    // setDesignerAtom(INVOICE_THEME(theme));
  };

  const saveDesign = async (event) => {
    event.preventDefault();

    if (
      true
    ) {

      try {
        const formData = new FormData();
        formData.append("properties", JSON.stringify(values.selectedTheme));

        if (values.newDesign) {
          // CREATE NEW THEME
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL_API}/themes`,
            "POST",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          )
            // .then((res) => console.log(res.theme._id))
            // .then(() => setOpenDesignerAtom(false))
            .then((res) => {
              // (themesAtom.length <= 0 || values.setAsDefault) && setSettingsAtom({ ...settingsAtom, invoicesSelectedTheme: res.theme._id });
              // (themesAtom.length <= 0 || values.setAsDefault) && DB_PATCH_SETTINGS({ ...settingsAtom, invoicesSelectedTheme: res.theme._id }, auth, sendRequest, enqueueSnackbar, t, false);
              setOpenInvoiceDesignerDialogAtom({ ...openInvoiceDesignerDialogAtom, open: false });
            })
        } else {
          // PATCH EXISTING THEME
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL_API}/themes/${values.chosenTheme}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          )
            .then((res) => {
              // values.setAsDefault && setSettingsAtom({ ...settingsAtom, invoicesSelectedTheme: res.theme._id });
              // values.setAsDefault && DB_PATCH_SETTINGS({ ...settingsAtom, invoicesSelectedTheme: res.theme._id }, auth, sendRequest, enqueueSnackbar, t, false);
              setOpenInvoiceDesignerDialogAtom({ ...openInvoiceDesignerDialogAtom, open: false });
            })
        };
      } catch (err) {
        // SERVER ERROR
      }
    } else {
    }
  };


  const changeDefault = async (themeId) => {
    setSettingsAtom({ ...settingsAtom, invoicesDefaultTheme: themeId })
    DB_PATCH_SETTINGS({ ...settingsAtom, invoicesDefaultTheme: themeId }, auth, sendRequest, enqueueSnackbar, t, false);
  };

  const templates = (
    <Container>
      <Grid container item wrap="wrap" direction="row" spacing={2} justifyContent="flex-start" style={{ maxWidth: "100%", padding: "20px" }}>
        <Grid item xs={12} md={6} lg={4} onClick={() => setValues({ ...values, chosenTheme: "classic" })}>
          <div className="invoice-template" style={{ boxShadow: values.chosenTheme === "classic" && "#26214a40 0px 7px 20px 0px" }}>
            <img src={Invoice1} alt="invoice 1" style={{ maxWidth: "100%" }} className="box-shadow--1" />
            <Grid container item justifyContent="space-between" alignContent="center" style={{ minHeight: "67.5px", marginTop: "-5px", padding: "15px" }}>
              <Typography component="p" variant="p" style={{ display: "flex", alignItems: "center", fontWeight: "700", }}>{values.chosenTheme === "classic" && <CheckCircleIcon style={{ color: "#4caf50", marginRight: "5px" }} />}Klassisch</Typography>
              {values.chosenTheme === "classic" && <Button variant="contained" color="primary" onClick={(e) => { e.stopPropagation(); setValues({ ...values, selectedTheme: INVOICE_THEME(values.chosenTheme), paper: false, newDesign: true, openModal: false }) }}>Weiter</Button>}
            </Grid>
          </div>
        </Grid>

        <Grid item xs={12} md={6} lg={4} onClick={() => setValues({ ...values, chosenTheme: "modern" })}>
          <div className="invoice-template" style={{ boxShadow: values.chosenTheme === "modern" && "#26214a40 0px 7px 20px 0px" }}>
            <img src={Invoice2} alt="invoice 2" style={{ maxWidth: "100%" }} className="box-shadow--1" />
            <Grid container item justifyContent="space-between" alignContent="center" style={{ minHeight: "67.5px", marginTop: "-5px", padding: "15px" }}>
              <Typography component="p" variant="p" style={{ display: "flex", alignItems: "center", fontWeight: "700", }}>{values.chosenTheme === "modern" && <CheckCircleIcon style={{ color: "#4caf50", marginRight: "5px" }} />}Modern</Typography>
              {values.chosenTheme === "modern" && <Button variant="contained" color="primary" onClick={(e) => { e.stopPropagation(); setValues({ ...values, selectedTheme: INVOICE_THEME(values.chosenTheme), paper: false, newDesign: true, openModal: false }) }}>Weiter</Button>}
            </Grid>
          </div>
        </Grid>

        <Grid item xs={12} md={6} lg={4} onClick={() => setValues({ ...values, chosenTheme: "flat" })}>
          <div className="invoice-template" style={{ boxShadow: values.chosenTheme === "flat" && "#26214a40 0px 7px 20px 0px" }}>
            <img src={Invoice3} alt="invoice 3" style={{ maxWidth: "100%" }} className="box-shadow--1" />
            <Grid container item justifyContent="space-between" alignContent="center" style={{ minHeight: "67.5px", marginTop: "-5px", padding: "15px" }}>
              <Typography component="p" variant="p" style={{ display: "flex", alignItems: "center", fontWeight: "700", }}>{values.chosenTheme === "flat" && <CheckCircleIcon style={{ color: "#4caf50", marginRight: "5px" }} />}Flat</Typography>
              {values.chosenTheme === "flat" && <Button variant="contained" color="primary" onClick={(e) => { e.stopPropagation(); setValues({ ...values, selectedTheme: INVOICE_THEME(values.chosenTheme), paper: false, newDesign: true, openModal: false }) }}>Weiter</Button>}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Container>
  )

  const themesReverseOrder = () => {
    let newArray = [...themesAtom];
    newArray.reverse();
    return newArray;
  }

  console.log(themesAtom.length)

  const templatesCustom = (
    <>
      {(themesReverseOrder()).map((theme, i) => (
        <Grid item xs={12} md={6} lg={4} onClick={() => setValues({ ...values, chosenTheme: theme.id })}>
          <div className="invoice-template" >
            {/* <img src={Invoice1} alt="invoice 1" style={{ maxWidth: "100%" }} className="box-shadow--1" /> */}
            {/* <Preview
                design={JSON.parse(theme.properties)}
                client="default"
                invoice="default"
                items="default"
                widthDesktop="30%"
              // invoice={invoicesAtom.filter((invoice) => invoice._id === "6557c5ada6263d23257bd3b5")}
              /> */}

            <PreviewDesignPdf
              design={JSON.parse(theme.properties)}
              client="default"
              invoice="default"
              items="default"
              widthDesktop="30%"
            // invoice={invoicesAtom.filter((invoice) => invoice._id === "6557c5ada6263d23257bd3b5")}
            />

            <Grid container item justifyContent="space-between" alignContent="center" alignItems="center" style={{ minHeight: "67.5px", marginTop: "-5px", padding: "15px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {settingsAtom.invoicesDefaultTheme !== theme.id ? <StarBorderIcon onClick={() => changeDefault(theme.id)} style={{ marginRight: "5px" }} /> : <StarIcon style={{ color: "#c3b700", marginRight: "5px" }} />}
                <Typography noWrap component="p" variant="p" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: '100px', fontWeight: "700", }}>{JSON.parse(theme.properties).title}</Typography>
              </div>
              {values.chosenTheme === theme.id &&
                <div style={{ display: "flex", alignItems: "center" }}>
                  {(themesAtom.length > 1 && settingsAtom.invoicesDefaultTheme !== theme.id) && <DeleteForeverIcon onClick={() => setShowConfirmModal(true)} style={{ color: "grey", marginRight: "5px" }} />}
                  <Button variant="contained" color="primary" onClick={(e) => { e.stopPropagation(); setValues({ ...values, selectedTheme: JSON.parse(theme.properties), paper: false, newDesign: false }) }}>Bearbeiten</Button>
                </div>
              }
            </Grid>
          </div>
        </Grid>
      ))}
    </>
  )


  return (
    <>
      <AppBar style={{ background: "#E312CA", paddingRight: "0" }}>
        <Toolbar style={{ justifyContent: "space-between" }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "30%" }}>
            <>
              <BrushRoundedIcon fontSize="small" style={{ marginRight: "5px" }} />
              <Typography variant="body1"><strong>Designer</strong></Typography>
            </>
          </div>

          <div style={{ width: "30%", display: "flex", justifyContent: "center" }}>
            <img
              src={Logo}
              alt={`${t("APP.name")} Logo`}
              style={{
                maxHeight: "40px",
              }}
            />
          </div>

          <div style={{ width: "30%", display: "flex", justifyContent: "end", gap: "10px", maxHeight: "40px" }}>
            <IconButton
              edge="start"
              color="inherit"
              style={{ margin: "0", display: "flex", width: "40px" }}
              onClick={() => { setOpenInvoiceDesignerDialogAtom({ ...openInvoiceDesignerDialogAtom, open: false }) }}
              aria-label={`${t("BUTTONS.AL_close")}`}
            >
              <CloseIcon />
            </IconButton>

          </div>
        </Toolbar>
      </AppBar>

      {!values.isLoading &&
        <>
          <div>
            <div className={classes.toolbar} />
            <Container maxWidth={false} disableGutters className="bg--1">
              <Container maxWidth={false} style={{ paddingTop: "25px", paddingBottom: "150px", minHeight: "100vh" }}>

                {values.paper &&
                  <>
                    <Container>
                      <Grid container item wrap="wrap" direction="row" spacing={2} justifyContent="flex-start" style={{ maxWidth: "100%", padding: "20px" }}>
                        <Grid item xs={12} md={6} lg={4} onClick={() => setValues({ ...values, openModal: true })}>
                          <div className="invoice-template" style={{ height: "100%", padding: "15px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", alignContent: "center" }}>
                            <AddCircleOutlineIcon style={{ zoom: 2 }} />
                            <Typography variant="h6" component="div">Neues Design erstellen</Typography>
                          </div>
                        </Grid>
                        {templatesCustom}
                      </Grid>
                    </Container>
                  </>
                }

                {/* {(values.chooseThemePaper && settingsAtom.invoicesSelectedTheme === "classic") &&
              <>
              <Typography variant="h6" component="div" style={{ textAlign: "center"}}>Designvorlage wählen</Typography>
              {templates}
              </>
              } */}

                <>
                  {/* Choose Dialog NEW Template for DESIGN */}
                  <Dialog
                    onClose={() => setValues({ ...values, openModal: false })}
                    aria-labelledby="customized-dialog-title"
                    open={values.openModal}
                    fullWidth={true}
                    maxWidth="lg"
                  >
                    <DialogTitle
                      id="customized-dialog-title"
                      onClose={() => setValues({ ...values, chosenTheme: "classic", setAsDefault: false, openModal: false })}
                    >
                      Vorlage wählen
                    </DialogTitle>

                    <DialogContent dividers>

                      {templates}


                    </DialogContent>

                    {/* {(userAtom.data.themes.length === 0 || values.chooseTheme) &&
                  <DialogActions>
                    <Button style={{ padding: "15px" }} variant="contained" color="primary" onClick={() => setValues({ ...values, selectedTheme: INVOICE_THEME(values.chosenTheme), openModal: false })}>Design verwenden</Button>
                  </DialogActions>
                  } */}
                  </Dialog>

                  {/* Choose Dialog EXISTING Template to change DESIGN */}
                  <Dialog
                    onClose={() => setValues({ ...values, chosenTheme: "classic", setAsDefault: false, openModalExisingDesigns: false })}
                    aria-labelledby="customized-dialog-title"
                    open={values.openModalExisingDesigns}
                    fullWidth={true}
                    maxWidth="lg"
                  >
                    <DialogTitle
                      id="customized-dialog-title"
                      onClose={() => setValues({ ...values, chosenTheme: "classic", setAsDefault: false, openModalExisingDesigns: false })}
                    >
                      Meine Designs
                    </DialogTitle>

                    <DialogContent dividers>

                      {templatesCustom}


                    </DialogContent>

                    {/* {(userAtom.data.themes.length === 0 || values.chooseTheme) &&
                  <DialogActions>
                    <Button style={{ padding: "15px" }} variant="contained" color="primary" onClick={() => setValues({ ...values, selectedTheme: INVOICE_THEME(values.chosenTheme), openModal: false })}>Design verwenden</Button>
                  </DialogActions>
                  } */}
                  </Dialog>
                </>

                {!values.paper &&
                  <>
                    <Grid container item direction="row" spacing={2} justifyContent="flex-start">
                      <Grid container item xs={12} lg={6} direction="column" spacing={2} justifyContent="flex-start">

                        {/* <Grid container item alignItems="center">
                      <Typography variant="h6" component="div" style={{ marginRight: "10px" }}>Design: {values.selectedTheme.theme}</Typography>
                      <Button onClick={()=>setValues({...values, openModal: true})} size="large" startIcon={<ViewModuleIcon />} variant="outlined">Vorlage ändern</Button>
                    </Grid> */}

                        <Grid container item alignItems="center">
                          <Button onClick={(e) => { e.stopPropagation(); setValues({ ...values, selectedTheme: "classic", paper: true, newDesign: false }) }} size="large" startIcon={<ArrowBackIosIcon />} >Zurück</Button>
                        </Grid>

                        {/* <Grid container item alignItems="center">
                          <Typography variant="h6" component="div" style={{ marginRight: "10px" }}>Design: {values.selectedTheme.theme}</Typography>
                        </Grid> */}

                        <Grid container item>
                          <TextField variant="outlined" required id="title" label="Design Name" defaultValue={values.selectedTheme.title}
                            onChange={(e) => setValues({
                              ...values,
                              ["selectedTheme"]: {
                                ...values["selectedTheme"],
                                title: e.target.value,
                              },
                            })}
                            fullWidth style={{ marginBottom: "20px" }}
                          />
                        </Grid>


                        <Grid container item>
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel2a-content"
                              id="panel2a-header"
                            >
                              <FormatColorFillIcon fontSize="small" />
                              <Typography className={classes.heading}>Hintergrundfarbe</Typography>
                            </AccordionSummary>

                            <AccordionDetails>
                              <Typography>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                                sit amet blandit leo lobortis eget.
                                <HexColorPicker color={values.selectedTheme.colorBgBody} onChange={(v) => setValues({
                                  ...values,
                                  ["selectedTheme"]: {
                                    ...values["selectedTheme"],
                                    colorBgBody: v,
                                  },
                                })} />
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>



                        {/* {themesAtom.length > 0 &&
                    <Grid container item>
                       <FormControlLabel
                        control={<Switch checked={values.setAsDefault} onChange={()=>setValues({...values, setAsDefault: !values.setAsDefault})} name="set default" color="primary"/>}
                        label="Als Standard-Design für Rechnungen festlegen"
                      />
                    </Grid>
                    } */}
                      </Grid>



                      <Grid item xs={12} lg={6}>

                        <Preview
                          design={values.selectedTheme}
                          client="default"
                          invoice="default"
                          items="default"
                        // invoice={invoicesAtom.filter((invoice) => invoice._id === "6557c5ada6263d23257bd3b5")}
                        />

                        <PreviewDesignPdf
                          design={values.selectedTheme}
                          client="default"
                          invoice="default"
                          items="default"
                          widthDesktop="100%"
                        // invoice={invoicesAtom.filter((invoice) => invoice._id === "6557c5ada6263d23257bd3b5")}
                        />

                      </Grid>
                    </Grid>
                  </>
                }

              </Container>
            </Container>
          </div>

          {/* <div className="bottom-nav">
          <Button variant="outlined" color="primary" onClick={() => { setOpenInvoiceDesignerDialogAtom({ ...openInvoiceDesignerDialogAtom, open: false }) }}>Zurück</Button>
          <Button style={{ padding: "15px" }} variant="contained" color="primary" onClick={() => setValues({ ...values, themeChosen: true })}>Weiter</Button>
        </div> */}


          {(!values.paper && !values.openModal) &&
            <div className="bottom-nav">
              <Button variant="outlined" color="primary" onClick={() => { setOpenInvoiceDesignerDialogAtom({ ...openInvoiceDesignerDialogAtom, open: false }) }}>Abbrechen</Button>

              <ButtonLoading
                loading={isLoading}
                disabled={isLoading}
                type="submit"
                onClick={(e) => saveDesign(e)}
                fullWidth={false}
                size="large"
                className="button--loading"
                startIcon={<SaveIcon style={{ marginRight: "5px" }} />}

              >
                {t("BUTTONS.save")}
              </ButtonLoading>

            </div>
          }
        </>
      }

      <ConfirmModal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header={"Design löschen"}
        footerClass="client-item__modal-actions"
        style={{ zIndex: "9999999" }}
        footer={
          <React.Fragment>
            <Button
              variant="outlined"
              style={{ marginRight: "10px" }}
              onClick={cancelDeleteHandler}
            >
              {t("BUTTONS.cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => confirmDeleteHandler(values.chosenTheme)}
            >
              {t("BUTTONS.delete")}
            </Button>
          </React.Fragment>
        }
      >
        <p>
          Bist du sicher, dass du dieses Design endgültig löschen möchtest? Das Löschen kann nicht mehr rückgängig gemacht werden.
        </p>
      </ConfirmModal>
    </>
  );
}

export const SET_USER_ATOM = (atomData, userAtom, setUserAtom) => {
    
    setUserAtom({
        ...userAtom,
        ["data"]: {
          ...userAtom["data"],
          ...atomData
        },
      });
  };
  
export const de_PAGE_DASHBOARD = {
    h1: "Dashboard",
    welcome: {
        headline: "Willkommen!",
        text: "Herzlich Willkommen bei DagoWert: Deine Web App zur Kundenpflege und Rechnungslegung."
    },
    notifications: {
        headline: "Benachrichtigungen",
        registrationComplete: {
            headline: "Registrierung abgeschlossen",
            text: "Du hast deine Registrierung erfolgreich abgeschlossen."
        },
        profileIncomplete: {
            headline: "Profil vervollständigen",
            text: "Bitte vervollständige deine Unternehmensprofil! Mit vollständigem Profil kannst du Kunden anlegen und Rechnungen erstellen."
        },
        none: {
            text: "Du hast keine neuen Benachrichtigungen."
        }
    }
}
  
import React from 'react';
import { BottomNavigation, Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import MailIcon from '@material-ui/icons/Mail';
import ButtonLoadingBottom from '../../../shared/components/UIElements/ButtonLoadingBottom';

const InvoiceBottomNavigation = ({ selectedClient, settings, values, isLoading, buttonLoadingAction, invoiceHandler, setButtonLoadingAction, openInvoiceDialogAtom }) => {
  return (
    <>
      {(selectedClient && values.sendState.state !== "scheduled") &&
        <BottomNavigation
          showLabels
          style={{ position: "fixed", width: "100%", zIndex: 999, bottom: 0, background: "transparent", margin: "0 auto" }}
          className="box-shadow--2"
        >
          <ButtonLoadingBottom
            loading={isLoading && (buttonLoadingAction === "save")}
            disabled={isLoading}
            color="primary"
            type="submit"
            size="large"
            className="button--loading"
            borderTopLeftRadius="10px"
            borderTopRightRadius={(selectedClient !== "" && settings.invoicesEmailServer !== "download" && openInvoiceDialogAtom.invoiceId !== "") ? "0px" : "10px"}
            startIcon={<SaveIcon />}
            onClick={(e) => { invoiceHandler(e, false); setButtonLoadingAction("save") }}
          >
            Speichern
          </ButtonLoadingBottom>

          {(selectedClient !== "" && settings.invoicesEmailServer !== "download" && openInvoiceDialogAtom.invoiceId !== "") &&
            <ButtonLoadingBottom
              loading={isLoading && (buttonLoadingAction === "save and send")}
              disabled={isLoading}
              color="secondary"
              type="submit"
              size="large"
              className="button--loading"
              borderTopLeftRadius={(selectedClient !== "" && settings.invoicesEmailServer !== "download" && openInvoiceDialogAtom.invoiceId !== "") ? "0px" : "10px"}
              borderTopRightRadius="10px"
              startIcon={<MailIcon />}
              onClick={(e) => { invoiceHandler(e, true); setButtonLoadingAction("save and send") }}
            >
              Senden
            </ButtonLoadingBottom>
          }
        </BottomNavigation>
      }
    </>
  );
};

export default InvoiceBottomNavigation;

export const INVOICE_THEME = (selectedTheme) => {
  let theme;
  switch (selectedTheme) {
    case "classic":
      theme = {
        title: "Klassisch",
        theme: "classic",
        colorBgBody: "#bada55"
      }
      break;
    case "modern":
      theme = {
        title: "Modern",
        theme: "modern",
        colorBgBody: "#9c27b0"
      }
      break;
    case "flat":
      theme = {
        title: "Flat",
        theme: "flat",
        colorBgBody: "#27a9b0"
      }
      break;
    default:
      theme = {
        title: "classic",
        theme: "classic",
        colorBgBody: "#ffffff"
      }
  }
  return theme;
};

import React, {
  useState,
  useContext,
  useEffect,
} from "react";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from '../../shared/context/auth-context';
import { useAuth } from "../../shared/hooks/auth-hook";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import settingsAtomState from "../../_atoms/settingsAtom";
import openInvoiceRecurringDialogAtomState from "../../_atoms/openInvoiceRecurringDialogAtom";
import invoicesAtomState from "../../_atoms/invoicesAtom";
import clientsAtomState from "../../_atoms/clientsAtom";
import invoicesFilteredAtomState from "../../_atoms/invoicesFilteredAtom";

// IMPORT functions
import { SET_VALUES } from '../../_functions/SET_VALUES';

// IMPORT components
import { useSnackbar } from 'notistack';
import { ReactSortable } from "react-sortablejs";
import { NumericFormat } from "react-number-format";
import InvoiceLivePreview from "../components/InvoiceLivePreview";

// IMPORT components MATERIAL UI
import {
  Typography,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  Grid,
  Box,
  InputAdornment,
  TextField,
  CircularProgress,
  Tooltip,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Alert from "@material-ui/lab/Alert";

import ScheduleIcon from '@material-ui/icons/Schedule';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import DateFnsUtils from '@date-io/date-fns';
import { de } from "date-fns/locale";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

// IMPORT icons MATERIAL UI
import InvoicesIcon from "@material-ui/icons/RecentActors";
import SaveIcon from '@material-ui/icons/Save';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DragIcon from "@material-ui/icons/OpenWith";
import SettingsIcon from '@material-ui/icons/Settings';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import MailIcon from '@material-ui/icons/Mail';

// IMPORT own components
import ImagePlaceholder from "../../_assets/image_placeholder.svg";
import ButtonLoading from "../../shared/components/UIElements/ButtonLoading";

// IMPORT own css
// import "./InvoiceDialog.scss"

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: "5px",
      borderRadius: "5px"
    },
    margin: "5px",
    borderRadius: "5px"
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    // backgroundColor: '#f0f3ff',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },

  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);


const useStyles = makeStyles((theme) => {
  return {
    toolbar: theme.mixins.toolbar,
    stepContent: {
      display: "flex",
      justifyContent: "center"
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  };
});


const InvoiceDialog = (props) => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const { isLoading, sendRequest, clearError } = useHttpClient();
  const classes = useStyles();
  const { userId } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  // GLOBAL STATE (RECOIL)
  const [openInvoiceRecurringDialogAtom, setOpenInvoiceRecurringDialogAtom] = useRecoilState(openInvoiceRecurringDialogAtomState);
  const [invoicesAtom, setInvoicesAtom] = useRecoilState(invoicesAtomState);
  const [, setInvoicesFilteredAtom] = useRecoilState(invoicesFilteredAtomState);
  const [settingsAtom] = useRecoilState(settingsAtomState);
  const [clientsAtom] = useRecoilState(clientsAtomState);

  // LOCAL
  const [selectedClient, setSelectedClient] = useState("");
  const [items, setItems] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const [sendMode, setSendMode] = useState(false);

  const [showGlobalDiscount, setShowGlobalDiscount] = useState(false);
  const [currentItem, setCurrentItem] = useState({
    name: "",
    quantity: 1,
    price: 0,
    unit: "",
    vatPercent: "",
    discount: "none",
    discountPercent: 0,
    discountAbsolute: 0, 
  });
  const [settings, setSettings] = useState(settingsAtom);
  const [values, setValues] = useState({
    vat: {
      // optional
      state: "",
      digits: 0, // e.g. Austria: 8, Germany: 9 -> see https://www.finanz.at/steuern/umsatzsteuer/uid-nummer/
      code: "", // e.g. ATU, DE, etc ...
      error: false,
      errorMsg: t("FIELDS.vat.error"),
    },
    vatPercent: {
      // optional
      state: "",
    },
    image: {
      // optional
      state: "",
    },
    showInvoiceNumber: settingsAtom.invoiceNumber === "manual" ? true : false,
    invoiceNumber: {
      state: "",
      error: false,
      errorMsg: t("FIELDS.invoiceNumber.errorMissing"),
    },
    invoiceNumberValues: {
      invoicesLength: 0,
      arrInvoiceNumbers: [0], // Array of all invoice numbers in use. 0 has to be default state to make invoice numbers start at 1.
      missingInvoiceumbers: [] // Invoice numbers that are "free" due to invoice deletion
    },
    status: {
      state: "draft",
    },
    loading: true,
    isLoading: true,
    invoiceNr: {
      state: "",
      error: false,
      errorMsg: "Bitte tragen Sie eine Rechnungsnummer ein.",
    },
    invoiceDate: new Date(),
    sendDate: new Date(),
    dueDate: new Date(),
    payDate: new Date(),
    subtotal: 0,
    vatValues: {},
    total: 0,
    discount: "none",
    discountAbsolute: 0,
    discountPercent: 0,
    discountValue: 0,
    discountedSubtotal: 0,
    discountedTotal: 0,
    sent: false,
    recurring: "no",
    text: {
      state: ""
    },
    sendState: {
      state: "notSent",
    },
    payState: {
      state: "notPayed",
    },
    blob: ""
  });



  const [, setAlignment] = React.useState('left');

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  /* 
    Functions
  */
  const handleChangeStatus = async (e) => {
    clearError();
    SET_VALUES(e, values, setValues);
  };


  const handleChange = (event) => {
    const target = event.target.id !== undefined ? event.target.id : event.target.name;

    if (target === "discount" || target === "discountAbsolute" || target === "discountPercent") {

      let discountValue = values.discountValue;
      if (target === "discountAbsolute") {
        discountValue = event.target.value;
      } else if (target === "discountPercent") {
        discountValue = values.subtotal * event.target.value / 100;
      } else if (event.target.value === "none") {
        discountValue = 0;
      }

      let discountedSubtotal = values.discountedSubtotal
      if (target === "discountAbsolute" || target === "discountPercent") {
        discountedSubtotal = values.subtotal - discountValue;
      }

      if (event.target.value === "none") {
        setValues({
          ...values,
          [target]: event.target.value,
          discountValue: discountValue,
          discountedSubtotal: discountedSubtotal,
          discountAbsolute: 0,
          discountPercent: 0
        })
      } else if (event.target.value === "absolute") {
        setValues({
          ...values,
          [target]: event.target.value,
          discountValue: discountValue,
          discountedSubtotal: discountedSubtotal,
          discountPercent: 0
        })
      } else if (event.target.value === "percent") {
        setValues({
          ...values,
          [target]: event.target.value,
          discountValue: discountValue,
          discountedSubtotal: discountedSubtotal,
          discountAbsolute: 0,
        })
      } else {
        setValues({
          ...values,
          [target]: event.target.value,
          discountValue: discountValue,
          discountedSubtotal: discountedSubtotal
        })
      }

    } else {
      setValues({
        ...values,
        [target]: {
          ...values[target],
          state: event.target.value,
        },
      });
    }
  };

  const changeItem = (event, index) => {
    //Events that use "id" vs. "name" as identifier

    // console.log(event)

    const target = event.target.id !== undefined ? event.target.id : event.target.name

    const newItems = items.map((item, i) => {
      if (i === index) {

        let total = item.total;
        let discountValue = item.discountValue;

        if (target === "price") {
          total = event.target.value * item.quantity
          if (item.discount === "percent") {
            discountValue = total * item.discountPercent / 100
          }
        } else if (target === "quantity") {
          total = event.target.value * item.price
          if (item.discount === "percent") {
            discountValue = total * item.discountPercent / 100
          }
        }

        // Handles switch between discount variants
        if (target === "discount" && event.target.value === "percent") {
          discountValue = total * item.discountPercent / 100
        } else if (target === "discount" && event.target.value === "absolute") {
          discountValue = item.discountAbsolute
        } else if (target === "discount" && event.target.value === "none") {
          discountValue = 0
        }

        // Handles change of discount % or absolute
        if (target === "discountPercent") {
          discountValue = total * event.target.value / 100
        } else if (target === "discountAbsolute") {
          discountValue = event.target.value
        }

        let discountedTotal = total - discountValue;

        return {
          ...item,
          [target]: event.target.value,
          total: total,
          discountValue: discountValue,
          discountedTotal: discountedTotal
        };
      }
      return item;
    });

    setItems(newItems);
  };

  const deleteItem = (index) => {
    setItems((current) =>
      current.filter((item, i) => {
        return i !== index;
      })
    );
  };

  useEffect(() => {
    const calculateSubtotal = () => {
      // Original array
      let total = 0;
      items.forEach(function (item) {
        total += item.discountedTotal;
      });
      return total;
    };

    const calculateDiscountValue = () => {
      const discountValue = values.discount === "absolute" ? values.discountAbsolute : (calculateSubtotal() * values.discountPercent / 100)
      return discountValue
    }

    const calculateDiscountedSubtotal = () => {
      const discountedSubtotal = calculateSubtotal() - values.discountValue;
      return discountedSubtotal
    }

    let discountedTotal = values.discountedTotal;

    // Group items according to VAT-% and and add VAT-% and absolute VAT sum to each VAT-%-category
    const vatGroups = () => {
      items.reduce((vatGroups, item) => {
        item["vatPercent"] = item.vatPercent !== "" ? item.vatPercent : selectedClient.vatPercent !== "" ? selectedClient.vatPercent : 0;
        const group = (vatGroups[item.vatPercent] || ["", 0]);
        group[0] = item.vatPercent;
        // group[0] = item.vatPercent !== "" ? item.vatPercent : selectedClient.vatPercent !== "" ? selectedClient.vatPercent : 0;

        // Takes global Discount into Account
        if (values.discount === "none") {
          group[1] += (parseInt(item.vatPercent) / 100 * item.discountedTotal);
          discountedTotal += (1 + (parseInt(item.vatPercent) / 100)) * item.discountedTotal;
        } else {
          if (values.discount === "absolute") {
            group[1] += parseInt(item.vatPercent) / 100 * (item.discountedTotal - (item.discountedTotal / calculateSubtotal() * parseFloat(values.discountValue)));
            discountedTotal += (1 + (parseInt(item.vatPercent) / 100)) * (item.discountedTotal - (item.discountedTotal / calculateSubtotal() * parseFloat(values.discountValue)));
          } else if (values.discount === "percent") {
            group[1] += (parseInt(item.vatPercent) / 100 * item.discountedTotal * (1 - parseFloat(values.discountPercent) / 100));
            discountedTotal += (1 + (parseInt(item.vatPercent) / 100)) * item.discountedTotal * (1 - parseFloat(values.discountPercent) / 100);
          }
        }
        vatGroups[item.vatPercent] = group;

        setValues({
          ...values,
          vatValues: vatGroups,
          subtotal: calculateSubtotal(),
          total: discountedTotal,
          discountValue: calculateDiscountValue(),
          discountedSubtotal: calculateDiscountedSubtotal(),
        });

        return vatGroups;
      }, {})
    }

    vatGroups();
  }, [items, values.discount, values.discountValue, values.discountAbsolute, values.discountPercent]);

  // useEffect(() => {
  //   console.log("foo")
  // }, [values.text]);

  const total = () => {
    return currentItem.price * currentItem.quantity
  }

  const discountValue = () => {
    const discountValue = currentItem.discount === "absolute" ? currentItem.discountAbsolute : (total() * currentItem.discountPercent / 100)
    return discountValue
  }

  const discountedTotal = () => {
    const discountedTotal = total() - discountValue()
    return discountedTotal
  }

  const addItem = () => {
    setItems((items) => [
      ...items,
      {
        name: currentItem.name,
        quantity: currentItem.quantity,
        price: currentItem.price,
        vatPercent: currentItem.vatPercent,
        total: total(),
        discount: currentItem.discount,
        discountPercent: currentItem.discountPercent,
        discountAbsolute: currentItem.discountAbsolute,
        discountValue: discountValue(),
        discountedTotal: discountedTotal()
      },
    ]);

    // reset state
    setCurrentItem({
      ...currentItem,
      name: "",
      quantity: 1,
      price: 0,
      // vatPercent: "",
      discount: "none",
      discountPercent: 0,
      discountAbsolute: 0,
    });

    // set accordion state
    // setExpanded(Object.keys(items).length)

  };

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(expanded !== panel ? panel : false);
  };


  const invoiceHandler = async (event, sendMode) => {
    event.preventDefault();

    sendMode && setSendMode(true);
    sendMode && setOpen(true);

    if (
      // userId &&
      // auth.token &&
      // !values.company.error &&
      // !values.country.error &&
      // !values.street.error &&
      // !values.streetNr.error &&
      // !values.zip.error &&
      // !values.city.error &&
      // !values.email.error &&
      // !values.firstName.error &&
      // !values.lastName.error &&
      // !values.vat.error &&
      // !values.clientNumber.error
      true
    ) {

      try {
        const formData = new FormData();
        formData.append("clientData", JSON.stringify(selectedClient));
        formData.append("items", JSON.stringify(items));
        formData.append("invoiceNr", values.invoiceNumber.state);
        formData.append("invoiceDate", values.invoiceDate);
        formData.append("sendDate", values.sendDate);
        formData.append("dueDate", values.dueDate);
        formData.append("payDate", values.payDate);
        formData.append("subtotal", JSON.stringify(values.subtotal));
        formData.append("vatValues", JSON.stringify(values.vatValues));
        formData.append("total", JSON.stringify(values.total));
        formData.append("discount", values.discount);
        formData.append("discountAbsolute", values.discountAbsolute);
        formData.append("discountPercent", values.discountPercent);
        formData.append("discountValue", values.discountValue);
        formData.append("discountedSubtotal", JSON.stringify(values.discountedSubtotal));
        formData.append("discountedTotal", JSON.stringify(values.discountedTotal));
        formData.append("status", values.status.state);
        formData.append("sendState", values.sendState.state);
        formData.append("payState", values.payState.state);
        formData.append("recurring", values.recurring);
        formData.append("text", values.text.state);
        formData.append("blob", values.blob);

        if (openInvoiceRecurringDialogAtom.invoiceId === "" || openInvoiceRecurringDialogAtom.duplicate === true) {
          // CREATE NEW INVOICE

          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL_API}/invoicesrecurring`,
            "POST",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          )
          // Necessary in order to set immediately sent invoices (after creation) to sent
          setOpenInvoiceRecurringDialogAtom({...openInvoiceRecurringDialogAtom, invoiceId: responseData.invoice._id})
            // .then(() => DB_PATCH_SETTINGS(settings, auth, sendRequest, enqueueSnackbar, t, false))
            // .then(() => setSettingsAtom(settings))


        } else if (openInvoiceRecurringDialogAtom.invoiceId !== "" && openInvoiceRecurringDialogAtom.duplicate === false) {
          // UPDATE EXISTING INVOICE
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL_API}/invoicesrecurring/${openInvoiceRecurringDialogAtom.invoiceId}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          )
        }

        // FETCH updated Invoices List
        try {
          const resInvoices = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL_API}/invoicesrecurring/user/${userId}`,
            "GET",
            null,
            {
              Authorization: `Bearer ${auth.token}`
            }
          );
          setInvoicesAtom(resInvoices.invoices);
          setInvoicesFilteredAtom(resInvoices.invoices);
          // !sendMode && setOpenInvoiceDialogAtom({ ...openInvoiceDialogAtom, open: false, invoiceId: "" });
          enqueueSnackbar(t("FIELDS.saved"), { variant: "success" });
        } catch (err) {
          // SERVER ERROR
          !sendMode && enqueueSnackbar(t("ERRORS.save_server"), { variant: "error" });
        }
      } catch (err) {
        // SERVER ERROR
        console.log(err)
        !sendMode && enqueueSnackbar(t("ERRORS.save_server"), { variant: "error" });
      }
    } else {
      enqueueSnackbar(t("ERRORS.save_form"), { variant: "error" });
    }
  };

  useEffect(() => {
    // If there are no invoices, avoid errors by setting length to 0
    let invoicesLength = invoicesAtom ? invoicesAtom.length : 0;

    // Collect invoice numbers in use. Fallback has to be an array with 1 entry -> 0 ... to avoid errors if there are no invoices.https://v4.mui.com/api/drawer/
    let arrInvoiceNumbersAll = [0] // All numbers including alphanumerical numbers (saved as string)
    let arrInvoiceNumbers = [0] // Only the pure numbers saved as string)
    if (invoicesAtom) {
      // console.log(invoicesAtom)
      // for (let key in invoicesAtom) {
      //   if (invoicesAtom.hasOwnProperty(key)) {
      //     arrInvoiceNumbersAll.push(invoicesAtom[key].invoiceNumber);
      //     if (!isNaN(invoicesAtom[key].invoicesNumber)) {
      //       // Only if it is a number push it to array
      //       arrInvoiceNumbers.push(invoicesAtom[key].invoiceNumber);
      //     }
      //   }
      // }
      invoicesAtom.forEach((item) => {
        arrInvoiceNumbersAll.push(item.invoiceNr);
        if (!isNaN(item.invoiceNr)) {
          arrInvoiceNumbers.push(item.invoiceNr);
        }
      })
    }


    // Sort collected invoice numbers from 0 to n.
    let arrInvoiceNumbersSorted = arrInvoiceNumbers.sort(function (a, b) {
      return a - b;
    });

    // Get length of sorted array of invoice numbers in use.
    let N = arrInvoiceNumbersSorted.length;

    // Identify missing invoice numbers. Missing invoice numbers are those that are "free" because of invoice deletion e.g. [0,1,3,6] -> results in [2,4,5]
    // let missingInvoiceNumbers = [];
    // function missingNumbersInInvoiceNumbersArray(arrInvoiceNumbersSorted, N) {
    //   let cnt = 0;
    //   for (let i = arrInvoiceNumbersSorted[0]; i <= arrInvoiceNumbersSorted[N - 1]; i++) {
    //     if (arrInvoiceNumbersSorted[cnt] == i) {
    //       cnt++;
    //     }
    //     else {
    //       missingInvoiceNumbers.push(i)
    //     }
    //   }
    // }
    // missingNumbersInInvoiceNumbersArray(arrInvoiceNumbersSorted, N);

    // console.log(JSON.parse(invoicesAtom[invoicesLength - 1].invoiceNr) + 1)

    let invoiceNumber;
    if (settingsAtom.invoicesNumber === "automatic") {
      // if (missingInvoiceNumbers.length !== 0) {
      //   // If there are missing invoice numbers, use the first number in the array.
      //   invoiceNumber = missingInvoiceNumbers[0];
      // } else {
      //   // If there are no missing invoice numbers, use the next number.
      //   invoiceNumber = invoicesLength + 1;
      // }

      // Get invoice number of most recent invoice and add 1
      invoiceNumber = JSON.parse(invoicesAtom[invoicesLength - 1].invoiceNr) + 1

    } else if (settingsAtom.invoicesNumber === "schema") {
      invoiceNumber = "";
      (typeof (settingsAtom.invoicesNumberItems) === "string" ? JSON.parse(settingsAtom.invoicesNumberItems) : settingsAtom.invoicesNumberItems).forEach((item) => {
        if (item.type === "continuousNumber") {
          invoiceNumber += parseInt(item.value);
        } else if (item.type === "year") {
          invoiceNumber += new Date().getFullYear();
        } else if (item.type === "month") {
          invoiceNumber += new Date().getMonth() + 1;
        } else if (item.type === "day") {
          invoiceNumber += new Date().getDate();
        } else {
          invoiceNumber += item.value;
        }
      })
    } else {
      invoiceNumber = ""
    }

    setValues(() => {
      if (openInvoiceRecurringDialogAtom.invoiceId === "") {
        // If is NEW Invoice
        return ({
          ...values,
          invoiceNumberValues: {
            ...values.invoiceNumberValues,
            invoicesLength: invoicesLength,
            arrInvoiceNumbers: arrInvoiceNumbersAll,
            // missingInvoiceNumbers: missingInvoiceNumbers,
          },
          invoiceNumber: {
            ...values.invoiceNumber,
            state: invoiceNumber
          },
          text: {
            ...values.text,
            state: settings.invoicesText,
          },
          blob: values.blob,
          loading: false
        })

      } else if (openInvoiceRecurringDialogAtom.invoiceId !== "" && openInvoiceRecurringDialogAtom.duplicate === false) {
        // If is EDITING MODE of existing invoice
        let invoice = (invoicesAtom.filter((invoice) => { return invoice.id === openInvoiceRecurringDialogAtom.invoiceId }))[0];

        setSelectedClient(clientsAtom.filter((client) => { return client.id === JSON.parse(invoice.clientData).id })[0]);

        setItems(JSON.parse(invoice.items))

        invoice.discount !== "none" && setShowGlobalDiscount(true);

        return ({
          ...values,
          invoiceNumberValues: {
            ...values.invoiceNumberValues,
            invoicesLength: invoicesLength,
            arrInvoiceNumbers: arrInvoiceNumbers,
            // missingInvoiceNumbers: missingInvoiceNumbers,
          },
          invoiceNumber: {
            ...values.invoiceNumber,
            state: invoice.invoiceNr
          },
          status: {
            ...values.status,
            state: invoice.status
          },
          sendState: {
            ...values.sendState,
            state: invoice.sendState
          },
          payState: {
            ...values.payState,
            state: invoice.payState
          },
          invoiceDate: new Date(invoice.invoiceDate),
          dueDate: new Date(invoice.dueDate),
          sendDate: new Date(invoice.sendDate),
          payDate: new Date(invoice.payDate),
          discount: invoice.discount,
          discountAbsolute: invoice.discountAbsolute,
          discountPercent: invoice.discountPercent,
          text: {
            ...values.text,
            state: invoice.text,
          },
          blob: values.blob,
          loading: false,
        })
      }
      else if (openInvoiceRecurringDialogAtom.invoiceId !== "" && openInvoiceRecurringDialogAtom.duplicate === true) {
        // If is DUPLICATE MODE of existing invoice
        let invoice = (invoicesAtom.filter((invoice) => { return invoice.id === openInvoiceRecurringDialogAtom.invoiceId }))[0];

        setSelectedClient(clientsAtom.filter((client) => { return client.id === JSON.parse(invoice.clientData).id })[0]);

        setItems(JSON.parse(invoice.items))

        invoice.discount !== "none" && setShowGlobalDiscount(true);

        return ({
          ...values,
          invoiceNumberValues: {
            ...values.invoiceNumberValues,
            invoicesLength: invoicesLength,
            arrInvoiceNumbers: arrInvoiceNumbers,
            // missingInvoiceNumbers: missingInvoiceNumbers,
          },
          invoiceNumber: {
            ...values.invoiceNumber,
            state: invoiceNumber
          },
          status: {
            ...values.status,
            state: invoice.status
          },
          sendState: {
            ...values.sendState,
            state: invoice.sendState
          },
          payState: {
            ...values.payState,
            state: invoice.payState
          },
          invoiceDate: new Date(invoice.invoiceDate),
          dueDate: new Date(invoice.dueDate),
          sendDate: new Date(invoice.sendDate),
          payDate: new Date(invoice.payDate),
          discount: invoice.discount,
          discountAbsolute: invoice.discountAbsolute,
          discountPercent: invoice.discountPercent,
          text: {
            ...values.text,
            state: invoice.text,
          },
          blob: values.blob,
          loading: false
        })
      }
    });

    if (settingsAtom.invoicesNumber === "schema") {
      // UPDATE SETTINGS: CONTINUOUS NUMBER IN SCHEMA when invoice is Created
      let invoiceNumberItems = (typeof (settingsAtom.invoicesNumberItems) === "string" ? JSON.parse(settingsAtom.invoicesNumberItems) : settingsAtom.invoicesNumberItems)
      const currentIndex = invoiceNumberItems.findIndex((elements) => elements.type === "continuousNumber");
      if (currentIndex !== -1) {
        let currentId = invoiceNumberItems[currentIndex].id;
        let currentValue = invoiceNumberItems[currentIndex].value;
        const updatedItem = { ...invoiceNumberItems[currentIndex], id: currentId, type: "continuousNumber", value: parseFloat(currentValue) + 1 };
        const newItems = [...invoiceNumberItems];
        newItems[currentIndex] = updatedItem;
        setSettings({ ...settings, invoicesNumberItems: newItems });
      }
    }
  }, []);


  // const handleChange = async (e) => {
  //   clearError();
  //   SET_VALUES(e, values, setValues);
  // };



  const validate = (field) => {
    // ONLY validate invoiceNumber if it is NEW INVOICE
    // openInvoiceDialogAtom.invoiceId === "" ?
    //   VALIDATE([
    //     "company",
    //     "country",
    //     "street",
    //     "streetNr",
    //     "zip",
    //     "city",
    //     "firstName",
    //     "lastName",
    //     "vat",
    //     "invoiceNumber"
    //   ],
    //     field, values, setValues)
    //   :
    //   VALIDATE([
    //     "company",
    //     "country",
    //     "street",
    //     "streetNr",
    //     "zip",
    //     "city",
    //     "firstName",
    //     "lastName",
    //     "vat",
    //   ],
    //     field, values, setValues)
  };


  const returnDate = (date) => {
    const dateObj = new Date(date);
    const month   = dateObj.getUTCMonth() + 1; // months from 1-12
    const day     = dateObj.getUTCDate();
    const year    = dateObj.getUTCFullYear();
    return(`${day}.${month}.${year}`);
  }

  const returnHours = (date) => {
    const dateObj = new Date(date);
    const hours   = dateObj.getHours(); // months from 1-12
    const minutes = dateObj.getMinutes();
    return(`${hours}:${minutes}`);
  }

  const returnOverdueDays = (date) => {

    let date1 = new Date(date);
    let date2 = new Date();
    // Ensure that only dates, whithout time elements are compared
    date1.setHours(0,0,0,0);
    date2.setHours(0,0,0,0); 

    
    // Calculating the time difference
    // of two dates
    let Difference_In_Time =
        date2.getTime() - date1.getTime();
    
    // Calculating the no. of days between
    // two dates
    let Difference_In_Days =
        Math.round
            (Difference_In_Time / (1000 * 3600 * 24));
    return(Difference_In_Days);
  }

  return (
    <React.Fragment>
      <AppBar style={{ top: "auto", bottom: 0, background: "#263238", paddingRight: "0", zIndex: "1500" }}>
        <Toolbar style={{ justifyContent: "space-between" }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "10%" }}>
            <>
              <IconButton
                edge="start"
                color="inherit"
                style={{ margin: "0", display: "flex", width: "40px", height: "40px", border: "1px solid white" }}
                onClick={() => setOpenInvoiceRecurringDialogAtom({
                  ...openInvoiceRecurringDialogAtom,
                  open: false,
                  invoiceId: "",
                  duplicate: false
                })}
                aria-label={`${t("BUTTONS.AL_close")}`}
              >
                <ArrowBackIcon />
              </IconButton>
              <div style={{color: "white"}}>Wiederkehrende Verrechnung erstellen</div>
            </>
          </div>

          <div style={{ width: "90%", display: "flex", justifyContent: "center", gap: "10px", maxHeight: "40px" }}>
            {selectedClient &&
              <>
                <>
                  <div className="MuiButton-startIcon--mobile" style={{ background: "#ffffff", borderRadius: "4px", display: "flex" }}>
                    <ButtonLoading
                      loading={isLoading}
                      disabled={isLoading}
                      type="submit"
                      onClick={(e) => invoiceHandler(e, false)}
                      fullWidth={false}
                      size="large"
                      className="button--loading"
                      startIcon={<SaveIcon />}
                    >
                      <span className="mobile-hide">{t("BUTTONS.save")}</span>
                    </ButtonLoading>
                  </div>

                  {settings.invoicesEmailServer !== "download" &&
                    <div className="MuiButton-startIcon--mobile" style={{ background: "#ffffff", borderRadius: "4px", display: "flex" }}>
                      <Button
                        variant="contained"
                        size="large"
                        color="secondary"
                        style={{ padding: "15px", }}
                        startIcon={<MailOutlineIcon />}
                        onClick={(e) => invoiceHandler(e, true)}
                      >
                        <span className="mobile-hide">{values.sendState.state !== "sent" ? "Versand Planen": "Nochmals senden"}</span>
                      </Button>
                    </div>
                  }
                </>
              </>
            }

          </div>


          <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "10%" }}>
            {selectedClient &&
              <Button
                variant="outlined"
                color="inherit"
                style={{ paddingTop: "7px", paddingBottom: "7px" }}
                // startIcon={<PdfPreviewIcon />}
                onClick={() => { setSendMode(false); setOpen(true) }}
              >
                PDF
              </Button>
            }
          </div>
        </Toolbar>
      </AppBar>


      {selectedClient &&
        <div>
          <Grid
            container
            justifyContent="center"
            spacing={1}
            className="h-borderRadius--10 box-shadow--2 h-padding--5-5 tool-menu bg--1"
          >

          </Grid>
        </div>
      }


      <React.Fragment>
        {/* <div style={{ display: "flex" }}> */}

        <div>
          <div className="invoice-edit">
            {/* <div className={classes.toolbar} /> */}
            <Container maxWidth={false} disableGutters className="bg--1">
              <Container maxWidth="lg" style={{ paddingTop: "25px", paddingBottom: "150px" }}>
                {!values.loading &&
                  <>
                    {/* <Grid container item direction="row" alignItems="center" style={{ marginBottom: "20px" }}>
                      <InvoicesIcon color="primary" fontSize="large" style={{ marginRight: "5px" }} />
                      <Typography variant="h5" component="h1" color="primary">{openInvoiceDialogAtom.invoiceId !== "" ? t("PAGE_INVOICES.editInvoice") : t("PAGE_INVOICES.createInvoice")}</Typography>
                    </Grid> */}

                    <form onSubmit={invoiceHandler}>
                      <Box display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        // alignItems="center"
                        margin="0 25px"
                        className="h-gap--25"
                      >
                        {/* Select company */}
                        <Box
                          display="flex"
                          flexDirection="column"
                          // padding="25px 0px"
                          className="h-gap--20"
                        >
                          <Grid
                            container
                            spacing={1}
                            direction="row"
                            alignContent="flex-start"
                            alignItems="flex-start"
                            className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                          >

                            <Grid container item direction="row" alignItems="center" style={{ marginBottom: "20px" }}>
                              <InvoicesIcon color="primary" fontSize="large" style={{ marginRight: "5px" }} />
                              <Typography variant="h6" component="h1" color="textSecondary" style={{ textTransform: "none" }}>{openInvoiceRecurringDialogAtom.invoiceId !== "" ? "Wiederkehrende Rechnung bearbeiten" : "Wiederkehrende Rechnung anlegen"}</Typography>
                            </Grid>

                            <Grid item xs={12} lg={12}>
                              <Autocomplete
                                style={{ width: "100%" }}
                                required
                                id="client"
                                onChange={(event, value) => (setSelectedClient(value), setCurrentItem({ ...currentItem, vatPercent: value.vatPercent }))}
                                disableClearable
                                defaultValue={selectedClient}
                                value={selectedClient || null}
                                getOptionSelected={(option, value) => option.company === value.company} // prevents infinite loop!!!
                                options={clientsAtom}
                                classes={{
                                  option: classes.option,
                                }}
                                autoHighlight
                                getOptionLabel={(option) => option.company}
                                renderOption={(option) => (
                                  <>
                                    <>
                                      {option.image !== "/#" && (
                                        <img
                                          src={`${process.env.REACT_APP_BACKEND_URL}/${option.image}`}
                                          alt="logo"
                                          style={{
                                            maxWidth: "50px",
                                            marginRight: "10px",
                                          }}
                                        />
                                      )}
                                      {option.image === "/#" && (
                                        <img
                                          src={ImagePlaceholder}
                                          alt="logo"
                                          style={{
                                            maxWidth: "50px",
                                            marginRight: "10px",
                                          }}
                                        />
                                      )}
                                    </>
                                    <>{option.company}</>
                                  </>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    required
                                    label="Kund:in"
                                    placeholder="Unternehmen auswählen"
                                    variant="outlined"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    // onBlur={() => validate("country")}
                                    // error={values.country.error}
                                    // helperText={
                                    //   values.country.error
                                    //     ? values.country.errorMsg
                                    //     : ""
                                    // }
                                    // value={selectedClient.company}
                                    // value={selectedClient.company}
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                    InputProps={{
                                      ...params.InputProps,
                                      startAdornment: (
                                        <>
                                          {(selectedClient.image !== "/#" && selectedClient.image !== undefined) &&
                                            <img
                                              src={`${process.env.REACT_APP_BACKEND_URL}/${selectedClient.image}`}
                                              alt="logo"
                                              style={{
                                                maxWidth: "50px",
                                                marginRight: "10px",
                                              }}
                                            />
                                          }
                                        </>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </Grid>

                            {selectedClient !== "" &&
                              <Grid item xs={12} md={4} >
                                <Tooltip
                                  title={openInvoiceRecurringDialogAtom.invoiceId !== "" ? t("PAGE_INVOICES.tooltipCannotEditExistingInvoice") : t("PAGE_INVOICES.tooltipChangeInvoiceNumberToManual")}
                                  disableFocusListener={(openInvoiceRecurringDialogAtom.invoiceId === "" && settingsAtom.invoicesNumber === "manual") ? true : false}
                                  disableHoverListener={(openInvoiceRecurringDialogAtom.invoiceId === "" && settingsAtom.invoicesNumber === "manual") ? true : false}
                                  disableTouchListener={(openInvoiceRecurringDialogAtom.invoiceId === "" && settingsAtom.invoicesNumber === "manual") ? true : false}
                                >
                                  <TextField
                                    disabled={(openInvoiceRecurringDialogAtom.invoiceId !== "" || settingsAtom.invoicesNumber === "automatic" || settingsAtom.invoicesNumber === "schema") ? true : false}
                                    id="invoiceNumber"
                                    label={`${t("FIELDS.invoiceNumber.label")} *`}
                                    variant="filled"
                                    type="text"
                                    defaultValue={values.invoiceNumber.state}
                                    error={values.invoiceNumber.error}
                                    helperText={
                                      values.invoiceNumber.error ?
                                        values.invoiceNumberValues.arrInvoiceNumbers.includes(values.invoiceNumber.state) ? t("FIELDS.invoiceNumber.errorAlreadyExisting") : values.invoiceNumber.errorMsg
                                        : ""
                                    }
                                    onChange={(e) => (
                                      handleChange(e)
                                      // setValidationField(e.target.id)
                                    )}
                                    fullWidth
                                  // InputProps={{ inputProps: { style: { background: '#f0f3ff' }}}}
                                  />
                                </Tooltip>
                              </Grid>
                            }

                            {selectedClient !== "" &&
                              <MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
                                <Grid item xs={12} md={4} className="datepicker">
                                  <KeyboardDatePicker
                                    fullWidth
                                    inputVariant="filled"
                                    id="invoiceDate"
                                    format="dd.MM.yyyy"
                                    label="Rechnungsdatum"
                                    value={values.invoiceDate}
                                    onChange={(date) =>
                                      setValues({
                                        ...values,
                                        invoiceDate: date,
                                      })
                                    }
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                    }}
                                    okLabel="OK"
                                    cancelLabel="Abbrechen"
                                  />
                                </Grid>

                                <Grid item xs={12} md={4} className="datepicker">
                                  <KeyboardDatePicker
                                    fullWidth
                                    inputVariant="filled"
                                    id="dueDate"
                                    format="dd.MM.yyyy"
                                    label="Fälligkeitsdatum"
                                    value={values.dueDate}
                                    onChange={(date) =>
                                      setValues({
                                        ...values,
                                        dueDate: date,
                                      })
                                    }
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                    }}
                                    okLabel="OK"
                                    cancelLabel="Abbrechen"
                                  />
                                </Grid>
                              </MuiPickersUtilsProvider>
                            }

                            {(selectedClient !== "" && settings.invoicesEmailServer === "download") &&
                              <Grid container item xs={12} md={4}>
                                <FormControl variant="filled" id="status" className={classes.formControl} fullWidth>
                                  <InputLabel id="status">Versandstatus</InputLabel>
                                  <Select
                                    labelId="sendState"
                                    id="sendState"
                                    name="sendState"
                                    value={values.sendState.state}
                                    onChange={handleChangeStatus}
                                    className="select-status"
                                  >
                                    <MenuItem value={"notSent"}>
                                      <ListItemIcon style={{ minWidth: "auto", marginRight: "5px" }}>
                                        <WarningIcon style={{ color: theme.palette.standard.main }} />
                                      </ListItemIcon>
                                      <ListItemText primary={"Noch nicht versendet"} />
                                    </MenuItem>

                                    <MenuItem value={"sent"}>
                                      <ListItemIcon style={{ minWidth: "auto", marginRight: "5px" }}>
                                        <MailIcon style={{ color: theme.palette.primary.dark }} />
                                      </ListItemIcon>
                                      <ListItemText primary={"Gesendet"} />
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                            }

                            {(selectedClient !== "" && settings.invoicesEmailServer !== "download" && values.sendState.state !== "sent" && values.sendState.state !== "scheduled" && openInvoiceRecurringDialogAtom.invoiceId !== "") &&
                                <Grid item xs={12} sm={12}>
                                  <Alert severity="error">
                                    <Typography>Diese Rechnung wurde noch nicht versendet.</Typography>
                                  </Alert>
                                </Grid>
                            }

                            {(selectedClient !== "" && settings.invoicesEmailServer !== "download" && values.sendState.state === "sent" ) &&
                                <Grid item xs={12} sm={12}>
                                  <Alert severity="info" icon={<MailIcon fontSize="inherit" />}>
                                    <Typography>Diese Rechnung wurde versendet am {returnDate(values.sendDate)}.</Typography>
                                  </Alert>
                                </Grid>
                            }

                            {(selectedClient !== "" && settings.invoicesEmailServer !== "download" && values.sendState.state === "scheduled" ) &&
                                <Grid item xs={12} sm={12}>
                                  <Alert severity="warning" icon={<ScheduleIcon fontSize="inherit" />}>
                                    <Typography>Diese Rechnung wird am {`${returnDate(values.sendDate)} um ${returnHours(values.sendDate)}`} versendet.</Typography>
                                  </Alert>
                                </Grid>
                            }

                            {(selectedClient !== "" && (values.payState.state === "notPayed" && returnOverdueDays(values.dueDate) > 0)) &&
                                <Grid item xs={12} sm={12}>
                                  <Alert severity="error">
                                    <Typography>Diese Rechnung ist überfällig seit {returnOverdueDays(values.dueDate)} Tag(en)</Typography>
                                  </Alert>
                                </Grid>
                            }

                            {selectedClient !== "" &&
                              <Grid container item xs={12} md={4}>
                                <FormControl variant="filled" id="status" className={classes.formControl} fullWidth>
                                  <InputLabel id="status">Bezahlstatus</InputLabel>
                                  <Select
                                    labelId="payState"
                                    id="payState"
                                    name="payState"
                                    defaultValue={values.payState.state}
                                    onChange={handleChangeStatus}
                                    className="select-status"
                                  >
                                    <MenuItem value={"notPayed"}>
                                      <ListItemIcon style={{ minWidth: "auto", marginRight: "5px" }}>
                                        <WarningIcon style={{ color: theme.palette.standard.main }} />
                                      </ListItemIcon>
                                      <ListItemText primary={"Noch nicht bezahlt"} />
                                    </MenuItem>

                                    <MenuItem value={"payed"} onClick={()=>
                                      // If an invoice is payed, it has to have been sent before
                                      // So if the sendState is still "notSent", automatically change it to "sent"
                                      // If the payState is changed to "payed"
                                      setValues({
                                        ...values,
                                        sendState: {
                                          ...values.sendState,
                                          state: "sent",
                                        },
                                      })
                                    }>
                                      <ListItemIcon style={{ minWidth: "auto", marginRight: "5px" }}>
                                        <CheckCircleIcon style={{ color: theme.palette.success.light }} />
                                      </ListItemIcon>
                                      <ListItemText primary={"Bezahlt"} />
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                            }

                            {(selectedClient !== "" && values.payState.state === "payed") &&
                              <MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
                                <Grid item xs={12} sm={4} className="datepicker" >
                                  <KeyboardDatePicker
                                    fullWidth
                                    inputVariant="filled"
                                    id="payDate"
                                    format="dd.MM.yyyy"
                                    label="Zahldatum"
                                    value={values.payDate}
                                    onChange={(date) =>
                                      setValues({
                                        ...values,
                                        payDate: date,
                                      })
                                    }
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                    }}
                                    okLabel="OK"
                                    cancelLabel="Abbrechen"
                                  />
                                </Grid>
                              </MuiPickersUtilsProvider>
                            }
                          </Grid>
                        </Box>

                        {/* Items */}
                        {selectedClient &&
                          <Box
                            display="flex"
                            flexDirection="column"
                            // padding="25px 0px"
                            className="h-gap--20"
                          >
                            <Grid
                              container
                              spacing={1}
                              direction="row"
                              alignContent="flex-start"
                              alignItems="flex-start"
                              className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                            >
                              {selectedClient &&
                                <Grid item xs={12} style={{ marginBottom: "20px" }}>
                                  <TextField
                                    multiline
                                    autoComplete="off"
                                    defaultValue={values.text.state}
                                    id="text"
                                    label={`Einleitungstext`}
                                    variant="outlined"
                                    type="textarea"
                                    // onChange={(e) =>
                                    //   setValues({
                                    //     ...values,
                                    //     text: e.target.value,
                                    //   })
                                    // }
                                    onChange={handleChangeStatus}

                                    fullWidth
                                  />
                                </Grid>
                              }

                              <Grid container item lg={12} spacing={1} >
                                <Grid container item direction="row" alignItems="center" justifyContent="space-between">
                                  <Typography variant="body1" component="h3" style={{ paddingBottom: "0px" }}>{t("PAGE_INVOICES.items.h2")}</Typography>
                                </Grid>

                                <Grid container item spacing={1}>
                                  <Grid item xs={12} >
                                    <Grid item xs={12}>
                                      <div className={classes.root}>
                                        <ReactSortable list={items} setList={setItems}>
                                          {items.map((item, i) => (

                                            <Accordion square key={i} expanded={expanded === i} style={{ marginBottom: "10px" }}>
                                              <AccordionSummary
                                                // expandIcon={<SettingsIcon />}
                                                aria-controls={item.name}
                                                id={item.name}
                                              >
                                                <Grid container item xs={12} spacing={1} style={{ alignItems: "center" }}>
                                                  {items.length > 1 &&
                                                    <Grid container item xs={1}>
                                                      <DragIcon style={{ cursor: "grab" }} fontSize="small" />
                                                    </Grid>

                                                  }
                                                  <Grid container item xs={items.length > 1 ? 7 : 8}>
                                                    <TextField
                                                      required
                                                      multiline
                                                      autoComplete='off'
                                                      className="input-white"
                                                      id="name"
                                                      label="Leistungsbeschreibung"
                                                      variant="outlined"
                                                      type="text"
                                                      value={item.name}
                                                      onChange={(e) => changeItem(e, i)}
                                                      fullWidth
                                                    />
                                                  </Grid>
                                                  <Grid container item lg={3}>
                                                    <NumericFormat
                                                      required
                                                      className="input-white"
                                                      id="price"
                                                      label="Preis (netto)"
                                                      variant="outlined"
                                                      value={item.price}
                                                      prefix="€ "
                                                      thousandSeparator="."
                                                      decimalSeparator=","
                                                      decimalScale={2}
                                                      valueIsNumericString
                                                      customInput={TextField}
                                                      onValueChange={({ value: v }) =>
                                                        changeItem(
                                                          {
                                                            target: { value: v, id: "price" },
                                                          },
                                                          i
                                                        )
                                                      }
                                                    />
                                                  </Grid>
                                                  <Grid container item xs={1} style={{ justifyContent: "end" }}>
                                                    <SettingsIcon onClick={handleChangeAccordion(i)} />
                                                  </Grid>
                                                </Grid>

                                              </AccordionSummary>
                                              <AccordionDetails>
                                                <Grid
                                                  container
                                                  item
                                                  lg={12}
                                                  spacing={1}
                                                >

                                                  <Grid
                                                    container
                                                    item
                                                    lg={12}
                                                    spacing={1}
                                                  >
                                                    <Grid container item lg={3}>
                                                      <TextField
                                                        required
                                                        id="quantity"
                                                        label="Menge"
                                                        variant="outlined"
                                                        type="text"
                                                        value={item.quantity}
                                                        // onBlur={() => changeItem(i)}
                                                        // error={values.company.error}
                                                        // helperText={
                                                        //   values.company.error ? values.company.errorMsg : ""
                                                        // }
                                                        onChange={(e) => changeItem(e, i)}
                                                        fullWidth
                                                      />
                                                    </Grid>

                                                    <Grid container item lg={3}>
                                                      <TextField
                                                        id="unit"
                                                        label="Einheit"
                                                        variant="outlined"
                                                        type="text"
                                                        placeholder="h/kg/cm etc."
                                                        defaultValue={item.unit}
                                                        InputLabelProps={{
                                                          shrink: true,
                                                        }}
                                                        // error={values.company.error}
                                                        // helperText={

                                                        //   values.company.error ? values.company.errorMsg : ""
                                                        // }
                                                        onChange={(e) => changeItem(e, i)}
                                                        fullWidth
                                                      />
                                                    </Grid>

                                                    <Grid container item lg={2}>
                                                      <TextField
                                                        label="MwSt."
                                                        id="vatPercent"
                                                        variant="outlined"
                                                        value={item.vatPercent !== "" ? item.vatPercent : selectedClient.vatPercent !== "" ? selectedClient.vatPercent : "0"}
                                                        onChange={(e) => changeItem(e, i)}
                                                        InputProps={{
                                                          endAdornment: <InputAdornment position="end">
                                                            <span style={{ color: "inherit" }}>%</span>
                                                          </InputAdornment>
                                                        }}

                                                      />
                                                    </Grid>

                                                    {item.discount === "none" &&
                                                      <Grid container item lg={4}>

                                                        <FormControl variant="outlined" style={{ width: "100%" }}>
                                                          <InputLabel id="discount">Rabatt</InputLabel>
                                                          <Select
                                                            labelId="discount"
                                                            id="discount"
                                                            name="discount"
                                                            value={item.discount}
                                                            onChange={(e) => changeItem(e, i)}
                                                            label="Rabatt"
                                                          >

                                                            <MenuItem value={"none"}>Kein Rabatt</MenuItem>
                                                            <MenuItem value={"absolute"}>Rabatt in €</MenuItem>
                                                            <MenuItem value={"percent"}>Rabatt in %</MenuItem>
                                                          </Select>
                                                        </FormControl>
                                                      </Grid>
                                                    }

                                                    {item.discount === "absolute" &&

                                                      <Grid container item lg={4}>
                                                        <NumericFormat
                                                          required
                                                          id="discountAbsolute"
                                                          label="Rabatt in €"
                                                          variant="outlined"
                                                          value={item.discountAbsolute}
                                                          prefix="€ "
                                                          thousandSeparator={"."}
                                                          decimalSeparator={","}
                                                          decimalScale={2}
                                                          valueIsNumericString
                                                          fixedDecimalScale
                                                          customInput={TextField}
                                                          InputProps={{
                                                            endAdornment: <InputAdornment position="end" style={{ height: "100%" }}>
                                                              <div style={{ borderLeft: "1px solid #d7d7d7de", height: "100%", marginLeft: "15px", display: "flex" }}>
                                                                <IconButton
                                                                  style={{ marginLeft: "5px" }}
                                                                  aria-label="delete discount"
                                                                  onClick={() => changeItem(
                                                                    {
                                                                      target: { value: "none", id: "discount" },
                                                                    },
                                                                    i
                                                                  )}
                                                                >
                                                                  <DeleteForeverIcon fontSize="small" />
                                                                </IconButton>
                                                              </div>
                                                            </InputAdornment>
                                                          }}
                                                          onValueChange={({ value: v }) =>
                                                            changeItem(
                                                              {
                                                                target: { value: v, id: "discountAbsolute" },
                                                              },
                                                              i
                                                            )
                                                          }
                                                        />

                                                      </Grid>
                                                    }

                                                    {item.discount === "percent" &&

                                                      <Grid container item lg={4}>
                                                        <TextField
                                                          label="Rabatt in %"
                                                          id="discountPercent"
                                                          variant="outlined"
                                                          value={item.discountPercent}
                                                          onChange={(e) => changeItem(e, i)}
                                                          InputProps={{
                                                            endAdornment: <InputAdornment position="end" style={{ height: "100%" }}>
                                                              <span style={{ color: "inherit" }}>%</span>
                                                              <div style={{ borderLeft: "1px solid #d7d7d7de", height: "100%", marginLeft: "15px", display: "flex" }}>
                                                                <IconButton
                                                                  style={{ marginLeft: "5px" }}
                                                                  aria-label="delete discount"
                                                                  onClick={() => changeItem(
                                                                    {
                                                                      target: { value: "none", id: "discount" },
                                                                    },
                                                                    i
                                                                  )}
                                                                >
                                                                  <DeleteForeverIcon fontSize="small" />
                                                                </IconButton>
                                                              </div>
                                                            </InputAdornment>
                                                          }}

                                                        />
                                                      </Grid>

                                                    }

                                                  </Grid>

                                                  <Grid container item xs={12} style={{ justifyContent: "start" }}>
                                                    <span
                                                      // onClick={() => deleteItem(i)}
                                                      onClick={() => {
                                                        deleteItem(i);
                                                        setExpanded(false);
                                                      }}
                                                      style={{ display: "flex", cursor: "pointer", marginTop: "10px" }}>
                                                      <DeleteForeverIcon fontSize="small" />
                                                      <Typography
                                                        component="div"
                                                        variant="subtitle2"
                                                        style={{ marginLeft: "5px" }}
                                                      // onClick={() => setShowSendDate(true)}

                                                      >Leistung löschen</Typography>
                                                    </span>
                                                  </Grid>

                                                </Grid>
                                              </AccordionDetails>
                                            </Accordion>

                                          ))}
                                        </ReactSortable>
                                      </div>

                                      <Grid container item lg={12} spacing={1}>

                                        <Button
                                          variant="outlined"
                                          size="large"
                                          style={{ marginLeft: "5px", marginTop: "5px", marginBottom: "10px", textTransform: "none" }}
                                          startIcon={<AddCircleIcon color="primary" />}
                                          onClick={addItem}
                                        >
                                          Leistung hinzfügen
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>
                        }

                        {/* Global discount */}
                        {selectedClient &&
                          <Box
                            display="flex"
                            flexDirection="column"
                            // padding="25px 0px"
                            className="h-gap--20"
                          >
                            <Grid
                              container
                              spacing={1}
                              direction="row"
                              alignContent="flex-start"
                              alignItems="flex-start"
                              className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                            >
                              <Grid container item lg={12} spacing={3} >
                                <Grid container item direction="row" alignItems="center" justifyContent="space-between">
                                  <Typography variant="h6" component="h2">{t("PAGE_INVOICES.items.h2")}</Typography>
                                </Grid>

                                <Grid container item spacing={1}>
                                  <Grid item xs={12} >
                                    <Grid item xs={12}>
                                      <Grid container item xs={12}>
                                        <span
                                          onClick={() => {
                                            setShowGlobalDiscount(true);
                                            setValues({ ...values, discount: "absolute" })
                                          }}
                                          style={{ display: "flex", cursor: "pointer" }}>
                                          <MoneyOffIcon fontSize="small" color="primary" />
                                          <Typography
                                            component="div"
                                            variant="subtitle2"
                                            style={{ cursor: "pointer", marginLeft: "5px" }}
                                          >Rabatt auf Gesamtrechnung hinzufügen</Typography>
                                        </span>
                                      </Grid>

                                      {(showGlobalDiscount || values.discount !== "none") &&
                                        <Grid container item xs={12} spacing={1} style={{ alignItems: "center" }}>
                                          <Grid container item lg={6}>
                                            <FormControl variant="outlined" style={{ width: "100%" }}>
                                              <InputLabel id="globalDiscount">Rabatt auf Gesamtrechnung</InputLabel>
                                              <Select
                                                labelId="globalDiscount"
                                                id="discount"
                                                name="discount"
                                                value={values.discount}
                                                onChange={handleChange}
                                                label="Rabatt auf Gesamtrechnung"
                                              >
                                                <MenuItem value={"absolute"}>Rabatt in €</MenuItem>
                                                <MenuItem value={"percent"}>Rabatt in %</MenuItem>
                                              </Select>
                                            </FormControl>
                                          </Grid>

                                          {values.discount === "absolute" &&
                                            <Grid container item lg={4}>
                                              <NumericFormat
                                                required
                                                id="discountAbsolute"
                                                label="Rabattbetrag"
                                                variant="outlined"
                                                value={values.discountAbsolute}
                                                prefix="€ "
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                decimalScale={2}
                                                valueIsNumericString
                                                fixedDecimalScale
                                                customInput={TextField}
                                                onValueChange={({ value: v }) =>
                                                  handleChange({
                                                    target: { value: v, id: "discountAbsolute" },
                                                  },
                                                  )}
                                              />

                                            </Grid>
                                          }

                                          {values.discount === "percent" &&
                                            <Grid container item lg={2}>
                                              <TextField
                                                label="Rabatt %"
                                                id="discountPercent"
                                                variant="outlined"
                                                defaultValue={values.discountPercent}
                                                onChange={handleChange}
                                                InputProps={{
                                                  endAdornment: <InputAdornment position="end">
                                                    <span style={{ color: "inherit" }}>%</span>
                                                  </InputAdornment>
                                                }}

                                              />
                                            </Grid>
                                          }
                                          <Grid container item xl={2} xs={12}>
                                            <span
                                              onClick={() => {
                                                setShowGlobalDiscount(false);
                                                handleChange({
                                                  target: { value: "none", id: "discount" },
                                                });
                                              }}
                                              style={{ display: "flex", cursor: "pointer", marginLeft: "10px" }}>
                                              <DeleteForeverIcon fontSize="small" />
                                            </span>
                                          </Grid>

                                        </Grid>
                                      }
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>
                        }

                      </Box>
                    </form>
                  </>
                }

                {values.loading &&
                  <div className="center">
                    <CircularProgress />
                  </div>
                }
              </Container>
            </Container>
          </div>

          <InvoiceLivePreview
            openPdfPreview={open}
            setOpen={setOpen}
            sendMode={sendMode}
            client={selectedClient}
            items={items}
            clientNr={selectedClient.clientNumber}
            invoiceNr={values.invoiceNumber.state}
            invoiceDate={values.invoiceDate}
            invoiceDueDate={values.dueDate}
            invoiceSubtotal={values.subtotal}
            invoiceDiscount={values.discount}
            invoiceDiscountAbsolute={values.discountAbsolute}
            invoiceDiscountPercent={values.discountPercent}
            invoiceDiscountValue={values.discountValue}
            invoiceDiscountedSubtotal={values.discountedSubtotal}
            invoiceVatValues={values.vatValues}
            invoiceTotal={values.total}
            text={values.text.state}
            loading={values.loading}
            directDownload={false}
            invoiceValues={values}
            selectedClient={selectedClient}
            userId={userId}
            setInvoicesAtom={setInvoicesAtom}
            setInvoicesFilteredAtom={setInvoicesFilteredAtom}
            sendState={values.sendState.state}
          />
        </div>


       
      </React.Fragment>
    </React.Fragment>
  );
};

export default InvoiceDialog;

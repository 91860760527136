import React, { useEffect, useState, useContext } from "react";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import userAtomState from "./../../_atoms/userAtom";
import openInvoiceDialogAtomState from "../../_atoms/openInvoiceDialogAtom";
import settingsAtomState from "../../_atoms/settingsAtom";
import designerAtomState from "../../_atoms/designerAtom";


// IMPORT hooks & context
import { useTranslation } from "react-i18next";

// import PreviewInvoice from "./../../invoices/pages/PreviewInvoice";

import { usePDF, Page, Text, View, Image, Document, StyleSheet, Font } from '@react-pdf/renderer';

import { useHttpClient } from "./../../shared/hooks/http-hook";
import { AuthContext } from "./../../shared/context/auth-context";

import {
  TextField,
  Button,
  Grid,
  Paper,
  InputAdornment,
  Link,
  MenuItem,
  SvgIcon,
  Chip
} from "@material-ui/core";

// IMPORT components
import InvoicePdfPreview from "./InvoicePdfPreview";
import AnimationSuccess from "./../../shared/components/UIElements/animations/AnimationSuccess"
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';


import "./InvoiceLivePreview.scss"

import Inter from "./../../_assets/fonts/inter/Inter-Regular.ttf";
import InterBold from "./../../_assets/fonts/inter/Inter-Bold.ttf";
import Jakarta from "./../../_assets/fonts/plusjakartasans/PlusJakartaSans-Regular.ttf";


import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import AttachmentIcon from '@material-ui/icons/Attachment';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';



import InvoicesScheduleSend from "./InvoicesScheduleSend";




const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


// function getWindowDimensions() {
//   const { innerWidth: width, innerHeight: height } = window;
//   return {
//     width,
//     height
//   };
// }

// TODO: 
// Create 5 different templates as starter basis
// Background color
// Background image
// Font family
// Font sizes
// Font color
// Show / hide dividers
// Letter spacing
// ??? Arrangement of sections

// FIXME:  



const InvoiceLivePreview = ({
  openPdfPreview,
  setOpen,
  sendMode,
  client,
  items,
  invoiceId,
  invoiceNr,
  invoiceDate,
  invoiceDueDate,
  invoiceSubtotal,
  invoiceDiscount,
  invoiceDiscountAbsolute,
  invoiceDiscountPercent,
  invoiceDiscountValue,
  invoiceDiscountedSubtotal,
  invoiceVatValues,
  invoiceTotal,
  designer,
  headline,
  text,
  clientNr,
  loading,
  directDownload,
  handleClose,
  invoiceValues,
  selectedClient,
  userId,
  setInvoicesAtom,
  setInvoicesFilteredAtom,
  sendState,
  reminderRequestCharge,
  isEstimate,
  type,
  showVat,
  showSignature,
  showAdditionalText,
  additionalText
}) => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  // const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);

  // GLOBAL STATE (RECOIL)
  const [userAtom] = useRecoilState(userAtomState);
  const [openInvoiceDialogAtom, setOpenInvoiceDialogAtom] = useRecoilState(openInvoiceDialogAtomState);
  const [designerAtom] = useRecoilState(designerAtomState);
  const [settingsAtom] = useRecoilState(settingsAtomState);
  const [settingsAtomInitial] = useRecoilState(settingsAtomState);
  const [editorStateMessage, setEditorStateMessage] = useState(null);

  const [cssZoom] = useState("100%");

  const [emailSent, setEmailSent] = useState(false);
  const [emailScheduled, setEmailScheduled] = useState(false);
  const [zoom, setZoom] = useState(false);

  const [, setEditorState] = useState(null);

  const [values, setValues] = useState({
    country: "",
    countryCode: "",
    items: [],
    headline: "",
    text: "",
    clientNr: "",
    invoiceNr: "",
    invoiceDate: "",
    dueDate: "",
    sendState: "",
    sendDate: new Date(),
    discount: "",
    discountAbsolute: "",
    discountPercent: "",
    discountValue: 0,
    reminderRequestCharge: 0,
    subject: "",
    isLoading: true
  });

  // RECIPIENT(S)
  const [recipients, setRecipients] = useState([]);
  const [recipient, setRecipient] = useState({
    state: "",
    isEmail: false,
    error: false,
  });

  // RECIPIENT(S) CC
  const [recipientsCc, setRecipientsCc] = useState([]);
  const [recipientCc, setRecipientCc] = useState({
    state: "",
    isEmail: false,
    error: false,
  });

  // RECIPIENT(S) BCC
  const [recipientsBcc, setRecipientsBcc] = useState([]);
  const [recipientBcc, setRecipientBcc] = useState({
    state: "",
    isEmail: false,
    error: false,
  });
  
  const [emailEnterState, setEmailEnterState] = useState("recipient"); // recipient, cc, bcc


  useEffect(() => {
    try {
      const country = JSON.parse(client.country);
      setValues({
        ...values,
        country: country.label,
        countryCode: country.code,
        headline: headline,
        text: text,
        items: items,
        clientNr: clientNr,
        invoiceNr: invoiceNr,
        invoiceDate: invoiceDate,
        dueDate: invoiceDueDate,
        sendState: sendState,
        discount: invoiceDiscount,
        discountAbsolute: invoiceDiscountAbsolute,
        discountPercent: invoiceDiscountPercent,
        discountValue: invoiceDiscountValue,
        reminderRequestCharge: reminderRequestCharge,
        subject: `${userAtom.data.company} Rechnung Nr. ${invoiceNrForDownload(values.invoiceNr)}`,
        type: type,
        showVat: showVat,
        showSignature: showSignature,
        showAdditonalText: showAdditionalText,
        additionalText: additionalText,
        isLoading: false
      })

    } catch (err) {
      // 👇️ This runs
      // console.log('Error: ', err.message);
    }
  }, [
    client,
    items,
    headline,
    text,
    invoiceDate,
    invoiceDueDate,
    clientNr,
    invoiceNr,
    sendState,
    invoiceDiscount,
    invoiceDiscountAbsolute,
    invoiceDiscountPercent,
    invoiceDiscountValue,
    reminderRequestCharge,
    type,
    showVat,
    showSignature,
  ]);

  Font.register({
    family: "Inter",
    fonts: [
      {
        src: Inter,
      },
      {
        src: InterBold,
        fontWeight: 'bold',
      },
      // {
      //   src: FontUbuntu700,
      //   fontWeight: 'normal',
      //   fontStyle: 'italic',
      // },
    ],
  });

  Font.register({
    family: "Plus Jakarta Sans",
    format: "truetype",
    src: Jakarta
  });



  const styles = StyleSheet.create({
    // DOCUMENT
    document: {
      zoom: cssZoom,
    },

    // PAGE
    page: {
      position: "relative",
      padding: "50px 50px 100px 50px",
      boxShadow: "0px 3px 3px -2px #545ea51a, 0px 3px 4px 0px #545ea51a, 0px 1px 8px 0px #545ea51a",
      backgroundColor: designerAtom.colorBgBody,
      // zoom: cssZoom,
      fontFamily: "Inter"
    },

    // VIEW WRAPPER
    pageView: {
      zoom: "70%",
      // paddingBottom: "100px",
    },

    // SENDER 
    sender: {
      paddingBottom: "20px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      flexGrow: 1,
      fontSize: "10px",
      marginBottom: "10px",
      logo: {
        text: {
          fontSize: "18px",
          fontFamily: "Inter"
        },
        onscreen: {
          maxHeight: "75px",
          width: "auto",
        },
        pdf: {
          display: "block",
          objectFit: "contain",
          objectPosition: "left top",
          height: "50px"
        }
      },
      viewImagePdf: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        flex: 5
      },
      view: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        flex: 5
      },
      text: {
        display: "flex",
        alignSelf: "flex-end",
        lineHeight: "1.2"
      },
      textBold: {
        display: "flex",
        alignSelf: "flex-end",
        lineHeight: "1.2",
        fontWeight: "bold",

      }
    },

    // RECIPIENT 
    recipient: {
      display: "flex",
      flexDirection: "row",
      fontSize: "10px",
      borderRadius: "6px",
      marginBottom:"20px",
      view: {
        padding: "10px 0",
        display: "flex",
        flexDirection: "row",
        flexGrow: 1
      },
      column: {
        width: "50%",
      },
      text: {
        display: "flex",
        lineHeight: "1.2"
      },
      textBold: {
        display: "flex",
        lineHeight: "1.2",
        fontWeight: "bold",
      },
      textRight: {
        display: "flex",
        lineHeight: "1.2",
        justifyContent: "flex-end",
        textAlign: "right"
      },
      textRightBold: {
        display: "flex",
        lineHeight: "1.2",
        justifyContent: "flex-end",
        fontWeight: "bold",
        textAlign: "right"
      }
    },
    // h1
    h1: {
      padding: "10px 0 30px 0",
      view: {
        display: "flex",
        fontSize: "16px",
      },
      text: {
        paddingTop: "5px",
        fontSize: "8px"
      }
    },

    // ITEMS
    items: {
      fontSize: "8px",
      headlines: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        gap: "5px",
        justifyContent: "space-between",
        borderBottom: "1px solid black",
        paddingBottom: "10px",
      },
      view: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        gap: "5px",
        justifyContent: "space-between",
        borderBottom: "1px solid lightgrey",
        padding: "10px 0",
      },
      headlineAlignLeft: {
        display: "flex",
        lineHeight: "1.2",
        justifyContent: "flex-start",
        alignSelf: "flex-end",
        textAlign: "left",
        flex: 3,
        fontWeight: "bold"
      },
      headlineAlignRight: {
        display: "flex",
        lineHeight: "1.2",
        justifyContent: "flex-end",
        alignSelf: "flex-end",
        textAlign: "right",
        flex: 1.5,
        fontWeight: "bold"
      },
      textAlignLeft: {
        wordBreak: "break-word",
        display: "flex",
        lineHeight: "1.5",
        flex: 3
      },
      textAlignRight: {
        display: "flex",
        lineHeight: "1.5",
        justifyContent: "flex-end",
        alignSelf: "flex-start",
        textAlign: "right",
        flex: 1.5
      },
    },

    // SUMMARY
    summary: {
      fontSize: "8px",
      display: "flex",
      flexDirection: "column",
      padding: "20px 0 0 0",
      view: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        justifyContent: "flex-end",
        headline: {
          display: "flex",
          lineHeight: "1.5",
          justifyContent: "flex-end",
          alignSelf: "flex-end",
          textAlign: "right",
          paddingRight: "10px",
          flex: 6,
        },
        headlineBold: {
          display: "flex",
          lineHeight: "1.5",
          justifyContent: "flex-end",
          alignSelf: "flex-end",
          textAlign: "right",
          paddingRight: "10px",
          fontWeight: "bold",
          flex: 6,
        },
        text: {
          display: "flex",
          lineHeight: "1.5",
          justifyContent: "flex-end",
          alignSelf: "flex-end",
          textAlign: "right",
          flex: 1
        },
        textBold: {
          display: "flex",
          lineHeight: "1.5",
          justifyContent: "flex-end",
          alignSelf: "flex-end",
          textAlign: "right",
          fontWeight: "bold",
          flex: 1
        }
      },
    },

    // FOOTNOTES
    footnotes: {
      fontSize: "8px",
      display: "flex",
      flexDirection: "column",
      padding: "30px 0",
      view: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        gap: "5px",
        justifyContent: "space-between",
      },
      viewBorder: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        gap: "5px",
        justifyContent: "space-between",
        borderTop: "1px solid black",
        maxWidth: "200px",
        paddingTop: "5px",
        fontWeight: "bold",
        fontSize: "8px",
      },
      viewBorderFirst: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        gap: "5px",
        justifyContent: "space-between",
        borderTop: "1px solid black",
        maxWidth: "200px",
        marginBottom: "40px",
        paddingTop: "5px",
        fontWeight: "bold",
        fontSize: "8px",
      },
    },

    // FOOTER
    footer: {
      zoom: "70%",
      position: "absolute",
      bottom: "0%",
      padding: "10px 50px 50px 50px",
      fontSize: "8px",
      // width: "100%",
      display: "flex",
      flexDirection: "column",
    },
  })


  const [logo, setLogo] = React.useState(null);


  useEffect(() => {
    // console.log(userAtom.data.logo)

    let blob = new Blob([userAtom.data.logo], {type: 'image'});
    let link = URL.createObjectURL(blob);

    setLogo(userAtom.data.logo)

  }, [userAtom])


  // function blobToDataURL(blob, callback) {
  //   var fileReader = new FileReader();
  //   fileReader.onload = function(e) {callback(e.target.result);}
  //   fileReader.readAsDataURL(blob);
  //   return fileReader;
  // }
  // console.log(blobToDataURL(userAtom.data.logo))


  let MyDoc = (
    <Document style={styles.document}>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.pageView}>
          <View style={styles.sender} fixed>
            

          {logo !== ""  ?
              <>
                {/* ON-SCREEN image */}
                <img
                  src={logo}
                  // src={`${process.env.REACT_APP_BACKEND_URL}/${userAtom.data.image}`}
                  alt={`${t("APP.name")} Logo`}
                  style={styles.sender.logo.onscreen}
                />

                {/* PDF image */}
                <View style={styles.sender.viewImagePdf}>
                  <Image
                    src={logo}
                    // src={`${process.env.REACT_APP_BACKEND_URL}/${userAtom.data.image}`}
                    style={styles.sender.logo.pdf}
                  />
                </View>
              </>
              :
                <View style={styles.h1}>
                  <View style={styles.h1.view}>
                    <Text>{userAtom.data.company}</Text>
                  </View>
                </View>
            }
            
            <View style={styles.sender.view}>
              <Text style={styles.sender.textBold}>{userAtom.data.company}</Text>
              <Text style={styles.sender.text}>{userAtom.data.street} {userAtom.data.streetNr}</Text>
              <Text style={styles.sender.text}>{userAtom.data.zip} {userAtom.data.city}</Text>
              <Text style={styles.sender.text}>{JSON.parse(userAtom.data.country).label}</Text>
            </View>
          </View>

          
          <View style={styles.recipient}>
            <View style={styles.recipient.view}>
              <div style={styles.recipient.column}>
                <Text style={styles.recipient.textBold}>{client.company}</Text>
                <Text style={styles.recipient.text}>{client.street} {client.streetNr}</Text>
                <Text style={styles.recipient.text}>{client.zip} {client.city}</Text>
                <Text style={styles.recipient.text}>{values.country}</Text>
                {client.vat !== "" &&
                <Text style={styles.recipient.text}>{`UID: ${values.countryCode === "AT" ? "ATU" : "DE"} ${client.vat}`}</Text>
                }
                </div>

              <div style={styles.recipient.column}>
                {!isEstimate ?
                <Text style={styles.recipient.textRightBold}>{t("FIELDS.invoiceNumber.label")}: {values.invoiceNr}</Text>
                : <Text style={styles.recipient.textRightBold}>Nr.: {values.invoiceNr}</Text>
                }
                {!isEstimate &&
                <Text style={styles.recipient.textRight}>{t("FIELDS.clientNumber.label")}: {values.clientNr}</Text>
                }
                <Text style={styles.recipient.textRight}>{!isEstimate ? "Rechnungsdatum" : "Datum"}: {`${invoiceDate.getDate() > 0 && invoiceDate.getDate() < 10 ? `0${invoiceDate.getDate()}` : invoiceDate.getDate()}.${(invoiceDate.getMonth() + 1) > 0 && (invoiceDate.getMonth() + 1) < 10 ? `0${invoiceDate.getMonth() + 1}` : invoiceDate.getMonth() + 1}.${invoiceDate.getFullYear()}`}</Text>
                {!isEstimate && <Text style={styles.recipient.textRight}>{t("FIELDS.invoiceDueDate.label")}: {`${invoiceDueDate.getDate() > 0 && invoiceDueDate.getDate() < 10 ? `0${invoiceDueDate.getDate()}` : invoiceDueDate.getDate()}.${(invoiceDueDate.getMonth() + 1) > 0 && (invoiceDueDate.getMonth() + 1) < 10 ? `0${invoiceDueDate.getMonth() + 1}` : invoiceDueDate.getMonth() + 1}.${invoiceDueDate.getFullYear()}`}</Text>}
              </div>
            </View>
          </View>
          

          <View style={styles.h1}>
            <View style={styles.h1.view}>
              <Text>{values.headline}</Text>
            </View>
                
            <View style={styles.h1.text}>
              <Text>{values.text}</Text>
            </View>
          </View>

          <View style={styles.items}>
            <View style={styles.items.headlines}>
              <Text style={styles.items.headlineAlignLeft}>Leistung</Text>
              {values.items.some(item => item.quantity > 1) &&
                <Text style={styles.items.headlineAlignRight}>Einzelpreis</Text>
              }
              {/* <Text style={styles.items.headlineAlignRight}>Preis<br/>{"\n"}(gesamt)</Text> */}
              {values.items.some(item => (item.unit !== "" && item.unit !== undefined)) &&
                <Text style={styles.items.headlineAlignRight}>Menge</Text>
              }
              {values.items.some(item => (item.quantity > 1 || item.discountValue !== 0)) &&
                <Text style={styles.items.headlineAlignRight}>Preis</Text>
              }
              {values.items.some(item => item.discountValue !== 0) &&
                <Text style={styles.items.headlineAlignRight}>Rabatt</Text>
              }
              {(!isEstimate || (isEstimate && showVat)) &&
              <>
              <Text style={styles.items.headlineAlignRight}>USt.-Satz</Text>
              <Text style={styles.items.headlineAlignRight}>USt.</Text>
              </>
              }
              <Text style={styles.items.headlineAlignRight}>Netto</Text>
            </View>
          </View>

          
          <>
          {values.items.length > 0 && (values.items).map((item, i) => (
            <View style={styles.items} key={i}>
              <View style={{
                        display: "flex",
                        flexDirection: "row",
                        flexGrow: 1,
                        gap: "5px",
                        justifyContent: "space-between",
                        borderBottom: values.items.length !== i + 1 ? "1px solid lightgrey" : "1px solid black",
                        padding: "10px 0",
                }}>
                <Text style={styles.items.textAlignLeft}>{item.name}</Text>
                {values.items.some(item => item.quantity > 1) &&
                  <Text style={styles.items.textAlignRight}>{`${(item.price * "1").toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</Text>
                }
                {values.items.some(item => (item.unit !== "" && item.unit !== undefined)) && (item.unit !== "" && item.unit !== undefined ) &&
                  <Text style={styles.items.textAlignRight}>{item.quantity === "" ? 0 : JSON.parse(item.quantity).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {item.unit !== "" && item.unit}</Text>
                }
                {values.items.some(item => (item.unit !== "" && item.unit !== undefined)) && (item.unit === "" || item.unit === undefined ) &&
                  <Text style={styles.items.textAlignRight}></Text>
                }
                {values.items.some(item => (item.quantity > 1 || item.discountValue !== 0)) &&
                  <Text style={styles.items.textAlignRight}>{`${(item.total).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</Text>
                }
                {values.items.some(item => item.discountValue !== 0) &&
                  <Text style={styles.items.textAlignRight}>{item.discount === "percent" ? `(${item.discountPercent}%) ${(JSON.parse(item.discountValue)).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : `${(JSON.parse(item.discountValue)).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</Text>
                }
                {(!isEstimate || (isEstimate && showVat)) &&
                <>
                <Text style={styles.items.textAlignRight}>{item.vatPercent !== "" ? item.vatPercent : client.vatPercent !== "" ? client.vatPercent : "0"} %</Text>
                <Text style={styles.items.textAlignRight}>{((item.discountedTotal * item.vatPercent)/100).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
                </>
                }
                <Text style={styles.items.textAlignRight}>{`${(item.discountedTotal).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</Text>
              </View>
            </View>
          ))}
          </>
          
          <>
          <View style={styles.summary}>
            <View style={styles.summary.view}>
              <Text style={styles.summary.view.headline}>Betrag (netto)</Text>
              <Text style={styles.summary.view.text}>{`${(invoiceSubtotal).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</Text>
            </View>

            {(values.discount !== "none" && values.discountValue > 0) &&
              <>
                <View style={styles.summary.view}>
                  <Text style={styles.summary.view.headline}>{values.discount === "percent" && `${values.discountPercent} %`}{" Rabatt "}</Text>
                  <Text style={styles.summary.view.text}>{`- ${(JSON.parse(values.discountValue)).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</Text>
                </View>

                <View style={styles.summary.view}>
                  <Text style={styles.summary.view.headline}>Rabattierter Betrag (netto)</Text>
                  <Text style={styles.summary.view.text}>{`${(invoiceDiscountedSubtotal).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</Text>
                </View>
              </>
            }

            {(values.reminderRequestCharge !== 0 && values.reminderRequestCharge !== "0" && values.reminderRequestCharge !== 0.00 && values.reminderRequestCharge !== "0.00" && values.reminderRequestCharge !== "") &&
            <View style={styles.summary.view}>
              <Text style={styles.summary.view.headline}>Mahnspesen</Text>
              <Text style={styles.summary.view.text}>{`${(parseFloat(values.reminderRequestCharge)).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</Text>
            </View>
            }
          </View>

          {(!isEstimate || (isEstimate && showVat)) &&
          <>
          <View style={styles.summary}>
            {Object.keys(invoiceVatValues).map((keyName, i) => (
              <View style={styles.summary.view} key={i}>
                <Text style={styles.summary.view.headline}>USt. {keyName} %</Text>
                <Text style={styles.summary.view.text}>{`${(invoiceVatValues[keyName][1]).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</Text>
              </View>
            ))}
          </View>

          <View style={styles.summary}>
            <View style={styles.summary.view}>
              <Text style={styles.summary.view.headlineBold}>Rechnungsbetrag (brutto)</Text>
              <Text style={styles.summary.view.textBold}>{`${(invoiceTotal).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</Text>
            </View>
          </View>
          </>
          }

          <View style={styles.footnotes}>
            <View style={styles.footnotes.view}>
              <Text style={styles.items.textAlignRight}>Alle Preise in Euro (€)</Text>
            </View>
          </View>


          {(isEstimate && showSignature) &&
          <>
            <View style={styles.footnotes.view}>
              <Text style={styles.footnotes.viewBorderFirst}>Ort und Datum</Text>
            </View>

            <View style={styles.footnotes.view}>
              <Text style={styles.footnotes.viewBorder}>{client.company}</Text>
            </View>
            </>
          }

          {(showAdditionalText && additionalText !== undefined && additionalText !== "") &&
          <>
            <View style={styles.footnotes.view}>
              <Text style={styles.footnotes}>{additionalText}</Text>
            </View>
            </>
          }
          </>
          
        </View>

        <View style={styles.footer} fixed>
          <View style={styles.footer.view} className="invoice-footer">
                <Text style={styles.footer.text}>{userAtom.data.company}, {userAtom.data.street} {userAtom.data.streetNr}, {userAtom.data.zip} {userAtom.data.city}, {JSON.parse(userAtom.data.country).label}{userAtom.data.fbnr !== "" && ` | FN ${userAtom.data.fbnr}`}{userAtom.data.vat !== "" && ` | UID: ${JSON.parse(userAtom.data.country).code === "AT" ? "ATU" : "DE"} ${userAtom.data.vat}`}</Text>
          </View>

          {(userAtom.data.phone !== "" || userAtom.data.mobile !== "" || userAtom.data.companyEmail !== "" || userAtom.data.companyWebsite !== "") &&
          <View style={styles.footer.view} className="invoice-footer">
                <Text style={styles.footer.text}>{userAtom.data.phone !== "" && `Tel.: ${userAtom.data.phone}, `}{userAtom.data.mobile !== "" && `Mobil: ${userAtom.data.mobile}, `}{userAtom.data.companyEmail !== "" && `Email: ${userAtom.data.companyEmail}, `}{userAtom.data.companyWebsite !== "" && `Web: ${userAtom.data.companyWebsite}`}</Text>
          </View>
          }

          {(userAtom.data.bank !== "" || userAtom.data.bankAccount !== "" || userAtom.data.bankAccountOwner !== "" || userAtom.data.bankZip !== "" || userAtom.data.iban !== "" || userAtom.data.bic !== "") &&
          <View style={styles.footer.view} className="invoice-footer">
                <Text style={styles.footer.text}>Bankverbindung: {userAtom.data.bank !== "" && `${userAtom.data.bank}, `}{userAtom.data.bankAccount !== "" && `Konto-Nr.: ${userAtom.data.bankAccount}, `}{userAtom.data.bankZip !== "" && `BLZ: ${userAtom.data.bankZip}, `}{userAtom.data.iban !== "" && `IBAN: ${userAtom.data.iban}, `}{userAtom.data.bic !== "" && `BIC: ${userAtom.data.bic}`}</Text>
          </View>
          }

        </View>
      </Page>
    </Document>
  )

  // Variable has to be placed below MyDoc and above updateInstance
  const [instance, updateInstance] = usePDF({ document: MyDoc });

  // useEffect(() => {
  //   if (settingsAtom.invoicesSelectedTheme === "classic") {
  //     // Default "classic" before any theme is saved
  //     setDesignerAtom(invoiceTheme(settingsAtom.invoicesSelectedTheme));
  //   } else {
  //       DB_GET_THEME(settingsAtom, setDesignerAtom, auth, sendRequest)
  //   }
  // }, [])

  // Update pdf content when values change because of input
  // DO NOT forget to add cssX dependencies in Order to change the the style of the PDF that is sent via Email!!
  // ALL styles that are only applied to the screen and not to the PDF that is sent, should NOT be added as dependency but in .scss!!!
  useEffect(() => {
    // !loading && updateInstance(MyDoc);
    updateInstance(MyDoc);
  }, [values, designerAtom, client, loading ])

  // useEffect(() => {
  //   function handleResize() {
  //     setWindowDimensions(getWindowDimensions());
  //   }
  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  // Calculate zoom depending on screen width
  // useEffect(() => {
  //   const zoomFactor = (100 / 1920 * windowDimensions.width) - 8;
  //   if (windowDimensions.width < 1920) {
  //     setCssZoom(`${zoomFactor}%`)
  //   } else {
  //     setCssZoom(`${zoomFactor}%`)
  //     // setCssZoom(`100%`)
  //   }
  // }, [windowDimensions, cssZoom]);

  //!!!!!!!!!!!!! ALTERNATIVE !!!!!!!!!!!!!!!!!!!!!!!
  // const [instance, updateInstance] = usePDF({
  //   document: (
  //     <PDFDocument projectsData={projectsData} projectId={projectId} />
  //   ),
  // });

  // console.log(invoice)

  // SET CURRENT pdf blob even the invoice is not sent
  useEffect(() => {
    if(openInvoiceDialogAtom.open === true) {
      const fetchBlobAndConvertToBase64 = async () => {
        try {
          const blobToBase64 = (blob) => {
            return new Promise((resolve) => {
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = () => {
                resolve(reader.result);
              };
            });
          };
  
          let jsonString;
  
          try {
            const b64 = await blobToBase64(instance.blob);
            console.log(b64)
            jsonString = b64;
            localStorage.setItem('jsonString', JSON.stringify({
              blob: jsonString,
              filename: `Rechnung_${userAtom.data.company}_${invoiceNrForDownload(values.invoiceNr)}_${client.company}`,
            }));
          } catch (error) {
            console.error('Error converting blob to base64:', error);
          }
        } catch (error) {
          console.error('Error fetching blob:', error);
        }
      };
      fetchBlobAndConvertToBase64();
    } else {
      localStorage.removeItem('jsonString')
    }
  }, [instance, updateInstance]);





  // SEND immediately
  const send = async (scheduled, sendDate) => {
    if (JSON.stringify(recipients) !== "[]") {
      try {
        await fetch(instance.blob.url)
          .then(r => r.blob())
          .then(blob => {
            const blobToBase64 = (blob) => {
              return new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                  resolve(reader.result);
                };
              });
            };

            let jsonString;

            (async () => {
              try {
                const b64 = await blobToBase64(instance.blob);
                jsonString = JSON.stringify({ blob: b64 });

                const formData = new FormData();
                // formData.append("image", instance.blob);
                formData.append("invoiceId", invoiceId);
                formData.append("jsonString", jsonString);
                formData.append("subject", values.subject);
                formData.append("filename", `Rechnung_${userAtom.data.company}_${invoiceNrForDownload(values.invoiceNr)}_${client.company}`)
                formData.append("email", JSON.stringify(recipients));
                formData.append("cc", JSON.stringify(recipientsCc));
                formData.append("bcc", JSON.stringify(recipientsBcc));
                formData.append("id", settingsAtom._id);
                formData.append("type", invoiceValues.type);
                
                editorStateMessage !== null
                  ? formData.append("message", draftToHtml(convertToRaw(editorStateMessage.getCurrentContent())))
                  : formData.append("message", "")
                
                if(scheduled !== "scheduled") {
                  await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL_API}/invoices/test`,
                    "POST",
                    formData,
                    {
                      // "Content-Type": "application/json",
                      Authorization: "Bearer " + auth.token,
                    }
                  )
                    .then(() => setEmailSent(true))
                    .then(() => setZoom(!zoom))
                    .then(() => invoiceHandler("sent", sendDate, jsonString))
                } else {
                  invoiceHandler("scheduled", sendDate, jsonString);
                  setEmailScheduled(true);
                }

              } catch (err) {
                console.log(err)
              }
            })();
          });
      } catch (err) {
        console.log(err)
      }
    } else {
      setRecipient({
        ...recipient,
        error: true
      })
    }
  };

  // editorState !== null && console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())))

  function PdfPreviewIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d=" M 18.5 10.5 L 17 10.5 L 17 11.5 L 18.5 11.5 L 18.5 13 L 17 13 L 17 15 L 15.5 15 L 15.5 9 L 18.5 9 L 18.5 10.5 L 18.5 10.5 L 18.5 10.5 L 18.5 10.5 Z  M 7 11.5 L 8 11.5 L 8 10.5 L 7 10.5 L 7 11.5 L 7 11.5 L 7 11.5 L 7 11.5 Z  M 9.5 11.5 C 9.5 12.33 8.83 13 8 13 L 7 13 L 7 15 L 5.5 15 L 5.5 9 L 8 9 C 8.83 9 9.5 9.67 9.5 10.5 L 9.5 11.5 L 9.5 11.5 L 9.5 11.5 L 9.5 11.5 Z  M 18 4 L 6 4 C 4.9 4 4 4.9 4 6 L 4 18 C 4 19.1 4.9 20 6 20 L 18 20 C 19.1 20 20 19.1 20 18 L 20 6 C 20 4.9 19.1 4 18 4 L 18 4 L 18 4 Z  M 12 13.5 L 13 13.5 L 13 10.5 L 12 10.5 L 12 13.5 L 12 13.5 L 12 13.5 Z  M 14.5 13.5 C 14.5 14.33 13.83 15 13 15 L 10.5 15 L 10.5 9 L 13 9 C 13.83 9 14.5 9.67 14.5 10.5 L 14.5 13.5 L 14.5 13.5 L 14.5 13.5 Z " />
      </SvgIcon>
    );
  }



  // SCHEDULE send
  const invoiceHandler = async (sendState, sendDate, jsonString) => {
    // event.preventDefault();

    if (
      JSON.stringify(recipients) !== "[]"
    ) {

      try {
        const formData = new FormData();
        formData.append("clientData", JSON.stringify(selectedClient));
        formData.append("items", JSON.stringify(items));
        formData.append("invoiceNr", JSON.stringify(invoiceValues.invoiceNumber.state));
        formData.append("invoiceDate", invoiceValues.invoiceDate);
        formData.append("sendDate", sendState === "scheduled" ? sendDate : new Date());
        formData.append("dueDate", invoiceValues.dueDate);
        formData.append("payDate", invoiceValues.payDate);
        formData.append("subtotal", JSON.stringify(invoiceValues.subtotal));
        formData.append("vatValues", JSON.stringify(invoiceValues.vatValues));
        formData.append("total", JSON.stringify(invoiceValues.total));
        formData.append("discount", invoiceValues.discount);
        formData.append("discountAbsolute", invoiceValues.discountAbsolute);
        formData.append("discountPercent", invoiceValues.discountPercent);
        formData.append("discountValue", invoiceValues.discountValue);
        formData.append("discountedSubtotal", JSON.stringify(invoiceValues.discountedSubtotal));
        formData.append("discountedTotal", JSON.stringify(invoiceValues.discountedTotal));
        formData.append("status", invoiceValues.status.state);
        formData.append("sendState", sendState);
        formData.append("payState", invoiceValues.payState.state);
        formData.append("recurring", invoiceValues.recurring);
        formData.append("headline", invoiceValues.headline.state);
        formData.append("text", invoiceValues.text.state);
        formData.append("type", invoiceValues.type);
        formData.append("reminderRequestCharge", invoiceValues.reminderRequestCharge === "" ? "0" : invoiceValues.reminderRequestCharge);
        formData.append("showAdditionalText", invoiceValues.showAdditionalText);
        formData.append("additionalText", invoiceValues.additionalText);
        formData.append("blob", sendState === "scheduled" ? JSON.stringify({
          blob: jsonString,
          subject: values.subject,
          filename: `Rechnung_${userAtom.data.company}_${invoiceNrForDownload(values.invoiceNr)}_${client.company}`,
          message: draftToHtml(convertToRaw(editorStateMessage.getCurrentContent())),
          email: JSON.stringify(recipients),
          cc: JSON.stringify(recipientsCc),
          bcc: JSON.stringify(recipientsBcc),
          settingId: settingsAtom._id,
          invoiceId: invoiceId,
          type: invoiceValues.type,
        }): JSON.stringify({blob: ""}));
        
        
          // UPDATE EXISTING INVOICE
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL_API}/invoices/${openInvoiceDialogAtom.invoiceId}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          )

        // FETCH updated Invoices List
        try {
          const resInvoices = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL_API}/invoices/user/${userId}`,
            "GET",
            null,
            {
              Authorization: `Bearer ${auth.token}`
            }
          );
          setInvoicesAtom(resInvoices.invoices);
          setInvoicesFilteredAtom(resInvoices.invoices);
          // enqueueSnackbar(t("FIELDS.saved"), { variant: "success" });
        } catch (err) {
          // SERVER ERROR
          // enqueueSnackbar(t("ERRORS.save_server"), { variant: "error" });
        }
      } catch (err) {
        // SERVER ERROR
        console.log(err)
        // enqueueSnackbar(t("ERRORS.save_server"), { variant: "error" });
      }
    } else {
      // enqueueSnackbar(t("ERRORS.save_form"), { variant: "error" });
    }
  };

  const invoiceNrForDownload = (invoiceNumber) => {
    let nr = ""
    for (const [key, value] of Object.entries(invoiceNumber)) {
      nr += value
    }
    return nr;
  }

  useEffect(() => {
    if(settingsAtomInitial.invoicesEmailText === undefined) {
      setEditorStateMessage(EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(`
          <p>Sehr geehrte Damen und Herren!</p>
          <p>Anbei senden wir Ihnen die Rechnung für die erbrachte Leistung und freuen uns auf erfolgreiche weitere Zusammenarbeit.</p>
          <p>Mit freundlichen Grüßen</br>${userAtom.data.company}</p>
          <p>Bitte antworten Sie nicht direkt auf diese automatisch generierte Nachricht. Bei Fragen sind wir unter den auf der Rechnung angegebenen Kontaktdaten gerne für Sie da.</p>
          `)
        )))
    } else {
      setEditorStateMessage(EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(settingsAtomInitial.invoicesEmailText)
        )))
    }
  }, [settingsAtomInitial]);






  // Recipients
  useEffect(() => {
    setRecipients(() => [
      client.email
    ]);
  }, [client.email]);

  const handleChangeRecipient = (event, type) => {
      setRecipient({
        ...recipient,
        state: event.target.value,
        isEmail: (/^\S+@\S+\.\S+$/).test(event.target.value) ? true : false
    })
  };

  const addRecipient = () => {
    setRecipients((items) => [
        ...items,
        recipient.state
    ]);
    setRecipient({
        ...recipient,
        state: "",
        isEmail: false,
        error: false,
    });
  };

  const handleDeleteRecipient = (index) => {
    setRecipients((current) =>
        current.filter((item, i) => {
            return i !== index;
        })
    );
  };

  // CC
  useEffect(() => {
    setRecipientsCc(() => JSON.parse(settingsAtom.cc));
  }, [settingsAtom]);

  const handleChangeRecipientCc = (event, type) => {
    setRecipientCc({
      ...recipientCc,
      state: event.target.value,
      isEmail: (/^\S+@\S+\.\S+$/).test(event.target.value) ? true : false
    })
  };

  const addRecipientCc = () => {
    setRecipientsCc((items) => [
        ...items,
        recipientCc.state
    ]);
    setRecipientCc({
        ...recipientCc,
        state: "",
        isEmail: false,
        error: false,
    });
  };

  const handleDeleteRecipientCc = (index) => {
    setRecipientsCc((current) =>
        current.filter((item, i) => {
            return i !== index;
        })
    );
  };

    // BCC
    useEffect(() => {
      setRecipientsBcc(() => JSON.parse(settingsAtom.bcc));
    }, [settingsAtom]);
  
    const handleChangeRecipientBcc = (event, type) => {
      setRecipientBcc({
        ...recipientBcc,
        state: event.target.value,
        isEmail: (/^\S+@\S+\.\S+$/).test(event.target.value) ? true : false
      })
    };
  
    const addRecipientBcc = () => {
      setRecipientsBcc((items) => [
          ...items,
          recipientBcc.state
      ]);
      setRecipientBcc({
          ...recipientBcc,
          state: "",
          isEmail: false,
          error: false,
      });
    };
  
    const handleDeleteRecipientBcc = (index) => {
      setRecipientsBcc((current) =>
          current.filter((item, i) => {
              return i !== index;
          })
      );
    };


  return (
    <div>

      {/* <div className="invoice-paper bg--1">
        <Grid container item xs={12} direction="column" spacing={2} style={{ margin: 0 }}>
          <Grid container item xs={12} direction="column" spacing={2} style={{ margin: 0 }}>
            <Grid item xs={12} style={{ marginTop: "15px" }}>
              <Paper elevation={0}> */}
                {/* {(!values.isLoading) && <InvoicePdfPreview instance={instance}/> } */}

                {/* {MyDoc} */}
              {/* </Paper>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <br />

      </div> */}

      {directDownload === false &&
      <div>
        {/* <Dialog onClose={() => { setOpen(false); setEmailSent(false); setZoom(!zoom); setEditorState(null) }} className="invoice-preview-dialog " aria-labelledby="customized-dialog-title" open={openPdfPreview} fullWidth={fullWidth}
          maxWidth={maxWidth} > */}
        <Dialog
          fullScreen
          // onClose={() => {
          //   setOpen(false);
          //   setEmailSent(false);
          //   setZoom(!zoom);
          //   setEditorState(null)
          // }}
          onClose={() => {
            // setOpen(false);
            // setEmailSent(false);
            // setZoom(!zoom);
            // setEditorState(null)

            setOpen(false);
            setEmailSent(false);
            setZoom(!zoom);
            setEditorState(null);
            // setOpenInvoiceDialogAtom({
            //   ...openInvoiceDialogAtom,
            //   open: false,
            //   invoiceId: ""
            // })
          }}

          className="invoice-preview-dialog "
          aria-labelledby="customized-dialog-title"
          open={openPdfPreview}>

          <DialogTitle id="customized-dialog-title"
           onClose={() => {
            setOpen(false);
            setEmailSent(false);
            setZoom(!zoom);
            setEditorState(null);
            // setOpenInvoiceDialogAtom({
            //   ...openInvoiceDialogAtom,
            //   open: false,
            //   invoiceId: ""
            // })
          }}
          >
          {!sendMode ? "PDF Vorschau" : (!emailSent && !emailScheduled) ? "Rechnung als Email senden" : emailSent ? "Email wurde versendet" : "Email Versand wurde geplant"}
          </DialogTitle>

          <DialogContent dividers className="bg--1" style={{ opacity: isLoading && "0.2" }}>
            {(sendMode && !emailSent && !emailScheduled) &&
            <>
            <Grid container item direction="column" spacing={2}>
              {/* <Grid container alignItems="center">
                <Typography style={{ color: "rgba(0, 0, 0, 0.5)", marginRight: "10px" }}>An</Typography>
                  {recipients.map((item, i) => (
                      <Chip key={i} variant="outlined" label={item} style={{ marginRight: "5px", marginTop: "5px", marginBottom: "5px"}}/>
                  ))}
              </Grid> */}

              <Grid container item>
                <Grid item container xs={12}>
                  <Grid item container xs={12} alignItems="center" style={{ marginBottom: "5px" }}>
                    <Typography><span style={{ color: "rgba(0, 0, 0, 0.5)", marginRight: "10px" }}>Von</span><span style={{ fontWeight: "500" }}>{settingsAtom.invoicesEmailServer === "own" ? `${settingsAtom.invoicesEmailFrom} <${settingsAtom.invoicesEmailAddress}>` : "DagoWert <accounting@dagowert.com>"}</span></Typography>
                  </Grid>

                  <Grid item container xs={12} alignItems="center">
                    <Typography style={{ color: "rgba(0, 0, 0, 0.5)", marginRight: "10px" }}>An</Typography>

                    {recipients.map((item, i) => (
                        <Chip key={i} onDelete={()=>handleDeleteRecipient(i)} variant="outlined" label={item} style={{ display: "flex", fontSize: "16px", marginRight: "10px", marginTop: "5px", marginBottom: "5px"}}/>
                    ))}
                  </Grid>

                  {JSON.stringify(recipientsCc) !== "[]" &&
                  <Grid item container xs={12} alignItems="center">
                    <Typography style={{ color: "rgba(0, 0, 0, 0.5)", marginRight: "10px" }}>CC</Typography>

                    {recipientsCc.map((item, i) => (
                        <Chip key={i} onDelete={()=>handleDeleteRecipientCc(i)} variant="outlined" label={item} style={{ display: "flex", fontSize: "16px", marginRight: "10px", marginTop: "5px", marginBottom: "5px"}}/>
                    ))}
                  </Grid>
                  }

                  {JSON.stringify(recipientsBcc) !== "[]" &&
                  <Grid item container xs={12} alignItems="center">
                    <Typography style={{ color: "rgba(0, 0, 0, 0.5)", marginRight: "10px" }}>BCC</Typography>

                    {recipientsBcc.map((item, i) => (
                        <Chip key={i} onDelete={()=>handleDeleteRecipientBcc(i)} variant="outlined" label={item} style={{ display: "flex", fontSize: "16px", marginRight: "10px", marginTop: "5px", marginBottom: "5px"}}/>
                    ))}
                  </Grid>
                  }

                  <Grid item container lg={6} alignItems="center" style={{ marginTop: "20px", marginBottom: "10px" }}>
                    <div style={{display: "flex"}}>
                      <Button size="small" onClick={()=>{setEmailEnterState(emailEnterState !== "recipient" ? "recipient" : "recipient")}} style={{ marginRight: "5px", marginBottom: "10px"}} variant={emailEnterState === "recipient" ? "contained" : "outlined"} color="primary">An</Button>
                      <Button size="small" onClick={()=>{setEmailEnterState(emailEnterState !== "cc" ? "cc" : "recipient")}} disabled={JSON.stringify(recipients) === "[]" ? true : false} style={{ marginRight: "5px", marginBottom: "10px"}} variant={emailEnterState === "cc" ? "contained" : "outlined"} color="primary">CC</Button>
                      <Button size="small" onClick={()=>{setEmailEnterState(emailEnterState !== "bcc" ? "bcc" : "recipient")}} disabled={JSON.stringify(recipients) === "[]" ? true : false} style={{ marginBottom: "10px", marginRight: "35px"}} variant={emailEnterState === "bcc" ? "contained" : "outlined"} color="primary">BCC</Button>
                    </div>

                    {emailEnterState === "recipient" &&
                    <TextField
                      id="email"
                      type="email"
                      fullWidth
                      placeholder="Empfänger hinzufügen ... "
                      value={recipient.state}
                      className="input-with-chips"
                      error={recipient.error}
                      helperText={
                        recipient.error ? "Bitte Empfänger eingeben" : ""
                      }
                      onChange={(e)=>
                        handleChangeRecipient(e, "input") 
                      }
                      onKeyDown={(e) => {
                        // On Spacebar or Enter
                        if ((e.keyCode === 32 || e.key === "Enter") && recipient.isEmail) {
                          addRecipient();
                        }
                      }}
                      InputProps={{
                        startAdornment:
                        <InputAdornment position="start">
                          <div style={{ color: "rgba(0, 0, 0, 0.5)" }}>An:</div>
                        </InputAdornment>
                        ,
                        endAdornment: <InputAdornment position="end">
                        <Button style={{ marginBottom: "10px" }} disabled={!recipient.isEmail} size="small" variant="contained" color="secondary" onClick={()=>addRecipient()}>hinzufügen</Button>
                        </InputAdornment>,
                        // endAdornment: (
                        //   <InputAdornment position="end">
                        //     <Button style={{ marginRight: "10px" }}>CC</Button>
                        //     <Button>BCC</Button>
                        //   </InputAdornment>
                        // ),
                        style: { fontWeight: "500" }
                      }}
                    />
                    }

                    {emailEnterState === "cc" &&
                    <TextField
                      id="cc"
                      type="email"
                      fullWidth
                      placeholder="Empfänger in CC hinzufügen ... "
                      value={recipientCc.state}
                      className="input-with-chips"
                      error={recipientCc.error}
                      helperText={
                        recipientCc.error ? "Bitte Empfänger eingeben" : ""
                      }
                      onChange={(e)=>
                        handleChangeRecipientCc(e, "input") 
                      }
                      onKeyDown={(e) => {
                        // On Spacebar or Enter
                        if ((e.keyCode === 32 || e.key === "Enter") && recipientCc.isEmail) {
                          addRecipientCc();
                        }
                      }}
                      InputProps={{
                        startAdornment:
                        <InputAdornment position="start">
                          <div style={{ color: "rgba(0, 0, 0, 0.5)" }}>CC:</div>
                        </InputAdornment>
                        ,
                        endAdornment: <InputAdornment position="end">
                        <Button style={{ marginBottom: "10px" }} disabled={!recipientCc.isEmail} size="small" variant="contained" color="secondary" onClick={()=>addRecipientCc()}>hinzufügen</Button>
                        </InputAdornment>,
                        style: { fontWeight: "500" }
                      }}
                    />
                    }

                    {emailEnterState === "bcc" &&
                    <TextField
                      id="bcc"
                      type="email"
                      fullWidth
                      placeholder="Empfänger in BCC hinzufügen ... "
                      value={recipientBcc.state}
                      className="input-with-chips"
                      error={recipientBcc.error}
                      helperText={
                        recipientBcc.error ? "Bitte Empfänger eingeben" : ""
                      }
                      onChange={(e)=>
                        handleChangeRecipientBcc(e, "input") 
                      }
                      onKeyDown={(e) => {
                        // On Spacebar or Enter
                        if ((e.keyCode === 32 || e.key === "Enter") && recipientBcc.isEmail) {
                          addRecipientBcc();
                        }
                      }}
                      InputProps={{
                        startAdornment:
                        <InputAdornment position="start">
                          <div style={{ color: "rgba(0, 0, 0, 0.5)" }}>BCC:</div>
                        </InputAdornment>
                        ,
                        endAdornment: <InputAdornment position="end">
                        <Button style={{ marginBottom: "10px" }} disabled={!recipientBcc.isEmail} size="small" variant="contained" color="secondary" onClick={()=>addRecipientBcc()}>hinzufügen</Button>
                        </InputAdornment>,
                        style: { fontWeight: "500" }
                      }}
                    />
                    }
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <TextField
                  id="subject"
                  fullWidth
                  multiline
                  onChange={(e)=> {setValues({...values, subject: e.target.value})}}
                  defaultValue={values.subject}
                  style={{ marginTop: "50px" }}
                  InputProps={{
                    startAdornment: <Typography style={{ color: "rgba(0, 0, 0, 0.5)", marginRight: "10px" }}>Betreff</Typography>,
                    style: { fontWeight: "500" }
                  }}
                />
              </Grid>

              <Grid item>
                <Typography variant="body1" component="p" style={{ color: "rgba(0, 0, 0, 0.5)", paddingTop: "10px", paddingBottom: "10px" }}>Nachricht:</Typography>
                
                <Editor
                  toolbar={{
                    options: ['inline', 'fontSize', 'list', 'textAlign', 'link'],
                    inline: { inDropdown: true, options: ['bold', 'italic', 'underline', 'strikethrough']},
                    textAlign: {inDropdown: true},
                    link: { inDropdown: true },
                    list: { inDropdown: true },
                  }}
                  // toolbarOnFocus
                  editorState={editorStateMessage}
                  onEditorStateChange={(editorState) => {setEditorStateMessage(editorState)}}
                  localization={{
                    locale: 'de',
                  }}
                  placeholder="E-Mail Text schreiben ..."
                  wrapperClassName="editor-wrapper"
                  editorClassName="editor"
                />
              </Grid>
            </Grid>

            <Grid item>
              <Grid container item direction="row" alignItems="center" style={{ marginTop: "20px"}}>
                <AttachmentIcon style={{ marginRight: "10px"}}/>
                <Typography component="div" variant="body1">Anhang: <span style={{ fontWeight: "500" }}>PDF</span></Typography>
              </Grid>

              <div className="email-pdf-preview" style={{ width: zoom ? "100%" : "200px", display: "flex", flexDirection: "row", alignItems: "flex-start"}} onClick={()=>setZoom(!zoom)}>
                  <InvoicePdfPreview instance={instance} />
                  <IconButton onClick={()=>setZoom(!zoom)} aria-label="zoom in" style={{ marginTop: "6px", marginLeft: "-18px", background: "#2196f3", color: "#ffffff", padding: "4px"}}>
                    {!zoom ? <ZoomInIcon /> : <ZoomOutIcon />}
                  </IconButton>
              </div>

              <a href={instance.url} download={`Rechnung_${invoiceNrForDownload(values.invoiceNr)}_${client.company}`}>
                <Button startIcon={<SaveAltIcon />}variant="contained" autoFocus color="primary">
                  PDF
                </Button>
              </a>
            </Grid>
            </>
            }
            
            {(!sendMode) &&
            <>
              <InvoicePdfPreview instance={instance} />
            </>
            
            }
            {(sendMode && emailSent && !isLoading) &&
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              <AnimationSuccess />
              <Typography variant="body1" component="p" style={{ paddingTop: "20px", paddingBottom: "10px" }}>Ihre Rechnung wurde erfolgreich per Email versendet.</Typography>
            </div>
            }

            {(sendMode && emailScheduled && !isLoading) &&
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              <AnimationSuccess />
              <Typography variant="body1" component="p" style={{ paddingTop: "20px", paddingBottom: "10px" }}>Ihre Rechnungs-Versand per Email wurde erfolgreich geplant.</Typography>
            </div>
            }
          </DialogContent>

          <DialogActions style={{justifyContent: "center"}}>
            {!sendMode &&
              <a href={instance.url} download={`Rechnung_${invoiceNrForDownload(values.invoiceNr)}_${client.company}`}>
                <Button startIcon={<SaveAltIcon />} variant="contained" onClick={() => setOpen(false)} autoFocus color="primary">
                  PDF Download
                </Button>
              </a>
            }

            {(sendMode && !emailSent && !emailScheduled) &&
            <>
              {/* <Button
                loading={isLoading}
                disabled={isLoading}
                onClick={() => { setOpen(false); setEmailSent(false); setZoom(!zoom); setEditorState(null) }}
                fullWidth={false}
                size="large"
                variant="outlined"
                className="button--loading"
              >
                Abbrechen
              </Button> */}

              {/* <ButtonLoading
              loading={isLoading}
              disabled={isLoading}
              type="submit"
              onClick={send}
              fullWidth={false}
              size="large"
              className="button--loading"
              startIcon={<SendIcon style={{ marginRight: "5px" }} />}
              >
              E-Mail jetzt senden
              </ButtonLoading> */}

              <InvoicesScheduleSend
                values={values}
                setValues={setValues}
                sendState={values.sendState}
                sendDate={values.sendDate}
                send={send}
              />
              </>
            }

            {/* {(sendMode && emailSent) &&
              <Button variant="outlined" autoFocus onClick={() => { setOpen(false); setEmailSent(false) }} >
                Rechnung weiter bearbeiten
              </Button>
            } */}

            {(sendMode && (emailSent || emailScheduled)) &&
              <Button variant="contained" autoFocus
                onClick={() => {
                  setOpen(false);
                  setEmailSent(false);
                  setOpenInvoiceDialogAtom({
                    ...openInvoiceDialogAtom,
                    open: false,
                    invoiceId: ""
                  })
                }}
                color="primary">
                Rechnung schließen
              </Button>
            }
          </DialogActions>
        </Dialog>
      </div>
      }

      {directDownload === true  &&
      <a href={instance.url} download={`Rechnung_${invoiceNrForDownload(values.invoiceNr)}_${client.company}`} onClick={()=>handleClose()}>
       <MenuItem style={{ fontSize: "13px" }}>
       <PdfPreviewIcon />
          PDF Download
      </MenuItem>
      </a>
      }

    </div>
  );
}

export default InvoiceLivePreview;

import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

const clientsFilteredAtom = atom({
  key: "clientsFiltered",
  default: [],
  // effects_UNSTABLE: [persistAtom],
});

export default clientsFilteredAtom
import React from "react";

import "./AnimationFailed.scss"

const AnimationFailed = () => {

    return (
        <div className="container">
            <div className="circle-border"></div>
            <div className="circle">
                <div className="error"></div>
            </div>
        </div>
    );
};

export default AnimationFailed;

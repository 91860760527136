import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from 'react-i18next';
import { RETURN_OVERDUE_DAYS, RETURN_DATE, RETURN_HOURS } from '../../../_functions/index'; // adjust the path accordingly

const InvoiceAlerts = ({ selectedClient, settings, values, openInvoiceDialogAtom, invoiceHandler, setShowConfirmModal }) => {
  const { t } = useTranslation();

  return (
    <>
      {(selectedClient !== "" && settings.invoicesEmailServer !== "download" && values.sendState.state !== "sent" && values.sendState.state !== "scheduled" && openInvoiceDialogAtom.invoiceId !== "") &&
        <Alert severity="error" style={{ justifyContent: "center", minHeight: "70px", borderRadius: 0, position: "fixed", top: "60px", width: "100%", zIndex: "9999", left: 0, boxShadow: "rgba(84, 94, 165, 0.2) 0px 1px 64px" }}>
          <Typography style={{ lineHeight: 1 }}>
            {!openInvoiceDialogAtom.isEstimate ? `${t("Rechnung")} ` : `${t("KV / Angebot")} `}
            {t("wurde noch nicht gesendet")}
          </Typography>
        </Alert>
      }

      {(selectedClient !== "" && settings.invoicesEmailServer !== "download" && values.sendState.state === "sent" && values.payState.state !== "payed" && values.sendState.state !== "scheduled" && openInvoiceDialogAtom.invoiceId !== "") &&
        <Alert severity="info" style={{ justifyContent: "center", minHeight: "70px", borderRadius: 0, position: "fixed", top: "60px", width: "100%", zIndex: "9999", left: 0, boxShadow: "rgba(84, 94, 165, 0.2) 0px 1px 64px" }}>
          <Grid container item alignItems="center" xs={12} style={{ flexDirection: "row" }}>
            <Grid item>
              <Typography style={{ lineHeight: 1, fontSize: "12px" }}>
                {!openInvoiceDialogAtom.isEstimate ? `${t("Rechnung")} ` : `${t("KV / Angebot")} `}
                {t("wurde gesendet")}
                {(!openInvoiceDialogAtom.isEstimate && selectedClient !== "" && (values.payState.state === "notPayed" && RETURN_OVERDUE_DAYS(values.dueDate) > 0)) && <span style={{ color: "red" }}> {RETURN_OVERDUE_DAYS(values.dueDate)} {t("Tag(e) überfällig!")}</span>}
              </Typography>
            </Grid>
          </Grid>
        </Alert>
      }

      {(!openInvoiceDialogAtom.isEstimate && selectedClient !== "" && values.payState.state === "payed" && openInvoiceDialogAtom.invoiceId !== "") &&
        <Alert severity="success" style={{ justifyContent: "center", minHeight: "70px", borderRadius: 0, position: "fixed", top: "60px", width: "100%", zIndex: "9999", left: 0, boxShadow: "rgba(84, 94, 165, 0.2) 0px 1px 64px" }}>
          <Typography style={{ lineHeight: 1 }}>{t("Diese Rechung wurde bezahlt.")}</Typography>
        </Alert>
      }

      {(selectedClient !== "" && settings.invoicesEmailServer !== "download" && values.sendState.state === "scheduled" && openInvoiceDialogAtom.invoiceId !== "") &&
        <Alert severity="warning" style={{ justifyContent: "center", minHeight: "70px", borderRadius: 0, position: "fixed", top: "60px", width: "100%", zIndex: "9999", left: 0, boxShadow: "rgba(84, 94, 165, 0.2) 0px 1px 64px" }}>
          <Typography style={{ lineHeight: 1 }}>{t("Wird am")} <span style={{ fontWeight: 500 }}>{`${RETURN_DATE(values.sendDate)} um ${RETURN_HOURS(values.sendDate)}`}</span> {t("gesendet.")}</Typography>
          <Button color="primary" style={{ background: "white", marginLeft: "5px", lineHeight: "1.2", fontSize: "12px" }}
            onClick={(e) => {
              invoiceHandler(e, false, true);
              setShowConfirmModal(true);
            }}
          >{t("Versand abbrechen")}</Button>
        </Alert>
      }
    </>
  );
};

export default InvoiceAlerts;

import { useEffect } from 'react';

const useInvoiceCalculations = (items, values, setValues, selectedClient) => {
  useEffect(() => {
    const calculateSubtotal = () => {
      let total = 0;
      items.forEach(function (item) {
        total += item.discountedTotal;
      });
      return total;
    };

    const calculateDiscountValue = () => {
      const discountValue = values.discount === "absolute" ? values.discountAbsolute : (calculateSubtotal() * values.discountPercent / 100);
      return discountValue;
    };

    const calculateDiscountedSubtotal = () => {
      const discountedSubtotal = calculateSubtotal() - values.discountValue;
      return discountedSubtotal;
    };

    let discountedTotal = values.discountedTotal;

    const vatGroups = () => {
      items.reduce((vatGroups, item) => {
        item["vatPercent"] = item.vatPercent !== "" ? item.vatPercent : selectedClient.vatPercent !== "" ? selectedClient.vatPercent : 0;
        const group = (vatGroups[item.vatPercent] || ["", 0, 0]); // i=0 -> vat percentage, i=1 -> vat absolute, i=2 -> net value
        group[0] = item.vatPercent;

        if (values.discount === "none") {
          group[1] += (parseInt(item.vatPercent) / 100 * item.discountedTotal);
          discountedTotal += (1 + (parseInt(item.vatPercent) / 100)) * item.discountedTotal;
          group[2] += item.discountedTotal;
        } else {
          if (values.discount === "absolute") {
            group[1] += parseInt(item.vatPercent) / 100 * (item.discountedTotal - (item.discountedTotal / calculateSubtotal() * parseFloat(values.discountValue)));
            discountedTotal += (1 + (parseInt(item.vatPercent) / 100)) * (item.discountedTotal - (item.discountedTotal / calculateSubtotal() * parseFloat(values.discountValue)));
            group[2] += item.discountedTotal - (item.discountedTotal / calculateSubtotal() * parseFloat(values.discountValue));
          } else if (values.discount === "percent") {
            group[1] += (parseInt(item.vatPercent) / 100 * item.discountedTotal * (1 - parseFloat(values.discountPercent) / 100));
            discountedTotal += (1 + (parseInt(item.vatPercent) / 100)) * item.discountedTotal * (1 - parseFloat(values.discountPercent) / 100);
            group[2] += item.discountedTotal * (1 - parseFloat(values.discountPercent) / 100);
          }
        }

        if (values.reminderRequestCharge !== 0 && values.reminderRequestCharge !== 0.00 && values.reminderRequestCharge !== "0.00" && values.reminderRequestCharge !== "") {
          discountedTotal += parseFloat(values.reminderRequestCharge);
        }

        vatGroups[item.vatPercent] = group;

        setValues({
          ...values,
          vatValues: vatGroups,
          subtotal: calculateSubtotal(),
          total: discountedTotal,
          discountValue: calculateDiscountValue(),
          discountedSubtotal: calculateDiscountedSubtotal(),
        });

        return vatGroups;
      }, {});
    };

    vatGroups();
  }, [items, values.discount, values.discountValue, values.discountAbsolute, values.discountPercent, values.reminderRequestCharge]);
};

export default useInvoiceCalculations;

import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

const subMenuOpenAtom = atom({
  key: "subMenuOpen",
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export default subMenuOpenAtom

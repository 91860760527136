const changeContinuousNumber = (e, values, setValues) => {
  // check if it is a positive number and not empty
  if (/^\d+$/.test(e.target.value)) {
    let invoiceNumberItems = (typeof (values.invoiceNumber.state) === "string" ? JSON.parse(values.invoiceNumber.state) : values.invoiceNumber.state);
    const currentIndex = invoiceNumberItems.findIndex((elements) => elements.type === "continuousNumber");
    if (currentIndex !== -1) {
      let currentId = invoiceNumberItems[currentIndex].id;
      const updatedItem = { ...invoiceNumberItems[currentIndex], id: currentId, type: "continuousNumber", value: e.target.value };
      const newItems = [...invoiceNumberItems];
      newItems[currentIndex] = updatedItem;
      setValues({
        ...values,
        invoiceNumber: {
          ...values.invoiceNumber,
          error: false,
          errorMsg: "",
          state: newItems
        }
      });
    }
  } else {
    setValues({
      ...values,
      invoiceNumber: {
        ...values.invoiceNumber,
        error: true,
        errorMsg: "Nur positive Zahlen möglich",
      }
    });
  }
};

export default changeContinuousNumber;

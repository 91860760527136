import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

const loggedInAtom = atom({
  key: "loggedIn",
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export default loggedInAtom
import React from "react";

// IMPORT hooks & context
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

// IMPORT components
import Logo from "../../shared/components/UIElements/Logo";
import Copyright from "../../shared/components/UIElements/Copyright";

// IMPORT components MATERIAL UI
import { Button, Typography, Box } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

const Feedback = (props) => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();

  return (
    <Container
      maxWidth={false}
      disableGutters
      className="bg--1"
    >
      <Container
        maxWidth="md">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent={props.center !== false && "center"}
          alignItems="center"
          minHeight="100vh"
          padding="50px"
          className="h-gap--50"
        >
          <Grid
            container
            direction="column"
            alignContent="center"
            alignItems="center"
            className="h-gap--30 h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--1"
          >
            {props.logo !== false &&
              <Logo />
            }

            {props.icon}

            <Grid
              container
              direction="column"
              alignItems="center"
              >
              <Typography component="h1" variant="h6" align="center" style={{paddingBottom: "15px"}}>{props.h1}</Typography>
              <Typography component="p" align="center">{props.text}</Typography>
            </Grid>

            {props.button &&
            <NavLink to={props.buttonLink}>
              <Button color="primary" variant="contained" onClick={props.onClick && props.onClick}>{props.button}</Button>
            </NavLink>
            }
          </Grid>
          
          {props.copyright !== false &&
          <Copyright />
          }
        </Box>
      </Container>
    </Container>
  );
};

export default Feedback;

import React, { useEffect, useState } from 'react';

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import invoicesAtomState from "../../_atoms/invoicesAtom";
import settingsAtomState from "../../_atoms/settingsAtom";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";

// IMPORT components MATERIAL UI
import {
  Typography,
  Button,
  Grid,
  IconButton,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  Dialog
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import DateFnsUtils from '@date-io/date-fns';
import { de } from "date-fns/locale";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

// IMPORT icons MATERIAL UI
import SaveIcon from '@material-ui/icons/Save';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CloseIcon from '@material-ui/icons/Close';

// IMPORT own CSS
import "./InvoicesDateRange.scss"


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function InvoicesDateRange({
  settings,
  handleChange,
  cancelSettingsHandler,
  updateSettingsHandler,
  SET_VALUE,
  setSettings,
  setValues,
  values
}) {

  // If invoicesNumberItems are received from DB -> it is  an array of objects stored AS STRING, afterwards it is not a string
  const invoicesNumberItems = typeof (settings.invoicesNumberItems) === "string" ? JSON.parse(settings.invoicesNumberItems) : settings.invoicesNumberItems;

  // HOOKS & CONTEXT
  const { t } = useTranslation();

  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');



  // GLOBAL STATE (RECOIL)
  const [invoicesAtom] = useRecoilState(invoicesAtomState);

  // LOCAL STATE (PURE)
  const [years, setYears] = useState(() => []);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedQuarter, setSelectedQuarter] = useState(null);


  /* 
    FUNCTIONS
  */
  const handleClickOpen = () => {
    SET_VALUE({ target: { id: "submenuOpen", value: "dateRange" } }, setValues);
  };

  useEffect(() => {
      // console.log(invoicesAtom)
      let allYears = []
      for (var key in invoicesAtom) {
        // skip loop if the property is from prototype
        if (!invoicesAtom.hasOwnProperty(key)) continue;
    
        var obj = invoicesAtom[key];
        for (var prop in obj) {
            // skip loop if the property is from prototype
            if (!obj.hasOwnProperty(prop)) continue;
    
            // your code
            if (prop === "invoiceDate" || prop === "dueDate" || prop === "payDate") {
              if (allYears.indexOf(new Date(obj[prop]).getUTCFullYear()) === -1) {
                allYears.push(new Date(obj[prop]).getUTCFullYear())
              }

              setYears(allYears.sort((a, b) => {return b - a}))
            }
        }
      }

  }, [invoicesAtom]);

  const handleYears = (year) => {
    setSelectedYear(year);
    setSelectedQuarter(null);

    SET_VALUE({
      target: {
        "invoicesDateRangeStartValue": new Date(`${year}-01-01T00:00`),
        "invoicesDateRangeEndValue": new Date(`${year}-12-31T23:59`),
      }
    }, setSettings, "multi");
  };

  const handleQuarters = (quarter) => {
    setSelectedQuarter(quarter);

    const saveQuarter = (start, end) => {
      SET_VALUE({
        target: {
          "invoicesDateRangeStartValue": new Date(`${selectedYear}-${start}`),
          "invoicesDateRangeEndValue": new Date(`${selectedYear}-${end}`),
        }
      }, setSettings, "multi");
    }

    if (quarter === "first") {
      if (selectedQuarter !== "first") {
        saveQuarter("01-01T00:00", "03-31T23:59");
      } else {
        handleYears(selectedYear)
      }
    } else if (quarter === "second") {
        if (selectedQuarter !== "second") {
          saveQuarter("04-01T00:00", "06-30T23:59");
        } else {
          handleYears(selectedYear)
        }
    } else if (quarter === "third") {
        if (selectedQuarter !== "third") {
          saveQuarter("07-01T00:00", "09-30T23:59");
        } else {
          handleYears(selectedYear)
        }
    } else if (quarter === "fourth") {
        if (selectedQuarter !== "fourth") {
          saveQuarter("10-01T00:00", "12-31T23:59");
        } else {
          handleYears(selectedYear)
        }
    }
  };

  return (
    <>
      <IconButton
        aria-label="settings"
        style={{ color: "inherit" }}
        onClick={handleClickOpen}
      >
        <DateRangeIcon fontSize="small" />
      </IconButton>
      <Dialog
        onClose={() => { cancelSettingsHandler() }}
        aria-labelledby="customized-dialog-title"
        open={values.submenuOpen === "dateRange"}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogTitle id="customized-dialog-title" onClose={() => { cancelSettingsHandler() }} style={{ display: "flex" }}>
          <DateRangeIcon color="primary" /> {t("PAGE_INVOICES.dateRange")}
        </DialogTitle>
        <DialogContent dividers>
     

        {/* <Grid container item alignItems="center" style={{ marginBottom: "20px" }}>
          <DateRangeIcon color="primary" style={{ marginLeft: "-2px", marginRight: "5px" }} />
          <Typography variant="h6" component="div">{t("PAGE_INVOICES.dateRange")}</Typography>
        </Grid> */}

        <Grid container item >
          <FormControl component="fieldset">
            {/* <Typography variant="body2" component="div"><strong>{t("PAGE_INVOICES.settingsCreation")}</strong></Typography> */}



            <RadioGroup aria-label="invoicesDateRange" name="invoicesDateRange" value={settings.invoicesDateRange} onChange={handleChange}>
              <FormControlLabel value="none" control={<Radio color="primary" style={{ paddingTop: "5px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("PAGE_INVOICES.settingsDateRangeNone")}</Typography>} />
              <FormControlLabel value="invoiceDate" control={<Radio color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("PAGE_INVOICES.settingsDateRangeInvoiceDate")}</Typography>} />
              <FormControlLabel value="dueDate" control={<Radio color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("PAGE_INVOICES.settingsDateRangeDueDate")}</Typography>} />
              <FormControlLabel value="payDate" control={<Radio color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />} label={<Typography variant="body2" component="div">{t("PAGE_INVOICES.settingsDateRangePayDate")}</Typography>} />
             </RadioGroup>
             <br />

            <Grid container item direction="row">
              <Grid item>
                <MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
                  <Grid item xs={12} sm={12} className="datepicker" style={{ marginBottom: "5px" }}>
                    <KeyboardDatePicker
                      disabled = {settings.invoicesDateRange === "none" ? true : false}
                      inputVariant="filled"
                      id="invoicesDateRangeStartValue"
                      format="dd.MM.yyyy"
                      label="Von"
                      value={settings.invoicesDateRangeStartValue}
                      onChange={(date) => {
                        console.log(date);
                        SET_VALUE({
                          target: {
                            "invoicesDateRangeStartValue": date,
                          }
                        }, setSettings, "multi");
                        setSelectedYear(null);
                        setSelectedQuarter(null);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      okLabel="OK"
                      cancelLabel="Abbrechen"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} className="datepicker">
                    <KeyboardDatePicker
                      disabled={settings.invoicesDateRange === "none" ? true : false}
                      inputVariant="filled"
                      id="invoicesDateRangeEndValue"
                      format="dd.MM.yyyy"
                      label="Bis"
                      value={settings.invoicesDateRangeEndValue}
                      onChange={(date) => {
                        SET_VALUE({
                          target: {
                            "invoicesDateRangeEndValue": date,
                          }
                        }, setSettings, "multi");
                        setSelectedYear(null);
                        setSelectedQuarter(null);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      okLabel="OK"
                      cancelLabel="Abbrechen"
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              
              <Grid item>
                <Grid container item className="date-range-toggle" style={{ marginTop: "30px" }}>
                  <Grid item container>
                    <Typography style={{ color: settings.invoicesDateRange === "none" ? "#bbbbbb" : "inherit", fontSize: "12px" }}>Gesamtes Jahr auswählen (01.01. bis 31.12.)</Typography>
                  </Grid>
                  <ToggleButtonGroup exclusive value={selectedYear} aria-label="text formatting">
                    {years.map((year => (
                      <ToggleButton key={year} value={year} onClick={() => handleYears(year)} aria-label="first Quarter" disabled={settings.invoicesDateRange === "none" ? true : false}>
                      {year}
                      </ToggleButton>
                    )))
                    }
                  </ToggleButtonGroup>
                </Grid>

                <Grid container item className="date-range-toggle" style={{ marginTop: "10px" }}>
                  <Grid item container>
                    <Typography style={{ color: (settings.invoicesDateRange === "none" || selectedYear === null) ? "#bbbbbb" : "inherit", fontSize: "12px" }}>Auf Quartal beschränken {(settings.invoicesDateRange === "none" || selectedYear === null) && "(Bitte zunächst das Jahr auswählen)"}</Typography>
                  </Grid>
                  <ToggleButtonGroup exclusive value={selectedQuarter} aria-label="text formatting">
                    <ToggleButton value="first" aria-label="first Quarter" onClick={() => handleQuarters("first")} disabled={(settings.invoicesDateRange === "none" || selectedYear === null) ? true : false}>
                      1. Quartal
                    </ToggleButton>

                    <ToggleButton value="second" aria-label="second Quarter" onClick={() => handleQuarters("second")} disabled={(settings.invoicesDateRange === "none" || selectedYear === null) ? true : false}>
                      2. Quartal
                    </ToggleButton>

                    <ToggleButton value="third" aria-label="third Quarter" onClick={() => handleQuarters("third")} disabled={(settings.invoicesDateRange === "none" || selectedYear === null) ? true : false}>
                      3. Quartal
                    </ToggleButton>

                    <ToggleButton value="fourth" aria-label="fourth Quarter" onClick={() => handleQuarters("fourth")} disabled={(settings.invoicesDateRange === "none" || selectedYear === null) ? true : false}>
                      4. Quartal
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
      </DialogContent>

<DialogActions>
  <Button
    variant="outlined"
    size="small"
    onClick={() => { cancelSettingsHandler() }}
  >
    {t("BUTTONS.cancel")}
  </Button>

  <Button
    variant="contained"
    color="primary"
    size="small"
    onClick={() => { updateSettingsHandler() }}
    startIcon={<SaveIcon style={{ marginRight: "5px" }} />}
  >
    {t("BUTTONS.save")}
  </Button>
</DialogActions>
</Dialog>
</>
  );
}

import React, { useEffect, useState, useContext } from "react";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import userAtomState from "./../../_atoms/userAtom";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";

import { PDFViewer, usePDF, Page, Text, View, Image, Document } from '@react-pdf/renderer';
import { useHttpClient } from "./../../shared/hooks/http-hook";
import { AuthContext } from "./../../shared/context/auth-context";

import {
  TextField,
  Button,
  Grid
} from "@material-ui/core";

import { HexColorPicker } from "react-colorful";

import { SET_VALUES } from '../../_functions/SET_VALUES';

import "./Test.scss"

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

// TODO: 
// Create 5 different templates as starter basis
// Background color
// Background image
// Font family
// Font sizes
// Font color
// Show / hide dividers
// Letter spacing
// ??? Arrangement of sections

// FIXME: 



const Test = () => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);

  // GLOBAL STATE (RECOIL)
  const [userAtom, setUserAtom] = useRecoilState(userAtomState);

  const [cssZoom, setCssZoom] = useState("100%");

  const [cssBackgroundColor, setCssBackgroundColor] = useState("#b32aa9");

  const [values, setValues] = useState({
    email: {
      state: "foo",
      error: false,
    },
    password: {
      state: "",
      error: false,
    },
  });

  let MyDoc = (
    <Document>
      <Page
        size="A4"
        style={{
          flexDirection: "row",
          padding: "50px 50px",
          backgroundColor: cssBackgroundColor,
          zoom: cssZoom
        }} >
        <View
          style={{
            flexGrow: 1
          }}
        >
          
         {/* PDF image */}
        <Image
          src={`${process.env.REACT_APP_BACKEND_URL}/${userAtom.data.image}`}
          style={{
            maxHeight: "80px",
            margin: "0 auto"
          }}
        />

        {/* ON-SCREEN image */}
        <img
              src={`${process.env.REACT_APP_BACKEND_URL}/${userAtom.data.image}`}
              alt={`${t("APP.name")} Logo`}
              style={{
                  maxHeight: "80px",
                  margin: "0 auto"
              }}
          />
          <Text>{values.email.state}</Text>
        </View>

        <View
          style={{
            flexGrow: 1
          }}>
          <Text
            style={{
              fontSize: "36px"
            }}>Section #2</Text>
        </View>
      </Page>
    </Document>
  )

  // Variable has to be placed below MyDoc and above updateInstance
  const [instance, updateInstance] = usePDF({ document: MyDoc });

  // Update pdf content when values change because of input
  // DO NOT forget to add cssX dependencies in Order to change the the style of the PDF that is sent via Email!!
  // ALL styles that are only applied to the screen and not to the PDF that is sent, should NOT be added as dependency but in .scss!!!
  useEffect(() => {
    updateInstance(MyDoc);
  }, [values, cssBackgroundColor])

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Calculate zoom depending on screen width
  useEffect(() => {
    const zoomFactor = 100 / 1920 * windowDimensions.width;
    if (windowDimensions.width < 1920) {
      setCssZoom(`${zoomFactor}%`)
    } else {
      setCssZoom(`100%`)
    }
  }, [windowDimensions, cssZoom]);

  //!!!!!!!!!!!!! ALTERNATIVE !!!!!!!!!!!!!!!!!!!!!!!
  // const [instance, updateInstance] = usePDF({
  //   document: (
  //     <PDFDocument projectsData={projectsData} projectId={projectId} />
  //   ),
  // });

  const handleChange = (e) => {
    SET_VALUES(e, values, setValues);
  };

  const send = async (event) => {
    event.preventDefault();
    const foosaR = await fetch(instance.blob.url)
      .then(r => r.blob())
      .then(blob => {
        var file_name = Math.random().toString(36).substring(6) + '_name.pdf'; //e.g ueq6ge1j_name.pdf
        var fooso = new File([blob], file_name, { type: 'application/pdf' });
        console.log(fooso); //Output
        return fooso;
      });

    try {
      await fetch(instance.blob.url)
        .then(r => r.blob())
        .then(blob => {
          const blobToBase64 = (blob) => {
            return new Promise((resolve) => {
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = function () {
                resolve(reader.result);
              };
            });
          };

          let jsonString;

          (async () => {
            const b64 = await blobToBase64(instance.blob);
            jsonString = JSON.stringify({ blob: b64 });
            console.log(jsonString);

            const formData = new FormData();
            // formData.append("image", instance.blob);
            formData.append("images", jsonString);

            sendRequest(
              `${process.env.REACT_APP_BACKEND_URL_API}/invoices/test`,
              "POST",
              formData,
              {
                // "Content-Type": "application/json",
                Authorization: "Bearer " + auth.token,
              }
            )
          })();
        });
    } catch (err) { }
  };

  const file = new Blob(
    [instance.blob],
    { type: 'application/pdf' }
  );

  return (
    <React.Fragment>
      <Grid container xs={12} direction="column" spacing={2} >
        <Grid container item xs={12}>
          <TextField
            required
            autoComplete="off"
            id="email"
            label={"some"}
            variant="outlined"
            type="email"
            onChange={handleChange}
            fullWidth
            disabled={isLoading}
          />
        </Grid>

        <Grid container item xs={12} direction="column" spacing={2}>
          <Grid item xs={12}>
            <HexColorPicker color={cssBackgroundColor} onChange={setCssBackgroundColor} />
          </Grid>

          <Grid item xs={12}>
            <div className="value" style={{ borderLeftColor: cssBackgroundColor }}>
              Current color is {cssBackgroundColor}
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="buttons">
              <button onClick={() => setCssBackgroundColor("#c6ad23")}>Choose gold</button>
              <button onClick={() => setCssBackgroundColor("#556b2f")}>Choose green</button>
              <button onClick={() => setCssBackgroundColor("#207bd7")}>Choose blue</button>
            </div>
          </Grid>

          <Grid item xs={12}>
            <a href={instance.url} download="test.pdf">
              Download
            </a>
          </Grid>

          <Grid item xs={12}>
            <Button onClick={send} variant="contained">send</Button>
          </Grid>


          <Grid item xs={12}>
           {MyDoc}
          </Grid>
        </Grid>
      </Grid>
      <PDFViewer width={"1000px"} height={"1410px"}>
        {MyDoc}
      </PDFViewer>

      {/* <PreviewInvoice fileBlob={file}/> */}
    </React.Fragment>
  );
}

export default Test;

import React, { useState, useContext } from "react";

// IMPORT hooks & context
import {
  NavLink
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "../../../shared/hooks/form-hook";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/auth-context";
import { useAuth } from "../../../shared/hooks/auth-hook";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import userAtomState from "../../../_atoms/userAtom";
import checkoutAtomState from "../../../_atoms/checkoutAtom";
import openCheckoutAtomState from "../../../_atoms/openCheckoutAtom";

// IMPORT functions
import { VALIDATE } from '../../../_functions/VALIDATE';
import { SET_USER_ATOM } from '../../../_functions/SET_USER_ATOM';

// IMPORT components
import ButtonLoading from "../../../shared/components/UIElements/ButtonLoading";
import PasswordDialog from "../../components/PasswordDialog";
import { useSnackbar } from 'notistack';

// IMPORT components MATERIAL UI
import {
  Typography,
  Button,
  Box,
  TextField,
  Container,
  Grid,
  InputAdornment,
  makeStyles,
  IconButton
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Fade from '@material-ui/core/Fade';


// IMPORT icons MATERIAL UI
import SaveIcon from '@material-ui/icons/Save';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: {
    height: '0px',
    '@media (max-width: 959px)': {
      height: '62px',
    },
    '@media (max-width: 599px)': {
      height: '45px',
    },
    '@media (max-width: 455px)': {
      height: '55px',
    },
  },
}));

const Account = () => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const classes = useStyles();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const { userId } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  // GLOBAL STATE (RECOIL)
  const [userAtom, setUserAtom] = useRecoilState(userAtomState);
  const [, setOpenCheckoutAtom] = useRecoilState(openCheckoutAtomState);
  const [checkoutAtom, setCheckoutAtom] = useRecoilState(checkoutAtomState);

  // LOCAL
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [submitted, setSubmitted] = useState(null);
  const [values, setValues] = useState({
    email: {
      state: userAtom.data.email,
      error: false,
      changed: false,
      errorMsg: t("FIELDS.email.error"),
    },
    // new password
    password: {
      state: "",
      error: false,
      changed: false,
      errorMsg: t("FIELDS.password.error"),
    },
  });
  const [locked, setLocked] = useState(true);



  /* 
    Functions
  */
  const accountUpdateHandler = async (event) => {
    event.preventDefault();

    if (
      !values.email.error &&
      !values.password.error
      ) {
      try {
        const formData = new FormData();
        formData.append("id", userAtom.data._id);
        formData.append("password", password);
        formData.append("emailchanged", values.email.changed);
        formData.append("email", values.email.state);
        formData.append("passwordNew", values.password.state);
        formData.append("passwordchanged", values.password.changed);
        formData.append("image", "#");

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL_API}/users/change-account`,
        "POST",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      )
      .then((res) => {
        console.log(res);
        if(res.messageEmail === "email already exists"){
          setValues({
            ...values,
            email: {
              ...values.email,
              error: true,
              errorMsg: "Es ist ein Problem aufgetreten. Wähle eine andere Email-Adresse."
            }
          })
          enqueueSnackbar(t("FIELDS.error"), { variant: "error" })
        } else if (res.messageEmail === "changed" && res.messagePassword === "changed") {
          SET_USER_ATOM({email: values.email.state}, userAtom, setUserAtom)
          enqueueSnackbar(t("FIELDS.saved"), { variant: "success" })
        } else {
          enqueueSnackbar(t("FIELDS.error"), { variant: "error" })
        }
      })

  
      } catch (err) {}
    } else {
      // setSubmitted(false);
    }
  };

  const validate = (field) => {
    if (values.password.changed === false) {
      VALIDATE([
        "email"
      ],
        field, values, setValues);
    } else if (values.password.changed === true) {
      VALIDATE([
        "email",
        "password"
      ],
        field, values, setValues);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <div className={classes.toolbar} />
      <Fade in={true}>
          <Grid container className="subpage-header" maxWidth={false} item direction="row" alignItems="center" alignContent="center">
          <Grid item container alignItems="center" style={{ marginRight: "10px" }}>
              <AccountBalanceWalletIcon color="primary" fontSize="large" style={{ background: "linear-gradient(to right top, rgb(38, 108, 189), rgb(36, 115, 192), rgb(33, 150, 243), #00bca7, #00bf72)", marginRight: "15px" }} className="icon--with-bg--1" />
              <Typography variant="body1" component="div" style={{ fontWeight: "500" }} className="headline">{t("PAGE_ACCOUNT.h1")}</Typography>
          </Grid>
          </Grid>
      </Fade>

      <Container maxWidth={false} disableGutters className="bg--1" >
            <Box
              display="flex"
              flexDirection="column"
              className="list-box"
            >

          <form onSubmit={accountUpdateHandler}>
            <Box display="flex"
              flexDirection="column"
              justifyContent="center"
              // alignItems="center"
              margin="0 25px"
              className="h-gap--25"
            >

              <Box
                display="flex"
                flexDirection="column"
                padding="25px 0px"
                className="h-gap--20"
              >
                <Grid
                  container
                  spacing={5}
                  direction="row"
                  alignContent="flex-start"
                  alignItems="flex-start"
                  className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                >
                  <Grid container item lg={12} spacing={2}>

                    <Grid container item spacing={3}>
                      <Grid container xs={12} item direction="row" alignItems="center">
                        <Typography variant="h6" component="h2">{t("PAGE_ACCOUNT.headlineEmailPassword")}</Typography>
                      </Grid>

                      <Grid container item xs={12} lg={6} spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            disabled={locked}
                            autoComplete="off"
                            error={values.email.error}
                            helperText={values.email.error ? values.email.errorMsg : ""}
                            value={values.email.state}
                            onChange={(e)=>setValues({
                              ...values,
                              email: {
                                ...values.email,
                                state: e.target.value,
                                changed: true,
                              },
                            })}
                            id="email"
                            label={`${t("FIELDS.email.label")} *`}
                            variant="outlined"
                            type="email"
                            fullWidth
                            InputProps={{
                              endAdornment: <InputAdornment position="end">
                                {locked ? <LockIcon /> : <LockOpenIcon style={{ color: "#4caf50" }}/>}
                              </InputAdornment>,
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Grid container item xs={12} lg={6} spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            disabled={locked}
                            autoComplete="off"
                            defaultValue="*********"
                            id="password"
                            error={values.password.error}
                            helperText={values.password.error ? values.password.errorMsg : ""}
                            label={`${t("FIELDS.password.label")} *`}
                            type={showPassword ? "text" : "password"}
                            onChange={(e)=>setValues({
                              ...values,
                              password: {
                                ...values.password,
                                state: e.target.value,
                                changed: true,
                              },
                            })}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              endAdornment: <InputAdornment position="end">
                                {locked ? <LockIcon /> : <LockOpenIcon style={{ color: "#4caf50" }} />}
                                <IconButton
                                  aria-label={t("BUTTONS.AL_password_visibilty")}
                                  onClick={handleClickShowPassword}
                                >
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>

                              </InputAdornment>,
                            }}
                          />
                        </Grid>
                      </Grid>
                      
                      <Grid container item xs={12} lg={6} spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <PasswordDialog locked={locked} setLocked={setLocked} password={password} setPassword={setPassword}/>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                padding="25px 0px"
                className="h-gap--20"
              >
                <Grid
                  container
                  spacing={5}
                  direction="row"
                  alignContent="flex-start"
                  alignItems="flex-start"
                  className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                >
                  <Grid container item lg={12} spacing={2}>
                    <Grid container item spacing={3}>
                      <Grid container xs={12} item direction="row" alignItems="center">
                        <Typography variant="h6" component="h2">{t("PAGE_ACCOUNT.headlineVersion")}</Typography>
                      </Grid>

                      <Grid container item xs={12} sm={12} spacing={2} alignItems="center">
                        <Grid item xs={12} sm={12}>
                          <Typography>Du benutzt derzeit <strong>{t("APP.name")} {(userAtom.data.subscription).toUpperCase()}</strong>
                          {userAtom.data.signupCompleted&&
                            <Button onClick={() => {setOpenCheckoutAtom(true); setCheckoutAtom({...checkoutAtom, show: userAtom.data.subscription === "basic" ? "PREMIUM_PRO" : userAtom.data.subscription === "premium" ? "BASIC_PRO" : "BASIC_PREMIUM"})}} size="small" color="primary" variant="outlined" style={{ marginLeft: "10px" }}>
                              <strong>Version ändern</strong>
                            </Button>
                          }

                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                padding="25px 0px"
                className="h-gap--20"
              >
                <Grid
                  container
                  spacing={5}
                  direction="row"
                  alignContent="flex-start"
                  alignItems="flex-start"
                  className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                >
                  <Grid container item xs={12} sm={12} spacing={1} direction="column">
                    <Grid item>
                      <NavLink to={`/account-transactions`}>
                        <strong>Transaktionen (Rechnungen)</strong>
                      </NavLink>
                    </Grid>

                    <Grid item>
                      <NavLink to={`/delete-account`}>
                        <strong>Konto löschen</strong>
                      </NavLink>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              <Grid container item lg={12} spacing={2} direction="column" alignItems="center">
                <Grid container item lg={12} spacing={2} justifyContent="center" alignItems="center">
                  {/* <Grid item>
                    <Button
                      variant="outlined"
                      size="large"
                      style={{padding: "15px"}}
                      onClick={() => window.location.href = `/dashboard`}
                    >
                      {t("BUTTONS.cancel")}
                    </Button>
                  </Grid> */}

                  <Grid item>
                    <ButtonLoading
                      loading={isLoading}
                      disabled={isLoading}
                      type="submit"
                      onClick={() => validate("all")}
                      fullWidth
                      size="large"
                      className="button--loading"
                      startIcon={<SaveIcon style={{ marginRight: "5px" }} />}
                    >
                      {t("BUTTONS.save")}
                    </ButtonLoading>
                  </Grid>
                </Grid>

                {/* FORM ERROR */}
                {submitted === false &&
                    <Grid item xs={12} lg={6}>
                      <Alert severity="error">{t("ERRORS.save_form")}</Alert>
                    </Grid>
                }
                
                {/* SERVER ERROR */}
                {error &&
                    <Grid item xs={12} lg={6}>
                      <Alert severity="error">{t("ERRORS.save_server")}</Alert>
                    </Grid>
                }

                {/* NO SERVER && NO FORM-ERROR */}
                {!error && submitted &&
                    <Grid item xs={12} lg={6}>
                      <Alert severity="success">{t("FIELDS.saved")}</Alert>
                    </Grid>
                }
              </Grid>
            </Box>
          </form>
        </Box>
      </Container>
    </div>
  );
};

export default Account;

import React from "react";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import userAtomState from "../../_atoms/userAtom";

// IMPORT custom components
import AnimationSuccess from "./../../shared/components/UIElements/animations/AnimationSuccess";
import Feedback from "./../../user/components/Feedback";

const Step2Completion = (props) => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();

  // GLOBAL STATE (RECOIL)
  const [userAtom] = useRecoilState(userAtomState);

  return (
    <Feedback
      h1={t("PAGE_CHECKOUT.completion.h1")}
      text={`${t("PAGE_CHECKOUT.completion.text")} ${(userAtom.data.subscription).toUpperCase()}.`}
      icon={<AnimationSuccess />}
      button={t("BUTTONS.back_to_app")}
      onClick={()=>(
        props.onClick()
        // console.log("foo")
        )}
      copyright={false}
      center={false}
      logo={false}
      />
  );
};

export default Step2Completion;

import React, { useEffect, useState } from "react";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import checkoutAtomState from "./../../_atoms/checkoutAtom";

// IMPORT components MATERIAL UI
import {
  Typography,
  Button,
  Grid,
  Card,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  CardActions,
  CardContent,
  CardHeader,
} from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from "@material-ui/core/styles";

// IMPORT icons MATERIAL UI
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Chip from '@material-ui/core/Chip';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

// IMPORT custom scss
import "./CardProduct.scss"

const useStyles = makeStyles((theme) => {
  return {
    cardHeader: {
      backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    },
  };
});

const CardProduct = (props) => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();

  // GLOBAL STATE (RECOIL)
  const [checkoutAtom, setCheckoutAtom] = useRecoilState(checkoutAtomState);

  // LOCAL STATE
  const [features, setFeatures] = useState([]);

  console.log(props.product)
  const bg = props.product === "premium" ? "bg--4" : props.product === "pro" ? "bg--5" : "bg--basic";
  const mainColor = props.product === "premium" ? theme.palette.premium.main : props.product === "pro" ? theme.palette.pro.main : "darkgrey";
  const monthlyProduct = props.product === "premium" ? checkoutAtom.premiumMonthly : checkoutAtom.proMonthly
  const yearlyProduct = props.product === "premium" ? checkoutAtom.premiumYearly : checkoutAtom.proYearly
  const monthlyPrice = props.product === "premium" ? checkoutAtom.premiumMonthly.price : props.product === "pro" ? checkoutAtom.proMonthly.price : 0
  const yearlyPrice = props.product === "premium" ? checkoutAtom.premiumYearly.price : props.product === "pro" ? checkoutAtom.proYearly.price : 0

  /* 
    Functions
  */
  const handleChangeInterval = (e, target, product) => {
    setCheckoutAtom({
      ...checkoutAtom,
      [target]: {
        ...checkoutAtom[target],
        [product]: e.target.value,
      },
    });
  };

  useEffect(() => {
    const contentObject = t(`PRODUCTS.${props.product}.features`, { returnObjects: true }); // Get object
    const contentArray = Object.values(contentObject); // Convert object to array
    setFeatures(contentArray)
  }, [props.product]);
  
  return (
    <Grid item xs={12} md={6}>
      <Card elevation={0} className="h-bg--white h-borderRadius--10  box-shadow--1" >
        <CardHeader
          title={t(`PRODUCTS.${props.product}.name`)}
          titleTypographyProps={{ align: 'center', variant: "h5" }}
          subheaderTypographyProps={{ align: 'center' }}
          className={`${classes.cardHeader} ${bg}`}
          style={{ color: "#ffffff", padding: "30px" }}
        />

        <CardContent style={{ padding: "15px 45px" }}>
          {props.product !== "basic" &&
          <Grid container style={{ justifyContent: "center", flexDirection: "row", marginTop: "20px", marginBottom: "20px" }}>
            <FormControl component="fieldset">
              <RadioGroup aria-label="subscription" name="subscription" value={checkoutAtom.selectedInterval[props.product]} onChange={(e) => handleChangeInterval(e, "selectedInterval", props.product)} style={{ display: "flex", flexDirection: "row" }}>
                <FormControlLabel value="monthly" control={<Radio color="primary" />} label={<Typography variant="div">{t("PROMO.monthly")}</Typography>} />

                <FormControlLabel
                  value="yearly"
                  control={<Radio color="primary" />}
                  label={
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Typography variant="div">{t("PROMO.yearly")}</Typography>
                      
                      <Chip
                        style={{ marginLeft: "5px", marginTop: "-1px" }}
                        // variant="outlined"
                        size="small"
                        label={t(`PROMO.${props.product}.save`)}
                        clickable
                        color="secondary"
                      />
                      
                    </div>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          }

          <Grid container justifyContent="center" alignItems="flex-end">
            <Typography component="div" variant="h5" color="textPrimary" style={{ marginRight: "6px" }}>
              {`€ ${Math.round((checkoutAtom.selectedInterval[props.product] === "monthly" ? monthlyPrice : yearlyPrice) * (1 + checkoutAtom.userTaxRate / 100))}`}
            </Typography>

            <Typography variant="subtitle2" color="textSecondary">
              {`${checkoutAtom.selectedInterval[props.product] === "monthly" ? `/ ${t("PROMO.month")}` : `/ ${t("PROMO.year")}`}`}
            </Typography>
          </Grid>

          {checkoutAtom.selectedInterval[props.product] === "monthly" &&
            <Grid container justifyContent="center" alignItems="flex-end">
              <Typography variant="subtitle2" color="textSecondary">
                {t("PROMO.inclVat")}
              </Typography>
            </Grid>
          }

          {checkoutAtom.selectedInterval[props.product] === "yearly" &&
            <Grid container justifyContent="center" alignItems="flex-end">
              <Typography variant="subtitle2" color="textSecondary">
                {t("PROMO.inclVatEquals")} {`€ ${((yearlyPrice * (1 + checkoutAtom.userTaxRate / 100)) / 12).toFixed(2)} / ${t("PROMO.month")}`}
              </Typography>
            </Grid>
          }

          <div style={{ marginTop: "30px", marginBottom: "25px" }}>
            {features.map(function (item, i) {
              return item[0] === "+" ?
                <div key={i} style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", marginBottom: "5px" }}>
                  <CheckCircleIcon fontSize="large" style={{ color: mainColor, padding: "2px", marginRight: "5px" }} />
                  <Typography component="span" variant="subtitle1" align="left" style={{ lineHeight: 1.2 }}>{item[1]}</Typography>
                </div>
                : <div key={i} style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", marginBottom: "5px" }}>
                  <HighlightOffIcon fontSize="large" color="primary" style={{ padding: "2px", marginRight: "5px", color: theme.palette.grey[300] }} />
                  <Typography component="span" variant="subtitle1" align="left" style={{ lineHeight: 1.2 }}>
                    <span style={{ color: theme.palette.grey[400] }}>{item[1]}</span>
                  </Typography>
                </div>
            })}
          </div>
        </CardContent>

        <CardActions>
          <Button
            onClick={() => {
              props.product !== "basic" ?
                checkoutAtom.selectedInterval[props.product] === "monthly" ? setCheckoutAtom({ ...checkoutAtom, selectedProduct: monthlyProduct }) : setCheckoutAtom({ ...checkoutAtom, selectedProduct: yearlyProduct })
                : setCheckoutAtom({ ...checkoutAtom, selectedProduct: "basic" });
              props.handleNext();
            }}
            size="large"
            style={{ padding: "15px" }}
            fullWidth
            variant="contained"
            color="primary"
            endIcon={<ArrowForwardIcon />}
          >
            {t(`PROMO.${props.product}.button`)}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default CardProduct;

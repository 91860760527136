import { PDFDocument, rgb } from 'pdf-lib';
import { saveAs } from 'file-saver';

const A4_WIDTH = 595.28;
const A4_HEIGHT = 841.89;

const base64ToArrayBuffer = (base64) => {
  const binaryString = window.atob(base64.split(',')[1]);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

const createPDFWithExpenseDetails = async (headline, company, netExpense, vatExpense, grossExpense) => {
  const pdfDoc = await PDFDocument.create();
  const page = pdfDoc.addPage([A4_WIDTH, A4_HEIGHT]);
  const fontSize = 12;
  const textFontSize = 10;

  page.drawText(headline, { x: 50, y: A4_HEIGHT - 4 * fontSize, size: fontSize, color: rgb(0, 0, 0) });
  page.drawText(`Lieferant: ${company}`, { x: 50, y: A4_HEIGHT - 6 * fontSize, size: textFontSize, color: rgb(0, 0, 0) });
  page.drawText(`Netto (in €): ${netExpense}`, { x: 50, y: A4_HEIGHT - 8 * fontSize, size: textFontSize, color: rgb(0, 0, 0) });
  page.drawText(`USt (in €): ${vatExpense}`, { x: 50, y: A4_HEIGHT - 10 * fontSize, size: textFontSize, color: rgb(0, 0, 0) });
  page.drawText(`Brutto (in €): ${grossExpense}`, { x: 50, y: A4_HEIGHT - 12 * fontSize, size: textFontSize, color: rgb(0, 0, 0) });

  const pdfBytes = await pdfDoc.save();
  return new Blob([pdfBytes], { type: 'application/pdf' });
};

const compressImageBlob = async (blob) => {
  const imageBitmap = await createImageBitmap(blob);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  
  // Set the canvas size to be smaller than the original image
  const MAX_WIDTH = 600;
  const scaleSize = MAX_WIDTH / imageBitmap.width;
  canvas.width = MAX_WIDTH;
  canvas.height = imageBitmap.height * scaleSize;
  
  // Draw the image on the canvas and get the compressed blob
  ctx.drawImage(imageBitmap, 0, 0, canvas.width, canvas.height);
  
  return new Promise((resolve) => {
    canvas.toBlob((compressedBlob) => {
      resolve(compressedBlob);
    }, 'image/jpeg', 0.7);  // You can adjust the quality here
  });
};

const embedImage = async (pdfDoc, blob) => {
  const arrayBuffer = await blob.arrayBuffer();
  let image = await pdfDoc.embedJpg(arrayBuffer);
  
  return image;
};

const addWatermark = (page, index, watermark) => {
  if (!watermark) return;

  const { width, height } = page.getSize();
  const circleSize = 32;
  const circleX = width - circleSize - 20;
  const circleY = height - circleSize - 20;

  page.drawEllipse({
    x: circleX + circleSize / 2,
    y: circleY + circleSize / 2,
    xScale: circleSize / 2,
    yScale: circleSize / 2,
    color: rgb(1, 1, 1, 0.3),  // 70% transparent
    borderColor: rgb(0, 0, 0),
    borderWidth: 1,
    opacity: 0.5,
  });

  // Center the text within the circle
  const text = `${index + 99}`;
  const textSize = 10;  // Half the size of the original 20
  const textWidth = textSize * text.length * 0.5;  // Approximate width
  const textHeight = textSize * 0.7;  // Approximate height

  page.drawText(text, {
    x: circleX + (circleSize - textWidth) / 2,
    y: circleY + (circleSize - textHeight) / 2,
    size: textSize,
    color: rgb(0, 0, 0),
  });
};

const DOWNLOAD_SINGLEPDF = async (data, watermark) => {
  const pdfDoc = await PDFDocument.create();

  for (const [index, item] of data.entries()) {
    const pageIndex = index + 1;

    if (item.type === "expense" && item.blob === undefined) {
      const expensePageBlob = await createPDFWithExpenseDetails(
        "Ohne Beleg",
        item.company,
        item.netExpense,
        item.vatExpense,
        item.grossExpense
      );
      const expensePdf = await PDFDocument.load(await expensePageBlob.arrayBuffer());
      const copiedPages = await pdfDoc.copyPages(expensePdf, expensePdf.getPageIndices());
      copiedPages.forEach((page) => {
        pdfDoc.addPage(page);
        addWatermark(page, pageIndex, watermark);
      });
    } else {
      const arrayBuffer = base64ToArrayBuffer(item.blob);

      if (item.blobtype === 'pdf') {
        const embeddedPdf = await PDFDocument.load(arrayBuffer);
        const copiedPages = await pdfDoc.copyPages(embeddedPdf, embeddedPdf.getPageIndices());
        copiedPages.forEach((page) => {
          pdfDoc.addPage(page);
          addWatermark(page, pageIndex, watermark);
        });
      } else if (item.blobtype === 'image') {
        let compressedBlob = await compressImageBlob(new Blob([arrayBuffer], { type: 'image/jpeg' }));
        const image = await embedImage(pdfDoc, compressedBlob);
        const page = pdfDoc.addPage([A4_WIDTH, A4_HEIGHT]);
        page.drawImage(image, {
          x: 0,
          y: 0,
          width: A4_WIDTH,
          height: A4_HEIGHT,
        });
        addWatermark(page, pageIndex, watermark);
      } else {
        throw new Error('Unsupported blob type');
      }
    }
  }

  const pdfBytes = await pdfDoc.save();
  const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
  saveAs(pdfBlob, 'Belege.pdf');
};

export default DOWNLOAD_SINGLEPDF;
